import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import RelevancyTableCell from 'components/molecules/TableCells/RelevancyTableCell/RelevancyTableCell';
import StyledTeamRelevancyTableCellEdit
  from 'components/organisms/TableCellEdits/TeamRelevancyTableCellEdit/TeamRelevancyTableCellEdit.styles';
import RelevancyBreakDownChart from 'components/organisms/Charts/RelevancyBreakDownChart/RelevancyBreakDownChart';
import {withMemo} from 'helpers/wrapper';

const TeamRelevancyTableCellEdit = withMemo(React.forwardRef((props, ref) => {
  const {
    cell,
    table,
    relevancy,
    relevancies,
    size,
    ...innerProps
  } = useComponentProps(props, 'TeamRelevancyTableCellEdit');

  return <StyledTeamRelevancyTableCellEdit ref={ref} {...innerProps}
                                                 cell={cell}
                                                 table={table}
                                                 TableCellPopperProps={{
                                                   density:'densest'
                                                 }}
                                                 Anchor={<RelevancyTableCell relevancy={relevancy}
                                                                             active={true}
                                                                             size={size}/>}>
    <RelevancyBreakDownChart relevancies={relevancies} />
  </StyledTeamRelevancyTableCellEdit>
}));

TeamRelevancyTableCellEdit.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  table: PropTypes.object,
  cell: PropTypes.object,
  relevancy: PropTypes.number,
  size: PropTypes.string
};

TeamRelevancyTableCellEdit.defaultProps = {
  size: 'medium'
};

export default TeamRelevancyTableCellEdit;


