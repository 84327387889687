import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectItem} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import StyledCollectionsTableBar from 'components/organisms/Bars/CollectionsTableBar/CollectionsTableBar.styles';
import Badge from 'components/atoms/Badges/Badge/Badge';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useClientStatisticLabelsBreakdown} from 'services/client/statistic/statistic.utils';
import BookmarksOutlined from '@mui/icons-material/BookmarksOutlined';

const CollectionsTableBar = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionsTableBar');

  const tableProvider = useTable();
  const listState = useEffectItem(tableProvider.appliedListState());

  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const searchField = useMemo(() => ({
    placeholder: 'Search for a collection'
  }), []);

  const filterAction = useMemo(() => ({
    auth: utils.createAuth({ attribute: 'collection.filters'})
  }), []);

  const columnAction = useMemo(() => ({
    auth: utils.createAuth({ attribute: 'collection.columns'})
  }), []);

  const [labelsBreakdown, , isLabelsLoading] = useClientStatisticLabelsBreakdown(
    listState.search,
    listState.filter
  );
  const themesCount = labelsBreakdown?.length ?? 0;
  const indexAction = useMemo(() => ({
    label: !mdDown ? 'Themes' : null,
    tooltip: mdDown ? 'Themes' : null,
    icon: BookmarksOutlined,
    auth: utils.createAuth({ attribute: 'collection.themes'}),
    badge: <Badge badgeContent={themesCount || '0'} color={themesCount > 0 ? 'primary' : 'light'}/>,
    ActionButtonProps: {
      BadgeProps: {
        isLoading: isLabelsLoading
      }
    },
    ActionIconButtonProps: {
      BadgeProps: {
        isLoading: isLabelsLoading
      }
    }
  }), [themesCount, isLabelsLoading, mdDown]);

  const collectionsCount = utils.formatNumber(tableProvider.list?.meta?.resultsCount ?? 0);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledCollectionsTableBar ref={ref} {...innerProps}
                                    title={`${collectionsCount} collection${(tableProvider.list?.meta?.resultsCount ?? 0) === 1 ? '' : 's'}`}
                                    searchField={searchField}
                                    hideAnalyse={true}
                                    hideFilter={true}
                                    hideColumn={true}
                                    hideIndex={false}
                                    indexAction={indexAction}
                                    filterAction={filterAction}
                                    columnAction={columnAction}/>
});

CollectionsTableBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionsTableBar.defaultProps = {
};

export default CollectionsTableBar;
