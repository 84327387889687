import React, {useMemo} from 'react'
import ProfileProvider from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import constants from 'helpers/constants';
import {useAuthClientId} from 'services/auth/auth.utils';
import {useClientGet} from 'services/client/client.hooks';
import {useDashboardCards} from 'services/client/client.utils';
import {useProviderView} from 'helpers/hooks/utils';

const DashboardProfileProvider = (props) => {
  const clientId = useAuthClientId();

  const view = useProviderView('dashboard', props.skipView);

  const client = useClientGet({clientId}, {enabled: clientId >= 0});

  const cards = useDashboardCards(view);

  // isLoading anything, is loading something definition related
  const isLoading = props?.isLoading || client.status?.isLoading;
  const isDefinitionsLoading = props?.isDefinitionsLoading;

  const cardDefinitions = useMemo(() => {
    return cards?.length > 0 ? cards : null;
  }, [cards]);

  const options = useMemo(() => ({
    cardState: {
      options: {
        name: 'dashboard',
        type: constants.appState.type.local
      }
    },
    cardDefinitions
  }), [cardDefinitions]);

  return <ProfileProvider {...props}
                          view={view}
                          data={client}
                          options={options}
                          isDefinitionsLoading={isDefinitionsLoading}
                          isLoading={isLoading}>
    {props.children}
  </ProfileProvider>
};

DashboardProfileProvider.propTypes = {
}

export default DashboardProfileProvider;
