import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import GraphCard from 'components/molecules/Cards/GraphCard/GraphCard';

const StyledCreditDistributionGraphCard = styled(GraphCard)`
  display: flex;
  flex-direction: column;

  .GraphCard-content {
    padding-bottom: ${props => props.theme.spacing(1.5)};
  }
  
  .CardContent {
    .CreditDistributionPieChart {
      &-chart {
        ${props => props.theme.breakpoints.down('lg')} {
          margin: 0 ${props => props.theme.spacing(0)};
        }
        ${props => props.theme.breakpoints.between('sm', 'md')} {
          margin: 0 ${props => props.theme.spacing(6)};
        }
        
        &-wrapper {
          ${props => props.theme.breakpoints.down('lg')} {
            margin-top: -${props => props.theme.layout('3sp')};
            height: calc(100% + ${props => props.theme.layout('6sp')});
          }
        }
      }
      
      &-center {
        ${props => props.theme.breakpoints.down('lg')} {
          transform: scale(0.75);
        }
        ${props => props.theme.breakpoints.down('md')} {
          transform: scale(1);
        }
        ${props => props.theme.breakpoints.down('sm')} {
          transform: scale(0.75);
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledCreditDistributionGraphCard.propTypes = {
  theme: PropTypes.object
};

StyledCreditDistributionGraphCard.defaultProps = {}

export default StyledCreditDistributionGraphCard;
