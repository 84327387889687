import utils from 'helpers/utils';
import {useServiceStatus} from 'stores/hooks/service';
import {useServiceApiMutation, useServiceApiQuery} from 'stores/hooks/query';

const path = utils.servicePath(import.meta.url);

export function useEntityPersonStatus () {
  return useServiceStatus(path);
}

export function useEntityPersonList ({entityId, search, filter, sort, page, pageSize, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list', {entityId, search, filter, sort, page, pageSize, ...rest}, queryOptions);
}

export function useEntityPersonAdd (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

export function useEntityPersonUpdate (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, mutationOptions);
}

export function useEntityPersonDelete (mutationOptions = {}) {
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}
