import React, {useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import StyledSectionPanel from 'components/organisms/SectionPanels/SectionPanel/SectionPanel.styles';
import Tabs from 'components/atoms/Tabs/Tabs/Tabs';
import Tab from 'components/atoms/Tabs/Tab/Tab';
import TabPanel from 'components/atoms/Tabs/TabPanel/TabPanel';
import SectionPanelContent from 'components/organisms/SectionPanels/SectionPanelContent/SectionPanelContent';
import Component from 'components/organisms/Utils/Component/Component';
import utils from 'helpers/utils';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';

const SectionPanel = React.forwardRef((props, ref) => {
  const {
    data,
    dataKey,
    sections,
    active,
    onChange,
    isLoading,
    TabsProps,
    ...innerProps
  } = useComponentProps(props, 'SectionPanel', {
    variable: ['anchor'],
    children: ['tabs', 'panel']
  });

  const innerRef = useRef(null);
  const initialised = useRef(false);
  const [internalState, setInternalState] = useState({
    dirty: false,
    activePanel: null
  });

  const sectionPanel = useMemo(() => ({
    state: {
      ...internalState
    },
    refs: {
      ref: innerRef
    }
  }), [internalState]);

  useImperativeHandle(ref, () => sectionPanel);

  const sectionsMemo = useMemo(() => {
    if (sections) {
      return sections
        .map((section, idx) => {
          const id = `key_${section.name}_${idx}`;

          return {
            id,
            ...section,
            cards: section.cards?.sort?.((a, b) => a.position - b.position)
          };
        })
        .filter((section) => {
          const hidden = utils.isFunction(section.hidden) ?
            Boolean(section.hidden({section, data})) :
            (section.hidden || !(section.Content || section.cards?.length > 0));
          return !hidden;
        })
        .sort((a, b) => a.position - b.position);
    } else {
      return [];
    }
  }, [sections, data]);

  const onChangeEvent = useEffectEvent(onChange);
  useEffect(() => {
    const activeIndex = sectionsMemo.findIndex((section) => section.name === active?.name);
    let section = sectionsMemo[+(sectionPanel.state.activePanel ?? activeIndex ?? 0)];
    if (initialised.current !== (active?.name ?? true)) {
      if (Boolean(section)) {
        initialised.current = (active?.name ?? true);
        onChangeEvent?.(section, 'sectionInit');
      }
    } else if (sectionPanel.state.activePanel) {
      if (!Boolean(section)) {
        section = sectionsMemo[0];
        if (Boolean(section)) {
          onChangeEvent?.(section, 'sectionNotFound');
        }
      }
    }
  }, [onChangeEvent, sectionPanel.state.activePanel, active?.name, sectionsMemo]);

  const handleChange = (event, value) => {
    if (!sectionPanel.state.dirty) {
      onChange?.(sectionsMemo[+value], 'sectionClick');
      setInternalState((current) => ({
        ...current,
        activePanel: value
      }))
    }
  };

  const activePanel = useMemo(() => {
    const activeIndex = sectionsMemo.findIndex((section) => section.name === active?.name);
    const section = sectionsMemo[+(sectionPanel.state.activePanel ?? activeIndex ?? 0)];
    if (Boolean(section)) {
      return +(sectionPanel.state.activePanel ?? activeIndex ?? 0);
    } else {
      return null;
    }
  }, [sectionPanel.state.activePanel, active?.name, sectionsMemo]);

  useEffect(() => {
    const scroll = () => {
      return innerRef.current?.querySelector('.ListWrapper-content')?.scrollTo({top: 0, left: 0});
    }

    utils.retry(scroll, 3);
  }, [data?.[dataKey]]);

  const handleDirty = (dirty) => {
    setInternalState(utils.updater({dirty}, true));
  }

  const renderSectionTab = (section, idx) => {
    const tabProps = utils.isFunction(section.TabProps) ? section.TabProps({section, data, isLoading}) : section.TabProps;

    return <Tooltip key={idx}
                    title={section.tooltip}
                    fullWidth={false}
                    placement="bottom"
                    showDisabled={Boolean(section.tooltip && section.disabled)}
                    {...section.TooltipProps}>
      <Tab value={idx}
           icon={section.icon ? <Icon icon={section.icon} /> : null}
           iconPosition={section.iconPosition ?? 'start'}
           label={section.title}
           disabled={Boolean(section.disabled)}
           {...tabProps}/>
    </Tooltip>
  }

  const renderSection = (section) => {
    const renderedSection = <SectionPanelContent className={`SectionPanel-section SectionPanel-section-${section.name}`}
                                                 sectionPanel={sectionPanel}
                                                 section={section}
                                                 onDirty={handleDirty}
                                                 isLoading={isLoading}
                                                 data={data}/>

    if (section.Content) {
      return <Component Original={section.Content}
                        className={`SectionPanel-section SectionPanel-section-${section.name}`}
                        sectionPanel={sectionPanel}
                        section={section}
                        data={data}
                        onDirty={handleDirty}
                        isLoading={isLoading}
                        renderedSection={renderedSection} />
    } else {
      return renderedSection;
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledSectionPanel ref={innerRef} {...innerProps}>
    {sectionsMemo.length > 1 ? <Tabs variant="scrollable"
                                     size="small"
                                     density="dense"
                                     value={activePanel ?? 0}
                                     onChange={handleChange}
                                     disabled={sectionPanel.state.dirty}
                                     className="SectionPanel-tabs"
                                     {...TabsProps}>
      {sectionsMemo.map((section, idx) => {
        return renderSectionTab(section, idx);
      })}
    </Tabs> : null}
    {sectionsMemo.map((section, idx) => {
      return <TabPanel key={section.id}
                       className="SectionPanel-panel"
                       value={activePanel ?? 0}
                       index={idx}>
        {renderSection(section)}
      </TabPanel>
    })}
  </StyledSectionPanel>
});

SectionPanel.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  data: PropTypes.object,
  dataKey: PropTypes.string,
  active: PropTypes.object,
  sections: PropTypes.array,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  TabsProps: PropTypes.object
};

SectionPanel.defaultProps = {
  anchor: 'right'
};

export default SectionPanel;
