import {useAuthClientId} from 'services/auth/auth.utils';
import {useClientStatisticGet} from 'services/client/statistic/statistic.hooks';
import {useMemo} from 'react';
import utils from 'helpers/utils';

export function useClientStatisticWeeklyUpdates () {
  const clientId = useAuthClientId();
  const teamTotals = useClientStatisticGet({clientId, statisticId: 'teamTotals'});

  const weeklyUpdates = useMemo(() => {
    if (utils.isDefined(teamTotals?.data?.data?.changes) &&
      utils.isDefined(teamTotals?.data?.data?.totals)) {
      return [
        teamTotals?.data?.data?.changes,
        teamTotals?.data?.data?.totals,
      ];
    } else {
      return [null, null];
    }
  }, [teamTotals?.data?.data?.changes, teamTotals?.data?.data?.totals]);

  return [...weeklyUpdates, teamTotals?.status?.isLoading];
}

export function useClientStatisticLabelsBreakdown (search, filter, queryOptions = {}) {
  const clientId = useAuthClientId();
  const labelsBreakdown = useClientStatisticGet({
    clientId,
    statisticId: 'labels',
    search, filter
  }, {
    enabled: clientId >= 0,
    ...queryOptions
  });

  const breakdown = useMemo(() => {
    if (utils.isDefined(labelsBreakdown?.data?.data?.breakdown) &&
      utils.isDefined(labelsBreakdown?.data?.data?.totals)) {
      return [labelsBreakdown?.data?.data?.breakdown, labelsBreakdown?.data?.data?.totals];
    } else {
      return [null, null];
    }
  }, [labelsBreakdown?.data?.data?.breakdown, labelsBreakdown?.data?.data?.totals]);

  return [...breakdown, labelsBreakdown?.status?.isLoading]
}

export function useClientStatisticDealflow (collectionId, search, filter, queryOptions = {}) {
  const clientId = useAuthClientId();
  const dealflowGet = useClientStatisticGet({
    clientId,
    collectionId,
    statisticId: 'dealflow',
    search, filter
  }, {
    enabled: clientId >= 0,
    ...queryOptions
  });

  const dealflow = useMemo(() => {
    if (utils.isDefined(dealflowGet?.data?.data)) {
      return dealflowGet?.data?.data;
    } else {
      return null;
    }
  }, [dealflowGet?.data?.data]);

  return [dealflow, dealflowGet?.status?.isLoading];
}

export function useClientStatisticDealflowMonthly (year, months, search, filter) {
  const clientId = useAuthClientId();
  const dealflowGet = useClientStatisticGet({
    clientId,
    statisticId: 'dealflowMonthly',
    year, months,
    search, filter
  }, {enabled: clientId >= 0});

  const dealflow = useMemo(() => {
    if (utils.isDefined(dealflowGet?.data?.data)) {
      return utils.camelcaseEx(dealflowGet?.data?.data);
    } else {
      return null;
    }
  }, [dealflowGet?.data?.data]);

  return [dealflow, dealflowGet?.status?.isLoading];
}

export function useClientStatisticDealflowDevelopment (years, search, filter) {
  const clientId = useAuthClientId();
  const dealflowGet = useClientStatisticGet({
    clientId,
    statisticId: 'dealflowDevelopmentYearly',
    years,
    search, filter
  }, {enabled: clientId >= 0});

  const dealflow = useMemo(() => {
    if (utils.isDefined(dealflowGet?.data?.data)) {
      return utils.camelcaseEx(dealflowGet?.data?.data);
    } else {
      return null;
    }
  }, [dealflowGet?.data?.data]);

  return [dealflow, dealflowGet?.status?.isLoading];
}

export function useClientStatisticDealflowRundown (year, months, search, filter) {
  const clientId = useAuthClientId();
  const dealflowGet = useClientStatisticGet({
    clientId,
    statisticId: 'dealflowRundown',
    year, months,
    search, filter
  }, {enabled: clientId >= 0});

  const dealflow = useMemo(() => {
    if (utils.isDefined(dealflowGet?.data?.data)) {
      return utils.camelcaseEx(dealflowGet?.data?.data);
    } else {
      return null;
    }
  }, [dealflowGet?.data?.data]);

  return [dealflow, dealflowGet?.status?.isLoading];
}

export function useClientStatisticLatestNews (collectionId, months, search, filter) {
  const clientId = useAuthClientId();
  const latestNewsGet = useClientStatisticGet({
    clientId,
    collectionId,
    statisticId: 'latestNews',
    months,
    search, filter
  }, { enabled: clientId >= 0 });

  const latestNews = useMemo(() => {
    if (utils.isDefined(latestNewsGet?.data?.data)) {
      return utils.camelcaseEx(latestNewsGet?.data?.data);
    } else {
      return null;
    }
  }, [latestNewsGet?.data?.data]);

  return [latestNews, latestNewsGet?.status?.isLoading];
}

export function useClientStatisticTaskBreakdown (year, months, collectionId, search, filter, queryOptions = {}) {
  const clientId = useAuthClientId();
  const breakdownGet = useClientStatisticGet({
    clientId,
    collectionId,
    statisticId: 'taskBreakdown',
    year, months,
    search, filter
  }, {
    enabled: clientId >= 0,
    ...queryOptions
  });

  const breakdown = useMemo(() => {
    if (utils.isDefined(breakdownGet?.data?.data)) {
      return breakdownGet?.data?.data;
    } else {
      return null;
    }
  }, [breakdownGet?.data?.data]);

  return [breakdown, breakdownGet?.status?.isLoading];
}

export function useClientStatisticRelevancyDistribution (collectionId, search, filter, queryOptions = {}) {
  const clientId = useAuthClientId();
  const distributionGet = useClientStatisticGet({
    clientId,
    collectionId,
    statisticId: 'relevancyDistribution',
    search, filter
  }, {
    enabled: clientId >= 0,
    ...queryOptions
  });

  const relevancyDistribution = useMemo(() => {
    if (utils.isDefined(distributionGet?.data?.data)) {
      return utils.camelcaseEx(distributionGet?.data?.data);
    } else {
      return null;
    }
  }, [distributionGet?.data?.data]);

  return [relevancyDistribution, distributionGet?.status?.isLoading];
}

export function useClientStatisticCreditDistribution (months, queryOptions = {}) {
  const clientId = useAuthClientId();
  const distributionGet = useClientStatisticGet({
    clientId,
    months,
    statisticId: 'creditDistribution'
  }, {
    enabled: clientId >= 0,
    ...queryOptions
  });

  const creditDistribution = useMemo(() => {
    if (utils.isDefined(distributionGet?.data?.data)) {
      return utils.camelcaseEx(distributionGet?.data?.data);
    } else {
      return null;
    }
  }, [distributionGet?.data?.data]);

  return [creditDistribution, distributionGet?.status?.isLoading];
}

export function useClientStatisticCpcBreakdown (years, filter, queryOptions = {}) {
  const clientId = useAuthClientId();
  const breakdownGet = useClientStatisticGet({
    clientId,
    years,
    filter,
    statisticId: 'cpcBreakdown'
  }, {
    enabled: clientId >= 0,
    ...queryOptions
  });

  const cpcBreakdown = useMemo(() => {
    if (utils.isDefined(breakdownGet?.data?.data)) {
      return utils.camelcaseEx(breakdownGet?.data?.data);
    } else {
      return null;
    }
  }, [breakdownGet?.data?.data]);

  return [cpcBreakdown, breakdownGet?.status?.isLoading];
}
