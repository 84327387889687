import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledDealflowEntitiesGraphsPage
  from 'components/pages/Dealflow/Entity/Graph/DealflowEntitiesGraphsPage/DealflowEntitiesGraphsPage.styles';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import useMediaQuery from '@mui/material/useMediaQuery';
import CardItem from 'components/atoms/Cards/Carditem/CardItem';
import Cards from 'components/atoms/Cards/Cards/Cards';
import DealflowBreakdownGraphCard
  from 'components/organisms/Cards/DealflowBreakdownGraphCard/DealflowBreakdownGraphCard';
import {useWrapper} from 'components/templates/Wrappers/Basic/Wrapper/Wrapper';
import DealflowMonthlyBreakdownGraphCard
  from 'components/organisms/Cards/DealflowMonthlyBreakdownGraphCard/DealflowMonthlyBreakdownGraphCard';
import DealflowRundownGraphCard from 'components/organisms/Cards/DealflowRundownGraphCard/DealflowRundownGraphCard';
import DealflowDevelopmentGraphCard
  from 'components/organisms/Cards/DealflowDevelopmentGraphCard/DealflowDevelopmentGraphCard';
import DealflowTaskBreakdownGraphCard
  from 'components/organisms/Cards/DealflowTaskBreakdownGraphCard/DealflowTaskBreakdownGraphCard';
import DealflowTasksGraphCard from 'components/organisms/Cards/DealflowTasksGraphCard/DealflowTasksGraphCard';
import Box from 'components/atoms/Layout/Box/Box';
import constants from 'helpers/constants';
import Debounce from 'components/organisms/Utils/Debounce/Debounce';
import DealflowLatestNewsGraphCard
  from 'components/organisms/Cards/DealflowLatestNewsGraphCard/DealflowLatestNewsGraphCard';

const DealflowEntitiesGraphsPage = (props) => {
  const innerProps = useComponentProps(props, 'DealflowEntitiesGraphsPage');

  const wrapper = useWrapper();

  const tableProvider = useTable();
  const graphState = tableProvider.graphState;
  const graphDefinitions = tableProvider.graphDefinitions;

  const graphs = useMemo(() => {
    const graphs = [];
    if (graphDefinitions) {
      graphDefinitions
        .forEach((graphDef, idx) => {
          const id = `key_${graphDef.name}_${idx}`;

          if (graphDef.name === 'breakdown') {
            graphs.push({
              ...graphDef,
              id,
              anchor: 'left',
              span: 8,
              content: <DealflowBreakdownGraphCard className="DealflowEntitiesGraphsPage-breakdown"/>
            });
          } else if (graphDef.name === 'monthlyBreakdown') {
            graphs.push({
              ...graphDef,
              id,
              anchor: 'right',
              span: 16,
              content: <DealflowMonthlyBreakdownGraphCard className="DealflowEntitiesGraphsPage-monthlyBreakdown"/>
            });
          } else if (graphDef.name === 'development') {
            graphs.push({
              ...graphDef,
              id,
              anchor: 'left',
              span: 12,
              content: <DealflowDevelopmentGraphCard className="DealflowEntitiesGraphsPage-development"/>
            });
          } else if (graphDef.name === 'rundown') {
            graphs.push({
              ...graphDef,
              id,
              anchor: 'right',
              span: 12,
              content: <DealflowRundownGraphCard className="DealflowEntitiesGraphsPage-rundown"/>
            });
          } else if (graphDef.name === 'latestNews') {
            const openTasks = graphs.find((g) => g.name === 'tasks');
            if (openTasks) {
              openTasks.fit = false;
              openTasks.content = <Box className="DealflowEntitiesGraphsPage-news-wrapper">
                <DealflowLatestNewsGraphCard className="DealflowEntitiesGraphsPage-latestNews"/>
                {openTasks.content}
              </Box>
            } else {
              graphs.push({
                ...graphDef,
                id,
                anchor: 'left',
                span: 12,
                content: <DealflowLatestNewsGraphCard className="DealflowEntitiesGraphsPage-latestNews"/>
              });
            }
          } else if (graphDef.name === 'tasks') {
            const latestNews = graphs.find((g) => g.name === 'latestNews');
            if (latestNews) {
              latestNews.fit = false;
              latestNews.content = <Box className="DealflowEntitiesGraphsPage-news-wrapper">
                {latestNews.content}
                <DealflowTaskBreakdownGraphCard className="DealflowEntitiesGraphsPage-tasks"/>
              </Box>
            } else {
              graphs.push({
                ...graphDef,
                id,
                anchor: 'left',
                span: 12,
                content: <DealflowTaskBreakdownGraphCard className="DealflowEntitiesGraphsPage-tasks"/>
              });
            }
          } else if (graphDef.name === 'openTasks') {
            graphs.push({
              ...graphDef,
              id,
              anchor: 'right',
              span: 12,
              content: <DealflowTasksGraphCard className="DealflowEntitiesGraphsPage-openTasks"/>
            });
          }
        });
    }

    return graphs
      .filter((graphDef) => graphState?.graphVisibility?.[graphDef.name] ?? true)
      .sort((a, b) => a.position - b.position);
  }, [graphDefinitions, graphState?.graphVisibility]);

  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const columns = !mdDown ? 24 : 1;

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledDealflowEntitiesGraphsPage {...innerProps} $wrapper={wrapper}>
    <Cards className="DealflowEntitiesGraphsPage-cards"
           columns={columns} gap={!lgDown ? 24 : 16}>
      {graphs?.map((graph, idx) => {
        return <CardItem key={graph.id}
                         data-key={graph.name}
                         className="DealflowEntitiesGraphsPage-card"
                         {...utils.filterObject(graph, ['anchor', 'span', 'rows', 'grow', 'fit'], false)}>
          <Debounce key={graph.id}
                    timeout={constants.debounce.minimal * Math.floor(idx / 2)}>
            {graph.content}
          </Debounce>
        </CardItem>
      })}
    </Cards>
  </StyledDealflowEntitiesGraphsPage>
};

DealflowEntitiesGraphsPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DealflowEntitiesGraphsPage.defaultProps = {
};

export default DealflowEntitiesGraphsPage;
