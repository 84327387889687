import utils from 'helpers/utils';
import logger from 'helpers/logger';
import ServiceStore from 'stores/service.store';

const path = utils.servicePath(import.meta.url);

export class EntityArticleService extends ServiceStore {
  constructor (app, callbacks) {
    logger.trace(`Starting module at: services/${path}`);

    super(path, app, {
      key: 'articleId',
      refreshEnabled: false,
      invalidateParent: true, // force invalidate entity when articles change
      invalidateFromParent: true,
      invalidateParentWithPriority: true
    }, callbacks);
  }
}
