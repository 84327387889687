import styled from 'styled-components';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import ComponentStyles from 'styles/mixins/Component.styles';

const StyledFormControl = styled(FormControl)`
  .Checkbox, .Radio {
    &:not(.Mui-checked) {
      color: ${props => props.theme.property('palette.input.outlined.enabledBorder')};
      .MuiTouchRipple-root {
        color: ${props => props.theme.property('palette.primary.main')} !important;
      }
      &:before {
        background-color: ${props => props.theme.property('palette.background.paper')} !important;
      }
      
      ${props => props.theme.state2Selector('MuiButtonBase-root', null, 'hover')} {
        color: ${props => props.theme.property('palette.input.outlined.hoverBorder')} !important;
        &:before {
          background-color: transparent !important;
        }
      }

      ${props => props.theme.state2Selector('MuiButtonBase-root', null, ['focus', 'focusVisible'])} {
        color: ${props => props.theme.property(`palette.primary`)?.main} !important;
        &:before {
          background-color: transparent !important;
        }
      }

      ${props => props.theme.state2Selector('MuiButtonBase-root', null, 'disabled')} {
        &:before {
          background-color: ${props => props.theme.property('palette.action.hover')} !important;
        }
      }
    }
  }
  
  .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.property('palette.input.outlined.enabledBorder')};
  }
  .MuiOutlinedInput-root {
    ${props => props.theme.state2Selector('MuiOutlinedInput-root', 'MuiOutlinedInput-notchedOutline', 'disabled')} {
      border-color: ${props => props.theme.property('palette.input.outlined.enabledBorder')} !important;
    }
  }

  .MuiInput-underline, .MuiFilledInput-root {
    &::before {
      border-color: ${props => props.theme.property('palette.input.standard.enabledBorder')};
    }

    &:hover {
      &::before {
        border-color: ${props => props.theme.property('palette.input.standard.hoverBorder')};
      }
    }
  }

  .MuiFormLabel-root {
    display: inline-flex;
  }
  
  .MuiFormHelperText-root {
    display: contents;
    margin: ${props => props.theme.spacing(0)};
    height: ${props => props.theme.layout(0)};
  }
  
  .MuiInputBase-root {
    input::placeholder, textarea::placeholder {
      color: ${props => props.theme.property('palette.text.disabled')};
      opacity: 0.9;
    }
  }

  &.FormControl {
    max-width: 100%;
    .InputContainer {
      max-width: 100%;
    }
  }

  &.FormControl-disabled {
    .Input-readOnly {
      color: ${props => props.theme.property('palette.text.disabled')};
    }
  }
  
  &.FormControl-fullWidth {
    width: 100%;
    
    &.FormControl-readOnly {
      .InputContainer {
        overflow-x: hidden;
      }
    }
  }

  &.FormControl-readOnly, &.FormControl-disabled {
    .InputLabel, .FormLabel {
      cursor: text;
      user-select: auto;
      -webkit-user-select: auto;
    }

    .Input-readOnly {
      > .Typography {
        user-select: auto;
        -webkit-user-select: auto;
      }
    }
  }
  
  &.FormControl-hiddenLabel {
    > .MuiOutlinedInput-notchedOutline, .InputOutline {
      span {
        display: none !important;
      }
    }
    > .MuiFormLabel-root {
      display: none !important;
    }
    > .CheckboxField-label .MuiFormControlLabel-label, > .SwitchField-label .MuiFormControlLabel-label {
      display: none !important;
    }
  }

  &.FormControl-hiddenHelperText {
    .MuiFormHelperText-root, .FormHelperText {
      display: none;
    }
  }
  
  .MuiOutlinedInput-notchedOutline, .InputOutline {
    border-width: 1px !important;

    span {
      display: ${props => props.hiddenLabel ? 'none' : 'inline-flex'};
    }
  }
  
  &.FormControl-radius-round {
    .MuiOutlinedInput-notchedOutline, .InputOutline {
      border-radius: ${props => props.theme.radius(2)};
    }
  }

  ${props => props.theme.state2Selector('FormControl', null, 'disabled')} {
    .FormControlLabel {
      pointer-events: none;
    }
  }
  
  // variants
  &.FormControl {
    &.FormControl {
      &-staticLabel, &-inlineLabel {
        &:not(.FormControl-disabled) {
          .InputLabel, .FormLabel {
            pointer-events: all;
            &:not(.Mui-focused, .Mui-error, .Mui-disabled) {
              color: ${props => props.theme.property('palette.text.primary')};
            }
          }
        }
        
        &.FormControl-disabled, &.FormControl-readOnly {
          .InputLabel, .FormLabel {
            color: ${props => props.theme.property('palette.text.primary')};
          }
        }
      }
      
      &-staticLabel {
        .InputLabel {
          transform: translate(0px, ${props => props.theme.layout(4)}) scale(0.75);
          width: calc(${1 / 0.75 * 100}%);
          max-width: calc(${1 / 0.75 * 100}%);
          position: relative;
          white-space: normal;

          + div {
            fieldset {
              legend > * {
                display: none;
              }
            }
          }
        }

        &.FormControl-hiddenLabel {
          > .InputLabel {
            display: none;
          }
          > .FormControlLabel, > .InputContainer > .FormControlLabel {
            margin: 0;
          }
          > .FormControlLabel > .MuiFormControlLabel-label, > .InputContainer > .FormControlLabel > .MuiFormControlLabel-label {
            display: none;
          }
        }
      }
      
      &-inlineLabel {
        display: flex;
        flex-direction: row;
        
        > .InputLabel, > .FormLabel {
          position: relative;
          overflow-wrap: anywhere;
          white-space: normal;
          margin-right: ${props => props.theme.layout('1sp')};
          margin-top: ${props => props.theme.layout('1sp')};
          transform: translate(0px, 0px);
        }

        &.ListField {
          > .InputLabel, > .FormLabel {
            margin-top: ${props => props.theme.layout('0.5sp')};
          }
        }
        
        &.CheckboxField, &.SwitchField {
          .InputLabel {
            align-self: center;
            transform: unset;
            margin-top: 0;
          }
          .InputContainer {
            justify-content: center;
          }
          .MuiFormControlLabel-label {
            align-self: center;
            margin-top: 0;
          }
        }
        
        &.FormControl-hiddenLabel {
          > .InputLabel {
            display: none;
          }
          > .FormControlLabel, > .InputContainer > .FormControlLabel {
            margin: 0;
          }
          > .MuiFormControlLabel-label, > .InputContainer > .FormControlLabel > .MuiFormControlLabel-label {
            display: none;
          }
        }
        
        &.FormControl-readOnly {
          .InputLabel, .FormLabel {
            transform: none;
            margin-top: 0;
          }
        }
        
        .InputContainer {
          flex-grow: 1;
        }

        .MuiOutlinedInput-notchedOutline, .InputOutline {
          span {
            display: none;
          }
        }
      }

      &-standard:not(.ListField):not(.CheckboxField):not(.SwitchField) {
        &:not(.FormControl-hiddenLabel) {
          > .InputContainer {
            margin-top: ${props => props.theme.layout(16)};
          }
        }
      }
    }
    
    &:not(.FormControl-staticLabel, .FormControl-inlineLabel) {
      .InputLabel {
        &:not(.MuiInputLabel-shrink) {
          color: ${props => props.theme.property('palette.text.disabled')};
          + .MuiInputBase-root, + * > .MuiInputBase-root {
            input::placeholder, textarea::placeholder {
              opacity: 0 !important;
            }
          }
        }
      }
      
      &.FormControl-hiddenLabel {
        &:not(.FormControl-outlined) {
          > .InputContainer {
            > .MuiInputBase-root {
              padding-top: 0;
              padding-bottom: 0;
            }

            > .MuiInputBase-input {
              padding-top: ${props => props.theme.layout(8)};
              padding-bottom: ${props => props.theme.layout(9)};
            }
          }
        }
        
        > .InputLabel {
          display: none;
          &:not(.MuiInputLabel-shrink) {
            transform: translate(${props => props.theme.layout(12)}, ${props => props.theme.layout(8)}) scale(1);
          }
        }

        > .FormControlLabel, > .InputContainer > .FormControlLabel {
          margin: 0;
        }
        > .MuiFormControlLabel-label, > .InputContainer > .FormControlLabel > .MuiFormControlLabel-label {
          display: none;
        }
      }
    }
    
    &-success {
      > .MuiFormLabel-root {
        color: ${props => props.theme.property('palette.success.main')} !important;
      }
      > .InputContainer {
        > .MuiOutlinedInput-notchedOutline, .InputOutline {
          border-color: ${props => props.theme.property('palette.success.main')} !important;
        }
        > .MuiFormHelperText-root {
          color: ${props => props.theme.property('palette.success.main')} !important;
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledFormControl.propTypes = {
  theme: PropTypes.object
};

StyledFormControl.defaultProps = {
}

export default StyledFormControl;
