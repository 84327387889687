import {useAppStore} from 'components/organisms/Providers/AppProvider/AppProvider';
import {useCallback, useLayoutEffect, useRef} from 'react';
import {useServiceState} from 'stores/hooks/store';
import utils from 'helpers/utils';
import constants from 'helpers/constants';

export function useAppState (type, key, scope, init) {
  const store = useAppStore();

  const stateRef = useRef({});
  const [state, setState] = useServiceState('app', store.atoms.processState({
    type: type === constants.appState.type.none ? constants.appState.type.temp : type,
    key,
    scope
  }));

  const stateFn = useCallback((f) => {
    return utils.isFunction(f) ? setState((current) => f(current?.raw ?? null)) : setState(f);
  }, [setState]);

  let data, initial;
  if (utils.isDefined(state?.data) || !utils.isDefined(init)) {
    data = state?.data;
  } else {
    data = utils.isFunction(init) ? init(null) : init;
    initial = data;
  }

  useLayoutEffect(() => {
    if (initial) {
      stateFn(initial);
    }
  }, [stateFn, initial]);

  useLayoutEffect(() => {
    if (type === constants.appState.type.none && stateRef.current.key !== key) {
      stateFn(initial);
    }
    stateRef.current = {
      type: type,
      key: key
    }
  }, [stateFn, type, key, initial]);

  return [data, stateFn];
}

export function useAppSetState (type, key, scope) {
  const [,setState] = useAppState(type, key, scope);

  return setState;
}

export function useAppStateValue (type, key, scope) {
  const [state] = useAppState(type, key, scope);

  return state;
}
