import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import BarChart from 'components/organisms/Charts/BarChart/BarChart';
import Legend from 'components/molecules/Charts/Legend/Legend';
import Box from 'components/atoms/Layout/Box/Box';
import StyledRecentChangesBarChart
  from 'components/organisms/Charts/RecentChangesBarChart/RecentChangesBarChart.styles';
import BreakdownTooltip from 'components/molecules/Tooltips/BreakdownTooltip/BreakdownTooltip';

const RecentChangesBarChart = React.forwardRef((props, ref) => {
  const {
    changes,
    fields,
    showLegend,
    isLoading,
    visibility,
    onVisibilityChange,
    onClick,
    BarChartProps,
    ...innerProps
  } = useComponentProps(props, 'RecentChangesBarChart', {
    children: ['chart', 'legend']
  });

  const [internalState, setInternalState] = useState({});

  const [changesMemo, usersMemo] = useMemo(() => {
    if (fields && changes) {
      let users = [];
      const flds = [];

      fields.forEach((field, idx) => {
        changes.forEach((c) => {
          let fld = flds.find((f) => f.id === field.value);
          if (!fld) {
            fld = {
              id: field.value,
              name: field.value,
              label: field.label,
              position: idx,
              values: []
            }
            flds.push(fld);
          }

          let val = fld.values.find((v) => +v.user.userId === +c.user.userId);
          if (!val) {
            const avatar = `${utils.personName(c.user.firstName, c.user.lastName)} (${c.user.username})`;
            const name = `${utils.personName(c.user.firstName, c.user.lastName)}`;
            const color = utils.string2Color(name);
            val = {
              user: c.user,
              name: name,
              avatar: avatar,
              color: color,
              count: 0
            }
            fld.values.push(val);

            if (!users.find((u) => +u.user.userId === +c.user.userId)) {
              users.push({
                name: name,
                avatar: avatar,
                color: color,
                user: c.user
              });
            }
          }

          val.count += +c[field.value];
        });
      });

      users = users
        .sort((a, b) => {
          return a.name.localeCompare(b.name) ? a.name.localeCompare(b.name) : +a.user.userId - +b.user.userId;
        })
        .map((u, idx) => {
          u.position = idx;
          return u;
        });

      return [flds, users];
    } else {
      return [[], []];
    }
  }, [fields, changes]);

  const [legend, rows] = useMemo(() => {
    let legend = [];
    if (usersMemo) {
      legend = usersMemo
        .map((u) => {
          return {
            id: u.user.userId,
            position: u.position,
            label: u.name,
            avatar: u.avatar,
            avatarTooltip: u.avatar,
            ActionAvatarProps: {
              TooltipProps: {enterDelay: 0}
            },
            color: u.color,
            active: visibility?.[u.user.userId] ?? true,
            meta: u,
          }
        });
    }

    const rows = Math.ceil(legend.length / 6);

    return [legend, rows];
  }, [usersMemo, visibility]);

  const changesData = useMemo(() => {
    if (changesMemo) {
      return {
        dataKey: 'name',
        layout: 'vertical',
        bars: usersMemo
          .map((u) => {
            return {
              id: u.user.userId,
              position: u.position,
              dataKey: u.user.userId,
              name: u.user.userId,
              label: u.avatar,
              color: u.color
            }
          })
          .filter((u) => {
            return Boolean(legend.find((l) => l.active && +l.id === +u.id))
          }),
        data: changesMemo
          .reduce((a, field) => {
            let item = a.find((i) => i.field === field.id);
            if (!item) {
              item = {
                field: field.id,
                name: field.label,
                position: field.position
              };
              a.push(item);
            }

            field.values.forEach((v) => {
              if (Boolean(legend.find((l) => l.active && +l.id === +v.user.userId))) {
                item[v.user.userId] = v.count;
                item[`${v.user.userId}-meta`] = {
                  ...v,
                  label: v.name,
                  barLabel: utils.avatarLabel(v.avatar),
                  countLabel: `${v.count} companies`,
                  avatarColor: v.color
                }
              }
            });

            return a;
          }, []),
        TooltipComponent: BreakdownTooltip
      };
    }
  }, [changesMemo, usersMemo, legend]);

  const handleLegendEnter = (e, item) => {
    if (item.active) {
      setInternalState(utils.updater({hoveredId: item.id}, true));
    }
  }

  const handleLegendLeave = () => {
    setInternalState(utils.updater({hoveredId: null}, true));
  }

  const handleLegendClick = (e, item) => {
    const newVisibility = {
      ...visibility,
      [item.id]: !(visibility?.[item.id] ?? true)
    }

    if (!legend.find((l) => newVisibility[l.id] ?? true)) {
      onVisibilityChange?.({});
    } else {
      onVisibilityChange?.(newVisibility);
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledRecentChangesBarChart ref={ref} {...innerProps}>
    <Box className="RecentChangesBarChart-chart">
      <BarChart {...changesData}
                showGrid
                showTooltip
                showCursor
                showLabels
                isLoading={isLoading}
                radius={4}
                barSize={40}
                layout="horizontal"
                hoveredId={internalState.hoveredId}
                onClick={onClick}
                TooltipProps={{
                  density: 'sparse',
                  placement: 'right'
                }}
                margin={{
                  top: 0, right: 0, bottom: 4, left: -22
                }}
                {...BarChartProps}/>
    </Box>
    {showLegend ? <Legend className="RecentChangesBarChart-legend"
                          onClick={onVisibilityChange ? handleLegendClick : null}
                          onMouseEnter={handleLegendEnter}
                          onMouseLeave={handleLegendLeave}
                          legend={legend}
                          isLoading={isLoading}
                          rows={rows}/> : null}
  </StyledRecentChangesBarChart>
});

RecentChangesBarChart.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  changes: PropTypes.array,
  fields: PropTypes.array,
  showLegend: PropTypes.bool,
  isLoading: PropTypes.bool,
  visibility: PropTypes.object,
  onVisibilityChange: PropTypes.func,
  onClick: PropTypes.func,
  BarChartProps: PropTypes.object
};

RecentChangesBarChart.defaultProps = {
};

export default RecentChangesBarChart;
