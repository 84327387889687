import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Paper from 'components/atoms/Papers/Paper/Paper';

const StyledAutocompleteFieldPaper = styled(Paper)`
  .MuiAutocomplete-listbox {
    padding: 0;
    
    .MuiAutocomplete-option:not(:last-child) {
      border-bottom: 1px solid ${props => props.theme.property('palette.divider')};
    }
  }

  > div {
    padding: ${props => props.theme.layout(6)} ${props => props.theme.layout(16)};
  }
  
  &.AutocompleteFieldPaper-density-dense {
    .MenuItem {
      padding: ${props => props.theme.layout(5)} ${props => props.theme.layout(14)};
    }
    > div {
      padding: ${props => props.theme.layout(5)} ${props => props.theme.layout(14)};
    }
  }

  &.AutocompleteFieldPaper-density-denser {
    .MenuItem {
      padding: ${props => props.theme.layout(3)} ${props => props.theme.layout(12)};
    }
    > div {
      padding: ${props => props.theme.layout(3)} ${props => props.theme.layout(12)};
    }
  }
  
  &.AutocompleteFieldPaper-size-large {
    .MuiAutocomplete-listbox {
      max-height: min(${props => props.theme.layout(480)}, 52vh) !important;
    }
  }
  &.AutocompleteFieldPaper-size-medium {
    .MuiAutocomplete-listbox {
      max-height: min(${props => props.theme.layout(300)}, 48vh) !important;
    }
  }
  &.AutocompleteFieldPaper-size-small {
    .MuiAutocomplete-listbox {
      max-height: min(${props => props.theme.layout(260)}, 40vh) !important;
    }
  }
  
  ${ComponentStyles}
`;

StyledAutocompleteFieldPaper.propTypes = {
  theme: PropTypes.object
};

StyledAutocompleteFieldPaper.defaultProps = {
}

export default StyledAutocompleteFieldPaper;
