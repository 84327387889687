import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import Component from 'components/organisms/Utils/Component/Component';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import Typography, {H6, P} from 'components/atoms/Text/Typography/Typography';
import Toolbar from 'components/atoms/Toolbars/Toolbar/Toolbar';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Close from '@mui/icons-material/Close';
import Search from '@mui/icons-material/Search';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import Clear from '@mui/icons-material/Clear';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import Fab from 'components/atoms/Fabs/Fab/Fab';
import TableColumnsContent from 'components/organisms/TableColumns/TableColumnsContent/TableColumnsContent';
import StyledTableColumns from 'components/organisms/TableColumns/TableColumns/TableColumns.styles';
import ListItem from 'components/atoms/Lists/ListItem/ListItem';
import List from 'components/atoms/Lists/List/List';
import Button from 'components/atoms/Buttons/Button/Button';
import Restore from '@mui/icons-material/Restore';
import constants from 'helpers/constants';

const TableColumns = React.forwardRef((props, ref) => {
  const {
    columnPresets,
    columnGroups,
    activeColumns,
    onClose,
    onChange,
    onReset,
    onSubmit,
    ...innerProps
  } = useComponentProps(props, 'TableColumns', {
    children: ['toolbar', 'presets', 'groups']
  });

  const [internalState, setInternalState] = useState({
    searchValue: '',
    activeColumns: {}
  });

  const tableColumns = useMemo(() => ({
    state: {
      ...internalState
    }
  }), [internalState]);

  const [columnGroupsMemo, columnPresetsMemo] = useMemo(() => {
    if (columnGroups && columnPresets) {
      const groups = columnGroups
        .map((cg) => {
          const groupMatch = tableColumns.state.searchValue &&
            cg.title.toLowerCase().includes(tableColumns.state.searchValue.toLowerCase());

          const columns = cg.columns
            .filter((f) => !tableColumns.state.searchValue || groupMatch ||
              f.label.toLowerCase().includes(tableColumns.state.searchValue.toLowerCase()))
            .map((c) => ({
              ...c,
              active: Boolean(tableColumns.state.activeColumns[c.id] ?? true)
            }))
            .sort((a, b) => a.position - b.position);

          return {
            ...cg,
            columns
          }
        })
        .filter((cg) => cg.columns.length > 0)
        .sort((a, b) => a.position - b.position);

      const presets = columnPresets
        .filter((cg) => cg.columns.length > 0)
        .sort((a, b) => a.position - b.position);

      return [groups, presets];
    } else {
      return [null, null];
    }
  }, [columnGroups, columnPresets, tableColumns.state.activeColumns, tableColumns.state.searchValue]);

  const hasColumns = useMemo(() => {
    return columnGroupsMemo?.length > 0;
  }, [columnGroupsMemo]);

  const hasPresets = useMemo(() => {
    return columnPresetsMemo?.length > 0;
  }, [columnPresetsMemo]);

  useLayoutEffect(() => {
    setInternalState(utils.updater({activeColumns}, true));
  }, [activeColumns]);

  const onChangeEvent = useEffectEvent(onChange);
  useEffect(() => {
    onChangeEvent?.(tableColumns.state.activeColumns);
  }, [onChangeEvent, tableColumns.state.activeColumns]);

  const onCloseEvent = useEffectEvent(onClose);
  const closeAction = useMemo(() => ({
    label: 'Close',
    tooltip: 'Close',
    icon: Close,
    onClick: (e) => {
      onCloseEvent?.(e, 'closeButtonClick');
    }
  }), [onCloseEvent]);

  const onResetEvent = useEffectEvent(onReset);
  const resetAction = useMemo(() => ({
    label: 'Reset',
    tooltip: 'Reset columns',
    icon: Restore,
    onClick: (e) => {
      onResetEvent?.(e);
    }
  }), [onResetEvent]);

  const searchFieldRef = useRef(null);
  const internalSearchField = useMemo(() => ([{
    name: 'search',
    label: 'Search',
    inlineLabel: 'search',
    type: constants.formFieldTypes.text,
    validation: constants.formFieldValidationTypes.text,
    initial: tableColumns.state.searchValue,
    debounce: constants.debounce.search,
    FormFieldProps: {
      ref: searchFieldRef,
      hiddenLabel: true,
      size: 'smaller'
    },
    prefix: <Icon icon={Search} />,
    postfix: tableColumns.state.searchValue?.length > 0 ? <ActionIconButton action={{
      icon: <Icon icon={Clear}/>,
      onClick: (e) => {
        setInternalState(utils.updater({
          searchValue: ''
        }, true));
        searchFieldRef.current?.focus();
        e.preventDefault();
      }
    }} /> : null
  }]), [tableColumns.state.searchValue]);

  const handleChange = (changedColumn, value) => {
    setInternalState((current) => {
      const activeColumns = {
        ...current.activeColumns,
        [changedColumn.id]: value
      }

      return {
        ...current,
        activeColumns: activeColumns
      }
    });
  };

  const handlePresetClick = (preset) => () => {
    const activeColumns = {};
    columnGroups.forEach((cg) => {
      cg.columns.forEach((c) => {
        activeColumns[c.id] = Boolean(preset.columns.find((pc) => pc === c.name));
      });
    });

    setInternalState((current) => {
      return {
        ...current,
        activeColumns: activeColumns
      }
    });

    onChange?.(activeColumns);
  }

  const handleSubmit = () => {
    onSubmit?.(internalState.activeColumns);
  }

  const handleSearch = (field, value) => {
    setInternalState(utils.updater({
      searchValue: value
    }, true));
  }

  const renderColumnGroup = (columnGroup, idx) => {
    const renderedColumnGroup = <TableColumnsContent key={idx}
                                                     className="TableColumns-group"
                                                     tableColumns={tableColumns}
                                                     columnGroup={columnGroup}
                                                     onChange={handleChange} />

    if (columnGroup.Group) {
      return <Component key={idx} 
                        Original={columnGroup.Group}
                        className="TableColumns-group"
                        tableColumns={tableColumns}
                        columnGroup={columnGroup}
                        onChange={handleChange}
                        renderedColumnGroup={renderedColumnGroup} />
    } else {
      return renderedColumnGroup;
    }
  }

  return <StyledTableColumns ref={ref} {...innerProps}>
    <Toolbar className="TableColumns-toolbar">
      <Box className="TableColumns-toolbar-title">
        <H6>Columns</H6>
      </Box>
      <ActionIconButton size="smaller"
                        density="sparse"
                        variant="outlined"
                        className="TableColumns-toolbar-close"
                        IconProps={{
                          size: 'smaller'
                        }}
                        action={resetAction} />
      <ActionIconButton size="smaller"
                        density="sparse"
                        variant="outlined"
                        className="TableColumns-toolbar-close"
                        IconProps={{
                          size: 'smaller'
                        }}
                        action={closeAction} />
    </Toolbar>
    <Box className="TableColumns-content">
      <InlineForm className="TableColumns-search"
                  onChange={handleSearch}
                  fields={internalSearchField} />

      {!hasColumns ? <Box className="TableColumns-empty">
        <P>No columns found</P>
      </Box> : null}

      <Box className="TableColumns-groups">
        {(hasColumns && hasPresets) ? <Box className="TableColumns-presets">
          <Typography variant="subtitle2">Available presets</Typography>
          {columnPresetsMemo.map((preset, idx) => {
            return <Button key={idx} variant="text" onClick={handlePresetClick(preset)}>
              {preset.label}
            </Button>
          })}
        </Box> : null}
        <List>
          {columnGroupsMemo?.map((columnGroup, idx) => {
            return <ListItem key={idx}
                             className="TableColumns-group">
              {renderColumnGroup(columnGroup, idx)}
            </ListItem>
          })}
        </List>
      </Box>

      <Box className="TableColumns-save">
        <Fab variant="extended"
             size="large"
             color="primary"
             onClick={handleSubmit}>
          SAVE CHANGES
        </Fab>
      </Box>
    </Box>
  </StyledTableColumns>
});

TableColumns.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  columnPresets: PropTypes.array,
  columnGroups: PropTypes.array,
  activeColumns: PropTypes.object,
  onClose: PropTypes.func,
  onReset: PropTypes.func,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func
};

TableColumns.defaultProps = {};

export default TableColumns;
