import React, {useLayoutEffect, useMemo} from 'react'
import TableProvider, {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import constants from 'helpers/constants';
import {useParams} from 'react-router-dom';
import {useEntityApplyActionsBulk, useEntityGet, useEntityList} from 'services/entity/entity.hooks';
import PropTypes from 'prop-types';
import {
  useEffectEvent, useEffectItem,
  useMergeColumnDefinitions,
  useMergeFieldData,
  useMergeFilterGroupDefinitions,
  useMergeGraphDefinitions,
  useMergePanelDefinitions,
  useMergeView,
  useProviderView
} from 'helpers/hooks/utils';
import {
  useEntityColumns, useEntityFilterGroups, useEntityGraphs, useEntityPanels,
  useEntityPatch,
  useEntityCallbacks, useEntitySelected
} from 'services/entity/entity.utils';
import {useEntityTagPatch} from 'services/entity/tag/tag.utils';
import {useEntityTagGroupPatch} from 'services/entity/tagGroup/tagGroup.utils';
import {useEntityCsiPatch} from 'services/entity/csi/csi.utils';
import utils from 'helpers/utils';
import {useExportAdd} from 'services/export/export.hooks';
import {useAuthClientId} from 'services/auth/auth.utils';
import {useClientGet} from 'services/client/client.hooks';
import {useEntityTaskCreate} from 'services/entity/comment/comment.utils';
import {useCollectionEntityAssign, useCollectionEntityUnAssign} from 'services/collection/entity/entity.hooks';
import {useClientCustomFieldCache} from 'services/client/customField/customField.hooks';
import {useCollectionGet} from 'services/collection/collection.hooks';
import useMediaQuery from '@mui/material/useMediaQuery';

const EntitiesTableLoader = ({children, active}) => {
  const tableProvider = useTable();
  const listState = useEffectItem(tableProvider.appliedListState());

  const list = useEntityList({
    clientId: tableProvider.context?.data?.clientId,
    search: listState.search,
    filter: listState.filter,
    sort: listState.sort,
    page: listState.pagination.pageIndex,
    pageSize: listState.pagination.pageSize
  }, {
    ...constants.queryOptions.infinite,
    enablePreload: true,
    enabled: (active && listState.active) && tableProvider.context?.data?.clientId >= 0
  });

  const setListEvent = useEffectEvent(tableProvider.setList);
  useLayoutEffect(() => {
    setListEvent?.(list);
  }, [setListEvent, list]);

  return <React.Fragment>
    {children}
  </React.Fragment>
}

const EntitiesTableTestLoader = ({children, active}) => {
  const tableProvider = useTable();
  const listState = tableProvider.appliedListState('test');

  const list = useEntityList({
    clientId: tableProvider.context?.data?.clientId,
    search: listState.search,
    filter: listState.filter,
    sort: listState.sort,
    page: listState.pagination.pageIndex,
    pageSize: listState.pagination.pageSize
  }, {
    ...constants.queryOptions.runOnce,
    enabled: (active && listState.active) && tableProvider.context?.data?.clientId >= 0
  });

  const setTestListEvent = useEffectEvent(tableProvider.setTestList);
  useLayoutEffect(() => {
    setTestListEvent?.(list);
  }, [setTestListEvent, list]);

  return <React.Fragment>
    {children}
  </React.Fragment>
}

const EntitiesTableProvider = (props) => {
  const params = useParams();
  const { entityId } = {...params, ...props};

  const clientId = useAuthClientId();

  const dataKey = props.dataKey ?? 'entityId';
  const entityView = useProviderView('entity', props.skipView);
  const view = useMergeView(entityView, props.view);

  const client = useClientGet({clientId}, {enabled: Boolean(!props.context && clientId >= 0)});
  const entity = useEntityGet({entityId}, {enabled: Boolean(!props.data && +entityId > 0)});

  const customFields = useClientCustomFieldCache({clientId: client?.data?.clientId},
    {enabled: client?.data?.clientId >= 0});
  const customFieldsCache = (customFields.data?.data ?? customFields.data);
  const collection = useCollectionGet({collectionId: client?.data?.universeCollectionId},
    {enabled: Boolean(client?.data?.universeCollectionId)});

  const columns = useEntityColumns(view, collection?.data, customFieldsCache);
  const filterGroups = useEntityFilterGroups(view, collection?.data, customFieldsCache, client?.data?.clientId);
  const panels = useEntityPanels(view, client?.data);
  const graphs = useEntityGraphs(view);

  const isLoading = props?.isLoading || client.status?.isLoading || customFields.status?.isLoading || collection.status?.isLoading;
  const isDefinitionsLoading = props?.isDefinitionsLoading || !customFields.status?.isSuccess ||
    !client.status?.isSuccess || !collection.status?.isSuccess;

  const entityProfileCallbacks = useEntityCallbacks();

  const entityFieldData = useMemo(() => ({
    currency: constants.data.lookup('currencies', client.data?.props?.currency),
    customFields: customFieldsCache ?? [],
    tagGroups: collection?.data?.tagGroups,
    callbacks: entityProfileCallbacks
  }), [customFieldsCache, collection?.data?.tagGroups, entityProfileCallbacks, client.data?.props?.currency]);

  const fieldData = useMergeFieldData(entityFieldData, props.fieldData);
  const columnDefinitions = useMergeColumnDefinitions(columns, props.options?.columnDefinitions);
  const filterGroupDefinitions = useMergeFilterGroupDefinitions(filterGroups, props.options?.filterGroupDefinitions);
  const panelDefinitions = useMergePanelDefinitions(panels, props.options?.panelDefinitions);
  const graphDefinitions = useMergeGraphDefinitions(graphs, props.options?.graphDefinitions);

  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const options = useMemo(() => {
    const options = {
      listState: {
        initial: {
          pageSize: 25
        },
        options: {
          type: constants.appState.type.local,
          name: 'entities',
          searchParams: true
        }
      },
      testListState: {
        initial: {
          active: false,
          pageSize: 0
        },
        options: {
          name: `entities_test`
        }
      },
      listSelection: {
        options: {}
      },
      columnState: {
        initial: {},
        options: {
          name: 'entities',
          type: constants.appState.type.local
        }
      },
      kanbanState: {
        initial: {},
        options: {
          name: `entities`,
          type: constants.appState.type.local
        }
      }
    };

    return {
      ...(utils.mergeObjects(options, props.options)),
      columnDefinitions: !smDown ? columnDefinitions :
        (columnDefinitions || [])
          .map((columnDef) => ({
            ...columnDef,
            visible: ['name'].includes(columnDef.name),
            pinned: false
          })),
      filterGroupDefinitions,
      panelDefinitions,
      graphDefinitions
    }
  }, [props.options, columnDefinitions, panelDefinitions, graphDefinitions, filterGroupDefinitions, smDown]);

  const handleCompare = (itmA, itmB) => {
    return (utils.isDefined(itmA.entityId) && utils.isDefined(itmB.entityId) && +itmA.entityId === +itmB.entityId) ||
      (utils.isDefined(itmA.originalEntityId) && utils.isDefined(itmB.originalEntityId) && +itmA.originalEntityId === +itmB.originalEntityId) ||
      (utils.isDefined(itmA.stackId) && utils.isDefined(itmB.stackId) && +itmA.stackId === +itmB.stackId);
  }

  const update = useEntityPatch();
  const updateTag = useEntityTagPatch();
  const updateTagGroup = useEntityTagGroupPatch();
  const downloadItems = useExportAdd();
  const updateCsi = useEntityCsiPatch();
  const createTask = useEntityTaskCreate();
  const assignItems = useCollectionEntityAssign();
  const unAssignItems = useCollectionEntityUnAssign();
  const acceptItems = useCollectionEntityAssign({deleteItem: true});
  const applyActions = useEntityApplyActionsBulk();

  const selectedItems = useEntitySelected(clientId);
  const updaters = useMemo(() => {
    return {
      updateData: update,
      updateTag: updateTag,
      updateTagGroup: updateTagGroup,
      updateCsi: updateCsi,
      downloadItems: (data) => {
        return downloadItems.mutation.mutateAsync(data);
      },
      assignItems: (data) => {
        return assignItems.mutation.mutateAsync(data);
      },
      unAssignItems: (data) => {
        return unAssignItems.mutation.mutateAsync(data);
      },
      acceptItems: (data) => {
        return acceptItems.mutation.mutateAsync(data);
      },
      createTask: (entities, data) => {
        return createTask(entities, null, data);
      },
      applyActions: (data) => {
        return applyActions.mutation.mutateAsync(data);
      },
      selectedItems: selectedItems,
      ...props.updaters
    }
  }, [update, updateTag, updateTagGroup, downloadItems.mutation, updateCsi, applyActions.mutation,
    assignItems.mutation, unAssignItems.mutation, acceptItems.mutation, createTask, selectedItems, props.updaters]);

  return <TableProvider {...props}
                        view={view}
                        options={options}
                        fieldData={fieldData}
                        dataKey={dataKey}
                        data={props.data ?? entity}
                        context={props.context ?? client}
                        updaters={updaters}
                        LoaderComponent={props.LoaderComponent ?? EntitiesTableLoader}
                        TestLoaderComponent={props.TestLoaderComponent ?? EntitiesTableTestLoader}
                        isLoading={isLoading}
                        isDefinitionsLoading={isDefinitionsLoading}
                        onCompare={props.onCompare ?? handleCompare}>
    {props.children}
  </TableProvider>
};

EntitiesTableProvider.propTypes = {
  view: PropTypes.object,
  data: PropTypes.object,
  context: PropTypes.object,
  options: PropTypes.object,
  fieldData: PropTypes.object,
  updaters: PropTypes.object,
  onCompare: PropTypes.func,
  LoaderComponent: PropTypes.any,
  TestLoaderComponent: PropTypes.any,
  isDefinitionsLoading: PropTypes.bool,
  isLoading: PropTypes.bool
}

export default EntitiesTableProvider;
