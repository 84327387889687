import React, {useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useBbox, useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import Typography, {Span} from 'components/atoms/Text/Typography/Typography';
import Box from 'components/atoms/Layout/Box/Box';
import utils from 'helpers/utils';
import Link from 'components/atoms/Links/Link/Link';
import Icon from 'components/atoms/Icons/Icon/Icon';
import {PersonOutlined} from '@mui/icons-material';
import StyledPersonCard from 'components/molecules/Cards/PersonCard/PersonCard.styles';
import Logo from 'components/atoms/Logos/Logo/Logo';
import {LinkedIn} from 'assets/icons';
import Markdown from 'components/atoms/Formatters/Markdown/Markdown';
import Search from '@mui/icons-material/Search';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import CircularProgress from 'components/atoms/Progress/CircularProgress/CircularProgress';
import PhoneInTalk from '@mui/icons-material/PhoneInTalk';
import Email from '@mui/icons-material/Email';

const PersonCard = React.forwardRef((props, ref) => {
  const {
    person,
    entity,
    onClick,
    onEnrich,
    onCanEnrich,
    onEnriching,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'PersonCard', {
    static: ['isLoading', 'clampText']
  });

  const innerRef = useRef(null);
  const updateRef = useRef({});

  useImperativeHandle(ref, () => innerRef.current);

  const contentBox = useBbox(() => innerRef.current?.querySelector('.PersonCard-text'), ['clamped']);

  const [clampText, setClampText] = useState(true);
  const [clampActive, setClampActive] = useState(false);

  useEffect(() => {
    if (clampText) {
      setClampActive(contentBox?.clamped);
    }
  }, [contentBox?.clamped, clampText]);

  let enrichingTypes = ['email', 'phone'].reduce((o, type) => {
    o[type] = {
      time: person?.enrichmentStats?.[`${type}UpdatedAt`],
      enriching: onEnriching?.(person, type) ||
        person?.enrichmentStats?.[`${type}Status`] === 'processing'
    };
    return o;
  }, {});

  const onEnrichEvent = useEffectEvent(onEnrich);
  const onCanEnrichEvent = useEffectEvent(onCanEnrich);
  const [enrichEmailAction, enrichPhoneAction] = useMemo(() => {
    return ['email', 'phone'].map((type) => {
      const isEnriching = enrichingTypes[type]?.enriching ||
        `${enrichingTypes[type].time}` === updateRef.current[type];
      // catch person add before enrich started / finished
      updateRef.current[type] = isEnriching ? (updateRef.current[type] ?? `${enrichingTypes[type].time}`) : null;

      const canEnrich = (onCanEnrichEvent ? onCanEnrichEvent?.(person) : true) &&
        (type !== 'email' || !person?.email) && (type !== 'phone' || !person?.phone);

      let disabledReason, canRetry = false;
      if (canEnrich) {
        if (!person?.linkedin && !person?.email && !(person.name && (entity?.name || utils.getHostname(entity?.website)))) {
          disabledReason = 'Please add name, linkedin or email';
        } else {
          if (person?.enrichmentStats?.[`${type}UpdatedAt`]) {
            const hasChanged = ((person?.enrichmentStats?.linkedin ?? '') !== (person?.linkedin ?? '')) ||
              ((person?.enrichmentStats?.email ?? '') !== (person?.email ?? '')) ||
              ((person?.enrichmentStats?.name ?? '') !== (person?.name ?? '')) ||
              ((person?.enrichmentStats?.entityName ?? '') !== (entity?.name ?? '')) ||
              ((person?.enrichmentStats?.entityHostname ?? '') !== (utils.getHostname(entity?.website) ?? ''));

            const updatedAt = new Date(person?.enrichmentStats?.[`${type}UpdatedAt`]);

            let retryTime = new Date(updatedAt.getTime());
            if (hasChanged) {
              retryTime = new Date(retryTime.getTime());
            } else if (person?.enrichmentStats?.[`${type}Status`] === 'processing') {
              retryTime = new Date(retryTime.getTime() + 15 * 60 * 1000);
            } else {
              retryTime = new Date(retryTime.getTime() + 60 * 60 * 1000);
            }

            if ((new Date()).getTime() < retryTime.getTime()) {
              if (person?.enrichmentStats?.[`${type}Status`] === 'success') {
                disabledReason = `${utils.upperFirst(type)} info was incomplete. Possibly retry ${utils.timeAgo(retryTime)}`;
              } else if (person?.enrichmentStats?.[`${type}Status`] === 'processing') {
                disabledReason = `${utils.upperFirst(type)} info is processing. Possibly retry ${utils.timeAgo(retryTime)}`;
              } else {
                disabledReason = `${utils.upperFirst(type)} info was not found. Possibly retry ${utils.timeAgo(retryTime)}`;
              }
            } else {
              canRetry = true;
            }
          }
        }
      }

      return {
        label: `Show ${type}`,
        tooltip: disabledReason,
        icon: (canRetry || !isEnriching) ? Search : <CircularProgress />,
        auth: !canEnrich ? utils.createAuth({attribute: 'system.null'}) : null,
        onClick: (e) => {
          updateRef.current[type] = `${enrichingTypes[type].time}`;
          onEnrichEvent?.(person, type);
          e.stopPropagation();
        },
        TooltipProps: {
          enterDelay: 0
        },
        ButtonProps: {
          size: 'smallest',
          variant: 'outlined',
          color: 'primary',
          disabled: !canRetry && (Boolean(disabledReason) || isEnriching)
        }
      }
    });
  }, [person, entity?.name, entity?.website, enrichingTypes, onCanEnrichEvent, onEnrichEvent]);

  const renderName = () => {
    return <Box className="PersonCard-name">
      <Typography showTooltip={true}
                  isLoading={isLoading}
                  variant="subtitle1">
        {person?.name}
      </Typography>
    </Box>
  }

  const renderRole = () => {
    const role = person?.role || person?.title;
    if (role) {
      return <Box className="PersonCard-role">
        <Typography showTooltip={true}
                    isLoading={isLoading}
                    variant="subtitle2">
          {role || 'Role not specified'}
        </Typography>
      </Box>
    }
  }

  const renderContactInfo = () => {
    return <Box className="PersonCard-contact">
      <ActionButton className="PersonCard-enrich"
                    isLoading={isLoading}
                    action={enrichEmailAction}/>
      <ActionButton className="PersonCard-enrich"
                    isLoading={isLoading}
                    action={enrichPhoneAction}/>
      {person.email ? <Typography className="PersonCard-email"
                                  isLoading={isLoading}
                                  variant="caption"
                                  color="text.secondary">
        <Icon size="smaller" icon={Email}/>
        <Span showTooltip={true}>
          <Link href={`mailto:${person?.email}`}
                onClick={(e) => e.stopPropagation()}>
            {person?.email}
          </Link>
        </Span>
      </Typography> : null}
      {person?.phone ? <Typography className="PersonCard-phone"
                                   isLoading={isLoading}
                                   variant="caption"
                                   color="text.secondary">
        <Icon size="smaller" icon={PhoneInTalk}/>
        <Span showTooltip={true}>
          <Link href={`tel:${utils.phone2String(person?.phone, entity?.location?.country)}`}
                onClick={(e) => e.stopPropagation()}>
            {person?.phone}
          </Link>
        </Span>
      </Typography> : null}
      {!person?.description ? renderLinkedin() : null}
    </Box>
  }

  const renderText = () => {
    if (person?.description) {
      return <Box className="PersonCard-text-wrapper">
        <Box className="PersonCard-text">
          <Typography as="div" isLoading={isLoading} variant="body2">
            <Markdown disabled={clampText}>{person?.description}</Markdown>
          </Typography>
        </Box>
        {clampActive ? <Link className="readMore"
                             isLoading={isLoading}
                             onClick={(e) => {
                               setClampText((current) => !current);
                               e.stopPropagation();
                               e.preventDefault();
                             }}>
          Show {clampText ? 'more' : 'less'}
        </Link> : null}
      </Box>
    } else if (isLoading) {
      return <Box className="PersonCard-text-wrapper">
        <Box className="PersonCard-text">
          <Typography isLoading={isLoading} variant="body2" min={16} max={32}/>
        </Box>
      </Box>
    }
  }

  const renderLinkedin = () => {
    if (person?.linkedin) {
      const link = `https://www.linkedin.com/in/${person.linkedin}`;

      return <Typography className="PersonCard-linkedin"
                         isLoading={isLoading}
                         variant="caption"
                         color="text.secondary">
        <Icon size="smaller" icon={LinkedIn}/>
        <Span showTooltip={true}>
          <Link href={utils.cleanExternalLink(link)}
                onClick={(e) => e.stopPropagation()}
                target="_blank">{person?.linkedin}</Link>
        </Span>
      </Typography>
    }
  }

  const handleClick = (e) => {
    onClick?.(e, person);
    if (!e.defaultPrevented) {
      innerRef.current?.querySelector?.('.PersonCard-linkedin .Link')?.click();
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className, {
    clampText: clampText
  });

  return <StyledPersonCard ref={innerRef} {...innerProps}
                           isLoading={isLoading}
                           variant="outlined"
                           onClick={handleClick}>
    <Box className="PersonCard-header">
      <Box className="PersonCard-img">
        <Logo density="densest"
              size="small"
              outlined={false}
              logo={person?.img}
              fallbackIcon={PersonOutlined}
              isLoading={isLoading} />
      </Box>
      <Box className="PersonCard-content">
        {renderName()}
        {renderRole()}
      </Box>
    </Box>

    {renderContactInfo()}
    {renderText()}

    {!isLoading ? <Box className="PersonCard-footer">
      {person?.description ? renderLinkedin() : null}
    </Box> : null}
  </StyledPersonCard>
});

PersonCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  person: PropTypes.object,
  entity: PropTypes.object,
  onClick: PropTypes.func,
  onEnrich: PropTypes.func,
  onCanEnrich: PropTypes.func,
  onEnriching: PropTypes.func,
  isLoading: PropTypes.bool
};

PersonCard.defaultProps = {
};

export default PersonCard;
