import React, {useCallback, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {useFormik} from 'formik';
import Button from 'components/atoms/Buttons/Button/Button';
import {useComponentProps, useEffectEvent, useEffectItem, useUpdatedRef} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import FormField from 'components/organisms/Fields/FormField/FormField';
import {H5, H6, Span} from 'components/atoms/Text/Typography/Typography';
import Box from 'components/atoms/Layout/Box/Box';
import StyledForm from 'components/organisms/Forms/Form/Form.styles';
import PropTypes from 'prop-types';
import Icon from 'components/atoms/Icons/Icon/Icon';
import SimpleLoader from 'components/molecules/Loaders/SimpleLoader/SimpleLoader';
import constants from 'helpers/constants';
import dom from 'helpers/dom';
import InfoPaper from 'components/molecules/Papers/InfoPaper/InfoPaper';
import Markdown from 'components/atoms/Formatters/Markdown/Markdown';

export const FormContext = React.createContext(null)

export function useForm () {
  return React.useContext(FormContext);
}

const Form = React.forwardRef((props, ref) => {
  const {
    pages,
    fieldData,
    showButtons,
    showLoader,
    disclaimer,
    readOnly,
    autoTouch,
    autoFocus,
    isLoading,
    error,
    success,
    onChange,
    onChangeDirect,
    onBlur,
    onValidating,
    onNext,
    onPrev,
    onReset,
    onSubmit,
    onKeyDown,
    renderContent,
    PrevButtonProps,
    NextButtonProps,
    SubmitButtonProps,
    ...innerProps
  } = useComponentProps(props, 'Form', {
    children: ['page-title', 'section', 'fields', 'footer', 'loader', 'error', 'success']
  });

  const innerRef = useRef(null);
  const fieldRefs = useRef({});
  const activeFieldRef = useRef(null);
  const activeTimeRef = useRef(null);
  const changeRef = useRef(false);
  const validatingRef = useRef(false);
  const initValuesRef = useRef(null);
  const [internalState, setInternalState] = useState({
    pageIndex: 0,
    validationSchema: null
  });

  const parentForm = useForm();

  const pagesMemo = useEffectItem(pages);
  const fieldDataMemo = useEffectItem(fieldData);
  const formSettings = useMemo(() => {
    if (pagesMemo) {
      const pgs = utils.object2Array(pagesMemo, 'id');
      const fields = pgs.reduce((a, page) => {
        const flds = utils.object2Array(page.fields);
        flds.forEach((f) => f.pageId = page.id);

        return a.concat(flds);
      }, []);

      const ffs = utils.fields2FormFields(fields, fieldDataMemo);
      const initialValues = utils.fields2InitialValues(ffs.filter((f) => !f.section), false);

      if (initValuesRef.current) {
        initValuesRef.current.changes = Object.keys(initialValues).filter((k) => {
          return !utils.compare(initialValues[k], initValuesRef.current.initialValues[k])
        }).reduce((o, k) => {
          o[k] = initialValues[k];
          return o;
        }, {});
      } else {
        initValuesRef.current = { changes: {} };
      }
      initValuesRef.current.initialValues = initialValues;

      return {
        pages: pgs,
        fields: ffs,
        initialValues: initialValues
      }
    }
  }, [pagesMemo, fieldDataMemo]);

  const isValidRelation = useCallback((relation, values) => {
    relation = relation ? relation.split('(').map((r) => (r.endsWith(')') ? r.slice(0, -1) : r)) : null;

    if (relation) {
      const relatedField = formSettings?.fields?.find((f) => f.name === relation?.[0]);
      const filters = relation?.[1] ? relation[1].split(',').map((o) => o.trim()) : [];

      let validRelation = false;
      if (relatedField) {
        const hasValue = !utils.isEmpty(values[relatedField.name]) && (
          !relatedField.relation || isValidRelation(relatedField.relation, values)
        );

        if (hasValue && filters.length > 0) {
          const filtered = utils.filterOptions(utils.toArray(values[relatedField.name]), filters);
          validRelation = filtered.length > 0;
        } else {
          validRelation = hasValue;
        }
      }

      return validRelation;
    } else {
      return true;
    }
  }, [formSettings?.fields]);

  const formik = useFormik({
    validationSchema: () => {
      return internalState.validationSchema;
    },
    initialValues: formSettings?.initialValues,
    validateOnChange: false,
    onSubmit: (values, actions) => {
      const converted = Object.keys(values).reduce((o, name) => {
        const field = formSettings?.fields?.find((f) => f.name === name);
        if (isValidRelation(field.relation, values)) {
          o[name] = utils.fieldValue2ConvertedValue(field, values[name]);
        }

        return o;
      }, {});

      onSubmit?.(converted, actions, formSettings?.fields);
    },
  });
  const formikRef = useUpdatedRef(formik);

  const fieldsMemo = useEffectItem((formSettings?.fields ?? []).filter((f) => isValidRelation(f.relation, formik?.values)));
  const pageFieldsMemo = useMemo(() => {
    return fieldsMemo?.filter((f) => {
      return f.pageId === formSettings?.pages?.[internalState.pageIndex]?.id;
    });
  }, [fieldsMemo, formSettings?.pages, internalState.pageIndex]);

  useLayoutEffect(() => {
    setInternalState((current) => ({
      ...current,
      validationSchema: utils.fields2ValidationSchema(fieldsMemo.filter((f) => !f.section))
    }));
  }, [fieldsMemo]);

  const onNextEvent = useEffectEvent(onNext);
  const onPrevEvent = useEffectEvent(onPrev);
  const handleNext = useCallback((e) => {
    if ((internalState.pageIndex + 1) < formSettings?.pages.length) {
      onNextEvent?.(e, internalState.pageIndex + 1);
      if (!e.defaultPrevented) {
        setInternalState((current) => {
          return utils.updater({...current, pageIndex: current.pageIndex + 1});
        });
      }
    }
  }, [internalState.pageIndex, onNextEvent, formSettings?.pages.length]);

  const handlePrev = useCallback((e) => {
    if ((internalState.pageIndex - 1) >= 0) {
      onPrevEvent?.(e, internalState.pageIndex - 1);
      if (!e.defaultPrevented) {
        setInternalState((current) => {
          return utils.updater({...current, pageIndex: current.pageIndex - 1});
        });
      }
    }
  }, [internalState.pageIndex, onPrevEvent]);

  const onChangeEvent = useEffectEvent(onChange);
  const onChangeDirectEvent = useEffectEvent(onChangeDirect);
  const onBlurEvent = useEffectEvent(onBlur);
  const onResetEvent = useEffectEvent(onReset);
  const form = useMemo(() => {
    return {
      formik,
      state: {
        ...internalState
      },
      refs: {
        ref: innerRef
      },
      page: formSettings?.pages?.[internalState.pageIndex],
      fields: pageFieldsMemo,
      next: () => {
        handleNext(null);
      },
      prev: () => {
        handlePrev(null);
      },
      changes: (skipEmpty = true) => {
        return utils.changedFormFields(formik.initialValues ?? {}, formik.values ?? {}, skipEmpty);
      },
      values: (values, touch = false, validate = false, notify = true, reset = false) => {
        values = Object.keys(values).reduce((o, k) => {
          const field = formSettings?.fields.find((f) => f.name === k);
          o[k] = utils.fields2InitialValues([{...field, initial: values[k]}])[k];
          return o;
        }, {});

        if (notify) {
          Object.keys(values).forEach((k) => {
            const field = formSettings?.fields.find((f) => f.name === k);

            onChangeDirectEvent?.({
              target: {
                name: k,
                value: values[k]
              }
            }, formSettings?.fields, formik);
            onChangeEvent?.({
              target: {
                name: k,
                value: utils.fieldValue2ConvertedValue(field, values[k])
              }
            }, formSettings?.fields, formik);
          });
        }

        return ((touch || reset) ? formik.setTouched({
          ...formik.touched,
          ...Object.keys(values).reduce((o, k) => {
            o[k] = !reset;
            return o;
          }, {})
        }, false) : Promise.resolve())
          .then(() => {
            return formik.setValues({
              ...formik.values,
              ...values
            }, validate);
          });
      },
      touch: (touches, validate = false, notify = true) => {
        if (notify) {
          Object.keys(touches).forEach((k) => {
            onBlurEvent?.({
              target: {
                name: k,
                value: formik.values[k]
              }
            }, formSettings?.fields, formik);
          })
        }
        return formik.setTouched({
          ...formik.touched,
          ...touches
        }, validate);
      },
      validate: (touch = true) => {
        return utils.retry(() => !changeRef.current)
          .then(() => {
            return Promise.all(Object.keys(fieldRefs.current).map((fieldId) => {
              return fieldRefs.current[fieldId]?.ref?.validate?.(touch)?.then((errors) => {
                if (Object.keys(errors).filter((k) => !utils.isEmpty(errors[k])).length > 0) {
                  return {[fieldId]: 'Field error'};
                }
              });
            }))
              .then((fieldErrors) => {
                fieldErrors = fieldErrors.reduce((o, f) => ({...o, ...utils.cleanObject(f)}), {});
                return formik.validateForm()
                  .then((errors) => {
                    errors = {...errors, ...fieldErrors};
                    if (touch && Object.keys(errors).length > 0) {
                      formik.setTouched({
                        ...formik.touched,
                        ...Object.keys(errors).reduce((o, k) => {
                          o[k] = true;
                          return o;
                        }, {})
                      });
                    }

                    return errors;
                  });
              });
          });
      },
      reset: (state) => {
        onResetEvent?.(state);
        formik.resetForm(state);
      },
      submit: (touch = true, propagate = false) => {
        return utils.retry(() => !changeRef.current)
          .then(() => {
            return Promise.all(Object.keys(fieldRefs.current).map((fieldId) => {
              return fieldRefs.current[fieldId]?.ref?.validate?.(touch)?.then((errors) => {
                if (Object.keys(errors).filter((k) => !utils.isEmpty(errors[k])).length > 0) {
                  return {[fieldId]: 'Field error'};
                }
              });
            }))
              .then((fieldErrors) => {
                fieldErrors = fieldErrors.reduce((o, f) => ({...o, ...utils.cleanObject(f)}), {});
                return formik.validateForm()
                  .then((errors) => {
                    errors = {...errors, ...fieldErrors};
                    if (Object.keys(errors).length === 0) {
                      return formik.submitForm()
                        .then(() => {
                          if (propagate) {
                            return parentForm?.submit(touch, propagate);
                          }
                        })
                    } else if (touch) {
                      formik.setTouched({
                        ...formik.touched,
                        ...Object.keys(errors).reduce((o, k) => {
                          o[k] = true;
                          return o;
                        }, {})
                      });
                    }
                  })
              });
          });
      }
    }
  }, [formik, internalState, formSettings?.pages, formSettings?.fields, pageFieldsMemo, handleNext, handlePrev,
    onChangeEvent, onChangeDirectEvent, onBlurEvent, onResetEvent, parentForm]);

  useImperativeHandle(ref, () => form);

  const groups = useMemo(() => {
    const groups = [];

    if (form.fields) {
      form.fields.forEach((field) => {
        const name = field.formGroup ?? '';
        let group = groups.find((g) => g.name === name);
        if (!group) {
          group = {
            name,
            fields: []
          }
          groups.push(group);
        }
        group.fields.push(field);
      });
    }

    return groups;
  }, [form.fields]);

  const debouncedValidate = useMemo(() => {
    return utils.debounce((name) => {
      try {
        formikRef.current.validateField(name)
          .finally(() => validatingRef.current = false);
      } catch {
        /* SQUASH */
      }
    }, constants.debounce.minimal);
  }, [formikRef]);

  const onValidatingEvent = useEffectEvent(onValidating);
  const handleChange = useCallback((e) => {
    if (internalState.validationSchema) {
      onChangeEvent?.(e, formSettings?.fields, formikRef.current);

      if (!e.defaultPrevented) {
        formikRef.current.setFieldValue(e?.target?.name, e?.target?.value, false);
        validatingRef.current = true;
        debouncedValidate(e?.target?.name);
      }
    }
    changeRef.current = false;
  }, [formikRef, onChangeEvent, debouncedValidate, formSettings?.fields, internalState.validationSchema]);

  const handleChangeDirect = useCallback((e) => {
    activeTimeRef.current = new Date();
    changeRef.current = e;
    activeFieldRef.current = e?.target?.name;

    onChangeDirectEvent?.(e, formSettings?.fields, formikRef.current);

    const hasErrors = Boolean(Object.keys(formikRef.current?.touched).find((f) => formikRef.current?.touched[f] && formikRef.current?.errors[f]));
    onValidatingEvent?.(true, true, hasErrors, formikRef.current, formSettings?.fields);
  }, [onChangeDirectEvent, onValidatingEvent, formSettings?.fields, formikRef]);

  const handleBlur = useCallback((e) => {
    if (internalState.validationSchema) {
      onBlurEvent?.(e, formSettings?.fields, formikRef.current);

      if (!e.defaultPrevented) {
        fieldRefs.current[e?.target?.name]?.ref?.validate?.(true);
        formikRef.current.handleBlur(e);
      }

      const hasErrors = Boolean(Object.keys({...formikRef.current?.touched, [e?.target.name]: true}).find((f) => {
        return (formikRef.current?.touched[f] || f === e?.target.name) && formikRef.current?.errors[f];
      }));
      onValidatingEvent?.(true, formikRef.current?.dirty, hasErrors, formikRef.current, formSettings?.fields);
    }
    activeFieldRef.current = null;
    activeTimeRef.current = null;
  }, [formikRef, onBlurEvent, onValidatingEvent, formSettings?.fields, internalState.validationSchema]);

  const touchedMemo = useEffectItem(formik.touched);
  useEffect(() => {
    const touchFields = form.fields.filter((f) => autoTouch || f.FormFieldProps?.autoTouch);
    if (touchFields.length > 0) {
      const touches = touchFields.reduce((o, f) => {
        o[f.name] = true;
        return o;
      }, touchedMemo);

      if (!utils.compare(touchedMemo, touches)) {
        formikRef.current.setTouched(touches, false);
      }
    }
  }, [formikRef, form.fields, touchedMemo, autoTouch]);

  const isValidating = validatingRef.current;
  useEffect(() => {
    const hasErrors = Boolean(Object.keys(formik.touched).find((f) => formik.touched[f] && formik.errors[f]));
    onValidatingEvent?.(formik?.isValidating || isValidating, formik?.dirty,
      hasErrors, formikRef.current, formSettings?.fields);
  }, [formikRef, formik?.isValidating, isValidating, formik.touched, formik.errors, formik?.dirty,
    onValidatingEvent, formSettings?.fields]);

  const fieldsRef = useUpdatedRef(form.fields);
  useEffect(() => {
    if (autoFocus) {
      const focus = () => {
        if (innerRef.current) {
          const focusAny = !(fieldsRef.current ?? []).some((f) => f.FormFieldProps?.autoFocus === false);

          if (focusAny) {
            if (!dom.isPartOfParent(document.activeElement, innerRef.current)) {
              return dom.focusElement(innerRef.current);
            }
          } else {
            const focusClasses = (fieldsRef.current ?? [])
              .filter((f) => !(f.FormFieldProps?.autoFocus === false))
              .map((f) => `.FormField-name-${f.name}`);

            if (focusClasses.length > 0) {
              const els = Array.from(innerRef.current.querySelectorAll(focusClasses.join(', ')));
              return dom.focusElements(els);
            }
          }
        }
      }

      utils.retry(focus, 3, constants.delay.minimal);
    }
  }, [autoFocus, fieldsRef]);

  const activeField = activeFieldRef.current;
  useEffect(() => {
    if (Boolean(activeField)) {
      return utils.observeInterval(() => {
        if (!activeTimeRef.current || activeTimeRef.current.getTime() < (Date.now() - (constants.delay.medium))) {
          activeFieldRef.current = null;
        }
      }, constants.delay.medium / 4);
    }
  }, [activeField]);

  const initialChanges = initValuesRef.current.changes;
  useEffect(() => {
    if (Object.keys(initialChanges).length > 0) {
      const active = Boolean(activeFieldRef.current) && (formSettings?.fields ?? []).find((f) => f.name === activeFieldRef.current);

      // keep it changed until blurred
      const initialValues = {
        ...utils.filterObject(formikRef.current?.values, (formSettings?.fields ?? []).map((f) => f.name), false),
        ...initValuesRef.current.changes
      }

      initValuesRef.current.changes = active ? initValuesRef.current.changes : {};
      formikRef.current.resetForm({
        values: (activeFieldRef.current && active) ? {
          ...initialValues,
          [activeFieldRef.current]: formikRef.current?.values[activeFieldRef.current]
        } : initialValues,
        errors: formikRef.current.errors,
        touched: formikRef.current.touched
      });

      setTimeout(() => {
        try {
          return formikRef.current.validateForm();
        } catch {
          /* SQUASH */
        }
      });
    }
  }, [initialChanges, formikRef, formSettings?.fields]);

  const handleFieldRef = (field) => (ref) => {
    fieldRefs.current[field.name] = {
      ...fieldRefs.current[field.name],
      ref
    };

    if (field.FormFieldProps?.ref) {
      field.FormFieldProps.ref.current = ref;
    }
  }

  const renderFields = (formGroup) => {
    return form.fields.map((field, idx) => {
      const name = field.formGroup ?? '';
      if (name === formGroup) {
        if (field.section) {
          if (field.type === constants.formSectionTypes.info) {
            return <InfoPaper className="Form-section"
                              icon={field.icon}
                              info={field.info}
                              action={field.action}
                              color={field.color}
                              fullWidth={true}
                              {...field.InfoPaperProps} />
          } else {
            return <Box key={idx} className="Form-section">
              {(field.icon && (field.iconPosition || 'start') === 'start') ?
                <Icon className="section-icon-start" icon={field.icon}/> : null}
              <H6 className="title">{field.title}</H6>
              {(field.icon && field.iconPosition === 'end') ?
                <Icon className="section-icon-end" icon={field.icon}/> : null}
            </Box>
          }
        } else {
          const index = 1 + (
            idx - form.fields.filter((f, sIdx) => (f.section || f.labelPrefix !== 'index') && sIdx < idx).length
          );

          return <FormField key={`${field.name}_${idx}`}
                            value={formik.values[field.name]}
                            index={index}
                            field={field}
                            fullWidth={true}
                            readOnly={readOnly}
                            disabled={formik.isSubmitting ? true : null}
                            isLoading={isLoading}
                            onChange={handleChange}
                            onChangeDirect={handleChangeDirect}
                            onBlur={handleBlur}
                            {...field.FormFieldProps}
                            ref={handleFieldRef(field)} />
        }
      } else {
        return null;
      }
    }).filter((_) => (_));
  }

  const renderGroups = () => {
    const renderedGroups = groups?.map((group) => {
      const rendered = renderFields(group.name);

      return {
        ...group,
        rendered
      }
    });

    if (renderContent) {
      return renderContent(renderedGroups);
    } else {
      return <Box className="Form-fields">
        {renderedGroups.map((group, idx) => {
          return group.name ? <Box key={`${idx}-${group.name}`} className={`Form-group Form-group-${group.name}`}>
            {group.rendered}
          </Box> : group.rendered;
        })}
      </Box>
    }
  }

  const handleKeyDown = (e) => {
    onKeyDown?.(e);

    if (!e.defaultPrevented && dom.isSubmitFormEvent(e)) {
      form.submit(true, true).then();
      e.preventDefault();
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <FormContext.Provider value={form}>
    <StyledForm ref={innerRef} {...innerProps} onKeyDown={handleKeyDown}>
      {(form.page?.title || form.page?.icon) ? <Box className="Form-page">
        {(form.page?.icon && (form.page?.iconPosition || 'start') === 'start') ? <Icon className="section-icon-start" icon={form.page?.icon}/> : null}
        {form.page?.title ? <H5 className="title">{form.page?.title}</H5> : null}
        {(form.page?.icon && form.page?.iconPosition === 'end') ? <Icon className="section-icon-end" icon={form.page?.icon}/> : null}
      </Box> : null}

      {renderGroups()}

      {showButtons ? <Box className="Form-footer">
        {(showLoader && formik.isSubmitting) ? <SimpleLoader className="Form-loader"></SimpleLoader> : null}
        {error ? <Span className="Form-error">{error}</Span> : null}
        {success ? <Span className="Form-success">{success}</Span> : null}

        {internalState.pageIndex > 0 ? <Button disabled={formik.isSubmitting}
                                               onClick={handlePrev}
                                               children={'Prev'}
                                               {...PrevButtonProps} /> : null}
        {internalState.pageIndex < (formSettings?.pages.length - 1) ? <Button disabled={formik.isSubmitting}
                                                                              onClick={handleNext}
                                                                              children={'Next'}
                                                                              {...NextButtonProps} /> : null}
        {internalState.pageIndex === (formSettings?.pages.length - 1) ? <Button disabled={formik.isSubmitting}
                                                                                type="submit"
                                                                                variant="contained"
                                                                                children={'Submit'}
                                                                                onClick={() => form.submit(true, true)}
                                                                                {...SubmitButtonProps} /> : null}
      </Box> : null}

      {disclaimer ? <Box className="Form-disclaimer">
        <Markdown>{disclaimer}</Markdown>
      </Box> : null}
    </StyledForm>
  </FormContext.Provider>;
});

Form.propTypes = {
  pages: PropTypes.object,
  fieldData: PropTypes.object,
  showButtons: PropTypes.bool,
  showLoader: PropTypes.bool,
  disclaimer: PropTypes.string,
  readOnly: PropTypes.bool,
  autoTouch: PropTypes.bool,
  autoFocus: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.any,
  success: PropTypes.any,
  onChange: PropTypes.func,
  onChangeDirect: PropTypes.func,
  onBlur: PropTypes.func,
  onValidating: PropTypes.func,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  onReset: PropTypes.func,
  onSubmit: PropTypes.func,
  onKeyDown: PropTypes.func,
  renderContent: PropTypes.func,
  PrevButtonProps: PropTypes.object,
  NextButtonProps: PropTypes.object,
  SubmitButtonProps: PropTypes.object
};

Form.defaultProps = {
  showButtons: true
};

export default Form;
