import React, {useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import DialogContent from 'components/atoms/Dialogs/DialogContent/DialogContent';
import DialogHeader from 'components/molecules/Dialogs/DialogHeader/DialogHeader';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import DialogFooter from 'components/molecules/Dialogs/DialogFooter/DialogFooter';
import {Span} from 'components/atoms/Text/Typography/Typography';
import {useDatabaseEntitySettings} from 'services/entity/entity.utils';
import constants from 'helpers/constants';
import AdvancedQuery from 'components/organisms/Queries/AdvancedQuery/AdvancedQuery';
import searchUtils from 'helpers/search';
import utils from 'helpers/utils';
import Button from 'components/atoms/Buttons/Button/Button';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Close from '@mui/icons-material/Close';
import Save from '@mui/icons-material/Save';
import StyledFilterSelectionDialog
  from 'components/organisms/Dialogs/FilterSelectionDialog/FilterSelectionDialog.styles';
import InfoPaper from 'components/molecules/Papers/InfoPaper/InfoPaper';

const FilterSelectionDialog = React.forwardRef((props, ref) => {
  const {
    title,
    info,
    filter,
    onSubmit,
    ...innerProps
  } = useComponentProps(props, 'FilterSelectionDialog');

  const innerRef = useRef(null);
  const formRef = useRef(null);

  const [error, setError] = useState(null);
  const [dirty, setDirty] = useState(null);
  const [validation, setValidation] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  useImperativeHandle(ref, () => innerRef.current);

  const [entityFieldData, entityFilterGroups] = useDatabaseEntitySettings();

  const fields = useMemo(() => {
    const fields = [];

    fields.push({
      name: 'filter',
      label: 'Filter',
      type: constants.formFieldTypes.component,
      validation: constants.formFieldValidationTypes.component,
      conversion: constants.formFieldConversionTypes.component,
      entity: 'source',
      valueProp: 'query',
      Component: <AdvancedQuery size="smaller"
                                fieldData={entityFieldData}
                                filterGroups={entityFilterGroups}/>,
      initial: {
        value: filter ?? searchUtils.advancedQuery(),
        errors: false
      },
      required: false,
      FormFieldProps: {
        variant: 'staticLabel',
        hiddenLabel: true,
        autoFocus: false
      }
    });

    return fields;
  }, [filter, entityFieldData, entityFilterGroups]);

  const handleChange = () => {
    setError(null);
    setValidation(null);
  };

  const handleSubmit = (values, actions) => {
    setSubmitting(true);

    utils.asPromise(onSubmit)(values['filter'])
      .catch(() => {
        setError('Saving filter failed');
      })
      .finally(() => {
        actions.setSubmitting(false);
        setSubmitting(false);
      });
  };

  const handleValidating = (isValidating, isDirty, hasErrors) => {
    setDirty(isDirty);
    if (hasErrors) {
      setValidation('Please check if all fields have the correct values');
    } else {
      setValidation(null);
    }
  }

  const handleSubmitClick = () => {
    formRef.current?.submit();
  };

  const handleCancel = (e) => {
    innerRef.current?.close?.(e, 'cancelButtonClick');
  }

  const handleClose = (e, reason) => {
    if ((!submitting && !error && !dirty) || ['escapeKeyDown', 'closeButtonClick', 'cancelButtonClick'].includes(reason)) {
      innerProps.onClose?.(e, reason);
    }
  }

  const renderButtons = () => {
    return <React.Fragment>
      <Button children={'Cancel'}
              variant="text"
              startIcon={<Icon icon={Close}/>}
              onClick={handleCancel}/>
      <Button disabled={submitting}
              type="submit"
              variant="contained"
              children="Save"
              startIcon={<Icon icon={Save}/>}
              onClick={handleSubmitClick}/>
    </React.Fragment>
  }

  return <StyledFilterSelectionDialog ref={innerRef} {...innerProps} onClose={handleClose}>
    <DialogHeader className="FilterSelectionDialog-header"
                  title={title ?? 'Filter'}/>
    <DialogContent className="FilterSelectionDialog-content" >
      {info ? <InfoPaper className="FilterSelectionDialog-info" info={info}/> : null}
      <InlineForm ref={formRef} fields={fields}
                  onValidating={handleValidating}
                  onChange={handleChange}
                  onSubmit={handleSubmit} />
    </DialogContent>
    <DialogFooter className="FilterSelectionDialog-footer"
                  info={(error ?? validation) ? <Span color="error">{error ?? validation}</Span> : null}
                  buttons={renderButtons()} />
  </StyledFilterSelectionDialog>
});

FilterSelectionDialog.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.string,
  filter: PropTypes.object,
  onSubmit: PropTypes.func
};

FilterSelectionDialog.defaultProps = {
  multiple: true
};

export default FilterSelectionDialog;
