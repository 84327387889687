import React, {useMemo} from 'react';
import {
  useAuthIsPortal,
  useAuthIsProxy
} from 'services/auth/auth.utils';
import utils from 'helpers/utils';
import Dashboard from '@mui/icons-material/Dashboard';
import FolderSharp from '@mui/icons-material/FolderSharp';
import Search from '@mui/icons-material/Search';
import {Logo, Database, Dealflow} from 'assets/icons';
import Notifications from '@mui/icons-material/Notifications';
import Settings from '@mui/icons-material/Settings';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import SearchDialog from 'components/organisms/Dialogs/SearchDialog/SearchDialog';
import SwitchTeamDialog from 'components/organisms/Dialogs/SwitchTeamDialog/SwitchTeamDialog';
import SwitchClientDialog from 'components/organisms/Dialogs/SwitchClientDialog/SwitchClientDialog';
import Logout from '@mui/icons-material/Logout';
import Badge from 'components/atoms/Badges/Badge/Badge';
import ClientNotificationList from 'components/organisms/Lists/ClientNotificationList/ClientNotificationList';
import {useClientTimelineList} from 'services/client/timeline/timeline.hooks';
import {
  useAuthClient,
  useAuthPasses,
  useAuthTeam,
  useAuthTeams
} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import {useLinkNavigate} from 'helpers/hooks/links';

export const MenuContext = React.createContext(null)

export function useMenu () {
  return React.useContext(MenuContext).menu;
}

export function useLogo () {
  return React.useContext(MenuContext).logo;
}

export function useSwitch () {
  return React.useContext(MenuContext).switch;
}

const MenuProvider = (props) => {
  const client = useAuthClient();
  const team = useAuthTeam();
  const teams = useAuthTeams();
  const isProxy = useAuthIsProxy();
  const isPortal = useAuthIsPortal();
  const passes = useAuthPasses();

  const navigate = useLinkNavigate();
  const dialogControl = useDialogControl();

  const notificationList = useClientTimelineList({
    clientId: client?.clientId,
    filter: [
      {id: 'notification', value: true},
      {id: 'mentioned', value: true},
      {id: 'excludedTypes', value: ['additions', 'status', 'csi', 'deal_leader']},
    ],
    pageSize: 0
  }, {
    enabled: !isPortal && utils.isDefined(client?.clientId),
    matchCallback: (cache, entity) => {
      // custom match to refetch on all watches that are invalidated to update the number
      return ['comment', 'task'].includes(entity);
    }
  });
  const notificationCount = notificationList.meta?.resultsCount ?? 0;

  const menu = useMemo(() => {
    const clientName = client?.name;

    let teamSwitch = {
      label: utils.avatarLabel(team?.name ?? ''),
      tooltip: team?.name,
      tooltipPosition: 'right'
    }

    const multipleTeams = (teams.filter((t) => !t.props.isPrivate || t.isMember).length > 1);
    if (!isProxy && passes?.length > 0) {
      teamSwitch = {
        label: utils.avatarLabel(clientName),
        tooltip: `${clientName} (switch)`,
        tooltipPosition: 'right',
        auth: utils.createAuth({ attribute: 'client.switch' }),
        onClick: () => {
          dialogControl.show(SwitchClientDialog, true)
        }
      };
    } else if (isProxy || multipleTeams) {
      teamSwitch = {
        label: utils.avatarLabel(team?.name ?? ''),
        tooltip: `${team?.name} (switch)`,
        tooltipPosition: 'right',
        auth: utils.createAuth(multipleTeams ? {attribute: 'team.switch'} :
          (isProxy ? {attribute: 'client.switch', meta: {client}} : {attribute: 'system.null'})),
        onClick: () => {
          dialogControl.show(SwitchTeamDialog, true);
        }
      };
    }

    return {
      logo: {
        label: 'Home',
        tooltip: 'Home',
        tooltipPosition: 'right',
        navigation: {
          to: '/',
          end: true
        },
        icon: Logo,
        anchor: 'top'
      },
      switch: teamSwitch,
      menu: [
        {
          label: 'Dashboard',
          tooltip: 'Dashboard',
          tooltipPosition: 'right',
          navigation: {
            to: '/',
            end: true
          },
          icon: Dashboard,
          anchor: 'top'
        },
        {
          label: 'Database',
          tooltip: 'Database',
          tooltipPosition: 'right',
          navigation: {
            to: '/database',
            end: false
          },
          icon: Database,
          anchor: 'top'
        },
        {
          label: 'Collections',
          tooltip: 'Collections',
          tooltipPosition: 'right',
          navigation: {
            to: '/collections',
            end: false
          },
          icon: FolderSharp,
          anchor: 'top'
        },
        {
          label: 'Deal flow',
          tooltip: 'Deal flow',
          tooltipPosition: 'right',
          navigation: {
            to: '/dealflow',
            end: false
          },
          icon: Dealflow,
          anchor: 'top'
        },

        // middle
        {
          label: 'Search',
          tooltip: 'Search (Ctrl + Space)',
          tooltipPosition: 'right',
          keyboard: 'Ctrl + Space',
          auth: utils.createAuth({ attribute: 'search.list' }),
          onClick: () => {
            dialogControl.show(SearchDialog, true);
          },
          icon: Search,
          anchor: 'middle'
        },

        // bottom
        {
          label: 'Notifications',
          tooltip: 'Notifications',
          tooltipPosition: 'right',
          auth: utils.createAuth({ attribute: 'client.notifications' }),
          icon: Notifications,
          anchor: 'bottom',
          popper: <ClientNotificationList />,
          ActionIconButtonProps: {
            BadgeProps: {
              variant: 'standard'
            },
            DropDownPopperProps: {
              ContextPopperProps: {
                density: 'densest',
                placement: 'right-end',
                closeOnNavigate: true,
                strategy: 'fixed',
                offset: [0, 8]
              }
            }
          },
          badge: <Badge badgeContent={notificationCount || 0} color="error"/>
        },
        {
          label: 'Settings',
          tooltip: 'Settings',
          tooltipPosition: 'right',
          navigation: {
            to: '/settings',
            end: false
          },
          icon: Settings,
          anchor: 'bottom'
        },
        {
          label: 'Logout',
          tooltip: 'Logout',
          tooltipPosition: 'right',
          onClick: (e) => {
            navigate({
              event: e,
              to: '/auth/logout', resetState: true
            });
          },
          icon: Logout,
          anchor: 'bottom'
        }
      ]
    }
  }, [client, team?.name, teams, isProxy, passes,
    notificationCount, dialogControl, navigate]);

  return <MenuContext.Provider value={menu}>
    {props.children}
  </MenuContext.Provider>
};

MenuProvider.propTypes = {
}

export default MenuProvider;
