import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledClientProfileCardContent
  from 'components/organisms/Cards/ClientProfileCardContent/ClientProfileCardContent.styles';

const ClientProfileCardContent = React.forwardRef((props, ref) => {
  const {
    content,
    ...innerProps
  } = useComponentProps(props, 'ClientProfileCardContent');

  const contentMemo = useMemo(() => {
    if (content?.fields) {
      content.fields = content?.fields.map((field) => {
        // if (field.name === 'projectFoundingYear') {
        //   field.FormFieldProps = {
        //     /* example */
        //     ...field.FormFieldProps
        //   };
        // }

        return field;
      });
    }
    return content;
  }, [content]);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledClientProfileCardContent ref={ref} {...innerProps} content={contentMemo}/>
});

ClientProfileCardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

ClientProfileCardContent.defaultProps = {
};

export default ClientProfileCardContent;
