import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Card from 'components/atoms/Cards/Card/Card';

const StyledThemeCard = styled(Card)`
  position: relative;
  
  .ThemeCard {
    &-content {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;
      
      > .Icon { 
        width: ${props => props.theme.layout(32)};
        height: ${props => props.theme.layout(32)};
      }
      
      &-text {
        display: flex;
        flex-grow: 1;
        overflow: hidden;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin-left: ${props => props.theme.layout(14)};
        padding-right: ${props => props.theme.layout('4sp')};
        
        &-label {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: 100%;
          
          font-weight: 400;
          justify-content: flex-end;
        }
      }
    }

    &-favorite {
      position: absolute;
      top: ${props => props.theme.layout(12)};
      right: ${props => props.theme.layout(12)};
      height: ${props => props.theme.layout('4sp')};
      width: ${props => props.theme.layout('4sp')};
    }
  }
  
  ${ComponentStyles}
`;

StyledThemeCard.propTypes = {
  theme: PropTypes.object
};

StyledThemeCard.defaultProps = {}

export default StyledThemeCard;
