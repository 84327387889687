import utils from 'helpers/utils';
import {atom, atomFamily, selector, selectorFamily} from 'recoil';
import BaseStore from 'stores/base.store';
import constants from 'helpers/constants';
import logger from 'helpers/logger';

const applyDefaultOptions = (options = {}) => {
  return {
    ...options
  };
}

export default class AppStore extends BaseStore {
  constructor (name, options) {
    logger.trace(`Starting app: ${name}`);

    super(options);

    this.options = {...this.options, ...applyDefaultOptions(options)};

    this.name = name || '';
    this.uuid = this.name

    this.initAtoms();
  }

  saveKeys (keys, set) {
    set(this.atoms.keys, (current) => {
      const hash = utils.sha1(keys);
      if (!current[hash]) {
        return {...current, [hash]: keys};
      } else {
        return current;
      }
    });
  }

  serializeItem (item) {
    let itm = utils.clone(item);
    if (utils.isObject(item)) {
      const persist = item.$store?.persist || [];

      const keepKeys = persist.filter((k) => !k.startsWith('-'));
      const removeKeys = persist.filter((k) => k.startsWith('-'));

      if (keepKeys.length > 0) {
        Object.keys(item).forEach((k) => {
          if (!keepKeys.find((kk) => kk === k)) {
            delete itm[k];
          }
        });
      } else if (removeKeys.length > 0) {
        removeKeys.forEach((k) => {
          delete itm[k.slice(1)];
        });
      }
    }

    return JSON.stringify(itm, (key, value) => {
      if (value?.id && value?.store) {
        return undefined;
      }
      return value;
    });
  }

  deserializeItem (item) {
    if (utils.isDefined(item)) {
      return JSON.parse(item);
    } else {
      return item;
    }
  }

  initAtoms () {
    this.atoms = {};

    this.atoms.reset = atom({
      key: `${this.name}Reset`,
      default: (new Date())
    });

    this.atoms.keys = atom({
      key: `${this.name}Store`,
      default: {}
    });

    this.atoms.tempState = atomFamily({
      key: `${this.name}TempState`,
      default: null,
    });

    this.atoms.sessionState = atomFamily({
      key: `${this.name}SessionState`,
      default: null,
      effects: ({key, userId, teamId}) => [
        ({setSelf, onSet}) => {
          // called on creation
          try {
            const saved = sessionStorage.getItem(`state_${key}_${userId}_${teamId}`);
            if (saved) {
              setSelf(this.deserializeItem(saved));
            }

            onSet((newValue, oldValue, isReset) => {
              if (!isReset) {
                if (!utils.isDefined(newValue)) {
                  sessionStorage.removeItem(`state_${key}_${userId}_${teamId}`);
                } else {
                  sessionStorage.setItem(`state_${key}_${userId}_${teamId}`, this.serializeItem(newValue));
                }
              }
            });
          } catch (err) {
            logger.trace('Session storage failed', err);
          }
        }
      ]
    });

    this.atoms.localState = atomFamily({
      key: `${this.name}LocalState`,
      default: null,
      effects: ({key, userId, teamId}) => [
        ({setSelf, onSet}) => {
          // called on creation
          try {
            const saved = localStorage.getItem(`state_${key}_${userId}_${teamId}`);
            if (saved) {
              setSelf(this.deserializeItem(saved));
            }

            onSet((newValue, oldValue, isReset) => {
              if (!isReset) {
                if (!utils.isDefined(newValue)) {
                  localStorage.removeItem(`state_${key}_${userId}_${teamId}`);
                } else {
                  localStorage.setItem(`state_${key}_${userId}_${teamId}`, this.serializeItem(newValue));
                }
              }
            });
          } catch (err) {
            logger.trace('Local storage failed', err);
          }
        }
      ]
    });

    this.atoms.processState = selectorFamily({
      key: `${this.name}ProcessState`,
      get: ({type, key, scope}) => ({get}) => {
        const auth = this.resurrectItem(null, get(this.atoms.sessionState({key: 'auth', userId: 0, teamId: 0})), get).data;

        let item = null;
        if (scope === constants.appState.scope.global || auth?.loggedIn) {
          const userId = (scope === constants.appState.scope.global ? 0 : auth?.userId) ?? 0;
          const teamId = (scope === constants.appState.scope.team ? auth?.teamId : 0) ?? 0;

          item = get(this.atoms[`${type}State`]({key, userId, teamId}));
        }

        return {...this.resurrectItem(null, item, get), raw: item};
      },
      set: ({type, key, scope}) => ({get, set, reset}, state) => {
        const auth = this.resurrectItem(null, get(this.atoms.sessionState({key: 'auth', userId: 0, teamId: 0})), get).data;
        if (scope === constants.appState.scope.global || auth?.loggedIn) {
          const userId = (scope === constants.appState.scope.global ? 0 : auth?.userId) ?? 0;
          const teamId = (scope === constants.appState.scope.team ? auth?.teamId : 0) ?? 0;

          this.saveKeys({type, key, scope, userId, teamId}, set);

          set(this.atoms[`${type}State`]({key, userId, teamId}), utils.updater(state));
        }
      }
    });

    // must be last
    this.atoms.processReset = selector({
      key: `${this.name}ProcessReset`,
      get: ({get}) => {
        return get(this.atoms.reset);
      },
      set: ({get, set, reset}, {type}) => {
        const doFullReset = [constants.resetTypes.logout].includes(type);

        if (doFullReset) {
          const keysStore = get(this.atoms.keys);
          const stateKeys = Object.keys(keysStore)
            .map((k) => keysStore[k]);

          // reset all families
          stateKeys.forEach((keys) => {
            reset(this.atoms[`${keys.type}State`](utils.filterObject(keys, ['type', 'scope'], true)));
            reset(this.atoms.processState(utils.filterObject(keys, ['userId', 'teamId'], true)));
          });

          // reset all keys
          reset(this.atoms.keys);

          const now = new Date();
          set(this.atoms.reset, now);
        }
      }
    });
  }
}
