import {useCallback} from 'react';
import {
  useEntityTagAdd,
  useEntityTagDelete
} from 'services/entity/tag/tag.hooks';
import utils from 'helpers/utils';

export function useEntityTagPatch () {
  const entityTagAdd = useEntityTagAdd();
  const entityTagDelete = useEntityTagDelete();

  return useCallback((entity, currentTags, tags) => {
    const add = tags.filter((t1) => {
      return !currentTags
        .find((t2) => +t2.tagId === +t1.tagId || (
          utils.isDefined(t1.value) && utils.isDefined(t2.value) && t2.value?.toLowerCase() === t1.value?.toLowerCase()
        ))
    });
    const del = currentTags.filter((t1) => {
      return !tags
        .find((t2) => +t2.tagId === +t1.tagId || (
          utils.isDefined(t1.value) && utils.isDefined(t2.value) && t2.value?.toLowerCase() === t1.value?.toLowerCase()
        ))
    });

    if (add.length > 0 && del.length > 0) {
      return entityTagAdd.mutation.mutateAsync({entityId: entity.entityId, tags: add})
        .then(() => {
          return entityTagDelete.mutation.mutateAsync({entityId: entity.entityId, tags: del})
        });
    } else if (add.length > 0) {
      return entityTagAdd.mutation.mutateAsync({entityId: entity.entityId, tags: add});
    } else if (del.length > 0) {
      return entityTagDelete.mutation.mutateAsync({entityId: entity.entityId, tags: del});
    } else {
      return Promise.resolve();
    }
  }, [entityTagAdd.mutation, entityTagDelete.mutation]);
}
