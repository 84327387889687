import React, {useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useEffectItem} from 'helpers/hooks/utils';
import Table from 'components/organisms/Tables/Table/Table';
import TextTableCell from 'components/molecules/TableCells/TextTableCell/TextTableCell';
import utils from 'helpers/utils';
import Logo from 'components/atoms/Logos/Logo/Logo';
import HomeWorkOutlined from '@mui/icons-material/HomeWorkOutlined';
import FieldTableCell from 'components/molecules/TableCells/FieldTableCell/FieldTableCell';
import constants from 'helpers/constants';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useAnalyseQuestion} from 'services/analyse/analyse.hooks';
import {processEntityInfo} from 'services/entity/entity.utils';
import Box from 'components/atoms/Layout/Box/Box';
import Typography, {H6} from 'components/atoms/Text/Typography/Typography';
import ScoreLinearProgress from 'components/organisms/Progress/ScoreLinearProgress/ScoreLinearProgress';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import SplitBar from 'components/organisms/Bars/SplitBar/SplitBar';
import {Downloading} from '@mui/icons-material';
import StyledEntitiesAnalyseTagWizardContent
  from 'components/organisms/WizardContent/EntitiesAnalyseTagWizardContent/EntitiesAnalyseTagWizardContent.styles';

const FieldCell = React.forwardRef(({table, row, column, cell, columnDef, fieldData}, ref) => {
  const entity = cell.row.original;
  const field = utils.initializeFormFields([columnDef], entity)[0];
  field.initial = cell.column.columnDef.optimistic.get(cell, field.initial);

  field.info = processEntityInfo(entity, field, field.initial?.value ?? field.initial);

  field.FormFieldProps = field.FormFieldProps ?? {};
  if (field.tagGroup) {
    field.FormFieldProps.ChipProps = {
      ...field.FormFieldProps.ChipProps,
      size: 'medium'
    };
  }

  return <FieldTableCell ref={ref} fields={[field]} fieldData={fieldData}/>
});

const EntitiesAnalyseTagWizardContent = React.forwardRef((props, ref) => {
  const {
    wizard,
    step,
    collection,
    tableProvider,
    onComplete,
    onError,
    onDirty,
    ...innerProps
  } = useComponentProps(props, 'EntitiesAnalyseTagWizardContent');

  const innerRef = useRef(null);
  const loadingRef = useRef(null);

  const state = wizard.getStepState();
  const data = wizard.data?.[wizard.dataKey];
  const setDataEvent = useEffectEvent(wizard.setData);
  const setStateEvent = useEffectEvent(wizard.setStepState);

  const questionsData = data?.[data?.questionsHash];
  const resultsCount = Math.min(tableProvider.listSelection.max, tableProvider.list?.meta?.resultsCount);

  const done = Math.min((questionsData?.pointer ?? 0) - (questionsData?.batch * constants.analyse.batchSizes.tag), constants.analyse.batchSizes.tag);
  const left = Math.min(resultsCount - (questionsData?.batch * constants.analyse.batchSizes.tag), constants.analyse.batchSizes.tag);
  const batchProgress = (done / left) * 100;
  const totalDone = (data?.entityIds ?? [])
    .slice(0, ((questionsData?.batch + 1) * constants.analyse.batchSizes.tag))
    .filter((entityId) => utils.isDefined(questionsData?.evidences?.[entityId])).length;
  //const totalProgress = (totalDone / resultsCount) * 100;
  const calculating = batchProgress < 100;

  const entitiesLoading = Object.keys(data?.entities || {})
    .filter((entityId) => data?.entities?.[entityId]?.questionsHash === data?.questionsHash).length === 0;

  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const snackbar = useSnackbar();
  const listState = useEffectItem(tableProvider.appliedListState());
  const entitySelectedEvent = useEffectEvent(tableProvider.updaters?.selectedItems);

  const analyseQuestion = useAnalyseQuestion();

  useEffect(() => {
    if (!utils.isDefined(data?.entityIds)) {
      entitySelectedEvent?.(listState, 0, resultsCount, null, true,
        (entities) => {
          setDataEvent?.((current) => ({
            ...current,
            [wizard.dataKey]: {
              ...current[wizard.dataKey],
              entityIds: (current?.[wizard.dataKey]?.entityIds || [])
                .concat(entities.map((e) => e.entityId))
            }
          }));
        }, () => {
          snackbar.show('Retrieving company data failed', null,
            {color: 'error', autoHideDuration: constants.delay.error});
        });
    }
  }, [snackbar, entitySelectedEvent, listState, data?.entityIds, resultsCount, wizard.dataKey, setDataEvent]);

  useEffect(() => {
    if (utils.isDefined(questionsData?.batch) &&
        (questionsData?.batch * constants.analyse.batchSizes.tag) < data?.entityIds?.length &&
        (questionsData?.batch * constants.analyse.batchSizes.tag) >= (data?.loadCount ?? 0) &&
        (questionsData?.batch * constants.analyse.batchSizes.tag) < resultsCount) {
      const entityIds = data?.entityIds.slice(questionsData?.batch * constants.analyse.batchSizes.tag, (questionsData?.batch + 1) * constants.analyse.batchSizes.tag);

      entitySelectedEvent?.(listState, 0, constants.analyse.batchSizes.tag, entityIds, false,
        (entities) => {
          setDataEvent?.((current) => ({
            ...current,
            [wizard.dataKey]: {
              ...current[wizard.dataKey],
              loadCount: (current?.[wizard.dataKey]?.loadCount ?? 0) + entityIds.length,
              entities: {
                ...current?.[wizard.dataKey]?.entities,
                ...entities.reduce((o, entity) => {
                  return {...o, [entity.entityId]: entity};
                }, {})
              }
            }
          }));
        }, () => {
          snackbar.show('Retrieving company data failed', null,
            {color: 'error', autoHideDuration: constants.delay.error});
        });
    }
  }, [questionsData?.batch, data?.loadCount, data?.entityIds, snackbar, entitySelectedEvent, listState,
    resultsCount, wizard.dataKey, setDataEvent]);

  const onCompleteEvent = useEffectEvent(onComplete);
  const matchColumnsWizardContent = useMemo(() => ({
    refs: {
      ref: innerRef,
    },
    submit: () => {
      onCompleteEvent?.(Boolean(step?.resetNextSteps));
    }
  }), [step?.resetNextSteps, onCompleteEvent]);

  useImperativeHandle(ref, () => matchColumnsWizardContent);

  const fieldData = useMemo(() => {
    return {
      tagGroups: data?.outputTagGroups.map((tg, idx) => {
        return {
          ...tg,
          groupId: -(idx + 1),
          hasPoints: false,
          autoTagType: data?.autoTagType,
          collectionId: collection?.collectionId,
          description: '',
          tags: tg.tags.map((t, idx) => ({
            tagId: -(idx + 1),
            value: t.name,
            autoTagParams: { keywords: t.keywords ?? [] }
          }))
        }
      })
    }
  }, [data?.outputTagGroups, data?.autoTagType, collection?.collectionId]);

  const tagGroupColumnDefs = useMemo(() => {
    return fieldData.tagGroups.map((tg) => ({
      id: `dropdown_${tg.groupId}`, // sortingKey
      name: tg.name,
      entity: 'entity',
      tagGroupId: tg.groupId,
      tagGroup: tg,
      FormFieldProps: {
        ChipListProps: {
          variant: 'compact'
        }
      }
    }));
  }, [fieldData.tagGroups]);

  const questions = useMemo(() => {
    return fieldData?.tagGroups.map((tg) => ({
      tagGroup: tg.groupId,
      explanation: tg.description,
      analyserType: constants.collection.analyserTypes.collectionTagAnalyser,
      analyserParams: {
        tagGroup: tg.groupId,
        multiselect: tg.multiselect,
        gptExplanations: true
      },
      subQuestions: tg.tags.map((t) => ({
        analyserType: tg.autoTagType,
        id: t.tagId,
        questionId: t.tagId,
        tagValue: t.value,
        filterParams: tg.autoTagType === constants.collection.autoTagTypes.byKeywords ? {
          keywords: {
            valid: t.autoTagParams?.keywords ?? []
          }
        } : null,
        analyserParams: tg.autoTagType === constants.collection.autoTagTypes.byKeywords ? {
          options: [
            'articles', 'patents', 'en', 'ss'
          ]
        } : {}
      }))
    }));
  }, [fieldData]);

  useLayoutEffect(() => {
    setDataEvent?.((current) => {
      if (!utils.compare(current?.[wizard.dataKey]?.questions, questions)) {
        const questionsHash = utils.sha1(questions);

        return {
          ...current,
          [wizard.dataKey]: {
            ...current[wizard.dataKey],
            fieldData: fieldData,
            questions: questions,
            questionsHash: questionsHash,
            [questionsHash]: {
              ...current[wizard.dataKey]?.[questionsHash],
              batch: current[wizard.dataKey]?.[questionsHash]?.batch ?? 0,
              pointer: current[wizard.dataKey]?.[questionsHash]?.pointer ?? 0
            }
          }
        }
      } else {
        return current;
      }
    })
  }, [questions, fieldData, wizard.dataKey, setDataEvent]);

  const columnsMemo = useMemo(() => {
    const columns = [];

    columns.push({
      accessorKey: 'name',
      id: 'name',
      header: 'Company name',
      enableEditing: false,
      Cell: ({cell}) => {
        const entity = cell.row.original;

        return <TextTableCell title={entity.name || entity.entityId}
                              logo={<Logo logo={entity.logoUrl}
                                          altLogo={entity.altLogoUrl}
                                          fallbackIcon={HomeWorkOutlined}
                                          density="sparse"
                                          outlined={true}/>}/>
      },
    });

    if (!mdDown && tagGroupColumnDefs.length === 1) {
      columns.push({
        accessorKey: 'oneliner',
        id: 'oneliner',
        header: 'Oneliner',
        enableSorting: false,
        enableEditing: false,
        Cell: (props) => <FieldCell {...props}
                                    columnDef={{
                                      name: 'oneliner',
                                      entity: 'entity',
                                      FormFieldProps: {
                                        showTooltip: true
                                      }
                                    }}
                                    fieldData={fieldData}/>
      });
    }

    tagGroupColumnDefs.forEach((columnDef) => {
      columns.push({
        accessorKey: columnDef.id,
        id: columnDef.id,
        header: columnDef.name,
        enableSorting: false,
        enableEditing: false,
        size: tagGroupColumnDefs.length >= 3 ? 120 : (tagGroupColumnDefs.length > 1 ? 180 : null),
        Cell: (props) => {
          const entity = props.cell.row.original;

          if (utils.isDefined(entity.evidenceTime)) {
            return <FieldCell {...props}
                              columnDef={columnDef}
                              fieldData={fieldData}/>
          } else if (entity.isLoading) {
            return <ScoreLinearProgress size="smallish"
                                        color="primary"
                                        showEmpty={true}
                                        score={0}
                                        LinearProgressProps={{
                                          variant: 'indeterminate'
                                        }}/>
          } else {
            return <ScoreLinearProgress size="smallish" isLoading={true}/>
          }
        }
      });
    });

    return columns;
  }, [fieldData, tagGroupColumnDefs, mdDown]);

  useEffect(() => {
    if (data?.questions && utils.compare(data?.questions, questions)) {
      if (questionsData?.pointer < data?.entityIds?.length && questionsData?.pointer < data?.loadCount &&
          questionsData?.pointer < ((questionsData?.batch + 1) * constants.analyse.batchSizes.tag)) {
        const batchSize = Math.min(5, data?.loadCount - questionsData?.pointer);
        const entityIds = data?.entityIds.slice(questionsData?.pointer, questionsData?.pointer + batchSize)
          .filter((entityId) => !questionsData?.evidences?.[entityId]);

        if (entityIds.length > 0) {
          if (loadingRef.current !== entityIds.join('_')) {
            loadingRef.current = entityIds.join('_');
            setStateEvent?.({loading: entityIds.reduce((o, entityId) => {
              o[entityId] = true;
              return o;
            }, {})});

            Promise.all(data?.questions.map((question) => {
              return analyseQuestion.mutation.mutateAsync({
                entityIds: entityIds,
                question: question,
                level: 2
              });
            }))
              .then((results) => {
                const credits = results.reduce((c, {response}) => {
                  const result = utils.camelcase(response.data?.data);
                  return c + result.credits;
                }, 0);
                const evidences = results.reduce((e, {response}, idx) => {
                  const question = data?.questions[idx];
                  const result = utils.camelcase(response.data?.data);
                  return e.concat(result.evidence.map((e) => ({
                    ...e,
                    tagGroupId: question.tagGroup
                  })));
                }, []);

                loadingRef.current = null;
                setStateEvent?.({loading: null});
                setDataEvent?.((current) => ({
                  ...current,
                  [wizard.dataKey]: {
                    ...current[wizard.dataKey],
                    [current[wizard.dataKey]?.questionsHash]: {
                      ...current[wizard.dataKey]?.[current[wizard.dataKey]?.questionsHash],
                      credits: (current[wizard.dataKey]?.[current[wizard.dataKey]?.questionsHash]?.credits ?? 0) + credits,
                      pointer: (current[wizard.dataKey]?.[current[wizard.dataKey]?.questionsHash]?.pointer ?? 0) + batchSize,
                      evidences: {
                        ...current[wizard.dataKey]?.[current[wizard.dataKey]?.questionsHash]?.evidences,
                        ...entityIds.reduce((o, entityId) => {
                          o[entityId] = {
                            time: new Date(),
                            evidences: evidences
                              .filter((evidence) => +evidence.entityId === +entityId)
                          };

                          return o;
                        }, {})
                      }
                    }
                  }
                }));
              })
              .catch((error) => {
                loadingRef.current = null;
                setStateEvent?.({loading: null});
                if (error?.response?.status === constants.http.status.conflict) {
                  snackbar.show('0 credits left in your wallet', null,
                    {color: 'error', autoHideDuration: constants.delay.warning});
                } else {
                  snackbar.show('Failed to analyse the company', null,
                    {color: 'error', autoHideDuration: constants.delay.warning});
                }
              });
          }
        } else {
          setDataEvent?.((current) => ({
            ...current,
            [wizard.dataKey]: {
              ...current[wizard.dataKey],
              [current[wizard.dataKey]?.questionsHash]: {
                ...current[wizard.dataKey]?.[current[wizard.dataKey]?.questionsHash],
                pointer: (current[wizard.dataKey]?.[current[wizard.dataKey]?.questionsHash]?.pointer ?? 0) + batchSize
              }
            }
          }));
        }
      }
    }
  }, [snackbar, questions, questionsData?.batch, questionsData?.pointer, questionsData?.evidences,
    data?.questions, data?.loadCount, data?.entityIds, collection?.collectionId,
    analyseQuestion.mutation, wizard.dataKey, setDataEvent, setStateEvent]);

  useEffect(() => {
    if (questionsData?.evidences || data?.questions || data?.loadCount) {
      setDataEvent?.((current) => {
        if (current[wizard.dataKey]?.questions) {
          const entities = Object.keys(current[wizard.dataKey]?.entities ?? {}).map((entityId) => {
            const entity = current[wizard.dataKey]?.entities[entityId];
            const evidences = current[wizard.dataKey]?.[current[wizard.dataKey]?.questionsHash]?.evidences?.[entity.entityId];

            const evidenceTime = evidences?.time;

            return {
              ...entity,
              evidenceTime: evidenceTime,
              questionsHash: current[wizard.dataKey]?.questionsHash,
              tagGroups: current[wizard.dataKey]?.fieldData?.tagGroups?.map((tg) => {
                const tagged = evidences?.evidences?.filter((e) =>{
                  return +e.tagGroupId === tg.groupId && +e.computedEvidence.score > 0;
                }) ?? [];

                if (tagged.length > 0) {
                  return {
                    groupId: tg.groupId,
                    tags: tagged.map((te) => {
                      return tg.tags.find((t) => +t.tagId === +te.questionId);
                    })
                  };
                } else {
                  return null;
                }
              }).filter((_) => (_)),
              clientQuestions: current[wizard.dataKey]?.questions?.map((question) => {
                const tagged = evidences?.evidences?.filter((e) =>{
                  return +e.tagGroupId === +question.tagGroup && +e.computedEvidence.score > 0;
                }) ?? [];

                if (tagged.length > 0) {
                  return {
                    ...question,
                    subQuestions: tagged.map((te) => {
                      return {
                        ...question.subQuestions.find((t) => +t.questionId === +te.questionId),
                        currentEvidence: {
                          2: te.computedEvidence
                        }
                      };
                    })
                  }
                } else {
                  return null;
                }
              }).filter((_) => (_))
            };
          }).reduce((o, e) => {
            return {...o, [e.entityId]: e};
          }, {});

          return {
            ...current,
            [wizard.dataKey]: {
              ...current[wizard.dataKey],
              entities
            }
          }
        } else {
          return current;
        }
      })
    }
  }, [questionsData?.evidences, data?.loadCount, data?.questions, wizard.dataKey, setDataEvent]);

  const rows = useMemo(() => {
    return (data?.entityIds ?? [])
      .slice(0, ((questionsData?.batch + 1) * constants.analyse.batchSizes.tag))
      .filter((entityId) => data?.entities?.[entityId]?.questionsHash === data?.questionsHash)
      .map((entityId) => ({
        ...data?.entities?.[entityId],
        isLoading: state?.loading?.[entityId]
      }))
      .filter((entity) => {
        const tagId = entity.tagGroups?.[0]?.tags?.[0]?.tagId;
        return !Object.keys(state?.legend?.visibility ?? {}).find((k) => state?.legend?.visibility[k]) ||
          (state?.legend?.visibility?.[tagId] ?? false);
      });
  }, [data?.questionsHash, questionsData?.batch, data?.entityIds, data?.entities, state?.loading, state?.legend?.visibility]);

  const renderSubtitle = () => {
    const subtitle = `${calculating ? 'Calculating' : 'Calculated'} tags for companies ${utils.formatNumber((questionsData?.batch * constants.analyse.batchSizes.tag) + 1)} to 
      ${utils.formatNumber(Math.min((questionsData?.batch + 1) * constants.analyse.batchSizes.tag, resultsCount))} 
      (${Math.round(batchProgress)} %)`;

    return <Box className="EntitiesAnalyseTagWizardContent-subtitle">
      <H6>{subtitle}</H6>
      <ScoreLinearProgress size="smallish"
                           showEmpty={true}
                           color="primary"
                           score={batchProgress} />
    </Box>
  };

  const barActions = useMemo(() => {
    return [{
      label: 'Load more',
      tooltip: null,
      icon: Downloading,
      onClick: () => {
        setDataEvent?.((current) => {
          return utils.updater({
            ...current,
            [wizard.dataKey]: {
              ...current[wizard.dataKey],
              [current[wizard.dataKey]?.questionsHash] : {
                ...current[wizard.dataKey]?.[current[wizard.dataKey]?.questionsHash],
                batch: (current?.[wizard.dataKey]?.[current[wizard.dataKey]?.questionsHash]?.batch ?? 0) + 1
              }
            }
          })(current);
        });
      },
      ButtonProps: {
        disabled: (
          calculating ||
          ((questionsData?.batch + 1) * constants.analyse.batchSizes.tag) >= resultsCount
        )
      }
    }]
  }, [questionsData?.batch, resultsCount, calculating, wizard.dataKey, setDataEvent]);

  const renderBarContext = () => {
    return <React.Fragment>
      <Box className="EntitiesAnalyseTagWizardContent-info">
        <Typography className="EntitiesAnalyseTagWizardContent-total" variant="caption">
          {utils.formatNumber(totalDone)} / {utils.formatNumber(resultsCount)}
        </Typography>
        <Typography className="EntitiesAnalyseTagWizardContent-credits" variant="caption">
          {utils.formatNumber(Math.round(questionsData?.credits ?? 0))} credits
        </Typography>
      </Box>
    </React.Fragment>
  }

  const renderBar = () => {
    if (data?.questions) {
      return <SplitBar className="EntitiesAnalyseTagWizardContent-controls"
                       reverse={true}
                       actions={barActions}
                       context={renderBarContext()}/>
    }
  }

  return <StyledEntitiesAnalyseTagWizardContent ref={ref} {...innerProps}>
    {renderSubtitle()}
    <Table className="EntitiesAnalyseTagWizardContent-table"
           dataKey="entityId"
           enableTableHead={true}
           enableParentScroll={false}
           enableBottomToolbar={false}
           enableStickyHeader={true}
           enableSorting={false}
           enableEditing={false}
           enableColumnDragging={false}
           enableColumnActions={false}
           enableColumnOrdering={false}
           enableColumnResizing={false}
           enablePinning={false}
           enableColumnVirtualization={false}
           columns={columnsMemo}
           data={rows}
           rowCount={rows?.length}
           state={{
             isLoading: entitiesLoading,
             columnOrder: ['name', 'oneliner', 'match'],
             pagination: {
               pageIndex: 0,
               pageSize: rows?.length || 8
             }
           }} />
    {renderBar()}
  </StyledEntitiesAnalyseTagWizardContent>
});

EntitiesAnalyseTagWizardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  wizard: PropTypes.object,
  step: PropTypes.object,
  collection: PropTypes.object,
  tableProvider: PropTypes.object,
  onComplete: PropTypes.func,
  onDirty: PropTypes.func,
  onError: PropTypes.func,
};

EntitiesAnalyseTagWizardContent.defaultProps = {
};

export default EntitiesAnalyseTagWizardContent;
