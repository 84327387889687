import React, {
  Children,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useRecycleSlots} from 'helpers/hooks/utils';
import StyledList from 'components/atoms/Lists/List/List.styles';
import {useVirtualizer} from '@tanstack/react-virtual';
import utils from 'helpers/utils';
import dom from 'helpers/dom';
import constants from 'helpers/constants';
import SortableOverlay from 'components/organisms/Utils/DragDrop/SortableOverlay';
import SortableContext from 'components/organisms/Utils/DragDrop/SortableContext';
import SortableItem from 'components/organisms/Utils/DragDrop/SortableItem';
import DndMonitor from 'components/organisms/Utils/DragDrop/DndMonitor';
import {horizontalListSortingStrategy, verticalListSortingStrategy} from '@dnd-kit/sortable';
import useMediaQuery from '@mui/material/useMediaQuery';

const List = React.forwardRef((props, ref) => {
  const {
    track,
    loop,
    rows,
    columns,
    autoFocus,
    orientation,
    selectedIndex,
    sortable,
    containerId,
    reorder,
    renderOverlay,
    virtualize,
    estimatedSize,
    dragHandle,
    onCanDrag,
    onDragStart,
    onDragOver,
    onDragDrop,
    onDragStop,
    catchFocus,
    isLoading,
    onSelectionChange,
    onHighlightChange,
    SortableContextProps,
    ...innerProps
  } = useComponentProps(props, 'List', {
    styled: ['gap', 'divider'],
    static: ['virtualize'],
    variable: ['orientation']
  });

  const scrollInitialisedRef = useRef(false);
  const scrollToIndexRef = useRef(false);
  const focusedIndexRef = useRef({});
  const hoveredIndexRef = useRef({});
  const innerRef = useRef(null);

  const trackSelection = Boolean(onSelectionChange) || track;

  const [internalState, setInternalState] = useState({
    selectedIndex: selectedIndex,
    scrollElement: null,
    search: '',
    added: [],
    removed: [],
    reorder: []
  });

  const items = useMemo(() => {
    let items = [];
    if (innerProps.children) {
      items = Children.toArray(innerProps.children)
        .map((child, idx) => {
          return {
            id: child.props['data-key'] ?? idx,
            droppable: child.props['data-droppable'] !== false,
            position: (idx + 1) * 1000,
            data: child
          }
        });

      internalState.reorder.forEach((ro) => {
        const item = items.find((itm) => itm.id === ro.id);
        if (item) {
          item.position = ro.position;
        }
      });

      items = items
        .concat(internalState.added.filter((a) => !items.find((itm) => a.id === itm.id)))
        .filter((itm) => !internalState.removed.find((r) => r.id === itm.id))
        .sort((a, b) => a.position - b.position);
    }

    return items;
  }, [internalState.added, internalState.removed, internalState.reorder, innerProps.children]);

  const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const applyVirtualization = !isLoading && (virtualize ?? (items?.length > 100));
  const recycleSlots = useRecycleSlots();
  const virtualizer = useVirtualizer({
    overscan: 3,
    count: items?.length,
    horizontal: orientation === 'horizontal',
    getScrollElement: () => {
      if (applyVirtualization) {
        return internalState.scrollElement;
      }
    },
    estimateSize: () => {
      return estimatedSize ?? (smUp ? 36 : 48);
    },
    observeElementRect: (v, cb) => {
      return utils.observeResize(v.scrollElement, cb);
    },
    observeElementOffset: (v, cb) => {
      return utils.observeScroll(v.scrollElement, ({x, y}) => {
        v.options.horizontal ? cb(x) : cb(y);
        v.initialised = true;
      }, {
        timeout: constants.debounce.minimal, speed: 1, // > 1px per ms, then debounce else apply
        direction: v.options.horizontal ? 'horizontal' : 'vertical'
      });
    },
    scrollToFn: (offset, {adjustments = 0, behavior}, v) => {
      if (v.initialised) { // prevent scroll reset
        const toOffset = offset + adjustments;
        if (toOffset >= 0) {
          v.scrollElement?.scrollTo?.({
            [v.options.horizontal ? 'left' : 'top']: toOffset,
            behavior,
          });
        }
      }
    }
  });

  const list = useMemo(() => ({
    refs: {
      ref: innerRef
    },
    state: {
      ...internalState,
      ...utils.cleanObject({selectedIndex})
    },
    reset: () => {
      setInternalState((current) => ({
        ...current,
        added: [], removed: [], reorder: []
      }));
    },
    virtualizer,
  }), [internalState, virtualizer, selectedIndex]);

  useImperativeHandle(ref, () => list);

  const findListItem = (el) => {
    if (el && innerRef.current) {
      while (el && el !== innerRef.current) {
        if (utils.isDefined(el.dataset['listIndex'])) {
          return el;
        }
        el = el.parentElement;
      }
    }
    return null;
  };

  const findListItemByIndex = (index) => {
    return innerRef.current?.querySelector?.(`[data-list-index="${index}"]`);
  }

  const scrollToIndex = useCallback((idx, focus, center = false, force = false) => {
    if (utils.isDefined(idx)) {
      if (scrollToIndexRef.current !== idx || force) {
        scrollToIndexRef.current = idx;

        if (applyVirtualization) {
          if (virtualizer.getMeasurements().length > 0) {
            try {
              virtualizer.scrollToIndex(idx);
            } catch {
              /* SQUASH */
            }
          }
        }

        // retry to focus
        utils.retry((count) => {
          const el = findListItemByIndex(idx);

          if (el) {
            const scrolled = dom.scrollIntoView(el, {
              align: center ? 'center' : 'auto',
              preventScroll: !force
            });
            if (focus) {
              dom.focusElement(el, {preventScroll: true});
            }

            let done = false;
            if (focus) {
              done = true;
            } else if (!force) {
              done = scrolled;
            } else { // force we wait for page settle
              done = scrolled && (applyVirtualization ? count >= 5 : count >= 3);
            }
            scrollToIndexRef.current = done ? false : scrollToIndexRef.current;
            return done;
          }
        }, 5, 0, () => scrollToIndexRef.current !== idx);
      }
    }
  }, [applyVirtualization, virtualizer]);

  const onSelectionChangeEvent = useEffectEvent(onSelectionChange);
  const doSelectionChange = useCallback((e, idx, reason, focus, scroll = true) => {
    hoveredIndexRef.current.index = null;
    onSelectionChangeEvent?.(e, idx, reason);
    setInternalState(utils.updater({selectedIndex: idx}, true));

    if (scroll) {
      scrollToIndex(idx, focus);
    }
  }, [scrollToIndex, onSelectionChangeEvent]);

  useLayoutEffect(() => {
    if (trackSelection) {
      if (utils.isDefined(selectedIndex) && selectedIndex !== internalState.selectedIndex) {
        setInternalState(utils.updater({selectedIndex}, true));
        scrollToIndex(selectedIndex, dom.isPartOfParent(document.activeElement, innerRef.current),
          true, internalState.selectedIndex !== -1);
      } else if (!scrollInitialisedRef.current) {
        scrollToIndex(selectedIndex, dom.isPartOfParent(document.activeElement, innerRef.current),
          true, true);
      }
    }
    scrollInitialisedRef.current = true;
  }, [trackSelection, scrollToIndex, selectedIndex, internalState.selectedIndex, virtualize]);

  const handleMouseDown = (e) => {
    if (!list.state.dragItem && trackSelection) {
      const el = findListItem(e?.target);
      if (el) {
        doSelectionChange(e, utils.toNumber(el.dataset['listIndex']), 'mouse', false, true);
      }
    }
  };

  const debouncedClear = useMemo(() => {
    return utils.debounce(() => {
      setInternalState((current) => ({
        ...current,
        search: ''
      }));
    }, constants.debounce.input);
  }, []);

  const debouncedSearch = useMemo(() => {
    return utils.debounce((search) => {
      if (search?.length > 0) {
        if (search.trim()) {
          const elements = innerRef.current?.querySelectorAll?.(`[data-list-index]`);
          const item = Array.from(elements ?? []).find((itm) => {
            return (itm.innerText).toLowerCase().includes(search.trim().toLowerCase())
          });

          if (item) {
            doSelectionChange(utils.createEvent('keydown'), +item.dataset['listIndex'], 'keyboard', true);
          } else {
            const itemIndex = (items ?? []).findIndex((item) => {
              return utils.reactElementToText(item.data).toLowerCase().includes(search.trim().toLowerCase());
            });

            if (itemIndex !== -1) {
              doSelectionChange(utils.createEvent('keydown'), itemIndex, 'keyboard', true);
            }
          }
        }

        debouncedClear();
      }
    }, constants.debounce.input);
  }, [debouncedClear, doSelectionChange, items]);

  const handleKeyDown = (e) => {
    if (list.state.dragItem) {
      if (e.key === 'Escape') {
        const event = utils.createEvent('keydown', {...e});
        window.document.body.dispatchEvent(event);
        e.stopPropagation();
      }
    } else if (trackSelection) {
      const children = Children.toArray(innerProps.children);
      const childCount = children.length;
      const currentSelectedIndex = hoveredIndexRef.current?.index ?? list.state.selectedIndex;
      if (orientation === 'horizontal' ? (e.key === 'ArrowLeft' || (e.key === 'ArrowUp' && rows > 1)) :
          (e.key === 'ArrowUp' || (e.key === 'ArrowLeft' && columns > 1))) {
        let step = (orientation === 'horizontal') ? (e.key === 'ArrowLeft' ? 1 : columns) : (e.key === 'ArrowUp' ? 1 : rows);
        let newIndex = (utils.isDefined(focusedIndexRef.current.index) && focusedIndexRef.current.index !== currentSelectedIndex) ?
          focusedIndexRef.current.index : ((currentSelectedIndex ?? 1) - step);
        if (step > 1 && newIndex < 0) {
          newIndex -= (newIndex === -step) ? -(step - 1) : 1;
        }
        newIndex = loop ? ((newIndex < 0 ? (childCount + newIndex) : newIndex) % childCount) : newIndex;
        if (newIndex >= 0) {
          if (children[newIndex]?.props?.variant === 'heading' || children[newIndex]?.props?.disabled) {
            newIndex = newIndex - step;
            newIndex = loop ? ((newIndex < 0 ? (childCount + newIndex) : newIndex) % childCount) : newIndex;
          }

          if (newIndex >= 0) {
            doSelectionChange(e, newIndex, 'keyboard', true, true);
          }
        }
        e.preventDefault();
      } else if (orientation === 'horizontal' ? (e.key === 'ArrowRight' || (e.key === 'ArrowDown' && rows > 1)) :
                 (e.key === 'ArrowDown' || (e.key === 'ArrowRight' && columns > 1))) {
        let step = (orientation === 'horizontal') ? (e.key === 'ArrowRight' ? 1 : columns) : (e.key === 'ArrowDown' ? 1 : rows);
        let newIndex = (utils.isDefined(focusedIndexRef.current.index) && focusedIndexRef.current.index !== currentSelectedIndex) ?
          focusedIndexRef.current.index : ((currentSelectedIndex ?? -1) + step);
        if (step > 1 && newIndex >= childCount) {
          newIndex += (newIndex === (childCount + step - 1)) ? -(step -1) : 1;
        }
        newIndex = loop ? ((newIndex < 0 ? (childCount + newIndex) : newIndex) % childCount) : newIndex;
        if (newIndex < childCount) {
          if (children[newIndex]?.props?.variant === 'heading' || children[newIndex]?.props?.disabled) {
            newIndex = newIndex + step;
            newIndex = loop ? ((newIndex < 0 ? (childCount + newIndex) : newIndex) % childCount) : newIndex;
          }

          if (newIndex < childCount) {
            doSelectionChange(e, newIndex, 'keyboard', true, true);
          }
        }
        e.preventDefault();
      } else {
        if (e.key?.length === 1) {
          setInternalState((current) => {
            return {
              ...current,
              search: current.search + e.key
            }
          });
        } else {
          setInternalState((current) => {
            return {
              ...current,
              search: ''
            }
          });
        }
      }
    }
  }

  useEffect(() => {
    debouncedSearch(internalState.search);
  }, [internalState.search, debouncedSearch]);

  useEffect(() => {
    if (autoFocus) {
      const focus = () => {
        if (!dom.isPartOfParent(document.activeElement, innerRef.current)) {
          return dom.focusElement(innerRef.current);
        }
      }

      utils.retry(focus, 3);
    }
  }, [autoFocus]);

  const handleFocus = (e) => {
    if (!list.state.dragItem && trackSelection && scrollInitialisedRef.current) {
      const el = findListItem(e.target);
      if (el) {
        focusedIndexRef.current.index = utils.toNumber(el.dataset['listIndex']);
        if (focusedIndexRef.current.index !== list.state.selectedIndex) {
          setTimeout(() => {
            doSelectionChange(e, focusedIndexRef.current.index, 'focus', false);
          }, constants.debounce.minimal);
        }
      }
    }
  };

  const handleBlur = (e) => {
    if (!list.state.dragItem && trackSelection) {
      if (!dom.isPartOfParent(e.target, innerRef.current) || !utils.isDefined(e.relatedTarget)) {
        hoveredIndexRef.current.index = null;
        focusedIndexRef.current.index = null;
        onHighlightChange?.(e, null);
        setInternalState(utils.updater({selectedIndex: -1}, true));
      }
    }
  };

  const handleMouseEnter = () => {
    if (!list.state.dragItem && trackSelection && catchFocus) {
      if (utils.isDefined(list.state.selectedIndex)) {
        if (!dom.isPartOfParent(document.activeElement, innerRef.current)) {
          focusedIndexRef.current.index = list.state.selectedIndex;
          if (!dom.focusElement(findListItemByIndex(list.state.selectedIndex), {preventScroll: true})) {
            dom.focusElement(innerRef.current, {preventScroll: true});
          }
        }
      } else {
        dom.focusElement(innerRef.current, {preventScroll: true});
      }
    }
  };

  const handleMouseLeave = () => {
    if (!list.state.dragItem && trackSelection) {
      hoveredIndexRef.current.index = null;
      if (catchFocus) {
        document.activeElement?.blur?.();
        innerRef.current?.blur?.();
      }
    }
  };

  const handleMouseOver = (e) => {
    if (!list.state.dragItem && trackSelection) {
      if (catchFocus && !dom.isPartOfParent(document.activeElement, innerRef.current)) {
        dom.focusElement(innerRef.current, {preventScroll: true});
      }

      const el = findListItem(e?.target);
      if (el) {
        const scroll = internalState.scrollElement ? {
          x: internalState.scrollElement === window ? internalState.scrollElement.scrollX : internalState.scrollElement.scrollLeft,
          y: internalState.scrollElement === window ? internalState.scrollElement.scrollY : internalState.scrollElement.scrollTop,
        } : null;
        const delta = Math.max(Math.abs((hoveredIndexRef.current?.x ?? 0) - e.clientX), Math.abs((hoveredIndexRef.current?.y ?? 0) - e.clientY));
        const scrollDelta = ((scroll?.x ?? 0) - (hoveredIndexRef.current?.scroll?.x ?? 0)) + ((scroll?.y ?? 0) - (hoveredIndexRef.current?.scroll?.y ?? 0));

        const idx = utils.toNumber(el.dataset['listIndex']);
        if (delta > 0 || !utils.isDefined(list.state.selectedIndex) ||
          (scrollDelta > 0 && idx > list.state.selectedIndex) || (scrollDelta < 0 && idx < list.state.selectedIndex)) {
          onHighlightChange?.(e, idx);
        }

        if (delta > 0) {
          focusedIndexRef.current.index = null;
          hoveredIndexRef.current = {
            x: e.clientX,
            y: e.clientY,
            scroll,
            index: utils.toNumber(el.dataset['listIndex'])
          };
        }
      }
    }
  };

  innerProps.style = {
    ...(applyVirtualization ? (
      orientation === 'horizontal' ? {
        width: `${virtualizer.getTotalSize()}px`
      } : {
        height: `${virtualizer.getTotalSize()}px`
      }
    ): {}),
    ...innerProps.style
  };

  // sortable
  const handleDragStart = ({active}) => {
    setInternalState(utils.updater({dragItem: active}, true));

    onDragStart?.();
  }

  const handleDragEnd = ({over}) => {
    const dragItem = internalState.dragItem;
    setInternalState(utils.updater({dragItem: null}, true));

    const sourceContainerId = dragItem?.data?.current?.sortable?.containerId;
    const overContainerId = over?.data?.current?.sortable?.containerId ?? over?.id;

    if (dragItem && overContainerId) {
      const overIndex = over?.data?.current?.sortable?.index ?? 0;

      if (overIndex >= 0) {
        if (containerId.toString() === overContainerId.toString()) {
          const reordering = containerId.toString() === sourceContainerId.toString();
          if (reorder || !reordering) {
            let itemIndex = items.findIndex((itm) => itm.id === dragItem.id);
            let position = items[overIndex].id === dragItem.id ? items[overIndex].position :
              (!reordering ? (
                (overIndex < (items.length - 1)) ? (items[overIndex]?.position - 1) : (items[overIndex]?.position + 1)
              ) : (
                itemIndex < overIndex ? items[overIndex]?.position + 1 : items[overIndex]?.position - 1
              ));

            let added = [...list.state.added];
            let reorder = [...list.state.reorder];
            const addedIndex = added.findIndex((a) => a.id === dragItem.id);
            if (addedIndex !== -1) {
              added[addedIndex].position = position;
            } else {
              const existing = items.find((a) => a.id === dragItem.id);
              reorder = reorder.filter((r) => r.id !== dragItem.id);
              reorder.push({
                ...existing,
                position
              });
            }

            setInternalState((current) => ({
              ...current,
              added: added,
              reorder: reorder
            }));
          }
        }

        if (containerId.toString() === sourceContainerId.toString()) {
          onDragDrop?.(dragItem.id, overContainerId, overIndex);
        }
      }
    }

    onDragStop?.();
  }

  const handleDragCancel = ({active}) => {
    setInternalState(utils.updater({dragItem: null}, true));

    const payload = active?.data?.current?.payload;
    if (payload) {
      setInternalState((current) => ({
        ...current,
        added: [], removed: []
      }));
    }

    onDragStop?.();
  }

  const handleDragOver = ({over}) => {
    const dragItem = internalState.dragItem;
    const overContainerId = over?.data?.current?.sortable?.containerId ?? over?.id;
    const sourceContainerId = dragItem?.data?.current?.sortable?.containerId;
    const overItem = Boolean(over?.data?.current?.sortable?.containerId);

    if (dragItem) {
      const payload = dragItem?.data?.current?.payload;

      if (payload) {
        if (overContainerId) {
          if (containerId.toString() === overContainerId.toString()) {
            if (!items.find((itm) => itm.id === dragItem.id)) {
              const added = list.state.added.concat((overContainerId.toString() !== sourceContainerId.toString()) ? [{
                id: dragItem.id,
                data: payload,
                droppable: true,
                position: overContainerId.toString() === sourceContainerId.toString() ?
                  dragItem?.data?.current?.position : (
                    overItem ? constants.numbers.maxInt : 0
                  )
              }] : []);
              const removed = list.state.removed.filter((r) => r.id !== dragItem.id);

              setInternalState((current) => ({
                ...current,
                added, removed
              }));

              onDragOver?.(true, added.map((itm) => itm.id), removed.map((itm) => itm.id));
            }
          } else {
            if (items.find((itm) => itm.id === dragItem.id)) {
              const added = list.state.added.filter((r) => r.id !== dragItem.id);
              const removed = list.state.removed.concat({id: dragItem.id});
              setInternalState((current) => ({
                ...current,
                added, removed
              }));

              onDragOver?.(true, added.map((itm) => itm.id), removed.map((itm) => itm.id));
            }
          }
        } else {
          const added = list.state.added.filter((r) => r.id !== dragItem.id);
          const removed = list.state.removed.filter((r) => r.id !== dragItem.id);
          setInternalState((current) => ({
            ...current,
            added, removed
          }));

          onDragOver?.(false, added.map((itm) => itm.id), removed.map((itm) => itm.id));
        }
      }
    }
  }

  const renderWrap = (item, idx, slot, props) => {
    if (sortable && item.droppable) {
      const draggable = onCanDrag?.(item.id) ?? true;
      // sortable needs stable key to work properly use recycleKey
      return <SortableItem key={item.id}
                           id={item.id}
                           recycleKey={`key_${slot}`}
                           dragHandle={dragHandle}
                           payload={item.data}
                           position={item.position}
                           sourceContainerId={list.state.dragItem?.data?.current?.sortable?.containerId}
                           disableReorder={!reorder}
                           disabled={!draggable}
                           {...props}>
        {item.data}
      </SortableItem>
    } else {
      // key is used to recycle elements
      return <React.Fragment key={`key_${slot}`}>
        {utils.cloneElement(item.data, {...props})}
      </React.Fragment>
    }
  }

  const debouncedScrollElement = useMemo(() => {
    return utils.debounce((el) => {
      if (el) {
        const element = () => {
          const scrollElement = dom.getScrollElement(el, orientation === 'horizontal', orientation === 'vertical');
          setInternalState((current) => {
            if (current.scrollElement !== scrollElement) {
              return {...current, scrollElement};
            } else {
              return current;
            }
          });

          return Boolean(scrollElement);
        }

        utils.retry(element);
      }
    }, constants.debounce.minimal, null, true);
  }, [orientation]);

  const debouncedRestoreFocus = useMemo(() => {
    return utils.debounce((el) => {
      if (el) {
        if (dom.isPartOfParent(document.activeElement, innerRef.current)) {
          if (!list.state.dragItem && trackSelection) {
            const listItemEl = findListItemByIndex(focusedIndexRef.current.index ?? list.state.selectedIndex);
            if (listItemEl) {
              dom.focusElement(listItemEl, {preventScroll: true});
            } else {
              dom.focusElement(el, {preventScroll: true});
            }
          }
        }
      }
    }, constants.debounce.minimal);
  }, [list.state.dragItem, trackSelection, list.state.selectedIndex]);

  useEffect(() => {
    if (internalState.scrollElement) {
      return utils.observeScroll(internalState.scrollElement, () => {
        debouncedRestoreFocus(innerRef.current);
      });
    }
  }, [internalState.scrollElement, debouncedRestoreFocus]);

  const handleRef = (el) => {
    innerRef.current = el;
    if (el) {
      debouncedScrollElement(el);
    }
  }

  const renderItem = (item, idx) => {
    if (applyVirtualization) {
      const slotIdx = recycleSlots.slot(item.id)?.idx;
      const virtualRow = virtualizer.getVirtualItems()[recycleSlots.slot(item.id)?.row];
      if (virtualRow || list.state.dragItem?.id === item.id) {
        const props = {
          ref: (el) => {
            virtualizer.measureElement(el);
          },
          style: orientation === 'horizontal' ? {
            position: 'absolute',
            top: 0,
            left: virtualRow?.start ?? 0,
            height: '100%',
            width: 'fit-content'
          } : {
            position: 'absolute',
            top: virtualRow?.start ?? 0,
            left: 0,
            width: '100%',
            height: 'fit-content'
          },
          'data-list-index': idx,
          'data-index': idx
        }

        return renderWrap(item, idx, virtualRow ? slotIdx : -1, props);
      } else {
        return null;
      }
    } else {
      return renderWrap(item, idx, idx, {'data-list-index': idx});
    }
  }

  const renderList = () => {
    if (applyVirtualization) {
      const virtualRows = virtualizer.getVirtualItems();
      recycleSlots.refresh(virtualRows, items);
    }

    return <StyledList ref={handleRef} {...innerProps}
                       $virtualize={applyVirtualization}
                       $sortable={sortable}
                       tabIndex={(trackSelection && catchFocus) ? 0 : -1}
                       onKeyDown={handleKeyDown}
                       onMouseEnter={handleMouseEnter}
                       onMouseLeave={handleMouseLeave}
                       onMouseOver={handleMouseOver}
                       onFocus={handleFocus}
                       onBlur={handleBlur}
                       onMouseDown={handleMouseDown}>
      {items.map((item, idx) => {
        return renderItem(item, idx);
      }).filter((_) => (_))}
    </StyledList>
  }

  innerProps.className = utils.flattenClassName(innerProps.className, {
    virtualize: applyVirtualization
  });

  if (sortable) {
    return <SortableContext id={containerId?.toString()}
                            items={items}
                            strategy={orientation === 'horizontal' ? horizontalListSortingStrategy : verticalListSortingStrategy}
                            {...SortableContextProps}>
      {renderList()}
      <SortableOverlay animate={false}>
        {list.state.dragItem ? (
          renderOverlay ? renderOverlay?.(list.state.dragItem) :
            utils.cloneElement(list.state.dragItem?.data?.current?.payload, {dragging: true})
        ) : null}
      </SortableOverlay>
      <DndMonitor onDragStart={handleDragStart}
                  onDragEnd={handleDragEnd}
                  onDragCancel={handleDragCancel}
                  onDragOver={handleDragOver} />
    </SortableContext>
  } else {
    return renderList();
  }
});

List.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  gap: PropTypes.number,
  divider: PropTypes.bool,
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),
  track: PropTypes.bool,
  catchFocus: PropTypes.bool,
  loop: PropTypes.bool,
  rows: PropTypes.number,
  columns: PropTypes.number,
  autoFocus: PropTypes.bool,
  sortable: PropTypes.bool,
  containerId: PropTypes.any,
  reorder: PropTypes.bool,
  renderOverlay: PropTypes.func,
  dragHandle: PropTypes.bool,
  onCanDrag: PropTypes.func,
  onDragStart: PropTypes.func,
  onDragOver: PropTypes.func,
  onDragDrop: PropTypes.func,
  onDragStop: PropTypes.func,
  virtualize: PropTypes.bool,
  estimatedSize: PropTypes.number,
  selectedIndex: PropTypes.number,
  onSelectionChange: PropTypes.func,
  onHighlightChange: PropTypes.func,
  SortableContextProps: PropTypes.object
};

List.defaultProps = {
  loop: true,
  orientation: 'vertical',
  catchFocus: true,
  reorder: true,
  containerId: 'list'
};

export default List;
