import React, {useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog.styles';
import DialogHeader from 'components/molecules/Dialogs/DialogHeader/DialogHeader';
import DialogFooter from 'components/molecules/Dialogs/DialogFooter/DialogFooter';
import Button from 'components/atoms/Buttons/Button/Button';
import constants from 'helpers/constants';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import DialogContent from 'components/atoms/Dialogs/DialogContent/DialogContent';
import Typography, {Span} from 'components/atoms/Text/Typography/Typography';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import Markdown from 'components/atoms/Formatters/Markdown/Markdown';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Close from '@mui/icons-material/Close';
import Check from '@mui/icons-material/Check';

const ConfirmDialog = React.forwardRef((props, ref) => {
  const {
    title,
    question,
    explanation,
    challenge,
    challengeLabel,
    challengeHelper,
    onConfirm,
    CancelButtonProps,
    ConfirmButtonProps,
    ...innerProps
  } = useComponentProps(props, 'ConfirmDialog', {
    static: ['challenge']
  });

  const [error, setError] = useState(null);
  const [dirty, setDirty] = useState(null);
  const [validation, setValidation] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const innerRef = useRef(null);
  const formRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  const fields = useMemo(() => {
    return challenge ? [
      {
        name: 'test',
        inlineLabel: challenge,
        type: constants.formFieldTypes.text,
        initial: challenge
      },
      {
        name: 'challenge',
        label: challengeLabel,
        placeholder: `Type '${challenge}'`,
        type: constants.formFieldTypes.text,
        validation: [constants.formFieldValidationTypes.text, 'match(test)'],
        initial: null,
        required: true,
        FormFieldProps: {
          variant: 'staticLabel',
          size: 'small',
          hiddenLabel: true,
          InputLabelProps: {
            shrink: true,
          }
        }
      }
    ] : null;
  }, [challenge, challengeLabel]);

  const doConfirm = (e, actions = null) => {
    setSubmitting(true);
    utils.asPromise(onConfirm)()
      .then(() => {
        innerRef.current?.close?.(e, 'confirmButtonClick');
      })
      .catch((msg) => {
        setError(msg ?? `${ConfirmButtonProps?.children ?? 'Confirm'} failed`);
      })
      .finally(() => {
        if (actions) {
          actions.setSubmitting(false);
        }
        setSubmitting(false);
      });
  };

  const handleCancelClick = (e) => {
    innerProps.onClose?.(e, 'cancelButtonClick');
  }

  const handleConfirmClick = (e) => {
    if (challenge) {
      formRef.current?.submit();
    } else {
      doConfirm(e);
    }
  };

  const handleSubmit = (values, actions) => {
    doConfirm(null, actions);
  }

  const handleValidating = (isValidating, isDirty, hasErrors) => {
    setDirty(isDirty);
    if (hasErrors) {
      setValidation('Please check if all fields have the correct values');
    } else {
      setValidation(null);
    }
  }

  const handleClose = (e, reason) => {
    if ((!submitting && !error && !dirty) || ['escapeKeyDown', 'closeButtonClick', 'confirmButtonClick'].includes(reason)) {
      innerProps.onClose?.(e, reason);
    }
  }

  const renderButtons = () => {
    return <React.Fragment>
      <Button children={'Cancel'}
              variant="text"
              startIcon={<Icon icon={Close} />}
              onClick={handleCancelClick}
              {...CancelButtonProps}/>
      <Button disabled={submitting}
              type="submit"
              variant="contained"
              children={'Confirm'}
              startIcon={<Icon icon={Check} />}
              onClick={handleConfirmClick}
              {...ConfirmButtonProps}/>
    </React.Fragment>
  };

  const renderChallenge = () => {
    if (challenge) {
      const renderHelper = () => {
        if (challengeLabel) {
          return <React.Fragment>
            the {challengeLabel} <Span copyText={true}>{challenge}</Span>
          </React.Fragment>
        } else {
          return <Span copyText={true}>{challenge}</Span>
        }
      }

      return <Box className="ConfirmDialog-challenge">
        {challengeHelper ? (utils.isReactElement(challengeHelper) ? challengeHelper :
          <Typography as="div"
                      className="ConfirmDialog-challengeHelper"
                      variant="subtitle2">
            <Markdown>{challengeHelper}</Markdown>
          </Typography>
        ) : <Typography className="ConfirmDialog-challengeHelper"
                        variant="subtitle2">
          Please type in {renderHelper()} to confirm`
        </Typography>}
        {challenge ? <InlineForm ref={formRef}
                                 className="ConfirmDialog-challengeForm"
                                 onSubmit={handleSubmit}
                                 onValidating={handleValidating}
                                 fields={fields}/> : null}
      </Box>
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className, {
    size: innerProps.size ?? (challenge ? 'medium' : 'small')
  });

  return <StyledConfirmDialog ref={innerRef} {...innerProps} onClose={handleClose}>
    <DialogHeader variant="compact" title={title} />
    <DialogContent>
      <Box className="ConfirmDialog-text">
        {question ? utils.isReactElement(question) ? question : <Typography className="ConfirmDialog-question"
                                                                            variant="subtitle1">{question}</Typography> : null}
        {explanation ? utils.isReactElement(explanation) ? explanation :
          <Typography className="ConfirmDialog-explanation"
                      variant="body1">{explanation}</Typography> : null}
      </Box>
      {renderChallenge()}
    </DialogContent>
    <DialogFooter className="ConfirmDialog-footer"
                  info={(error ?? validation) ? <Span color="error">{error ?? validation}</Span> : null}
                  buttons={renderButtons()} />
  </StyledConfirmDialog>
});

ConfirmDialog.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.string,
  question: PropTypes.string,
  explanation: PropTypes.any,
  challenge: PropTypes.string,
  challengeLabel: PropTypes.string,
  challengeHelper: PropTypes.string,
  onConfirm: PropTypes.func,
  CancelButtonProps: PropTypes.object,
  ConfirmButtonProps: PropTypes.object
};

ConfirmDialog.defaultProps = {
};

export default ConfirmDialog;
