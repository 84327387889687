import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledContextCard from 'components/molecules/Cards/ContextCard/ContextCard.styles';
import CardHeader from 'components/atoms/Cards/CardHeader/CardHeader';
import Typography from 'components/atoms/Text/Typography/Typography';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import CardActions from 'components/atoms/Cards/CardActions/CardActions';
import CardContent from 'components/atoms/Cards/CardContent/CardContent';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';

const ContextCard = React.forwardRef((props, ref) => {
  const {
    title,
    context,
    actionButtons,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'ContextCard', {
    children: ['header', 'content', 'actions']
  });

  const renderButtons = (items) => {
    return items.map((action, idx) => {
      if (action.label) {
        return <ActionButton key={idx}
                             action={action}
                             isLoading={isLoading}/>
      } else {
        return <ActionIconButton key={idx}
                                 action={action}
                                 isLoading={isLoading}/>
      }
    });
  }

  return <StyledContextCard ref={ref} {...innerProps}>
    {(title || context) ? <CardHeader className="ContextCard-header"
                                      title={title ?
                                        <Typography className="ContextCard-header-title"
                                                    variant="subtitle2"
                                                    min={8} max={20}
                                                    isLoading={isLoading}>
                                          {title}
                                        </Typography> : null}
                                      action={context} /> : null}

    {innerProps.children ? <CardContent className="ContextCard-content" density="densest">
      {innerProps.children}
    </CardContent> : null}

    {actionButtons?.length > 0 ? <CardActions className="ContextCard-actions">
      {renderButtons(actionButtons)}
    </CardActions> : null}
  </StyledContextCard>
});

ContextCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.string,
  context: PropTypes.any,
  actionButtons: PropTypes.array,
  isLoading: PropTypes.bool,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['standard', 'inline']), PropTypes.string])
};

ContextCard.defaultProps = {
  variant: 'standard'
};

export default ContextCard;
