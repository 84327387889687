import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';

import Typography from 'components/atoms/Text/Typography/Typography';
import {Box} from '@mui/material';
import TableFiltersContentFilter
  from 'components/organisms/TableFilters/TableFiltersContentFilter/TableFiltersContentFilter';
import StyledTableFiltersContent
  from 'components/organisms/TableFilters/TableFiltersContent/TableFiltersContent.styles';

const TableFiltersContent = React.forwardRef((props, ref) => {
  const {
    tableFilters,
    filterGroup,
    filters,
    showGroupTitle,
    fieldData,
    onChange,
    onClear,
    ...innerProps
  } = useComponentProps({...props, ...utils.filterObject(props.filterGroup, [
      'filters'
    ], false), ...props.filterGroup?.TableFiltersContentProps}, 'TableFiltersContent', {
    children: ['title', 'name', 'filter']
  });

  const authorize = useAuthorize();

  const filtersMemo = useMemo(() => {
    if (filters) {
      return filters
        .sort((a, b) => a.position - b.position)
        .map((f) => {
          return {
            ...f,
            required: false,
            readOnly: !authorize(f.auth?.update ?? {}),
            FormFieldProps: {
              fullWidth: true,
              hiddenLabel: true,
              size: 'smaller',
              ...f.FormFieldProps
            }
          }
        });
    } else {
      return null;
    }
  }, [authorize, filters]);

  const handleClear = (filter) => {
    onClear?.([filter]);
  }

  return <StyledTableFiltersContent ref={ref} {...innerProps}>
    {(filtersMemo.length > 0 && showGroupTitle) ? <Box className="TableFiltersContent-title">
      <Typography variant="tableHeader">{filterGroup.title}</Typography>
    </Box> : null}
    {filtersMemo?.map((filter) => {
      return <TableFiltersContentFilter key={filter.name}
                                        className="TableFiltersContent-filter"
                                        filter={filter}
                                        filterGroup={filterGroup}
                                        tableFilters={tableFilters}
                                        fieldData={fieldData}
                                        onChange={onChange}
                                        onClear={handleClear}/>
    })}
  </StyledTableFiltersContent>
});

TableFiltersContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  showGroupTitle: PropTypes.bool,
  tableFilters: PropTypes.object,
  filterGroup: PropTypes.object,
  filters: PropTypes.array,
  fieldData: PropTypes.object,
  onChange: PropTypes.func,
  onClear: PropTypes.func
};

TableFiltersContent.defaultProps = {};

export default TableFiltersContent;
