import React, {useCallback, useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useOverflowShadow} from 'helpers/hooks/utils';
import DialogContent from 'components/atoms/Dialogs/DialogContent/DialogContent';
import constants from 'helpers/constants';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import {useEntityCallbacks} from 'services/entity/entity.utils';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Search from '@mui/icons-material/Search';
import DialogHeader from 'components/molecules/Dialogs/DialogHeader/DialogHeader';
import Button from 'components/atoms/Buttons/Button/Button';
import DialogFooter from 'components/molecules/Dialogs/DialogFooter/DialogFooter';
import {Span} from 'components/atoms/Text/Typography/Typography';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import Close from '@mui/icons-material/Close';
import Save from '@mui/icons-material/Save';
import StyledEntitySelectionDialog
  from 'components/organisms/Dialogs/EntitySelectionDialog/EntitySelectionDialog.styles';
import EntityCard from 'components/molecules/Cards/EntityCard/EntityCard';

const EntitySelectionDialog = React.forwardRef((props, ref) => {
  const {
    title,
    subtitle,
    onClose,
    onChange,
    onSubmit,
    entities,
    multiple,
    required,
    hideEntities,
    entityFilter,
    disableEntities,
    SaveButtonProps,
    ...innerProps
  } = useComponentProps(props, 'EntitySelectionDialog');

  const innerRef = useRef(null);
  const formRef = useRef(null);
  const overflowRef = useRef(null);

  const [error, setError] = useState(null);
  const [dirty, setDirty] = useState(null);
  const [validation, setValidation] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [selection, setSelection] = useState(null);
  const [scrollElement, setScrollElement] = useState(null);

  useImperativeHandle(ref, () => innerRef.current);

  const callbacks = useEntityCallbacks();

  useOverflowShadow(scrollElement, null, overflowRef.current);

  const onSubmitEvent = useEffectEvent(onSubmit);
  const onCloseEvent = useEffectEvent(onClose);
  const doSubmit = useCallback((collections) => {
    return utils.asPromise(onSubmitEvent)(collections)
      .then(() => {
        onCloseEvent?.(null, 'saveButtonClick');
      });
  }, [onSubmitEvent, onCloseEvent]);

  const fields = useMemo(() => ([{
    name: 'entity',
    placeholder: 'Search for company',
    type: constants.formFieldTypes.list,
    validation: Boolean(multiple) ? constants.formFieldValidationTypes.list : constants.formFieldValidationTypes.number,
    initial: hideEntities ? [] : entities?.map((e) => ({
      entity: e,
      label: e.name,
      value: e.entityId,
      disabled: disableEntities
    })),
    options: entityFilter ? ({search, ids, filter, callback}) => {
      return callbacks.entities({search, ids, filter: utils.addFilter(filter, entityFilter), callback});
    } : callbacks.entities,
    prefix: <Icon icon={Search} />,
    required: true,
    filter: hideEntities ? entities?.map((e) => `-${e.entityId}`) : null,
    FormFieldProps: {
      multiple: Boolean(multiple),
      required: Boolean(required),
      autoFocus: true,
      showSearch: true,
      hiddenLabel: true,
      fullHeight: true,
      variant: 'outlined',
      size: 'smaller',
      emptyText: 'No companies found',
      limitOptions: false,
      hiddenHelperText: true,
      ref: (el) => {
        setScrollElement(el?.ref?.current?.refs?.ref?.current?.querySelector('.ListField-list'));
      },
      ListProps: {
        gap: 6
      },
      renderOption: (option, props) => {
        const handleClick = () => {
          props.onChange?.({
            target: {
              name: props.name,
              checked: !props.checked
            }
          })
        };

        return <EntityCard entity={option.entity}
                           variant="outlined"
                           selected={props.checked}
                           disabled={props.disabled}
                           onClick={handleClick}
                           fullWidth={true}
                           showLink={true}
                           showStatus={false}
                           showRelevancy={false}/>
      }
    }
  }]), [callbacks, entities, multiple, required, hideEntities, entityFilter, disableEntities]);

  const handleChangeDirect = (e) => {
    const values = utils.toArray(e.target.value);
    const selected = utils.toArray(selection ?? fields[0].initial);

    const added = values.filter((opt) => !selected.find((sel) => sel.value === opt.value));
    const removed = selected.filter((sel) => !values.find((opt) => sel.value === opt.value));

    if (added.length > 0 || removed.length > 0) {
      const entities = values.map((v) => v.entity);

      setSelection(values);
      if (onChange) {
        (removed.length > 0 ? utils.asPromise(onChange)(removed[0].entity, entities, true) : utils.asPromise(onChange)(added[0].entity, entities, false))
          .catch(() => {
            setError(removed.length > 0 ? 'Removing company failed' : 'Adding company failed');
          })
      }
    }

    setError(null);
    setValidation(null);
  };

  const handleSubmit = (values, actions) => {
    setSubmitting(true);

    doSubmit(values['entity'].map((v) => v.entity))
      .catch(() => {
        setError('Adding to entity failed');
      })
      .finally(() => {
        actions.setSubmitting(false);
        setSubmitting(false);
      });
  };

  const handleValidating = (isValidating, isDirty, hasErrors) => {
    setDirty(isDirty);
    if (hasErrors) {
      setValidation('Please choose a company');
    } else {
      setValidation(null);
    }
  }

  const handleSubmitClick = (e) => {
    if (onSubmit) {
      formRef.current?.submit();
    } else {
      innerRef.current?.close?.(e)
    }
  }

  const handleCancelClick = (e) => {
    onClose?.(e, 'cancelButtonClick');
  }

  const handleClose = (e, reason) => {
    if ((!submitting && !error && !dirty) || ['escapeKeyDown', 'closeButtonClick', 'cancelButtonClick'].includes(reason)) {
      onClose?.(e, reason);
    }
  }

  const renderButtons = () => {
    return <React.Fragment>
      <Button variant="text"
              children="Cancel"
              startIcon={<Icon icon={Close}/>}
              disabled={submitting}
              onClick={handleCancelClick}/>
      <Button type="submit"
              variant="contained"
              children="Save"
              startIcon={<Icon icon={Save}/>}
              disabled={submitting}
              onClick={handleSubmitClick}
              {...SaveButtonProps}/>
    </React.Fragment>
  }

  return <StyledEntitySelectionDialog ref={innerRef} {...innerProps} onClose={handleClose}>
    <DialogHeader title={title ?? 'Add company'}
                  subtitle={subtitle} />
    <DialogContent>
      <InlineForm ref={formRef}
                  className="EntitySelectionDialog-form"
                  fields={fields}
                  onValidating={handleValidating}
                  onSubmit={handleSubmit}
                  onChangeDirect={handleChangeDirect}/>
      <Box ref={overflowRef} className="EntitySelectionDialog-overflow" />
    </DialogContent>
    <DialogFooter className="EntitySelectionDialog-footer"
                  info={(error ?? validation) ? <Span color="error">{error ?? validation}</Span> : null}
                  buttons={renderButtons()} />
  </StyledEntitySelectionDialog>
});

EntitySelectionDialog.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  entities: PropTypes.array,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  hideCreate: PropTypes.bool,
  hideEntities: PropTypes.bool,
  entityFilter: PropTypes.any,
  disableEntities: PropTypes.bool,
  SaveButtonProps: PropTypes.object
};

EntitySelectionDialog.defaultProps = {
  multiple: true
};

export default EntitySelectionDialog;
