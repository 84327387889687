import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import DataList from 'components/organisms/Lists/DataList/DataList';

const StyledThemesList = styled(DataList)`
  &.ThemesList {
    .List {
      padding: 0;
    }
    .ListItem {
      .Card-button {
        width: 100%
      }

      .ThemeCard {
        border-radius: 0;
        box-shadow: unset;
        border-bottom: 1px solid ${props => props.theme.property('palette.divider')};
      }
    }
  }

  &.ThemesList-orientation-horizontal {
    height: ${props => props.theme.layout(160)};
  }
  
  ${ComponentStyles}
`;

StyledThemesList.propTypes = {
  theme: PropTypes.object
};

StyledThemesList.defaultProps = {}

export default StyledThemesList;
