import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import TimelineTableCell from 'components/molecules/TableCells/TimelineTableCell/TimelineTableCell';
import StyledTimelineTableCellEdit
  from 'components/organisms/TableCellEdits/TimelineTableCellEdit/TimelineTableCellEdit.styles';
import EntityTimelinePanel from 'components/organisms/Panels/EntityTimelinePanel/EntityTimelinePanel';
import {withMemo} from 'helpers/wrapper';

const TimelineTableCellEdit = withMemo(React.forwardRef((props, ref) => {
  const {
    cell,
    table,
    entity,
    collection,
    canUpdate,
    ...innerProps
  } = useComponentProps(props, 'TimelineTableCellEdit');

  const [busy, setBusy] = useState(false);

  const handleDirty = (dirty) => {
    setBusy(dirty);
  }

  return <StyledTimelineTableCellEdit ref={ref} {...innerProps}
                                      cell={cell}
                                      table={table}
                                      autoClose={!busy}
                                      TableCellPopperProps={{
                                        density: 'densest'
                                      }}
                                      Anchor={<TimelineTableCell comments={entity.totalComments}
                                                                 unread={entity.unreadComments > 0}
                                                                 active={true}/>}>
    <EntityTimelinePanel variant="simple"
                         entity={entity}
                         collection={collection}
                         commentsOnly={true}
                         canUpdate={canUpdate}
                         onDirty={handleDirty}
                         TimelineListProps={{
                           loaderCount: 6,
                           onEdit: (e, item, editing) => {
                             setBusy(editing);
                             e?.stopPropagation();
                           }
                         }}/>
  </StyledTimelineTableCellEdit>
}));

TimelineTableCellEdit.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  table: PropTypes.object,
  cell: PropTypes.object,
  entity: PropTypes.object,
  collection: PropTypes.object,
  canUpdate: PropTypes.bool
};

TimelineTableCellEdit.defaultProps = {
};

export default TimelineTableCellEdit;


