import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDealflowStatusTableCellEdit
  from 'components/organisms/TableCellEdits/DealflowStatusTableCellEdit/DealflowStatusTableCellEdit.styles';
import DealflowStatusTableCell from 'components/molecules/TableCells/DealflowStatusTableCell/DealflowStatusTableCell';
import DealflowStatusList from 'components/molecules/Lists/DealflowStatusList/DealflowStatusList';
import utils from 'helpers/utils';
import {withMemo} from 'helpers/wrapper';
import constants from 'helpers/constants';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';

const DealflowStatusTableCellEdit = withMemo(React.forwardRef((props, ref) => {
  const {
    cell,
    table,
    statusId,
    size,
    onChange,
    ...innerProps
  } = useComponentProps(props, 'DealflowStatusTableCellEdit');

  const snackbar = useSnackbar();

  const handleClick = (e, status) => {
    cell.column.columnDef.optimistic.set(cell, status.statusId);
    table.setEditingCell(null);
    utils.asPromise(onChange)(cell.row.original, status.statusId)
      .catch(() => {
        cell.column.columnDef.optimistic.reset(cell);
        table.setEditingCell(cell);
        snackbar.show('Saving failed', null,
          {color: 'error', autoHideDuration: constants.delay.error});
      });

    e.preventDefault();
  }

  return <StyledDealflowStatusTableCellEdit ref={ref} {...innerProps}
                                            cell={cell}
                                            table={table}
                                            TableCellPopperProps={{
                                              size: 'medium',
                                              density: 'densest'
                                            }}
                                            Anchor={<DealflowStatusTableCell statusId={statusId}
                                                                             size={size}
                                                                             active={true}/>}>
    <DealflowStatusList statusId={statusId} track={true} onClick={handleClick}/>
  </StyledDealflowStatusTableCellEdit>
}));

DealflowStatusTableCellEdit.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  table: PropTypes.object,
  cell: PropTypes.object,
  status: PropTypes.number,
  onChange: PropTypes.func
};

DealflowStatusTableCellEdit.defaultProps = {
  size: 'large'
};

export default DealflowStatusTableCellEdit;
