import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Dialog from 'components/organisms/Dialogs/Dialog/Dialog';

const StyledLogoDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: clamp(0px, ${props => props.theme.layout(440)}, 86vw);
    height: clamp(0px, ${props => props.theme.layout(510)}, 86vh);
  }
  
  .DialogContent {
    padding: ${props => props.theme.spacing(3)};
    
    .Form {
      .Form-fields {
        .FileField {
          &-dropzone {
            width: calc(100% - 2 * ${props => props.theme.layout('8sp')});
            margin: 0 ${props => props.theme.layout('8sp')};
          }

          &-delete {
            right: ${props => props.theme.layout('10sp')};
          }
          
          .MuiFormHelperText-root:has(*) {
            position: absolute;
            display: flex;
            width: 100%;
            bottom: ${props => props.theme.layout(-22)};
            height: ${props => props.theme.layout(24)};

            > * {
              width: 1px;
              flex-grow: 1;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;

              p {
                display: contents;
              }
            }
          }
        }
      }
    }
  }

  ${props => props.theme.breakpoints.down('xs')} {
    .DialogContent {
      .Form {
        .Form-fields {
          .FileField {
            &-dropzone {
              width: calc(100% - 2 * ${props => props.theme.layout('2.5sp')});
              margin: 0 ${props => props.theme.layout('2.5sp')};
            }
          }
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledLogoDialog.propTypes = {
  theme: PropTypes.object
};

StyledLogoDialog.defaultProps = {}

export default StyledLogoDialog;
