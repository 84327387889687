import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledCollectionProfileCardContent
  from 'components/organisms/Cards/CollectionProfileCardContent/CollectionProfileCardContent.styles';

const CollectionProfileCardContent = React.forwardRef((props, ref) => {
  const {
    content,
    ...innerProps
  } = useComponentProps(props, 'CollectionProfileCardContent');

  const contentMemo = useMemo(() => {
    if (content?.fields) {
      content.fields = content?.fields.map((field) => {
        if (field.name === 'projectFoundingYear') {
          field.FormFieldProps = {
            /* example */
            ...field.FormFieldProps
          };
        }

        return field;
      });
    }
    return content;
  }, [content]);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledCollectionProfileCardContent ref={ref} {...innerProps} content={contentMemo}/>
});

CollectionProfileCardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionProfileCardContent.defaultProps = {
};

export default CollectionProfileCardContent;
