import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDashboardCard from 'components/molecules/Cards/DashboardCard/DashboardCard.styles';
import {H5} from 'components/atoms/Text/Typography/Typography';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import CardContent from 'components/atoms/Cards/CardContent/CardContent';
import CardHeader from 'components/atoms/Cards/CardHeader/CardHeader';
import CardActions from 'components/atoms/Cards/CardActions/CardActions';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';

const DashboardCard = React.forwardRef((props, ref) => {
  const {
    title,
    context,
    actionButtons,
    variant,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'DashboardCard');

  const renderButtons = (items) => {
    return items.map((action, idx) => {
      if (action.label) {
        return <ActionButton key={idx}
                             action={action}
                             isLoading={isLoading}/>
      } else {
        return <ActionIconButton key={idx}
                                 action={action}
                                 isLoading={isLoading}/>
      }
    });
  }

  return <StyledDashboardCard ref={ref} {...innerProps}>
    {(title || context) ? <CardHeader className="DashboardCard-header"
                                      title={title ? <H5 className="DashboardCard-header-title" isLoading={isLoading}>{title}</H5> : null}
                                      action={context} /> : null}
    <CardContent className="DashboardCard-content">
      {innerProps.children}
    </CardContent>

    {actionButtons?.length > 0 ? <CardActions className="DashboardCard-actions">
      {renderButtons(actionButtons)}
    </CardActions> : null}

  </StyledDashboardCard>
});

DashboardCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.string,
  context: PropTypes.element,
  actionButtons: PropTypes.array,
  density: PropTypes.string
};

DashboardCard.defaultProps = {
  title: 'DashboardCard title',
  elevation: 1,
  radius: 'round'
};

export default DashboardCard;
