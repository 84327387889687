import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectItem} from 'helpers/hooks/utils';
import Component from 'components/organisms/Utils/Component/Component';
import ArticleCard from 'components/molecules/Cards/ArticleCard/ArticleCard';
import StyledArticlesList from 'components/organisms/Lists/ArticlesList/ArticlesList.styles';

const ArticlesList = React.forwardRef((props, ref) => {
  const {
    articles,
    Card,
    onClick,
    isLoading,
    ArticleCardProps,
    ...innerProps
  } = useComponentProps(props, 'ArticlesList');

  const ArticleCardDefault = ({article, ...props}) => {
    return <ArticleCard article={article}
                        fullWidth={true}
                        {...props}/>
  };

  const CardMemo = useEffectItem(Card ?? ArticleCardDefault);
  const renderCard = (article, state) => {
    return <Component Original={CardMemo}
                      article={article}
                      selected={state.selected}
                      onClick={onClick}
                      isLoading={state.isLoading}
                      {...ArticleCardProps} />
  };

  const isItemEqual = (article, selected) => {
    return +article.articleId === +selected.articleId;
  }

  return <StyledArticlesList ref={ref} {...innerProps}
                             data={articles?.data}
                             count={articles.meta?.resultsCount}
                             dataKey="articleId"
                             isItemEqual={isItemEqual}
                             renderItem={renderCard}
                             isLoading={isLoading || articles.status.isLoading}
                             showProgressBar={articles.status.isLoadingNext}
                             onFetchMore={articles.query?.fetchNextPage}
                             ListProps={{
                               track: true,
                               virtualize: true,
                               estimatedSize: 156,
                               gap: 8,
                             }}/>
});

ArticlesList.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  articles: PropTypes.object,
  Card: PropTypes.any,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  ArticleCardProps: PropTypes.object
};

ArticlesList.defaultProps = {
  loaderCount: 6
};

export default ArticlesList;
