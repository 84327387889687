import React, {useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import StyledSourceDatabaseSectionPanelContent
  from 'components/organisms/SectionPanels/SourceDatabaseSectionPanelContent/SourceDatabaseSectionPanelContent.styles';
import SourcesList from 'components/organisms/Lists/SourcesList/SourcesList';
import constants from 'helpers/constants';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Search from '@mui/icons-material/Search';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import Clear from '@mui/icons-material/Clear';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import dom from 'helpers/dom';

const SourceDatabaseSectionPanelContent = React.forwardRef((props, ref) => {
  const {
    databaseSources,
    search,
    onSearch,
    onSubmit,
    ...innerProps
  } = useComponentProps(props, 'SourceDatabaseSectionPanelContent');

  const searchFieldRef = useRef(null);
  const onSearchEvent = useEffectEvent(onSearch);
  const searchFields = useMemo(() => ([{
    name: 'search',
    label: 'Search',
    inlineLabel: 'search',
    type: constants.formFieldTypes.text,
    validation: constants.formFieldValidationTypes.text,
    initial: search,
    debounce: constants.debounce.search,
    FormFieldProps: {
      ref: searchFieldRef,
      hiddenLabel: true,
      size: 'smaller'
    },
    prefix: <Icon icon={Search} />,
    postfix: search?.length > 0 ? <ActionIconButton action={{
      icon: <Icon icon={Clear}/>,
      onClick: (e) => {
        onSearchEvent?.('');
        searchFieldRef.current?.focus();
        e.preventDefault();
      }
    }} /> : null
  }]), [search, onSearchEvent]);

  const handleClick = (source) => {
    onSubmit?.({...source, sourceId: source?.sourceId, type: source?.type});
  }

  const handleSearch = (field, value) => {
    onSearchEvent?.(value);
  }

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      dom.focusElement(dom.getParent(e.target, null, 'SourceDatabaseSectionPanelContent').querySelector('.SourcesList'));
      e.preventDefault();
    }
  }

  const renderHeader = () => {
    return <InlineForm className="SourceDatabaseSectionPanelContent-search"
                       onKeyDown={handleKeyDown}
                       onChange={handleSearch}
                       fields={searchFields} />
  }

  return <StyledSourceDatabaseSectionPanelContent ref={ref} {...innerProps} header={renderHeader()}>
    <SourcesList sources={databaseSources}
                 onClick={handleClick}
                 isLoading={databaseSources.status.isLoading}
                 emptyText="No database sources found"
                 SourceCardProps={{
                   variant: 'suggested'
                 }}
                 ListProps={{
                   catchFocus: false
                 }}/>
  </StyledSourceDatabaseSectionPanelContent>
});

SourceDatabaseSectionPanelContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  databaseSources: PropTypes.object,
  search: PropTypes.string,
  onSearch: PropTypes.func,
  onSubmit: PropTypes.func
};

SourceDatabaseSectionPanelContent.defaultProps = {};

export default SourceDatabaseSectionPanelContent;
