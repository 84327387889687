import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {withMemo} from 'helpers/wrapper';
import StyledQuestionnaireTableCellEdit
  from 'components/organisms/TableCellEdits/QuestionnaireTableCellEdit/QuestionnaireTableCellEdit.styles';
import QuestionnaireTableCell from 'components/molecules/TableCells/QuestionnaireTableCell/QuestionnaireTableCell';
import QuestionnaireCard from 'components/organisms/Cards/QuestionnaireCard/QuestionnaireCard';
import utils from 'helpers/utils';
import CardHeader from 'components/atoms/Cards/CardHeader/CardHeader';
import Typography from 'components/atoms/Text/Typography/Typography';
import constants from 'helpers/constants';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';

const QuestionnaireTableCellEdit = withMemo(React.forwardRef((props, ref) => {
  const {
    cell,
    table,
    value,
    entity,
    collection,
    canUpdate,
    fieldData,
    personal,
    lastAnswer,
    onChange,
    questionnaireQuestion,
    ...innerProps
  } = useComponentProps(props, 'QuestionnaireTableCellEdit');

  const [busy, setBusy] = useState(false);

  const snackbar = useSnackbar();

  const handleChange = (answer) => {
    cell.column.columnDef.optimistic.set(cell, {...answer, processedValue: answer.value});
    table.setEditingCell(null);
    utils.asPromise(onChange)(questionnaireQuestion, entity, answer)
      .catch(() => {
        cell.column.columnDef.optimistic.reset(cell);
        table.setEditingCell(cell);
        snackbar.show('Saving failed', null,
          {color: 'error', autoHideDuration: constants.delay.error});
      });
  }

  const handleDirty = (dirty) => {
    setBusy(dirty);
  }

  return <StyledQuestionnaireTableCellEdit ref={ref} {...innerProps}
                                           cell={cell}
                                           table={table}
                                           autoClose={!busy}
                                           Anchor={<QuestionnaireTableCell info={questionnaireQuestion.info}
                                                                           value={value}
                                                                           active={true}
                                                                           question={questionnaireQuestion.question}
                                                                           fieldData={fieldData}/>}>
    <CardHeader className="QuestionnaireTableCellEdit-header"
                title={<Typography variant="subtitle2">
                  {questionnaireQuestion.question.question}
                </Typography>}/>
    <QuestionnaireCard entity={entity}
                       collection={collection}
                       questionnaireQuestion={questionnaireQuestion}
                       canUpdate={canUpdate}
                       lastAnswer={lastAnswer}
                       isEditing={personal}
                       fieldData={fieldData}
                       onChange={handleChange}
                       onDirty={handleDirty} />
  </StyledQuestionnaireTableCellEdit>
}));

QuestionnaireTableCellEdit.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  table: PropTypes.object,
  cell: PropTypes.object,
  value: PropTypes.any,
  entity: PropTypes.object,
  collection: PropTypes.object,
  canUpdate: PropTypes.bool,
  fieldData: PropTypes.object,
  lastAnswer: PropTypes.object,
  personal: PropTypes.bool,
  onChange: PropTypes.func,
  questionnaireQuestion: PropTypes.object
};

QuestionnaireTableCellEdit.defaultProps = {
};

export default QuestionnaireTableCellEdit;


