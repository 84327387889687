import React, {useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useUploadFiles} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import constants from 'helpers/constants';
import Box from 'components/atoms/Layout/Box/Box';
import {P} from 'components/atoms/Text/Typography/Typography';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Search from '@mui/icons-material/Search';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import Clear from '@mui/icons-material/Clear';
import Add from '@mui/icons-material/Add';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';
import Delete from '@mui/icons-material/Delete';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import {
  useEntityUploadGet,
} from 'services/entity/upload/upload.hooks';
import FileUploadDialog from 'components/organisms/Dialogs/FileUploadDialog/FileUploadDialog';
import FilesList from 'components/organisms/Lists/FilesList/FilesList';
import StyledEntityFilesProfileCardContent
  from 'components/organisms/Cards/EntityFilesProfileCardContent/EntityFilesProfileCardContent.styles';

const EntityFilesProfileCardContent = React.forwardRef((props, ref) => {
  const {
    card,
    content,
    entity,
    collection,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'EntityFilesProfileCardContent', {
    static: ['isEditing']
  });

  const innerRef = useRef(null);
  const searchFieldRef = useRef(null);
  const [internalState, setInternalState] = useState({
    search: null,
    open: false,
    persons: null,
    files: [],
    download: null
  });

  useImperativeHandle(ref, () => innerRef.current);

  const isEditing = content.state.isEditing;

  const authorize = useAuthorize();
  const snackbar = useSnackbar();
  const dialogControl = useDialogControl();

  const [uploadState, setUploadState, uploadFiles] = useUploadFiles();

  const isDataLoading = isLoading;

  const profileProvider = useProfile();
  const createFileEvent = useEffectEvent(profileProvider.updaters?.createFile);
  const updateFileEvent = useEffectEvent(profileProvider.updaters?.updateFile);
  const deleteFileEvent = useEffectEvent(profileProvider.updaters?.deleteFile);

  const file = useEntityUploadGet({entityId: entity?.entityId, uploadId: internalState.download?.uploadId}, {
    ...constants.queryOptions.runOnceFresh,
    enabled: +entity?.entityId > 0 && +internalState.download?.uploadId > 0
  });

  const fields = useMemo(() => ([{
    name: 'search',
    label: 'Search',
    inlineLabel: 'search',
    type: constants.formFieldTypes.text,
    validation: constants.formFieldValidationTypes.text,
    initial: internalState.search,
    debounce: constants.debounce.search,
    FormFieldProps: {
      ref: searchFieldRef,
      hiddenLabel: true,
      autoFocus: false,
      size: 'smaller',
      radius: 'round'
    },
    prefix: <Icon icon={Search} />,
    postfix: internalState.search?.length > 0 ? <ActionIconButton action={{
      icon: <Icon icon={Clear}/>,
      onClick: (e) => {
        setInternalState(utils.updater({search: ''}, true));
        searchFieldRef.current?.focus();
        e.preventDefault();
      }
    }} /> : null
  }]), [internalState.search]);

  const action = useMemo(() => ({
    auth: utils.createAuth({attribute: 'entity.file.create'}),
    onClick: (e) => {
      setInternalState(utils.updater({open: true}, true));
      e.preventDefault();
    },
    label: 'Add files',
    icon: Add
  }), []);

  const FileFieldProps = useMemo(() => {
    const fileState = uploadState ?
      Object.keys(uploadState).reduce((o, k) => {
        const split = k.split('_');
        o[split[1]] = uploadState[k];
        return o;
      }, {}) : null;

    return {
      validate: (value, testContext) => {
        const missing = (value ?? [])
          .some((f) => (f.description?.length ?? 0) === 0);

        if (missing) {
          return testContext.createError({message: 'Enter a valid description for all files'});
        } else {
          return true;
        }
      },
      FormFieldProps: {
        multiple: true,
        addDescription: true,
        status: fileState,
        minSize: 1,
        maxSize: constants.numbers.GB
      }
    }
  }, [uploadState]);

  const fileCardProps = useMemo(() => ({
    onDelete: (file) => {
      const handleConfirm = () => {
        setInternalState((current) => {
          return utils.updater({
            files: current.files.filter((f) => +f.uploadId !== +file.uploadId)
          })(current);
        });

        deleteFileEvent?.({entityId: entity.entityId, uploadId: file.uploadId})
          .catch(() => {
            setInternalState(utils.updater({files: entity.fileUploads}, true));
            snackbar.show('Removing file failed', null,
              {color: 'error', autoHideDuration: constants.delay.error});
          });
      }

      dialogControl.show(<ConfirmDialog question="Are you sure you want to remove this file?"
                                        explanation="The file will be removed permanently"
                                        onConfirm={handleConfirm}
                                        ConfirmButtonProps={{
                                          children: 'Remove file',
                                          startIcon: <Icon icon={Delete} />,
                                          color: 'error'
                                        }}/>, true);
    },
    onCanUpdate: (file, isDelete = false) => {
      return authorize({
        attribute: isDelete ? 'entity.file.delete' : 'entity.file.update',
        meta: { entity, file }
      });
    }
  }), [entity, authorize, snackbar, dialogControl, deleteFileEvent]);

  useLayoutEffect(() => {
    setInternalState(utils.updater({
      files: (entity.fileUploads ?? []).filter((f) => {
        return !(internalState.search?.trim()?.length > 0) ||
          (f.name ?? f.filename)?.toLowerCase().includes(internalState.search.trim().toLowerCase()) ||
          f.description?.toLowerCase().includes(internalState.search.trim().toLowerCase());
      })
    }, true));
  }, [entity?.fileUploads, internalState.search]);

  useEffect(() => {
    if (file.status.isSuccess) {
      if (file?.data?.filename && file?.data?.downloadUrl) {
        utils.fileDownload(file.data.filename, file.data.downloadUrl);
      }
      setInternalState(utils.updater({download: null}, true));
    }
  }, [file?.data?.filename, file?.data?.downloadUrl, file.status.isSuccess]);
  
  const handleSearch = (field, value) => {
    setInternalState(utils.updater({search: value}, true));
  }

  const handleFileClick = (e, file) => {
    setInternalState(utils.updater({download: file}, true));
    e.preventDefault();
  }

  const handleUploadChange = () => {
    setUploadState({});
  }

  const handleUploadSubmit = (files) => {
    return Promise.all(utils.toArray(files, true).map((file, idx) => {
      return createFileEvent?.({
        entityId: entity?.entityId,
        filename: file.name,
        description: file.description,
        filesize: file.size,
        filetype: file.type || constants.filetypes.default
      })
        .then((res) => {
          const upload = utils.camelcase(res.response.data.data);
          const filename = upload.filename;
          const uploadId = upload.fileUploadId;
          const uploadUrl = upload.uploadUrl;

          return uploadFiles([{file, id: `file_${idx}`, url: uploadUrl, name: filename}], true)
            .then(() => {
              return updateFileEvent?.({
                entityId: entity?.entityId,
                uploadId, finished: true
              });
            })
        })
    }))
      .then(() => {
        setUploadState({});
      });
  }

  const handleUploadClose = () => {
    setInternalState(utils.updater({open: false}, true));
  }

  const renderDialog = () => {
    if (internalState.open) {
      return <FileUploadDialog open={true}
                               onChange={handleUploadChange}
                               onSubmit={handleUploadSubmit}
                               onClose={handleUploadClose}
                               FileFieldProps={FileFieldProps} />
    }
  }

  const renderAddButton = () => {
    return <ActionButton className="EntityFilesProfileCardContent-action"
                         action={action}
                         variant="text"
                         isLoading={isLoading}/>
  }
  
  useEffect(() => {
    const scroll = () => {
      return innerRef.current?.querySelector('.EntityFilesProfileCardContent-files .DataList-list')?.scrollTo({top: 0, left: 0});
    }

    utils.retry(scroll, 3);
  }, [entity?.entityId]);

  innerProps.className = utils.flattenClassName(innerProps.className, {
    isEditing: isEditing
  });

  return <StyledEntityFilesProfileCardContent ref={innerRef} {...innerProps}>
    <InlineForm className="EntityFilesProfileCardContent-search"
                onChange={handleSearch}
                fields={fields} />
    {(internalState.files?.length > 0 || isDataLoading) ?
      <FilesList className="EntityFilesProfileCardContent-files"
                 files={internalState.files}
                 loaderCount={3}
                 isLoading={isLoading}
                 onClick={handleFileClick}
                 FileCardProps={fileCardProps} /> : null}
    {(!(internalState.files?.length > 0) && !isDataLoading) ?
      <Box className="EntityFilesProfileCardContent-empty">
        <P>No file(s) found</P>
      </Box> : null}
    <Box className="EntityFilesProfileCardContent-action-wrapper">
      {renderAddButton()}
      {renderDialog()}
    </Box>
  </StyledEntityFilesProfileCardContent>
});

EntityFilesProfileCardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  card: PropTypes.object,
  content: PropTypes.object,
  entity: PropTypes.object,
  collection: PropTypes.object,
  isLoading: PropTypes.bool
};

EntityFilesProfileCardContent.defaultProps = {
};

export default EntityFilesProfileCardContent;


