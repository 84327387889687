import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import GraphCard from 'components/molecules/Cards/GraphCard/GraphCard';

const StyledCollectionEntitiesLatestNewsGraphCard = styled(GraphCard)`
  .GraphCard-content {
    padding-top: ${props => props.theme.spacing(1)};
    padding-bottom: ${props => props.theme.spacing(0.5)};
  }
  
  ${ComponentStyles}
`;

StyledCollectionEntitiesLatestNewsGraphCard.propTypes = {
  theme: PropTypes.object
};

StyledCollectionEntitiesLatestNewsGraphCard.defaultProps = {}

export default StyledCollectionEntitiesLatestNewsGraphCard;
