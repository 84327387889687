import React, {useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledProfileCardContent from 'components/organisms/Cards/ProfileCardContent/ProfileCardContent.styles';
import utils from 'helpers/utils';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import Component from 'components/organisms/Utils/Component/Component';
import constants from 'helpers/constants';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Link from 'components/atoms/Links/Link/Link';
import Typography, {Span} from 'components/atoms/Text/Typography/Typography';
import Box from 'components/atoms/Layout/Box/Box';
import {Dot} from 'assets/icons';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';
import FeedbackHelper from 'components/molecules/Helpers/FeedbackHelper/FeedbackHelper';
import Debounce from 'components/organisms/Utils/Debounce/Debounce';
import {useOverlay} from 'components/organisms/Utils/DragDrop/DndContext';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

const ProfileCardContent = React.forwardRef((props, ref) => {
  const {
    profile,
    card,
    data,
    content,
    fields,
    fieldData,
    isDialog,
    isLoading,
    variant,
    onPatch,
    onSubmit,
    onValidating,
    Content,
    ContentProps,
    Loader,
    LoaderProps,
    ...innerProps
  } = useComponentProps({...props, ...utils.filterObject(props.content, [
      'fields', 'Content', 'Loader', 'variant',
      'onPatch', 'onSubmit', 'onValidating'
    ], false), ...props.content?.ProfileCardContentProps}, 'ProfileCardContent', {
    static: ['isEditing', 'Content']
  });

  const innerRef = useRef(null);
  const formRef = useRef(null);
  const [internalState, setInternalState] = useState({});

  const overlay = useOverlay();

  const getFields = useCallback((state) => {
    if (data && fields?.length > 0) {
      const formFields = utils.initializeFormFields(fields, data)
        .sort((a, b) => a.position - b.position)
        .reduce((a, field) => {
          const fieldEditable = Boolean(utils.isFunction(field.editable) ?
            field.editable({content, field, data}) : (field.editable ?? true));
          const fieldReadOnly = Boolean(utils.isFunction(field.readOnly) ?
            field.readOnly({content, field, data}) : field.readOnly);

          const fieldEditing = (fieldEditable && !fieldReadOnly) && state.isEditing;
          const fieldFeedback = utils.isFunction(field.feedback) ? field.feedback(data, field.initial) : field.feedback;
          const fieldInfo = utils.isFunction(field.info) ? field.info(data, field.initial) : field.info;

          if (fieldEditing || (!utils.isEmpty(field.initial) || fieldFeedback?.show || fieldInfo) || content.Content) {
            let fieldVariant = 'staticLabel';
            if (!['links', 'tagGroups'].includes(variant) && (['detail', 'options'].includes(variant) || fieldEditing)) {
              fieldVariant = 'inlineLabel';
            }

            let info;
            if (!fieldEditing && fieldFeedback?.show) {
              info = <Tooltip title={fieldFeedback.tooltip}
                              enterDelay={0}
                              placement="bottom">
                <Icon className="feedback"
                      icon={fieldFeedback.icon}
                      color={fieldFeedback.color ?? 'error'}
                      size="smaller"/>
              </Tooltip>
            } else if (fieldInfo) {
              info = <Tooltip title={fieldInfo}
                              enterDelay={0}
                              variant="emphasis"
                              placement="bottom">
                <Icon icon={InfoOutlined}
                      className="info"
                      color="light.dark"
                      size="smaller"/>
              </Tooltip>
            }

            field = {
              ...field,
              editable: fieldEditable && !fieldReadOnly,
              readOnly: !fieldEditing,
              context: utils.isFunction(field.context) ? field.context(data, field.initial) : field.context,
              labelPostfix: field.labelPostfix ?? info,
              debounce: state.canPatch ? constants.debounce.input : false,
              FormFieldProps: {
                hiddenHelperText: !fieldEditing,
                autoFocus: false,
                variant: fieldVariant,
                ...field.FormFieldProps
              }
            };

            if (!field.readOnly && state.isSubmitting) {
              field.FormFieldProps.disabled = true;
            }
            a.push(field);

            if (fieldFeedback) {
              a.push({
                name: `${utils.camelcase(field.name)}Verified`,
                type: constants.formFieldTypes.text,
                parent: field,
                initial: fieldFeedback.verified,
                FormFieldProps: {
                  hidden: true
                }
              });
            }
          }
          return a;
        }, []);

      if (formFields.length > 0) {
        if (variant === 'summary') {
          formFields.forEach((field) => {
            if (field.FormFieldProps?.readOnly || formFields.length === 1) {
              field.FormFieldProps = {
                hiddenLabel: true,
                ...field.FormFieldProps
              };
            }
          });
        }

        if (variant === 'tags') {
          formFields.forEach((field) => {
            if (formFields.length === 1) {
              field.FormFieldProps = {
                hiddenLabel: true,
                ...field.FormFieldProps
              };
            }
          });
        }

        if (variant === 'tagGroups') {
          formFields.forEach((field) => {
            if (field.tagGroup) {
              const points = utils.isFunction(field.points) ? field.points(data) : field.points;

              field.FormFieldProps = {
                label: !field.readOnly ? <Box>
                  <Icon icon={Dot} color={field.tagGroup.color || 'tagGroupDefault'}/>
                  <Span>{field.tagGroup.name}</Span>
                </Box> : utils.isDefined(points) ? <Box>
                  <Span>{field.tagGroup.name}</Span>
                  {field.labelPostfix ? <Span className="FormField-labelPostfix">{field.labelPostfix}</Span> : null}
                  <Typography color="text.secondary" nowrap={true} variant="caption">{points} points</Typography>
                </Box> : null,
                ...field.FormFieldProps
              };

              if (utils.isDefined(points) && field.readOnly) {
                field.labelPostfix = null;
              }
            }
          });
        }

        if (variant === 'links') {
          formFields.forEach((field) => {
            if (field.link) {
              const feedback = utils.isFunction(field.feedback) ? field.feedback(data, field.initial) : field.feedback;
              const helperText = field.FormFieldProps?.helperText ?? '';

              field.FormFieldProps = {
                hiddenLabel: field.readOnly,
                helperText: feedback?.show ? ((field, value, disabled) => {
                  if (!disabled) {
                    const feedback = utils.isFunction(field.feedback) ? field.feedback(data, value) : field.feedback;
                    return <FeedbackHelper variant="compact"
                                           feedback={feedback}
                                           fallback={helperText}
                                           acceptField={`${field.name}Verified`}
                                           ignoreField={`${field.name}Verified`}
                                           suggestionField={field.name}/>
                  }
                }) : helperText,
                renderReadOnlyOption: (option, props) => {
                  return <React.Fragment>
                    {field.link.icon ? <Icon icon={field.link.icon} size="small" color="text.secondary"/> : null}
                    <Box className="value">
                      {!utils.isEmpty(option?.label ?? option) ?
                        <Link href={utils.cleanExternalLink(option?.label ?? option)}
                              target="_blank">{option?.label ?? option}</Link> :
                        ((props.placeholder && !props.hiddenPlaceholder) ?
                          <Span className="placeholder">{props.placeholder}</Span> : <Span>&nbsp;</Span>)}
                    </Box>
                    {feedback?.show ? <Tooltip title={feedback.tooltip}
                                               enterDelay={0}
                                               placement="bottom">
                      <Icon className="feedback"
                            icon={feedback.icon}
                            color={feedback.color ?? 'error'}
                            size="smaller"/>
                    </Tooltip> : null}
                  </React.Fragment>
                },
                ...field.FormFieldProps
              };
            }
          });
        } else {
          formFields.forEach((field) => {
            const feedback = utils.isFunction(field.feedback) ? field.feedback(data, field.initial) : field.feedback;
            if (feedback?.show) {
              const helperText = field.FormFieldProps?.helperText ?? '';
              field.FormFieldProps = {
                helperText: ((field, value, disabled) => {
                  if (!disabled) {
                    const feedback = utils.isFunction(field.feedback) ? field.feedback(data, value) : field.feedback;
                    return <FeedbackHelper variant="compact"
                                           feedback={feedback}
                                           fallback={helperText}
                                           acceptField={`${utils.camelcase(field.name)}Verified`}
                                           ignoreField={`${utils.camelcase(field.name)}Verified`}
                                           suggestionField={field.name}/>
                  }
                }),
                renderReadOnly: (value, props) => {
                  return <React.Fragment>
                    <Box className="value">
                      {value ? <Span showTooltip={props.showTooltip}>{value}</Span> :
                        ((props.placeholder && !props.hiddenPlaceholder) ?
                          <Span className="placeholder">{props.placeholder}</Span> : <Span>&nbsp;</Span>)}
                    </Box>
                    {feedback?.show ? <Tooltip title={feedback.tooltip}
                                         enterDelay={0}
                                         placement="bottom">
                      <Icon className="feedback"
                            icon={feedback.icon}
                            color={feedback.color ?? 'error'}
                            size="smaller"/>
                    </Tooltip> : null}
                  </React.Fragment>
                },
                ...field.FormFieldProps
              };
            }
          });
        }

        if (state.isEditing) {
          const hasEditableFields = formFields.filter((f) => !f.readOnly).length > 0;
          return hasEditableFields ? formFields : [];
        } else {
          return formFields;
        }
      }
    }
  }, [content, fields, variant, data]);

  const profileCardContent = useMemo(() => {
    const contentState = {
      ...content,
      isEditing: utils.isDefined(card.state.isEditing) ?
        Boolean(content.isEditing || (card.state.isEditing && (isDialog || card.card.editInline))) :
        Boolean(content.isEditing || (isDialog ? card.state.dialog?.isEditing : card.state.card?.isEditing)),
      isSubmitting: Boolean(content.isSubmitting || card.state.isSubmitting ||
        (isDialog ? card.state.dialog?.isSubmitting : card.state.card?.isSubmitting)),
    }

    const state = {
      ...internalState,
      ...utils.cleanObject({
        isEditing: contentState.isEditing,
        isSubmitting: contentState.isSubmitting
      })
    };

    const fields = getFields(state);

    state.readOnly = utils.isFunction(content.readOnly) ?
      Boolean(content.readOnly({content, state, data, fields, fieldData})) : (
        content.readOnly || (fields?.length > 0 && !fields?.some((f) => f.editable))
      );

    const hidden = utils.isFunction(content.hidden) ?
      Boolean(content.hidden({content, state, data, fieldData})) : content.hidden;

    state.visible = !utils.isDefined(data) ? true :
      ((!content.Content ? (!hidden && fields?.length > 0) : !hidden) && !state.hideContent);

    state.canPatch = Boolean(state.isEditing && !isDialog && !profile.state.isEditing);

    return {
      refs: {
        ref: innerRef,
        formRef: formRef
      },
      state: state,
      fields: fields,
      content: contentState,
      resetForm: () => {
        formRef.current?.reset?.();
      },
      validate: () => {
        return formRef.current?.validate?.()
      },
      setSubmitting: (value) => {
        setInternalState(utils.updater({isSubmitting: value}, true));
        formRef.current?.formik?.setSubmitting?.(value);
      },
      submit: () => {
        formRef.current?.submit?.();
      },
      toggle: () => {
        setInternalState((current) => ({...current, isEditing: !current.isEditing}));
      }
    }
  }, [internalState, content, data, isDialog, fieldData, getFields, profile.state.isEditing, card.state.dialog?.isEditing, card.state.card?.isEditing,
    card.state.dialog?.isSubmitting, card.state.card?.isSubmitting, card.state.isEditing, card.state.isSubmitting, card.card.editInline]);

  useImperativeHandle(ref, () => profileCardContent);

  useEffect(() => {
    setInternalState(utils.updater({hideContent: false}, true));
  }, [profile.data, profileCardContent.fields, profileCardContent.state.isEditing]);

  const handleValidating = (validating, dirty, errors) => {
    onValidating?.(validating, dirty, errors);
  }

  const handleSubmit = (values, actions, fields, optimistic = null, passThrough = false, onSuccess = null, onError = null) => {
    if (!utils.isDefined(optimistic)) {
      if (variant === 'tagGroups') {
        optimistic = {tagGroups: utils.clone(data?.tagGroups ?? [], true)};
        Object.keys(values).forEach((k) => {
          if (k.startsWith('tagGroup')) {
            optimistic.tagGroups.forEach((tg) => {
              if (+tg.groupId === +k.replace('tagGroup', '')) {
                tg.tags = utils.toArray(values[k], true);
              }
            });
          } else {
            optimistic[utils.camelcase(k)] = values[k];
          }
        });
      } else {
        optimistic = utils.camelcase(values);
      }
    }

    setInternalState(utils.updater({isSubmitting: true}, true));
    onSubmit?.(values, {
      setSubmitting: (value) => {
        setInternalState(utils.updater({isSubmitting: value}, true));
        actions?.setSubmitting?.(value);
      },
      resetForm: () => actions?.resetForm?.()
    }, fields, optimistic, passThrough, onSuccess, onError);
  }

  const handlePatch = (field, value, onSuccess, onError) => {
    onPatch?.(field, value, onSuccess, onError);
  }

  const handleHide = (hidden) => {
    setInternalState(utils.updater({
      hideContent: hidden
    }, true));
  }

  const renderContent = () => {
    const canPatch = Boolean(profileCardContent.state.isEditing && !isDialog && !profile.state.isEditing);
    const renderedContent = <InlineForm ref={formRef}
                                        key={content.id}
                                        fields={profileCardContent.fields}
                                        fieldData={fieldData}
                                        onChange={canPatch ? handlePatch : null}
                                        onSubmit={handleSubmit}
                                        onValidating={handleValidating}
                                        {...ContentProps}/>
    if (Content) {
      return <Component ref={formRef}
                        key={content.id}
                        Original={Content}
                        profile={profile}
                        card={card}
                        data={data}
                        content={profileCardContent}
                        renderedContent={renderedContent}
                        fields={profileCardContent.fields}
                        fieldData={fieldData}
                        isDialog={isDialog}
                        isLoading={isLoading}
                        onHide={handleHide}
                        onPatch={handlePatch}
                        onSubmit={handleSubmit}
                        onValidating={onValidating}
                        {...ContentProps}/>
    } else {
      return renderedContent;
    }
  }

  const renderLoader = () => {
    const min = (variant === 'detail' && !content.Content) ? 4 : 1;
    const max = (variant === 'detail' && !content.Content) ? 12 : 1;

    const renderedLoader = <Box className="ProfileCardContent-loader">
      {(profileCardContent.fields ?? (new Array(Math.max(min, Math.ceil(Math.random() * max)))).fill(null))
        .map((f, idx) => {
          if (variant === 'summary') {
            return <Box key={idx} className={variant}>
              <Typography isLoading={true}
                          min={profileCardContent.state.isEditing ? 220 : 80}
                          max={profileCardContent.state.isEditing ? 320 : 120} />
            </Box>
          } else if (variant === 'links') {
            return <Box key={idx} className={variant}>
              <Typography isLoading={true} />
            </Box>
          } else if (variant === 'tags') {
            return <Box key={idx} className={variant}>
              <Box>
                {(new Array(Math.max(8, Math.ceil(Math.random() * 32)))).fill(null)
                  .map((t, idx) => {
                    return <Typography key={idx} isLoading={true} min={8} max={16} />
                  })}
              </Box>
              {profileCardContent.state.isEditing ?
                <Typography isLoading={true} style={{minWidth: '100%'}} min={24} max={32}/> : null}
            </Box>
          } else if (variant === 'tagGroups') {
            return <Box key={idx} className={variant}>
              <Box>
                <Typography isLoading={true} min={8} max={16} />
                <Typography isLoading={true} min={4} max={12} />
              </Box>
              <Typography isLoading={true}
                          min={profileCardContent.state.isEditing ? 220 : 80}
                          max={profileCardContent.state.isEditing ? 320 : 120} />
            </Box>
          } else if (variant === 'options') {
            return <Box key={idx} className={variant}>
              <Box>
                <Typography isLoading={true} min={8} max={16}/>
              </Box>
              <Typography isLoading={true} min={4} max={6}/>
            </Box>
          } else {
            return <Box key={idx} className={variant}>
              <Box>
                <Typography isLoading={true} min={8} max={16}/>
              </Box>
              <Typography isLoading={true} min={12} max={40}/>
            </Box>
          }
        })}
    </Box>

    if (Loader) {
      return <Component ref={formRef}
                        Original={Loader}
                        profile={profile}
                        card={card}
                        data={data}
                        content={profileCardContent}
                        fields={profileCardContent.fields}
                        renderedLoader={renderedLoader}
                        {...(LoaderProps ?? ContentProps)}/>
    } else if (!Content) {
      return renderedLoader;
    } else {
      return <Box className="ProfileCardContent-empty" />
    }
  }

  const debounce = !isDialog &&
    !overlay && !profile.initialised() &&
    (!profile.initialised() || Loader || !Content) &&
    (!profileCardContent.state.isEditing || profile.state.isEditing);

  innerProps.className = utils.flattenClassName(innerProps.className, {
    isEditing: profileCardContent.state.isEditing
  });

  if (profileCardContent.state.visible) {
    return <StyledProfileCardContent ref={innerRef} {...innerProps}>
      <Debounce key={content.id}
                condition={utils.isDefined(data)}
                placeholder={renderLoader()}
                timeout={(debounce ? (constants.debounce.minimal * (1 + Math.floor(card.card.position / Math.max(2, profile.state.columns)))) : 0)}>
        {isLoading ? renderLoader() : renderContent()}
      </Debounce>
    </StyledProfileCardContent>
  }
});

ProfileCardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  profile: PropTypes.object,
  card: PropTypes.object,
  data: PropTypes.object,
  content: PropTypes.object,
  fields: PropTypes.array,
  fieldData: PropTypes.object,
  isEditing: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isDialog: PropTypes.bool,
  isLoading: PropTypes.bool,
  onPatch: PropTypes.func,
  onSubmit: PropTypes.func,
  onValidating: PropTypes.func,
  onHide: PropTypes.func,
  Content: PropTypes.any,
  ContentProps: PropTypes.object,
  Loader: PropTypes.any,
  LoaderProps: PropTypes.object,
  variant: PropTypes.oneOfType([PropTypes.oneOf([
    'standard', 'summary', 'detail', 'options',
    'tags', 'tagGroups', 'links', 'finance',
    'scoreGraph', 'tractionGraph', 'patentGraph',
    'articles', 'persons', 'files', 'systemInfo'
  ]), PropTypes.string]),
};

ProfileCardContent.defaultProps = {
  variant: 'standard',
  children: 'ProfileCardContent text'
};

export default ProfileCardContent;
