import React, {useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useBbox, useComponentProps} from 'helpers/hooks/utils';
import StyledMarkdown from 'components/atoms/Formatters/Markdown/Markdown.styles';
import remarkGfm from 'remark-gfm';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';
import Box from 'components/atoms/Layout/Box/Box';
import {withMemo} from 'helpers/wrapper';
import Typography from 'components/atoms/Text/Typography/Typography';
import Link from 'components/atoms/Links/Link/Link';
import utils from 'helpers/utils';

const Markdown = withMemo(React.forwardRef((props, ref) => {
  const {
    disabled,
    showTooltip,
    TooltipProps,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'Markdown', {
    static: ['disabled']
  });

  const boxRef = useRef(null);
  const innerRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  const alwaysTooltip = showTooltip === 'always';

  const textBox = useBbox(() => boxRef.current?.querySelector?.('.Markdown > *'), ['clamped']);

  // cleanup simple html
  const text = innerProps.children.toString();

  const value = useMemo(() => {
    return text
      .replace(/<br\s?[^>]*>/gi, '  \n')
      .replace(/&nbsp;/gi, ' ')
      .replace(/<span\s?[^>]*>/gi, '')
      .replace(/<div><p>/gi, '<p>').replace(/<\/\s?p><\/\s?div>/gi, '</p>')
      .replace(/<p><div>/gi, '<p>').replace(/<\/\s?div><\/\s?p>/gi, '</p>')
      .replace(/<p>/gi, '  \n').replace(/<\/\s?p>/gi, '')
      .replace(/<div>/gi, '  \n').replace(/<\/\s?div>/gi, '')
      .replace(/<b>/gi, '**').replace(/<\/\s?b>/gi, '**')
      .replace(/<strong>/gi, '**').replace(/<\/\s?strong>/gi, '**')
      .replace(/<em\s?[^>]*>/gi, '*').replace(/<\/\s?em>/gi, '*');
  }, [text]);


  const defaultComponents = useMemo(() => {
    const renderLink = ({node, ...props}) => {
      if (disabled) {
        props.tabIndex = -1;
      }

      const handleClick = (e) => {
        props.onClick?.(e);
        e.stopPropagation();
      }
      
      return <Link {...props} onClick={handleClick}>{props.children}</Link>
    }

    return {
      a: renderLink
    };
  }, [disabled]);

  const renderTooltip = () => {
    if (!isLoading && showTooltip && (alwaysTooltip || textBox?.clamped)) {
      return <StyledMarkdown {...innerProps}>
        {value}
      </StyledMarkdown>
    }
  }

  const renderMarkdown = () => {
    if (isLoading) {
      return <Typography ref={innerRef} isLoading={true}>
        {value}
      </Typography>
    } else {
      innerProps.linkTarget = innerProps.linkTarget ?? '_blank';
      innerProps.remarkPlugins = innerProps.remarkPlugins ?? [remarkGfm];

      return <StyledMarkdown ref={innerRef} {...innerProps} skipHtml={true}>
        {value}
      </StyledMarkdown>
    }
  }

  innerProps.components = innerProps.components ?? defaultComponents;

  innerProps.className = utils.flattenClassName(innerProps.className);

  if (showTooltip) {
    return <Tooltip title={renderTooltip()}
                    placement="bottom"
                    {...TooltipProps}>
      <Box ref={boxRef}>
        {renderMarkdown()}
      </Box>
    </Tooltip>
  } else {
    return renderMarkdown();
  }
}));

Markdown.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  showTooltip: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  TooltipProps: PropTypes.object,
  isLoading: PropTypes.bool
};

Markdown.defaultProps = {
  children: 'Markdown text'
};

export default Markdown;
