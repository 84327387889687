import utils from 'helpers/utils';
import constants from 'helpers/constants';

function createQuery (name = 'Search', parts = [], filters = {}, searchQuerySourceType = null, advanced = false, inUniverse = false, collectionId = null) {
  return {
    id: utils.sha1(name),
    name,
    parts,
    filters,
    advanced,
    inUniverse,
    collectionId,
    searchQuerySourceType,
    suggestions: [],
    tools: {
      translations: ['en'],
      extensions: ['ss'],
      noiseReduction: ['articles', 'patents']
    }
  };
}

function simpleQuery (query, search, wizard, examples, filter) {
  query = !utils.isEmpty(query) ? utils.clone(query, true) : createQuery();

  query.advanced = false;

  if (search || wizard || examples?.length > 0) {
    const partId = utils.sha1(`${query.id}_${0}`);

    if (wizard || examples?.length > 0) {
      let wizardPart, examplesPart;
      if (wizard) {
        wizardPart = {
          id: partId,
          queryType: constants.query.partTypes.and,
          wizard: wizard
        };
      }

      if (examples?.length > 0) {
        examplesPart = {
          id: partId,
          queryType: constants.query.partTypes.and,
          examples: examples
        };
      }

      if (wizardPart && examplesPart) {
        query.parts = [
          {...wizardPart, id: utils.sha1(`${partId}_wizard`)},
          {...examplesPart, id: utils.sha1(`${partId}_examples`)}
        ];
      } else {
        query.parts = [wizardPart ?? examplesPart];
      }

    } else {
      let terms = searchTextToTerms(search);
      query.parts = [
        {
          id: partId,
          queryType: constants.query.partTypes.and,
          termsFunctor: constants.query.termFunctors.or,
          terms: terms.map((t, idx) => {
            return {
              id: utils.sha1(`${partId}_${idx}_${t.value}`),
              term: t.exactMatch ? `"${t.value}"` : t.value
            };
          })
        }
      ];
    }
  } else {
    query.parts = [];
  }

  if (filter) {
    query.filters = utils.filter2Object(filter);
  } else {
    query.filters = {};
  }

  return query;
}

function advancedQuery (query, search, wizard, examples, filter) {
  query = simpleQuery(query, search, wizard, examples, filter);
  query.advanced = true;

  return query;
}

function searchTextToTerms(search) {
  let terms = [];

  const match = (search.match(/["][^"]+["]/g) || []).concat(search.match(/['][^']+[']/g) || []);

  if (match) {
    match.forEach((s) => {
      if (s.trim().length > 0) {
        search = search.split(s).join('');
        s = s.slice(1, s.length - 1);
        terms.push({
          exactMatch: true,
          value: s
        });
      }
    });
  }

  search.split(/[\s,]+/).forEach((s) => {
    if (s.trim().length > 0) {
      terms.push({
        exactMatch: false,
        value: s
      });
    }
  });

  return terms;
}

const cleanQuery = (query) => {
  delete query.advanced;
  return query;
}

const queryFilterCount = (query) => {
  return (query?.parts?.reduce((t, p) => t + queryFilterCount(p), 0) ?? 0) +
    ((query?.terms?.length > 0 || query?.wizard?.length > 0 || query?.examples?.length > 0 ||
      Object.keys(query?.filter ?? {}).length > 0) ? 1 : 0);
}

const search = {
  createQuery,
  simpleQuery,
  advancedQuery,
  cleanQuery,
  queryFilterCount
};

export default search;


