import {useCallback, useEffect, useMemo, useState} from 'react';
import {
  useCollectionFavoriteAddBulk,
  useCollectionFavoriteDeleteBulk,
  useCollectionList,
  useCollectionPublish,
  useCollectionShare,
  useCollectionUnPublish,
  useCollectionUnShare,
  useCollectionUpdate
} from 'services/collection/collection.hooks';
import {useCollectionUserFavoriteAdd, useCollectionUserFavoriteDelete} from 'services/collection/user/user.hooks';
import constants from 'helpers/constants';
import utils from 'helpers/utils';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import {
  useCollectionLabelAdd,
  useCollectionLabelCreate,
  useCollectionLabelDelete,
  useCollectionLabelList
} from 'services/collection/label/label.hooks';

export function processCollection (collection) {
  collection = utils.camelcase(collection);
  const tagGroups = utils.camelcaseEx(collection.tagsGroups ?? []);

  return {
    ...utils.filterObject(collection, ['tagsGroups'], true),
    author: utils.camelcase(collection.author),
    tagGroups: tagGroups,
    labels: utils.camelcaseEx(collection.labels),
    scoringPotential: utils.camelcase(collection.scoringPotential)
  }
}

export function useCollectionPatch () {
  const collectionUpdate = useCollectionUpdate();

  return useCallback((collection, changes) => {
    changes = (Object.keys(changes).length > 1) ? utils.changedFormFields(collection, changes) : changes;

    if (!utils.isEmpty(changes)) {
      return collectionUpdate.mutation.mutateAsync({
        collectionId: collection.collectionId,
        ...changes
      });
    } else {
      return Promise.resolve();
    }
  }, [collectionUpdate.mutation]);
}

export function useCollectionToggleFavorite (mutationOptions = {}) {
  const favoriteAdd = useCollectionUserFavoriteAdd(mutationOptions);
  const favoriteDelete = useCollectionUserFavoriteDelete(mutationOptions);

  return useCallback((collection, favorite) => {
    favorite = utils.isDefined(favorite) ? favorite : !collection.favorite;

    return (!favorite ? favoriteDelete.mutation.mutateAsync : favoriteAdd.mutation.mutateAsync)(
      {collectionId: collection.collectionId}
    );
  }, [favoriteAdd.mutation, favoriteDelete.mutation]);
}

export function useCollectionsToggleFavorite (mutationOptions = {}) {
  const favoriteAdd = useCollectionFavoriteAddBulk(mutationOptions);
  const favoriteDelete = useCollectionFavoriteDeleteBulk(mutationOptions);

  return useCallback((collections, favorite) => {
    return (!favorite ? favoriteDelete.mutation.mutateAsync : favoriteAdd.mutation.mutateAsync)(
      {collectionIds: collections.map((collection) => collection.collectionId)}
    );
  }, [favoriteAdd.mutation, favoriteDelete.mutation]);
}

export function useCollectionToggleVisibility (mutationOptions = {}) {
  const share = useCollectionShare(mutationOptions);
  const unShare = useCollectionUnShare(mutationOptions);
  const publish = useCollectionPublish(mutationOptions);
  const unPublish = useCollectionUnPublish(mutationOptions);

  return useCallback((collection, visibility) => {
    let promise;
    if (collection.visibility === constants.collection.visibility.public) {
      promise = unPublish.mutation.mutateAsync({collectionId: collection.collectionId, $httpParams: {target: visibility}});
    } else if (visibility === constants.collection.visibility.public) {
      promise = publish.mutation.mutateAsync({collectionId: collection.collectionId});
    } else if (collection.visibility === constants.collection.visibility.client &&
      visibility === constants.collection.visibility.user) {
      promise = unShare.mutation.mutateAsync({collectionId: collection.collectionId});
    } else if (collection.visibility === constants.collection.visibility.user &&
      visibility === constants.collection.visibility.client) {
      promise = share.mutation.mutateAsync({collectionId: collection.collectionId});
    }

    return promise;
  }, [publish.mutation, unPublish.mutation, share.mutation, unShare.mutation]);
}

export function useCollectionToggleLabel (mutationOptions = {}) {
  const labelCreate = useCollectionLabelCreate(mutationOptions);
  const labelAdd = useCollectionLabelAdd(mutationOptions);
  const labelDelete = useCollectionLabelDelete(mutationOptions);

  return useCallback((collections, label, add) => {
    if (!(+label.labelId > 0)) {
      if (add) {
        return labelCreate.mutation.mutateAsync({
          collectionIds: collections.map((c) => c.collectionId),
          label: {collections: collections.map((c) => c.collectionId), ...label}
        });
      }
    } else {
      return (add ? labelAdd.mutation.mutateAsync : labelDelete.mutation.mutateAsync)(
        {collectionIds: collections.map((c) => c.collectionId), labelId: label.labelId}
      );
    }
  }, [labelCreate.mutation, labelAdd.mutation, labelDelete.mutation]);
}

export function useCollectionSelected () {
  const [state, setState] = useState({});

  const selectedEnabled = utils.isDefined(state.listState);
  const selected = useCollectionList({
    search: state.ids?.length > 0 ? null : state.listState?.search,
    filter: state.ids?.length > 0 ? null : state.listState?.filter,
    sort: state.listState?.sort,
    collectionIds: state.ids,
    page: state.ids?.length > 0 ? 0 : state.page,
    pageSize: state.ids?.length > 0 ? state.ids?.length : state.pageSize,
    lookupOnly: state.minimal
  }, {
    ...constants.queryOptions.runOnceNotStale,
    overrideDataType: constants.dataTypes.other,
    enabled: selectedEnabled
  });

  useEffect(() => {
    if (!selectedEnabled || selected.data || selected.status.hasError) {
      if (!selected.status.hasError) {
        state.success?.(selected.data.map(processCollection));
      } else {
        state.error?.();
      }
      if (selectedEnabled) {
        setState({});
      }
    }
  }, [state, selectedEnabled, selected.data, selected.status.hasError]);

  return useCallback((listState, page, pageSize, ids, minimal, success, error) => {
    setState({listState, page, pageSize, ids, minimal, success, error});
  }, []);
}

export function useCollectionGroups (view, collection, customFields) {
  const authorize = useAuthorize();

  return useMemo(() => {
    const groups = [];

    Object.keys(constants.collection.groupDefinition).forEach((k) => {
      if (!utils.isFunction(constants.collection.groupDefinition[k])) {
        if (!groups.find((g) => g.name === k)) {
          const groupDef = constants.groupDefinition.lookup('collection', view, k, false, utils.mergeObjects);

          groups.push({
            ...groupDef,
            name: k,
            position: groupDef.position ??
              groups.reduce((p, g) => Math.max(p, g.position), 0),
            fields: utils.object2Array(groupDef.fields).map((f, idx) => {
              const field = {
                ...f,
                entity: 'collection',
                position: f.position ?? idx,
                auth: {
                  read: utils.createAuth({attribute: `collection.field.${f.name}.read`}),
                  update: utils.createAuth({attribute: `collection.field.${f.name}.update`})
                },
                required: f.required ?? false
              }

              if (field.name === 'visibility') {
                field.options = constants.data.collectionVisibility
                  .filter((v) => authorize({
                    attribute: `collection.field.visibility.update.${v.value}`,
                    meta: {collection}
                  }));
              }

              return field;
            }) ?? []
          });
        }
      }
    });

    if (utils.isDefined(collection?.tagGroups)) {
      collection.tagGroups.forEach((tg, idx) => {
        groups.push({
          name: `tagGroup-${tg.groupId ?? idx}`,
          data: tg,
          position: +tg.pos,
          auth: {
            read: utils.createAuth({attribute: 'collection.tagGroup.read', meta: {collection, tagGroup: tg}}),
            update: utils.createAuth({attribute: 'collection.tagGroup.update', meta: {collection, tagGroup: tg}})
          },
          title: tg.name,
          DialogProps: {
            variant: 'basic',
            width: 900,
            minWidth: 900,
            height: 692,
            minHeight: 692,
            showHeader: false,
            wrapContent: false
          }
        });
      });
    }

    let position = groups.reduce((m, g) => g.name.startsWith('tagGroup-') ? Math.max(g.position, m) : m, 0);
    groups.push({
      name: `tagGroup-new-${position + 1}`, // force new card
      data: {},
      auth: {
        read: utils.createAuth({attribute: 'collection.tagGroup.create', meta: {collection}}),
        update: utils.createAuth({attribute: 'collection.tagGroup.create', meta: {collection}})
      },
      position: position + 1,
      title: 'New category',
      DialogProps: {
        variant: 'basic',
        width: 900,
        minWidth: 900,
        height: 692,
        minHeight: 692,
        showHeader: false,
        wrapContent: false
      }
    });

    if (utils.isDefined(collection?.projectSources)) {
      const sources = collection?.projectSources;
      let position = groups.reduce((m, g) => g.name.startsWith('source-') ? Math.max(g.position, m) : m, 0);

      sources.forEach((s, idx) => {
        groups.push({
          name: `source-${s.sourceId ?? idx}`,
          data: s,
          auth: {
            read: utils.createAuth({attribute: 'collection.source.read', meta: {collection, source: s}}),
            update: utils.createAuth({attribute: 'collection.source.update', meta: {collection, source: s}})
          },
          position: position + idx,
          DialogProps: {
            fullWidth: true,
            maxWidth: 884
          }
        });
      });
    }

    position = groups.reduce((m, g) => g.name.startsWith('source-') ? Math.max(g.position, m) : m, 0);
    groups.push({
      name: `source-new-${position + 1}`, // force new card
      data: {},
      auth: {
        read: utils.createAuth({attribute: 'collection.source.create', meta: {collection}}),
        update: utils.createAuth({attribute: 'collection.source.create', meta: {collection}})
      },
      position: position + 1,
      title: 'New source',
      anchor: 'right',
      DialogProps: {
        fullWidth: true,
        maxWidth: 884
      }
    });

    if (utils.isDefined(customFields)) {
      let position = groups.reduce((m, g) => g.name.startsWith('customField-') ? Math.max(g.position, m) : m, 0);

      [...customFields].sort((a, b) => {
        const pos = +(a.pos ?? -1) - +(b.pos ?? -1);
        return pos ? pos : (
          (+a.clientId - +b.clientId) ? (+a.clientId - +b.clientId) : (
            (a.groupName ?? '').toLowerCase().localeCompare((b.groupName ?? '')?.toLowerCase()) ?
              (a.groupName ?? '').toLowerCase().localeCompare((b.groupName ?? '').toLowerCase()) : (
                a.label?.localeCompare(b.label) ? a.label?.localeCompare(b.label) : +b.fieldId - +a.fieldId
              )
          )
        );
      })
        .forEach((f, idx) => {
          groups.push({
            name: `customField-${f.fieldId ?? idx}`,
            data: f,
            auth: {
              read: utils.createAuth({attribute: 'collection.customField.read', meta: {collection, field: f}}),
              update: utils.createAuth({attribute: 'collection.customField.update', meta: {collection, field: f}})
            },
            position: position + idx,
            DialogProps: {
              fullWidth: true,
              maxWidth: 544
            }
          });
        });
    }

    position = groups.reduce((m, g) => g.name.startsWith('customField-') ? Math.max(g.position, m) : m, 0);
    groups.push({
      name: `customField-new-${position + 1}`, // force new card
      data: {},
      auth: {
        read: utils.createAuth({attribute: 'collection.customField.create', meta: {collection}}),
        update: utils.createAuth({attribute: 'collection.customField.create', meta: {collection}})
      },
      position: position + 1,
      title: 'New field',
      anchor: 'right',
      DialogProps: {
        fullWidth: true,
        maxWidth: 544
      }
    });

    if (constants.data.services.length > 0) {
      const services = constants.data.services;
      let position = groups.reduce((m, g) => g.name.startsWith('service-') ? Math.max(g.position, m) : m, 0);

      services.forEach((s, idx) => {
        s = {...s, name: s.label, serviceId: s.value};

        groups.push({
          name: `service-${s.value}`,
          title: s.label,
          data: s,
          auth: {
            read: utils.createAuth({attribute: 'collection.service.read', meta: {collection, service: s}}),
            update: utils.createAuth({attribute: 'collection.service.update', meta: {collection, service: s}})
          },
          position: position + idx,
          DialogProps: {
            variant: 'basic',
            showHeader: false,
            showFooter: false,
            autoFocus: false,
            minWidth: 0
          }
        });
      });
    }

    return groups;
  }, [authorize, collection, customFields, view]);
}

export function useCollectionSections (view) {
  return useMemo(() => {
    const sections = [];
    Object.keys(constants.collection.sectionDefinition).forEach((k) => {
      if (!utils.isFunction(constants.collection.sectionDefinition[k])) {
        const sectionDef = constants.sectionDefinition.lookup('collection', view, k, utils.mergeObjects);
        const lastPos = sections.reduce((p, s) => Math.max(p, s.position ?? 0), 0);
        sections.push({
          ...sectionDef,
          name: k,
          position: (sectionDef.position ?? (lastPos + 1)),
          auth: {
            read: utils.createAuth({attribute: `collection.section.${sectionDef.name}.read`}),
            update: utils.createAuth({attribute: `collection.section.${sectionDef.name}.update`})
          },
          cards: utils.object2Array(sectionDef?.cards).map((cardDef) => ({
            ...cardDef,
            auth: {
              read: utils.createAuth({attribute: `collection.section.${cardDef.name}.read`}),
              update: utils.createAuth({attribute: `collection.section.${cardDef.name}.update`})
            }
          }))
        });
      }
    });

    return sections;
  }, [view]);
}

export function useCollectionProfileCallbacks () {
  const [labelListSearch, setLabelListSearch] = useState({});
  const labelListCurrent = labelListSearch[Object.keys(labelListSearch)[0]];
  const labelListEnabled = Boolean(labelListCurrent?.callback);
  const labelList = useCollectionLabelList({
    search: labelListCurrent?.search,
    filter: utils.addFilter((utils.toArray(labelListCurrent?.ids, true).length > 0) ? [{
      id: 'labelIds',
      value: utils.toArray(labelListCurrent?.ids, true)
        .map((v) => v.toString())
        .sort((a, b) => a.localeCompare(b))
    }] : null, labelListCurrent?.filter),
    limit: Math.max(15, utils.toArray(labelListCurrent?.ids, true)?.length)
  }, {
    ...constants.queryOptions.runOnceNotStale,
    enabled: labelListEnabled
  });

  useEffect(() => {
    if (!labelListEnabled || labelList.data || labelList.status.hasError) {
      labelListCurrent?.callback?.((labelList.data ?? []).map((l) => ({
        object: l,
        label: l.label,
        value: +l.labelId
      })));
      if (utils.isDefined(labelListCurrent)) {
        setLabelListSearch((current) => utils.filterObject(current, Object.keys(current)[0]));
      }
    }
  }, [labelListCurrent, labelListEnabled, labelList.data, labelList.status.hasError]);

  return useMemo(() => {
    return {
      labels: ({key, search, ids, filter, callback}) => {
        setLabelListSearch((current) => ({...current, [key ?? Date.now()]: {search, ids, filter, callback}}))
      }
    }
  }, []);
}

export function useCollectionColumns (view) {
  return useMemo(() => {
    const columns = [];
    Object.keys(constants.collection.columnDefinition).forEach((k) => {
      if (!utils.isFunction(constants.collection.columnDefinition[k])) {
        const groupDef = constants.groupDefinition.lookup('collection', view, k, true, utils.mergeObjects);
        const presetDefs = constants.presetDefinition.lookup('collection', view);
        const fieldDef = groupDef?.fields?.[Object.keys(groupDef?.fields || {})
          .find((k1) => k1 === k)];
        const columnDef = constants.columnDefinition.lookup('collection', view, k, utils.mergeObjects);

        const lastPos = columns.reduce((p, c) => Math.max(p, c.position), 0);
        const lastGroupPos = Math.floor(lastPos / constants.numbers.position.groupGap);
        const firstPosInGroup = columns.reduce((p, c) => (groupDef?.name && c.group?.name === groupDef?.name) ?
          (!p ? c.position : p) : p, groupDef?.name ? 0 : null);
        const lastPosInGroup = columns.reduce((p, c) => (groupDef?.name && c.group?.name === groupDef?.name) ?
          (+p + 1) : p, groupDef?.name ? 0 : null);
        const groupPos = +(groupDef?.tablePosition ?? groupDef?.position ?? (
          ((firstPosInGroup ?? 0) === 0 && lastGroupPos > 0) ? (lastGroupPos + 1) :
            ((firstPosInGroup > 0) ? (Math.floor((firstPosInGroup - 1) / constants.numbers.position.groupGap)) :
              (firstPosInGroup < 0 ? (Math.floor((firstPosInGroup + 1) / constants.numbers.position.groupGap)) :
                (lastGroupPos + 1)))
        )) * constants.numbers.position.groupGap;
        const fieldPos = (utils.isDefined(fieldDef?.position ?? lastPosInGroup) ? (
          groupPos > 0 ? (groupPos + (fieldDef?.position ?? (lastPosInGroup + 1))) :
            (groupPos - (fieldDef?.position ?? (lastPosInGroup + 1)))
        ) : (lastPos + 1));

        if (!columns.find((c) => c.name === k)) {
          columns.push({
            ...columnDef,
            id: columnDef.sortingKey ?? k,
            name: k,
            entity: 'collection',
            hidden: columnDef?.hidden,
            group: columnDef.group ?? groupDef,
            presets: columnDef.presets ?? presetDefs,
            position: columnDef.position ?? fieldPos,
            fieldPosition: columnDef.group ? (columnDef.position ?? fieldPos) : (fieldDef?.position ?? lastPosInGroup),
            auth: {
              read: utils.createAuth({attribute:`collection.column.${k}.read`}),
              update: utils.createAuth({attribute:`collection.column.${k}.update`})
            }
          });
        }
      }
    });

    return columns;
  }, [view]);
}

export function useCollectionFilterGroups (view) {
  return useMemo(() => {
    const filterGroups = [];
    Object.keys(constants.collection.filterGroupDefinition).forEach((k) => {
      if (!utils.isFunction(constants.collection.filterGroupDefinition[k])) {
        const groupDef = constants.filterGroupDefinition.lookup('collection', view, k, false, utils.mergeObjects);
        const filters = utils.isFunction(groupDef.filters) ? groupDef.filters(view) : groupDef.filters;
        const lastPos = filterGroups.reduce((p, g) => Math.max(p, g.position ?? 0), 0);
        filterGroups.push({
          ...groupDef,
          name: k,
          position: (groupDef.position ?? (lastPos + 1)),
          filters: Object.keys(filters ?? {}).map((k, idx) => {
            const f = filters[k];
            return {
              ...f,
              id: f.filterKey ?? k,
              name: k,
              position: f.position ?? (idx + 1),
              entity: 'collection',
              auth: {
                read: utils.createAuth({attribute: `collection.filter.${k}.read`}),
                update: utils.createAuth({attribute: `collection.filter.${k}.update`}),
              }
            }
          })
        });
      }
    });

    return filterGroups;
  }, [view]);
}

