import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {useTheme} from 'components/organisms/Providers/ThemeProvider/ThemeProvider';
import themeLight from 'theme/light/theme';
import utils from 'helpers/utils';
import dom from 'helpers/dom';
import {useAppState} from 'stores/hooks/app';
import {useLocation, useMatches, useSearchParams} from 'react-router-dom';
import constants from 'helpers/constants';
import axios from 'axios';
import {useLinkNavigate} from 'helpers/hooks/links';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import {useAuthTeamId} from 'services/auth/auth.utils';

export function useComponentProps (props, name, modifiers) {
  const theme = useTheme() || themeLight;

  const variants = theme.property(`components.${name}.variants`, []);
  const variant = variants.find((v) => v?.props?.variant === props?.variant);

  const modifiersMemo = useEffectItem(modifiers);
  const $modifiers = useMemo(() => ({
    ...modifiersMemo,
    variable: ['variant', 'color', 'size', 'density', 'radius', ...(modifiersMemo?.variable || [])]
  }), [modifiersMemo]);

  const $variant = useMemo(() => {
    return variant ?? {variant: props?.variant}
  }, [variant, props?.variant]);

  const innerProps = {
    ...theme.property(`components.${name}.defaultProps`, {}),
    ...variant?.props,
    ...props,
    theme,
    $componentName: name,
    $modifiers,
    $variant
  };

  if (innerProps.color) {
    // assure the color
    if (!theme?.palette?.[innerProps.color]) {
      innerProps.color = theme.assureColor(innerProps.color);
    }
  }

  const canFlatten = Boolean(!modifiers || (
    !(modifiers.static?.length > 0) && !(modifiers.variable?.length > 0)
  ));

  // setup standard props: order: default || theme || component
  let className = utils.classNames(name, innerProps.className);

  // convert style props
  ($modifiers?.styled || []).forEach((prop) => {
    if (innerProps.hasOwnProperty(prop)) {
      innerProps['$' + prop] = innerProps[prop];
      delete innerProps[prop];
    }
  });

  // static modifier are switched on or off
  if ($modifiers?.static?.length > 0) {
    className = utils.classNames((overrideProps) => {
      const props = utils.mergeObjects(innerProps, overrideProps);
      const variant = props.$variant?.variant ?? props.variant;
      return $modifiers.static.map((modifier) => {
        if (Boolean(props[modifier])) {
          return `${name}-${modifier}` + ((variant && modifier !== 'variant') ? ` ${name}-${variant}-${modifier}` : '');
        } else {
          return '';
        }
      }).join(' ');
    }, className);
  }

  // variable modifier depend on the type
  if ($modifiers?.variable?.length > 0) {
    className = utils.classNames((overrideProps) => {
      const props = utils.mergeObjects(innerProps, overrideProps);
      const variant = props.$variant?.variant ?? props.variant;
      return $modifiers.variable.map((modifier) => {
        if (utils.isDefined(props[modifier])) {
          const modKey = modifier === 'variant' ? props[modifier] : `${modifier}-${props[modifier]}`;
          return `${name}-${modKey}` + ((variant && modifier !== 'variant') ? ` ${name}-${variant}-${modKey}` : '');
        } else {
          return '';
        }
      }).join(' ');
    }, className);
  }

  if (canFlatten) { // not modifiers then flatten it here, otherwise wait till last moment
    innerProps.className = utils.flattenClassName(className);
  } else {
    innerProps.className = className;
  }

  return innerProps;
}

export function useComponent (c) {
  return useMemo(() => {
    if (utils.isReactElement(c)) {
      return React.forwardRef((props, ref) => {
        return props.children ? utils.cloneElement(c, {...props, ref}, props.children) :
          utils.cloneElement(c, {...props, ref});
      });
    } else {
      return c;
    }
  }, [c]);
}

export function useOptimistic (value, doUpdate, isCallback = false, confirmUpdate = false) {
  const valueMemo = useEffectItem(value);

  const confirmRef = useRef(false);
  const [internalValues, setInternalValues] = useState({prev: null, value: valueMemo});

  const updateEvent = useEffectEvent(doUpdate);
  const update = useCallback((newValue, ...args) => {
    if (updateEvent) {
      const updateValue = () => {
        setInternalValues((current) => {
          return {
            prev: current.value,
            value: utils.isObject(newValue, false) ? utils.mergeObjects(current.value, newValue, true) : newValue
          };
        });
      }

      const resetValue = () => {
        setInternalValues((current) => {
          return {prev: null, value: current.prev};
        });
      }

      if (!confirmUpdate) {
        updateValue();
      } else {
        confirmRef.current = true;
      }

      if (isCallback) {
        const onSuccess = args[args.length - 2];
        const onError = args[args.length - 1];
        return utils.asPromiseCallback(updateEvent)(...args.slice(0, -2))
          .then((msg) => {
            if (confirmRef.current && confirmUpdate) {
              updateValue();
            }
            onSuccess?.(msg);
          })
          .catch((error) => {
            if (!confirmUpdate) {
              resetValue();
            }
            onError?.(error);
          });
      } else {
        return utils.asPromise(updateEvent)(...args)
          .then(() => {
            if (confirmRef.current && confirmUpdate) {
              updateValue();
            }
          })
          .catch((error) => {
            if (!confirmUpdate) {
              resetValue();
            }
            throw error;
          });
      }
    }
  }, [updateEvent, isCallback, confirmUpdate]);

  useLayoutEffect(() => {
    confirmRef.current = false;
    setInternalValues({prev: null, value: valueMemo});
  }, [valueMemo]);

  return [internalValues.value, update];
}

export function useSetState (s) {
  const [, setState] = useState(s);

  return setState;
}

export function useStateValue (s) {
  const [state] = useState(s);

  return state;
}

export function useImmediateState (s) {
  const stateRef = useRef(s);
  const setState = useSetState(s);

  const set = useCallback((s) => {
    const v = utils.isFunction(s) ? s(stateRef.current) : s;

    stateRef.current = v;
    setState(v);
  }, [setState]);

  return [stateRef.current, set];
}

export function useUpdatedState (s) {
  const [state, setState] = useState(s);

  const sMemo = useEffectItem(s);

  useLayoutEffect(() => {
    setState(sMemo);
  }, [sMemo]);

  return [state, setState];
}

export function useFirstEffect () {
  const first = useRef(true);

  useEffect(() => {
    if (first.current) {
      first.current = false;
    }
  }, []);

  return first;
}

export function usePageVisibility () {
  const getBrowserDocumentHiddenProp = useCallback(() => {
    if (typeof document.hidden !== "undefined") {
      return "hidden"
    } else if (typeof document.msHidden !== "undefined") {
      return "msHidden"
    } else if (typeof document.webkitHidden !== "undefined") {
      return "webkitHidden"
    }
  }, []);

  const getIsDocumentHidden = useCallback(() => {
    return !document[getBrowserDocumentHiddenProp()]
  }, [getBrowserDocumentHiddenProp]);

  const [isVisible, setIsVisible] = useState(getIsDocumentHidden());

  useEffect(() => {
    const getBrowserVisibilityProp = () => {
      if (typeof document.hidden !== "undefined") {
        // Opera 12.10 and Firefox 18 and later support
        return "visibilitychange"
      } else if (typeof document.msHidden !== "undefined") {
        return "msvisibilitychange"
      } else if (typeof document.webkitHidden !== "undefined") {
        return "webkitvisibilitychange"
      }
    }

    const visibilityChange = getBrowserVisibilityProp();

    return utils.observeEvent(document, visibilityChange, () => setIsVisible(getIsDocumentHidden()));
  }, [getIsDocumentHidden]);

  return isVisible;
}

export function useImageLoaded (src, srcSet = null, referrerPolicy = null, crossOrigin = null) {
  const [loaded, setLoaded] = useState(null);

  useLayoutEffect(() => {
    if (src || srcSet) {
      setLoaded(null);

      let active = true;
      const image = new Image();
      image.onload = () => {
        if (!active) {
          return;
        }

        setLoaded('loaded');
      };
      image.onerror = () => {
        if (!active) {
          return;
        }
        setLoaded('error');
      };
      image.crossOrigin = crossOrigin;
      image.referrerPolicy = referrerPolicy;
      image.src = src;
      if (srcSet) {
        image.srcset = srcSet;
      }

      return () => {
        active = false;
      };
    } else {
      setLoaded('error');
    }
  }, [crossOrigin, referrerPolicy, src, srcSet]);

  return loaded;
}

export function useDebounce (value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    return utils.observeTimeout(() => setDebouncedValue(value), delay || 500);
  }, [value, delay])

  return debouncedValue
}

export function useUpdatedRef (o) {
  const ref = useRef(o);
  ref.current = o;
  return ref;
}

export function useEffectItem(itm) {
  const ref = useRef(itm);

  return useMemo(() => {
    if (!utils.compare(ref.current, itm)) {
      ref.current = itm;
    }
    return ref.current;
  }, [itm]);
}

export function useEffectEventStable (fn) {
  const ref = useRef(fn);

  ref.current = fn;

  return useCallback((...args) => {
    return ref.current?.(...args);
  }, []);
}

export function useEffectEvent (fn) {
  const cb = useEffectEventStable (fn);

  return fn ? cb : null;
}

export function useListState (initial, options = {}) {
  const {
    type = constants.appState.type.none,
    name = 'none',
    scope = constants.appState.scope.user,
    searchParams = false,
    saveCustom = false,
    searchParamsRoutes = null
  } = options || {};

  const changeRef = useRef({reset: false, empty: false});
  const stateRef = useRef({});

  const location = useLocation();
  const matches = useMatches();
  const match = matches?.find((m) => {
    return utils.comparePath(m.pathname, location.pathname) &&
      utils.isDefined(m.handle?.title);
  });
  const activeForPath = !searchParamsRoutes || searchParamsRoutes.test(location.pathname);

  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const setUrlSearchParamsEvent = useEffectEvent(setUrlSearchParams);
  const searchParamsMemo = useEffectItem(activeForPath ? Array.from(urlSearchParams.entries()) : []);

  const paramsHash = useCallback((arr) => {
    return utils.sha1(
        [...arr].map((v) => v[0].toString() + '_' + v[1].toString())
        .sort((a, b) => a.localeCompare(b))
      );
  }, []);

  const searchParamsParsedRef = useRef({});
  const searchParamsParsedMemo = useMemo(() => {
    const parsed = {
      search: searchParamsMemo.find((v) => v[0] === 'search')?.[1] ?? '',
      custom: searchParamsMemo.filter((v) => v[0] === 'custom').map((v) => v[1]),
      filter: searchParamsMemo.reduce((a, v) => {
        return !['custom', 'sort', 'search', 'page', 'pageSize'].includes(v[0]) ? a.concat([v]) : a;
      }, []).sort((a, b) => (a[0].toString() + '_' + a[1].toString()).localeCompare(b[0].toString() + '_' + b[1].toString())),
      sort: searchParamsMemo.filter((v) => v[0] === 'sort').map((v) => v[1]),
      page: searchParamsMemo.find((v) => v[0] === 'page')?.[1] ?
        utils.toNumber(searchParamsMemo.find((v) => v[0] === 'page')?.[1]) : null,
      pageSize: searchParamsMemo.find((v) => v[0] === 'pageSize')?.[1] ?
        utils.toNumber(searchParamsMemo.find((v) => v[0] === 'pageSize')?.[1]) : null
    }

    changeRef.current.empty = !Object.keys(parsed).some((k) => !utils.isEmpty(parsed[k]));
    changeRef.current.reset = (location.state?.resetSearchParams === true) || (
      location.state?.resetSearchParams !== false &&
      changeRef.current.empty &&
      utils.comparePath(searchParamsParsedRef.current?.pathname, location.pathname)
    );
    searchParamsParsedRef.current = {
      ...parsed,
      paramsHash: paramsHash(searchParamsMemo),
      pathname: location.pathname
    };
    
    return parsed;
  }, [searchParamsMemo, paramsHash, location.pathname, location.state?.resetSearchParams]);

  const prepareFilter = useCallback((filter) => {
    return filter?.map((f) => {
      if (f.id !== 'custom') {
        if (f.id === 'switch') {
          return {
            id: f.id,
            value: utils.toArray(f.value).filter((v) => v !== 'custom')
          };
        } else {
          return {
            id: f.id,
            value: utils.toArray(f.value)
          };
        }
      } else {
        return null;
      }
    })?.filter((f) => {
      return !utils.isEmpty(f?.value);
    })?.sort((a, b) => a.id.toString().localeCompare(b.id.toString()));
  }, []);

  const prepareSort = useCallback((sort) => {
    return !utils.isDefined(sort) ? null :
      utils.toArray(sort);
  }, []);

  const getSearch = useCallback((current) => {
    let newSearch = current ?? initial?.search ?? '';
    if (activeForPath && searchParams) {
      if (!changeRef.current.empty || changeRef.current.reset) {
        newSearch = searchParamsParsedMemo?.search;
      }
    }

    if (!activeForPath || (!searchParams || changeRef.current.empty)) {
      stateRef.current.init = Date.now();
    }

    if (!utils.compare(current, newSearch)) {
      return newSearch;
    } else {
      return current;
    }
  }, [searchParams, searchParamsParsedMemo?.search, initial?.search, activeForPath]);

  const getQuery = useCallback((current) => {
    let newQuery = current ?? initial?.query ?? null;
    if (activeForPath && searchParams) {
      if (!searchParams || changeRef.current.reset) {
        newQuery = null;
      }
    }

    if (!activeForPath || (!searchParams || changeRef.current.empty)) {
      stateRef.current.init = Date.now();
    }

    if (!utils.compare(current, newQuery)) {
      return newQuery;
    } else {
      return current;
    }
  }, [searchParams, initial?.query, activeForPath]);

  const getCustom = useCallback((current) => {
    let newCustom = current ?? initial?.custom ?? null;
    if (activeForPath && searchParams) {
      if (!changeRef.current.empty || changeRef.current.reset) {
        newCustom = searchParamsParsedMemo?.custom;
      }
    }

    if (!activeForPath || (!searchParams || changeRef.current.empty)) {
      stateRef.current.init = Date.now();
    }

    if (!utils.compare(current, newCustom)) {
      return newCustom;
    } else {
      return current;
    }
  }, [searchParams, searchParamsParsedMemo?.custom, initial?.custom, activeForPath]);

  const getFilter = useCallback((current) => {
    let newFilter = current ?? initial?.filter ?? [];
    if (activeForPath && searchParams) {
      if (!changeRef.current.empty || changeRef.current.reset) {
        newFilter = prepareFilter(utils.param2Filter(searchParamsParsedMemo?.filter));
      }
    }

    if (!activeForPath || (!searchParams || changeRef.current.empty)) {
      stateRef.current.init = Date.now();
    }

    if (!utils.compare(current, newFilter)) {
      return newFilter;
    } else {
      return current;
    }
  }, [searchParams, searchParamsParsedMemo?.filter, initial?.filter, prepareFilter, activeForPath]);

  const getSort = useCallback((current) => {
    let newSort = current ?? initial?.sort ?? [];
    if (activeForPath && searchParams) {
      if (!changeRef.current.empty || changeRef.current.reset) {
        newSort = prepareSort(utils.param2Sort(searchParamsParsedMemo?.sort));
      }
    }

    if (!activeForPath || (!searchParams || changeRef.current.empty)) {
      stateRef.current.init = Date.now();
    }

    if (!utils.compare(current, newSort)) {
      return newSort;
    } else {
      return current;
    }
  }, [searchParams, searchParamsParsedMemo?.sort, initial?.sort, prepareSort, activeForPath]);

  const getPagination = useCallback((current) => {
    let newPagination = {
      pageIndex: current?.pageIndex ?? initial?.page ?? null,
      pageSize: current?.pageSize ?? initial?.pageSize ?? null
    };
    if (activeForPath && searchParams) {
      if (!changeRef.current.empty || changeRef.current.reset) {
        newPagination = {
          pageIndex: searchParamsParsedMemo?.page,
          pageSize: searchParamsParsedMemo?.pageSize
        };
      }
    }

    if (!activeForPath || (!searchParams || changeRef.current.empty)) {
      stateRef.current.init = Date.now();
    }

    if (!utils.compare(current, newPagination)) {
      return newPagination;
    } else {
      return current;
    }
  }, [searchParams, searchParamsParsedMemo?.page, searchParamsParsedMemo?.pageSize,
    initial?.page, initial?.pageSize, activeForPath]);

  const getScroll = useCallback((current) => {
    let newScroll = current ?? initial?.scroll ?? null;
    if (activeForPath && searchParams) {
      if (changeRef.current.reset) {
        newScroll = null;
      }
    }

    if (!activeForPath || (!searchParams || changeRef.current.empty)) {
      stateRef.current.init = Date.now();
    }

    if (!utils.compare(current, newScroll)) {
      return newScroll;
    } else {
      return current;
    }
  }, [searchParams, initial?.scroll, activeForPath]);

  const [active, setActive] = useUpdatedState(initial?.active ?? true);
  const [savedSearch, setSavedSearch] = useAppState(type, `${name}_search`, scope, getSearch);
  const [savedCustom, setSavedCustom] = useAppState((saveCustom ? type : constants.appState.type.none),
    `${name}_custom`, scope, getCustom);
  const [savedQuery, setSavedQuery] = useAppState(type, `${name}_query`, scope, getQuery);
  const [savedFilter, setSavedFilter] = useAppState(type, `${name}_filter`, scope, getFilter);
  const [savedSort, setSavedSort] = useAppState(type, `${name}_sort`, scope, getSort);
  const [savedPagination, setSavedPagination] = useAppState(type, `${name}_pagination`, scope, getPagination);
  const [savedScroll, setSavedScroll] = useAppState(constants.appState.type.temp, `${name}_scroll`, scope, getScroll);

  const updateSearchParams = useCallback((changes, reset, replace) => {
    if (activeForPath && searchParams) {
      if (searchParams) {
        let params = [];

        params = params.concat((changes.search ?? searchParamsParsedRef.current.search) ? [['search', (changes.search ?? searchParamsParsedRef.current.search)]] : []);
        params = params.concat((changes.custom ?? searchParamsParsedRef.current.custom) ? (changes.custom ?? searchParamsParsedRef.current.custom).map((c) => (['custom', c])) : []);
        params = params.concat(utils.filter2Param((changes.filter ?? utils.param2Filter(searchParamsParsedRef.current.filter))) || []);
        params = params.concat((utils.sort2Param((changes.sort ?? utils.param2Sort(searchParamsParsedRef.current.sort))) || []).map((v) => (['sort', v])));

        // hide page and pageIndex if it's same as initial, empty defaults to null
        const pageIndex = (!utils.isDefined(changes.pagination?.pageIndex) || +changes.pagination?.pageIndex !== +initial?.page) ? changes.pagination?.pageIndex : null;
        params = params.concat(utils.isDefined(pageIndex ?? searchParamsParsedRef.current.page) ? [['page', (pageIndex ?? searchParamsParsedRef.current.page)]] : []);

        const pageSize = (!utils.isDefined(changes.pagination?.pageSize) || +changes.pagination?.pageSize !== +initial?.pageSize) ? changes.pageSize : null;
        params = params.concat(utils.isDefined(pageSize ?? searchParamsParsedRef.current.pageSize) ? [['pageSize', (pageSize ?? searchParamsParsedRef.current.pageSize)]] : []);

        const currentHash = searchParamsParsedRef.current.paramsHash;
        if (currentHash !== paramsHash(params)) {
          // save stats for double setParams
          searchParamsParsedRef.current.paramsHash = paramsHash(params);
          searchParamsParsedRef.current.search = utils.isDefined(changes.search) ? changes.search : searchParamsParsedRef.current.search;
          searchParamsParsedRef.current.custom = utils.isDefined(changes.custom) ? changes.custom : searchParamsParsedRef.current.custom;
          searchParamsParsedRef.current.filter = utils.isDefined(changes.filter) ? utils.filter2Param(changes.filter) : searchParamsParsedRef.current.filter;
          searchParamsParsedRef.current.sort = utils.isDefined(changes.sort) ? utils.sort2Param(changes.sort) : searchParamsParsedRef.current.sort;
          searchParamsParsedRef.current.page = utils.isDefined(changes.pagination) ? changes.pagination.pageIndex : searchParamsParsedRef.current.page;
          searchParamsParsedRef.current.pageSize = utils.isDefined(changes.pagination) ? changes.pagination.pageSize : searchParamsParsedRef.current.pageSize;

          setUrlSearchParamsEvent?.(params, {
            replace: replace,
            preventScrollReset: true,
            state: {
              resetSearchParams: reset
            }
          });
        }
      }
    }
  }, [searchParamsParsedRef, searchParams, setUrlSearchParamsEvent, paramsHash, initial?.page, initial?.pageSize, activeForPath]);

  const updateSearch = useCallback((update) => {
    setSavedSearch((current) => {
      const search = utils.isFunction(update) ? update(current) : update;

      if (!utils.isEmpty(search)) {
        stateRef.current.init = Date.now();
      }

      updateSearchParams({search}, utils.isEmpty(search), utils.compare(current, search));
      if (!utils.compare(current, search)) {
        stateRef.current.search = Date.now();
        return search;
      } else {
        return current;
      }
    })
  }, [setSavedSearch, updateSearchParams]);

  const updateQuery = useCallback((update) => {
    setSavedQuery((current) => {
      const query = utils.isFunction(update) ? update(current) : update;

      if (!utils.isEmpty(query)) {
        stateRef.current.init = Date.now();
      }

      updateSearchParams({query}, utils.isEmpty(query), utils.compare(current, query));
      if (!utils.compare(current, query)) {
        stateRef.current.query = Date.now();
        return query;
      } else {
        return current;
      }
    })
  }, [setSavedQuery, updateSearchParams]);

  const updateCustom = useCallback((update) => {
    setSavedCustom((current) => {
      const custom = utils.isFunction(update) ? update(current) : update;

      if (!utils.isEmpty(custom)) {
        stateRef.current.init = Date.now();
      }

      updateSearchParams({custom}, utils.isEmpty(custom), utils.compare(current, custom));
      if (!utils.compare(current, custom)) {
        stateRef.current.custom = Date.now();
        return custom;
      } else {
        return current;
      }
    })
  }, [setSavedCustom, updateSearchParams]);

  const updateFilter = useCallback((update) => {
    setSavedFilter((current) => {
      const filter = prepareFilter(utils.isFunction(update) ? update(current) : update);

      if (!utils.isEmpty(filter)) {
        stateRef.current.init = Date.now();
      }

      updateSearchParams({filter}, utils.isEmpty(filter), utils.compare(current, filter));
      if (!utils.compare(current, filter)) {
        stateRef.current.filter = Date.now();
        return filter;
      } else {
        return current;
      }
    })
  }, [setSavedFilter, prepareFilter, updateSearchParams]);

  const updateSort = useCallback((update) => {
    setSavedSort((current) => {
      const sort = prepareSort(utils.isFunction(update) ? update(current) : update);

      if (!utils.isEmpty(sort)) {
        stateRef.current.init = Date.now();
      }

      updateSearchParams({sort}, utils.isEmpty(sort), utils.compare(current, sort));
      if (!utils.compare(current, sort)) {
        stateRef.current.sort = Date.now();
        return sort;
      } else {
        return current;
      }
    })
  }, [setSavedSort, prepareSort, updateSearchParams]);

  const updatePagination = useCallback((update) => {
    setSavedPagination((current) => {
      const pagination = utils.isFunction(update) ? update(current) : update;

      if (utils.isDefined(pagination?.pageIndex) || utils.isDefined(pagination?.pageSize)) {
        stateRef.current.init = Date.now();
      }

      updateSearchParams({pagination}, !(utils.isDefined(pagination?.pageIndex) || utils.isDefined(pagination?.pageSize)), utils.compare(current, pagination));
      if (!utils.compare(current, pagination)) {
        stateRef.current.pagination = Date.now();
        return pagination;
      } else {
        return current;
      }
    })
  }, [setSavedPagination, updateSearchParams]);

  const updateScroll = useCallback((update) => {
    setSavedScroll((current) => {
      const scroll = utils.isFunction(update) ? update(current) : update;

      if (!utils.isEmpty(scroll)) {
        stateRef.current.init = Date.now();
      }

      updateSearchParams({scroll}, utils.isEmpty(scroll), utils.compare(current, scroll));
      if (!utils.compare(current, scroll)) {
        stateRef.current.scroll = Date.now();
        return scroll;
      } else {
        return current;
      }
    })
  }, [setSavedScroll, updateSearchParams]);

  // fix page index bug
  const resetPageIndex = useCallback(() => {
    setSavedPagination((current) => {
      const pagination = {...current, pageIndex: 0};

      const params = searchParamsMemo.filter((v) => v[0] !== 'page');
      setUrlSearchParamsEvent?.(params, {replace: true});
      if (!utils.compare(current, pagination)) {
        return pagination;
      } else {
        return current;
      }
    });
  }, [searchParamsMemo, setUrlSearchParamsEvent, setSavedPagination]);

  // init and initial changes
  useLayoutEffect(() => {
    if (activeForPath && match?.id) {
      updateSearch(getSearch);
    }
  }, [getSearch, updateSearch, activeForPath, match?.id]);

  useLayoutEffect(() => {
    if (activeForPath && match?.id) {
      updateQuery(getQuery);
    }
  }, [getQuery, updateQuery, activeForPath, match?.id]);

  useLayoutEffect(() => {
    if (activeForPath && match?.id) {
      updateCustom(getCustom);
    }
  }, [getCustom, updateCustom, activeForPath, match?.id]);

  useLayoutEffect(() => {
    if (activeForPath && match?.id) {
      updateFilter(getFilter);
    }
  }, [getFilter, updateFilter, activeForPath, match?.id]);

  useLayoutEffect(() => {
    if (activeForPath && match?.id) {
      updateSort(getSort);
    }
  }, [getSort, updateSort, activeForPath, match?.id]);

  useLayoutEffect(() => {
    if (activeForPath && match?.id) {
      updatePagination(getPagination);
    }
  }, [getPagination, updatePagination, activeForPath, match?.id]);

  useLayoutEffect(() => {
    if (activeForPath && match?.id) {
      updateScroll(getScroll);
    }
  }, [getScroll, updateScroll, activeForPath, match?.id]);

  const queryReset = (stateRef.current.query ?? Date.now()) < Math.max(
    stateRef.current.search ?? 0, stateRef.current.filter ?? 0
  );
  useLayoutEffect(() => {
    if (activeForPath && queryReset) {
      updateQuery(null);
    }
  }, [updateQuery, queryReset, activeForPath]);

  const scrollReset = (stateRef.current.scroll ?? Date.now()) < Math.max(
    stateRef.current.search ?? 0, stateRef.current.query ?? 0, stateRef.current.custom ?? 0,
    stateRef.current.filter ?? 0, stateRef.current.sort ?? 0, stateRef.current.pagination ?? 0
  );
  useLayoutEffect(() => {
    if (activeForPath && scrollReset) {
      updateScroll(null);
    }
  }, [updateScroll, scrollReset, activeForPath]);

  const lastSearch = useMemo(() => {
    return savedSearch ?? initial?.search ?? '';
  }, [savedSearch, initial?.search]);
  const lastQuery = useMemo(() => {
    return savedQuery ?? initial?.query ?? null;
  }, [savedQuery, initial?.query]);
  const lastCustom = useMemo(() => {
    return savedCustom ?? initial?.custom ?? null;
  }, [savedCustom, initial?.custom]);
  const lastFilter = useMemo(() => {
    return (savedFilter ?? initial?.filter ?? [])
      .concat(lastCustom ? lastCustom.map((c) => ({id: 'custom', value: c})) : [])
      .concat(lastCustom?.length > 1 ? [{id: 'switch', value: 'custom'}] : []);
  }, [savedFilter, initial?.filter, lastCustom]);
  const lastSort = useMemo(() => {
    return savedSort ?? initial?.sort ?? [];
  }, [savedSort, initial?.sort]);
  const lastPagination = useMemo(() => {
    return {
      pageIndex: savedPagination?.pageIndex ?? initial?.page ?? 0,
      pageSize: savedPagination?.pageSize ?? initial?.pageSize ?? 25,
    }
  }, [savedPagination, initial?.page, initial?.pageSize]);
  const lastScroll = useMemo(() => {
    return savedScroll ?? initial?.scroll ?? null;
  }, [savedScroll, initial?.scroll]);

  const initialised = Boolean(Object.keys(stateRef.current).find((k) => utils.isDefined(stateRef.current[k])));
  const stateMemo = useEffectItem({
    active: active && initialised,
    search: lastSearch,
    query: lastQuery,
    custom: lastCustom,
    filter: lastFilter,
    sort: lastSort,
    pagination: lastPagination,
    scroll: lastScroll
  });

  return useMemo(() => {
    return {
      setActive: setActive,
      setSearch: updateSearch,
      setQuery: updateQuery,
      setCustom: updateCustom,
      setFilter: updateFilter,
      setSort: updateSort,
      setPagination: updatePagination,
      setScroll: updateScroll,
      ...stateMemo,
      resetPageIndex,
      clear: () => {
        updateSearch(null);
        updateQuery(null);
        updateCustom(null);
        updateFilter(null);
        updateSort(null);
        updatePagination(null);
        updateScroll(null);
      }
    }
  }, [setActive, updateSearch, updateQuery, updateCustom, updateFilter,
    updateSort, updatePagination, updateScroll, resetPageIndex, stateMemo]);
}

export function useColumnState (initial, options = {}) {
  const {
    type = constants.appState.type.none,
    name = 'none',
    scope = constants.appState.scope.user
  } = options || {};

  const [columnOrder, setColumnOrder] = useAppState(type, `${name}_column_order`, scope, null);
  const [columnVisibility, setColumnVisibility] = useAppState(type, `${name}_column_visibility`, scope, null);
  const [columnPinning, setColumnPinning] = useAppState(type, `${name}_column_pinning`, scope, null);
  const [columnSizing, setColumnSizing] = useAppState(type, `${name}_column_sizing`, scope, null);

  useLayoutEffect(() => {
    if (!utils.isDefined(columnOrder)) {
      setColumnOrder(initial?.columnOrder);
    }
  }, [setColumnOrder, columnOrder, initial?.columnOrder]);

  useLayoutEffect(() => {
    setColumnVisibility((current) => {
      const missing = Object.keys(initial?.columnVisibility ?? {}).filter((cId) => !Object.keys(current ?? {}).find((oId) => oId === cId));
      if (utils.isDefined(current) && missing.length > 0) {
        return {
          ...initial?.columnVisibility,
          ...current
        }
      } else {
        return current;
      }
    });
  }, [setColumnVisibility, initial?.columnVisibility]);

  useLayoutEffect(() => {
    if (!utils.isDefined(columnPinning)) {
      setColumnPinning(initial?.columnPinning);
    }
  }, [setColumnPinning, columnPinning, initial?.columnPinning]);

  useLayoutEffect(() => {
    setColumnSizing((current) => {
      const missing = Object.keys(initial?.columnSizing ?? {}).filter((cId) => !Object.keys(current ?? {}).find((oId) => oId === cId));
      if (utils.isDefined(current) && missing.length > 0) {
        return {
          ...initial?.columnSizing,
          ...current
        }
      } else {
        return current;
      }
    });
  }, [setColumnSizing, initial?.columnSizing]);

  const lastColumnOrder = useMemo(() => {
    return columnOrder ?? initial?.columnOrder ?? [];
  }, [columnOrder, initial?.columnOrder]);
  const lastColumnVisibility = useMemo(() => {
    return columnVisibility ?? initial?.columnVisibility ?? {};
  }, [columnVisibility, initial?.columnVisibility]);
  const lastColumnPinning = useMemo(() => {
    return columnPinning ?? initial?.columnPinning ?? {};
  }, [columnPinning, initial?.columnPinning]);
  const lastColumnSizing = useMemo(() => {
    return columnSizing ?? initial?.columnSizing ?? {};
  }, [columnSizing, initial?.columnSizing]);

  const stateMemo = useEffectItem({
    columnOrder: lastColumnOrder,
    columnVisibility: lastColumnVisibility,
    columnPinning: lastColumnPinning,
    columnSizing: lastColumnSizing
  });

  return useMemo(() => {
    return {
      setColumnOrder,
      setColumnVisibility,
      setColumnPinning,
      setColumnSizing,
      ...stateMemo,
      clear: () => {
        setColumnOrder(null);
        setColumnVisibility(null);
        setColumnPinning(null);
        setColumnSizing(null);
      }
    }
  }, [setColumnOrder, setColumnVisibility, setColumnPinning, setColumnSizing, stateMemo]);
}

export function useKanbanState (initial, options = {}) {
  const {
    type = constants.appState.type.none,
    name = 'none',
    scope = constants.appState.scope.user
  } = options || {};

  const [panelOrder, setPanelOrder] = useAppState(type, `${name}_panel_order`, scope, null);
  const [panelVisibility, setPanelVisibility] = useAppState(type, `${name}_panel_visibility`, scope, null);
  const [panelSettings, setPanelSettings] = useAppState(type, `${name}_panel_settings`, scope, (initial?.panelSettings ?? {}));

  useLayoutEffect(() => {
    if (!utils.isDefined(panelOrder)) {
      setPanelOrder(initial?.panelOrder);
    }
  }, [setPanelOrder, panelOrder, initial?.panelOrder]);

  useLayoutEffect(() => {
    setPanelVisibility((current) => {
      const missing = Object.keys(initial?.panelVisibility ?? {}).filter((cId) => !Object.keys(current ?? {}).find((oId) => oId === cId));
      if (utils.isDefined(current) && missing.length > 0) {
        return {
          ...initial?.panelVisibility,
          ...current
        }
      } else {
        return current;
      }
    });
  }, [setPanelVisibility, initial?.panelVisibility]);

  useLayoutEffect(() => {
    setPanelSettings((current) => {
      const missing = Object.keys(initial?.panelSettings ?? {}).filter((cId) => !Object.keys(current ?? {}).find((oId) => oId === cId));
      if (utils.isDefined(current) && missing.length > 0) {
        return {
          ...initial?.panelSettings,
          ...current
        }
      } else {
        return current;
      }
    });
  }, [setPanelSettings, initial?.panelSettings]);

  const lastPanelOrder = useMemo(() => {
    return panelOrder ?? initial?.panelOrder ?? [];
  }, [panelOrder, initial?.panelOrder]);
  const lastPanelVisibility = useMemo(() => {
    return panelVisibility ?? initial?.panelVisibility ?? {};
  }, [panelVisibility, initial?.panelVisibility]);
  const lastPanelSettings = useMemo(() => {
    return panelSettings ?? initial?.panelSettings ?? {};
  }, [panelSettings, initial?.panelSettings]);

  const stateMemo = useEffectItem({
    panelOrder: lastPanelOrder,
    panelVisibility: lastPanelVisibility,
    panelSettings: lastPanelSettings
  });

  return useMemo(() => {
    return {
      setPanelOrder,
      setPanelVisibility,
      setPanelSettings,
      ...stateMemo,
      clear: () => {
        setPanelOrder(null);
        setPanelVisibility(null);
        setPanelSettings(null);
      }
    }
  }, [setPanelOrder, setPanelVisibility, setPanelSettings, stateMemo]);
}

export function useCardState (initial, options = {}) {
  const {
    type = constants.appState.type.none,
    name = 'none',
    scope = constants.appState.scope.user
  } = options || {};

  const [cardOrder, setCardOrder] = useAppState(type, `${name}_card_order`, scope, null);
  const [cardVisibility, setCardVisibility] = useAppState(type, `${name}_card_visibility`, scope, null);
  const [cardSettings, setCardSettings] = useAppState(type, `${name}_card_settings`, scope, null);

  useLayoutEffect(() => {
    if (!utils.isDefined(cardOrder)) {
      setCardOrder(initial?.cardOrder);
    }
  }, [setCardOrder, cardOrder, initial?.cardOrder]);

  useLayoutEffect(() => {
    setCardVisibility((current) => {
      const missing = Object.keys(initial?.cardVisibility ?? {}).filter((cId) => !Object.keys(current ?? {}).find((oId) => oId === cId));
      if (utils.isDefined(current) && missing.length > 0) {
        return {
          ...initial?.cardVisibility,
          ...current
        }
      } else {
        return current;
      }
    });
  }, [setCardVisibility, initial?.cardVisibility]);

  useLayoutEffect(() => {
    setCardSettings((current) => {
      const missing = Object.keys(initial?.cardSettings ?? {}).filter((cId) => !Object.keys(current ?? {}).find((oId) => oId === cId));
      if (utils.isDefined(current) && missing.length > 0) {
        return {
          ...initial?.cardSettings,
          ...current
        }
      } else {
        return current;
      }
    });
  }, [setCardSettings, initial?.cardSettings]);

  const lastCardOrder = useMemo(() => {
    return cardOrder ?? initial?.cardOrder ?? [];
  }, [cardOrder, initial?.cardOrder]);
  const lastCardVisibility = useMemo(() => {
    return cardVisibility ?? initial?.cardVisibility ?? {};
  }, [cardVisibility, initial?.cardVisibility]);
  const lastCardSettings = useMemo(() => {
    return cardSettings ?? initial?.cardSettings ?? {};
  }, [cardSettings, initial?.cardSettings]);

  const stateMemo = useEffectItem({
    cardOrder: lastCardOrder,
    cardVisibility: lastCardVisibility,
    cardSettings: lastCardSettings
  });

  return useMemo(() => {
    return {
      setCardOrder,
      setCardVisibility,
      setCardSettings,
      ...stateMemo,
      clear: () => {
        setCardOrder(null);
        setCardVisibility(null);
        setCardSettings(null);
      }
    }
  }, [setCardOrder, setCardVisibility, setCardSettings, stateMemo]);
}

export function useGraphState (initial, options = {}) {
  const {
    type = constants.appState.type.none,
    name = 'none',
    scope = constants.appState.scope.user
  } = options || {};

  const [graphOrder, setGraphOrder] = useAppState(type, `${name}_graph_order`, scope, (initial?.graphOrder ?? []));
  const [graphVisibility, setGraphVisibility] = useAppState(type, `${name}_graph_visibility`, scope, (initial?.graphVisibility ?? {}));
  const [graphSettings, setGraphSettings] = useAppState(type, `${name}_graph_settings`, scope, (initial?.graphSettings ?? {}));

  useLayoutEffect(() => {
    if (!utils.isDefined(graphOrder)) {
      setGraphOrder(initial?.graphOrder);
    }
  }, [setGraphOrder, graphOrder, initial?.graphOrder]);

  useLayoutEffect(() => {
    setGraphVisibility((current) => {
      const missing = Object.keys(initial?.graphVisibility ?? {}).filter((cId) => !Object.keys(current ?? {}).find((oId) => oId === cId));
      if (utils.isDefined(current) && missing.length > 0) {
        return {
          ...initial?.graphVisibility,
          ...current
        }
      } else {
        return current;
      }
    });
  }, [setGraphVisibility, graphVisibility, initial?.graphVisibility]);

  useLayoutEffect(() => {
    setGraphSettings((current) => {
      const missing = Object.keys(initial?.graphSettings ?? {}).filter((cId) => !Object.keys(current ?? {}).find((oId) => oId === cId));
      if (utils.isDefined(current) && missing.length > 0) {
        return {
          ...initial?.graphSettings,
          ...current
        }
      } else {
        return current;
      }
    });
  }, [setGraphSettings, initial?.graphSettings]);

  const lastGraphOrder = useMemo(() => {
    return graphOrder ?? initial?.graphOrder ?? [];
  }, [graphOrder, initial?.graphOrder]);
  const lastGraphVisibility = useMemo(() => {
    return graphVisibility ?? initial?.graphVisibility ?? {};
  }, [graphVisibility, initial?.graphVisibility]);
  const lastGraphSettings = useMemo(() => {
    return graphSettings ?? initial?.graphSettings ?? {};
  }, [graphSettings, initial?.graphSettings]);

  const stateMemo = useEffectItem({
    graphOrder: lastGraphOrder,
    graphVisibility: lastGraphVisibility,
    graphSettings: lastGraphSettings
  });

  return useMemo(() => {
    return {
      setGraphOrder,
      setGraphVisibility,
      setGraphSettings,
      ...stateMemo,
      clear: () => {
        setGraphOrder(null);
        setGraphVisibility(null);
        setGraphSettings(null);
      }
    }
  }, [setGraphOrder, setGraphVisibility, setGraphSettings, stateMemo]);
}

export function useListSelection (initial, options, list) {
  const {
    dataKey,
    max = constants.numbers.maxInt,
    selectAll = true
  } = options || {};

  const teamId = useAuthTeamId();

  const [internalState, setInternalState] = useState({allSelected: false, selection: []});

  useEffect(() => {
    setInternalState({allSelected: false, selection: []});
  }, [teamId]);

  return useMemo(() => {
    const getRowSelection = () => {
      if (internalState.allSelected) {
        return list?.data?.reduce((o, item) => {
          o[item[dataKey]] = true;
          return o;
        }, {}) ?? {};
      } else {
        return internalState.selection.reduce((o, item) => {
          o[item[dataKey]] = true;
          return o;
        }, {});
      }
    };

    const allSelected = list?.meta?.resultsCount > 0 && (
      internalState.allSelected || (list?.meta?.resultsCount === internalState.selection.length) ||
      (!selectAll && internalState.selection.length === list?.data?.length)
    );

    return {
      state: internalState,
      max: max,
      count: internalState.allSelected ? (list?.meta?.resultsCount ?? 0) : internalState.selection.length,
      allSelected: allSelected,
      rowSelection: getRowSelection(),
      dataSelection: internalState.allSelected ? list?.data : internalState.selection,
      selectAll: () => {
        const clear = !(list?.meta?.resultsCount > 0) || allSelected;

        setInternalState((current) => {
          return {
            ...current,
            allSelected: !clear && selectAll && (current.selection.length === list?.data?.length),
            selection: !clear ? (list?.data ?? []) : []
          }
        });
      },
      setRowSelection: (rowSelection) => {
        const newRowSelection = utils.isFunction(rowSelection) ? rowSelection(getRowSelection()) : rowSelection;
        setInternalState((current) => ({
          ...current,
          allSelected: false,
          selection: Object.keys((newRowSelection || {}))
            .map((key) => {
              if (newRowSelection[key] === true) {
                return list?.data?.find((d) => d[dataKey].toString() === key.toString()) ??
                  current.selection?.find((d) => d[dataKey].toString() === key.toString());
              } else {
                return null;
              }
            })
            .filter((_) => (_))
        }));
      },
      clearSelection: () => {
        setInternalState({allSelected: false, selection: []});
      }
    }
  }, [max, dataKey, internalState, selectAll, list?.data, list?.meta?.resultsCount]);
}

export function useBbox (elementCb, keys = null, ignoreVisibility = true) {
  const [bBox, setBbox] = useImmediateState(null);

  const elementCbEvent = useEffectEvent(elementCb);
  const keysMemo = useEffectItem(keys);

  const updateBbox = useCallback(() => {
    const ele = elementCbEvent?.();
    if (ele) {
      const bb = dom.getBbox(ele, ignoreVisibility);
      const box = keysMemo ? utils.filterObject(bb, keysMemo, false) : bb;
      setBbox(utils.updater(box));
    } else {
      setBbox(null);
    }
  }, [elementCbEvent, keysMemo, ignoreVisibility, setBbox]);

  useLayoutEffect(() => {
    updateBbox();
  }, [updateBbox]);

  useEffect(() => {
    return utils.observeResize(elementCb, () => {
      updateBbox();
    }, {timeout: constants.debounce.shortest});
  }, [elementCb, updateBbox]);

  useEffect(() => {
    return utils.observeResize(window, () => {
      updateBbox();
    }, {timeout: constants.debounce.shortest});
  }, [updateBbox]);

  useEffect(() => {
    return utils.observeScroll(window, () => {
      updateBbox();
    }, {timeout: constants.debounce.shortest});
  }, [updateBbox]);

  useEffect(() => {
    const ele = elementCbEvent?.();
    const sp = dom.getScrollElement(ele, true, true);
    if (sp) {
      return utils.observeScroll(sp, () => {
        updateBbox();
      }, {timeout: constants.debounce.shortest});
    }
  }, [updateBbox, elementCbEvent]);

  useEffect(() => {
    return utils.observeInterval(() => {
      updateBbox();
    }, constants.delay.minimal);
  }, [updateBbox]);

  return bBox;
}

export function useOverflowShadow (scrollElement, overflowHeader, overflowFooter, allItemsLoaded = true, orientation = 'vertical') {
  const checkScroll = useCallback(() => {
    const horizontal = orientation === 'horizontal';
    const scrollSize = horizontal ?
      (scrollElement === window ? document.body.scrollWidth : scrollElement.scrollWidth) :
      (scrollElement === window ? document.body.scrollHeight : scrollElement.scrollHeight);

    const scrollPos = horizontal ?
      (scrollElement === window ? window.scrollX : scrollElement.scrollLeft) :
      (scrollElement === window ? window.scrollY : scrollElement.scrollTop);
    const clientSize = horizontal ?
      (scrollElement === window ? window.innerWidth : scrollElement.clientWidth) :
      (scrollElement === window ? window.innerHeight : scrollElement.clientHeight);

    const startOfPage = scrollPos === 0;

    if (overflowHeader) {
      if (startOfPage) {
        overflowHeader.style.background = 'unset';
      } else {
        overflowHeader.style.background = null;
      }
    }

    const endOfPage = ((scrollSize - scrollPos - clientSize) < (scrollPos === 0 ? 1 : (clientSize * 0.1)));

    if (overflowFooter) {
      if (endOfPage && allItemsLoaded) {
        overflowFooter.style.background = 'unset';
      } else {
        overflowFooter.style.background = null;
      }
    }
  }, [overflowHeader, overflowFooter, scrollElement, allItemsLoaded, orientation]);

  useEffect(() => {
    if (scrollElement) {
      utils.retry(checkScroll, 3);
    }
  }, [scrollElement, checkScroll]);

  useEffect(() => {
    if (scrollElement) {
      return utils.observeScroll(scrollElement, () => {
        checkScroll();
      });
    }
  }, [scrollElement, checkScroll]);

  useEffect(() => {
    if (scrollElement) {
      return utils.observeResize(scrollElement, () => {
        checkScroll();
      });
    }
  }, [scrollElement, checkScroll]);
}

export function useUploadFiles (init = null, splitProgress = false) {
  const [state, setState] = useState(init ?? {});

  const uploadFiles = useCallback(async (files, attachment = false) => {
    return Promise.all(files.map((f) => {
      const id = f.id;
      const url = f.url;
      const file = f.file;

      setState((current) => ({
        ...current, [id]: {
          ...current?.[id],
          progress: 0,
          success: false,
          loading: true
        }
      }));

      return axios.put(url, file, {
        headers: Object.assign({
          'Content-Type': file.type ?? constants.filetypes.default
        }, attachment ? {
          'Content-Disposition': `attachment; filename=${f.name}`
        } : {}),
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * (splitProgress ? 50 : 100);
          setState((current) => ({
            ...current, [id]: {
              ...current?.[id],
              progress
            }
          }));
        },
        onDownloadProgress: (progressEvent) => {
          const progress = 50 + (progressEvent.loaded / progressEvent.total) * (splitProgress ? 50 : 100);
          setState((current) => ({
            ...current, [id]: {
              ...current?.[id],
              progress
            }
          }));
        },
      })
        .then(() => {
          setState((current) => ({
            ...current, [id]: {
              ...current?.[id],
              progress: 100,
              success: true
            }
          }));
        })
        .catch((error) => {
          setState((current) => ({
            ...current, [id]: {
              ...current?.[id],
              error
            }
          }));

          throw error;
        })
        .finally(() => {
          setState((current) => ({
            ...current, [id]: {
              ...current?.[id],
              loading: false
            }
          }));
        });
    }));
  }, [splitProgress]);

  return [state, setState, uploadFiles];
}

const requestTimeout = (fn, delay, registerCancel) => {
  const start = new Date().getTime();

  const loop = () => {
    const delta = new Date().getTime() - start;

    if (delta >= delay) {
      fn();
      registerCancel(() => {});
      return;
    }

    const raf = requestAnimationFrame(loop);
    registerCancel(() => cancelAnimationFrame(raf));
  };

  const raf = requestAnimationFrame(loop);
  registerCancel(() => cancelAnimationFrame(raf));
};

export function useCancelableScheduledWork () {
  const cancelCallback = useRef(() => {});
  const registerCancel = fn => (cancelCallback.current = fn);
  const cancelScheduledWork = () => cancelCallback.current();

  // Cancels the current sheduled work before the "unmount"
  useEffect(() => {
    return cancelScheduledWork;
  }, []);

  return [registerCancel, cancelScheduledWork];
}

export function useClickPrevention (onClick, onDoubleClick, delay = 300) {
  const [registerCancel, cancelScheduledRef] = useCancelableScheduledWork();

  const handleClick = () => {
    cancelScheduledRef();
    requestTimeout(onClick, delay, registerCancel);
  };

  const handleDoubleClick = () => {
    cancelScheduledRef();
    onDoubleClick();
  };

  return [handleClick, handleDoubleClick];
}

export function useChartMouse () {
  const [mouseState, setMouseState] = useState({mouseLeave: true});

  const handleChartMouseOut = useCallback(() => {
    setMouseState(utils.updater({mouseLeave: true}));
  }, []);

  const handleDetailMouseMove = useCallback((dataKey) => (payload, idx) => {
    idx = utils.isInt(idx) ? idx : 0;
    setMouseState((current) => {
      const ns = {
        ...current,
        activeIndex: idx,
        mouseLeave: false,
        hoverPayLoadKey: idx >= 0 ? `${dataKey}-${idx}` : `${dataKey}`
      };

      return !utils.compare(current, ns) ? ns : current;
    });
  }, []);

  const isDetailHovering = useCallback((dataKey, cellIdx) => {
    if (utils.isDefined(cellIdx)) {
      return cellIdx === mouseState.activeIndex &&
        (!utils.isDefined(dataKey) || mouseState.hoverPayLoadKey === `${dataKey}-${cellIdx}`);
    } else {
      return mouseState.hoverPayLoadKey && mouseState.hoverPayLoadKey.startsWith(dataKey);
    }
  }, [mouseState]);

  const isHovering = useCallback(() => {
    return !mouseState.mouseLeave;
  }, [mouseState])

  return useMemo(() => ({
    ...mouseState,
    handleDetailMouseMove,
    handleChartMouseOut,
    isDetailHovering,
    isHovering
  }), [mouseState, isDetailHovering, isHovering,
    handleChartMouseOut, handleDetailMouseMove]);
}

export function useMergeView (viewA, viewB) {
  return useMemo(() => {
    return utils.mergeObjects(viewA, viewB, true);
  }, [viewA, viewB]);
}

export function useMergeFieldData (fieldDataA, fieldDataB) {
  return useMemo(() => {
    let tagGroups, customFields, callbacks;
    if (fieldDataA?.tagGroups || fieldDataB?.tagGroups) {
      tagGroups = (fieldDataA?.tagGroups || []).concat((fieldDataB?.tagGroups || []).filter((tg) => {
        return !(fieldDataA?.tagGroups || []).find((tg1) => +tg1.groupId === +tg.groupId);
      }));
    }

    if (fieldDataA?.customFields || fieldDataB?.customFields) {
      customFields = (fieldDataA?.customFields || []).concat((fieldDataB?.customFields || []).filter((cf) => {
        return !(fieldDataA?.customFields || []).find((cf1) => +cf1.fieldId === +cf.fieldId || cf1.name === cf.name);
      }));
    }

    if (fieldDataA?.callbacks || fieldDataB?.callbacks) {
      callbacks = utils.mergeObjects(fieldDataA?.callbacks, fieldDataB?.callbacks);
    }

    return {
      ...fieldDataA,
      ...fieldDataB,
      tagGroups,
      customFields,
      callbacks
    }
  }, [fieldDataA, fieldDataB]);
}

export function useMergeCardDefinitions (cardDefinitionsA, cardDefinitionsB) {
  return useMemo(() => {
    let cardDefs;
    if (cardDefinitionsA) {
      cardDefs = utils.clone(cardDefinitionsB ?? []);

      cardDefinitionsA.forEach((cardDefinition) => {
        const cardDefIndex = cardDefs.findIndex((cardDef) => utils.camelcase(cardDef.name) === utils.camelcase(cardDefinition.name));
        if (cardDefIndex !== -1 && !cardDefinition.locked && !cardDefs[cardDefIndex].locked) {
          cardDefs[cardDefIndex] = {
            ...cardDefinition,
            ...utils.cleanObject(cardDefs[cardDefIndex]),
            fields: (cardDefs[cardDefIndex].fields ?? []).concat((cardDefinition.fields ?? [])
              .map((f) => ({...f, position: +f.position * constants.numbers.position.fieldGap}))
              .filter((f1) => !(cardDefs[cardDefIndex].fields ?? []).find((f2) => utils.camelcase(f1.name) === utils.camelcase(f2.name)))),
            name: cardDefinition.name
          }
        } else {
          if (cardDefIndex !== -1 && cardDefinition.locked) {
            cardDefs[cardDefIndex] = {
              ...cardDefs[cardDefIndex],
              name: `${cardDefs[cardDefIndex].name}Locked`
            }
          }
          cardDefs.push({
            ...cardDefinition,
            name: `${cardDefinition.name}${(cardDefIndex !== -1 && cardDefs[cardDefIndex].locked) ? 'Locked' : ''}`,
            position: cardDefinition.position * constants.numbers.position.groupGap,
            fields: (cardDefinition.fields ?? []).map((f) => ({...f, position: +f.position * constants.numbers.position.fieldGap}))
          });
        }
      })
    }

    if (cardDefs?.length > 0) {
      return cardDefs;
    } else {
      return null;
    }
  }, [cardDefinitionsB, cardDefinitionsA]);
}

export function useMergeColumnDefinitions (columnDefinitionsA, columnDefinitionsB) {
  return useMemo(() => {
    let columnDefs;
    if (columnDefinitionsA) {
      columnDefs = utils.clone(columnDefinitionsB ?? []);

      let presetDefs = columnDefs.reduce((a, columnDef) => {
        columnDef.presets?.forEach((p) => {
          const preset = a.find((pr) => utils.camelcase(pr.name) === utils.camelcase(p.name));
          if (!preset) {
            a.push(p);
          }
        });

        return a;
      }, []);

      columnDefinitionsA.forEach((columnDef) => {
        columnDef.presets?.forEach((preset) => {
          const presetIndex = presetDefs.findIndex((pr) => utils.camelcase(pr.name) === utils.camelcase(preset.name));
          if (presetIndex !== -1) {
            presetDefs[presetIndex] = {
              ...preset,
              ...utils.cleanObject(presetDefs[presetIndex]),
              columns: {
                ...preset.columns,
                ...presetDefs[presetIndex].columns
              },
              name: preset.name
            }
          } else {
            presetDefs.push({
              ...preset,
              position: preset.position * constants.numbers.position.presetGap
            })
          }
        });
      });

      columnDefs.forEach((columDef) => {
        columDef.presets = presetDefs.filter((p) => {
          return Object.keys(p.columns)
            .filter((c) => p.columns[c])
            .find((c) => utils.camelcase(c) === utils.camelcase(columDef.name) ||
              utils.camelcase(c) === utils.camelcase(columDef.group?.name));
        });
      });

      columnDefinitionsA.forEach((columnDefinition) => {
        const columnDefIndex = columnDefs.findIndex((columnDef) => utils.camelcase(columnDef.name) === utils.camelcase(columnDefinition.name));
        const presets = presetDefs.filter((p) => {
          return Object.keys(p.columns)
            .filter((c) => p.columns[c])
            .find((c) => utils.camelcase(c) === utils.camelcase(columnDefinition.name) ||
              utils.camelcase(c) === utils.camelcase(columnDefinition.group?.name));
        });

        if (columnDefIndex !== -1) {
          columnDefs[columnDefIndex] = {
            ...columnDefinition,
            ...utils.cleanObject(columnDefs[columnDefIndex]),
            presets: presets,
            id: columnDefs[columnDefIndex].sortingKey ?? columnDefinition.sortingKey ?? columnDefinition.name,
            name: columnDefinition.name
          }
        } else {
          columnDefs.push({
            ...columnDefinition,
            presets: presets,
            position: columnDefinition.position * constants.numbers.position.columnGap,
            name: columnDefinition.name
          });
        }
      });
    }

    if (columnDefs?.length > 0) {
      return columnDefs;
    } else {
      return null;
    }
  }, [columnDefinitionsB, columnDefinitionsA]);
}

export function useMergeFilterGroupDefinitions (filterGroupDefinitionsA, filterGroupDefinitionsB) {
  return useMemo(() => {
    let filterGroupDefs;
    if (filterGroupDefinitionsA) {
      filterGroupDefs = utils.clone(filterGroupDefinitionsB ?? []);

      filterGroupDefinitionsA.forEach((filterGroup) => {
        const filterGroupDefIndex = filterGroupDefs.findIndex((filterGroupDef) => utils.camelcase(filterGroupDef.name) === utils.camelcase(filterGroup.name));
        if (filterGroupDefIndex !== -1) {
          filterGroup.filters?.forEach((filter) => {
            const filterGroupDefFilterIndex = filterGroupDefs[filterGroupDefIndex].filters.findIndex((filterDef) => utils.camelcase(filterDef.name) === utils.camelcase(filter.name));
            if (filterGroupDefFilterIndex !== -1) {
              filterGroupDefs[filterGroupDefIndex].filters[filterGroupDefFilterIndex] = {
                ...filter,
                ...utils.cleanObject(filterGroupDefs[filterGroupDefIndex].filters[filterGroupDefFilterIndex]),
                name: filter.name
              }
            }
          });

          filterGroupDefs[filterGroupDefIndex] = {
            ...filterGroup,
            ...utils.cleanObject(filterGroupDefs[filterGroupDefIndex]),
            name: filterGroup.name
          }

          filterGroupDefs[filterGroupDefIndex] = {
            ...filterGroup,
            ...utils.cleanObject(filterGroupDefs[filterGroupDefIndex]),
            filters: (filterGroupDefs[filterGroupDefIndex].filters ?? []).concat((filterGroup.filters ?? [])
              .map((f) => ({...f, position: +f.position * constants.numbers.position.filterGap}))
              .filter((f1) => !(filterGroupDefs[filterGroupDefIndex].filters ?? []).find((f2) => utils.camelcase(f1.name) === utils.camelcase(f2.name)))),
            name: filterGroup.name
          }
        } else {
          filterGroupDefs.push({
            ...filterGroup,
            position: filterGroup.position * constants.numbers.position.groupGap
          });
        }
      })
    }

    if (filterGroupDefs?.length > 0) {
      return filterGroupDefs;
    } else {
      return null;
    }
  }, [filterGroupDefinitionsB, filterGroupDefinitionsA]);
}

export function useMergeSectionDefinitions (sectionDefinitionsA, sectionDefinitionsB) {
  return useMemo(() => {
    let sectionDefs;
    if (sectionDefinitionsA) {
      sectionDefs = utils.clone(sectionDefinitionsB ?? []);

      sectionDefinitionsA.forEach((sectionDefinition) => {
        const sectionDefIndex = sectionDefs.findIndex((sectionDef) => utils.camelcase(sectionDef.name) === utils.camelcase(sectionDefinition.name));
        if (sectionDefIndex !== -1) {
          sectionDefs[sectionDefIndex] = {
            ...sectionDefinition,
            ...utils.cleanObject(sectionDefs[sectionDefIndex]),
            cards: (sectionDefs[sectionDefIndex].cards ?? []).concat((sectionDefinition.cards ?? [])
              .map((c) => ({...c, position: +c.position * constants.numbers.position.cardGap}))
              .filter((c1) => !sectionDefs[sectionDefIndex].cards.find((c2) => utils.camelcase(c1.name) === utils.camelcase(c2.name)))),
            name: sectionDefinition.name
          }
        } else {
          sectionDefs.push({
            ...sectionDefinition,
            position: sectionDefinition.position * constants.numbers.position.sectionGap,
            cards: (sectionDefinition.cards ?? []).map((c) => ({...c, position: +c.position * constants.numbers.position.cardGap}))
          });
        }
      })
    }

    if (sectionDefs?.length > 0) {
      return sectionDefs;
    } else {
      return null;
    }
  }, [sectionDefinitionsA, sectionDefinitionsB]);
}

export function useMergePanelDefinitions (panelDefinitionsA, panelDefinitionsB) {
  return useMemo(() => {
    let panelDefs;
    if (panelDefinitionsA) {
      panelDefs = utils.clone(panelDefinitionsB ?? []);

      panelDefinitionsA.forEach((panelDefinition) => {
        const panelDefIndex = panelDefs.findIndex((panelDef) => utils.camelcase(panelDef.name) === utils.camelcase(panelDefinition.name));
        if (panelDefIndex !== -1) {
          panelDefs[panelDefIndex] = {
            ...panelDefinition,
            ...utils.cleanObject(panelDefs[panelDefIndex]),
            position: (panelDefs[panelDefIndex].position ?? panelDefinition.position) * constants.numbers.position.panelGap,
            name: panelDefinition.name
          }
        } else {
          panelDefs.push({
            ...panelDefinition,
            position: panelDefinition.position * constants.numbers.position.panelGap
          });
        }
      })
    }

    if (panelDefs?.length > 0) {
      return panelDefs;
    } else {
      return null;
    }
  }, [panelDefinitionsB, panelDefinitionsA]);
}

export function useMergeGraphDefinitions (graphDefinitionsA, graphDefinitionsB) {
  return useMemo(() => {
    let graphDefs;
    if (graphDefinitionsA) {
      graphDefs = utils.clone(graphDefinitionsB ?? []);

      graphDefinitionsA.forEach((graphDefinition) => {
        const graphDefIndex = graphDefs.findIndex((graphDef) => utils.camelcase(graphDef.name) === utils.camelcase(graphDefinition.name));
        if (graphDefIndex !== -1 && !graphDefinition.locked && !graphDefs[graphDefIndex].locked) {
          graphDefs[graphDefIndex] = {
            ...graphDefinition,
            ...utils.cleanObject(graphDefs[graphDefIndex]),
            position: (graphDefs[graphDefIndex].position ?? graphDefinition.position) * constants.numbers.position.graphGap,
            name: graphDefinition.name
          }
        } else {
          graphDefs.push({
            ...graphDefinition,
            position: graphDefinition.position * constants.numbers.position.graphGap
          });
        }
      })
    }

    if (graphDefs?.length > 0) {
      return graphDefs;
    } else {
      return null;
    }
  }, [graphDefinitionsB, graphDefinitionsA]);
}

export function useTableReset (pathPrefix, pathPostfix, tablePostfix = '') {
  const tableProvider = useTable();
  const loaded = tableProvider.list?.status?.isSuccess && !tableProvider.list?.status?.isLoading;
  const empty = tableProvider?.list?.data?.length === 0;
  const dataKey = tableProvider.dataKey;
  const reset = tableProvider.state?.reset;

  const loadedEmpty = loaded && empty;
  const tail = pathPostfix ? pathPostfix.split('/') : null;
  const isItemPage = tail?.length > 0 ? utils.isNumber(tail[0]) : false;
  const itemId = isItemPage ? utils.toNumber(tail[0]) : null;
  const postFix = isItemPage ? `${tail.slice(1).join('/')}` : '';

  const navigate = useLinkNavigate();

  const isItemPageRef = useUpdatedRef(isItemPage);
  const firstEvent = useEffectEvent(tableProvider.first);
  const findEvent = useEffectEvent(tableProvider.find);
  const setResetEvent = useEffectEvent(tableProvider.setReset);

  const debouncedReset = useMemo(() => {
    return utils.debounce(() => {
      if (isItemPageRef.current) {
        const findItemId = findEvent()?.[dataKey];
        const firstItemId = firstEvent()?.[dataKey];

        if (findItemId) {
          navigate({
            to: `${pathPrefix}/${findItemId}${postFix ? `/${postFix}` : ''}`,
            replace: true,
            keepSearchParams: true
          });
        } else if (firstItemId) {
          navigate({
            to: `${pathPrefix}/${firstItemId}${postFix ? `/${postFix}` : ''}`,
            replace: true,
            keepSearchParams: true
          });
        }
        setResetEvent?.(false);
      }
    }, constants.debounce.reset);
  }, [navigate, setResetEvent, firstEvent, findEvent,
    isItemPageRef, pathPrefix, postFix, dataKey]);

  const debouncedRedirect = useMemo(() => {
    return utils.debounce(() => {
      if (isItemPageRef.current) {
        navigate({to: `${pathPrefix}${tablePostfix ? `/${tablePostfix}` : ''}`, replace: true, keepSearchParams: true});
      }
    }, constants.debounce.reset);
  }, [navigate, isItemPageRef, pathPrefix, tablePostfix]);

  useEffect(() => {
    if (loaded) {
      if (loadedEmpty) {
        debouncedRedirect();
      } else if (itemId === 0) { // itemId = 0 means select one
        debouncedReset();
      }
    }
  }, [loaded, loadedEmpty, itemId, debouncedRedirect, debouncedReset]);

  useEffect(() => {
    if (reset) {
      if (loaded) {
        debouncedReset();
      }
    }
  }, [reset, loaded, debouncedReset]);
}

export function useProviderView (type, skip = false) {
  const [searchParams] = useSearchParams();
  const customViews = useEffectItem(Array.from(searchParams.entries()).filter((e) => e[0] === 'custom').map((e) => e[1]));

  return useMemo(() => {
    const viewDefinition = type.split('.').reduce((o, key) => {
      return o[key];
    }, constants);

    const customViewDefinitions = customViews.length > 0 ? customViews
      .map((c) => {
        const view = (!skip && c) ? c.split(':')[0] : 'default';
        const params = (!skip && c) ? c.split(':')?.[1]?.split('_') : null;
        return {
          name: view,
          params: params,
          skipStorage: true,
          ...viewDefinition?.view?.[view]
        };
      })
      .filter((_) => (_))
      .sort((a, b) => +(a.position ?? 0) - +(b.position ?? 0)) : [];

    const view = customViewDefinitions.length > 0 ? customViewDefinitions.reverse().reduce((o, v) => utils.mergeObjects(o, v), {}) : null;
    if (view) {
      view.activeViews = customViewDefinitions;
    }

    return view;
  }, [type, skip, customViews]);
}

export function useRecycleSlots () {
  const recycleSlotsRef = useRef({});

  return useMemo(() => {
    return {
      slot: (id) => recycleSlotsRef.current[id],
      refresh: (rows, items) => {
        const recycleSlotsNew = {}, usedSlots = {};
        rows.forEach((r, rIdx) => {
          const item = items[r.index];
          if (utils.isDefined(recycleSlotsRef.current[item.id])) {
            recycleSlotsNew[item.id] = {
              idx: recycleSlotsRef.current[item.id].idx,
              row: rIdx
            };
            usedSlots[recycleSlotsRef.current[item.id].idx] = true;
          }
        });
        rows.forEach((r, rIdx) => {
          const item = items[r.index];
          if (!utils.isDefined(recycleSlotsNew[item.id])) {
            const idx = (new Array(rows.length).fill(null)
              .findIndex((_, idx) => !usedSlots[idx]));
            recycleSlotsNew[item.id] = {
              idx: idx,
              row: rIdx
            };
            usedSlots[idx] = true;
          }
        });
        recycleSlotsRef.current = recycleSlotsNew;
      }
    }
  }, []);
}
