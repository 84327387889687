import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Card from 'components/atoms/Cards/Card/Card';

const StyledSuggestionCard = styled(Card)`
  display: flex;
  gap: ${props => props.theme.layout('2sp')};
  align-items: center;
  padding: ${props => props.theme.spacing(1.5)};
  background-color: transparent;
  
  .SuggestionCard {
    &-body {
      flex-grow: 1;
      width: 1px;
      overflow: hidden;
    }
    
    &-title {
      > .Typography {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    
    &-subtitle {
      white-space: normal;
    }

    &-number {
      width: 100%;
      max-width: 100%;
      display: flex;
      align-items: center;
      gap: ${props => props.theme.layout('1sp')};
      
      > .Typography {
        font-style: italic;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: ${props => props.theme.spacing(1/8)};
      }
      
      > .SuggestionCard-chip {
        margin-top: ${props => props.theme.layout(-3)};
      }
    }

    &-link {
      ${props => props.theme.font2Css('body2')};
      
      > a {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: fit-content;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledSuggestionCard.propTypes = {
  theme: PropTypes.object
};

StyledSuggestionCard.defaultProps = {
}

export default StyledSuggestionCard;
