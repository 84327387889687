import React, {useEffect, useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import StyledDropdownTableCellEdit
  from 'components/organisms/TableCellEdits/DropdownTableCellEdit/DropdownTableCellEdit.styles';
import BaseTableCellEdit from 'components/organisms/TableCellEdits/BaseTableCellEdit/BaseTableCellEdit';
import Box from 'components/atoms/Layout/Box/Box';
import dom from 'helpers/dom';
import utils from 'helpers/utils';
import constants from 'helpers/constants';
import {withMemo} from 'helpers/wrapper';

const DropdownTableCellEdit = withMemo(React.forwardRef((props, ref) => {
  const {
    cell,
    table,
    autoClose,
    autoFocus,
    Anchor,
    onMouseLeave,
    onMouseEnter,
    TableCellWrapperProps,
    TableCellPopperProps,
    ...innerProps
  } = useComponentProps(props, 'DropdownTableCellEdit');

  const innerRef = useRef(null);
  const boxRef = useRef(null);
  const popperRef = useRef(null);
  const hoverRef = useRef({});

  const dropdownTableCellEdit = useMemo(() => ({
    refs: {
      ref: innerRef,
      boxRef,
      popperRef
    },
    open: (...args) => {
      popperRef?.current?.close?.(...args);
    },
    close: (...args) => {
      popperRef?.current?.close?.(...args);
    }
  }), []);

  useImperativeHandle(ref, () => dropdownTableCellEdit);

  const hasAnchor = Boolean(Anchor);
  const anchorMemo = useMemo(() => ({
    getElement: () => {
      return hasAnchor ? boxRef.current?.children?.[0] : boxRef.current;
    },
    getBoundingClientRect: () => {
      let el = hasAnchor ? boxRef.current?.children?.[0] : boxRef.current;
      return el?.getBoundingClientRect();
    }
  }), [hasAnchor]);

  useEffect(() => {
    if (autoFocus) {
      const focus = () => {
        if (!dom.isPartOfParent(document.activeElement, innerRef.current)) {
          return dom.focusElement(innerRef.current);
        }
      }

      utils.retry(focus, 3);
    }
  }, [autoFocus]);

  const onMouseLeaveEvent = useEffectEvent(onMouseLeave);
  const onMouseEnterEvent = useEffectEvent(onMouseEnter);
  const debouncedHover = useMemo(() => {
    return utils.debounce((e) => {
      if (hoverRef.current.next !== hoverRef.current.prev) {
        if (hoverRef.current.next) {
          onMouseEnterEvent?.(e);
        } else {
          onMouseLeaveEvent?.(e);
        }
        hoverRef.current.prev = hoverRef.current.next;
      }
    }, constants.debounce.hover);
  }, [onMouseLeaveEvent, onMouseEnterEvent]);

  const handleDropdownOver = (e) => {
    hoverRef.current = {...hoverRef.current, next: true};
    debouncedHover(e);
  }

  const handleDropdownLeave = (e) => {
    hoverRef.current = {...hoverRef.current, next: false};
    debouncedHover(e);
  }

  const handleBoxOver = (e) => {
    hoverRef.current = {...hoverRef.current, next: true};
    debouncedHover(e);
  }

  const handleBoxLeave = (e) => {
    hoverRef.current = {...hoverRef.current, next: false};
    debouncedHover(e);
  }

  return <Box ref={boxRef}
              style={{height: '100%', width: '100%'}}
              {...TableCellWrapperProps}
              onMouseOver={handleBoxOver}
              onMouseLeave={handleBoxLeave}>
    {Anchor}
    <BaseTableCellEdit ref={popperRef}
                       cell={cell}
                       table={table}
                       autoClose={autoClose}
                       anchor={anchorMemo}
                       variant="popper"
                       onMouseOver={handleDropdownOver}
                       onMouseLeave={handleDropdownLeave}
                       TableCellPopperProps={TableCellPopperProps}>
      <StyledDropdownTableCellEdit ref={innerRef} {...innerProps}>
        {innerProps.children}
      </StyledDropdownTableCellEdit>
    </BaseTableCellEdit>
  </Box>
}));

DropdownTableCellEdit.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  table: PropTypes.object,
  cell: PropTypes.object,
  Anchor: PropTypes.any,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['standard']), PropTypes.string]),
  TableCellWrapperProps: PropTypes.object,
  TableCellPopperProps: PropTypes.object
};

DropdownTableCellEdit.defaultProps = {
  children: 'DropdownTableCellEdit text',
  variant: 'standard',
  autoFocus: true
};

export default DropdownTableCellEdit;
