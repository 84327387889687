import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import ContextWrapper from 'components/templates/Wrappers/Sidebars/ContextWrapper/ContextWrapper';

const StyledCollectionsThemesWrapper = styled(ContextWrapper)`
  ${ComponentStyles}
`;

StyledCollectionsThemesWrapper.propTypes = {
  theme: PropTypes.object
};

StyledCollectionsThemesWrapper.defaultProps = {
};

export default StyledCollectionsThemesWrapper;
