import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledFilesContextCard from 'components/organisms/Cards/FilesContextCard/FilesContextCard.styles';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import FilesList from 'components/organisms/Lists/FilesList/FilesList';
import Box from 'components/atoms/Layout/Box/Box';
import {P} from 'components/atoms/Text/Typography/Typography';

const FilesContextCard = React.forwardRef((props, ref) => {
  const {
    files,
    isLoading,
    action,
    FilesListProps,
    ...innerProps
  } = useComponentProps(props, 'FilesContextCard');

  const renderUploadButton = () => {
    return <ActionButton action={action}
                         variant="text"
                         isLoading={isLoading}/>
  }

  return <StyledFilesContextCard ref={ref} {...innerProps}
                                 title="Files"
                                 context={renderUploadButton()}
                                 isLoading={isLoading}>
    <FilesList className="FilesContextCard-list"
               files={files}
               loaderCount={3}
               isLoading={isLoading}
               {...FilesListProps}/>
    {(!(files?.length > 0) && !isLoading) ?
      <Box className="FilesContextCard-empty">
        <P>No file(s) found</P>
      </Box> : null}
    {innerProps.children}
  </StyledFilesContextCard>
});

FilesContextCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  files: PropTypes.array,
  isLoading: PropTypes.bool,
  action: PropTypes.object,
  FilesListProps: PropTypes.object
};

FilesContextCard.defaultProps = {
};

export default FilesContextCard;
