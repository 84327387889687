import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledSectionPanelContent from 'components/organisms/SectionPanels/SectionPanelContent/SectionPanelContent.styles';
import List from 'components/atoms/Lists/List/List';
import ListItem from 'components/atoms/Lists/ListItem/ListItem';
import utils from 'helpers/utils';
import Component from 'components/organisms/Utils/Component/Component';
import constants from 'helpers/constants';
import Debounce from 'components/organisms/Utils/Debounce/Debounce';

const SectionPanelContent = React.forwardRef((props, ref) => {
  const {
    data,
    isLoading,
    sectionPanel,
    section,
    cards,
    onDirty,
    Footer,
    ...innerProps
  } = useComponentProps({...props, ...utils.filterObject(props.section, [
      'cards', 'variant', 'Footer'
    ], false), ...props.section?.SectionPanelContentProps}, 'SectionPanelContent');

  const groupsMemo = useMemo(() => {
    if (cards) {
      return cards
        .map((card, idx) => {
          const id = `key_${card.id || card.name}_${idx}`;

          return {
            id,
            ...card
          };
        })
        .filter((card) => {
          const hidden = utils.isFunction(card.hidden) ?
            Boolean(card.hidden({data})) : (card.hidden || !card.Card);
          return !hidden;
        })
        .sort((a, b) => a.position - b.position)
        .reduce((groups, card) => {
          let group = groups.find((g) => g.id === card.group);
          if (!group) {
            group = {
              id: card.group ?? groups.length,
              cards: []
            };
            groups.push(group);
          }
          group.cards.push(card);

          return groups;
        }, []);
    } else {
      return [];
    }
  }, [cards, data]);

  const renderCard = (card) => {
    return <Component key={card.id}
                      Original={card.Card}
                      sectionPanel={sectionPanel}
                      section={section}
                      card={card}
                      onDirty={onDirty}
                      isLoading={isLoading}
                      data={data}/>
  }

  const renderFooter = (section) => {
    if (utils.isDefined(section.Footer)) {
      return <Component Original={section.Footer}
                        className="SectionPanelContent-footer"
                        sectionPanel={sectionPanel}
                        section={section}
                        cards={section.cards}
                        onDirty={onDirty}
                        isLoading={isLoading}
                        data={data}/>
    } else {
      return null;
    }
  }

  return <StyledSectionPanelContent ref={ref} {...innerProps}
                                    footer={renderFooter(section)}>
    <List gap={32} divider={true}>
      {groupsMemo.map((group, idx) => {
        return <ListItem key={group.id ?? idx} data-key={group.id ?? idx} density="densest">
          {group.cards.map((card, idx) => {
            return <Debounce key={card.id}
                             timeout={constants.debounce.minimal * (1 + Math.floor(idx / 2))}>
              {renderCard(card)}
            </Debounce>
          })}
        </ListItem>
      })}
    </List>
  </StyledSectionPanelContent>
});

SectionPanelContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  sectionPanel: PropTypes.object,
  section: PropTypes.object,
  onDirty: PropTypes.func,
  Footer: PropTypes.any
};

SectionPanelContent.defaultProps = {};

export default SectionPanelContent;
