import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useUploadFiles} from 'helpers/hooks/utils';
import {H6, Span} from 'components/atoms/Text/Typography/Typography';
import HomeWorkOutlined from '@mui/icons-material/HomeWorkOutlined';
import StyledEntityHeading from 'components/molecules/Headings/EntityHeading/EntityHeading.styles';
import Box from 'components/atoms/Layout/Box/Box';
import Icon from 'components/atoms/Icons/Icon/Icon';
import IconButton from 'components/atoms/Buttons/IconButton/IconButton';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';
import utils from 'helpers/utils';
import constants from 'helpers/constants';
import Edit from '@mui/icons-material/Edit';
import ActionLogo from 'components/molecules/Images/ActionLogo/ActionLogo';
import ActionBadge from 'components/molecules/Badges/ActionBadge/ActionBadge';
import LogoDialog from 'components/organisms/Dialogs/LogoDialog/LogoDialog';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';

const EntityHeading = React.forwardRef((props, ref) => {
  const {
    entity,
    showName,
    showLinks,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'EntityHeading', {
    static: ['isLoading', 'showLinks'],
    children: ['logo', 'name', 'links']
  });

  const profileProvider = useProfile();

  const [open, setOpen] = useState(false);

  const [uploadState, setUploadState, uploadFiles] = useUploadFiles();

  const updateEvent = useEffectEvent(profileProvider.updaters?.updateData);
  const createLogoEvent = useEffectEvent(profileProvider.updaters?.createLogo);

  const FileFieldProps = useMemo(() => {
    const fileState = uploadState ?
      Object.keys(uploadState).reduce((o, k) => {
        const split = k.split('_');
        o[split[1]] = uploadState[k];
        return o;
      }, {}) : null;

    return {
      FormFieldProps: {
        addDescription: false,
        status: fileState,
        minSize: 1,
        maxSize: constants.numbers.MB
      }
    }
  }, [uploadState]);

  const logoActionMemo = useMemo(() => ({
    auth: utils.createAuth({attribute: 'entity.update', meta: {entity}}),
    logo: entity?.logoUrl,
    altLogo: entity?.altLogoUrl,
    badge: <ActionBadge isLoading={isLoading}
                        action={{
                          label: <Icon icon={Edit} size="mini"/>,
                          auth: utils.createAuth({attribute: 'entity.update', meta: {entity}})
                        }}
                        hover={true}
                        size="smaller"
                        color="white" />,
    onClick: () => setOpen(true),
    LogoProps: {
      outlined: true,
      fallbackIcon: HomeWorkOutlined,
      isLoading: isLoading
    }
  }), [entity, isLoading]);

  const handleLogoChange = () => {
    setUploadState({});
  }

  const handleLogoSubmit = (logo) => {
    if (utils.isFile(logo)) {
      if (!utils.isDefined(logo.src) || logo.src !== entity?.logoUrl) {
        return createLogoEvent?.({
          entityId: entity?.entityId,
          filename: logo.name,
          description: logo.description,
          filesize: logo.size,
          filetype: logo.type || constants.filetypes.default
        })
          .then((res) => {
            const filename = res.response.data.data.filename;
            const uploadUrl = res.response.data.data.uploadUrl;
            const downloadUrl = res.response.data.data.downloadUrl;

            return uploadFiles([{file: logo, id: 'logo_0', url: uploadUrl, name: filename}], false)
              .then(() => {
                return updateEvent?.(entity, {logo_url: downloadUrl})
                  .then(() => {
                    setUploadState({});
                  })
              })
          })
      }
    } else if (!logo) {
      return updateEvent?.(entity, {logo_url: ''})
        .then(() => {
          setUploadState({});
        })
    }
  }

  const handleLogoClose = () => {
    setOpen(false);
  }

  const renderDialog = () => {
    if (open) {
      return <LogoDialog open={true}
                         name={entity?.name}
                         logo={(entity?.logoUrl !== entity?.altLogoUrl) ? entity?.logoUrl : null}
                         altLogo={entity?.altLogoUrl}
                         fallbackIcon={HomeWorkOutlined}
                         onChange={handleLogoChange}
                         onSubmit={handleLogoSubmit}
                         onClose={handleLogoClose}
                         FileFieldProps={FileFieldProps} />
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledEntityHeading ref={ref} {...innerProps}>
    <ActionLogo className="EntityHeading-logo"
                action={logoActionMemo}
                size="large"
                density="sparse"
                showInactive={true} />
    <Box className="EntityHeading-info">
      {showName ? <H6 className="EntityHeading-name" min={8} max={20} isLoading={isLoading} showTooltip={true}>{entity?.name}</H6> : null}
      {showLinks ? (!isLoading ? <Box className="EntityHeading-links">
        {constants.data.links.map((link, idx) => {
          if (entity?.[link.value]?.length > 0) {
            return <Tooltip key={`${link.value}_${idx}`} showDisabled={true}
                            title={link.tooltip}
                            placement="bottom">
              <IconButton size="smaller"
                          density="densest"
                          variant="transparent"
                          onClick={() => utils.externalLink(entity?.[link.value])}>
                <Icon icon={link.icon} size="tiny"/>
              </IconButton>
            </Tooltip>
          } else {
            return null;
          }
        }).filter((_) => (_))}
      </Box> : <Span isLoading={true} min={8} max={20}/>) : null}
    </Box>
    {renderDialog()}
  </StyledEntityHeading>
});

EntityHeading.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  entity: PropTypes.object,
  logoAction: PropTypes.object,
  showName: PropTypes.bool,
  showLinks: PropTypes.bool,
  isLoading: PropTypes.bool
};

EntityHeading.defaultProps = {
  showName: true,
  showLinks: true
};

export default EntityHeading;
