import React, {useEffect, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useBbox, useComponentProps} from 'helpers/hooks/utils';
import Typography, {Span} from 'components/atoms/Text/Typography/Typography';
import Box from 'components/atoms/Layout/Box/Box';
import utils from 'helpers/utils';
import StyledArticleCard from 'components/molecules/Cards/ArticleCard/ArticleCard.styles';
import Link from 'components/atoms/Links/Link/Link';
import Markdown from 'components/atoms/Formatters/Markdown/Markdown';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Img from 'components/atoms/Images/Img/Img';
import constants from 'helpers/constants';
import {EventNote, PersonPin} from '@mui/icons-material';
import Chip from 'components/atoms/Chips/Chip/Chip';
import ChipList from 'components/atoms/Chips/ChipList/ChipList';

const ArticleCard = React.forwardRef((props, ref) => {
  const {
    article,
    isLoading,
    onClick,
    ...innerProps
  } = useComponentProps(props, 'ArticleCard', {
    static: ['isLoading', 'clampText']
  });

  const innerRef = useRef(null);
  const contentRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  const contentBox = useBbox(() => contentRef.current?.querySelector('.ArticleCard-text'), ['clamped']);

  const [clampText, setClampText] = useState(true);
  const [clampActive, setClampActive] = useState(false);

  useEffect(() => {
    if (clampText) {
      setClampActive(contentBox?.clamped);
    }
  }, [contentBox?.clamped, clampText]);

  const renderIcon = () => {
    const source = constants.data.lookup('diskSourceTypes', article?.source);
    const subSource = constants.data.lookup('diskSourceTypes', article?.subSource);

    const icon = subSource ? subSource?.icon : source?.icon;
    const iconColor = subSource ? subSource?.iconColor : source?.iconColor;
    const img = subSource ? subSource?.img : source?.img;

    return <React.Fragment>
      {(icon || isLoading) ? <Box className="ArticleCard-logo">
        {utils.isReactElement(icon) ? icon : <Icon icon={icon} color={iconColor} size="smaller" isLoading={isLoading}/>}
      </Box> : null}
      {(!icon && img) ? <Box className="ArticleCard-logo">
        {utils.isReactElement(img) ? img : <Img src={img} alt="logo" isLoading={isLoading}/>}
      </Box> : null}
    </React.Fragment>
  }

  const renderTitle = () => {
    const title = article?.title ?? article?.author;

    return <Box className="ArticleCard-title">
      {renderIcon()}
      <Typography showTooltip={true}
                  isLoading={isLoading}
                  variant="subtitle2">
        <Link href={utils.cleanExternalLink(article?.url)}
              onClick={(e) => e.stopPropagation()}
              target="_blank">{title ?? 'No title'}</Link>
      </Typography>
    </Box>
  }

  const renderLabels = () => {
    if (article?.labels?.length > 0) {
      return <Box className="ArticleCard-labels">
        <ChipList variant="compact"
                  maxRows={2}
                  drawFromStart={true}>
          {article.labels.map((label, idx) => {
            return <Chip key={idx}
                         size="small"
                         color="primary"
                         variant="outlined"
                         isLoading={isLoading}
                         label={label.label} />
          })}
        </ChipList>
      </Box>
    }
  }

  const renderText = () => {
    const text = article?.embed || article?.text;
    if (text) {
      return <Box className="ArticleCard-text">
        <Typography as="div" isLoading={isLoading} variant="body2">
          <Markdown disabled={clampText}>{text}</Markdown>
        </Typography>
      </Box>
    } else if (isLoading) {
      return <Box className="ArticleCard-text">
        <Typography isLoading={isLoading} variant="body2" min={16} max={32}/>
      </Box>
    }
  }

  const renderDate = () => {
    if (article?.publishedAt) {
      return <Typography className="ArticleCard-date"
                         isLoading={isLoading}
                         variant="caption" color="text.secondary">
        <Icon size="smaller" icon={EventNote}/>
        <Span showTooltip={true}>{utils.dayjs(new Date(article?.publishedAt)).format('MMM YY')}</Span>
      </Typography>
    }
  }

  const renderAuthor = () => {
    if (article?.author) {
      const source = constants.data.lookup('diskSourceTypes', article?.source);
      const link = article.authorHandle ? (
        ([constants.disks.sourceTypes.linkedin, constants.disks.sourceTypes.twitter].includes(article.source)) ? (
          article.authorHandle.toLowerCase().includes('http') ? article.authorHandle :
            `${utils.cleanPath(source.link)}/${article.source === constants.disks.sourceTypes.linkedin ? 'company/' : ''}${article.authorHandle}`
        ) : article.authorHandle) : null;

      return <Typography className="ArticleCard-author"
                         isLoading={isLoading}
                         variant="caption"
                         color="text.secondary">
        <Icon size="smaller" icon={PersonPin}/>
        <Span showTooltip={true}>
          {link ? <Link href={utils.cleanExternalLink(link)}
                        onClick={(e) => e.stopPropagation()}
                        target="_blank">{article?.author}</Link> : article?.author}
        </Span>
      </Typography>
    }
  }

  const handleClick = (e) => {
    onClick?.(e, article);
    if (!e.defaultPrevented) {
      innerRef.current?.querySelector?.('.ArticleCard-title .Link')?.click();
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className, {
    clampText: clampText
  });

  return <StyledArticleCard ref={innerRef} {...innerProps}
                            isLoading={isLoading}
                            variant="outlined"
                            onClick={handleClick}>
    <Box ref={contentRef} className="ArticleCard-content">
      {renderTitle()}
      {renderLabels()}
      <Box className="ArticleCard-text-wrapper">
        {renderText()}
        {clampActive ? <Link className="readMore"
                             isLoading={isLoading}
                             onClick={(e) => {
                               setClampText((current) => !current);
                               e.stopPropagation();
                               e.preventDefault();
                             }}>
          Show {clampText ? 'more' : 'less'}
        </Link> : null}
      </Box>
    </Box>

    {!isLoading ? <Box className="ArticleCard-footer">
      {renderDate()}
      {renderAuthor()}
    </Box> : null}
  </StyledArticleCard>
});

ArticleCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  article: PropTypes.object,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func
};

ArticleCard.defaultProps = {
};

export default ArticleCard;
