import utils from 'helpers/utils';
import logger from 'helpers/logger';
import ServiceStore from 'stores/service.store';
import constants from 'helpers/constants';

const path = utils.servicePath(import.meta.url);

export class ClientCustomFieldService extends ServiceStore {
  constructor (app, callbacks) {
    logger.trace(`Starting module at: services/${path}`);

    super(path, app, {
      key: 'fieldId',
      invalidateParent: true,
      match: (cache, entity) => {
        return entity === 'customField' && cache?.context?.$store?.dataType === constants.dataTypes.other;
      }
    }, callbacks);
  }
}
