import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ContextCard from 'components/molecules/Cards/ContextCard/ContextCard';

const StyledQuestionnaireContextCard = styled(ContextCard)`
  width: 100%;

  .QuestionnaireContextCard {
    &-action {
      visibility: hidden;
      
      &.Skeleton {
        margin-right: 0;
      }
    }
  }

  &.QuestionnaireContextCard {
    &:not(:has(.Skeleton)):hover {
      .QuestionnaireContextCard-action {
        visibility: visible;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledQuestionnaireContextCard.propTypes = {
  theme: PropTypes.object
};

StyledQuestionnaireContextCard.defaultProps = {}

export default StyledQuestionnaireContextCard;
