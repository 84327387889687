import logger from 'helpers/logger';
import utils from 'helpers/utils';
import system from 'helpers/system';

const applyDefaultOptions = (options = {}) => {
  return {
    maxResurrectDepth: system.maxResurrectDepth(),
    ...options
  };
}

export default class BaseStore {
  constructor(options) {
    this.options = applyDefaultOptions(options);
  }

  retrieveObjectsFromCache (cache, cacheIds, invalid = false) {
    return utils.uniqueArray(cacheIds).map((cacheId) => {
      if (!cache[cacheId]) {
        if (!invalid) {
          logger.trace('Object not found in cache', this.path, cacheId, cache);
        }
        return null;
      } else {
        return cache[cacheId];
      }
    }).filter((_) => (_));
  }

  retrieveObjectsFromCacheById (cache, entity, ids, meta) {
    return Object.keys(cache)
      .filter((k) => ids.find((id) => {
        return (this.entity === entity && cache[k].id.toString() === id.toString()) ||
           Boolean(this.options.match?.(cache[k], entity, id, meta)) ||
            (cache[k].matchCallbacks ?? []).some((matchCallback) => matchCallback(cache[k], entity, id, meta));
      }))
      .map((k) => cache[k]);
  }

  retrieveObjectsFromCacheByContextId (cache, ids, ignoreParent) {
    return Object.keys(cache)
      .filter((k) => (!this.parent || ignoreParent) || ids.find((id) => {
        return utils.toArray(cache[k].context?.$store[this.parent?.listKey]).find((cId) => {
          return cId.toString() === id.toString();
        })
      }))
      .map((k) => cache[k]);
  }

  resurrectItemFromCache (keysHash, parent, key, get, caches = {}, used = [], depth = 0) {
    const maxDepth = this.options.maxResurrectDepth;

    const resurrectObject = (keysHash, source, key) => {
      const itm = source[key];

      if (!caches[itm.store.atoms.cache.key]) {
        caches[itm.store.atoms.cache.key] = get(itm.store.atoms.cache);
      }
      const objs = this.retrieveObjectsFromCache(caches[itm.store.atoms.cache.key], [itm.cacheId]);
      const obj = objs?.length > 0 ? objs[0]: null;

      if (obj) {
        if (obj.dummy || obj.preload) {
          if (utils.isArray(source)) {
            source.splice(key, 1);
          } else {
            delete source[key];
          }
        } else if (obj.deleted || (utils.isDefined(keysHash) && !obj.dataKeys?.find((odk) => odk.keysHash === keysHash))) {
          if (itm.store.options.keepDeletedData === true) {
            source[key] = obj.data ? obj.data : {[itm.store.key]: obj.id};
            source[key].$store = {
              deleted: true,
              keep: true
            };
          } else {
            source[key] = {
              $store: {
                deleted: true,
                keep: false
              }
            };
          }
        } else {
          used.push(itm);
          source[key] = obj.data;
        }
      } else {
        source[key] = null;
        logger.trace('Resurrection failed', itm);

        throw new Error('Resurrection failed');
      }
    };

    if (!utils.isDefined(maxDepth) || depth <= (maxDepth - 1)) {
      if (depth === 0) {
        parent = {root: parent};
        key = 'root';
      }

      if (utils.isArray(parent[key])) {
        let processed = [...parent[key]];
        processed.forEach((itm, idx) => {
          this.resurrectItemFromCache(keysHash, processed, idx, get, caches, used, depth + 1);
        });

        parent[key] = processed.filter((itm) => !utils.isObject(itm) || !itm.$store?.deleted || itm.$store?.keep);
      } else if (utils.isObject(parent[key])) {
        if (parent[key].cacheId && parent[key].store) {
          resurrectObject(keysHash, parent, key);
          keysHash = null; // reset the keysHash it's only used to extra verify the very first layer
        }
        if (!utils.isObject(parent[key]) || !parent[key]?.$store?.deleted || parent[key]?.$store?.keep) {
          if (utils.isArray(parent[key])) {
            let processed = [...parent[key]];
            processed.forEach((itm, idx) => {
              this.resurrectItemFromCache(keysHash, processed, idx, get, caches, used, depth + 1);
            });

            parent[key] = processed.filter((itm) => !utils.isObject(itm) || !itm.$store?.deleted || itm.$store?.keep);
          } else if (utils.isObject(parent[key]) && !utils.isClass(parent[key])) {
            let processed = {...parent[key]};
            Object.keys(processed).forEach((k) => {
              this.resurrectItemFromCache(keysHash, processed, k, get, caches, used, depth + 1);
            });

            parent[key] = processed;
          }
        }
      }
    }

    const data = !key ? parent : parent[key];
    if (!utils.isObject(data) || !data.$store?.deleted || data.$store?.keep) {
      return {data, used};
    } else {
      return {data: null, used}; // removed
    }
  }

  resurrectItem (keysHash, data, get) {
    try {
      return this.resurrectItemFromCache(keysHash, data, null, get);
    } catch (err) {
      logger.trace('Resurrection failed', err, data, this);
      return {data: null, used: []};
    }
  }
}
