import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import constants from 'helpers/constants';
import EntityCard from 'components/molecules/Cards/EntityCard/EntityCard';
import StyledChooseEntityWizardContent
  from 'components/organisms/WizardContent/ChooseEntityWizardContent/ChooseEntityWizardContent.styles';
import utils from 'helpers/utils';
import {useEntityMergeAttempt} from 'services/entity/entity.hooks';

const ChooseEntityWizardContent = React.forwardRef((props, ref) => {
  const {
    entity,
    wizard,
    step,
    ...innerProps
  } = useComponentProps(props, 'ChooseEntityWizardContent');

  const setDataEvent = useEffectEvent(wizard.setData);

  const [merge, setMerge] = useState({});

  const mergeAttempt = useEntityMergeAttempt({entityId: entity?.entityId, mergedId: merge?.mergedId}, {
    ...constants.queryOptions.runOnceFresh,
    enabled: merge?.mergedId > 0
  });

  const fields = useMemo(() => ([{
    name: 'target',
    label: 'Choose a company',
    inlineLabel: 'Company',
    placeholder: 'Search company',
    description: '',
    type: constants.formFieldTypes.autocomplete,
    conversion: constants.formFieldConversionTypes.none,
    options: 'entities',
    required: true,
    target: 'data',
    filter: entity ? [`-${entity.entityId}`] : [],
    FormFieldProps: {
      variant: 'staticLabel',
      initialSearch: entity?.name,
      renderOption: (option) => {
        return <EntityCard entity={option.entity}
                           variant="suggestion"
                           radius="square"
                           fullWidth={true}
                           showLink={true}
                           showStatus={false}
                           showRelevancy={false}/>
      }
    }
  }]), [entity]);

  useEffect(() => {
    if (merge.callback && (mergeAttempt.status?.isSuccess || mergeAttempt.status?.error)) {
      merge.callback(mergeAttempt.data?.data, mergeAttempt.status?.error);
      setMerge({});
    }
  }, [merge, mergeAttempt.data, mergeAttempt.status?.isSuccess, mergeAttempt.status?.error]);

  const handleSubmit = (values) => {
    return new Promise((resolve, reject) => {
      setMerge({mergedId: values['target']?.entity?.entityId, callback: (result, error) => {
        if (error) {
          reject('Retrieving merge information failed');
        } else {
          setDataEvent?.((current) => ({
            ...current,
            [wizard.dataKey]: {
              ...current[wizard.dataKey],
              mergedId: values['target']?.entity?.entityId,
              changes: {},
              merge: result
            }
          }));

          resolve();
        }
      }});
    });
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledChooseEntityWizardContent ref={ref} {...innerProps}
                                          wizard={wizard}
                                          step={step}
                                          fields={fields}
                                          onSubmit={handleSubmit}/>
});

ChooseEntityWizardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  wizard: PropTypes.object,
  step: PropTypes.object,
  entity: PropTypes.object
};

ChooseEntityWizardContent.defaultProps = {};

export default ChooseEntityWizardContent;
