import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Card from 'components/atoms/Cards/Card/Card';

const StyledTaskCard = styled(Card)`
  display: flex;
  flex-direction: column;
  
  .TaskCard {
    &-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: ${props => props.theme.layout('1sp')};

      padding: ${props => props.theme.spacing(2)};
    }

    &-title {
      display: flex;
      align-items: center;
      > *:first-child {
        flex-grow: 1;
      }
      
      &-check {
        position: absolute;
        right: ${props => props.theme.spacing(1)};
        top: ${props => props.theme.spacing(1)};
      }
    }
    
    &-subject {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      gap: ${props => props.theme.layout('1sp')};

      .AvatarGroup {
        justify-content: flex-end;
      }
    }
    
    &-footer {
      display: flex;
      align-items: center;
      border-top: 1px solid ${props => props.theme.property('palette.divider')};
      padding: ${props => props.theme.spacing(2)};
      gap: ${props => props.theme.layout('1sp')};
      
      .ChipList {
        width: 1px;
        flex-grow: 1;
        flex-wrap: nowrap;
        gap: ${props => props.theme.layout('1sp')};
        height: fit-content;
        
        > li:has(.TaskCard-location) {
          overflow: hidden;
        }
      }

      .TaskCard-tags-collaborate, .TaskCard-due {
        line-height: ${props => props.theme.lineHeight('1f')};
        align-items: center;
        color: ${props => props.theme.property('palette.text.disabled')};
      }

      .TaskCard-tags-collaborate {
        margin-top: ${props => props.theme.layout(1)};
        svg {
          margin-top: ${props => props.theme.layout(-1)};
        }
      }

      .TaskCard-due {
        margin-top: ${props => props.theme.layout(0)};
        svg {
          margin-top: ${props => props.theme.layout(-1)};
        }
      }
      
      .TaskCard-responsible {
        display: none;
      }

      .TaskCard-due, .TaskCard-tags-collaborate {
        &.Typography {
          display: flex;
          gap: ${props => props.theme.layout('0.5sp')};
          white-space: nowrap;
        }
      }
    }
  }

  &.TaskCard-showDone {
    &-title {
      > *:first-child {
        margin-right: ${props => props.theme.layout('3.5sp')};
      }
    }
  }
 
  // sizes
  &.TaskCard-size-small {
    &.TaskCard-showDone {
      .TaskCard-title {
        > *:first-child {
          margin-right: ${props => props.theme.layout('3sp')};
        }
      }
    }
  }
  
  // variants
  &.TaskCard-orientation-horizontal {
    &.TaskCard-showDone {
      &-title {
        > *:first-child {
          margin-right: ${props => props.theme.layout('3sp')};
        }
      }
    }
    
    .TaskCard {
      &-content {
        gap: ${props => props.theme.layout('1sp')};
      }
      
      &-footer {
        padding: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(2)};
      }
    }

    &.TaskCard-size-small, &.TaskCard-size-smaller {
      .TaskCard {
        &-content {
          padding-bottom: ${props => props.theme.spacing(1)};
        }
        &-subject {
          display: none;
        }
        
        &-footer {
          border-top: unset;
          padding-top: 0;
          align-items: flex-end;
          padding-bottom: ${props => props.theme.spacing(2)};

          .ChipList {
            margin-bottom: ${props => props.theme.layout(-1)};
          }
          
          .TaskCard-responsible {
            display: flex;
            margin-bottom: ${props => props.theme.layout(-2)};
          }
          .TaskCard-due {
            display: none;
          }
        }
      }
    }
    
    &.TaskCard-size-smaller {
      .TaskCard {
        &-footer {
          .TaskCard-tags {
            > li:has(.TaskCard-tags-collaborate) {
              display: none;
            }
          }
          .TaskCard-due {
            display: none;
          }
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledTaskCard.propTypes = {
  theme: PropTypes.object
};

StyledTaskCard.defaultProps = {}

export default StyledTaskCard;
