import React, {useCallback, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import constants from 'helpers/constants';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Box from 'components/atoms/Layout/Box/Box';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import Card from 'components/atoms/Cards/Card/Card';
import Button from 'components/atoms/Buttons/Button/Button';
import Add from '@mui/icons-material/Add';
import ConnectionCard from 'components/organisms/Cards/ConnectionCard/ConnectionCard';
import StyledClientConnectionProfileCardContent
  from 'components/organisms/Cards/ClientConnectionProfileCardContent/ClientConnectionProfileCardContent.styles';
import MappingsForm from 'components/organisms/Forms/MappingsForm/MappingsForm';
import FieldMappingsForm from 'components/organisms/Forms/FieldMappingsForm/FieldMappingsForm';
import {Span} from 'components/atoms/Text/Typography/Typography';
import {InArrow, OutArrow} from 'assets/icons';
import Close from '@mui/icons-material/Close';

const ClientConnectionProfileCardContent = React.forwardRef((props, ref) => {
  const {
    card,
    content,
    connection,
    client,
    isDialog,
    fieldData,
    onValidating,
    onSubmit,
    onPatch,
    renderedLoader,
    ...innerProps
  } = useComponentProps(props, 'ClientConnectionProfileCardContent', {
    static: ['isAddNew', 'isEditing', 'isDeleted']
  });

  const formRef = useRef(null);
  const innerRef = useRef(null);

  const connectionDef = constants.data.lookup('connections', connection?.type);

  const [type, setType] = useState(connectionDef);
  const [key, setKey] = useState(connection?.key);
  const [active, setActive] = useState(Boolean(connection?.active ?? true));
  const [reset, setReset] = useState(Boolean(false));
  const [mappedFields, setMappedFields] = useState(connection?.fields);
  const [mappings, setMappings] = useState(connection?.mappings);
  const [allMappedFields, setAllMappedFields] = useState([]);

  // ProfileCardContent handles form state
  useImperativeHandle(ref, () => formRef.current);

  const isAddNew = !utils.isDefined(connection?.connectionId);
  const isEditing = content.state.isEditing;
  const isLoading = !utils.isDefined(connection?.name) || !utils.isDefined(connection?.type);

  const getMapping = useCallback((fields, mappings) => {
    const mappedFields = (fields ?? [])
      .filter((f) => utils.isDefined(f.from?.id) && utils.isDefined(f.to?.id))
      .map((f) => utils.filterObject(f, 'id'));

    const processedMappings = (mappings ?? [])
      .filter((m) => utils.isDefined(m.teams) || utils.isDefined(m.team))
      .map((m) => {
        return {
          ...m,
          team: utils.filterObject((utils.isDefined(m.teams) ? m.teams : [m.team])[0], 'id'),
          lists: (m.lists ?? [])
            .filter((l) => utils.isDefined(l.from?.id) && utils.isDefined(l.to?.id))
            .map((l) => {
              return {
                ...utils.filterObject(l, 'id'),
                fields: (l.fields ?? [])
                  .filter((f) => utils.isDefined(f.from?.id) && utils.isDefined(f.to?.id))
                  .map((f) => utils.filterObject(f, 'id'))
              }
            }),
          fields: (m.fields ?? [])
            .filter((f) => utils.isDefined(f.from?.id) && utils.isDefined(f.to?.id))
            .map((f) => utils.filterObject(f, 'id'))
        }
      })
      .map((m) => utils.filterObject(m, ['id', 'teams', 'list']));

    return [mappedFields, processedMappings];
  }, []);

  const fields = useMemo(() => {
    const fields = [];

    if (isAddNew) {
      fields.push({
        name: 'type',
        label: 'Type',
        entity: 'connection',
        type: constants.formFieldTypes.autocomplete,
        validation: constants.formFieldValidationTypes.text,
        placeholder: 'Select a connection',
        required: true,
        readOnly: !isEditing || !isAddNew,
        initial: type,
        options: constants.data.connections.filter((ct) => {
          return !(client?.props?.connections ?? []).find((c) => c.connectionId !== connection.connectionId && c.type === ct.value);
        }),
        debounce: content.state.canPatch ? constants.debounce.input : false,
        FormFieldProps: {
          variant: 'inlineLabel',
          hiddenLabel: !isDialog,
        }
      });
    }

    fields.push({
      name: 'name',
      label: 'Name',
      entity: 'connection',
      placeholder: 'Type a name',
      type: constants.formFieldTypes.text,
      validation: constants.formFieldValidationTypes.text,
      initial: connection?.name ?? '',
      required: true,
      readOnly: !isEditing,
      disabled: content.state.isSubmitting,
      debounce: content.state.canPatch ? constants.debounce.input : false,
      FormFieldProps: {
        variant: 'inlineLabel',
        hiddenLabel: !isDialog
      }
    });

    fields.push({
      name: 'key',
      label: 'Key',
      entity: 'connection',
      placeholder: 'Type a key',
      type: constants.formFieldTypes.text,
      validation: constants.formFieldValidationTypes.text,
      initial: connection?.key ?? '',
      required: active,
      readOnly: !isEditing,
      disabled: content.state.isSubmitting,
      debounce: content.state.canPatch ? constants.debounce.input : false,
      FormFieldProps: {
        variant: 'inlineLabel',
        hiddenLabel: !isDialog ,
      }
    });

    fields.push({
      name: 'comment',
      label: 'Description',
      inlineLabel: 'description',
      placeholder: 'Describe this connection',
      type: constants.formFieldTypes.textarea,
      validation: constants.formFieldValidationTypes.text,
      conversion: constants.formFieldConversionTypes.value,
      entity: 'connection',
      readOnly: !isEditing,
      initial: connection?.comment ?? '',
      disabled: content.state.isSubmitting,
      debounce: content.state.canPatch ? constants.debounce.input : false,
      FormFieldProps: {
        minRows: 2,
        variant: 'inlineLabel',
        hiddenLabel: !isDialog && Boolean(isEditing)
      }
    });

    fields.push({
      name: 'active',
      label: 'Active',
      inlineLabel: 'active',
      type: constants.formFieldTypes.list,
      validation: constants.formFieldValidationTypes.boolean,
      conversion: constants.formFieldConversionTypes.value,
      options: constants.data.toggleYesNo,
      entity: 'connection',
      initial: connection.active ?? true,
      FormFieldProps: {
        variant: 'inlineLabel',
        deselect: false,
        hiddenLabel: !isDialog && Boolean(isEditing),
        ListProps: {
          catchFocus: false,
          orientation: 'horizontal',
          gap: 16
        }
      }
    });

    const failed = ['failed', 'inactive'].includes(connection?.status);
    const errorInfo = connection.active && failed && connection?.errors?.find((e) => e.type === 'info');

    if (errorInfo && !reset) {
      const renderInfo = () => {
        const errors = ([...connection?.errors] || [])
          .filter((e) => e.type === 'info')
          .sort((a ,b) => {
            return a.task === b.task ? 0 : (
              (a.task === 'source' && b.task !== 'source') ? -1 : 1
            );
          });

        return <React.Fragment>
          <Span>Synchronisation failed for this connection, see errors below</Span>
          <Box className="info-lines">
            {errors.map((err, errIdx) => {
              return <Box key={errIdx}>
                <Span>{err.task === 'source' ? 'The following errors happened on list mappings:' : 'The following errors happened on field mappings:'}</Span>
                {[...err.info]
                  .sort((a, b) => {
                    return a.from === b.from ? 0 : (
                      (a.from && !b.from) ? -1 : 1
                    );
                  })
                  .map((info, idx) => {
                    return <React.Fragment key={idx}>
                      <br />
                      <Span>
                        <Icon size="small" icon={info.from ? OutArrow : InArrow}/>
                        <Span>{info.name}:</Span>
                        <Span>{info.error}</Span>
                      </Span>
                    </React.Fragment>
                  })}
                </Box>
              })}
          </Box>
        </React.Fragment>
      }

      fields.push({
        section: true,
        type: constants.formSectionTypes.info,
        color: 'error',
        info: renderInfo(),
        action: {
          icon: Close,
          label: 'Reset',
          onClick: () => {
            formRef.current?.values({'reset': true});
          }
        }
      });
    }

    fields.push({
      name: 'reset',
      type: constants.formFieldTypes.checkbox,
      conversion: constants.formFieldConversionTypes.boolean,
      initial: false,
      FormFieldProps: {
        hidden: true
      }
    });

    if (type) {
      const currentConnection = {type: type?.value, key, active, hasTeams: type.hasTeams};

      fields.push({
        name: `fields`,
        label: 'General fields',
        formGroup: 'fields',
        type: constants.formFieldTypes.component,
        validation: `${constants.formFieldValidationTypes.component}(${constants.formFieldValidationTypes.unique}(${constants.formFieldValidationTypes.fieldMapping}))`,
        validate: (value, testContext) => {
          const other = allMappedFields.filter((f) => utils.isDefined(f.mapIdx));
          const duplicate = (value.value || []).find((field) => {
            return other.find((mf) => {
              const fromReadOnly = [constants.client.connection.mappingDirections.out].includes(field.direction) ||
                [constants.client.connection.mappingDirections.out].includes(mf.direction);
              const toReadOnly = [constants.client.connection.mappingDirections.in].includes(field.direction) ||
                [constants.client.connection.mappingDirections.in].includes(mf.direction);

              return (mf.from?.id === field.from?.id && !fromReadOnly && (!field.from?.teamOnly || !field.from?.listOnly)) ||
                (mf.to?.id === field.to?.id && !toReadOnly && (!field.to?.teamOnly || !field.to?.listOnly));
            });
          });

          if (duplicate) {
            return testContext.createError({message: 'Duplicate field mapping'});
          } else {
            return true;
          }
        },
        conversion: constants.formFieldConversionTypes.component,
        entity: 'connection',
        valueProp: 'fields',
        Component: <FieldMappingsForm fieldData={fieldData}
                                      mappedFields={allMappedFields.filter((f) => utils.isDefined(f.mapIdx))}
                                      connection={currentConnection}/>,
        initial: {
          value: connection?.fields ?? [],
          errors: false
        },
        FormFieldProps: {
          variant: 'staticLabel',
          hiddenLabel: false,
          autoFocus: false
        }
      });

      fields.push({
        name: `mappings`,
        label: 'Mappings',
        formGroup: 'mapping',
        type: constants.formFieldTypes.component,
        validation: `${constants.formFieldValidationTypes.component}(${constants.formFieldValidationTypes.unique}(${constants.formFieldValidationTypes.mapping}))`,
        validate: (value, testContext) => {
          const duplicate = (value.value || []).find((mapping) => {
            return (mapping.fields || []).find((field) => {
              return allMappedFields.find((mf) => {
                const fromReadOnly = [constants.client.connection.mappingDirections.out].includes(field.direction) ||
                  [constants.client.connection.mappingDirections.out].includes(mf.direction);
                const toReadOnly = [constants.client.connection.mappingDirections.in].includes(field.direction) ||
                  [constants.client.connection.mappingDirections.in].includes(mf.direction);
                let sameTeam = ((+mf.team?.from?.id || 0) === mapping.teams?.[0]?.from?.id || (+mf.team?.to?.id || 0) === mapping.teams?.[0]?.to?.id);

                return (mf.from?.id === field.from?.id && !fromReadOnly && (!sameTeam || mf.list) &&
                    (!field.from?.teamOnly || (!field.from?.listOnly && sameTeam))) ||
                  (mf.to?.id === field.to?.id && !toReadOnly && (!sameTeam || mf.list) &&
                    (!field.to?.teamOnly || (!field.to?.listOnly && sameTeam)));
              });
            });
          });

          if (duplicate) {
            return testContext.createError({message: 'Duplicate field mapping'});
          } else {
            return true;
          }
        },
        conversion: constants.formFieldConversionTypes.component,
        entity: 'connection',
        valueProp: 'mappings',
        Component: <MappingsForm fieldData={fieldData}
                                 mappedFields={allMappedFields}
                                 connection={currentConnection}/>,
        initial: {
          value: connection?.mappings?.map((m) => ({
            ...m,
            teams: utils.isDefined(m.team) ? [m.team] : []
          })) ?? [],
          errors: false
        },
        FormFieldProps: {
          variant: 'staticLabel',
          hiddenLabel: false,
          autoFocus: false
        }
      });
    }

    return fields
      .filter((f) => !f.readOnly || !utils.isEmpty(f.initial));
  }, [connection, type, key, active, reset, isAddNew, isDialog, isEditing,
    fieldData, allMappedFields, client?.props?.connections, content.state.canPatch, content.state.isSubmitting]);

  const handleChange = (field, value) => {
    if (field.name === 'key') {
      setKey(key);
    } else if (field.name === 'active') {
      setActive(value);
    } else if (field.name === 'reset') {
      setReset(value);
    }
  }

  const handleChangeDirect = (e) => {
    const field = fields?.find((field) => field.name === e?.target?.name);
    const value = e?.target?.value;

    if (field.name === 'type') {
      setType(value);
    } else if (field.name === 'fields') {
      setMappedFields(value?.value);
    } else if (field.name === 'mappings') {
      setMappings(value?.value);
    }
  }

  useLayoutEffect(() => {
    const [flds, maps] = getMapping(mappedFields, mappings);

    setAllMappedFields((current) => {
      const all = flds.concat(maps.reduce((a, m, idx) => {
        return a.concat(m.fields.map((f) => ({...f, team: m.team, mapIdx: idx})))
          .concat((m.lists || []).reduce((al, l, lIdx) => {
            return al.concat(l.fields.map((f) => ({...f, team: m.team, list: l, listIdx: lIdx, mapIdx: idx})))
          }, []));
      }, []));

      return utils.updater(all)(current);
    });
  }, [mappedFields, mappings, getMapping]);

  const handleSubmit = (values) => {
    const fields = [{
      name: 'connections'
    }];

    const connections = (client?.props?.connections ?? []);
    const connectionIndex = isAddNew ? connections.length :
      connections.findIndex((c) => c.connectionId === connection?.connectionId);
    const clientConnection = isAddNew ? { connectionId: utils.sha1(`${values['name']}_${connectionIndex}_${Math.random() * constants.numbers.randomInt}`)} :
      connections[connectionIndex];

    const [mappedFields, mappings] = getMapping(values.fields, values.mappings);

    const changes = {
      connections: [
        ...connections.slice(0, connectionIndex),
        {
          ...clientConnection,
          ...connection,
          name: values['name'],
          type: isAddNew ? values['type'].value : connection.type,
          key: values['key'],
          comment: values['comment'],
          active: values['active'],
          fields: mappedFields,
          mappings: mappings
        },
        ...connections.slice(connectionIndex + 1)
      ]
    };

    return onSubmit?.(changes, null, fields, changes.connections[connectionIndex], true);
  };

  const handleValidating = (validating, dirty, errors) => {
    onValidating?.(validating, dirty, errors);
  }

  const onValidatingEvent = useEffectEvent(onValidating);
  useEffect(() => {
    if (isAddNew && isEditing) {
      onValidatingEvent?.(false, true, false);
    }
  }, [isAddNew, isEditing, onValidatingEvent]);

  const renderReadOnly = () => {
    return <ConnectionCard connection={connection}
                           icon={connectionDef?.icon}
                           img={connectionDef?.img}
                           isLoading={isLoading}
                           IconProps={{color: connectionDef?.color}}/>
  };

  const renderForm = () => {
    return <InlineForm ref={formRef}
                       className="ClientConnectionProfileCardContent-form"
                       fields={fields}
                       fieldData={fieldData}
                       onChange={handleChange}
                       onChangeDirect={handleChangeDirect}
                       onSubmit={handleSubmit}
                       onValidating={handleValidating}/>
  };

  const renderNew = () => {
    return <Card className="ClientConnectionProfileCardContent-new" fullWidth={true} fullHeight={true}>
      <Button variant="contained"
              startIcon={<Icon icon={Add} />}
              onClick={() => {
                card.edit()
              }}>
        Add connection
      </Button>
    </Card>
  };

  delete innerProps.source;
  innerProps.className = utils.flattenClassName(innerProps.className, {
    isAddNew: isAddNew,
    isEditing: isEditing
  });

  return <StyledClientConnectionProfileCardContent ref={innerRef} {...innerProps}>
    <Box className="ClientConnectionProfileCardContent-content">
      {!isDialog ? (isAddNew ? renderNew() : renderReadOnly()) : renderForm()}
    </Box>
  </StyledClientConnectionProfileCardContent>
});

ClientConnectionProfileCardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  card: PropTypes.object,
  content: PropTypes.object,
  connection: PropTypes.object,
  client: PropTypes.object,
  isDialog: PropTypes.bool,
  fieldData: PropTypes.object,
  onValidating: PropTypes.func,
  onSubmit: PropTypes.func,
  onPatch: PropTypes.func,
};

ClientConnectionProfileCardContent.defaultProps = {
};

export default ClientConnectionProfileCardContent;


