import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {H6} from 'components/atoms/Text/Typography/Typography';
import CardContent from 'components/atoms/Cards/CardContent/CardContent';
import CardHeader from 'components/atoms/Cards/CardHeader/CardHeader';
import StyledGraphCard from 'components/molecules/Cards/GraphCard/GraphCard.styles';
import CardActions from 'components/atoms/Cards/CardActions/CardActions';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';

const GraphCard = React.forwardRef((props, ref) => {
  const {
    title,
    context,
    actionButtons,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'GraphCard');

  const renderButtons = (items) => {
    return items.map((action, idx) => {
      if (action.label) {
        return <ActionButton key={idx}
                             action={action}
                             isLoading={isLoading}/>
      } else {
        return <ActionIconButton key={idx}
                                 action={action}
                                 isLoading={isLoading}/>
      }
    });
  }

  return <StyledGraphCard ref={ref} {...innerProps}>
    {(title || context) ? <CardHeader className="GraphCard-header"
                                      title={title ? <H6 className="GraphCard-header-title" isLoading={isLoading}>{title}</H6> : null}
                                      action={context} /> : null}
    <CardContent className="GraphCard-content">
      {innerProps.children}
    </CardContent>

    {actionButtons?.length > 0 ? <CardActions className="GraphCard-actions">
      {renderButtons(actionButtons)}
    </CardActions> : null}
  </StyledGraphCard>
});

GraphCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.string,
  context: PropTypes.element,
  actionButtons: PropTypes.array,
  isLoading: PropTypes.bool
};

GraphCard.defaultProps = {
  elevation: 1,
  radius: 'round'
};

export default GraphCard;
