import React, {useLayoutEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDashboardContextWrapper
  from 'components/templates/Wrappers/Dashboard/DashboardContextWrapper/DashboardContextWrapper.styles';
import TimelineList from 'components/organisms/Lists/TimelineList/TimelineList';
import {H6, P} from 'components/atoms/Text/Typography/Typography';
import ToggleButtonGroup from 'components/atoms/Toggles/ToggleButtonGroup/ToggleButtonGroup';
import ToggleButton from 'components/atoms/Toggles/ToggleButton/ToggleButton';
import Box from 'components/atoms/Layout/Box/Box';
import UpdateForm from 'components/organisms/Forms/UpdateForm/UpdateForm';
import constants from 'helpers/constants';
import ListWrapper from 'components/templates/Wrappers/Headers/ListWrapper/ListWrapper';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import {useClientTimelineList} from 'services/client/timeline/timeline.hooks';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';

import {useCommentCreate} from 'services/comment/comment.utils';
import utils from 'helpers/utils';
import useMediaQuery from '@mui/material/useMediaQuery';

const DashboardContextWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DashboardContextWrapper', {
    children: ['header', 'feed', 'form']
  });

  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [open, setOpen] = useState(!mdDown);

  const profileProvider = useProfile();
  const client = profileProvider.data?.data;

  const authorize = useAuthorize();

  const canCreate = authorize({attribute: 'team.comment.create'});
  const commentCreate = useCommentCreate();

  const timelineMentioned = Boolean(profileProvider?.state?.settings?.timelineMentioned);
  const timeline = useClientTimelineList({
    clientId: client?.clientId,
    page: 0,
    pageSize: 15,
    filter: [
      {id: 'mentioned', value: Boolean(timelineMentioned)},
      {id: 'excludedTypes', value: 'task'}
    ]
  }, {
    ...constants.queryOptions.infinite,
    enabled: utils.isDefined(client?.clientId)
  });
  const isLoading = timeline.status?.isLoading || !utils.isDefined(timeline.data);

  const handleToggle = (e, value) => {
    if (value) {
      profileProvider?.setSettings?.({timelineMentioned: value !== 'all'});
    }
  };

  const handleUpdateFormSubmit = (value) => {
    profileProvider?.setSettings?.({timelineMentioned: false});
    return commentCreate(value);
  };

  const renderUpdates = () => {
    const renderHeader = () => {
      return <Box className="DashboardContextWrapper-header">
        <H6>Recent updates</H6>
        <ToggleButtonGroup color="primary"
                           size="small"
                           value={timelineMentioned ? 'mentions' : 'all'}
                           exclusive={true}
                           onChange={handleToggle}>
          <ToggleButton value="all">All</ToggleButton>
          <ToggleButton value="mentions">Mentions</ToggleButton>
        </ToggleButtonGroup>
      </Box>
    }

    const isItemEqual = (update, selected) => {
      return +update.timelineId === +selected.timelineId;
    }

    return <ListWrapper header={renderHeader()}
                        footer={canCreate ? <UpdateForm className="DashboardContextWrapper-form"
                                                        onSubmit={handleUpdateFormSubmit} /> : null}>
      <Box className="DashboardContextWrapper-feed">
        {(timeline.data?.length > 0 || isLoading) ?
          <TimelineList count={timeline?.meta?.resultsCount}
                        timeline={timeline.data}
                        dataKey="timelineId"
                        isItemEqual={isItemEqual}
                        onFetchMore={timeline.query?.fetchNextPage}
                        isLoading={isLoading}
                        showProgressBar={timeline.status?.isLoadingNext} /> : null}
        {(timeline.data?.length === 0 && !isLoading) ?
          <Box className="DashboardContextWrapper-empty">
            <P>No recent updates found</P>
          </Box> : null}
      </Box>
    </ListWrapper>
  }

  const handleContextToggle = (e, open) => {
    setOpen(open);
  }

  useLayoutEffect(() => {
    setOpen(!mdDown);
  }, [mdDown]);

  return <StyledDashboardContextWrapper ref={ref} {...innerProps}
                                        context={renderUpdates()}
                                        variant={mdDown ? 'temporary' : 'standard'}
                                        onToggle={handleContextToggle}
                                        open={open}
                                        size="medium">
    {innerProps.children}
  </StyledDashboardContextWrapper>
});

DashboardContextWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DashboardContextWrapper.defaultProps = {
  children: 'DashboardContextWrapper text',
};

export default DashboardContextWrapper;
