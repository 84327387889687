import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledEntitiesUploadWizard from 'components/organisms/Wizards/EntitiesUploadWizard/EntitiesUploadWizard.styles';
import EntitiesUploadWizardContent
  from 'components/organisms/WizardContent/EntitiesUploadWizardContent/EntitiesUploadWizardContent';
import EntitiesMatchWizardContent
  from 'components/organisms/WizardContent/EntitiesMatchWizardContent/EntitiesMatchWizardContent';
import utils from 'helpers/utils';
import EntitiesValidateWizardContent
  from 'components/organisms/WizardContent/EntitiesValidateWizardContent/EntitiesValidateWizardContent';
import EntitiesFinalizeWizardContent
  from 'components/organisms/WizardContent/EntitiesFinalizeWizardContent/EntitiesFinalizeWizardContent';
import constants from 'helpers/constants';
import Add from '@mui/icons-material/Add';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';

const EntitiesUploadWizard = React.forwardRef((props, ref) => {
  const {
    collection,
    file,
    isSource,
    onSubmit,
    ...innerProps
  } = useComponentProps(props, 'EntitiesUploadWizard');

  const snackbar = useSnackbar();
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const steps = useMemo(() => {
    return [
      {
        name: 'upload',
        shortLabel: 'Add file upload',
        resetNextSteps: true,
        enableStep: () => {
          return true;
        },
        Content: (props) => <EntitiesUploadWizardContent ref={props.ref}
                                                         className={props.className}
                                                         wizard={props.wizard}
                                                         step={props.step}
                                                         file={file}
                                                         isSource={isSource}
                                                         onComplete={props.onComplete}
                                                         onError={props.onError}
                                                         onDirty={props.onDirty}/>
      },
      {
        name: 'match',
        shortLabel: 'Match columns',
        title: 'Match the columns',
        subtitle: 'Just to make sure your data matches our structure',
        resetNextSteps: true,
        enableStep: () => {
          return true;
        },
        Content: (props) => <EntitiesMatchWizardContent ref={props.ref}
                                                        className={props.className}
                                                        wizard={props.wizard}
                                                        step={props.step}
                                                        onComplete={props.onComplete}
                                                        onError={props.onError}
                                                        onDirty={props.onDirty}/>
      },
      {
        name: 'validate',
        shortLabel: 'Validate',
        title: 'Validate',
        subtitle: 'Field validations failed for the cells below. Please correct them.',
        resetNextSteps: true,
        enableStep: (data) => {
          return !utils.isEmpty(data.entitiesUpload.errors);
        },
        Content: (props) => <EntitiesValidateWizardContent ref={props.ref}
                                                           className={props.className}
                                                           wizard={props.wizard}
                                                           step={props.step}
                                                           onComplete={props.onComplete}
                                                           onError={props.onError}
                                                           onDirty={props.onDirty}/>
      },
      {
        name: 'finalize',
        shortLabel: 'Finalize',
        title: 'Finalize',
        subtitle: 'All set!',
        resetNextSteps: true,
        enableStep: () => {
          return true;
        },
        Content: (props) => <EntitiesFinalizeWizardContent ref={props.ref}
                                                           className={props.className}
                                                           wizard={props.wizard}
                                                           step={props.step}
                                                           isSource={isSource}
                                                           onComplete={props.onComplete}
                                                           onError={props.onError}
                                                           onDirty={props.onDirty}/>
      },
    ];
  }, [file, isSource]);

  const initialData = useMemo(() => ({
    'entitiesUpload': {
      file: null,
      fileId: '',
      fields: [],
      replacement: [],
      errors: [],
      example: [],
      total: 0,
      name: '',
      params: {
        collectionId: collection?.collectionId,
        config: [],
        file: '',
        fileName: '',
        fileEnrichProfiles: false,
        fileOverwriteValues: false,
        fileStaticDataset: false
      },
      period: '1 minute',
      subType: null,
      type: constants.sources.types.fileUpload
    }
  }), [collection]);

  const handleSubmit = (data, actions, onSuccess, onError) => {
    return utils.asPromise(onSubmit)(data.entitiesUpload)
      .then(() => {
        onSuccess?.();
      })
      .catch(() => {
        onError?.()
        snackbar.show('Uploading entities failed', null, {color: 'error', autoHideDuration: constants.delay.error});
      });
  }

  return <StyledEntitiesUploadWizard ref={ref} {...innerProps}
                                     steps={steps}
                                     dataKey="entitiesUpload"
                                     initialData={initialData}
                                     onSubmit={handleSubmit}
                                     saveAction={{
                                       label: mdDown ? 'Add' : 'Add companies',
                                       icon: Add,
                                       iconPosition: 'start',
                                       ButtonProps: {
                                         color: 'success'
                                       }
                                     }} />
});

EntitiesUploadWizard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  collection: PropTypes.object,
  file: PropTypes.any,
  isSource: PropTypes.bool,
  onSubmit: PropTypes.func
};

EntitiesUploadWizard.defaultProps = {};

export default EntitiesUploadWizard;
