import React, {useMemo} from 'react'
import {useParams} from 'react-router-dom';
import ProfileProvider from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import constants from 'helpers/constants';
import PropTypes from 'prop-types';
import {
  useMergeCardDefinitions,
  useMergeFieldData,
  useMergeSectionDefinitions, useMergeView,
  useProviderView
} from 'helpers/hooks/utils';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import utils from 'helpers/utils';
import {useCommentDelete, useCommentGet} from 'services/comment/comment.hooks';
import {
  useCommentToggleTaskDone,
  useTaskGroups,
  useTaskPatch,
  useTaskProfileCallbacks,
  useTaskSections
} from 'services/comment/comment.utils';
import {useClientGet} from 'services/client/client.hooks';
import {useAuthClientId} from 'services/auth/auth.utils';

const TaskProfileProvider = (props) => {
  const params = useParams();
  const tableProvider = useTable();
  const { taskId } = {...utils.cleanObject({
    taskId: tableProvider?.selected()?.taskId
  }), ...params, ...props};

  const clientId = useAuthClientId();

  const dataKey = props.dataKey ?? 'commentId';
  const taskView = useProviderView('task', props.skipView);
  const view = useMergeView(taskView, props.view);

  const client = useClientGet({clientId}, {enabled: Boolean(!props.context && clientId >= 0)});
  const task = useCommentGet({commentId: taskId}, {enabled: Boolean(!props.data && +taskId > 0)});

  const groups = useTaskGroups(view);
  const sections = useTaskSections(view);

  // isLoading anything, is loading something definition related
  const isLoading = props?.isLoading || client.status?.isLoading || task.status?.isLoading;
  const isDefinitionsLoading = props?.isDefinitionsLoading;

  const taskProfileCallbacks = useTaskProfileCallbacks();

  const taskFieldData = useMemo(() => ({
    callbacks: taskProfileCallbacks
  }), [taskProfileCallbacks]);

  const fieldData = useMergeFieldData(taskFieldData, props.fieldData);
  const cardDefinitions = useMergeCardDefinitions(groups, props.options?.cardDefinitions);
  const sectionDefinitions = useMergeSectionDefinitions(sections, props.options?.sectionDefinitions);

  const options = useMemo(() => {
    const options = {
      cardState: {
        options: {
          name: 'task',
          type: constants.appState.type.local
        }
      }
    };

    return {
      ...(utils.mergeObjects(options, props.options)),
      cardDefinitions,
      sectionDefinitions
    }
  }, [props.options, cardDefinitions, sectionDefinitions]);

  const update = useTaskPatch();
  const deleteTask = useCommentDelete();
  const toggleDone = useCommentToggleTaskDone();
  const updaters = useMemo(() => {
    return {
      updateData: update,
      deleteData: (data) => {
        return deleteTask.mutation.mutateAsync(data);
      },
      toggleDone: toggleDone,
      ...props.updaters
    }
  }, [update, toggleDone, deleteTask.mutation, props.updaters]);

  return <ProfileProvider {...props}
                          view={view}
                          dataKey={dataKey}
                          data={props.data ?? task}
                          context={props.context ?? client}
                          fieldData={fieldData}
                          options={options}
                          updaters={updaters}
                          isDefinitionsLoading={isDefinitionsLoading}
                          isLoading={isLoading}>
    {props.children}
  </ProfileProvider>
};

TaskProfileProvider.propTypes = {
  view: PropTypes.object,
  dataKey: PropTypes.string,
  data: PropTypes.object,
  context: PropTypes.object,
  options: PropTypes.object,
  updaters: PropTypes.object,
  isLoading: PropTypes.bool
}

export default TaskProfileProvider;
