import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import BaseTableCell from 'components/molecules/TableCells/BaseTableCell/BaseTableCell';

const StyledFieldTableCell = styled(BaseTableCell)`
  gap: ${props => props.theme.layout('0.5sp')};
  
  .Input-readOnly {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
    * {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .TextField-multiline {
    .Input-readOnly {
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
      
      * {
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
  
  .FieldTableCell {
    &-feedback, &-info {
      margin-right: ${props => props.theme.layout('0.25sp')};
      align-self: center;
    }
    &-field {
      .ChipList { 
        height: 100%;
        &.ChipList-compact {
          align-items: center;
        }
      }
    }
    &-empty {
      width: 100%;
    }
    &-edit {
      display: none;
      align-self: center;
      justify-self: flex-end;
      margin-left: ${props => props.theme.layout('0.5sp')};
      color: ${props => props.theme.property('palette.text.disabled')};
    }
  }
  
  &:not(:has(.FieldTableCell-field)) {
    justify-content: flex-end;
  }

  &.FieldTableCell-canUpdate {
    .FieldTableCell-field:hover {
      cursor: pointer;
    }
    &:hover {
      .FieldTableCell-edit {
        display: block;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledFieldTableCell.propTypes = {
  theme: PropTypes.object
};

StyledFieldTableCell.defaultProps = {
}

export default StyledFieldTableCell;
