import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledApplicantForm = styled(Box)`
  > .H6 {
    width: 100%;
    text-align: center;
  }

  .loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: ${props => props.theme.layout('2sp')};

    width: 100%;
    text-align: center;
  }
  
  .Form {
    &-page {
      .title {
        width: 100%;
      }
    }

    &-section {
      width: 100%;
      display: flex;
      align-items: center;
      column-gap: ${props => props.theme.layout('1sp')};
    }

    &-fields {
      display: flex;
      flex-wrap: wrap;
      column-gap: ${props => props.theme.layout('1sp')};
      row-gap: ${props => props.theme.layout('2.5sp')};

      .FormControl-staticLabel {
        .MuiFormLabel-root {
          &.InputLabel, &.FormLabel {
            width: calc(100%);
            max-width: calc(100%);
            transform: scale(1);
            font-size: ${props => props.theme.fontSize('1.1f')};
          }
        }
      }
    }

    &-footer {
      margin-top: ${props => props.theme.layout('4sp')};
    }

    &-disclaimer {
      margin-top: ${props => props.theme.layout('2.5sp')};
    }
  }
  
  ${ComponentStyles}
`;

StyledApplicantForm.propTypes = {
  theme: PropTypes.object
};

StyledApplicantForm.defaultProps = {
}

export default StyledApplicantForm;
