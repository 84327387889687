import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectItem} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledDatabaseEntitiesTableBar
  from 'components/organisms/Bars/DatabaseEntitiesTableBar/DatabaseEntitiesTableBar.styles';
import {useSplitPath} from 'helpers/hooks/links';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import useMediaQuery from '@mui/material/useMediaQuery';
import Add from '@mui/icons-material/Add';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import SourceAddDialog from 'components/organisms/Dialogs/SourceAddDialog/SourceAddDialog';
import constants from 'helpers/constants';
import {useCollectionAdd} from 'services/collection/collection.hooks';
import {useCollectionPatch} from 'services/collection/collection.utils';

const DatabaseEntitiesTableBar = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DatabaseEntitiesTableBar');

  const tableProvider = useTable();
  const dialogControl = useDialogControl();

  const listState = useEffectItem(tableProvider.appliedListState());

  const [, pathPostfix] = useSplitPath('database');

  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const addCollection = useCollectionAdd();
  const patchCollection = useCollectionPatch();

  const columnAction = useMemo(() => ({
    auth: utils.createAuth({ attribute: 'database.entity.columns' }),
  }), []);

  const saveSearchAction = useMemo(() => ({
    onClick: (e) => {
      const handleSubmit = (source) => {
        const addSource = (collection) => {
          const data = {...source, sourceId: -1};
          const sources = (collection?.projectSources ?? []).concat(data);
          sources[sources.length - 1].sourceId = (collection?.projectSources ?? []).reduce((m, s) => +s.sourceId < m ? +s.sourceId : m, 0) - 1;

          const sourcesValue = utils.uniqueArray(sources, 'sourceId', true);

          return patchCollection(collection, {projectSources: sourcesValue});
        }

        if (!utils.isDefined(source.collection?.collection)) {
          return addCollection.mutation.mutateAsync({
            name: source.collection.label,
            visibility: constants.collection.visibility.client,
            projectSources: [{sourceId: 0, type: constants.sources.types.suggestions}]
          })
            .then(({response}) => {
              const newCollection = utils.camelcase(response.data?.data);
              return addSource(newCollection);
            });
        } else {
          return addSource(source.collection?.collection);
        }
      };

      dialogControl.show(<SourceAddDialog type={constants.data.lookup('sourceTypes', constants.sources.types.database)}
                                          filter={listState.query}
                                          onSubmit={handleSubmit}/>, true);

      e.preventDefault();
    },
    label: !mdDown ? 'Add as source' : null,
    tooltip: mdDown ? 'Add as source' : null,
    icon: Add,
    ActionButtonProps: {
      color: 'success'
    },
    ActionIconButtonProps: {
      color: 'success'
    }
  }), [listState.query, addCollection.mutation, patchCollection, mdDown, dialogControl]);

  const entitiesCount = utils.formatNumber(tableProvider.list?.meta?.resultsCount ?? 0);

  innerProps.title = innerProps.title ?? `${entitiesCount} compan${(tableProvider.list?.meta?.resultsCount ?? 0) === 1 ? 'y' : 'ies'}`;

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledDatabaseEntitiesTableBar ref={ref} {...innerProps}
                                         columnAction={columnAction}
                                         saveAction={saveSearchAction}
                                         hideColumn={Boolean(pathPostfix) || smDown}
                                         hideSearch={true}
                                         hideFilter={true}
                                         hideSave={false} />
});

DatabaseEntitiesTableBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DatabaseEntitiesTableBar.defaultProps = {
};

export default DatabaseEntitiesTableBar;
