import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Chip from '@mui/material/Chip';
import constants from 'helpers/constants';

const StyledChip = styled(Chip)`
  overflow: hidden;
  border-radius: ${props => props.theme.radius(1)};
  font-size: ${props => props.theme.fontSize(16)};
  
  &.Chip {
    .MuiChip-label {
      ${props => props.theme.font2Css('chipLabel')};
    }
    
    .Chip-badge {
      .MuiBadge-badge {
        height: ${props => props.theme.layout(6)};
        width: ${props => props.theme.layout(6)};
        min-width: ${props => props.theme.layout(6)};
        right: ${props => props.theme.layout(1)};
        top: ${props => props.theme.layout(1)};
      }
      
      .Icon {
        font-size: inherit;
      }
    }
  }

  &.Chip-fullWidth {
    width: 100%;  
  }
  
  &.Chip-size-large {
    font-size: ${props => props.theme.fontSize(13)};
    border-radius: ${props => props.theme.radius(2)};
    height: ${props => props.theme.layout(36)};

    .MuiChip-label {
      font-size: inherit;
      padding: 0 ${props => props.theme.layout('1.25sp')};
    }
    .MuiChip-icon, .MuiChip-avatar {
      font-size: ${props => props.theme.fontSize(16)};
      margin-left: ${props => props.theme.layout(8)};
      margin-right: ${props => props.theme.layout(-2)};
    }
    .MuiChip-deleteIcon {
      font-size: ${props => props.theme.fontSize(16)};
      margin-left: ${props => props.theme.layout(-2)};
      margin-right: ${props => props.theme.layout(8)};
    }
  }

  &.Chip-size-medium {
    font-size: ${props => props.theme.fontSize(13)};
    height: ${props => props.theme.layout(24)};
    .MuiChip-label {
      font-size: inherit;
      padding: 0 ${props => props.theme.layout(6)};
    }
    .MuiChip-icon, .MuiChip-avatar {
      font-size: ${props => props.theme.fontSize(16)};
      margin-right: ${props => props.theme.layout(-2)};
    }
    .MuiChip-deleteIcon {
      font-size: ${props => props.theme.fontSize(16)};
      margin-left: ${props => props.theme.layout(-2)};
    }
  }
  
  &.Chip-size-small {
    border-radius: ${props => props.theme.radius(0.5)};
    font-size: ${props => props.theme.fontSize(12)};
    height: ${props => props.theme.layout(18)};
    .MuiChip-label {
      font-size: inherit;
      line-height: ${props => props.theme.lineHeight('1f')};
      padding: 0 ${props => props.theme.layout(4)};
    }
    .MuiChip-icon, .MuiChip-avatar {
      font-size: ${props => props.theme.fontSize(12)};
      margin-right: ${props => props.theme.layout(-2)};
    } 
    .MuiChip-deleteIcon {
      font-size: ${props => props.theme.fontSize(12)};
      margin-left: ${props => props.theme.layout(-2)};
    }
  }

  &.Chip-outlined {
    ${props => props.theme.color2Css('Chip', null, props.color, {
      interactive: props.clickable,
      focusable: Boolean(props.onDelete),
      type: constants.colorTypes.outlined
    })};
    
    &.Chip-color-secondary {
      color: ${props => props.theme.property('palette.action.active')};

      .MuiChip-deleteIcon {
        &:not(${props => props.theme.state2Selector('MuiChip-deleteIcon', null, ['hover', 'focus', 'focusVisible', 'active', 'selected'], '')}) {
          color: ${props => props.theme.property('palette.action.active')};
        }
      }
    }
    
    ${props => props.theme.state2Selector('Chip', null, 'disabled')} {
      border-color: ${props => props.theme.property('palette.chip.defaultEnabledBorder')} !important;
      color: ${props => props.theme.state2Color(
        props.color, 'default', constants.colorTypes.outlined, constants.colorTargets.foreground)} !important;
    }

    &.Chip {
      border-color: ${props => props.theme.property('palette.chip.defaultEnabledBorder')};
    }
  }
  
  &.Chip-transparent {
    ${props => props.theme.color2Css('Chip', null, props.color, {
      interactive: props.clickable,
      focusable: Boolean(props.onDelete),
      type: constants.colorTypes.transparent
    })};

    .MuiChip-deleteIcon {
      color: inherit;
    }
  }

  &.Chip-filled {
    ${props => props.theme.color2Css('Chip', null, props.color, {
      interactive: props.clickable,
      focusable: Boolean(props.onDelete),
      type: constants.colorTypes.button
    })};
  }

  &.Chip-radius-round {
    border-radius: ${props => props.theme.radius(2)};

    &.Chip-size-small {
      border-radius: ${props => props.theme.radius(1)};
    }
  }
  
  ${ComponentStyles}
`;

StyledChip.propTypes = {
  theme: PropTypes.object
};

StyledChip.defaultProps = {
}

export default StyledChip;

