import React, {useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledGraphFrame from 'components/molecules/Frames/GraphFrame/GraphFrame.styles';

const injectStyle = `
  body {
    display: block;
    flex-direction: column;
    padding: 0 1rem;
    margin: 0;
    overflow: hidden;
    position: relative;
    width: 100vw;
    height: 100vh;
    max-height: calc(100vh - 1.5rem) !important;
    
    font-family: 'Roboto', 'Helvetica', 'Arial', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 1rem;
    
    :has(canvas) {
      display: flex;
    }
    
    > img, > h1, > h2, > h3, > h4, > h5, > h6, > span, > p {
      width: calc(100% - 2rem) !important;
      max-height: 100% !important;
    }
    
    > div:has(*.plotly) {
      height: 100% !important;
      width: 100% !important;
      
      > div, > div > div, > div > div > div {
        height: 100% !important;
        width: 100% !important;
      }
    }
    
    > canvas {
      width: unset !important;
      height: unset !important;
      margin: 1rem !important;
      overflow: hidden;
      width: calc(100% - 4rem) !important;
      max-height: 100% !important;
    }
    
    .modebar-container {
      right: 1rem !important;
    }
    .modebar-container > * > *:first-child {
      display: none !important;
    }
    .modebar-container > * > *:last-child {
      display: none !important;
    }
  }`;

const injectScript = `
  // X-Frame-Bypass navigation event handlers
  try {
    try {
      // setTimeout(() => {
      //   let svgEls = Array.from(document.querySelectorAll('.main-svg'));
      //  
      //   console.log('svgEls', svgEls);
      //   svgEls.forEach((svgEl) => {
      //     if (svgEl.getAttribute('width') > 0 || svgEl.getAttribute('height') > 0) {
      //       svgEl.setAttribute('viewBox', '0 0 ' + svgEl.getAttribute('width') + ' ' + svgEl.getAttribute('height'));
      //       svgEl.removeAttribute('height');
      //       svgEl.removeAttribute('width');
      //     }
      //   });
      // }, 500);
    } catch(err) {
      console.log('frame onLoad error', err);
    }
  } catch(err) {
    //ignore 
  }`;

const GraphFrame = React.forwardRef((props, ref) => {
  const {
    srcDoc,
    ...innerProps
  } = useComponentProps(props, 'GraphFrame');

  const innerRef = useRef(null);

  const srcDocMemo = useMemo(() => {
    return srcDoc ? srcDoc
      .replace(/<head([^>]*)>/i, `<head$1>
                  <base href="${window.location.href}">
                  <style type="text/css">${injectStyle}</style>
                  <script type="text/javascript">${injectScript}</script>
                `)
      .replace(/<body([^>]*)>/i, `<body$1>
                  <script type="text/javascript">${injectScript}</script>
                `): srcDoc;
  }, [srcDoc]);

  const graphFrame = useMemo(() => ({
    refs: {
      ref: innerRef
    },
    source: srcDocMemo
  }), [srcDocMemo]);

  useImperativeHandle(ref, () => graphFrame)

  const sandbox = useMemo(() => {
    return [
      'allow-scripts',
      'allow-forms'
    ].join(' ');
  }, []);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledGraphFrame ref={innerRef} {...innerProps} srcDoc={srcDocMemo} sandbox={sandbox}>
  </StyledGraphFrame>
});

GraphFrame.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  state: PropTypes.object,
  url: PropTypes.string,
  onLoading: PropTypes.func,
  onWaiting: PropTypes.func,
  onFailed: PropTypes.func,
  onSuccess: PropTypes.func
};

GraphFrame.defaultProps = {
};

export default GraphFrame;
