import React, {useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledMentionsInput from 'components/molecules/Mentions/MentionsInput/MentionsInput.styles';
import Mention from 'components/molecules/Mentions/Mention/Mention';
import DropdownPopper from 'components/molecules/Poppers/DropdownPopper/DropdownPopper';
import dom from 'helpers/dom';
import MenuItem from 'components/atoms/Menus/MenuItem/MenuItem';
import MenuList from 'components/atoms/Menus/MenuList/MenuList';

const MentionsInput = React.forwardRef((props, ref) => {
  const {
    mentions,
    rows,
    minRows,
    maxRows,
    multiline,
    ...innerProps
  } = useComponentProps(props, 'MentionsInput');

  const innerRef = useRef(null);
  const nodesRef = useRef(null);
  const suggestionsRef = useRef(null);
  const suggestionsMenuRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  const [internalState, setInternalState] = useState({
    minHeight: null,
    maxHeight: null
  });

  const mentionsMemo = useMemo(() => {
    return mentions.map((mention) => ({
      ...mention,
      Component: mention.Component ?? Mention,
      markup: `${mention.trigger}[${mention.trigger}__display__:__id__]`,
      displayTransform: (id, display) => {
        return `${mention.trigger}${display}`
      }
    }))
  }, [mentions]);

  const testText = useCallback((text) => {
    let canvas = innerRef.current.parentElement.querySelector('.text-test');
    if (!canvas) {
      canvas = document.createElement('span');
      canvas.className = 'text-test';
      innerRef.current.parentElement.appendChild(canvas);
    }

    if (canvas.innerHTML !== text) {
      canvas.innerHTML = text;
    }

    return {
      width: canvas.clientWidth,
      height: canvas.clientHeight
    }
  }, []);

  const scrollable = multiline && Boolean(rows || minRows || maxRows);
  useEffect(() => {
    if (scrollable) {
      const textStats = testText('X');
      const calculatedRowHeight = textStats.height;

      const cs = dom.getComputedStyle(innerRef.current);

      const padding = (cs?.paddingTop ? utils.toNumber(cs.paddingTop) : 0) + (cs?.paddingBottom ? utils.toNumber(cs.paddingBottom) : 0);
      if (rows) {
        setInternalState(utils.updater({
          minHeight: `${(calculatedRowHeight * rows) + padding}px`,
          maxHeight: `${(calculatedRowHeight * rows) + padding}px`
        }, true));
      } else {
        setInternalState(utils.updater({
          minHeight: minRows ? `${(calculatedRowHeight * minRows) + padding}px` : null,
          maxHeight: maxRows ? `${(calculatedRowHeight * maxRows) + padding}px` : null
        }, true));
      }
    }
  }, [scrollable, rows, minRows, maxRows, testText]);

  innerProps.style = utils.mergeObjects({
    input: {
      overflow: scrollable ? 'auto' : 'hidden',
      minHeight: internalState.minHeight,
      maxHeight: internalState.maxHeight
    },
    highlighter: {
      border: 'unset',
      minHeight: internalState.minHeight,
      maxHeight: internalState.maxHeight
    },
  }, innerProps.style);

  const selectedIdx = nodesRef.current?.findIndex((n) => n.props.focused);
  useEffect(() => {
    if (selectedIdx !== -1 && suggestionsMenuRef.current) {
      const el = Array.from(suggestionsMenuRef.current.children).find((child) => {
        return +child.dataset['suggestionIndex'] === selectedIdx;
      });
      dom.scrollIntoView(el);
    }
  }, [selectedIdx]);

  const renderSuggestions = (children) => {
    if (innerRef) {
      const nodes = children.props.children;
      const query = nodes[0]?.props?.query ?? '';

      const findCaret = (el) => {
        if (el.nodeType === Node.TEXT_NODE && el.textContent.endsWith(`@${query}`)) {
          return el;
        } else {
          let found;
          Array.from(el.childNodes ?? []).some((el) => {
            found = findCaret(el);
            return found;
          })
          return found;
        }
      }
      const anchorEl = findCaret(innerRef.current.parentElement.children[0]);

      if (anchorEl) {
        let bBox;

        const offset = {x: 0, y: 0};
        const text = anchorEl.textContent;
        const atIndex = text.lastIndexOf(`@${query}`);
        try {
          const textStats = testText('@');
          const range = document.createRange();
          range.setStart(anchorEl, atIndex);
          range.setEnd(anchorEl, atIndex + 1);
          bBox = Array.from(range.getClientRects())
            .filter((cr) => Math.round(cr.width) >= Math.round(textStats.width))
            .sort((a, b) => a.width - b.width)[0];
        } catch {
          /* SQUASH */
        }

        if (bBox) {
          return <DropdownPopper ref={suggestionsRef}
                                 anchorEl={{
                                   getBoundingClientRect: () => {
                                     return new DOMRect(bBox?.left + offset.x,
                                       bBox?.top + offset.y, bBox.width, bBox.height);
                                   }
                                 }}
                                 open={true}
                                 autoFocus={false}
                                 ContextPopperProps={{
                                   size: 'small',
                                   density: 'densest',
                                   placement: 'top-start'
                                 }}>
            <MenuList ref={suggestionsMenuRef}
                      density="dense">
              {nodes?.map((node, idx) => {
                return <MenuItem key={idx}
                                 data-suggestion-index={idx}
                                 onClick={node.props.onClick}
                                 onMouseEnter={node.props.onMouseEnter}
                                 selected={node.props.focused}>
                  {node.props.children?.[0] ? utils.cloneElement(node.props.children?.[0]) :
                    node.props.suggestion.label}
                </MenuItem>
              })}
            </MenuList>
          </DropdownPopper>
        }
      }
    }
  }

  useEffect(() => {
    return utils.observeEvent(innerRef.current, 'click', () => {
      innerRef.current.parentElement.children[0].scrollTo({
        top: innerRef.current.parentElement.children[1].scrollTop,
        left: innerRef.current.parentElement.children[1].scrollLeft,
        behavior: 'instant'
      });
    });
  }, []);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledMentionsInput inputRef={innerRef} {...innerProps}
                              customSuggestionsContainer={renderSuggestions}
                              isOpened={false}
                              singleLine={!multiline}>
    {mentionsMemo.map((mention, idx) => {
      return <mention.Component key={idx}
                                regex={mention.regex}
                                markup={mention.markup}
                                displayTransform={mention.displayTransform}
                                trigger={mention.trigger}
                                renderSuggestion={mention.renderSuggestion}
                                data={mention.data}
                                appendSpaceOnAdd={true}
                                isLoading={false}
                                {...mention.MentionProps}/>
    })}
  </StyledMentionsInput>
});

MentionsInput.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.string,
  multiline: PropTypes.bool
};

MentionsInput.defaultProps = {
};

export default MentionsInput;
