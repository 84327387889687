import React, {useCallback, useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledInlineForm from 'components/organisms/Forms/InlineForm/InlineForm.styles';
import {withMemo} from 'helpers/wrapper';
import constants from 'helpers/constants';

const InlineForm = withMemo(React.forwardRef((props, ref) => {
  const {
    fields,
    onChange,
    onError,
    onChangeDirect,
    onValidating,
    onBusy,
    onReset,
    onSubmit,
    ...innerProps
  } = useComponentProps(props, 'InlineForm');

  const changedRef = useRef({});
  const editingRef = useRef(false);
  const processingRef = useRef(false);
  const [internalState, setInternalState] = useState({
    error: {},
    success: {}
  });

  const innerRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  const debouncedChange = useMemo(() => {
    return fields.reduce((o, field) => {
      o[field.name] = utils.debounce((apply, fields) => {
        if (!processingRef.current) {
          apply(innerRef.current?.formik?.values, innerRef.current?.formik?.errors, fields, true);
        }
      }, field.debounce === true ? constants.debounce.input : (!field.debounce ? 0 : field.debounce));

      return o;
    }, {});
  }, [fields]);

  const onBusyEvent = useEffectEvent(onBusy);
  const onChangeEvent = useEffectEvent(onChange);
  const onErrorEvent = useEffectEvent(onError);
  const applyChanges = useCallback((values, errors, fields, isBlur = false) => {
    const changedFields = Object.keys(changedRef.current)
      .filter((k) => {
        return errors?.[k] || (changedRef.current[k]?.changed && utils.compare(changedRef.current[k]?.value, (values[k] ?? true)));
      })
      .map((k) => k);

    if (changedFields.length > 0) {
      let savedCount = 0;
      changedFields.forEach((name) => {
        const field = fields?.find((field) => field.name === name);
        if (field) {
          if (!(field.readOnly || field.disabled)) {
            if (!field.debounce || isBlur) {
              const value = utils.fieldValue2ConvertedValue(field, values?.[name]);

              if ((field.onChange || onChangeEvent) && !errors?.[name]) {
                const handleSuccess = (msg) => {
                  savedCount += 1;
                  if (savedCount >= changedFields.length) {
                    onBusyEvent?.(false);
                  }

                  setInternalState((current) => {
                    return utils.updater({
                      ...current,
                      success: {
                        ...current.success,
                        [field.name]: msg ?? 'Saved',
                        ...(field.parent ? {
                          [field.parent.name]: msg ?? 'Saved'
                        } : {})
                      },
                      error: {
                        ...current.error,
                        [field.name]: null,
                        ...(field.parent ? {
                          [field.parent.name]: null
                        } : {})
                      }
                    })(current);
                  })
                }

                const handleError = (err) => {
                  setInternalState((current) => {
                    return utils.updater({
                      ...current,
                      error: {
                        ...current.error,
                        [field.name]: err ?? 'Saving failed',
                        ...(field.parent ? {
                          [field.parent.name]: err ?? 'Saving failed'
                        } : {})
                      },
                      success: {
                        ...current.success,
                        [field.name]: null,
                        ...(field.parent ? {
                          [field.parent.name]: null
                        } : {})
                      }
                    })(current)
                  })
                }

                field.onChange?.(
                  value,
                  handleSuccess,
                  handleError
                );

                onChangeEvent?.(
                  field,
                  value,
                  handleSuccess,
                  handleError
                );
              } else if ((field.onError || onErrorEvent) && errors?.[name]) {
                field.onError?.(value);
                onErrorEvent?.(field, value);
              }

              changedRef.current[name] = null;
            } else {
              debouncedChange[name](applyChanges, fields);
            }
          } else {
            changedRef.current[name] = null;
          }
        }
      });
    }
  }, [onChangeEvent, onErrorEvent, onBusyEvent, debouncedChange]);

  const handleBlur = () => {
    editingRef.current = false;
  };

  const handleSubmit = (values, actions, fields) => {
    editingRef.current = false;
    if (onSubmit) {
      onSubmit?.(values, actions, fields);
    } else {
      actions.setSubmitting(false);
    }
  }

  const handleReset = () => {
    setInternalState(utils.updater({
      success: {},
      error: {}
    }, true));
  }

  const handleChange = (e) => {
    changedRef.current = {...changedRef.current, [e?.target?.name]: {
      value: (e?.target?.value ?? true),
      changed: true
    }};
  };

  const handleChangeDirect = (e) => {
    const field = fields?.find((field) => field.name === e?.target?.name);

    if (field) {
      field.onChangeDirect?.(e);
      onChangeDirect?.(e);
      onBusy?.(true);
      editingRef.current = true;

      setInternalState((current) => {
        return utils.updater({
          ...current,
          success: {
            ...current.success,
            [field.name]: null,
            ...(field.parent ? {
              [field.parent.name]: null
            } : {})
          },
          error: {
            ...current.error,
            [field.name]: null,
            ...(field.parent ? {
              [field.parent.name]: null
            } : {})
          }
        })(current);
      });
    }
  };

  const handleValidating = (validating, isDirty, hasErrors, formik, fields) => {
    onValidating?.(validating, isDirty, hasErrors, formik, fields);

    onBusy?.((current) => isDirty && (current || Object.keys(internalState.error)
      .filter((k) => internalState.error[k]).length > 0 || hasErrors));

    if (!validating && isDirty) {
      processingRef.current = false;
      applyChanges(formik?.values, formik?.errors, fields, !editingRef.current);
    } else {
      processingRef.current = true; // suspend debounce
    }
  };

  const pages = useMemo(() => {
    return {
      0: {
        fields: fields?.reduce((o, field) => {
          o[field.name ?? field.title] = {
            ...field,
            FormFieldProps: {
              error: internalState.error[field.name],
              success: internalState.success[field.name],
              ...field.FormFieldProps
            }
          }
          return o;
        }, {})
      }
    }
  }, [fields, internalState.error, internalState.success]);

  innerProps.onReset = handleReset;
  innerProps.onSubmit = handleSubmit;
  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledInlineForm ref={innerRef} {...innerProps}
                           pages={pages}
                           onBlur={handleBlur}
                           onChange={handleChange}
                           onChangeDirect={handleChangeDirect}
                           onValidating={handleValidating}
                           showButtons={false} />
}));

InlineForm.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  fields: PropTypes.array,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  onChangeDirect: PropTypes.func,
  onValidating: PropTypes.func,
  onBusy: PropTypes.func,
  onReset: PropTypes.func,
  onSubmit: PropTypes.func
};

InlineForm.defaultProps = {
  autoFocus: true
};

export default InlineForm;
