import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import CollectionCard from 'components/organisms/Cards/CollectionCard/CollectionCard';
import {useCollectionList} from 'services/collection/collection.hooks';
import Section from 'components/organisms/Sections/Section/Section';
import StyledRecentCollectionsDashboardCard
  from 'components/organisms/Cards/RecentCollectionsDashboardCard/RecentCollectionsDashboardCard.styles';
import utils from 'helpers/utils';

const RecentCollectionsDashboardCard = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'RecentCollectionsDashboardCard', {
    children: ['section']
  });

  const list = useCollectionList({
    sort: [{id: 'last_updated_at', desc: true}],
    pageSize: 10
  });
  const isLoading = list?.status?.isLoading || !utils.isDefined(list?.data)

  return <StyledRecentCollectionsDashboardCard ref={ref} {...innerProps}>
    <Section className="RecentCollectionsDashboardCard-section"
             Loader={<CollectionCard isLoading={true}/>}
             loaderCount={8}
             isLoading={isLoading}
             gap={16}>
      {list?.data?.map((collection, index) => {
        return <CollectionCard key={index} collection={collection}/>;
      })}
    </Section>
  </StyledRecentCollectionsDashboardCard>
});

RecentCollectionsDashboardCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
};

RecentCollectionsDashboardCard.defaultProps = {
  title: 'You\'ve recently worked on',
  density: 'densest',
  variant: 'small',
  elevation: 0
};

export default RecentCollectionsDashboardCard;
