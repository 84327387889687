import utils from 'helpers/utils';
import {useServiceStatus} from 'stores/hooks/service';
import {useServiceApiMutation, useServiceApiQuery} from 'stores/hooks/query';
import constants from 'helpers/constants';

const path = utils.servicePath(import.meta.url);

export function usePersonStatus () {
  return useServiceStatus(path);
}

export function usePersonList ({search, filter, sort, page, pageSize, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list', {search, filter, sort, page, pageSize, ...rest}, queryOptions);
}

export function usePersonAdd (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

export function usePersonUpdate (mutationOptions = {}) {
  return useServiceApiMutation(path, 'patch', {}, mutationOptions);
}

export function usePersonDelete (mutationOptions = {}) {
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

export function usePersonGet ({personId}, queryOptions = {}) {
  return useServiceApiQuery(path, 'get', {personId}, queryOptions);
}

export function usePersonImgAdd (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    overridePath: 'persons/:personId/uploadImg',
    overrideDataType: constants.dataTypes.other,
    ...mutationOptions
  });
}

export function usePersonEnrich (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    overridePath: 'persons/:personId/enrich',
    ...mutationOptions
  });
}
