import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import DealflowTooltip from 'components/molecules/Tooltips/DealflowTooltip/DealflowTooltip';
import BarChart from 'components/organisms/Charts/BarChart/BarChart';
import Legend from 'components/molecules/Charts/Legend/Legend';
import Box from 'components/atoms/Layout/Box/Box';
import StyledDealflowMonthlyBarChart from 'components/organisms/Charts/DealflowMonthlyBarChart/DealflowMonthlyBarChart.styles';
import {useAuthClient} from 'components/organisms/Providers/AuthProvider/AuthProvider';

const DealflowMonthlyBarChart = React.forwardRef((props, ref) => {
  const {
    dealflow,
    showLegend,
    isLoading,
    visibility,
    onVisibilityChange,
    onClick,
    BarChartProps,
    ...innerProps
  } = useComponentProps(props, 'DealflowMonthlyBarChart', {
    children: ['chart', 'legend']
  });

  const [internalState, setInternalState] = useState({});

  const client = useAuthClient();
  const groups = client?.dealflowGroups;

  const [legend, rows] = useMemo(() => {
    let legend = [];
    if (dealflow && groups) {
      legend = groups
        .filter((g) => !g.statuses.find((status) => +status.statusId === 0))
        .filter((g) => dealflow.find((df) => +df.groupId === +g.groupId))
        .map((g) => {
          return {
            id: g.groupId,
            position: g.position,
            label: g.label,
            color: utils.deprecatedColor(g.color),
            active: visibility?.[g.groupId] ?? true,
            meta: g,
          }
        });
    }

    const rows = Math.ceil(legend.length / 6);

    return [legend, rows];
  }, [groups, dealflow, visibility]);

  const dealflowData = useMemo(() => {
    if (dealflow && groups) {
      return {
        dataKey: 'name',
        layout: 'vertical',
        bars: groups
          .filter((g) => !g.statuses.find((status) => +status.statusId === 0))
          .map((g) => {
            return {
              id: g.groupId,
              position: g.position,
              dataKey: g.groupId,
              name: g.groupId,
              label: g.label,
              color: utils.deprecatedColor(g.color)
            }
          })
          .filter((g) => {
            return Boolean(legend.find((l) => l.active && +l.id === +g.id))
          }),
        data: dealflow
          .sort((a, b) => (new Date(a.month)).getTime() - (new Date(b.month)).getTime())
          .reduce((a, df, idx) => {
            const group = groups.find((g) => +g.groupId === +df.groupId);

            let item = a.find((i) => i.month === df.month);
            if (!item) {
              item = {
                month: df.month,
                name: utils.dayjs(new Date(df.month)).format('MMM'),
                position: idx
              };
              a.push(item);
            }
            item[df.groupId] = df.count;
            item[`${df.groupId}-meta`] = {
              ...group,
              members: df.leaders.map((l) => ({
                ...utils.camelcase(l.leader),
                count: l.count
              })).sort((a, b) => {
                if (!utils.isDefined(a.userId) && !utils.isDefined(b.userId)) {
                  return 0;
                } else if (!utils.isDefined(a.userId)) {
                  return -1
                } else if (!utils.isDefined(b.userId)) {
                  return 1
                } else {
                  return a.count - b.count;
                }
              })
            }
            return a;
          }, []),
        TooltipComponent: DealflowTooltip
      };
    }
  }, [dealflow, groups, legend]);

  const handleLegendEnter = (e, item) => {
    if (item.active) {
      setInternalState(utils.updater({hoveredId: item.id}, true));
    }
  }

  const handleLegendLeave = () => {
    setInternalState(utils.updater({hoveredId: null}, true));
  }

  const handleLegendClick = (e, item) => {
    const newVisibility = {
      ...visibility,
      [item.id]: !(visibility?.[item.id] ?? true)
    }

    if (!legend.find((l) => newVisibility[l.id] ?? true)) {
      onVisibilityChange?.({});
    } else {
      onVisibilityChange?.(newVisibility);
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledDealflowMonthlyBarChart ref={ref} {...innerProps}>
    <Box className="DealflowMonthlyBarChart-chart">
      <BarChart {...dealflowData}
                showGrid
                showTooltip
                showCursor
                isLoading={isLoading}
                radius={4}
                barSize={24}
                layout="horizontal"
                hoveredId={internalState.hoveredId}
                onClick={onClick}
                TooltipProps={{
                  variant: 'members',
                  placement: 'right'
                }}
                margin={{
                  top: 0, right: 0, bottom: 4, left: -22
                }}
                {...BarChartProps}/>
    </Box>
    {showLegend ? <Legend className="DealflowMonthlyBarChart-legend"
                          onClick={onVisibilityChange ? handleLegendClick : null}
                          onMouseEnter={handleLegendEnter}
                          onMouseLeave={handleLegendLeave}
                          legend={legend}
                          isLoading={isLoading}
                          rows={rows}/> : null}
  </StyledDealflowMonthlyBarChart>
});

DealflowMonthlyBarChart.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  dealflow: PropTypes.array,
  showLegend: PropTypes.bool,
  isLoading: PropTypes.bool,
  visibility: PropTypes.object,
  onVisibilityChange: PropTypes.func,
  onClick: PropTypes.func,
  BarChartProps: PropTypes.object
};

DealflowMonthlyBarChart.defaultProps = {
};

export default DealflowMonthlyBarChart;
