import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledSourceCard from 'components/organisms/Cards/SourceCard/SourceCard.styles';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Typography, {H6} from 'components/atoms/Text/Typography/Typography';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import Add from '@mui/icons-material/Add';
import constants from 'helpers/constants';
import Search from '@mui/icons-material/Search';
import Cached from '@mui/icons-material/Cached';
import FilterList from '@mui/icons-material/FilterList';
import UploadFile from '@mui/icons-material/UploadFile';
import {default as LinkIcon} from '@mui/icons-material/Link';
import Link from 'components/atoms/Links/Link/Link';
import Badge from 'components/atoms/Badges/Badge/Badge';
import searchUtils from 'helpers/search';
import {DatabaseOutlined} from 'assets/icons';
import SourceStatsPieChart from 'components/organisms/Charts/SourceStatsPieChart/SourceStatsPieChart';
import Img from 'components/atoms/Images/Img/Img';
import ListAlt from '@mui/icons-material/ListAlt';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import {FolderOutlined} from '@mui/icons-material';
import Chip from 'components/atoms/Chips/Chip/Chip';
import ChipList from 'components/atoms/Chips/ChipList/ChipList';
import ActionChip from 'components/molecules/Chips/ActionChip/ActionChip';

const SourceCard = React.forwardRef((props, ref) => {
  const {
    source,
    title,
    description,
    icon,
    img,
    variant,
    onClick,
    IconProps,
    ImgProps,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'SourceCard', {
    variable: ['type']
  });

  const renderDetails = () => {
    const failed = ['failed', 'inactive'].includes(source?.status);

    if (variant === 'inset') {
      return <Box className="SourceCard-details">
        {source?.params?.fileName ? <Box className="SourceCard-detail">
          <Icon className="SourceCard-detail-icon"
                isLoading={isLoading}
                size="smaller" icon={UploadFile}/>
          <Typography className="SourceCard-detail-value"
                      showTooltip={true} isLoading={isLoading}
                      variant="caption">{source?.params?.fileName}</Typography>
        </Box> : (source?.params?.query ? <Box className="SourceCard-detail">
          <Icon className="SourceCard-detail-icon"
                isLoading={isLoading}
                size="smaller" icon={Search}/>
          <Typography className="SourceCard-detail-value"
                      showTooltip={true} isLoading={isLoading}
                      variant="caption">{source?.params?.query}</Typography>
        </Box> : (source?.params?.email ? <Box className="SourceCard-detail">
          <Icon className="SourceCard-detail-icon"
                isLoading={isLoading}
                size="smaller" icon={ForwardToInboxIcon}/>
          <Typography className="SourceCard-detail-value"
                      showTooltip={true} isLoading={isLoading}
                      variant="caption">
            <Link showTooltip={true}
                  href={`mailto:${source?.params?.email}`}>{source?.params?.email}</Link></Typography>
        </Box> : (source?.params?.link ? <Box className="SourceCard-detail">
          <Icon className="SourceCard-detail-icon"
                isLoading={isLoading}
                size="smaller" icon={LinkIcon}/>
          <Typography className="SourceCard-detail-value"
                      showTooltip={false} isLoading={isLoading}
                      variant="caption">
            <Link showTooltip={true}
                  href={utils.cleanExternalLink(source?.params?.link)}
                  target="_blank">{utils.displayExternalLink(source?.params?.link)}</Link></Typography>
        </Box> : ((constants.sources.types.collection === source?.type) ?
          <Box className="SourceCard-detail">
            <Icon className="SourceCard-detail-icon"
                  isLoading={isLoading}
                  size="smaller" icon={FolderOutlined} color="text.primary"/>
            <Typography className="SourceCard-detail-value"
                        showTooltip={true} isLoading={isLoading}
                        variant="caption">Collection(s)</Typography>
          </Box> : ((constants.sources.types.curatedList === source?.type) ?
            <Box className="SourceCard-detail">
              <Icon className="SourceCard-detail-icon"
                    isLoading={isLoading}
                    size="smaller" icon={ListAlt} color="text.primary"/>
              <Typography className="SourceCard-detail-value"
                          showTooltip={true} isLoading={isLoading}
                          variant="caption">List</Typography>
            </Box> : <Box className="SourceCard-detail">
              <Icon className="SourceCard-detail-icon"
                    isLoading={isLoading}
                    size="smaller" icon={DatabaseOutlined} color="text.primary"/>
              <Typography className="SourceCard-detail-value"
                          showTooltip={true} isLoading={isLoading}
                          variant="caption">{(constants.sources.types.database === source?.type) ? 'Database' : 'Suggestions'}</Typography>
            </Box>)))))}
        <Box className="SourceCard-detail">
          <Icon className={`SourceCard-detail-icon`}
                isLoading={isLoading}
                size="smaller" icon={Cached}/>
          <Typography className={`SourceCard-detail-value ${failed ? 'error' : ''}`}
                      isLoading={isLoading}
                      variant="caption">{source?.lastRun ? utils.timeAgo(new Date(source?.lastRun)) : (
                        failed ? 'Failed' : 'Processing...'
                      )}</Typography>
        </Box>
        <Box className="SourceCard-detail">
          <Icon className="SourceCard-detail-icon"
                isLoading={isLoading}
                size="smaller" icon={FilterList}/>
          <Typography className="SourceCard-detail-value"
                      isLoading={isLoading}
                      variant="caption">
            {source?.filterType === constants.sources.filterTypes.override ? 'Override filters' : source?.filter ? 'Extended filters' : 'No custom filters'}
          </Typography>
          {(source?.filter || source?.filterType === constants.sources.filterTypes.override) ?
            <Badge className="SourceCard-detail-badge"
                   variant="inline"
                   size="smaller"
                   isLoading={isLoading}
                   badgeContent={searchUtils.queryFilterCount(source?.filter) || '0'}/> : null}
        </Box>
      </Box>
    } else if (variant === 'suggested') {
      const renderLastResults = () => {
        if (source?.entityStats?.companiesRecent?.length > 0) {
          return <ChipList variant="compact"
                           drawFromStart={true}>
            {source?.entityStats.companiesRecent.map((entity, idx) => {
              return <ActionChip key={idx}
                                 size="small"
                                 variant="outlined"
                                 isLoading={isLoading}
                                 color={'primary'}
                                 fullWidth={true}
                                 action={{
                                   label: entity.name,
                                   tooltip: entity.name,
                                   navigation: {
                                     to: `/database/${entity.entityId}?custom=entity:${entity.entityId}`,
                                   }
                                 }}/>
            })}
          </ChipList>
        } else {
          return <React.Fragment>Processing...</React.Fragment>
        }
      };

      const resultsText = failed ? 'Failed' : (
        (source?.status === 'finished') ? renderLastResults() : 'Processing...'
      );

      if (source?.type === constants.sources.types.fileUpload) {
        return <Box className="SourceCard-details">
          <Box className="SourceCard-detail">
            <Typography className="SourceCard-detail-label"
                        isLoading={isLoading}
                        variant="body2">Last update</Typography>
            <Typography className="SourceCard-detail-value"
                        isLoading={isLoading}
                        variant="body2">{source?.lastRun ? utils.timeAgo(new Date(source?.lastRun)) : 'Never'}</Typography>
          </Box>
          <Box className="SourceCard-detail">
            <Typography className="SourceCard-detail-label"
                        isLoading={isLoading}
                        variant="body2">Last results</Typography>
            <Typography className="SourceCard-detail-value"
                        isLoading={isLoading}
                        color={failed ? 'error' : null}
                        variant="body2">{resultsText}</Typography>
          </Box>
        </Box>
      } else {
        return <Box className="SourceCard-details">
          <Box className="SourceCard-detail">
            <Typography className="SourceCard-detail-label"
                        isLoading={isLoading}
                        variant="body2">Last update</Typography>
            <Typography className="SourceCard-detail-value"
                        isLoading={isLoading}
                        variant="body2">{source?.lastRun ? utils.timeAgo(new Date(source?.lastRun)) : 'Never'}</Typography>
          </Box>
          <Box className="SourceCard-detail">
            <Typography className="SourceCard-detail-label"
                        isLoading={isLoading}
                        variant="body2">Last results</Typography>
            <Typography className="SourceCard-detail-value"
                        isLoading={isLoading}
                        color={failed ? 'error' : null}
                        variant="body2">{resultsText}</Typography>
          </Box>
        </Box>
      }
    } else if (variant === 'wizard') {
      return <Box className="SourceCard-details">
        {source?.query ? <Box className="SourceCard-detail">
          <Typography className="SourceCard-detail-value"
                      isLoading={isLoading}
                      variant="body2">"{source?.query}"</Typography>
        </Box> : (source?.link ? <Box className="SourceCard-detail">
          <Typography className="SourceCard-detail-value"
                      isLoading={isLoading}
                      variant="body2">
            <Link showTooltip={true}
                  onClick={(e) => {e.stopPropagation()}}
                  href={utils.cleanExternalLink(source?.link)}
                  target="_blank">{utils.displayExternalLink(source?.link)}</Link>
          </Typography>
        </Box> : ((source?.keywords?.length > 0) ? <Box className="SourceCard-detail">
          <Typography className="SourceCard-detail-value"
                      isLoading={isLoading}
                      variant="body2">
            <ChipList variant="compact"
                      drawFromStart={true}>
              {source?.keywords?.map((kw, idx) => {
                return <Chip key={idx}
                             size="small"
                             label={kw}
                             variant="outlined"
                             isLoading={isLoading}
                             color="secondary"/>
              })}
            </ChipList>
          </Typography>
        </Box> : <Box className="SourceCard-detail">
          <Typography className="SourceCard-detail-value"
                      isLoading={isLoading}
                      variant="body2">Not detail info</Typography>
        </Box>))}
      </Box>
    }
  }

  const renderStats = () => {
    return <Box className="SourceCard-stats">
      <SourceStatsPieChart isLoading={isLoading} source={source} />
    </Box>
  }

  const renderAction = () => {
    if (variant === 'standard') {
      return <Box className="SourceCard-icon">
        <Icon size="smaller" icon={ChevronRight} />
      </Box>
    } else if (variant === 'suggested') {
      return <Box className="SourceCard-button">
        <ActionButton variant="text"
                      size="small"
                      action={{
                        label: 'Use source',
                        icon: Add,
                        onClick,
                        ButtonProps: {
                          isLoading
                        }
                      }} />
      </Box>
    } else if (variant === 'wizard') {
      return <Box className="SourceCard-button">
        <ActionButton variant="text"
                      size="small"
                      action={{
                        label: 'Create source',
                        icon: Add,
                        onClick,
                        ButtonProps: {
                          isLoading
                        }
                      }} />
      </Box>
    }
  };

  const renderTitle = () => {
    return title ?? source?.name;
  }

  innerProps.className = utils.flattenClassName(innerProps.className, {
    type: source?.type
  });

  return <StyledSourceCard ref={ref} {...innerProps} onClick={onClick}>
    <Box className="SourceCard-content">
      {icon ? <Box className="SourceCard-logo">
        {utils.isReactElement(icon) ? icon : <Icon icon={icon} isLoading={isLoading} {...IconProps}/>}
      </Box> : null}
      {(!icon && img) ? <Box className="SourceCard-logo">
        {utils.isReactElement(img) ? img : <Img src={img} alt="logo" isLoading={isLoading} {...ImgProps}/>}
      </Box> : null}
      <Box className="SourceCard-info">
        <H6 className="SourceCard-title" showTooltip={true}
            min={8} max={20}
            isLoading={isLoading}>{renderTitle()}</H6>
        {variant !== 'standard' ? renderDetails() : <Box className="SourceCard-description">
          <Typography isLoading={isLoading} variant="body2">{description}</Typography>
        </Box>}
      </Box>
      {variant !== 'inset' ? renderAction() : null}
    </Box>
    {variant === 'inset' ? renderStats() : null}
  </StyledSourceCard>
});

SourceCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  source: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.any,
  img: PropTypes.any,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  IconProps: PropTypes.object,
  ImgProps: PropTypes.object,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['standard', 'suggested', 'wizard', 'inset']), PropTypes.string])
};

SourceCard.defaultProps = {};

export default SourceCard;
