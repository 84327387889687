import utils from 'helpers/utils';
import logger from 'helpers/logger';
import ServiceStore from 'stores/service.store';
import constants from 'helpers/constants';

const path = utils.servicePath(import.meta.url);

export class ClientTeamService extends ServiceStore {
  constructor (app, callbacks) {
    logger.trace(`Starting module at: services/${path}`);

    super(path, app, {
      refreshEnabled: false,
      invalidateParent: true,
      watch: ['user'],
      match: (cache, entity, id) => {
        return entity === 'user' &&
          Boolean(cache?.data?.members?.find((m) => +m.userId === +id));
      },
      process: (data, meta) => {
        const processTeam = (data) => {
          const raw = utils.camelcaseEx(data);

          raw.teamId = raw.teamId ?? raw.clientId;

          return raw;
        }

        if (meta?.context?.$store?.dataType !== constants.dataTypes.other) {
          if (utils.isArray(data)) {
            data = data.map(processTeam);
          } else {
            data = processTeam(data);
          }
        } else {
          return data;
        }

        return data;
      }
    }, callbacks);
  }
}
