import React, {useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledColumnsDialog from 'components/organisms/Dialogs/ColumnsDialog/ColumnsDialog.styles';
import constants from 'helpers/constants';
import DialogContent from 'components/atoms/Dialogs/DialogContent/DialogContent';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import DialogFooter from 'components/molecules/Dialogs/DialogFooter/DialogFooter';
import Button from 'components/atoms/Buttons/Button/Button';
import DialogHeader from 'components/molecules/Dialogs/DialogHeader/DialogHeader';
import {Span} from 'components/atoms/Text/Typography/Typography';
import utils from 'helpers/utils';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Save from '@mui/icons-material/Save';
import Close from '@mui/icons-material/Close';

const ColumnsDialog = React.forwardRef((props, ref) => {
  const {
    columns,
    onChange,
    onSubmit,
    ...innerProps
  } = useComponentProps(props, 'ColumnsDialog');

  const innerRef = useRef(null);
  const formRef = useRef(null);

  const [error, setError] = useState(null);
  const [dirty, setDirty] = useState(null);
  const [validation, setValidation] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  useImperativeHandle(ref, () => innerRef.current);

  const fields = useMemo(() => {
    const options = columns.map((c) => ({column: c, label: c.label, value: c.id}));
    return [{
      name: 'columns',
      type: constants.formFieldTypes.list,
      validation: constants.formFieldValidationTypes.list,
      initial: options.filter((option) => option.column.visible),
      FormFieldProps: {
        hiddenLabel: true,
        multiple: true,
        ListProps: {
          catchFocus: false
        }
      },
      options
    }]
  }, [columns]);

  const handleChange = (field, value) => {
    onChange?.(field, value);
    setError(null);
    setValidation(null);
  };

  const handleSubmit = (values, actions) => {
    setSubmitting(true);

    utils.asPromise(onSubmit)(values['columns'].map((option) => option.column))
      .catch(() => {
        setError('Saving columns failed');
      })
      .finally(() => {
        actions.setSubmitting(false);
        setSubmitting(false);
      });
  };

  const handleValidating = (isValidating, isDirty, hasErrors) => {
    setDirty(isDirty);
    if (hasErrors) {
      setValidation('Please check if all fields have the correct values');
    } else {
      setValidation(null);
    }
  }

  const handleSubmitClick = () => {
    formRef.current?.submit();
  };

  const handleCancel = (e) => {
    innerRef.current?.close?.(e);
  }

  const handleClose = (e, reason) => {
    if ((!submitting && !error && !dirty) || ['escapeKeyDown', 'closeButtonClick', 'cancelButtonClick'].includes(reason)) {
      innerProps.onClose?.(e, reason);
    }
  }

  const renderButtons = () => {
    return <React.Fragment>
      <Button children={'Cancel'}
              variant="text"
              startIcon={<Icon icon={Close}/>}
              onClick={handleCancel}/>
      <Button disabled={submitting}
              type="submit"
              variant="contained"
              children={'Confirm'}
              startIcon={<Icon icon={Save}/>}
              onClick={handleSubmitClick}/>
    </React.Fragment>
  }

  return <StyledColumnsDialog ref={innerRef} {...innerProps} onClose={handleClose}>
    <DialogHeader title="Columns" />
    <DialogContent>
      <InlineForm ref={formRef} fields={fields}
                  onValidating={handleValidating}
                  onChange={handleChange}
                  onSubmit={handleSubmit} />
    </DialogContent>
    <DialogFooter className="ColumnsDialog-footer"
                  info={(error ?? validation) ? <Span color="error">{error ?? validation}</Span> : null}
                  buttons={renderButtons()} />
  </StyledColumnsDialog>
});

ColumnsDialog.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  columns: PropTypes.array,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func
};

ColumnsDialog.defaultProps = {};

export default ColumnsDialog;
