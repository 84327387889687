import React, {useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useEffectItem, useImageLoaded} from 'helpers/hooks/utils';
import constants from 'helpers/constants';
import DialogContent from 'components/atoms/Dialogs/DialogContent/DialogContent';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import DialogFooter from 'components/molecules/Dialogs/DialogFooter/DialogFooter';
import Button from 'components/atoms/Buttons/Button/Button';
import DialogHeader from 'components/molecules/Dialogs/DialogHeader/DialogHeader';
import {Span} from 'components/atoms/Text/Typography/Typography';
import utils from 'helpers/utils';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Save from '@mui/icons-material/Save';
import Close from '@mui/icons-material/Close';
import Add from '@mui/icons-material/Add';
import StyledPersonDialog from 'components/organisms/Dialogs/PersonDialog/PersonDialog.styles';
import {useClientCallbacks} from 'services/client/client.utils';
import {useAuthClientId} from 'services/auth/auth.utils';

const PersonDialog = React.forwardRef((props, ref) => {
  const {
    person,
    title,
    type,
    country,
    onClose,
    onChange,
    onSubmit,
    onDelete,
    FileFieldProps,
    ...innerProps
  } = useComponentProps(props, 'PersonDialog', {
    static: ['isEditing']
  });

  const innerRef = useRef(null);
  const formRef = useRef(null);
  const validationRef = useRef({});

  const [internalState, setInternalState] = useState({
    error: null,
    warning: null,
    dirty: null,
    validation: null,
    submitting: false,
    linkedin: null,
    email: null,
    params: null,
    lookup: null
  });

  useImperativeHandle(ref, () => innerRef.current);

  const clientId = useAuthClientId();
  const isEditing = utils.isDefined(person?.personId);

  const clientCallbacks = useClientCallbacks();

  const linkedinMemo = useMemo(() => {
    return internalState.linkedin ?? person?.linkedin;
  }, [person?.linkedin, internalState.linkedin]);

  const emailMemo = useMemo(() => {
    return internalState.email ?? person?.email;
  }, [person?.email, internalState.email]);

  const personMemo = useMemo(() => {
    return (!isEditing ? internalState.lookup : null) ?? person;
  }, [person, internalState.lookup, isEditing]);

  const imgLoaded = useImageLoaded(personMemo?.img, null, utils.cleanFilename(personMemo?.name ?? 'current image') + '.png');

  const imgFile = useMemo(() => {
    return (personMemo?.img && imgLoaded === 'loaded') ? {
      name: utils.cleanFilename('current image') + '.png',
      src: personMemo?.img,
      type: 'image/png',
      size: (constants.numbers.KB * (100 + Math.ceil(Math.random() * 100)))
    } : null;
  }, [personMemo?.img, imgLoaded]);

  const FileFieldPropsMemo = useEffectItem(FileFieldProps);
  const fields = useMemo(() => {
    const fields = [];

    fields.push({
      name: 'img',
      label: 'Photo',
      entity: 'person',
      type: constants.formFieldTypes.file,
      validation: constants.formFieldValidationTypes.file,
      conversion: constants.formFieldConversionTypes.none,
      initial: imgFile,
      required: false,
      ...{
        ...FileFieldPropsMemo,
        FormFieldProps: {
          variant: isEditing ? 'inlineLabel' : 'staticLabel',
          hiddenLabel: !isEditing,
          fileVariant: 'logo',
          hiddenFiles: true,
          multiple: false,
          fileDescription: 'Jpeg, Png or Gif image',
          types: ['jpg', 'png', 'gif'],
          ...FileFieldPropsMemo?.FormFieldProps
        }
      }
    });

    fields.push({
      name: 'name',
      placeholder: 'Type a person name',
      type: constants.formFieldTypes.suggestion,
      validation: constants.formFieldValidationTypes.text,
      conversion: constants.formFieldConversionTypes.label,
      initial: personMemo?.name,
      options: !isEditing ? clientCallbacks.persons : null,
      required: true,
      FormFieldProps: {
        variant: isEditing ? 'inlineLabel' : 'staticLabel',
        explanation: 'these persons are already in our database',
        freeSolo: true,
        loadEmpty: false,
        hideEmpty: true,
        hideOpenClose: true,
        autoComplete: false,
        PaperProps: {
          size: 'small'
        }
      }
    });

    fields.push({
      name: 'linkedin',
      label: 'LinkedIn',
      entity: 'person',
      type: constants.formFieldTypes.link,
      validation: `${constants.formFieldValidationTypes.linkedin}(true)`,
      conversion: constants.formFieldConversionTypes.personLinkedin,
      format: constants.formFieldFormatTypes.url,
      validate: (value, testContext) => {
        return utils.retry(() => validationRef.current.ready)
          .then(() => {
            if (validationRef.current.linkedin === false) {
              return testContext.createError({message: 'Duplicate linkedin address'});
            } else {
              return true;
            }
          });
      },
      initial: personMemo?.linkedin,
      required: false,
      FormFieldProps: {
        variant: isEditing ? 'inlineLabel' : 'staticLabel'
      }
    });

    fields.push({
      name: 'email',
      entity: 'person',
      type: constants.formFieldTypes.email,
      validation: constants.formFieldValidationTypes.email,
      validate: (value, testContext) => {
        return utils.retry(() => validationRef.current.ready)
          .then(() => {
            if (validationRef.current.email === false) {
              return testContext.createError({message: 'Duplicate email address'});
            } else {
              return true;
            }
          })
      },
      initial: personMemo?.email,
      required: false,
      FormFieldProps: {
        variant: isEditing ? 'inlineLabel' : 'staticLabel'
      }
    });

    fields.push({
      name: 'phone',
      entity: 'person',
      type: constants.formFieldTypes.phone,
      validation: constants.formFieldValidationTypes.phone,
      initial: personMemo?.phone,
      required: false,
      context: country ? {country} : null,
      FormFieldProps: {
        variant: isEditing ? 'inlineLabel' : 'staticLabel'
      }
    });

    fields.push({
      name: 'title',
      type: constants.formFieldTypes.text,
      validation: constants.formFieldValidationTypes.text,
      initial: personMemo?.title,
      FormFieldProps: {
        variant: isEditing ? 'inlineLabel' : 'staticLabel'
      }
    });

    fields.push({
      name: 'role',
      type: constants.formFieldTypes.text,
      description: 'Current role at this company',
      validation: constants.formFieldValidationTypes.text,
      initial: personMemo?.role,
      FormFieldProps: {
        variant: isEditing ? 'inlineLabel' : 'staticLabel'
      }
    });

    fields.push({
      name: 'description',
      type: constants.formFieldTypes.textarea,
      validation: constants.formFieldValidationTypes.text,
      initial: personMemo?.description,
      FormFieldProps: {
        variant: isEditing ? 'inlineLabel' : 'staticLabel',
        minRows: 2
      }
    });

    return fields;
  }, [personMemo, imgFile, country, FileFieldPropsMemo, clientCallbacks, isEditing]);

  const handleChange = (field, value) => {
    onChange?.(field, value);
    setInternalState(utils.updater({error: null, validation: null, success: null}, true));
  };

  const handleChangeDirect = (e) => {
    const field = fields?.find((field) => field.name === e?.target?.name);
    const value = e?.target?.value;

    if (field.name === 'name') {
      if (utils.isEmpty(value)) {
        setInternalState((current) => {
          const reset = !current.lookupLinkedin && !current.lookupEmail;
          return utils.updater({
            ...current,
            lookup: reset ? null : current.lookup,
            lookupPerson: false,
            params: null,
            success: reset ? null : current.success
          })(current);
        });
      } else if (utils.isDefined(value?.person)) {
        formRef.current?.reset();
        setInternalState((current) => {
          return utils.updater({
            lookup: value?.person,
            linkedin: value?.person?.linkedin,
            email: value?.person?.email,
            lookupPerson: value?.person,
            params: null,
            success: null
          })(current);
        });
      }
    } else if (field.name === 'linkedin') {
      const clean = utils.cleanLinkedInHandle(value, true);

      if (utils.isEmpty(clean)) {
        setInternalState((current) => {
          const reset = !current.lookupPerson && !current.lookupEmail && !current.dirty;
          return utils.updater({
            ...current,
            params: null,
            lookup: reset ? null : current.lookup,
            linkedin: null,
            lookupLinkedin: false,
            success: reset ? null : current.success
          })(current);
        });
      } else if (linkedinMemo !== clean) {
        const dirty = Boolean(Object.keys(formRef.current?.changes() ?? {}).find((k) => k !== 'linkedin'));
        validationRef.current.ready = false;
        setInternalState(utils.updater({linkedin: clean, params: {type: 'linkedin', dirty}}, true));
      }
    } else if (field.name === 'email') {
      const clean = utils.cleanEmail(value);

      if (utils.isEmpty(clean)) {
        setInternalState((current) => {
          const reset = !current.lookupPerson && !current.lookupLinkedin && !current.dirty;
          return utils.updater({
            ...current,
            params: null,
            lookup: reset ? null : current.lookup,
            email: null,
            lookupEmail: false,
            success: reset ? null : current.success
          })(current);
        });
      } else if (emailMemo !== clean) {
        const dirty = Boolean(Object.keys(formRef.current?.changes() ?? {}).find((k) => k !== 'email'));
        validationRef.current.ready = false;
        setInternalState(utils.updater({email: clean, params: {type: 'email', dirty}}, true));
      }
    }
  }

  const debouncedLookup = useMemo(() => {
    return utils.debounce(({filter, callback}) => {
      clientCallbacks.persons({
        key: 'persons',
        filter: filter,
        callback: callback
      });
    }, constants.debounce.input);
  }, [clientCallbacks]);

  useEffect(() => {
    let active = true;

    const params = internalState.params || {};
    if (params?.type && (params.type === 'email' ? emailMemo?.length > 0 : linkedinMemo?.length > 0)) {
      setInternalState(utils.updater({
        isLoading: true
      }, true));

      debouncedLookup({
        filter: params.type === 'email' ? [{id: 'email', value: emailMemo}] :
          [{id: 'linkedin', value: linkedinMemo}],
        callback: (data) => {
          if (active) {
            if (data.length > 0) {
              setInternalState((current) => {
                return utils.updater({
                  ...current,
                  params: null,
                  lookup: (!current.lookup && !params.dirty) ? data[0].person : current.lookup,
                  [params.type]: data[0].person[params.type],
                  [params.type === 'email' ? 'lookupEmail' : 'lookupLinkedin']: data[0].person,
                  success: (!isEditing && !current.lookup && !params.dirty) ? 'Found a matching person' : current.success,
                  isLoading: false
                })(current);
              });
            } else {
              setInternalState((current) => {
                const reset = !current.lookupPerson && !current[params.type === 'email' ? 'lookupEmail' : 'lookupLinkedin'] && !params.dirty;
                return utils.updater({
                  ...current,
                  params: null,
                  lookup: reset ? null : current.lookup,
                  [params.type]: null,
                  [params.type === 'email' ? 'lookupEmail' : 'lookupLinkedin']: false,
                  success: reset ? null : current.success,
                  isLoading: false
                })(current);
              });
            }
          }
        }
      });
    }

    return () => {
      active = false;
      setInternalState(utils.updater({
        isLoading: false
      }, true));
    };
  }, [isEditing, linkedinMemo, emailMemo, internalState.params, debouncedLookup]);

  useEffect(() => {
    if (!internalState.isLoading) {
      validationRef.current.linkedin = !(internalState.lookupLinkedin &&
        +personMemo?.personId !== +internalState.lookupLinkedin?.personId &&
        +internalState.lookupLinkedin.clientId === +clientId);

      validationRef.current.email = !(internalState.lookupEmail &&
        +personMemo?.personId !== +internalState.lookupEmail?.personId &&
        +internalState.lookupEmail.clientId === +clientId);

      validationRef.current.ready = true;
    }
  }, [personMemo?.personId, clientId, internalState.isLoading, internalState.lookupLinkedin, internalState.lookupEmail]);

  const handleSubmit = (values, actions) => {
    setInternalState(utils.updater({submitting: true}, true));

    utils.asPromise(onSubmit)(values, internalState.lookup)
      .then(() => {
        onClose?.(null, 'saveButtonClick');
      })
      .catch(() => {
        setInternalState(utils.updater({error: `Saving ${type} failed`}, true));
      })
      .finally(() => {
        actions.setSubmitting(false);
        setInternalState(utils.updater({submitting: false}, true));
      });
  };

  const handleValidating = (isValidating, isDirty, hasErrors) => {
    setInternalState(utils.updater({
      dirty: isDirty,
      validation: hasErrors ? 'Please check if all fields have the correct values' : null
    }, true));
  }

  const handleSubmitClick = () => {
    formRef.current?.submit();
  };

  const handleCancel = (e) => {
    innerRef.current?.close?.(e);
  }

  const handleClose = (e, reason) => {
    if ((!internalState.submitting && !internalState.error && !internalState.dirty && !internalState.lookup) ||
        ['escapeKeyDown', 'closeButtonClick', 'cancelButtonClick'].includes(reason)) {
      onClose?.(e, reason);
    }
  }

  const renderButtons = () => {
    return <React.Fragment>
      <Button children={'Cancel'}
              variant="text"
              startIcon={<Icon icon={Close}/>}
              onClick={handleCancel}/>
      <Button disabled={internalState.submitting}
              type="submit"
              variant="contained"
              color={!isEditing ? 'success' : 'primary'}
              children={!isEditing ? `Add ${type}` : 'Save'}
              startIcon={<Icon icon={!isEditing ? Add : Save}/>}
              onClick={handleSubmitClick}/>
    </React.Fragment>
  }

  const onDeleteEvent = useEffectEvent(onDelete);
  const onCloseEvent = useEffectEvent(onClose);
  const deleteAction = useMemo(() => ({
    label: `Remove ${type}`,
    tooltip: `Remove ${type}`,
    auth: !onDeleteEvent ? utils.createAuth({attribute: 'system.null'}) : null,
    onClick: () => {
      setInternalState(utils.updater({submitting: true}, true));
      formRef.current?.formik?.setSubmitting?.(true);

      utils.asPromise(onDeleteEvent)()
        .then(() => {
          onCloseEvent?.(null, 'saveButtonClick');
        })
        .catch(() => {
          setInternalState(utils.updater({error: `Deleting ${type} failed`}, true));
        })
        .finally(() => {
          formRef.current?.formik?.setSubmitting?.(false);
          setInternalState(utils.updater({submitting: false}, true));
        });
    },
    IconButtonProps: {
      disabled: internalState.submitting
    }
  }), [type, internalState.submitting, onDeleteEvent, onCloseEvent]);

  innerProps.className = utils.flattenClassName(innerProps.className, {
    isEditing: isEditing
  });

  return <StyledPersonDialog ref={innerRef} {...innerProps} onClose={handleClose}>
    <DialogHeader title={title ?? (!isEditing ? `Add ${type}` : (person?.name ?? utils.upperFirst(type)))}
                  deleteAction={deleteAction} />
    <DialogContent>
      <InlineForm ref={formRef}
                  fields={fields}
                  onValidating={handleValidating}
                  onChange={handleChange}
                  onChangeDirect={handleChangeDirect}
                  onSubmit={handleSubmit} />
    </DialogContent>
    <DialogFooter className="LogoDialog-footer"
                  info={(internalState.error ?? internalState.validation) ?
                    <Span color="error">{internalState.error ?? internalState.validation}</Span> : (
                      internalState.success ?
                        <Span color="success">{internalState.success}</Span> : null
                    )}
                  buttons={renderButtons()} />
  </StyledPersonDialog>
});

PersonDialog.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  person: PropTypes.object,
  title: PropTypes.string,
  type: PropTypes.oneOfType([PropTypes.oneOf(['person', 'employee']), PropTypes.string]),
  country: PropTypes.string,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  FileFieldProps: PropTypes.object
};

PersonDialog.defaultProps = {
  type: 'person'
};

export default PersonDialog;
