import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import FormControl from 'components/atoms/Controls/FormControl/FormControl';
import {rgba} from 'polished';

const StyledCloudField = styled(FormControl)`
  &.FormControl-fullHeight {
    height: 100%;

    .CloudField-container {
      height: 100%;

      .ListField-list {
        height: 100%;
      }
    }
  }

  .CloudField {
    &-container {
      display: flex;
      flex-direction: column;
      gap: ${props => props.theme.layout('1sp')};

      .FormHelperText {
        margin-top: ${props => props.theme.spacing(-1)};
      }
    }
    
    &-list {
      padding: 0;
      width: 100%;
      
      .MuiFormControlLabel-root {
        width: calc(100% + ${props => props.theme.layout(8)});
        gap: ${props => props.theme.layout('0.5sp')};
        margin-left: ${props => props.theme.layout(-8)};
        margin-right: 0;
      }
      .MuiFormControlLabel-label {
        display: inline-flex;
        align-self: center;
      }

      .MuiChip-label {
        text-overflow: ellipsis;
        flex-grow: 1;
        overflow: hidden;
      }
    }
    
    &-empty {
      width: 100%;
      text-align: center;
      color: ${props => rgba(props.theme.property('palette.text.primary'), props.theme.property('palette.action.disabledOpacity'))};
      font-style: italic;

      P {
        white-space: pre-line;
        margin: ${props => props.theme.spacing(1)} 0;
      }
    }

    &-readOnly {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }
  
  ${ComponentStyles}
`;

StyledCloudField.propTypes = {
  theme: PropTypes.object
};

StyledCloudField.defaultProps = {
}

export default StyledCloudField;
