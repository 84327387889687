import React, {useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectItem, useImageLoaded} from 'helpers/hooks/utils';
import constants from 'helpers/constants';
import DialogContent from 'components/atoms/Dialogs/DialogContent/DialogContent';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import DialogFooter from 'components/molecules/Dialogs/DialogFooter/DialogFooter';
import Button from 'components/atoms/Buttons/Button/Button';
import DialogHeader from 'components/molecules/Dialogs/DialogHeader/DialogHeader';
import {Span} from 'components/atoms/Text/Typography/Typography';
import utils from 'helpers/utils';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Save from '@mui/icons-material/Save';
import Close from '@mui/icons-material/Close';
import StyledLogoDialog from 'components/organisms/Dialogs/LogoDialog/LogoDialog.styles';

const LogoDialog = React.forwardRef((props, ref) => {
  const {
    name,
    logo,
    altLogo,
    fallbackIcon,
    onChange,
    onSubmit,
    onClose,
    FileFieldProps,
    ...innerProps
  } = useComponentProps(props, 'LogoDialog');

  const innerRef = useRef(null);
  const formRef = useRef(null);

  const [error, setError] = useState(null);
  const [dirty, setDirty] = useState(null);
  const [validation, setValidation] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  useImperativeHandle(ref, () => innerRef.current);

  const logoLoaded = useImageLoaded(logo);

  const logoFile = useMemo(() => {
    return (logo && logoLoaded === 'loaded') ? {
      name: utils.cleanFilename(name ?? 'current logo') + '.png',
      src: logo,
      type: 'image/png',
      size: (constants.numbers.KB * (100 + Math.ceil(Math.random() * 100)))
    } : null;
  }, [logo, logoLoaded, name]);

  const FileFieldPropsMemo = useEffectItem(FileFieldProps);
  const fields = useMemo(() => {
    const fields = [];

    fields.push({
      name: 'logo',
      type: constants.formFieldTypes.file,
      validation: constants.formFieldValidationTypes.file,
      conversion: constants.formFieldConversionTypes.none,
      initial: logoFile,
      ...{
        ...FileFieldPropsMemo,
        FormFieldProps: {
          variant: 'staticLabel',
          fileVariant: 'logo',
          hiddenLabel: true,
          multiple: false,
          fileDescription: 'Jpeg, Png or Gif image',
          types: ['jpg', 'png', 'gif'],
          ...FileFieldPropsMemo?.FormFieldProps
        }
      }
    });

    return fields;
  }, [logoFile, FileFieldPropsMemo]);

  const handleChange = (field, value) => {
    onChange?.(field, value);
    setError(null);
    setValidation(null);
  };

  const handleSubmit = (values, actions) => {
    setSubmitting(true);

    utils.asPromise(onSubmit)(values['logo'])
      .then(() => {
        onClose?.(null, 'saveButtonClick');
      })
      .catch(() => {
        setError('Saving logo failed');
      })
      .finally(() => {
        actions.setSubmitting(false);
        setSubmitting(false);
      });
  };

  const handleValidating = (isValidating, isDirty, hasErrors) => {
    setDirty(isDirty);
    if (hasErrors) {
      setValidation('Please check if all fields have the correct values');
    } else {
      setValidation(null);
    }
  }

  const handleSubmitClick = () => {
    formRef.current?.submit();
  };

  const handleCancel = (e) => {
    innerRef.current?.close?.(e);
  }

  const handleClose = (e, reason) => {
    if ((!submitting && !error && !dirty) || ['escapeKeyDown', 'closeButtonClick', 'cancelButtonClick'].includes(reason)) {
      onClose?.(e, reason);
    }
  }

  const renderButtons = () => {
    return <React.Fragment>
      <Button children={'Cancel'}
              variant="text"
              startIcon={<Icon icon={Close}/>}
              onClick={handleCancel}/>
      <Button disabled={submitting}
              type="submit"
              variant="contained"
              children={'Save'}
              startIcon={<Icon icon={Save}/>}
              onClick={handleSubmitClick}/>
    </React.Fragment>
  }

  return <StyledLogoDialog ref={innerRef} {...innerProps} onClose={handleClose}>
    <DialogHeader title="Logo" />
    <DialogContent>
      <InlineForm ref={formRef}
                  fields={fields}
                  onValidating={handleValidating}
                  onChange={handleChange}
                  onSubmit={handleSubmit} />
    </DialogContent>
    <DialogFooter className="LogoDialog-footer"
                  info={(error ?? validation) ? <Span color="error">{error ?? validation}</Span> : null}
                  buttons={renderButtons()} />
  </StyledLogoDialog>
});

LogoDialog.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  name: PropTypes.string,
  logo: PropTypes.string,
  altLogo: PropTypes.string,
  fallbackIcon: PropTypes.any,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  FileFieldProps: PropTypes.object
};

LogoDialog.defaultProps = {};

export default LogoDialog;
