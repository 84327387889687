import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledLatestNewsBarChart = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: ${props => props.theme.layout('3sp')};
  width: 100%;
  height: 100%;
  overflow: hidden;

  .LatestNewsBarChart {
    &-chart {
      height: 100%;
      width: 100%;
      overflow: hidden;
    }

    &-legend {
      flex-grow: 1;
      min-width: min(40%, ${props => props.theme.layout(160)});
      max-width: fit-content;
      align-items: flex-start;
      justify-content: center;

      &.Legend {
        .Legend-list {
          gap: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(1)};
        }
        .Legend-item {
          &-title {
            width: 100%;

            .Span {
              max-width: 100% !important;
            }
          }
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledLatestNewsBarChart.propTypes = {
};

StyledLatestNewsBarChart.defaultProps = {
}

export default StyledLatestNewsBarChart;
