import React, {useCallback, useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useListState} from 'helpers/hooks/utils';
import StyledEntitiesValidateWizardContent
  from 'components/organisms/WizardContent/EntitiesValidateWizardContent/EntitiesValidateWizardContent.styles';
import Box from 'components/atoms/Layout/Box/Box';
import {H6} from 'components/atoms/Text/Typography/Typography';
import Table from 'components/organisms/Tables/Table/Table';
import constants from 'helpers/constants';
import FieldTableCellEdit from 'components/organisms/TableCellEdits/FieldTableCellEdit/FieldTableCellEdit';
import TextTableCell from 'components/molecules/TableCells/TextTableCell/TextTableCell';
import Edit from '@mui/icons-material/Edit';
import utils from 'helpers/utils';
import {useSourceProcess} from 'services/source/source.hooks';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Search from '@mui/icons-material/Search';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import Clear from '@mui/icons-material/Clear';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';

const EntitiesValidateWizardContent = React.forwardRef((props, ref) => {
  const {
    wizard,
    step,
    onComplete,
    onError,
    onDirty,
    ...innerProps
  } = useComponentProps(props, 'EntitiesValidateWizardContent');

  const innerRef = useRef(null);

  const data = wizard.data?.[wizard.dataKey];
  const state = wizard.getStepState();
  const fileId = data.fileId;
  const errors = data.errors;
  const replacement = data.replacement;
  const config = data.params.config;
  const collectionId = data.params.collectionId;

  const snackbar = useSnackbar();
  const dialogControl = useDialogControl();
  const processFileSource = useSourceProcess();
  const setStateEvent = useEffectEvent(wizard.setStepState);
  const setDataEvent = useEffectEvent(wizard.setData);

  const options = useMemo(() => ({
    listState: {
      options: {
        name: `entitiesValidate`,
      }
    }
  }), []);

  const listState = useListState(options.listState?.initial, options.listState?.options);

  const searchFieldRef = useRef(null);
  const setSearchEvent = useEffectEvent(listState.setSearch);
  const searchFields = useMemo(() => ([{
    name: 'search',
    label: 'Search',
    inlineLabel: 'search',
    placeholder: 'Search company name, column or value',
    type: constants.formFieldTypes.text,
    validation: constants.formFieldValidationTypes.text,
    initial: listState.search,
    debounce: constants.debounce.search,
    FormFieldProps: {
      ref: searchFieldRef,
      hiddenLabel: true,
      size: 'smallest',
      radius: 'round'
    },
    prefix: <Icon icon={Search} />,
    postfix: listState.search?.length > 0 ? <ActionIconButton action={{
      icon: <Icon icon={Clear}/>,
      onClick: (e) => {
        setSearchEvent?.('');
        searchFieldRef.current?.focus();
        e.preventDefault();
      }
    }} /> : null
  }]), [listState.search, setSearchEvent]);

  const onCompleteEvent = useEffectEvent(onComplete);
  const onErrorEvent = useEffectEvent(onError);
  const validateColumnsWizardContent = useMemo(() => ({
    refs: {
      ref: innerRef,
    },
    submit: () => {
      processFileSource.mutation.mutateAsync({
        fileId,
        collectionId,
        mapping: utils.underscoreEx(config, true),
        replacement: replacement
          .map((r) => {
            const corrected = r.correctedValue === '' ? null : (r.correctedValue ?? r.value);
            return {...r, correctedValue: corrected};
          })
      }).then((res) => {
        const errors = res.response?.data?.data?.errors;
        if (utils.isEmpty(errors)) {
          setStateEvent?.({cellsLeft: null});
          setDataEvent?.((current) => {
            return utils.updater({
              ...current,
              [wizard.dataKey]: {
                ...current[wizard.dataKey],
                example: res.response?.data?.data?.example,
                total: res.response?.data?.data?.total,
                errors: []
              }
            })(current);
          });
          onCompleteEvent?.(Boolean(step?.resetNextSteps));
        } else {
          const handleConfirm = () => {
            setStateEvent?.({cellsLeft: null});
            setDataEvent?.((current) => {
              return utils.updater({
                ...current,
                [wizard.dataKey]: {
                  ...current[wizard.dataKey],
                  example: res.response?.data?.data?.example,
                  total: res.response?.data?.data?.total,
                  errors: replacement.map((r) => {
                    const error = errors.find((err) => r.row === err.row && r.columnName === err.columnName && r.fieldName === err.fieldName);
                    if (error) {
                      return {...r, correctedValue: null}
                    } else {
                      return r;
                    }
                  }).concat(errors
                    .filter((err) => !replacement
                      .find((r) => r.row === err.row && r.columnName === err.columnName && r.fieldName === err.fieldName))
                    .map((err) => {
                      return {...err, correctedValue: null};
                    }))
                }
              })(current);
            });
            onCompleteEvent?.(Boolean(step?.resetNextSteps));
            dialogControl.hide();
          }

          const handleClose = () => {
            setStateEvent?.({cellsLeft: errors.length});
            setDataEvent?.((current) => {
              return utils.updater({
                ...current,
                [wizard.dataKey]: {
                  ...current[wizard.dataKey],
                  errors,
                }
              })(current);
            });

            onErrorEvent?.('More validation.');
            dialogControl.hide();
          }

          dialogControl.show(<ConfirmDialog question={`There are validation errors in ${errors.length} cells remaining, do you want to ignore these errors?`}
                                            explanation="The cells with invalid data will not be saved to the database"
                                            onConfirm={handleConfirm}
                                            ConfirmButtonProps={{
                                              children: 'Ignore',
                                              color: 'primary'
                                            }}/>, true, handleClose);
        }
      }).catch(() => {
        onErrorEvent?.('Validation failed.');
        snackbar.show('Column validation failed', null,
          {color: 'error', autoHideDuration: constants.delay.error});
      });
    }
  }), [
    fileId, config, replacement, collectionId, wizard.dataKey, step?.resetNextSteps, snackbar, dialogControl,
    processFileSource.mutation, onCompleteEvent, setDataEvent, setStateEvent, onErrorEvent
  ]);

  useImperativeHandle(ref, () => validateColumnsWizardContent);

  const onDirtyEvent = useEffectEvent(onDirty);
  const columnsMemo = useMemo(() => {
    const columns = [];

    columns.push({
      id: 'row',
      header: 'Row',
      size: 41,
      minSize: 41,
      enableEditing: false,
      Cell: ({cell}) => {
        const row = (cell.row.original.row ?? 0) + 1;
        return <TextTableCell title={row} />
      }
    });

    columns.push({
      id: 'entityName',
      header: 'Company',
      size: 120,
      minSize: 120,
      enableEditing: false,
      Cell: ({cell}) => {
        const name = (cell.row.original.entityName ?? '');
        return <TextTableCell title={name} />
      }
    });

    columns.push({
      id: 'columnName',
      header: 'Column',
      size: 142,
      minSize: 142,
      enableEditing: false,
      Cell: ({cell}) => {
        const columnName = cell.row.original.columnName;
        const fieldDef = constants.upload.fieldDefinition.lookup(utils.camelcase(columnName));

        return <TextTableCell title={fieldDef?.label ?? columnName} />
      }
    });

    columns.push({
      id: 'value',
      header: 'Value',
      enableEditing: true,
      Edit: ({column, table, cell}) => {
        const value = cell.column.columnDef.optimistic.get(cell, cell.row.original?.value);
        const error = cell.row.original;

        const fields = useMemo(() => [{
          name: column.id,
          label: column.columnDef.header,
          placeholder: 'Type a value',
          type: utils.isEmpty(error.options) ? constants.formFieldTypes.text : constants.formFieldTypes.autocomplete,
          conversion: !utils.isEmpty(error.options) ? constants.formFieldConversionTypes.value : null,
          required: false,
          initial: utils.isArray(value) ? value.join(';') : (value ?? ''),
          options: !utils.isEmpty(error.options) ? error.options : null,
          FormFieldProps: {
            minWidth: 400
          }
        }], [column.columnDef.header, column.id, error.options, value]);

        const handleChange = (field, value, onSuccess) => {
          cell.column.columnDef.optimistic.set(cell, value);
          const currentError = cell.row.original;
          const newReplacement = replacement.map((r) => r);
          const replacementIdx = newReplacement.findIndex((r) => {
            return r.row === currentError.row && r.columnName === currentError.columnName &&
              r.fieldName === currentError.fieldName;
          });

          if (replacementIdx !== -1) {
            newReplacement[replacementIdx] = {...newReplacement[replacementIdx], correctedValue: value};
          } else {
            newReplacement.push({...currentError, correctedValue: value});
          }

          setDataEvent?.((current) => {
            return utils.updater({
              ...current,
              [wizard.dataKey]: {
                ...current[wizard.dataKey],
                replacement: newReplacement
              }
            })(current);
          });

          onSuccess?.();
          onDirtyEvent?.(true);
        };

        const handleSubmit = (values, actions) => {
          actions.setSubmitting(false);
        };

        return <FieldTableCellEdit fields={fields}
                                   table={table}
                                   cell={cell}
                                   onChange={handleChange}
                                   onSubmit={handleSubmit}
                                   TableCellPopperProps={{stretch: true}}
                                   Anchor={<TextTableCell title={utils.isArray(value) ? value.join(';') : (value ?? '')} />}/>
      },
      Cell: ({table, cell}) => {
        const value = cell.column.columnDef.optimistic.get(cell, cell.row.original?.value);

        const action = wizard.state.submitting ? null : {
          label: 'Edit',
          tooltip: 'Edit',
          auth: null,
          icon: Edit,
          onClick: (e) => {
            table.setEditingCell(cell);
            e.preventDefault();
          }
        };
        return <TextTableCell title={utils.isArray(value) ? value.join(';') : (value ?? '')}
                              action={action} />
      }
    });

    columns.push({
      id: 'hint',
      header: 'Correct example',
      enableEditing: false,
      Cell: ({cell}) => {
        let error = cell.row.original.errorMsg;
        let example = cell.row.original.hint;
        if (cell.row.original.options?.length > 0) {
          example = cell.row.original.options.slice(0, 10).join(', ');
        }
        return <TextTableCell title={error} subtitle={`e.g. ${example}`} />
      }
    });

    return columns;
  }, [replacement, setDataEvent, wizard.dataKey, wizard.state.submitting, onDirtyEvent]);

  const rows = useMemo(() => {
    return (errors ?? []).filter((error) => {
      const entityName = error.entityName;
      const columnName = error.columnName;
      const fieldDef = constants.upload.fieldDefinition.lookup(utils.camelcase(columnName));

      const value = replacement.find((r) => {
        return (r.row === error.row && r.columnName === error.columnName &&
          r.fieldName === error.fieldName && r.hasOwnProperty('correctedValue'));
      })?.correctedValue ?? error.value;

      return utils.isEmpty(listState.search) ||
        entityName?.toLowerCase()?.includes(listState.search.toLowerCase()) ||
        value?.toString().toLowerCase()?.includes(listState.search.toLowerCase()) ||
        (fieldDef?.label ?? columnName)?.toLowerCase()?.includes(listState.search.toLowerCase());
    });
  }, [errors, listState.search, replacement]);

  const handleSearch = (field, value) => {
    setSearchEvent?.(value);
  }

  const renderSubtitle = () => {
    const step = wizard.steps[wizard.state.stepIndex];
    const subtitle = step.subSteps?.[wizard.state.subStepIndex || 0].subtitle ?? step.subtitle;

    if (subtitle) {
      return <Box className="EntitiesValidateWizardContent-subtitle">
        <H6>{state.cellsLeft ? `Please correct the remaining ${state.cellsLeft} cells.` : subtitle}</H6>
        <InlineForm className="EntitiesValidateWizardContent-search"
                    onChange={handleSearch}
                    fields={searchFields} />
      </Box>
    }
  };

  const getRowId = useCallback((row) => {
    return `${row.row}_${row.columnName}`;
  }, []);

  return <StyledEntitiesValidateWizardContent ref={ref} {...innerProps}>
    {renderSubtitle()}
    <Table className="EntitiesValidateWizardContent-table"
           getRowId={getRowId}
           enableParentScroll={false}
           enableBottomToolbar={false}
           enableStickyHeader={true}
           enableSorting={false}
           enableEditing={!wizard.state.submitting}
           enableColumnDragging={false}
           enableColumnActions={false}
           enableColumnOrdering={false}
           enableColumnResizing={false}
           enablePinning={false}
           listState={listState}
           columns={columnsMemo}
           data={rows}
           rowCount={rows.length}
           debounce={false}
           state={{
             isLoading: false,
             showProgressBars: false
           }} />
  </StyledEntitiesValidateWizardContent>
});

EntitiesValidateWizardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  wizard: PropTypes.object,
  step: PropTypes.object,
  onComplete: PropTypes.func,
  onDirty: PropTypes.func,
  onError: PropTypes.func,
};

EntitiesValidateWizardContent.defaultProps = {
};

export default EntitiesValidateWizardContent;
