import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import StyledEntitiesTableColumns
  from 'components/organisms/TableColumns/EntitiesTableColumns/EntitiesTableColumns.styles';
import constants from 'helpers/constants';

const EntitiesTableColumns = React.forwardRef((props, ref) => {
  const {
    columnGroups,
    columnPresets,
    ...innerProps
  } = useComponentProps(props, 'EntitiesTableColumns');

  const tableProvider = useTable();
  const columnDefinitions = tableProvider.columnDefinitions;

  const [columnGroupsMemo, columnPresetsMemo] = useMemo(() => {
    const entityColumnGroups = [], entityColumnPresets = [];
    if (columnDefinitions) {
      columnDefinitions
        .sort((a, b) => {
          return ((a.position < 0) === (b.position < 0)) ? a.position - b.position : (a.position < 0 ? 1 : -1);
        })
        .forEach((columnDef) => {
          // columnGroups
          if (columnDef.group) {
            let group = entityColumnGroups.find((g) => g.name === columnDef.group.name);
            if (!group) {
              group = {
                id: columnDef.group.name,
                name: columnDef.group.name,
                title: columnDef.group.title,
                columns: []
              }
              entityColumnGroups.push(group);
            }
            group.columns.push({
              id: columnDef.id,
              name: columnDef.name,
              label: columnDef.header,
              position: columnDef.fieldPosition ?? columnDef.position
            });

            // columnPresets
            columnDef.presets?.forEach((cp) => {
              let preset = entityColumnPresets.find((p) => p.name === cp.name);
              if (!preset) {
                preset = {
                  id: cp.name,
                  name: cp.name,
                  label: cp.title,
                  position: cp.position,
                  columns: []
                };
                entityColumnPresets.push(preset);
              }

              preset.columns.push(columnDef.name);
            });
          }
        });

      entityColumnGroups.forEach((cg, idx) => {
        cg.position = (idx + 1) * constants.numbers.position.groupGap;
        cg.columns = cg.columns.sort((a, b) => {
          return ((a.position < 0) === (b.position < 0)) ? a.position - b.position : (a.position < 0 ? 1 : -1);
        });
        cg.columns.forEach((c, idx) => {
          c.position = (idx + 1) * constants.numbers.position.fieldGap;
        });
      });
    }

    // merge columnGroups
    columnGroups?.forEach?.((columnGroup) => {
      const existingColumnGroupIndex = entityColumnGroups.findIndex((cg) => {
        return cg.name === columnGroup.name && cg.title === columnGroup.title;
      });

      if (existingColumnGroupIndex !== -1) {
        const columns = columnGroup.columns ?? [];
        entityColumnGroups[existingColumnGroupIndex].columns?.forEach?.((column) => {
          if (!columns.find((c) => c.name === column.name)) {
            columns.push(column);
          }
        });

        entityColumnGroups[existingColumnGroupIndex] = {
          ...entityColumnGroups[existingColumnGroupIndex],
          ...columnGroup,
          columns
        }
      } else {
        entityColumnGroups.push(columnGroup);
      }
    });

    // merge columnPresets
    columnPresets?.forEach?.((columnPreset) => {
      const existingColumnPresetIndex = entityColumnPresets.findIndex((cp) => {
        return cp.name === columnPreset.name && cp.title === columnPreset.title;
      });

      if (existingColumnPresetIndex !== -1) {
        const columns = columnPreset.columns ?? [];
        entityColumnPresets[existingColumnPresetIndex].columns?.forEach?.((column) => {
          if (!columns.find((c) => c === column)) {
            columns.push(column);
          }
        });

        entityColumnPresets[existingColumnPresetIndex] = {
          ...entityColumnPresets[existingColumnPresetIndex],
          ...columnPreset,
          columns
        }
      } else {
        entityColumnPresets.push(columnPreset);
      }
    })

    return [entityColumnGroups, entityColumnPresets];
  }, [columnDefinitions, columnGroups, columnPresets]);

  const handleClose = () => {
    tableProvider.closeColumns();
  }

  const handleReset = () => {
    tableProvider.columnState.clear();
    tableProvider.closeColumns();
  }

  const handleSubmit = (activeColumns) => {
    tableProvider.columnState.setColumnVisibility(activeColumns);
    tableProvider.closeColumns();
  }

  return <StyledEntitiesTableColumns ref={ref} {...innerProps}
                                     columnPresets={columnPresetsMemo}
                                     columnGroups={columnGroupsMemo}
                                     activeColumns={tableProvider.columnState.columnVisibility}
                                     onClose={handleClose}
                                     onReset={handleReset}
                                     onSubmit={handleSubmit}>
    {innerProps.children}
  </StyledEntitiesTableColumns>
});

EntitiesTableColumns.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  columnGroups: PropTypes.array,
  columnPresets: PropTypes.array
};

EntitiesTableColumns.defaultProps = {};

export default EntitiesTableColumns;
