import React, {useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import StyledSearchForm from 'components/organisms/Forms/SearchForm/SearchForm.styles';
import constants from 'helpers/constants';
import Search from '@mui/icons-material/Search';
import Close from '@mui/icons-material/Close';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Button from 'components/atoms/Buttons/Button/Button';
import utils from 'helpers/utils';

const SearchForm = React.forwardRef((props, ref) => {
  const {
    onChange,
    value,
    placeholder,
    keepFocus,
    ...innerProps
  } = useComponentProps(props, 'SearchForm');

  const innerRef = useRef(null);
  const searchFieldRef = useRef(null);
  const [internalState, setInternalState] = useState({});

  const searchForm = useMemo(() => ({
    refs: {
      ref: innerRef,
      searchFieldRef
    },
    state: {
      ...internalState,
      ...utils.cleanObject({value})
    },
    focus: () => {
      const fieldRef = searchForm.refs.searchFieldRef;
      fieldRef.current?.focus();
    }
  }), [internalState, value]);

  useImperativeHandle(ref, () => searchForm);

  const handleChange = (field, value) => {
    onChange?.(value);

    setInternalState(utils.updater({value}, true));
  };

  const handleBlur = () => {
    if (keepFocus) {
      const fieldRef = searchForm.refs.searchFieldRef;
      fieldRef.current?.focus();
    }
  };

  const handleClear = () => {
    const fieldRef = searchForm.refs.searchFieldRef;

    onChange?.('');
    setInternalState(utils.updater({value: ''}, true));
    fieldRef.current?.focus();
  };

  const fields = useMemo(() => ([{
    name: 'search',
    placeholder: placeholder ?? 'Search',
    type: constants.formFieldTypes.text,
    validation: constants.formFieldValidationTypes.text,
    initial: searchForm.state.value,
    prefix: <Icon icon={Search} />,
    debounce: constants.debounce.search,
    FormFieldProps: {
      ref: searchForm.refs.searchFieldRef,
      hiddenLabel: true,
      variant: 'standard',
      size: 'small',
      InputLabelProps: {
        shrink: true,
      },
      InputProps: {
        disableUnderline: true
      }
    }
  }]), [searchForm.state.value, searchForm.refs.searchFieldRef, placeholder]);

  return <StyledSearchForm ref={searchForm.refs.ref} {...innerProps}>
    <InlineForm className="SearchForm-form" onChange={handleChange} onBlur={handleBlur} fields={fields}/>
    <Button className="SearchForm-button"
            variant="text"
            startIcon={<Icon icon={Close}/>}
            onClick={handleClear}
            disabled={!Boolean(searchForm.state.value)}>
      Clear
    </Button>
  </StyledSearchForm>
});

SearchForm.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func
};

SearchForm.defaultProps = {};

export default SearchForm;
