import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectItem} from 'helpers/hooks/utils';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import constants from 'helpers/constants';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import Box from 'components/atoms/Layout/Box/Box';
import {P} from 'components/atoms/Text/Typography/Typography';
import {useLinkNavigate} from 'helpers/hooks/links';
import StyledCollectionEntitiesLatestNewsGraphCard
  from 'components/organisms/Cards/CollectionEntitiesLatestNewsGraphCard/CollectionEntitiesLatestNewsGraphCard.styles';
import LatestNewsBarChart from 'components/organisms/Charts/LatestNewsBarChart/LatestNewsBarChart';
import {useClientStatisticLatestNews} from 'services/client/statistic/statistic.utils';

const CollectionEntitiesLatestNewsGraphCard = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionEntitiesLatestNewsGraphCard');

  const tableProvider = useTable();
  const collection = tableProvider.context?.data;
  const graphState = tableProvider.graphState;

  const [latestNews, setLatestNews] = useState(null);

  const navigate = useLinkNavigate();

  const listState = useEffectItem(tableProvider.appliedListState());
  const months = graphState.graphSettings['latestNews']?.filter?.months ?? 12;

  const [latestNewsBreakdown, isLoading] = useClientStatisticLatestNews(
    collection?.collectionId,
    months,
    listState.search,
    listState.filter
  );

  useEffect(() => {
    if (latestNewsBreakdown) {
      setLatestNews(latestNewsBreakdown);
    }
  }, [latestNewsBreakdown]);

  const fields = useMemo(() => {
    const initialOption = constants.data.periodMonths.find((opt) => opt.value === months);

    return [{
      name: 'months',
      type: constants.formFieldTypes.autocomplete,
      validation: constants.formFieldValidationTypes.text,
      placeholder: 'Select a period',
      initial: initialOption,
      FormFieldProps: {
        autoFocus: false,
        hiddenLabel: true,
        clearable: false,
        size: 'small'
      },
      options: constants.data.periodMonths
    }];
  }, [months]);

  const handleFilterChange = (field, value) => {
    graphState.setGraphSettings((current) => ({
      ...current,
      'latestNews': {
        ...current?.['latestNews'],
        filter: {
          ...current?.['latestNews']?.filter,
          months: value?.value ?? value
        }
      }
    }));
  };

  const handleVisibilityChange = (visibility) => {
    graphState.setGraphSettings((current) => ({
      ...current,
      'latestNews': {
        ...current?.['latestNews'],
        visibility
      }
    }));
  };

  const handleClick = (e, release, data) => {
    const label = data.tooltipPayload?.[0]?.dataKey;
    let month = data.month;
    if (label && month) {
      month = new Date(month);
      navigate({
        event: e,
        to: `/collections/${collection.collectionId}/entities?custom=latestNews:${label}_${month.getFullYear()}${(month.getMonth() + 1) < 10 ? '0' : ''}${month.getMonth() + 1}`,
        keepSearchParams: true
      });
    }
  };

  const total = (latestNews ?? []).reduce((t, n) => t + n?.count, 0);

  return <StyledCollectionEntitiesLatestNewsGraphCard ref={ref} {...innerProps}
                                                      title="Latest news"
                                                      context={(fields ? <InlineForm onChange={handleFilterChange}
                                                                                     fields={fields} /> : null)}>
    {(total > 0 || isLoading) ?
      <LatestNewsBarChart news={latestNews}
                          showLegend={true}
                          isLoading={isLoading}
                          onClick={handleClick}
                          visibility={graphState.graphSettings['latestNews']?.visibility}
                          onVisibilityChange={handleVisibilityChange} /> : null}

    {(!(total > 0) && !isLoading) ?
      <Box className="GraphCard-empty">
        <P>No latest news found</P>
      </Box> : null}
  </StyledCollectionEntitiesLatestNewsGraphCard>
});

CollectionEntitiesLatestNewsGraphCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionEntitiesLatestNewsGraphCard.defaultProps = {};

export default CollectionEntitiesLatestNewsGraphCard;
