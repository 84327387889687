import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import FieldTableCell from 'components/molecules/TableCells/FieldTableCell/FieldTableCell';

const StyledQuestionnaireTableCell = styled(FieldTableCell)`
  ${ComponentStyles}
`;

StyledQuestionnaireTableCell.propTypes = {
  theme: PropTypes.object
};

StyledQuestionnaireTableCell.defaultProps = {
}

export default StyledQuestionnaireTableCell;
