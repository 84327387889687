import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import StyledRelevancyTableCell
  from 'components/molecules/TableCells/RelevancyTableCell/RelevancyTableCell.styles';
import constants from 'helpers/constants';
import {withMemo} from 'helpers/wrapper';
import utils from 'helpers/utils';

const RelevancyTableCell = withMemo(React.forwardRef((props, ref) => {
  const {
    relevancy,
    disabled,
    active,
    size,
    tie,
    onClick,
    ...innerProps
  } = useComponentProps(props, 'RelevancyTableCell');

  const onClickEvent = useEffectEvent(onClick);
  const ActionButtonProps = useMemo(() => {
    const relevancyDef = constants.data.lookup('hml', relevancy ?? 0);
    return {
      showInactive: true,
      action: {
        auth: disabled ? utils.createAuth({attribute: 'system.null'}) : null,
        label: tie ? 'Tie' : (relevancyDef.label ?? 'None'),
        ButtonProps: {
          color: relevancyDef.buttonColor,
          variant: 'flat',
          size: size,
          fullWidth: true,
          active: active
        },
        onClick: onClickEvent ? (e) => {
          onClickEvent?.(e);
          e.preventDefault();
        } : null
      }
    }
  }, [relevancy, disabled, active, onClickEvent, size, tie]);

  return <StyledRelevancyTableCell ref={ref} {...innerProps}
                                   ActionButtonProps={ActionButtonProps} />
}));

RelevancyTableCell.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  relevancy: PropTypes.number,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  tie: PropTypes.bool,
  onClick: PropTypes.func,
  ButtonProps: PropTypes.object
};

RelevancyTableCell.defaultProps = {
  size: 'medium'
};

export default RelevancyTableCell;
