import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectItem} from 'helpers/hooks/utils';
import Component from 'components/organisms/Utils/Component/Component';
import StyledPersonsList from 'components/organisms/Lists/PersonsList/PersonsList.styles';
import PersonCard from 'components/molecules/Cards/PersonCard/PersonCard';

const PersonsList = React.forwardRef((props, ref) => {
  const {
    persons,
    Card,
    onClick,
    isLoading,
    PersonCardProps,
    ...innerProps
  } = useComponentProps(props, 'PersonsList');

  const PersonCardDefault = ({person, ...props}) => {
    return <PersonCard person={person}
                       fullWidth={true}
                       {...props}/>
  };

  const CardMemo = useEffectItem(Card ?? PersonCardDefault);
  const renderCard = (person, state) => {
    return <Component Original={CardMemo}
                      person={person}
                      selected={state.selected}
                      onClick={onClick}
                      isLoading={state.isLoading}
                      {...PersonCardProps} />
  };

  const isItemEqual = (person, selected) => {
    return +person.personId === +selected.personId;
  }

  return <StyledPersonsList ref={ref} {...innerProps}
                            data={persons?.data}
                            count={persons.meta?.resultsCount}
                            dataKey="personId"
                            isItemEqual={isItemEqual}
                            renderItem={renderCard}
                            isLoading={isLoading || persons.status.isLoading}
                            showProgressBar={persons.status.isLoadingNext}
                            onFetchMore={persons.query?.fetchNextPage}
                            ListProps={{
                              track: true,
                              virtualize: true,
                              estimatedSize: 156,
                              gap: 8,
                            }}/>
});

PersonsList.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  persons: PropTypes.object,
  Card: PropTypes.any,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  PersonCardProps: PropTypes.object
};

PersonsList.defaultProps = {
  loaderCount: 6
};

export default PersonsList;
