import axios from 'axios';
import system from 'helpers/system';
import {useCallback} from 'react';
import {useAuthTeamId, useAuthToken} from 'services/auth/auth.utils';

export const apiHost = system.apiHost();
export const apiPath = '/v1';

export const api = axios.create({
  baseURL: apiHost + apiPath,
  headers: {
    "content-type": "application/json",
    ...(apiHost.includes('ngrok') ? {"ngrok-skip-browser-warning": "true"} : null)
  },
});

export function useApi (token) {
  const authToken = useAuthToken();
  const authTeamId = useAuthTeamId();
  token = token ?? authToken;

  return useCallback((signal = null) => {
    const getPath = (path) => {
      return path.startsWith('/') ? path : ('/' + path);
    };

    const getHeaders = (headers) => {
      return {
        'x-access-token': token,
        'x-team-id': authTeamId,
        ...headers
      };
    }

    return {
      get: (path, params, headers = null) => {
        return api.get(`${getPath(path)}`, {headers: getHeaders(headers), params, signal});
      },
      put: (path, params, data, headers = null) => {
        return api.put(`${getPath(path)}`, data, {headers: getHeaders(headers), params, signal});
      },
      patch: (path, params, data, headers = null) => {
        return api.patch(`${getPath(path)}`, data, {headers: getHeaders(headers), params, signal});
      },
      post: (path, params, data, headers = null) => {
        return api.post(`${getPath(path)}`, data, {headers: getHeaders(headers), params, signal});
      },
      delete: (path, params, data, headers = null) => {
        return api.delete(`${getPath(path)}`, {headers: getHeaders(headers), data, params, signal});
      }
    }
  }, [token, authTeamId]);
}
