import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledTasksContextCard from 'components/organisms/Cards/TasksContextCard/TasksContextCard.styles';
import TasksList from 'components/organisms/Lists/TasksList/TasksList';
import ActionSwitch from 'components/molecules/Switches/ActionSwitch/ActionSwitch';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import {P} from 'components/atoms/Text/Typography/Typography';

const TasksContextCard = React.forwardRef((props, ref) => {
  const {
    tasks,
    onSwitch,
    onClick,
    switched,
    isLoading,
    TasksListComponent,
    TaskListProps,
    ...innerProps
  } = useComponentProps(props, 'TasksContextCard');

  const isContentLoading = isLoading || tasks.status.isLoading || !utils.isDefined(tasks.data);
  const [internalState, setInternalState] = useState({
    switched: false
  });

  const tasksContextCard = useMemo(() => ({
    state: {
      ...internalState, // the state from start, and internal toggle
      ...utils.cleanObject({switched}) // the override state (nulls are cleaned), see onToggle
    }
  }), [internalState, switched]);

  const switchAction = useMemo(() => ({
    label: 'My tasks',
    active: tasksContextCard.state.switched,
    onClick: (e) => {
      onSwitch?.(!tasksContextCard.state.switched);
      setInternalState((current) => {
        return utils.updater({
          switched: !current.switched
        })(current);
      });
      e.preventDefault();
    },
  }), [tasksContextCard.state.switched, onSwitch])

  const renderActionSwitch = () => {
    return <ActionSwitch action={switchAction}
                         isLoading={isLoading}
                         SwitchProps={{
                           size: 'medium',
                           color: 'primary'
                         }}/>
  }

  const taskCount = utils.formatNumber(tasks.meta?.resultsCount ?? 0);

  return <StyledTasksContextCard ref={ref} {...innerProps}
                                 title={(isContentLoading ? 'Tasks' : `${taskCount ?? 0} task${(tasks.meta?.resultsCount ?? 0) === 1 ? '' : 's'}`)}
                                 context={renderActionSwitch()}
                                 isLoading={isContentLoading}>
    <TasksListComponent className="TasksContextCard-list"
                        tasks={tasks}
                        onClick={onClick}
                        isLoading={isContentLoading}
                        loaderCount={2}
                        TaskCardProps={{
                          variant: 'compact'
                        }}
                        {...TaskListProps}/>
    {(!(tasks.data?.length > 0) && !isContentLoading) ?
      <Box className="TasksContextCard-empty">
        <P>No task(s) found</P>
      </Box> : null}
  </StyledTasksContextCard>
});

TasksContextCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  tasks: PropTypes.object,
  onSwitch: PropTypes.func,
  onClick: PropTypes.func,
  switched: PropTypes.bool,
  isLoading: PropTypes.bool,
  TasksListComponent: PropTypes.any,
  TaskListProps: PropTypes.object
};

TasksContextCard.defaultProps = {
  TasksListComponent: TasksList
};

export default TasksContextCard;
