import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledTaskProfileCardContent
  from 'components/organisms/Cards/TaskProfileCardContent/TaskProfileCardContent.styles';
import ActionChip from 'components/molecules/Chips/ActionChip/ActionChip';
import constants from 'helpers/constants';
import Box from 'components/atoms/Layout/Box/Box';
import Typography from 'components/atoms/Text/Typography/Typography';
import ActionAvatar from 'components/molecules/Avatars/ActionAvatar/ActionAvatar';

const TaskProfileCardContent = React.forwardRef((props, ref) => {
  const {
    content,
    ...innerProps
  } = useComponentProps(props, 'TaskProfileCardContent');

  const contentMemo = useMemo(() => {
    if (content?.fields) {
      const task = innerProps.data;

      content.fields = content?.fields.map((field) => {
        if (field.name === 'location') {
          const renderOption = (option, props) => {
            const to = option.value === constants.task.locations.collection ?
              `/collections/${task?.collection?.collectionId}/entities` : `/${option.value}`;

            const locationAction = {
              label: option.label,
              navigation: {
                to: to,
                resetSearchParams: true
              },
              icon: option.icon
            };

            return <ActionChip action={locationAction}
                               variant="outlined"
                               color="primary"
                               size="medium"
                               disabled={props?.disabled}/>
          }
          field.FormFieldProps = {
            renderOption: renderOption,
            renderReadOnlyOption: renderOption,
            ...field.FormFieldProps
          };
        } else if (field.name === 'comment') {
          field.FormFieldProps = {
            mentions: [{
              trigger: '@',
              data: (search, callback) => innerProps.fieldData.callbacks.members({search, callback})
            }],
            ...field.FormFieldProps
          };
        } else if (field.name === 'responsible') {
          const renderOptionLabel = (option, props) => {
            const avatar = `${utils.personName(option.profile.firstName, option.profile.lastName)} (${option.profile.username})`;
            const name = utils.personName(option.profile.firstName, option.profile.lastName);

            const avatarLabel = utils.avatarLabel(avatar);
            const color = utils.string2Color(avatar);

            return <Box className="responsible-label">
              <ActionAvatar size="smaller"
                            action={{
                              label: avatarLabel,
                              tooltip: avatar
                            }}
                            color={color}
                            disabled={props?.disabled}
                            TooltipProps={{enterDelay: 0}}/>
              <Typography variant="body2">{name}</Typography>
            </Box>
          }
          field.FormFieldProps = {
            renderOptionLabel: renderOptionLabel,
            renderReadOnlyOption: renderOptionLabel,
            ...field.FormFieldProps
          };
        } else if (field.name === 'collaborationType') {
          field.readOnly = true; //Boolean(task?.type && !task.type.collaborationType);
        }
        return field;
      });
    }
    return content;
  }, [content, innerProps.data, innerProps.fieldData.callbacks]);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledTaskProfileCardContent ref={ref} {...innerProps}
                                       content={contentMemo}/>
});

TaskProfileCardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

TaskProfileCardContent.defaultProps = {
};

export default TaskProfileCardContent;
