import React, {useLayoutEffect, useMemo, useRef, useState} from 'react'
import PropTypes from 'prop-types';
import utils from 'helpers/utils';
import {useCardState, useGraphState} from 'helpers/hooks/utils';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';

import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import constants from 'helpers/constants';

export const ProfileContext = React.createContext(null);

export function useProfile () {
  return React.useContext(ProfileContext);
}

const ProfileProvider = (props) => {
  const refs = useRef({});
  const [internalState, setInternalState] = useState({
    showSidebar: {
      profile: true,
      browser: false
    },
    showContext: {
      profile: true,
      browser: true
    },
    showProfile: false,
    showTask: false,
    settings: {},
    isEditing: false,
    isSubmitting: false
  });

  const viewInitialised = useRef(false);
  const definitionsInitialised = useRef(false);

  // see also TableProvider
  const optionsMemo = useMemo(() => {
    const options = utils.mergeObjects(props?.options ?? {}, props?.view?.options, true);
    Object.keys(options).forEach((k) => {
      if (options[k]?.options && options[k].options.type) {
        if (props?.view || props.skipStorage) {
          if (props?.view && (props?.view?.name !== 'default') && options[k].options.name) {
            options[k].options.name = `${options[k].options.name}_${props.view.name}`;
          }
          if (props?.view?.skipStorage || props.skipStorage) {
            if (props.skipStorage) {
              options[k].options.searchParams = false;
            }
            options[k].options.type = constants.appState.type.none;
          }
        }
      }
    });

    if (!props?.isDefinitionsLoading || definitionsInitialised.current) {
      definitionsInitialised.current = true;

      const cardVisibility = (options?.cardDefinitions || []).reduce((o, cardDef) => {
        if (utils.isDefined(cardDef.visible)) {
          o = o ?? {};
          o[cardDef.name] = cardDef.visible;
        }
        return o;
      }, options?.cardState?.initial?.cardVisibility);

      const graphVisibility = (options?.graphDefinitions || []).reduce((o, graphDef) => {
        if (utils.isDefined(graphDef.visible)) {
          o = o ?? {};
          o[graphDef.name] = graphDef.visible;
        }
        return o;
      }, options?.graphState?.initial?.graphVisibility);

      return {
        ...options,
        cardState: {
          ...options?.cardState,
          initial: {
            ...options?.cardState?.initial,
            cardVisibility: cardVisibility
          }
        },
        graphState: {
          ...options?.graphState,
          initial: {
            ...options?.graphState?.initial,
            graphVisibility: graphVisibility
          }
        }
      };
    } else {
      return {};
    }
  }, [props?.options, props?.skipStorage, props?.view, props?.isDefinitionsLoading]);

  const cardState = useCardState(optionsMemo?.cardState?.initial, optionsMemo?.cardState?.options);
  const graphState = useGraphState(optionsMemo?.graphState?.initial, optionsMemo?.graphState?.options);

  const tableProvider = useTable();
  const authorize = useAuthorize();

  const cardDefinitions = useMemo(() => {
    const cardDefs = props.options?.cardDefinitions;
    if (cardDefs?.length > 0) {
      return cardDefs
        .filter((cardDef) => !cardDef.hidden && authorize(cardDef.auth?.read ?? {}))
        .map((cardDef) => {
          cardDef.fields = (cardDef.fields ?? [])
            .filter((f) => !f.hidden && authorize(f.auth?.read ?? {}));
          return cardDef;
        });
    } else {
      return null;
    }
  }, [authorize, props.options?.cardDefinitions]);

  const sectionDefinitions = useMemo(() => {
    const sectionDefs = props.options?.sectionDefinitions;
    if (sectionDefs?.length > 0) {
      return sectionDefs
        .filter((sectionDef) => !sectionDef.hidden && authorize(sectionDef.auth?.read ?? {}))
        .map((sectionDef) => {
          sectionDef.cards = !sectionDef.cards ? null : sectionDef.cards
            .filter((c) => !c.hidden && authorize(c.auth?.read ?? {}));
          return sectionDef;
        });
    } else {
      return null;
    }
  }, [authorize, props.options?.sectionDefinitions]);

  const tableIsLoading = Boolean(tableProvider?.isLoading());
  const context = useMemo(() => ({
    state: {
      ...internalState,
      ...props.state
    },
    refs,
    cardState,
    cardDefinitions,
    sectionDefinitions,
    graphState,
    dataKey: props.dataKey,
    data: props.data,
    context: props.context,
    view: props.view,
    fieldData: props.fieldData,
    updaters: props.updaters,
    clearState: () => {
      cardState?.clear?.();
      setInternalState(utils.updater({
        showProfile: false,
        showTask: false,
        settings: {}
      }, true));
    },
    edit: () => {
      setInternalState(utils.updater({
        isEditing: true,
        isSubmitting: false,
        validation: null,
        submitSuccess: null,
        submitError: null
      }, true)); // remove state
    },
    close: () => {
      setInternalState(utils.updater({
        isEditing: false
      }, true));
    },
    toggle: () => {
      setInternalState((current) => {
        return utils.updater({
          isEditing: !current.isEditing
        })(current);
      });
    },
    dirty: (isDirty) => {
      setInternalState(utils.updater({
        isDirty
      }, true));
    },
    validation: (validation) => {
      setInternalState(utils.updater({
        validation: validation
      }, true));
    },
    error: (error) => {
      setInternalState(utils.updater({
        isSubmitting: false,
        validation: null,
        submitSuccess: null,
        submitError: error
      }, true));
    },
    success: (success) => {
      setInternalState(utils.updater({
        isSubmitting: false,
        isEditing: false,
        validation: null,
        submitError: null,
        submitSuccess: success
      }, true));
    },
    submit: () => {
      setInternalState(utils.updater({
        isSubmitting: true,
        validation: null,
        submitError: null,
        submitSuccess: null
      }, true));
      refs.current.profile?.submit?.();
    },
    cancel: () => {
      setInternalState(utils.updater({
        isDirty: false,
        isEditing: false,
        isSubmitting: false
      }, true));
      refs.current.profile?.reset?.();
    },
    isLoading: () => {
      return Boolean(tableIsLoading || props.isLoading || !props.data?.data);
    },
    toggleSidebar: (key) => {
      setInternalState((current) => {
        return utils.updater({
          ...current,
          showSidebar: {
            ...current.showSidebar,
            [key]: !current.showSidebar[key]
          }
        })(current);
      });
    },
    openSidebar: (key) => {
      setInternalState((current) => {
        return utils.updater({
          ...current,
          showSidebar: {
            ...current.showSidebar,
            [key]: true
          }
        })(current);
      });
    },
    closeSidebar: (key) => {
      setInternalState((current) => {
        return utils.updater({
          ...current,
          showSidebar: {
            ...current.showSidebar,
            [key]: false
          }
        })(current);
      });
    },
    toggleContext: (key) => {
      setInternalState((current) => {
        return utils.updater({
          ...current,
          showContext: {
            ...current.showContext,
            [key]: !current.showContext[key]
          }
        })(current);
      });
    },
    openContext: (key) => {
      setInternalState((current) => {
        return utils.updater({
          ...current,
          showContext: {
            ...current.showContext,
            [key]: true
          }
        })(current);
      });
    },
    closeContext: (key) => {
      setInternalState((current) => {
        return utils.updater({
          ...current,
          showContext: {
            ...current.showContext,
            [key]: false
          }
        })(current);
      });
    },
    toggleProfile: () => {
      setInternalState((current) => {
        return utils.updater({
          ...current,
          showProfile: !current.showProfile
        })(current);
      });
    },
    openProfile: (settings) => {
      setInternalState((current) => {
        return utils.updater({
          ...current,
          showProfile: true,
          settings: {
            ...current.settings,
            ...settings
          }
        })(current);
      });
    },
    closeProfile: () => {
      setInternalState(utils.updater({showProfile: false}, true));
    },
    toggleTask: () => {
      setInternalState((current) => {
        return utils.updater({
          ...current,
          showTask: !current.showTask
        })(current);
      });
    },
    openTask: (id) => {
      setInternalState(utils.updater({showTask: id}, true));
    },
    closeTask: () => {
      setInternalState(utils.updater({showTask: false}, true));
    },
    setSettings: (settings) => {
      if (!utils.isFunction(settings)) {
        setInternalState((current) => {
          return utils.updater({
            ...current,
            settings: {
              ...current.settings,
              ...settings
            }
          })(current);
        });
      } else {
        setInternalState((current) => {
          return utils.updater({
            ...current,
            settings: settings(current.settings)
          })(current);
        });
      }
    },
    setProfile: (profile) => {
      refs.current.profile = profile;
    }
  }), [internalState, cardState, cardDefinitions, sectionDefinitions, graphState,
    props.dataKey, props.view, props.updaters, props.fieldData, props.data, props.context, props.state,
    tableIsLoading, props.isLoading]);

  useLayoutEffect(() => {
    if (viewInitialised.current !== props.view) {
      viewInitialised.current = props.view;
      setInternalState(utils.updater({
        showProfile: false,
        showTask: false,
        settings: {}
      }, true));
    }
  }, [props.view]);

  return <ProfileContext.Provider value={context}>
    {props.children}
  </ProfileContext.Provider>
};

ProfileProvider.propTypes = {
  view: PropTypes.object,
  dataKey: PropTypes.string,
  data: PropTypes.object,
  context: PropTypes.object,
  options: PropTypes.object,
  fieldData: PropTypes.object,
  state: PropTypes.object,
  updaters: PropTypes.object,
  isLoading: PropTypes.bool,
  isDefinitionsLoading: PropTypes.bool
}

export default ProfileProvider;
