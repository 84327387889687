import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import GraphCard from 'components/molecules/Cards/GraphCard/GraphCard';

const StyledCreditWalletGraphCard = styled(GraphCard)`
  display: flex;
  flex-direction: column;

  .GraphCard-content {
    padding-bottom: ${props => props.theme.spacing(3)};
  }

  .CardContent {
    .CreditWalletGraphCard {
      &-chart {
        height: 100%;
        width: 100%;
        overflow: hidden;

        display: flex;
        align-items: center;
        gap: ${props => props.theme.layout('5sp')};
        
        .CreditWalletPieChart {
          width: 39.6%;
        }
      }
      
      &-description {
        flex-grow: 1;
        
        .H6 {
          font-weight: 700;
        }

        ${props => props.theme.breakpoints.down('lg')} {
          > * {
            font-size: 50%;
          }
        }
        ${props => props.theme.breakpoints.down('md')} {
          > * {
            font-size: 100%;
          }
        }
      }
    }
    
    .CreditWalletPieChart {
      &-chart {
        ${props => props.theme.breakpoints.down('lg')} {
          margin: 0 ${props => props.theme.spacing(0)};
        }
      }
      
      &-center {
        ${props => props.theme.breakpoints.down('lg')} {
          transform: scale(0.75);
        }
        ${props => props.theme.breakpoints.down('md')} {
          transform: scale(1);
        }
        ${props => props.theme.breakpoints.down('sm')} {
          transform: scale(0.75);
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledCreditWalletGraphCard.propTypes = {
  theme: PropTypes.object
};

StyledCreditWalletGraphCard.defaultProps = {}

export default StyledCreditWalletGraphCard;
