import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import {withMemo} from 'helpers/wrapper';
import StyledQuestionnaireTableCell
  from 'components/molecules/TableCells/QuestionnaireTableCell/QuestionnaireTableCell.styles';
import Edit from '@mui/icons-material/Edit';
import constants from 'helpers/constants';
import Visibility from '@mui/icons-material/Visibility';

const QuestionnaireTableCell = withMemo(React.forwardRef((props, ref) => {
  const {
    info,
    value,
    question,
    canUpdate,
    disabled,
    active,
    onClick,
    ...innerProps
  } = useComponentProps(props, 'QuestionnaireTableCell');

  const fields = useMemo(() => {
    return [{
      label: question.question,
      inlineLabel: question.question.toLowerCase(),
      type: info.numeric ? constants.formFieldTypes.text : constants.formFieldTypes.list,
      initial: (info.numeric && utils.isArray(value)) ? value[0]?.value : (value?.value ?? value),
      options: info.options,
      FormFieldProps: info.numeric ? null : {
        size: 'smaller',
        multiple: info.multiselect,
        hiddenIcons: false,
        readOnlyChip: !info.yesNo,
        deselect: true,
        ChipProps: {
          size: 'medium',
          variant: 'outlined',
          color: 'secondary'
        },
        ChipListProps: {
          variant: 'compact'
        }
      }
    }];
  }, [info, value, question]);

  const onClickEvent = useEffectEvent(onClick);
  const action = useMemo(() => {
    if (!active && onClickEvent) {
      return {
        label: canUpdate ? 'Edit' : 'View',
        tooltip: canUpdate ? 'Edit' : 'View',
        icon: canUpdate ? Edit : Visibility,
        auth: disabled ? utils.createAuth({attribute: 'system.null'}) : null,
        onClick: (e) => {
          onClickEvent?.(e)
        }
      }
    }
  }, [canUpdate, disabled, active, onClickEvent]);

  return <StyledQuestionnaireTableCell ref={ref} {...innerProps}
                                       fields={fields}
                                       action={action} />
}));

QuestionnaireTableCell.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  info: PropTypes.object,
  value: PropTypes.any,
  question: PropTypes.object,
  canUpdate: PropTypes.bool,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  onClick: PropTypes.func
};

QuestionnaireTableCell.defaultProps = {
};

export default QuestionnaireTableCell;


