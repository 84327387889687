import React, {useEffect, useImperativeHandle, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import StyledCardItem from 'components/atoms/Cards/Carditem/CardItem.styles';
import utils from 'helpers/utils';
import Skeleton from 'components/atoms/Skeletons/Skeleton/Skeleton';
import {withMemo} from 'helpers/wrapper';

const CardItem = withMemo(React.forwardRef((props, ref) => {
  const {
    anchor,
    span,
    grow,
    fit,
    dragging,
    onResize,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'CardItem', {
    styled: ['grid'],
    static: ['grow', 'fit', 'dragging'],
    variable: ['span', 'rows', 'anchor']
  });

  const innerRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  const onResizeEvent = useEffectEvent(onResize);
  useEffect(() => {
    if (onResizeEvent) {
      return utils.observeResize(innerRef.current, onResizeEvent);
    }
  }, [onResizeEvent]);

  innerProps.as = isLoading ? Skeleton : innerProps.as;

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledCardItem ref={innerRef} {...innerProps}>
    {innerProps.children}
  </StyledCardItem>
}));

CardItem.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  anchor: PropTypes.string,
  span: PropTypes.number,
  grow: PropTypes.bool,
  fit: PropTypes.bool,
  dragging: PropTypes.bool,
  isLoading: PropTypes.bool,
  onResize: PropTypes.func
};

CardItem.defaultProps = {
  grow: true
};

export default CardItem;
