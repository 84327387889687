import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Page from 'components/pages/Base/Page/Page';

const StyledCollectionsPage = styled(Page)`
  .CollectionsPage { 
    &-header {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      flex-wrap: nowrap;
      padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(3)};
      
      .CollectionsTableBar {
        background-color: transparent;
      }
    }
    
    &-table {
      .TableCell-labels {
        .FieldTableCell {
          align-items: center;
        }
        
        .ChipList { 
          height: unset;
          
          &.ChipList-compact {
            align-items: center;
            flex-wrap: nowrap;
          }
        }
      }
    }
  }
    
  ${ComponentStyles}
`;

StyledCollectionsPage.propTypes = {
  theme: PropTypes.object
};

StyledCollectionsPage.defaultProps = {
};

export default StyledCollectionsPage;
