import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Dialog from 'components/organisms/Dialogs/Dialog/Dialog';

const StyledCollectionSelectionDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: clamp(0px, ${props => props.theme.layout(444)}, 86vw);
    max-width: clamp(0px, ${props => props.theme.layout(444)}, 86vw);
    height: clamp(0px, ${props => props.theme.layout(525)}, 86vh);
    max-height: clamp(0px, ${props => props.theme.layout(525)}, 86vh);
  }
  
  .DialogHeader {
    border-bottom: unset;
  }
  
  .DialogContent {
    padding: 0;
    overflow: hidden;
    position: relative;
  }
  
  .CollectionSelectionDialog {
    &-form {
      height: 100%;
      .Form-fields {
        height: 100%;
        
        .ListField {
          &-search {
            padding: 0 ${props => props.theme.spacing(2)} ${props => props.theme.spacing(1)} ${props => props.theme.spacing(2)};
            margin: 0;
            border-bottom: 1px solid ${props => props.theme.property('palette.divider')};
          }
          
          .List {
            overflow: auto;
            width: unset;
            
            margin: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(2)} 0 ${props => props.theme.spacing(2)};
            .ListItem:last-child {
              padding-bottom: ${props => props.theme.spacing(1)};
            }
          }
        }
      }
    }
    
    &-info {
      ${props => props.theme.font2Css('body2')};
      flex-grow: 1;
    }

    &-footer {
      .Tooltip-wrap:first-of-type {
        order: 1 !important;
      }
      .DialogFooter-info {
        order: 2 !important;
      }
      .Button:first-of-type {
        order: 3 !important;
      }
      .Button:last-of-type {
        order: 4 !important;
      }
    }
    
    &-overflow {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: absolute;
      bottom: 0;
      pointer-events: none;

      height: ${props => props.theme.layout(48)};
      background: linear-gradient(0deg, ${props => props.theme.property('palette.background.paper')} 0%, rgba(0, 0, 0, 0) 100%);
    }
  }
  ${ComponentStyles}
`;

StyledCollectionSelectionDialog.propTypes = {
  theme: PropTypes.object
};

StyledCollectionSelectionDialog.defaultProps = {}

export default StyledCollectionSelectionDialog;
