import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ButtonTableCell from 'components/molecules/TableCells/ButtonTableCell/ButtonTableCell';

const StyledRelevancyTableCell = styled(ButtonTableCell)`
  .Button-size-medium {
    min-width: ${props => props.theme.layout(112)};
    max-width: ${props => props.theme.layout(112)};
  }
  .Button-size-small {
    min-width: ${props => props.theme.layout(67)};
  }
  
  ${ComponentStyles}
`;

StyledRelevancyTableCell.propTypes = {
  theme: PropTypes.object
};

StyledRelevancyTableCell.defaultProps = {}

export default StyledRelevancyTableCell;
