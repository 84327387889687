import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledFieldMappingsForm = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${props => props.theme.layout('1sp')};
  
  > .List {
    padding: 0;
    .ListItem {
      padding: 0;
      max-width: 100%;
    }
    .Button {
      
    }
  }
  ${ComponentStyles}
`;

StyledFieldMappingsForm.propTypes = {
  theme: PropTypes.object
};

StyledFieldMappingsForm.defaultProps = {}

export default StyledFieldMappingsForm;
