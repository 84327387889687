import {css} from 'styled-components';

/* Test styles are used for components with text, spans labels, buttons etc. */
const FormStyles = css`
  .InlineForm {
    .FormControl-inlineLabel {
      > .InputLabel, > .FormLabel {
        min-width: ${props => props.theme.layout(140)};
        max-width: ${props => props.theme.layout(140)};
        
        &:has(.FormField-labelPostfix) {
          > *:not(.FormField-labelPrefix, .FormField-labelPostfix) {
            flex-grow: 1;
          }
        }

        .FormField-labelPrefix:has(.Icon) {
          display: inline-flex;
          align-items: flex-start;
          margin-top: ${props => props.theme.layout(1.5)};
          margin-right: ${props => props.theme.layout('0.5sp')};
        }
        
        .FormField-labelPostfix:has(.Icon) {
          display: inline-flex;
          align-items: flex-start;
          margin-top: ${props => props.theme.layout(1.5)};
          margin-left: ${props => props.theme.layout('0.5sp')};
        }
      }
      
      &:not(.FormControl-readOnly) {
        > .InputLabel, > .FormLabel {
          margin-top: ${props => props.theme.layout(10)};
        }

        &.CheckboxField {
          > .InputLabel, > .FormLabel {
            margin-top: ${props => props.theme.layout(10)};
          }
        }
        
        &.SwitchField {
          > .InputLabel, > .FormLabel {
            margin-top: ${props => props.theme.layout(7)};
          }
        }

        &.ListField {
          > .InputLabel, > .FormLabel {
            margin-top: ${props => props.theme.layout(6)};
          }
        }
      }
      
      &.CheckboxField, &.SwitchField {
        > .InputLabel, > .FormLabel {
          align-self: flex-start;
        }

        .InputContainer {
          justify-content: flex-start !important;
        }
      }
    }
    
    .FormControl-staticLabel {
      .FormLabel, .InputLabel {
        width: calc(100%);
        max-width: calc(100%);
        ${props => props.theme.font2Css('body2')};
        transform: scale(1);

        .FormField-labelPrefix:has(.Icon) {
          display: inline-flex;
          align-items: flex-start;
          margin-top: ${props => props.theme.layout(4)};
          margin-right: ${props => props.theme.layout('0.5sp')};
        }

        .FormField-labelPostfix:has(.Icon) {
          display: inline-flex;
          align-items: flex-start;
          margin-top: ${props => props.theme.layout(4)};
          margin-left: ${props => props.theme.layout('0.5sp')};
        }
      }
      
      &-readOnly {
        .FormLabel, .InputLabel {
          .FormField-labelPrefix:has(.Icon), .FormField-labelPostfix:has(.Icon) {
            margin-top: ${props => props.theme.layout(1.5)};
          }
        }
      }
    }

    .FormControl {
      > .InputLabel, > .MuiFormControlLabel-label, > .FormLabel  {
        ${props => props.theme.font2Css('subtitle2')};
      }

      .Input-readOnly {
        ${props => props.theme.font2Css('body2')};
      }
    }
  }

  ${props => props.theme.breakpoints.down('md')} {
    .InlineForm {
      .FormControl-inlineLabel {
        > .InputLabel, > .FormLabel {
          min-width: ${props => props.theme.layout(104)};
          max-width: ${props => props.theme.layout(104)};
        }
      }
    }
  }
  
  // overrides
  ${props => props.theme.property('mixins.FormStyles', '')}
  ${props => props.theme.convert2Css('components.FormStyles.styleOverrides.root')}
`

export default FormStyles;
