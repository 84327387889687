import React, {useEffect, useLayoutEffect, useMemo, useState} from 'react'
import PropTypes from 'prop-types';
import system from 'helpers/system';
import utils from 'helpers/utils';
import {useThemeControl} from 'components/organisms/Providers/ThemeProvider/ThemeProvider';
import {useLocation} from 'react-router-dom';
import {useAuthClient, useAuthTeam} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import {useEffectEvent, useEffectItem} from 'helpers/hooks/utils';

export const ClientContext = React.createContext(null);

export function useClientControl () {
  return React.useContext(ClientContext);
}

export function useClientConfigType () {
  const location = useLocation();

  return (['app', 'portal'].find((type) => {
    return location.pathname.toLowerCase().split('/').filter((_) => (_))[0] === type;
  })) ?? 'app';
}

export function useClientConfig (name) {
  const type = useClientConfigType();
  const context = React.useContext(ClientContext);

  useLayoutEffect(() => {
    if (context) {
      const paths = context.getPaths(type);
      if (paths && !context.getConfig(type, name)) {
        const clientPath = paths.client;
        const teamPath = paths.team;

        const getClientConfig = async (path) => {
          if (path) {
            return import(`clients/${path}/configs/${name}.config.json`).then((gs) => {
              let runningConfig = {...gs?.default, path};
              return import(`clients/${path}/configs/${name}.${system.getEnvironment()}.config.json`)
                .then((gs) => {
                  return utils.mergeObjects(runningConfig, gs?.default);
                })
                .catch(() => {
                  return runningConfig;
                });
            }).catch(() => {
              return {};
            });
          } else {
            return {};
          }
        }

        let runningConfig = null;
        getClientConfig(clientPath)
          .then((config) => {
            runningConfig = config;
            return getClientConfig(teamPath)
              .then((config) => {
                runningConfig = config ? utils.mergeObjects(runningConfig, config) : runningConfig;
              })
          })
          .finally(() => {
            if (runningConfig) {
              context.setConfig(type, name, runningConfig);
            }
          });
      }
    }
  }, [context, name, type]);

  return context?.getConfig(type, name);
}

export function useClientComponent (config, path, props) {
  const [component, setComponent] = useState(null);

  const propsMemo = useEffectItem({...props, config});
  useEffect(() => {
    if (config?.custom) {
      import(`clients/${config.path}/components/${path}`).then((gs) => {
        const Custom = gs?.default;
        setComponent(<Custom {...propsMemo} />);
      });
    } else {
      setComponent(null);
    }
  }, [config?.custom, config?.path, path, propsMemo]);

  return component;
}

const ClientProvider = (props) => {
  const client = useAuthClient();
  const team = useAuthTeam();
  const type = useClientConfigType();

  const themeControl = useThemeControl();

  const [internalState, setInternalState] = useState({});

  const context = useMemo(() => ({
    getPaths: (type) => {
      return internalState.paths?.[type];
    },
    setOverride: (override) => {
      setInternalState(utils.updater({override}, true));
    },
    getConfig: (type, name) => {
      return internalState.configs?.[type]?.[name];
    },
    setConfig: (type, name, value) => {
      setInternalState((current) => ({
        ...current,
        configs: {
          ...current?.configs,
          [type]: {
            ...current?.configs?.[type],
            [name]: value
          }
        }
      }));
    }
  }), [internalState]);

  useLayoutEffect(() => {
    if ((internalState.override?.client ?? client?.name) && (internalState.override?.team ?? team?.name)) {
      const paths = {};
      Promise.all(['app', 'portal'].map((type) => {
        const runningPaths = {};
        return import(`clients/${utils.cleanFilename(internalState.override?.client ?? client?.name)}/${utils.cleanFilename(internalState.override?.team ?? team?.name)}/${type}`)
          .then(() => {
            runningPaths.team = `${utils.cleanFilename(internalState.override?.client ?? client?.name)}/${utils.cleanFilename(internalState.override?.team ?? team?.name)}/${type}`;
          })
          .catch(() => {
            /* SQUASH */
          })
          .finally(() => {
            return import(`clients/${utils.cleanFilename(internalState.override?.client ?? client?.name)}/${type}`)
              .then(() => {
                runningPaths.client = `${utils.cleanFilename(internalState.override?.client ?? client?.name)}/${type}`;
              })
              .catch(() => {
                /* SQUASH */
              })
              .finally(() => {
                paths[type] = runningPaths;
              });
          })
      }))
        .then(() => {
          setInternalState((current) => ({
            override: current.override,
            paths: paths
          }));
        })
    }
  }, [client?.name, team?.name, internalState.override?.client, internalState.override?.team]);

  useLayoutEffect(() => {
    if (internalState.paths) {
      Object.keys(internalState.paths).forEach((type) => {
        const clientPath = internalState.paths[type].client;
        const teamPath = internalState.paths[type].team;

        const getClientStyles = async (path) => {
          if (path) {
            return import(`clients/${path}/Global.styles`).then((gs) => {
              let runningStyles = gs?.default;
              return import(`clients/${path}/Global.${system.getEnvironment()}.styles`)
                .then((gs) => {
                  return gs?.default;
                })
                .catch(() => {
                  return runningStyles;
                })
            }).catch(() => {
              return null;
            });
          } else {
            return null;
          }
        }

        let runningStyles = null;
        getClientStyles(clientPath)
          .then((styles) => {
            runningStyles = styles;
            return getClientStyles(teamPath)
              .then((styles) => {
                runningStyles = styles ?? runningStyles;
              })
          })
          .finally(() => {
            setInternalState((current) => ({
              ...current,
              styles: {
                ...current?.styles,
                [type]: runningStyles
              }
            }));
          });

        const getClientTheme = async (path, name) => {
          if (path) {
            return import(`clients/${path}/theme/${name}/theme.js`).then((gs) => {
              let runningTheme = gs?.default;
              return import(`clients/${path}/theme/${name}/theme.${system.getEnvironment()}.js`)
                .then((gs) => {
                  return utils.mergeObjects(runningTheme, gs?.default);
                })
                .catch(() => {
                  return runningTheme;
                })
            }).catch(() => {
              return null;
            });
          } else {
            return null;
          }
        }

        ['light', 'dark'].forEach((name) => {
          let runningTheme = null;
          getClientTheme(clientPath, name)
            .then((theme) => {
              runningTheme = theme;
              return getClientTheme(teamPath, name)
                .then((theme) => {
                  runningTheme = theme ? utils.mergeObjects(runningTheme, theme) : runningTheme;
                })
            })
            .finally(() => {
              if (runningTheme) {
                setInternalState((current) => ({
                  ...current,
                  themes: {
                    ...current?.themes,
                    [type]: [
                      ...(current?.themes?.[type] ?? []),
                      runningTheme
                    ]
                  }
                }));
              }
            });
        });
      })
    }
  }, [internalState.paths]);

  const typeThemes = internalState.themes?.[type];
  const addThemesEvent = useEffectEvent(themeControl.addThemes);
  const resetThemesEvent = useEffectEvent(themeControl.resetThemes);
  useEffect(() => {
    if (typeThemes) {
      addThemesEvent?.(typeThemes);
    } else {
      resetThemesEvent?.();
    }
  }, [typeThemes, addThemesEvent, resetThemesEvent]);

  const Styles = internalState.styles?.[type];
  return <ClientContext.Provider value={context}>
    {Styles ? <Styles /> : null}
    {props.children}
  </ClientContext.Provider>
};

ClientProvider.propTypes = {
  theme: PropTypes.any
}

export default ClientProvider;
