import {useMemo} from 'react';
import utils from 'helpers/utils';
import {
  useCollectionQuestionnaireList
} from 'services/collection/questionnaire/questionnaire.hooks';
import {useCollectionQuestionList} from 'services/collection/question/question.hooks';
import constants from 'helpers/constants';

export function useQuestionnaireQuestions (collection) {
  const questionnaires = useCollectionQuestionnaireList({
    collectionId: collection?.collectionId,
    filter: [{id: 'active', value: true}],
    sort: [{id: 'startAt', desc: false}, {id: 'endAt', desc: false}, {id: 'createdAt', desc: false}],
    page: 0,
    pageSize: 1000,
    minimal: false
  }, {
    enabled: Boolean(collection?.collectionId)
  });

  const questionIds = useMemo(() => {
    if (questionnaires.data) {
      return utils.uniqueArray(
        questionnaires.data
          .reduce((a, qn) => a.concat(qn.questionIds), [])
          .sort((a, b) => +a - +b));
    } else {
      return [];
    }
  }, [questionnaires.data]);

  const questionsEnabled = collection?.collectionId > 0 && questionIds.length > 0;
  const questions = useCollectionQuestionList({
    collectionId: collection?.collectionId,
    filter: [{id: 'questionIds', value: questionIds}],
    page: 0,
    pageSize: questionIds.length
  }, {
    enabled: questionsEnabled
  });

  const questionnaireQuestions = useMemo(() => {
    if (collection && questionnaires.data && questions.data) {
      return questionnaires.data.reduce((a, questionnaire) => {
        (questionnaire.questionIds ?? []).forEach((qId, qIdx) => {
          const question = questions.data.find((q) => +q.questionId === +qId);
          if (question) {
            let q = a.find((q) => +q.questionId === +qId);
            if (!q) {
              const info = { options: [] };

              if (question.analyserType === constants.collection.analyserTypes.collectionTagAnalyser) {
                let tagGroup = collection.tagGroups.find((tg) => +tg.groupId === +question.analyserParams.tagGroup);

                if (tagGroup) {
                  info.multiselect = tagGroup.multiselect;
                  info.options = (tagGroup.tags || []).map((t) => {
                    return {
                      label: t.value,
                      value: t.value,
                      tagId: t.tagId
                    }
                  });
                }
              } else {
                info.yesNo = true;
                info.options = constants.data.toggleYesNo
              }

              info.numeric = !info.multiselect && info.options
                .reduce((isNum, o) => isNum && utils.isNumber(o.label), true);
              info.longestWord = info.options
                .map((o) => o.label.toString().split(/\s+/).reduce((len, s) => len < s.length ? s.length : len, 0))
                .reduce((len, l) => (len < l) ? l : len, 0);
              info.longestLabel = info.options
                .reduce((len, o) => len < o.label.toString().length ? o.label.toString().length : len, 0);

              q = {
                questionId: +question.questionId,
                questionnaires: [],
                info: info,
                question,
                position: qIdx
              }
              a.push(q);
            }
            q.questionnaires.push(questionnaire);
          }
        });

        return a;
      }, [])
        .sort((a, b) => a.position - b.position)
    }
  }, [collection, questionnaires.data, questions.data]);

  return [
    questionnaireQuestions, 
    questionnaires.status.isLoading || questions.status.isLoading,
    questionnaires.status.isSuccess && (!questionsEnabled || questions.status.isSuccess)
  ];
}
