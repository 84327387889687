import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledFieldInline = styled(Box)`
  .Input-readOnly {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    > * {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  
  .FieldInline {
    &-content {
      width: 100%;
      max-width: fit-content;
      
      display: flex;
      align-items: center;
      justify-content: center;
      gap: ${props => props.theme.spacing(1)};
    }

    &-field {
      .ChipList {
        height: 100%
      }
    }
    
    &-edit {
      display: none;
      align-self: center;
      justify-self: flex-end;
      margin-left: ${props => props.theme.layout('0.5sp')};
      color: ${props => props.theme.property('palette.text.disabled')};
    }
  }

  &:not(:has(.FieldInline-field)) {
    justify-content: flex-end;
  }

  &:has(.ChipList-compact) {
    .FieldInline-edit {
      display: block;
      visibility: hidden;
    }
  }

  &.FieldInline-canUpdate {
    .FieldInline-content:hover {
      cursor: pointer;
    }
    &:hover {
      .FieldInline-edit {
        display: block;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledFieldInline.propTypes = {
  theme: PropTypes.object
};

StyledFieldInline.defaultProps = {}

export default StyledFieldInline;

