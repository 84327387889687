import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledSuggestionField from 'components/molecules/Fields/SuggestionField/SuggestionField.styles';
import SuggestionCard from 'components/molecules/Cards/SuggestionCard/SuggestionCard';
import SuggestionFieldExplanation from 'components/molecules/Fields/SuggestionField/SuggestionFieldExplanation';
import Button from 'components/atoms/Buttons/Button/Button';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import Icon from 'components/atoms/Icons/Icon/Icon';
import {withMemo} from 'helpers/wrapper';

const SuggestionField = withMemo(React.forwardRef((props, ref) => {
  const {
    options,
    explanation,
    renderOption,
    ButtonProps,
    SuggestionCardProps,
    ...innerProps
  } = useComponentProps(props, 'SuggestionField');

  const optionsMemo = useMemo(() => {
    if (explanation) {
      const addExplanation = (opts) => {
        if (opts?.length > 0) {
          return [{
            label: explanation,
            value: null,
            explanation: true,
            disabled: true,
            alwaysVisibleOption: true,
          }, ...opts];
        } else {
          return opts;
        }
      }

      if (!utils.isFunction(options)) {
        return addExplanation(options);
      } else {
        return ({search, ids, filter, callback}) => {
          options({search, ids, filter, callback: (results) => {
            callback(addExplanation(results))
          }});
        }
      }
    } else {
      return options
    }
  }, [options, explanation]);

  const handleRenderOption = (option, state, props) => {
    if (option.explanation) {
      return <SuggestionFieldExplanation className="SuggestionField-explanation"
                                         explanation={option.label}/>
    } else {
      if (renderOption) {
        return renderOption(option, state, props)
      } else {
        return <SuggestionCard title={option.title ?? option.label}
                               subtitle={option.subtitle ?? option.helper}
                               link={option.link}
                               number={option.number}
                               icon={option.icon}
                               chip={option.chip}
                               button={option.button}
                               fullWidth={true}
                               fullHeight={true}
                               selected={props.checked}
                               disabled={props.disabled}
                               {...SuggestionCardProps}/>
      }
    }
  };

  const handleRenderAdornment = (props) => {
    const {
      open,
      options,
      readOnly,
      disabled,
      isLoading,
      onClick,
      renderedAdornment
    } = props;

    if (isLoading) {
      return renderedAdornment;
    } else if (!innerProps.hideOpenClose) {
      return <Button className="SuggestionField-adornment"
                     tabIndex={-1}
                     variant="outlined"
                     size={innerProps.size}
                     endIcon={<Icon icon={open ? ArrowDropUp : ArrowDropDown}/>}
                     onClick={onClick}
                     disabled={!(options?.length > 0) || readOnly || disabled}
                     {...ButtonProps}>
        {`${options?.length ?? 0}${innerProps.size === 'smaller' ? '' : ' suggestions'}`}
      </Button>
    }
  };

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledSuggestionField ref={ref} {...innerProps}
                                options={optionsMemo}
                                renderOption={handleRenderOption}
                                renderInputAdornment={handleRenderAdornment}
                                MenuItemProps={{
                                  style: {
                                    padding: '0',
                                    opacity: 1
                                  }
                                }}
                                hiddenSelectionHelper={true}/>
}));

SuggestionField.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  options: PropTypes.any,
  explanation: PropTypes.any,
  renderOption: PropTypes.func,
  ButtonProps: PropTypes.object,
  SuggestionCardProps: PropTypes.object
};

SuggestionField.defaultProps = {
  size: 'small'
};

export default SuggestionField;
