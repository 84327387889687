import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import BarChart from 'components/organisms/Charts/BarChart/BarChart';
import Legend from 'components/molecules/Charts/Legend/Legend';
import Box from 'components/atoms/Layout/Box/Box';
import StyledLatestNewsBarChart from 'components/organisms/Charts/LatestNewsBarChart/LatestNewsBarChart.styles';
import constants from 'helpers/constants';
import BreakdownTooltip from 'components/molecules/Tooltips/BreakdownTooltip/BreakdownTooltip';

const LatestNewsBarChart = React.forwardRef((props, ref) => {
  const {
    news,
    showLegend,
    isLoading,
    visibility,
    onVisibilityChange,
    onClick,
    BarChartProps,
    ...innerProps
  } = useComponentProps(props, 'LatestNewsBarChart', {
    children: ['chart', 'legend']
  });

  const [internalState, setInternalState] = useState({});

  const [legend, rows] = useMemo(() => {
    let legend = [];
    if (news) {
      legend = constants.data.articleLabels
        .map((al, idx) => {
          const n = news.find((n) => n.label?.label?.toLowerCase() === al.value.toLowerCase());

          if (n) {
            return {
              id: n?.labelId,
              position: idx,
              label: al.label,
              color: innerProps.theme.property(`palette.${al.graphColor}`),
              active: visibility?.[n?.labelId] ?? true,
              meta: al
            }
          } else {
            return null;
          }
        }).filter((_) => (_));
    }

    const rows = legend.length;

    return [legend, rows];
  }, [news, visibility, innerProps.theme]);

  const newsData = useMemo(() => {
    if (news) {
      return {
        dataKey: 'name',
        bars: constants.data.articleLabels
          .map((al, idx) => {
            const n = news.find((n) => n.label?.label?.toLowerCase() === al.value.toLowerCase());

            return {
              id: n?.labelId,
              position: idx,
              dataKey: n?.labelId,
              name: n?.labelId,
              label: al.label,
              color: innerProps.theme.property(`palette.${al.graphColor}`)
            }
          })
          .filter((al) => {
            return Boolean(legend.find((l) => l.active && +l.id === +al.id))
          }),
        data: news
          .sort((a, b) => (new Date(b.month)).getTime() - (new Date(a.month)).getTime())
          .reduce((a, n, idx) => {
            let label = constants.data.articleLabels
              .find((al) => n.label?.label?.toLowerCase() === al.value.toLowerCase());

            let item = a.find((i) => i.month === n.month);
            if (!item) {
              item = {
                month: n.month,
                name: utils.dayjs(new Date(n.month)).format('MMM'),
                position: idx
              };
              a.push(item);
            }

            if (label && +n.labelId > 0) {
              item[n.labelId] = n.count;
              item[`${n.labelId}-meta`] = {
                ...n.label
              }
            }
            return a;
          }, []),
        TooltipComponent: BreakdownTooltip
      };
    }
  }, [news, legend, innerProps.theme]);

  const handleLegendEnter = (e, item) => {
    if (item.active) {
      setInternalState(utils.updater({hoveredId: item.id}, true));
    }
  }

  const handleLegendLeave = () => {
    setInternalState(utils.updater({hoveredId: null}, true));
  }

  const handleLegendClick = (e, item) => {
    const newVisibility = {
      ...visibility,
      [item.id]: !(visibility?.[item.id] ?? true)
    }

    if (!legend.find((l) => newVisibility[l.id] ?? true)) {
      onVisibilityChange?.({});
    } else {
      onVisibilityChange?.(newVisibility);
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledLatestNewsBarChart ref={ref} {...innerProps}>
    <Box className="LatestNewsBarChart-chart">
      <BarChart {...newsData}
                showGrid
                showTooltip
                showCursor
                isLoading={isLoading}
                radius={4}
                barSize={16}
                layout="vertical"
                hoveredId={internalState.hoveredId}
                onClick={onClick}
                TooltipProps={{
                  density: 'denser',
                  placement: 'right'
                }}
                margin={{
                  top: 0, right: 0, bottom: 4, left: -22
                }}
                {...BarChartProps}/>
    </Box>
    {showLegend ? <Legend className="LatestNewsBarChart-legend"
                          orientation="vertical"
                          onClick={onVisibilityChange ? handleLegendClick : null}
                          onMouseEnter={handleLegendEnter}
                          onMouseLeave={handleLegendLeave}
                          legend={legend}
                          isLoading={isLoading}
                          rows={rows}/> : null}
  </StyledLatestNewsBarChart>
});

LatestNewsBarChart.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  news: PropTypes.array,
  showLegend: PropTypes.bool,
  isLoading: PropTypes.bool,
  visibility: PropTypes.object,
  onVisibilityChange: PropTypes.func,
  onClick: PropTypes.func,
  BarChartProps: PropTypes.object
};

LatestNewsBarChart.defaultProps = {
};

export default LatestNewsBarChart;
