import {resolvePath, useHref, useLinkClickHandler, useLocation, useNavigate} from 'react-router-dom';
import utils from 'helpers/utils';
import {useCallback, useMemo} from 'react';
import {useEffectEvent, useUpdatedRef} from 'helpers/hooks/utils';
import dom from 'helpers/dom';

export function linkModifier (e) {
  const modKey = ['macos', 'ios'].includes(dom.getOS()) ? e.metaKey : e.ctrlKey;
  return (modKey || e.shiftKey);
}

export function eventLink (e, url, external = false) {
  if (url && e) {
    const modKey = ['macos', 'ios'].includes(dom.getOS()) ? e.metaKey : e.ctrlKey;
    if (url && (modKey || e.shiftKey)) {
      if (modKey && e.shiftKey) {
        // open new tab + activate
        if (external) {
          utils.externalLink(url)?.focus();
        } else {
          utils.internalLink(url)?.focus();
        }
        e.modified = true;
        e.preventDefault();
      } else if (modKey) {
        // open new tab
        if (external) {
          utils.externalLink(url);
        } else {
          utils.internalLink(url);
        }
        e.modified = true;
        e.preventDefault();
      } else if (e.shiftKey) {
        // open new window
        if (external) {
          utils.externalLink(url);
        } else {
          utils.internalLink(url, '_window');
        }
        e.modified = true;
        e.preventDefault();
      }
      return true;
    }
  }
}

export function linkTo (navigation, location, resolve = false) {
  let path = (navigation?.to || '');
  if (resolve) {
    const lp = linkPath(navigation, location);
    path = lp.pathname;
  }

  return utils.cleanPathParams(path + (navigation?.keepSearchParams ? location.search : ''));
}

export function useLinkTo (navigation) {
  const location = useLocation();

  return linkTo(navigation, location);
}

export function linkPath (navigation, location) {
  const to = linkTo(navigation, location);
  const relative = navigation?.relative || !to.startsWith('/');

  return resolvePath(to, relative ? location.pathname : '/');
}

export function useLinkPath (navigation) {
  const location = useLocation();

  return linkPath(navigation, location);
}

export function useSplitPath (splitter) {
  const location = useLocation();
  return useMemo(() => {
    return utils.splitPath(location.pathname, splitter);
  }, [location.pathname, splitter]);
}

export function matchLocation (navigation, location) {
  const path = linkTo({...navigation, keepSearchParams: false}, location);

  return utils.comparePath(path, location.pathname) &&
    utils.compare(navigation.state, location.state);
}

export function matchPath (navigation, location) {
  const path = linkTo({...navigation, keepSearchParams: false}, location, true);

  return utils.comparePath(path, location.pathname, navigation.end === false)
}

export function linkActive (navigation, location) {
  return matchPath(navigation, location);
}

export function useLinkActive (navigation = {}) {
  const location = useLocation();

  return linkActive(navigation, location);
}

export function useLinkNavigate () {
  const location = useLocation();
  const navigate = useNavigate();

  const navigateEvent = useEffectEvent(navigate);
  const locationRef = useUpdatedRef(location);
  return useCallback((navigation) => {
    const handledEvent = eventLink(navigation.event, linkTo(navigation, locationRef.current, true));

    if (!handledEvent) {
      if (navigation.force || !matchLocation(navigation, locationRef.current)) {
        const path = linkTo(navigation, locationRef.current);
        const state = {
          from: navigation.resetState ? null : locationRef.current,
          resetSearchParams: navigation.resetSearchParams,
          ...navigation.state
        };
        navigateEvent?.(utils.isEmpty(path) ? '/' : path, {state, ...utils.filterObject(navigation, ['to'])});
      }
    }
  }, [navigateEvent, locationRef]);
}

export function useNavLink (navigation = {}) {
  const location = useLocation();
  const to = useLinkTo(navigation);

  const active = useLinkActive(navigation);
  const href = useHref(to, {relative: navigation.relative});

  const state = {
    from: location,
    resetSearchParams: navigation.resetSearchParams,
    ...navigation.state
  };

  const handleLinkClick = useLinkClickHandler(to, {
    replace: navigation.replace,
    state: state,
    target: navigation.target,
    preventScrollReset: navigation.preventScrollReset,
    relative: navigation.relative
  });

  const onClickPrev = navigation.onClick;
  const handleClick = (e) => {
    onClickPrev?.(e);
    if (!e.defaultPrevented) {
      if (!navigation.reloadDocument) {
        const handledEvent = eventLink(e, href);
        if (!handledEvent) {
          handleLinkClick(e);
          e.preventDefault();
        }
      } else if (navigation.target === '_self' || !navigation.target) {
        window.location.href = href;
        e.preventDefault();
      }
    }
  };

  return [handleClick, active, utils.isDefined(navigation?.to) ? href : null];
}
