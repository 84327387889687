import React, {useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useUploadFiles} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import constants from 'helpers/constants';
import Box from 'components/atoms/Layout/Box/Box';
import {P} from 'components/atoms/Text/Typography/Typography';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Search from '@mui/icons-material/Search';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import Clear from '@mui/icons-material/Clear';
import {useEntityPersonList} from 'services/entity/person/person.hooks';
import StyledEntityPersonsProfileCardContent
  from 'components/organisms/Cards/EntityPersonsProfileCardContent/EntityPersonsProfileCardContent.styles';
import PersonsList from 'components/organisms/Lists/PersonsList/PersonsList';
import Add from '@mui/icons-material/Add';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import PersonDialog from 'components/organisms/Dialogs/PersonDialog/PersonDialog';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import {useAuthClientId} from 'services/auth/auth.utils';
import {usePersonEnrich, usePersonImgAdd, usePersonUpdate} from 'services/person/person.hooks';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';
import Delete from '@mui/icons-material/Delete';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';

const EntityPersonsProfileCardContent = React.forwardRef((props, ref) => {
  const {
    card,
    content,
    entity,
    collection,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'EntityPersonsProfileCardContent', {
    static: ['isEditing']
  });

  const innerRef = useRef(null);
  const searchFieldRef = useRef(null);
  const [internalState, setInternalState] = useState({
    search: null,
    open: false,
    persons: null
  });

  useImperativeHandle(ref, () => innerRef.current);

  const isEditing = content.state.isEditing;

  const clientId = useAuthClientId();
  const profileProvider = useProfile();
  const authorize = useAuthorize();
  const snackbar = useSnackbar();
  const dialogControl = useDialogControl();

  const [uploadState, setUploadState, uploadFiles] = useUploadFiles();

  const updatePerson = usePersonUpdate();
  const enrichPerson = usePersonEnrich();
  const addPersonImg = usePersonImgAdd();
  const createPersonEvent = useEffectEvent(profileProvider.updaters?.createPerson);
  const updatePersonEvent = useEffectEvent(profileProvider.updaters?.updatePerson);
  const deletePersonEvent = useEffectEvent(profileProvider.updaters?.deletePerson);
  const setSettingsEvent = useEffectEvent(profileProvider.setSettings);

  const persons = useEntityPersonList({
    entityId: entity?.entityId,
    page: 0,
    pageSize: 10,
    search: internalState.search
  }, {
    ...constants.queryOptions.infinite,
    enabled: Boolean(entity?.entityId) && entity?.personCount > 0
  });

  const isDataLoading = isLoading || persons.status.isLoading;

  const fields = useMemo(() => ([{
    name: 'search',
    label: 'Search',
    inlineLabel: 'search',
    type: constants.formFieldTypes.text,
    validation: constants.formFieldValidationTypes.text,
    initial: internalState.search,
    debounce: constants.debounce.search,
    FormFieldProps: {
      ref: searchFieldRef,
      hiddenLabel: true,
      autoFocus: false,
      size: 'smaller',
      radius: 'round'
    },
    prefix: <Icon icon={Search} />,
    postfix: internalState.search?.length > 0 ? <ActionIconButton action={{
      icon: <Icon icon={Clear}/>,
      onClick: (e) => {
        setInternalState(utils.updater({search: ''}, true));
        searchFieldRef.current?.focus();
        e.preventDefault();
      }
    }} /> : null
  }]), [internalState.search]);

  const action = useMemo(() => ({
    auth: utils.createAuth({attribute: 'entity.person.create'}),
    onClick: (e) => {
      setInternalState(utils.updater({open: true, person: null}, true));
      e.preventDefault();
    },
    label: 'Add employee',
    icon: Add
  }), []);

  const FileFieldProps = useMemo(() => {
    const fileState = uploadState ?
      Object.keys(uploadState).reduce((o, k) => {
        const split = k.split('_');
        o[split[1]] = uploadState[k];
        return o;
      }, {}) : null;

    return {
      FormFieldProps: {
        addDescription: false,
        status: fileState,
        minSize: 1,
        maxSize: constants.numbers.MB
      }
    }
  }, [uploadState]);

  const handleSearch = (field, value) => {
    setInternalState(utils.updater({search: value}, true));
  }

  const handlePersonChange = () => {
    setUploadState({});
  }

  const handlePersonSubmit = (values, lookup) => {
    const isAddNew = !internalState.person;
    const source = internalState.person ?? lookup;

    let changes = {...values};

    if (!utils.isFile(changes.img)) {
      if (utils.isDefined(changes.img?.src)) {
        changes.img = changes.img?.src;
      } else {
        changes.img = '';
      }
    }

    if (source) {
      const realChanges = (Object.keys(changes).length > 1) ? utils.changedFormFields(source, changes) : changes;

      if (isAddNew || +source.clientId !== +clientId) {
        // keep connecting field always
        if (!utils.isEmpty(changes.linkedin)) {
          realChanges.linkedin = changes.linkedin;
        }
        if (!utils.isEmpty(changes.email)) {
          realChanges.email = changes.email;
        }
        if (utils.isEmpty(changes.linkedin) && utils.isEmpty(changes.email)) {
          realChanges.name = changes.name;
        }
      }

      // not changed linking fields, then calc the difference else save all values
      if (!(utils.isDefined(realChanges.linkedin) && !utils.isEmpty(source.linkedin) && realChanges.linkedin !== source.linkedin) &&
          !(utils.isDefined(realChanges.email) && !utils.isEmpty(source.email) && realChanges.email !== source.email)) {
        changes = realChanges;
      } else {
        Object.keys(changes).forEach((k) => {
          if (utils.isEmpty(changes[k])) {
            if ((k !== 'linkedin' || utils.isEmpty(source.linkedin)) && (k !== 'email' || utils.isEmpty(source.email))) {
              delete changes[k];
            }
          }
        });
      }

      if (!isAddNew || +source.clientId === +clientId) {
        changes.personId = source.personId;
      }
    } else {
      // completely new save only non empty values
      Object.keys(changes).forEach((k) => {
        if (utils.isEmpty(changes[k])) {
          delete changes[k];
        }
      });
    }

    if (isAddNew || !utils.isEmpty(changes)) {
      const file = utils.isFile(changes.img) ? changes.img : null;
      if (file) {
        changes.img = source?.img ?? '';
      }

      return ((internalState.person && +internalState.person?.personId === +changes?.personId) ? updatePersonEvent : createPersonEvent)({
        entityId: entity?.entityId, originalId: +internalState.person?.personId, person: changes
      })
        .then((res) => {
          if (file) {
            const person = utils.camelcase(res.response.data.data);

            return addPersonImg.mutation.mutateAsync({
              personId: person?.personId,
              filename: file.name,
              description: file.description,
              filesize: file.size,
              filetype: file.type || constants.filetypes.default
            })
              .then((res) => {
                const filename = res.response.data.data.filename;
                const uploadUrl = res.response.data.data.uploadUrl;
                const downloadUrl = res.response.data.data.downloadUrl;

                return uploadFiles([{file: file, id: 'logo_0', url: uploadUrl, name: filename}], false)
                  .then(() => {
                    return updatePerson.mutation.mutateAsync({personId: +person.personId, img: downloadUrl})
                      .then(() => {
                        setUploadState({});
                      });
                  })
              })
          }
        });
    } else {
      return Promise.resolve();
    }
  }

  const handlePersonDelete = () => {
    return new Promise((resolve, reject) => {
      const handleConfirm = () => {
        return deletePersonEvent({entityId: entity?.entityId, personId: +internalState.person.personId});
      }

      const handleClose = (e, reason) => {
        if (reason !== 'confirmButtonClick') {
          reject(reason);
        } else {
          resolve();
        }
        dialogControl.hide();
      }

      dialogControl.show(<ConfirmDialog question="Are you sure you want to remove this employee?"
                                        onConfirm={handleConfirm}
                                        ConfirmButtonProps={{
                                          children: 'Remove employee',
                                          startIcon: <Icon icon={Delete} />,
                                          color: 'error'
                                        }}/>, true, handleClose);
    });
  }

  const handlePersonClose = () => {
    setInternalState(utils.updater({open: false, person: null}, true));
  }

  const renderDialog = () => {
    if (internalState.open) {
      const canDeletePerson = internalState.person &&
        authorize({attribute: 'entity.person.delete', meta: {person: internalState.person}});

      return <PersonDialog open={true}
                           type="employee"
                           country={entity?.location?.country}
                           person={internalState.person}
                           onChange={handlePersonChange}
                           onSubmit={handlePersonSubmit}
                           onDelete={canDeletePerson ? handlePersonDelete : null}
                           onClose={handlePersonClose}
                           FileFieldProps={FileFieldProps} />
    }
  }

  const renderAddButton = () => {
    return <ActionButton className="EntityPersonsProfileCardContent-action"
                         action={action}
                         variant="text"
                         isLoading={isLoading}/>
  }

  const handlePersonClick = (e, person) => {
    if (authorize({attribute: 'entity.person.update', meta: {person: person}})) {
      setInternalState(utils.updater({open: true, person}, true));
      e.preventDefault();
    }
  }

  const personCardProps = useMemo(() => ({
    onEnrich: (person, type) => {
      const enrichingPersonIds = [];
      const setEnriching = (personId) => {
        if (!enrichingPersonIds.find((id) => +id === +personId)) {
          enrichingPersonIds.push(personId);
          setSettingsEvent?.((current) => ({
            ...current,
            enrichingPersons: {
              ...current?.enrichingPersons,
              [+personId]: current?.enrichingPersons?.[+personId] ?
                current?.enrichingPersons?.[+personId].concat([type]) : [type]
            }
          }));
        }
      }

      setEnriching(person.personId);

      const updatingSnackbar = snackbar.show(`Updating contact info for '${person.name}'...`, null, {
        color: 'info',
        autoHideDuration: null
      });

      const createPerson = () => {
        if (+person.clientId !== +clientId) {
          const changes = {};
          // keep connecting field always
          if (!utils.isEmpty(person.linkedin)) {
            changes.linkedin = person.linkedin;
          }
          if (!utils.isEmpty(person.email)) {
            changes.email = person.email;
          }
          if (utils.isEmpty(person.linkedin) && utils.isEmpty(person.email)) {
            changes.name = person.name;
          }

          return createPersonEvent?.({entityId: entity?.entityId, originalId: +person?.personId, person: changes})
            .then((res) => {
              return utils.camelcase(res.response.data.data);
            })
        } else {
          return Promise.resolve(person);
        }
      }

      createPerson()
        .then((person) => {
          setEnriching(person.personId);

          return enrichPerson.mutation.mutateAsync({
            collectionId: collection?.collectionId,
            personId: person?.personId,
            type: type,
            extra: {
              name: entity?.name,
              hostname: utils.getHostname(entity?.website)
            }
          })
            .then((res) => {
              const person = utils.camelcase(res.response.data.data);

              if (person?.enrichmentStats?.[`${type}Status`] !== 'success') {
                if (person?.enrichmentStats?.[`${type}Status`] !== 'processing') {
                  snackbar.show(`No ${type} info found for '${person.name}'`, null,
                    {color: 'warning', autoHideDuration: constants.delay.warning});
                }
              }
            })
            .catch((error) => {
              if (error?.response?.status === constants.http.status.conflict) {
                if (error?.response?.data?.extra?.field === type) {
                  snackbar.show(`Duplicate ${type} found '${error?.response?.data?.extra?.value}'`, null,
                    {color: 'warning', autoHideDuration: constants.delay.warning});
                } else {
                  snackbar.show(`The contact info update limit has been reached`, null,
                    {color: 'warning', autoHideDuration: constants.delay.warning});
                }
              } else {
                throw error;
              }
            });
        })
        .catch(() => {
          snackbar.show(`Updating contact info for '${person.name}' failed`, null,
            {color: 'error', autoHideDuration: constants.delay.error});
        })
        .finally(() => {
          setSettingsEvent?.((current) => {
            return {
              ...current,
              enrichingPersons: {
                ...current?.enrichingPersons,
                ...(enrichingPersonIds.reduce((o, personId) => {
                  o[+personId] = current?.enrichingPersons?.[+personId] ?
                    current?.enrichingPersons?.[+personId].filter((t) => t !== type) : null
                  return o;
                }, {}))
              }
            }
          });

          snackbar.hide(updatingSnackbar);
        });
    },
    onEnriching: (person, type) => {
      return !type ? profileProvider.state?.settings?.enrichingPersons?.[person.personId]?.length > 0 :
        profileProvider.state?.settings?.enrichingPersons?.[person.personId]?.includes(type);
    },
    onCanEnrich: (person) => {
      return authorize({
        attribute: 'person.enrich',
        meta: {person}
      });
    },
    entity: entity
  }), [authorize, snackbar, clientId, profileProvider.state?.settings?.enrichingPersons,
    enrichPerson.mutation, entity, collection?.collectionId, createPersonEvent, setSettingsEvent]);

  useEffect(() => {
    const scroll = () => {
      return innerRef.current?.querySelector('.EntityPersonsProfileCardContent-persons .DataList-list')?.scrollTo({top: 0, left: 0});
    }

    utils.retry(scroll, 3);
  }, [entity?.entityId]);

  innerProps.className = utils.flattenClassName(innerProps.className, {
    isEditing: isEditing
  });

  return <StyledEntityPersonsProfileCardContent ref={innerRef} {...innerProps}>
    <InlineForm className="EntityPersonsProfileCardContent-search"
                onChange={handleSearch}
                fields={fields} />
    {(persons.data?.length > 0 || isDataLoading) ?
      <PersonsList className="EntityPersonsProfileCardContent-persons"
                   isLoading={isDataLoading}
                   onClick={handlePersonClick}
                   persons={persons}
                   PersonCardProps={personCardProps} /> : null}
    {(!(persons.data?.length > 0) && !isDataLoading) ?
      <Box className="EntityPersonsProfileCardContent-empty">
        <P>No employee(s) found</P>
      </Box> : null}
    <Box className="EntityPersonsProfileCardContent-action-wrapper">
      {renderAddButton()}
      {renderDialog()}
    </Box>
  </StyledEntityPersonsProfileCardContent>
});

EntityPersonsProfileCardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  card: PropTypes.object,
  content: PropTypes.object,
  entity: PropTypes.object,
  collection: PropTypes.object,
  isLoading: PropTypes.bool
};

EntityPersonsProfileCardContent.defaultProps = {
};

export default EntityPersonsProfileCardContent;


