import React, {useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledThemeBreakdownDashboardCard
  from 'components/organisms/Cards/ThemeBreakdownDashboardCard/ThemeBreakdownDashboardCard.styles';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import constants from 'helpers/constants';
import LinearProgress from 'components/atoms/Progress/LinearProgress/LinearProgress';
import Typography, {P, Span} from 'components/atoms/Text/Typography/Typography';
import Box from 'components/atoms/Layout/Box/Box';
import utils from 'helpers/utils';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import {useClientStatisticLabelsBreakdown} from 'services/client/statistic/statistic.utils';

const ThemeBreakdownDashboardCard = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'ThemeBreakdownDashboardCard');

  const initialised = useRef(false);
  const profileProvider = useProfile();
  const cardState = profileProvider.cardState;
  const theme = cardState.cardSettings['themeBreakdown']?.filter?.theme;

  const [breakdown, totals, isStatisticLoading] = useClientStatisticLabelsBreakdown();

  const [internalState, setInternalState] = useState({});

  const isLoading = isStatisticLoading || !utils.isDefined(breakdown);

  const options = useMemo(() => {
    return breakdown?.map((theme) => {
      return {
        label: theme.label,
        value: theme.labelId
      };
    });
  }, [breakdown]);

  const fields = useMemo(() => {
    const initialOption = options?.find((opt) => opt.value === theme) ?? options?.[0];

    if (options) {
      return [{
        name: 'theme',
        type: constants.formFieldTypes.autocomplete,
        validation: constants.formFieldValidationTypes.text,
        placeholder: 'Select a theme',
        initial: initialOption,
        FormFieldProps: {
          autoFocus: false,
          hiddenLabel: true,
          clearable: false,
          size: 'small'
        },
        options
      }];
    } else {
      return null;
    }
  }, [options, theme]);

  useEffect(() => {
    const option = fields?.[0]?.initial;
    const theme = breakdown?.find((bd) => +bd.labelId === +option?.value);
    if (!initialised.current && theme) {
      initialised.current = true;
      setInternalState((current) => {
        if (!current.value) {
          return {
            ...current,
            theme
          }
        } else {
          return current;
        }
      });
    }
  }, [fields, breakdown]);

  const handleChange = (field, value) => {
    const theme = breakdown?.find((bd) => +bd.labelId === +(value?.value ?? value));
    setInternalState(utils.updater({theme}, true));

    cardState.setCardSettings((current) => ({
      ...current,
      'themeBreakdown': {
        ...current?.['themeBreakdown'],
        filter: {
          ...current?.['themeBreakdown']?.filter,
          theme: value?.value ?? value
        }
      }
    }));
  };

  const renderBars = () => {
    return <React.Fragment>
      <Box className="barInfo">
        <Typography variant="body2" isLoading={isLoading}>
          <Span>{internalState.theme?.entitiesCount ?? 0}</Span> Companies
        </Typography>
        <LinearProgress variant="determinate"
                        isLoading={isLoading}
                        value={totals?.entitiesCount ?
                          ((internalState.theme?.entitiesCount ?? 0) / totals?.entitiesCount) * 100 : 0} />
      </Box>

      <Box className="barInfo">
        <Typography variant="body2" isLoading={isLoading}>
          <Span>{internalState.theme?.collectionsCount ?? 0}</Span> Collections
        </Typography>
        <LinearProgress variant="determinate"
                        isLoading={isLoading}
                        value={totals?.collectionsCount ?
                          ((internalState.theme?.collectionsCount ?? 0) / totals?.collectionsCount) * 100 : 0} />
      </Box>

      <Box className="barInfo">
        <Typography variant="body2" isLoading={isLoading}>
          <Span>{internalState.theme?.dealflowCount ?? 0}</Span> Companies in dealflow
        </Typography>
        <LinearProgress variant="determinate"
                        isLoading={isLoading}
                        value={totals?.dealflowCount ?
                          ((internalState.theme?.dealflowCount ?? 0) / totals?.dealflowCount) * 100 : 0} />
      </Box>
    </React.Fragment>
  }

  return <StyledThemeBreakdownDashboardCard ref={ref} {...innerProps}
                                            title="Theme breakdown"
                                            context={(fields ? <InlineForm onChange={handleChange}
                                                                           fields={fields} /> : null)}>

    {(options?.length > 0 || isLoading) ? renderBars() : null}
    {(options?.length === 0 && !isLoading) ?
      <Box className="DashboardCard-empty">
        <P>No theme(s) found</P>
      </Box> : null}
  </StyledThemeBreakdownDashboardCard>
});

ThemeBreakdownDashboardCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
};

ThemeBreakdownDashboardCard.defaultProps = {};

export default ThemeBreakdownDashboardCard;
