import system from 'helpers/system';
import utils from 'helpers/utils';
import {atom, atomFamily, selector, selectorFamily} from 'recoil';
import logger from 'helpers/logger';
import constants from 'helpers/constants';
import BaseStore from 'stores/base.store';

const applyDefaultOptions = (options = {}) => {
  return {
    process: null, // process incoming data function
    priority: null, // process change with priority
    match: null, // match cache data to incoming data function
    watch: null, // watch other entity either a function or an array

    maxSize: system.maxStoreSize(), // number of items to max store

    hasAuthorizedData: true, // has data that should be removed on logout / proxy
    hasClientSpecificData: true, // has data that should be removed on client switch
    hasTeamSpecificData: true, // has data that should be removed on team switch

    refreshEnabled: true, // can refresh items
    refreshHasList: true, // there is a list endpoint to refresh items
    refreshDeleted: true, // try to refresh removed items, backup for view refresh (like entity.collections)
    refreshTime: system.storeRefreshTime(), // minimum age for entities to be auto refreshed if invalidated
    refreshResetTime: system.storeRefreshResetTime(), // after this time the refresh tris again on hanging request
    refreshEntityLimit: system.storeRefreshEntityLimit(), // at most this amount will be refreshed without using list end-point
    refreshListBatchSize: system.storeRefreshListBatchSize(), // number of ids to refresh at a time
    refreshEntityBatchSize: system.storeRefreshEntityBatchSize(), // number of ids to refresh at a time
    refreshEntityParams: null, // extra http params to send with refresh and entity
    refreshListParams: null, // extra http params to send with refresh a list of entities

    queryStatusTimeout: system.queryStatusTimeout(), // timeout hanging loadings status

    invalidateByEntity: true, // on patch or delete invalidate al with the same entity
    invalidateByEntityWithPriority: false, // priority invalidate other entities
    invalidateChildren: true, // invalidate children, default always
    invalidateChildrenWithPriority: false, // priority invalidate children
    invalidateParent: false, // on patch or delete also invalidate parent
    invalidateParentWithPriority: false, // priority invalidate parent (like for tagGroups, labels, budgets)
    invalidateFromParent: false, // invalidate when parent is invalidated not invalidating is an optimisation

    separateEntityData: false, // true if item values are contextual (more data or based on other params)
    preloadEntityLimit: system.storePreloadEntityLimit(), // max items to preload concurrently depending on current invalid count
    dedupListedEntities: true, // remove duplicates from lists (pagination sometimes fails)

    keepDeletedData: false, // return id and deleted flag for later handling, removes the item,

    invalidateListQuery: true, // default lists invalidate because the list (sort, filter etc.)
                               // relies on the item data points
    invalidateEntityQuery: false, // invalidate complete get query instead of refreshing in background

    putHasInvalidData: false, // has invalid data, force data to be invalid and reloaded
    patchHasInvalidData: false, // has invalid data, force data to be invalid and reloaded
    postHasInvalidData: false, // has invalid data, force data to be invalid and reloaded
    deleteHasInvalidData: false, // has invalid data, force data to be invalid and reloaded

    overrideListPath: null, // override path for list data
    overrideEntityPath: null, // override path for entity data
    overrideOtherPath: null, // override path for other data

    overrideChildPath: null, // override path prefix for your children
    overrideParentPath: null, // override parent path prefix

    ...options,
    atoms: {
      ...options.atoms
    },
    api: {
      ...options.api
    }
  };
}

export default class ServiceStore extends BaseStore {
  constructor (path, app, options, callbacks) {
    super(options);

    this.options = {...this.options, ...applyDefaultOptions(options)};

    this.path = path;
    this.uuid = utils.camelcase(path.replace(/\//g, '_'));
    this.name = path.split('/').slice(-1)[0];

    this.app = app;
    this.parent = null;
    this.children = [];

    this.entity = this.options.entity ?? this.name;
    this.list = this.options.list ?? this.entity + 's';

    this.key = this.options.key ?? this.entity + 'Id';
    this.listKey = this.options.listKey ?? this.key + 's';

    this.callbacks = callbacks;

    this.initAtoms();
    this.initApi();
  }

  parents () {
    let parents = [];
    if (this.parent) {
      parents.push(this.parent);
      parents = parents.concat(this.parent.parents());
    }

    return parents;
  }

  cacheId (id, context) {
    if (!context?.$store?.hash) {
      logger.trace('Context without hash', this.path, context, id, this);
    }
    return `${context?.$store?.hash || 'NC'}_${id}`;
  }

  storePath () {
    let path = this.name;
    if (this.parent) {
      path = `${this.parent.storePath()}/${path}`
    }

    return path;
  }

  getIdsFromContext = (context, parent = null) => {
    let ids = [];
    if (utils.isArray(context)) {
      context.forEach((c) => {
        ids = ids.concat(this.getIdsFromContext(c, parent));
      });
    } else if (utils.isObject(context)) {
      ids = ids.concat(utils.toArray(context?.[parent ? parent.key : this.key]))
        .concat(utils.toArray(context?.[parent ? utils.underscore(parent.key) : utils.underscore(this.key)]))
        .concat(utils.toArray(context?.[parent ? parent.listKey : this.listKey]))
        .concat(utils.toArray(context?.[parent ? utils.underscore(parent.listKey) : utils.underscore(this.listKey)]));
    }

    return utils.uniqueArray(ids.map((id) => id.toString()));
  }

  apiPath (dataType, overridePath = null, child = false) {
    if (!constants.dataTypes[dataType]) {
      throw new Error('Invalid datatype not defined');
    }

    // path is the tree path, context exists in the ids in the tree
    let path = overridePath ?? (
      child ? this.options['overrideChildPath'] :
        this.options[`override${utils.upperFirst(dataType)}Path`]
    );

    if (!path) {
      path = this.list;

      if (this.parent) {
        if (this.options['overrideParentPath']) {
          path = `${this.options['overrideParentPath']}/${path}`;
        } else {
          path = `${this.parent.apiPath(constants.dataTypes.entity, null, true).split('?')[0]}/${path}`;
        }
      }

      path = (dataType === constants.dataTypes.list) ? path : `${path}/:${this.key}`;
    }

    return path;
  }

  apiPathContext (path, dataType, params = {}, extra={}) {
    const contextParams = {}, defaultParams = {}, keys = [], info = {};
    const parents = this.parents().map((p) => ({
      key: p.key,
      listKey: p.listKey
    }));
    const parentKeys = parents.map((p) => p.key);

    // add default parent keys,
    // used for detecting context and refreshing but is not
    // actual hash context, that is service path specific
    const defaultKeys = [];
    if (utils.isDefined(this.app?.options?.defaultParent)) {
      parents.push(this.app.options.defaultParent);
      defaultKeys.push(this.app.options.defaultParent.key);
    }

    const matches = path.match(/:(\w+)/gi);
    (matches || []).forEach((m) => {
      if (utils.isDefined(params[m.slice(1)])) {
        keys.push(m.slice(1));
        info[m.slice(1)] = params[m.slice(1)];
        if (m.slice(1) !== this.key) {
          if (parentKeys.includes(m.slice(1))) {
            contextParams[m.slice(1)] = params[m.slice(1)].toString();
          } else if (defaultKeys.includes(m.slice(1))) {
            defaultParams[m.slice(1)] = params[m.slice(1)].toString();
          }
        }
      }
    });

    // extra info, listed data
    info[this.listKey] = this.getIdsFromContext(params);
    parents.forEach((p) => {
      info[p.listKey] = this.getIdsFromContext(params, p);
    });
    if (this.parent) {
      info['$contextIds'] = info[this.parent.listKey];
    }

    let separateData;
    if (dataType === constants.dataTypes.other) {
      separateData = true;
      contextParams.params = params; // add other params to make the hash unique
    } else {
      separateData = (dataType === constants.dataTypes.entity) && Boolean(this.options.separateEntityData);
    }

    // unique hash for parent context only no ids for this store
    return {
      $store: {
        hash: utils.sha1({
          ...contextParams,
          // make sure the context is unique for this dataType
          unique: separateData ?
            (dataType === constants.dataTypes.other ? (dataType + path) : dataType) : null
        }),
        path,
        dataType,
        separateData,
        ...info,
        ...extra
      },
      keys: keys,
      ...contextParams,
      ...defaultParams
    };
  }

  hasEntity (entity) {
    const alternativePath = this.parents().reduce((s, p) => `${p.entity}/${s}`, this.entity);
    return this.path === entity || alternativePath === entity || this.entity === entity || (
      this.options.watch &&
        (utils.isFunction(this.options.watch) ? this.options.watch(entity) : this.options.watch.includes(entity))
    );
  }

  findStore (path) {
    return this.callbacks.find(path);
  }

  findStoresByEntity (entity) {
    return this.callbacks.findByEntity(entity);
  }

  calcChanges (current, objects, key, meta, opts = {}) {
    const changes = [];
    let changed = false, refreshed = false;
    objects.forEach((obj) => {
      const id = obj[key];
      const cacheId = obj?.cacheId || this.cacheId(obj[key], meta?.context);
      const existing = current[cacheId];

      // don't count internal data calls as used
      const newItem = {
        cacheId,
        id: id,
        context: meta?.context,
        dataAt: meta?.dataAt,
        servedAt: meta?.servedAt,
        dataKeys: (meta?.dataKey && !opts?.system) ? [meta.dataKey] : [],
        dummy: Boolean(obj.$dummy),
        preload: Boolean(obj.$preload),
        deleted: opts?.delete,
        deletedAt: opts?.delete ? meta?.dataAt : null,
        invalid: opts?.invalidate,
        invalidAt: opts?.invalidate ? meta?.dataAt : null,
        data: obj
      };

      if (existing) {
        // merge stuff
        newItem.dataKeys = newItem.dataKeys
          .filter((dk) => !existing.dataKeys.find((edk) => edk.hash === dk.hash))
          .concat(existing.dataKeys);
      }

      newItem.monitorCallbacks = utils.uniqueArray(newItem.dataKeys.reduce((a, dk) => {
        if (Boolean(dk.monitorCallback)) {
          a.push(dk.monitorCallback);
        }
        return a;
      }, []));

      newItem.matchCallbacks = utils.uniqueArray(newItem.dataKeys.reduce((a, dk) => {
        if (Boolean(dk.matchCallback)) {
          a.push(dk.matchCallback);
        }
        return a;
      }, []));

      if (existing) {
        // check not loading old data over the stored data, dataAt = local time
        if (newItem.dataAt.getTime() > existing.dataAt.getTime()) {
          changed = true;
          refreshed = true;
          if (opts?.delete) {
            changes.push({
              cacheId,
              invalid: opts?.invalidate, invalidAt: opts?.invalidate ? newItem.dataAt : null,
              deleted: true, deletedAt: newItem.dataAt,
              dataKeys: newItem.dataKeys.filter((dk) => !dk.invalidate)
            });
          } else if (opts?.invalidate) {
            changes.push({
              cacheId,
              deleted: false, deletedAt: null,
              invalid: true, invalidAt: newItem.dataAt
            });
          } else {
            changes.push(newItem);
          }
        } else {
          changed = changed || newItem.dataKeys.length !== existing.dataKeys.length;
          changes.push({
            cacheId, id: newItem.id,
            dataKeys: newItem.dataKeys
          });
        }
      } else {
        changed = true;
        refreshed = true;

        changes.push(newItem);
      }
    });

    // MAKE SURE - MUST RETURN ALL OBJECTS
    if (changes.length !== objects.length) {
      logger.error('Less / more changes then objects in the store', changes, objects);
    }
    return [changes, changed, refreshed];
  }

  storeChanges (current, changes, invalid, get, set, reset) {
    const purgeStore = () => {
      const maxSize = this.options.maxSize;

      if (Object.keys(current).length > maxSize) {
        // sort the newest first
        const keys = Object.keys(current).sort((a, b) => {
          return current[b].dataAt.getTime() - current[a].dataAt.getTime();
        });

        // keep 75%
        const purged = keys.slice(0, Math.ceil(maxSize - maxSize / 4)).reduce((obj, key) => {
          obj[key] = current[key];
          return obj;
        }, {});

        // purge keys
        set(this.atoms.keys, (current) => {
          const used = Object.keys(purged).reduce((hashes, k) => {
            const obj = purged[k];
            return hashes.concat((obj.dataKeys || []).reduce((dks, dk) => {
              return dks.concat([dk.keysHash]);
            }, []))
          }, []);

          return Object.keys(current).reduce((o, k) => {
            const found = used.find((u) => u === k);
            if (!found) {
              // see also processReset
              reset(this.atoms.status(current[k]));
              reset(this.atoms.data(current[k]));
              reset(this.atoms.meta(current[k]));
              reset(this.atoms.processStatus(current[k]));
              reset(this.atoms.processData(current[k]));
            } else {
              o[k] = current[k];
            }

            return o;
          }, {});
        });

        return purged;
      } else {
        return {...current};
      }
    };

    current = purgeStore();

    const stateFn = (type, key, scope, state) => this.storeState(type, key, scope, state, get, set);

    if (invalid) {
      // invalid cache only holds invalid items
      changes.forEach((change) => {
        if (!change.invalid) {
          delete current[change.cacheId];
        }
      });
      changes = changes.filter((change) => change.invalid);
    }

    changes.forEach((change) => {
      if (!invalid) {
        const cached = current[change.cacheId];
        const isDelete = cached && !cached.deleted && change.deleted;

        const prev = cached;
        const next = isDelete ? null : change;

        this.options?.effects?.(prev, next, {state: stateFn});
        let monitorCallbacks = change.monitorCallbacks ?? [];
        Object.keys(current).forEach((cacheId) => {
          if (change?.cacheId !== cacheId) {
            monitorCallbacks = monitorCallbacks.concat(current[cacheId].monitorCallbacks);
          }
        });

        utils.uniqueArray(monitorCallbacks).forEach((monitorCallback) => monitorCallback(prev, next));
      }

      if (current[change.cacheId]) {
        current[change.cacheId] = {
          ...current[change.cacheId],
          ...change,
          data: {
            ...current[change.cacheId].data,
            ...change.data
          }
        };
      } else {
        current[change.cacheId] = change
      }
    });

    return current;
  }

  storeReference (data, meta, opts, set) {
    let storedChanges = [];

    set(this.atoms.processStore, {
      data, meta, opts: {
        ...opts,
        changesCallback: (changes) => {
          storedChanges = changes;
        }
      }
    });

    const stores = utils.toArray(storedChanges)
      .map((change) => ({id: change.id, cacheId: change.cacheId, store: this}));
    return utils.isArray(storedChanges) ? stores : stores[0];
  }

  storeState (type, key, scope, state, get, set) {
    set(this.app.atoms.processState({type, key: key ?? this.name, scope}), (current) => {
      return utils.isFunction(state) ? state(current.data) : state;
    });
  }

  saveKeys (keys, set) {
    set(this.atoms.keys, (current) => {
      const hash = utils.sha1(keys);
      if (!current[hash]) {
        return {...current, [hash]: keys};
      } else {
        return current;
      }
    });
  }

  metaData (data, keys, opts) {
    const meta = data?.hasOwnProperty('meta') ? data.meta : null;

    const dataType = opts?.dataContext?.$store?.dataType;
    const isSystem = Boolean(opts?.system);
    const isMutation = Boolean(opts?.mutation);
    const isRefetch = utils.isDefined(opts?.refetchInvalidQuery) ? opts?.refetchInvalidQuery :
      dataType === constants.dataTypes.other;

    const dataKey = {
      keys,
      keysHash: utils.sha1(keys),
      // query is invalidated
      invalidate: !isSystem && !isMutation && utils.isDefined(dataType) && (
        opts?.invalidateQuery === true || !this.canRefreshDataType(dataType)
      ),
      // query is refetched when active
      refetch: isRefetch
    };

    const {
      time,
      serverTime,
      invalidate,
      ...rest
    } = meta || {};

    return {
      dataAt: time,
      servedAt: serverTime,
      dataKey: {
        ...dataKey,
        monitorCallback: opts?.monitorCallback,
        matchCallback: opts?.matchCallback,
        hash: utils.sha1(dataKey)
      },
      context: {...opts?.dataContext},
      invalidate: invalidate,
      ...rest
    }
  }

  canActivelyRefresh (loggedIn) {
    return (loggedIn || this.options.hasAuthorizedData === false);
  }

  canRefreshDataType (dataType) {
    return this.options.refreshEnabled && dataType !== constants.dataTypes.other;
  }

  calcRefreshCache (current, invalid, refreshCacheIds, dataType, opts) {
    const checkCacheIds = refreshCacheIds.concat(current.cache.map((rs) => rs.cacheId));

    // if the old don't need a refresh they can be dropped
    const newRefreshStore = utils.uniqueArray(
      this.retrieveObjectsFromCache(invalid, checkCacheIds, true)
        .filter((obj) => {
          // make sure you get ids only once when a list refresh is enough
          return obj.invalid && !obj.dummy &&
            ((dataType === constants.dataTypes.list && !obj?.context?.$store?.separateData) ||
             (dataType === obj?.context?.$store?.dataType && obj?.context?.$store?.separateData));
        })
        .sort((a, b) => a.dataAt.getTime() - b.dataAt.getTime())
        .map((obj) => {
          const prev = current.cache.find((r) => r.cacheId === obj.cacheId);
          const priority = prev?.priority || obj.priority || (
            !utils.isDefined(obj.priority) && (
              obj?.context?.$store?.separateData ||
              !opts?.refetchContext ||
              !Boolean(obj.dataKeys?.find((dk) => dk.invalidate))
            )
          );
          return {
            cacheId: obj.cacheId,
            id: obj.id,
            context: obj.context,
            priority: priority
          };
        }), 'cacheId');

    if (!utils.compare(newRefreshStore, current.cache)) {
      return {time: new Date(), cache: newRefreshStore};
    } else if (current.cache.length > 0) {
      return {time: new Date(), cache: current.cache};
    } else {
      return current;
    }
  }

  initAtoms () {
    this.atoms = {};

    this.atoms.reset = atom({
      key: `${this.uuid}Reset`,
      default: (new Date()),
      effects: this.options.atoms.reset?.effects
    });

    this.atoms.cache = atom({
      key: `${this.uuid}Cache`,
      default: {},
      effects: this.options.atoms.cache?.effects
    });

    this.atoms.invalid = atom({
      key: `${this.uuid}Invalid`,
      default: {},
      effects: this.options.atoms.invalid?.effects
    });

    this.atoms.keys = atom({
      key: `${this.uuid}Keys`,
      default: {},
      effects: this.options.atoms.keys?.effects
    });

    this.atoms.refresh = atomFamily({
      key: `${this.uuid}Refresh`,
      default: {time: null, cache: []},
      effects: this.options.atoms.refresh?.effects
    });

    this.atoms.status = atomFamily({
      key: `${this.uuid}Status`,
      default: {},
      effects: this.options.atoms.status?.effects
    });

    this.atoms.data = atomFamily({
      key: `${this.uuid}Data`,
      default: null,
      effects: this.options.atoms.data?.effects
    });

    this.atoms.meta = atomFamily({
      key: `${this.uuid}Meta`,
      default: null,
      effects: this.options.atoms.meta?.effects
    });

    this.atoms.processRefresh = selector ({
      key: `${this.uuid}ProcessRefresh`,
      get: ({get}) => {
        return Object.keys(constants.dataTypes).reduce((o, k) => {
          const dataType = constants.dataTypes[k];
          o[dataType] = get(this.atoms.refresh(dataType));
          return o;
        }, {});
      },
      set: ({ get, set }, {items}) => {
        if (items?.length > 0) {
          const stores = items.reduce((s, itm) => {
            let store = s.find((st) => st.store === itm.store);
            if (!store) {
              store = {
                store: itm.store,
                cacheIds: []
              };
              s.push(store);
            }
            store.cacheIds.push(itm.cacheId);
            return s;
          }, []);
  
          stores.forEach((store) => {
            const cached = get(store.store.atoms.invalid);
            Object.keys(constants.dataTypes).forEach((k) => {
              const dataType = constants.dataTypes[k];
              if (store.store.canRefreshDataType(dataType)) {
                set(store.store.atoms.refresh(dataType), (current) => {
                  return store.store.calcRefreshCache(current, cached, store.cacheIds, dataType);
                });
              }
            });
          })
        }
      }
    })

    this.atoms.processStatus = selectorFamily({
      key: `${this.uuid}ProcessStatus`,
      get: (keys) => ({get}) => {
        if (keys === 'all') {
          const keysStore = get(this.atoms.keys);
          return Object.keys(keysStore).map((k) => get(this.atoms.status(keysStore[k])));
        } else {
          return get(this.atoms.status(keys));
        }
      },
      set: (keys) => ({ get, set }, status) => {
        if (status) {
          if (keys === 'all') {
            const keysStore = get(this.atoms.keys);
            Object.keys(keysStore).map((k) => set(this.atoms.processStatus(keysStore[k]), status));
          } else {
            // save key for clearing later
            this.saveKeys(keys, set);

            set(this.atoms.status(keys), (current) => {
              let changes = {};
              Object.keys(status).forEach((type) => {
                const stored = current?.[type]?.value;
                if (stored !== status[type]) {
                  changes[type] = status[type];
                }
              });
  
              if (Object.keys(changes).length > 0) {
                const stored = {...current};
                Object.keys(changes).forEach((type) => {
                  stored[type] = {
                    ...stored[type],
                    value: changes[type]
                  };
                });
  
                return stored;
              } else {
                return current;
              }
            });
          }
        }
      }
    });

    this.atoms.processStore = selector({
      key: `${this.uuid}ProcessStore`,
      get: ({get}) => {
        return get(this.atoms.cache);
      },
      set: ({ get, set, reset }, {data, meta, opts}) => {
        let items = null, returnArray = false;
        if (data) {
          if (this.options.process) {
            items = this.options.process(data, meta);
            returnArray = utils.isArray(items);
          }

          // nothing processed
          if (!utils.isDefined(items) || data === items) {
            // dataType = other:
            // - means the data is always seperated, but can contain entity data
            // - with entityId it will invalidate but only if all data has the key
            items = utils.toArray(data).map((itm) => utils.isObject(itm) ? utils.camelcase(itm) : itm);
            if (meta?.context?.$store?.dataType === constants.dataTypes.other && (
              items.length === 0 || items.some((d) => !utils.isDefined(d[this.key]))
            )) {
              items = [{
                [this.key]: meta?.context?.$store[this.key] ?? meta?.context?.$store?.path,
                data: utils.isArray(data) ? data.map((itm) => utils.isObject(itm) ? utils.camelcase(itm) : itm) : (
                  utils.isObject(data) ? utils.camelcase(data) : data
                )
              }];
              returnArray = false;
            } else {
              returnArray = utils.isArray(data);
            }
          }

          // make sure it are reasonable objects
          items = utils.toArray(items).filter((itm) => utils.isDefined(itm[this.key]));
          if (this.options.dedupListedEntities && meta?.context?.$store?.dataType === constants.dataTypes.list) {
            items = utils.uniqueArray(items, this.key);
          }

          if (meta?.context?.$store?.dataType === constants.dataTypes.list) {
            // used for refetchContext, adding always will enforce new dataAt;
            items.push({
              [this.key]: `[DUMMY][${meta?.dataKey?.keysHash}]`,
              $dummy: true
            });
          }
        } else {
          items = [];
        }
  
        // here we rely on the current store
        set(this.atoms.cache, (cachePrev) => {
          let cacheNext = cachePrev, cacheChanged = false,
            invalids = [], refreshCacheIds = [], invalidateDataIds = [];

          // invalidate data first, trigger data keys query cache updates
          const invalidateCacheIds = (invalidIds, options, visited) => {
            const {
              priority,
              isDelete,
              isFailed,
              contextKey,
              entity = this.entity
            } = options;

            let invalidatedIds = [];
            const refreshTime = meta?.servedAt ?? meta?.dataAt;
            if (!refreshTime) {
              logger.trace('Clear without refresh time', invalidIds, this);
            }

            if (!visited.find((p) => p === this.path)) {
              const context = utils.toArray(meta?.context?.$store?.['$contextIds']).map((id) => id.toString());
              let parentIds = utils.toArray(meta?.context?.$store?.[this.parent?.listKey]);

              if (invalidIds?.length > 0) {
                let objs = [];
                if (contextKey) {
                  if (this.options.invalidateFromParent) {
                    objs = this.retrieveObjectsFromCacheByContextId(cacheNext, invalidIds);
                  }
                } else {
                  objs = this.retrieveObjectsFromCacheById(cacheNext, entity, invalidIds, meta);
                }

                if (!isDelete) {
                  objs = objs
                    .filter((obj) => {
                      const objTime = obj.servedAt ?? obj.dataAt;
                      return !objTime || !refreshTime || objTime.getTime() < refreshTime.getTime();
                    })
                    .filter((obj) => this.options.refreshDeleted || !obj.deleted);
                } else {
                  if (parentIds?.length > 0) {
                    objs = objs
                      .filter((obj) => parentIds.find((id) => {
                        return utils.toArray(obj.context?.$store[this.parent?.listKey]).find((cId) => {
                          return cId.toString() === id.toString();
                        })
                      }));
                  }
                }

                if (objs.length > 0) {
                  objs.forEach((obj) => {
                    parentIds = parentIds.concat(utils.toArray(obj.context?.$store?.[this.parent?.listKey]));
                  });

                  // see calcRefreshCache for default priority is it is not defined
                  const invalidatePriority = isDelete ? false : (
                    this.options.priority ? this.options.priority.includes(entity) : priority
                  );
                  const invalidate = !isDelete || (!isFailed && this.options.refreshDeleted);
                  const [changes, changed] = this.calcChanges(cacheNext, objs.map((obj) => ({
                      cacheId: obj.cacheId,
                      [this.key]: obj.id
                    })),
                    this.key, meta, {...opts, invalidate: invalidate, delete: isDelete});

                  // flag deleted objs in cache, if also invalid then invalidIds handles them
                  if (changed && isDelete) {
                    cacheChanged = true;
                    cacheNext = this.storeChanges(cacheNext, changes, false, get, set, reset);
                  }
                  invalids = invalids.concat(changes.map((c) => ({...c, priority: invalidatePriority})));

                  // check refresh time, only young items will be refreshed
                  const storeRefreshTime = Date.now() - this.options.refreshTime;
                  const refreshObjs = objs.filter((obj) => isFailed || obj.dataAt.getTime() >= storeRefreshTime);
                  refreshCacheIds = refreshCacheIds.concat(refreshObjs.map((obj) => obj.cacheId));

                  if (invalidate) {
                    // check if a full invalidate is necessary was a new invalidate or delete
                    const invalidateObjs = objs.filter((obj) => {
                      return (!isDelete && !obj.invalid) || (isDelete && !obj.deleted);
                    });
                    invalidatedIds = utils.uniqueArray(invalidatedIds.concat(invalidateObjs)
                      .filter((obj) => !obj.dummy)
                      .map((obj) => obj.id.toString()));

                    invalidateDataIds = invalidateDataIds.concat(invalidateObjs.map((obj) => ({
                      cacheId: obj.cacheId,
                      // propagated or empty data invalidations, always refetch active query when it can not refresh
                      refetch: items.length === 0 && !this.canRefreshDataType(obj?.context?.$store?.dataType),
                      priority: invalidatePriority ?? true
                    })));
                  }
                }

                if (!isFailed && (!contextKey || this.options.invalidateFromParent)) {
                  // propagate invalidation add all items to invalidate the most data
                  // invalidate all children related to this or to the context
                  const propagateIds = this.entity === entity ? invalidIds : invalidatedIds;

                  if (propagateIds.length > 0 && this.options.invalidateChildren) {
                    this.children.forEach((child) => {
                      const visitedChild = visited.some((path) => path === child.path);

                      if (!visitedChild) {
                        set(child.atoms.processStore, {
                          meta: {
                            ...meta,
                            invalidate: {
                              priority: priority && this.options.invalidateChildrenWithPriority,
                              invalidIds: !isDelete ? propagateIds : null,
                              removedIds: isDelete ? propagateIds : null,
                              contextKey: this.key,
                              entity: this.entity,
                              visited: visited.concat([this.path])
                            }
                          }, opts: {...opts, refetchContext: opts?.refetchContext && opts?.refetchChildren}
                        })
                      }
                    });
                  }

                  // invalidate same entity name
                  const visitedEntity = visited.some((path) => this.findStore(path).entity === this.entity);
                  if (this.options.invalidateByEntity && entity === this.entity && !visitedEntity) {
                    const stores = this.findStoresByEntity(this.entity);
                    stores.forEach((store) => {
                      if (store !== this) {
                        const canDelete = isDelete && context.length === 0;

                        set(store.atoms.processStore, {
                          meta: {
                            ...meta,
                            invalidate: {
                              priority: priority && this.options.invalidateByEntityWithPriority,
                              invalidIds: !canDelete ? invalidIds : null,
                              removedIds: canDelete ? invalidIds : null,
                              entity: this.entity,
                              visited: visited.concat([this.path])
                            }
                          }, opts: opts // refetchContext for same types if true
                        })
                      }
                    })
                  }
                }
              }

              if (!isFailed && (!contextKey || this.options.invalidateFromParent)) {
                parentIds = utils.uniqueArray(parentIds.map((id) => id.toString()));
                if (opts?.refetchContext) {
                  // refetch contextual list's or lists holding this data
                  if (parentIds.length > 0 || (context.length === 0 && !this.parent)) {
                    let objs = this.retrieveObjectsFromCacheByContextId(cacheNext, parentIds);

                    if (invalidIds?.length > 0) {
                      objs = objs.concat(this.retrieveObjectsFromCacheById(cacheNext, entity, invalidIds, meta));
                    }

                    objs = objs
                      .filter((obj) => {
                        const objTime = obj.servedAt ?? obj.dataAt;
                        return !objTime || !refreshTime || objTime.getTime() < refreshTime.getTime();
                      })
                      .filter((obj) => !obj.deleted)
                      .filter((obj) => {
                        return obj.context.$store.dataType === constants.dataTypes.list ||
                          obj.context.$store.dataType === constants.dataTypes.other
                      });

                    invalidateDataIds = invalidateDataIds.concat(objs.map((obj) => ({
                      cacheId: obj.cacheId,
                      priority: this.options.priority ? this.options.priority.includes(entity) : (priority ?? true)
                    })));
                  }
                }

                // invalidate possible parents
                const visitedParent = !this.parent || visited.some((path) => path === this.parent.path);
                if (parentIds.length > 0 && !visitedParent && this.options.invalidateParent) {
                  set(this.parent.atoms.processStore, {
                    meta: {
                      ...meta,
                      invalidate: {
                        priority: priority && this.options.invalidateParentWithPriority,
                        invalidIds: parentIds,
                        entity: this.parent.entity,
                        visited: visited.concat([this.path])
                      }
                    }, opts: {...opts, refetchContext: false}
                  });
                }

                // custom invalidate
                if (this.options.invalidate) {
                  const invalidateFn = (path, priority, invalidIds, removedIds, contextKey, entity, opts) => {
                    const store = this.find(path);
                    set(store.atoms.processStore, {
                      meta: {
                        ...meta,
                        invalidate: {
                          priority,
                          invalidIds,
                          removedIds,
                          contextKey,
                          entity,
                          visited: visited.concat([this.path])
                        }
                      }, opts: opts
                    });
                  }

                  this.options.invalidate(invalidIds, meta, {isDelete, contextKey, visited, entity, opts, invalidate: invalidateFn});
                }
              }
            }
          }

          // process all contexts, mutation at least go through ONCE for possible context invalidation
          if (opts?.mutation || utils.toArray(meta?.invalidate?.invalidIds).length > 0) {
            let invalidIds = utils.toArray(meta?.invalidate?.invalidIds);
            if (opts?.mutation) {
              invalidIds = utils.uniqueArray(invalidIds.concat(items.map((item) => item[this.key])));
            }

            invalidateCacheIds(invalidIds, {
              isDelete: false,
              contextKey: meta?.invalidate?.contextKey,
              priority: meta?.invalidate?.priority,
              entity: meta?.invalidate?.entity
            }, utils.toArray(meta?.invalidate?.visited));
          }
          if (utils.toArray(meta?.invalidate?.removedIds).length > 0) {
            invalidateCacheIds(utils.toArray(meta?.invalidate?.removedIds), {
              isDelete: true,
              isFailed: Boolean(meta?.invalidate?.failed),
              contextKey: meta?.invalidate?.contextKey,
              priority: meta?.invalidate?.priority,
              entity: meta?.invalidate?.entity
            }, utils.toArray(meta?.invalidate?.visited));
          }
  
          // if there is new data then revalidate the data
          if (items.length > 0) {
            // update items in cache
            const [changes, changed, refreshed] = this.calcChanges(cacheNext, items, this.key, meta, opts);
            if (changed) {
              cacheChanged = true;
              cacheNext = this.storeChanges(cacheNext, changes, false, get, set, reset);
            }
            if (refreshed) {
              // overwrite invalids since the data is fresh
              invalids = changes.concat(invalids.filter((invalid) => !changes.find((change) => invalid.cacheId === change.cacheId)));
            }

            const objs = this.retrieveObjectsFromCache(cacheNext, changes.map((change) => change.cacheId));
            refreshCacheIds = refreshCacheIds.concat(objs.map((obj) => obj.cacheId));

            // preload entities by adding them to refreshCache
            if (opts?.enablePreload) {
              if (this.options.separateEntityData && this.canRefreshDataType(constants.dataTypes.entity) &&
                  meta?.context?.$store?.dataType === constants.dataTypes.list) {
                const invalidCache = get(this.atoms.invalid);
                const invalidCount = Object.keys(invalidCache).reduce((t, k) => {
                  return t + ((invalidCache[k].context.$store.dataType === constants.dataTypes.entity && invalidCache[k].invalid) ? 1 : 0);
                }, 0);
                const entityChanges = [];
                items
                  .slice(0, Math.max(0, (this.options.preloadEntityLimit - invalidCount)))
                  .forEach((item) => {
                    if (utils.isDefined(item[this.key]) && !item.$dummy) {
                      const entityMeta = {
                        ...meta,
                        context: this.apiPathContext(
                          this.apiPath(constants.dataTypes.entity), constants.dataTypes.entity,
                          {[this.key]: item[this.key], ...meta.context, ...meta.context.$store}
                        ),
                        servedAt: new Date(0),
                        dataAt: new Date(0)
                      }
                      const [changes] = this.calcChanges(cacheNext, [{[this.key]: item[this.key], $preload: true}], this.key, entityMeta, {
                        ...opts,
                        invalidate: true
                      });
                      const change = changes?.[0];
                      const obj = this.retrieveObjectsFromCache(cacheNext, [change.cacheId])?.[0];

                      if (!obj) {
                        cacheNext = this.storeChanges(cacheNext, [change], true, get, set, reset);
                        cacheChanged = true;

                        entityChanges.push({...change, priority: false, invalid: true, invalidAt: new Date()});
                      }
                    }
                  });

                invalids = entityChanges.concat(invalids.filter((invalid) => !entityChanges.find((change) => invalid.cacheId === change.cacheId)));
                refreshCacheIds = refreshCacheIds.concat(entityChanges.map((change) => change.cacheId));
              }
            }

            opts?.changesCallback?.(returnArray ? changes : changes[0]);
          }

          // update invalid cache
          set(this.atoms.invalid, (invalidPrev) => {
            let invalidNext = invalidPrev, invalidChanged = false;

            if (invalids.length > 0) {
              invalidChanged = true;
              invalidNext = this.storeChanges(invalidNext,
                invalids.map((invalid) => ({...cacheNext[invalid.cacheId], ...invalid})), true, get, set, reset);
            }

            // register refresh objects for later processing
            Object.keys(constants.dataTypes).forEach((k) => {
              const dataType = constants.dataTypes[k];
              if (this.canRefreshDataType(dataType)) {
                set(this.atoms.refresh(dataType), (current) => {
                  return this.calcRefreshCache(current, invalidNext, refreshCacheIds, dataType, opts);
                });
              }
            });

            if (invalidChanged) {
              return invalidNext;
            } else {
              return invalidPrev;
            }
          });

          // trigger query invalidation for the data keys
          if (opts?.invalidateQueryCallback && invalidateDataIds.length > 0) {
            const priority = Boolean(invalidateDataIds.find((d) => d.priority));
            const refetch = Boolean(invalidateDataIds.find((d) => d.refetch));

            const objs = this.retrieveObjectsFromCache(cacheNext, utils.uniqueArray(invalidateDataIds.map((d) => d.cacheId)));
            const invalidateObjs = objs.reduce((a, obj) => {
              obj.dataKeys.forEach(((odk) => {
                if (odk.invalidate && !a.find((dk) => dk.hash === odk.hash)) {
                  a.push({
                    ...odk,
                    exact: true,
                    refetch: odk.refetch || refetch || opts?.refetchContext,
                    remove: false
                  });
                }
              }));
              return a;
            }, []);

            if (invalidateObjs.length > 0) {
              opts?.invalidateQueryCallback(this, invalidateObjs, priority);
            }
          }
  
          // save the store
          if (cacheChanged) {
            return cacheNext;
          } else {
            return cachePrev;
          }
        });
      }
    });

    this.atoms.processData = selectorFamily({
      key: `${this.uuid}ProcessData`,
      get: (keys) => ({get}) => {
        const meta = get(this.atoms.meta(keys));
        const keysHash = meta?.dataKey?.invalidate ? meta?.dataKey?.keysHash : null;
        return {
          ...this.resurrectItem(keysHash, get(this.atoms.data(keys)), get),
          meta: meta
        }
      },
      set: (keys) => ({ get, set, reset}, {data, opts}) => {
        if (data) {
          this.saveKeys(keys, set);

          let meta = this.metaData(data, keys, opts);
          if (this.options.metaData) {
            meta = {
              ...meta,
              ...this.options.metaData(data)
            };
          }

          set(this.atoms.meta(keys), utils.updater(meta));

          data = data.hasOwnProperty('meta') ? data.data : data;
          data = data?.hasOwnProperty('data') ? data.data : data;
          set(this.atoms.data(keys), utils.updater(this.storeReference(data, meta, opts, set)));
        }
      }
    });

    this.atoms.dataById = selectorFamily({
      key: `${this.uuid}DataById`,
      get: ({id, context}) => ({get}) => {
        const cId = id ?? (context?.$store?.dataType === constants.dataTypes.other ?
          (context?.$store[this.key] ?? context?.$store?.path) : null);

        if (utils.isDefined(cId)) {
          const cacheId = this.cacheId(cId, context);
          const objs = this.retrieveObjectsFromCache(get(this.atoms.cache), [cacheId]);
          if (objs.length > 0) {
            const {data, ...meta} = objs[0];
            return {
              ...this.resurrectItem(null, data, get),
              meta: meta
            };
          }
        }

        return null;
      },
    });

    // must be last
    this.atoms.processReset = selector({
      key: `${this.uuid}ProcessReset`,
      get: ({get}) => {
        return get(this.atoms.reset);
      },
      set: ({ get, set, reset}, {type, invalidateQueryCallback}) => {
        const doFullReset = (
          ([constants.resetTypes.logout].includes(type) && this.options.hasAuthorizedData) ||
          ([constants.resetTypes.client].includes(type) && this.options.hasClientSpecificData) ||
          ([constants.resetTypes.team].includes(type) && this.options.hasTeamSpecificData)
        );
        const doInvalidate = doFullReset ||
          ([constants.resetTypes.client].includes(type) && this.options.hasClientSpecificData) ||
          ([constants.resetTypes.team].includes(type) && this.options.hasTeamSpecificData);

        if (doFullReset) {
          const keysStore = get(this.atoms.keys);

          // reset all families
          Object.keys(keysStore).forEach((k) => {
            const keys = keysStore[k];

            reset(this.atoms.meta(keys));
            reset(this.atoms.data(keys));
            reset(this.atoms.processData(keys));

            reset(this.atoms.status(keys));
            reset(this.atoms.processStatus(keys));
          });

          // reset all atoms
          Object.keys(constants.dataTypes).forEach((k) => {
            const dataType = constants.dataTypes[k];
            reset(this.atoms.refresh(dataType));
          });
          reset(this.atoms.cache);
          reset(this.atoms.invalid);

          // reset all selectors
          reset(this.atoms.processStore);
          reset(this.atoms.processRefresh);

          // reset all keys
          reset(this.atoms.keys);

          const now = new Date();
          set(this.atoms.reset, now);
        }

        if (doInvalidate) {
          // invalidate all caches
          invalidateQueryCallback?.(this, [{keys: [], exact: false, remove: true, refetch: true}]);
        }
      }
    });
  }
  
  initApi () {
    this.api = {
      default: {}
    };

    this.api.default.query = {
      list: (params) => ({
        options: {
          dataType: constants.dataTypes.list, // important to know how to refresh
          invalidateQuery: this.options.invalidateListQuery,
          keepPreviousData: true
        },
        action: (http, {pageParam}) => {
          const {
            path,
            search,
            filter,
            sort,
            page,
            pageSize,
            ...rest
          } = params;

          const resolvedPath = utils.resolvePath(path, rest);
          const httpParams = {
            ...utils.filter2Object(filter),
            search: search,
            sort: utils.sort2Http(sort),
            page: pageParam ?? page,
            pageSize: pageSize,
            ...rest
          };

          return http.get(resolvedPath, httpParams).then((resp) => {
            return {meta: utils.responseMeta(resp), data: resp?.data};
          });
        }
      }),
      post: (params) => ({
        options: {
          dataType: constants.dataTypes.list, // important to know how to refresh
          invalidateQuery: this.options.invalidateListQuery,
          keepPreviousData: true
        },
        action: (http, {pageParam}) => {
          const {
            path,
            search,
            filter,
            $body,
            $httpParams,
            ...rest
          } = params;

          const resolvedPath = utils.resolvePath(path, {...params, ...$httpParams});
          const httpParams = {
            ...$httpParams,
            page: pageParam ?? $httpParams?.page
          };
          const data = $body ?? utils.filterObject({
            ...utils.filter2Object(filter),
            search: search,
            ...rest
          }, ['path', this.key]);

          return http.post(resolvedPath, httpParams, data).then((resp) => {
            return {meta: utils.responseMeta(resp), data: resp?.data};
          });
        }
      }),
      get: (params) => ({
        options: {
          dataType: constants.dataTypes.entity, // important to know how to refresh
          invalidateQuery: this.options.invalidateEntityQuery
        },
        action: (http) => {
          const {
            path,
            search,
            filter,
            ...rest
          } = params;

          const resolvedPath = utils.resolvePath(path, params);
          const httpParams = utils.filterObject({
            ...utils.filter2Object(filter),
            search: search,
            ...rest
          }, [this.key]);

          return http.get(resolvedPath, httpParams).then((resp) => {
            return {meta: utils.responseMeta(resp), data: resp?.data};
          });
        }
      }),
      refresh: (params) => {
        const {
          ids,
          dataType
        } = params;

        let calculatedDataType = dataType;

        // try to optimize refreshing, but refresh need to be hit in the cache
        // list is never unique, but entity can be so never switch them if that's hte case
        if (!this.options.separateEntityData) {
          if (!this.options.refreshHasList) {
            calculatedDataType = constants.dataTypes.entity;
          } else if (ids?.length <= this.options.refreshEntityLimit) {
            calculatedDataType = constants.dataTypes.entity;
          } else {
            calculatedDataType = constants.dataTypes.list;
          }
        }

        return {
          options: {
            dataType: calculatedDataType
          },
          action: (http) => {
            if (ids?.length > 0) {
              logger.trace('Refresh cache', ids, params);

              if (calculatedDataType === constants.dataTypes.entity) {
                return Promise.all(ids.map((id) => {
                  const path = utils.resolvePath(params.path, {[this.key]: id});
                  return http.get(path, {
                    intercept: false,
                    ...this.options.refreshEntityParams
                  })
                    .catch((error) => {
                      logger.trace('Refresh failed or was denied', error, id);
                      return {id, failed: true};
                    });
                }))
                  .then((resp) => {
                    const valid = resp.filter((r) => !r.failed);
                    const failed = resp.filter((r) => r.failed);

                    const first = valid.length > 0 ? valid[0] : null;
                    const meta = utils.responseMeta(first);

                    return {
                      meta: {
                        ...meta,
                        invalidate: {
                          failed: true,
                          removedIds: failed.map((f) => f.id)
                        }
                      },
                      data: valid.length > 0 ? valid.map((resp) => {
                        const data = resp?.data;
                        return data?.hasOwnProperty('data') ? data.data : data;
                      }) : null
                    }
                  })
              } else {
                return http.get(params.path, {
                  page: 0,
                  pageSize: ids.length,
                  intercept: false,
                  [this.listKey]: ids,
                  ...this.options.refreshListParams
                })
                  .then((resp) => {
                    const items = utils.toArray(resp?.data?.data).map(utils.camelcase);
                    const failed = ids.filter((id) => {
                      return !items.find((itm) => itm[this.key]?.toString() === id.toString());
                    })

                    if (failed.length > 0) {
                      logger.trace('Refresh failed or was denied', failed);
                    }

                    return {
                      meta: {
                        ...utils.responseMeta(resp),
                        invalidate: {
                          failed: true,
                          removedIds: failed
                        }
                      },
                      data: resp?.data?.data
                    };
                  })
                  .catch((error) => {
                    logger.trace('Refresh failed or was denied', error, ids);
                    return {
                      meta: {
                        ...utils.responseMeta(null),
                        invalidate: {
                          failed: true,
                          removedIds: ids
                        }
                      }
                    };
                  });
              }
            } else {
              return null;
            }
          }
        }
      }
    };

    this.api.default.mutation = {
      post: (params, options) => ({
        options: {
          dataType: constants.dataTypes.entity,
          delete: Boolean(options?.deleteItem)
        },
        action: (http, data, httpParams) => {
          const path = utils.resolvePath(params.path, data);
          const invalidIds = this.getIdsFromContext([params, data, httpParams]);

          return http.post(path, httpParams, data).then((resp) => {
            // Option 1 return data gives fastest updates
            // Option 2 no data at least get the id to invalidate / refresh in background
            return {
              meta: {
                ...utils.responseMeta(resp),
                invalidate: {
                  invalidIds: !options?.deleteItem ? invalidIds : null,
                  removedIds: options?.deleteItem ? invalidIds : null
                }
              },
              data: (this.options.postHasInvalidData || options?.hasInvalidData) ? null : resp?.data
            };
          });
        }
      }),
      put: (params, options) => ({
        options: {
          dataType: constants.dataTypes.entity,
          delete: Boolean(options?.deleteItem)
        },
        action: (http, data, httpParams) => {
          const path = utils.resolvePath(params.path, data);
          const invalidIds = this.getIdsFromContext([params, data, httpParams]);

          return http.put(path, httpParams, data).then((resp) => {
            // Option 1 return data gives fastest updates
            // Option 2 no data at least get the id to invalidate / refresh in background
            return {
              meta: {
                ...utils.responseMeta(resp),
                invalidate: {
                  invalidIds: !options?.deleteItem ? invalidIds : null,
                  removedIds: options?.deleteItem ? invalidIds : null
                }
              },
              data: (this.options.putHasInvalidData || options?.hasInvalidData) ? null : resp?.data
            };
          });
        }
      }),
      patch: (params, options) => ({
        options: {
          dataType: constants.dataTypes.entity,
          delete: Boolean(options?.deleteItem)
        },
        action: (http, data, httpParams) => {
          const path = utils.resolvePath(params.path, data);
          const invalidIds = this.getIdsFromContext([params, data, httpParams]);

          return http.patch(path, httpParams, data).then((resp) => {
            // Option 1 return data gives fastest updates
            // Option 2 no data at least get the id to invalidate / refresh in background
            return {
              meta: {
                ...utils.responseMeta(resp),
                invalidIds: !options?.deleteItem ? invalidIds : null,
                removedIds: options?.deleteItem ? invalidIds : null
              },
              data: (this.options.patchHasInvalidData || options?.hasInvalidData) ? null : resp?.data
            };
          });
        }
      }),
      delete: (params, options) => ({
        options: {
          dataType: constants.dataTypes.entity,
          delete: !options?.deleteProperty
        },
        action: (http, data, httpParams) => {
          const path = utils.resolvePath(params.path, data);
          const invalidIds = this.getIdsFromContext([params, data, httpParams]);

          return http.delete(path, httpParams, data).then((resp) => {
            return {
              meta: {
                ...utils.responseMeta(resp),
                invalidate: {
                  invalidIds: options?.deleteProperty ? invalidIds : null,
                  removedIds: !options?.deleteProperty ? invalidIds : null
                }
              },
              data: options?.deleteProperty ? ((this.options.deleteHasInvalidData || options?.hasInvalidData) ? null : resp?.data) : null
            };
          });
        }
      })
    }

    // mount the defaults
    Object.keys(this.api.default).forEach((type) => {
      Object.keys(this.api.default[type]).forEach((f) => {
        if (utils.isFunction(this.api.default[type][f])) {
          this.api[type] = this.api[type] ?? {};
          this.api[type][f] = this.api.default[type][f];
        }
      })
    });
  
    // override any function from the service
    Object.keys(this.options.api || {}).forEach((type) => {
      if (utils.isObject(this.options.api[type])) {
        Object.keys(this.options.api[type]).forEach((f) => {
          if (utils.isFunction(this.options.api[type][f])) {
            this.api[type] = this.api[type] ?? {};
            this.api[type][f] = this.options.api[type][f];
          }
        });
      }
    });
  }
}


