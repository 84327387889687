import React, {useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import Box from 'components/atoms/Layout/Box/Box';
import constants from 'helpers/constants';
import utils from 'helpers/utils';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import Button from 'components/atoms/Buttons/Button/Button';
import FormHelperText from 'components/atoms/Helpers/FormHelperText/FormHelperText';
import Markdown from 'components/atoms/Formatters/Markdown/Markdown';
import Typography, {Span} from 'components/atoms/Text/Typography/Typography';
import LinearProgress from 'components/atoms/Progress/LinearProgress/LinearProgress';
import StyledQuestionnaireCard from 'components/organisms/Cards/QuestionnaireCard/QuestionnaireCard.styles';
import Poll from '@mui/icons-material/Poll';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';
import {Adjust} from '@mui/icons-material';
import {useAuthUserId} from 'services/auth/auth.utils';

const QuestionnaireCard = React.forwardRef((props, ref) => {
  const {
    entity,
    collection,
    questionnaireQuestion,
    canUpdate,
    fieldData,
    onDirty,
    onChange,
    lastAnswer,
    isEditing,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'QuestionnaireCard', {
    static: ['canUpdate']
  });

  const innerRef = useRef(null);
  const formRef = useRef(null);
  const skipRef = useRef(false);

  useImperativeHandle(ref, () => innerRef.current);

  const [internalState, setInternalState] = useState({
    disabled: false,
    explain: false,
    values: {}
  });

  const userId = useAuthUserId();

  const questionnaireCard = useMemo(() => {
    const open = Boolean(questionnaireQuestion?.questionnaires?.find((qn) => {
      return entity?.openQuestions?.find((q) => +q.questionId === +questionnaireQuestion.questionId && +q.questionnaireId === +qn.questionnaireId);
    }));

    const edit = canUpdate && (Boolean(isEditing) || open);

    return {
      state: {
        ...internalState,
        edit: edit
      }
    }
  }, [internalState, canUpdate, isEditing, questionnaireQuestion?.questionId,
    questionnaireQuestion?.questionnaires, entity?.openQuestions]);

  useEffect(() => {
    setInternalState({
      disabled: false,
      explain: false,
      values: {}
    });
  }, [entity?.entityId, questionnaireCard.state.edit]);

  useLayoutEffect(() => {
    const edit = Boolean(isEditing) || questionnaireQuestion?.questionnaires?.find((qn) => {
      return entity?.openQuestions?.find((q) => +q.questionId === +questionnaireQuestion.questionId && +q.questionnaireId === +qn.questionnaireId);
    });

    setInternalState(utils.updater({edit}, true));
  }, [isEditing, questionnaireQuestion?.questionId, questionnaireQuestion?.questionnaires, entity?.openQuestions]);

  const statsMemo = useMemo(() => {
    const values = [];
    let answer, lastValue, usedBudget = 0;

    if (questionnaireQuestion?.questionnaires && questionnaireQuestion?.info) {
      let userIds = [];
      answer = lastAnswer ?? entity?.questionnaireUserAnswers?.[questionnaireQuestion?.questionId];
      lastValue = utils.isEmpty(answer?.processedValue) ? null : (
        (questionnaireQuestion.info.numeric && utils.isArray(answer.processedValue)) ?
          answer.processedValue[0]?.value : (answer.processedValue?.value ?? answer.processedValue)
      );

      questionnaireQuestion?.questionnaires
        .forEach((questionnaire) => {
          const questionnaireStats = fieldData.questionnaireStats?.find((qs) => +qs.questionnaireId === +questionnaire.questionnaireId);

          if (questionnaireQuestion.info.numeric) {
            const usage = (questionnaireStats?.answers ?? []).reduce((usage, answer) => {
              if (+answer.entityId === +entity?.entityId && +answer.parentQuestionId === +questionnaireQuestion?.questionId) {
                if (answer.userIds?.find((id) => +id === +userId)) {
                  usage += +answer.label;
                }
              }
              return usage;
            }, 0);

            usedBudget = Math.max(usage, usedBudget);
          }
          userIds = userIds.concat(questionnaireStats?.userIds ?? []);

          questionnaireQuestion.info.options.forEach((opt) => {
            let value = values.find((s) => s.option === opt);
            if (!value) {
              value = {
                option: opt,
                userIds: []
              }
              values.push(value);
            }

            const answers = questionnaireStats?.answers?.filter((answer) => {
              return +answer.entityId === +entity?.entityId &&
                +answer.parentQuestionId === +questionnaireQuestion?.questionId && (
                  answer.value.toString() === opt.value.toString() ||
                  answer.value.toString() === opt.tagId?.toString()
                );
            });

            answers?.forEach((answer) => {
              value.userIds = value.userIds.concat(answer.userIds);
            })

            if (utils.toArray(answer?.value).findIndex((v) => v.toString() === opt.value.toString()) !== -1 ||
              utils.toArray(answer?.value).findIndex((v) => v.toString() === opt.tagId?.toString()) !== -1) {
              value.userAnswer = answer;
            }
          });
        });

      userIds = utils.uniqueArray(userIds);
      values.forEach((value) => {
        value.label = value.option.label;
        value.votes = utils.uniqueArray(value.userIds).length;
        value.progress = (userIds.length > 0) ? ((value.votes / userIds.length) * 100) : 0;
      });
    }

    return {values, usedBudget, lastAnswer: answer, lastValue};
  }, [userId, lastAnswer, fieldData.questionnaireStats, questionnaireQuestion?.questionnaires, questionnaireQuestion?.info,
    entity?.entityId, entity?.questionnaireUserAnswers, questionnaireQuestion?.questionId]);

  const fields = useMemo(() => {
    const fields = [];

    if (questionnaireQuestion?.question && questionnaireQuestion?.info) {
      if (questionnaireCard.state.edit) {
        if (!questionnaireCard.state.explain) {
          if (questionnaireQuestion?.info.numeric) {
            fields.push({
              name: 'value',
              label: 'vote',
              type: constants.formFieldTypes.slider,
              options: questionnaireQuestion?.info.options
                .sort((a, b) => +a.value - +b.value)
                .map((v) => ({...v, value: +v.value})),
              required: true,
              initial: statsMemo.lastValue,
              disabled: questionnaireCard.state.disabled,
              validate: (value, testContext) => {
                if (questionnaireQuestion?.question?.budget > 0) {
                  const usedBudget = statsMemo.usedBudget + (utils.isDefined(value) ? (+(value ?? 0) - +(statsMemo.lastValue ?? 0)) : 0);
                  if ((questionnaireQuestion?.question?.budget - usedBudget) < 0) {
                    return testContext.createError({message: 'Not enough budget remaining for this vote'});
                  } else {
                    return true;
                  }
                } else {
                  return true;
                }
              },
              FormFieldProps: {
                autoFocus: false,
                hiddenLabel: true,
                defaultThumbs: false,
                size: 'medium',
                SliderProps: {
                  step: null
                }
              }
            });
          } else {
            fields.push({
              name: 'value',
              label: 'vote',
              type: constants.formFieldTypes.list,
              validation: questionnaireQuestion?.info.multiselect ? constants.formFieldValidationTypes.list : constants.formFieldValidationTypes.text,
              options: questionnaireQuestion?.info.options,
              required: true,
              initial: statsMemo.lastValue,
              disabled: questionnaireCard.state.disabled,
              FormFieldProps: {
                multiple: questionnaireQuestion?.info.multiselect,
                autoFocus: false,
                hiddenLabel: true,
                size: 'smaller',
                ListProps: {
                  catchFocus: false
                }
              }
            });
          }
        } else {
          fields.push({
            name: 'comment',
            label: 'Would you like to explain your vote?',
            placeholder: 'Explanation',
            inlineLabel: 'explanation',
            type: constants.formFieldTypes.textarea,
            initial: statsMemo.lastAnswer?.comment,
            disabled: questionnaireCard.state.disabled,
            FormFieldProps: {
              size: 'smaller',
              autoFocus: true,
              minRows: 3
            }
          })
        }
      }
    }

    return fields;
  }, [statsMemo.lastAnswer, statsMemo.lastValue, statsMemo.usedBudget, questionnaireQuestion?.question,
    questionnaireQuestion?.info, questionnaireCard.state.edit,
    questionnaireCard.state.explain, questionnaireCard.state.disabled]);

  const renderStatistics = () => {
    const votes = utils.uniqueArray(statsMemo.values.reduce((a, stat) => a.concat(stat.userIds), [])).length;
    const result = entity?.questionnaireAnswers?.[questionnaireQuestion?.questionId]?.value;

    return <Box className="QuestionnaireCard-stats">
      {statsMemo.values.map((value) => {
        return <Box className="QuestionnaireCard-stat">
          <Box className="QuestionnaireCard-stat-title">
            <Typography variant="subtitle2">{value.label}</Typography>
            {value.votes > 0 ? <Typography variant="body2" color="primary">
              {`${value.votes} vote${value.votes === 1 ? '' : 's'}`}
            </Typography> : null}
          </Box>
          <LinearProgress variant="determinate"
                          color={value.userAnswer ? 'warning' : 'primary'}
                          value={value.progress} />
        </Box>
      })}
      {utils.isDefined(result) ?
        <Box className="QuestionnaireCard-stat-result">
          {questionnaireQuestion.info?.numeric ?
            <Tooltip title="Overall result"
                     placement="bottom">
              <Typography variant="subtitle2">
                <Icon size="smaller" icon={Poll}/>
                <Span showTooltip={true}>{utils.toNumber(result).toFixed(2)}</Span>
              </Typography>
            </Tooltip> : <Typography variant="subtitle2">&nbsp;</Typography>}
          <Typography variant="body2" color="primary">
            {`${votes} vote${votes === 1 ? '' : 's'}`}
          </Typography>
        </Box> : null}
    </Box>
  }

  const handleSubmit = (values, actions) => {
    if (!questionnaireCard.state.explain) {
      actions.setSubmitting(false);

      const options = utils.toArray(values['value']).map((v) => {
        return questionnaireQuestion.info.options
          .find((opt) => {
            return +opt.tagId === +v?.tagId ||
              opt.value.toString() === (v.value ?? v).toString() ||
              opt.label.toString() === (v.value ?? v).toString();
          });
      });

      setInternalState(utils.updater({
        explain: true,
        values: {
          value: utils.isArray(values['value']) ? options : options[0]
        }
      }, true));
    } else {
      setInternalState(utils.updater({submitting: true}, true));

      const answer = (skipRef.current ? questionnaireCard.state.values : {
        ...questionnaireCard.state.values,
        ...values
      });

      utils.asPromise(onChange)(answer)
        .then(() => {
          setInternalState(utils.updater({disabled: true}, true));
        })
        .catch(() => {
          setInternalState(utils.updater({error: true}, true));
        })
        .finally(() => {
          actions.setSubmitting(false);
          setInternalState(utils.updater({submitting: false}, true));
        });
    }
  };

  const handleChange = (field, value) => {
    setInternalState((current) => {
      return utils.updater({
        error: null,
        value: field.name === 'value' ? value : current.value
      }, true)(current);
    });
  }

  const handleSkipClick = () => {
    skipRef.current = true;
    formRef.current?.submit();
  };

  const handleSubmitClick = () => {
    skipRef.current = false;
    formRef.current?.submit();
  };

  const handleValidating = (isValidating, isDirty) => {
    onDirty?.(isDirty);
  }

  const renderQuestion = () => {
    const usedBudget = statsMemo.usedBudget +
      (utils.isDefined(questionnaireCard.state.value) ? (+(questionnaireCard.state.value ?? 0) - +(statsMemo.lastValue ?? 0)) : 0);

    return <Box className={`QuestionnaireCard-question ${questionnaireCard.state.error ? 'error' : ''}`}>
      <InlineForm ref={formRef} className="QuestionnaireCard-form"
                  fields={fields}
                  onSubmit={handleSubmit}
                  onChange={handleChange}
                  onValidating={handleValidating}/>
      {isLoading ? <Box className="QuestionnaireCard-loaders">
        {(new Array(Math.ceil(Math.random() * 3) + 1)).fill(null).map((i, idx) => {
          return <Typography key={idx} min={8} max={24} isLoading={true}/>
        })}
      </Box> : null}
      <Box className="QuestionnaireCard-buttons">
        <Button type="submit"
                variant="contained"
                size="small"
                isLoading={isLoading}
                disabled={questionnaireCard.state.submitting || questionnaireCard.state.disabled}
                children={questionnaireCard.state.explain ? 'Submit' : 'Vote'}
                onClick={handleSubmitClick}/>
        {questionnaireCard.state.explain ? <Button children={'Skip'}
                                                   variant="text"
                                                   size="small"
                                                   disabled={questionnaireCard.state.submitting || questionnaireCard.state.disabled}
                                                   isLoading={isLoading}
                                                   onClick={handleSkipClick}/> : null}
        {(!questionnaireCard.state.explain && questionnaireQuestion?.info?.numeric && questionnaireQuestion?.question?.budget > 0) ?
          <Box className="QuestionnaireCard-budget">
            <Tooltip title="Remaining budget"
                     placement="bottom">
              <Typography variant="subtitle2">
                <Icon size="smaller" icon={Adjust}/>
                <Span showTooltip={true}>{utils.formatNumber(Math.max(0, (questionnaireQuestion.question.budget - usedBudget)))}</Span>
              </Typography>
            </Tooltip>
          </Box> : <Typography variant="subtitle2">&nbsp;</Typography>}
      </Box>
    </Box>
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledQuestionnaireCard ref={innerRef} {...innerProps}
                                  isLoading={isLoading}>
    {!questionnaireCard.state.edit ? renderStatistics() : renderQuestion()}
    {(questionnaireCard.state.edit && questionnaireQuestion?.question?.explanation) ? <FormHelperText component="div">
      <Markdown>{questionnaireQuestion?.question.explanation}</Markdown>
    </FormHelperText> : null}
  </StyledQuestionnaireCard>
});

QuestionnaireCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  entity: PropTypes.object,
  questionnaireQuestion: PropTypes.object,
  canUpdate: PropTypes.bool,
  onDirty: PropTypes.func,
  onChange: PropTypes.func,
  fieldData: PropTypes.object,
  lastAnswer: PropTypes.object,
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool
};

QuestionnaireCard.defaultProps = {
  elevation: 0
};

export default QuestionnaireCard;
