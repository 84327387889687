import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import constants from 'helpers/constants';
import EntitiesAnalyseMatchWizardContent
  from 'components/organisms/WizardContent/EntitiesAnalyseMatchWizardContent/EntitiesAnalyseMatchWizardContent';
import StyledEntitiesAnalyseWizard
  from 'components/organisms/Wizards/EntitiesAnalyseWizard/EntitiesAnalyseWizard.styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import EntitiesAnalyseActionsWizardContent
  from 'components/organisms/WizardContent/EntitiesAnalyseActionsWizardContent/EntitiesAnalyseActionsWizardContent';
import Save from '@mui/icons-material/Save';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import utils from 'helpers/utils';

const EntitiesAnalyseWizard = React.forwardRef((props, ref) => {
  const {
    collection,
    tableProvider,
    onSubmit,
    ...innerProps
  } = useComponentProps(props, 'EntitiesAnalyseWizard');

  const snackbar = useSnackbar();

  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const steps = useMemo(() => {
    return [
      {
        name: 'criteria',
        label: 'Criteria',
        resetNextSteps: true,
        enableStep: () => {
          return true;
        },
        fields: [
          {
            name: 'description',
            label: 'Company description',
            placeholder: 'Type a description of a company',
            entity: 'analyser',
            type: constants.formFieldTypes.textarea,
            validation: constants.formFieldValidationTypes.text,
            required: true,
            FormFieldProps: {
              variant: 'staticLabel'
            },
          },
          {
            name: 'matchType',
            label: 'How do you want to analyse the companies?',
            entity: 'analyser',
            type: constants.formFieldTypes.list,
            validation: constants.formFieldValidationTypes.text,
            conversion: constants.formFieldConversionTypes.value,
            options: 'entityAnalyserMatchTypes',
            required: true,
            FormFieldProps: {
              multiple: false,
              ListProps: {
                catchFocus: false
              }
            }
          }
        ]
      },
      {
        name: 'matching',
        shortLabel: 'Matches',
        title: 'Match the companies',
        enableStep: () => {
          return true;
        },
        Content: (props) => <EntitiesAnalyseMatchWizardContent ref={props.ref}
                                                               className={props.className}
                                                               wizard={props.wizard}
                                                               step={props.step}
                                                               tableProvider={tableProvider}
                                                               collection={collection}
                                                               onComplete={props.onComplete}
                                                               onError={props.onError}
                                                               onDirty={props.onDirty}/>
      },
      {
        name: 'actions',
        shortLabel: 'Actions',
        enableStep: () => {
          return true;
        },
        Content: (props) => <EntitiesAnalyseActionsWizardContent ref={props.ref}
                                                                 className={props.className}
                                                                 wizard={props.wizard}
                                                                 step={props.step}
                                                                 tableProvider={tableProvider}
                                                                 collection={collection}
                                                                 onComplete={props.onComplete}
                                                                 onError={props.onError}
                                                                 onDirty={props.onDirty}/>
      }
    ];
  }, [collection, tableProvider]);

  const handleSubmit = (data, actions, onSuccess, onError) => {
    data = data.entitiesAnalyse;

    const entities = (data?.entityIds ?? [])
      .slice(0, ((data?.[data?.questionHash]?.batch + 1) * constants.analyse.batchSizes.match))
      .filter((entityId) => data?.entities?.[entityId]?.questionHash === data?.questionHash)
      .filter((entityId) => utils.isDefined(data?.entities?.[entityId]?.evidenceTime))
      .map((entityId) => data?.entities?.[entityId]);

    const selectedEntities = entities.filter((entity) => {
      return data.selectedTags.find((t) => (+entity.tagGroups?.[0]?.tags?.[0]?.tagId === +(t?.value ?? t)));
    })

    const tagGroup = data?.fieldData?.tagGroups?.find((tg) => +tg.groupId === +data?.question.analyserParams.tagGroup);

    const entityActions = [data.collectionAction].concat(utils.toArray(data.otherActions))
      .map((ea) => {
        let actionCollection = null;

        if (utils.isDefined(data.collection?.label)) {
          actionCollection = {
            name: data.collection.label,
            collectionId: data.collection.value,
            visibility: constants.collection.visibility.client,
            projectSources: [{sourceId: 0, type: constants.sources.types.suggestions}],
            autotagPeriod: data.autotagPeriod
          }
        } else if (collection) {
          actionCollection = {
            name: collection.name,
            collectionId: collection.collectionId,
            autotagPeriod: data.autotagPeriod
          }
        }

        let actionTagGroups, actionEvidences;
        if ([constants.analyse.entityAnalyserActions.createTagGroups,
          constants.analyse.entityAnalyserActions.createTagGroup].includes(ea?.value ?? ea)) {
          actionTagGroups = [{
            ...tagGroup,
            name: data.tagGroup,
            color: innerProps.theme.pickAColor('tagGroup'),
            tags: tagGroup.tags.map((t) => {
              return {
                ...t,
                entityIds: selectedEntities
                  .filter((entity) => (+entity.tagGroups?.[0]?.tags?.[0]?.tagId === +t.tagId))
                  .map((entity) => entity.entityId)
              };
            })
          }];

          actionEvidences = selectedEntities
            .reduce((o, entity) => {
              const evidences = data?.[data?.questionHash]?.evidences?.[entity.entityId];
              if (evidences?.evidences?.length > 0) {
                o[entity.entityId] = evidences?.evidences;
              }
              return o;
            }, {});
        }

        let actionDealLeaderId;
        if ([constants.analyse.entityAnalyserActions.setDealLeader].includes(ea?.value ?? ea)) {
          actionDealLeaderId = +data.dealLeader.value;
        }

        let actionDealflowStatusId;
        if ([constants.analyse.entityAnalyserActions.setDealflowStatus].includes(ea?.value ?? ea)) {
          actionDealflowStatusId = +data.dealflowStatus.value;
        }

        return {
          action: (ea?.value ?? ea),
          collection: actionCollection,
          evidences: actionEvidences,
          tagGroups: actionTagGroups,
          dealLeaderId: actionDealLeaderId,
          dealflowStatusId: actionDealflowStatusId
        }
      });

    const entityIds = (data?.entityIds ?? []).filter((entityId) => {
      return selectedEntities.find((e) => +e.entityId === +entityId) || (
        utils.isDefined(data?.selectedTags.find((t) => +(t?.value ?? t) === 0)) &&
        !utils.isDefined(entities.find((e) => +e.entityId === +entityId)?.tagGroups?.[0])
      )
    });

    return utils.asPromise(onSubmit)(entityIds, entityActions)
      .then(() => {
        onSuccess?.();
      })
      .catch(() => {
        onError?.()
        snackbar.show(`Saving the selected actions failed`, null, {color: 'error', autoHideDuration: constants.delay.error});
      });
  }

  const initialData = useMemo(() => ({
    'entitiesAnalyse': {
      matchType: constants.analyse.entityAnalyserMatchTypes.gptAnalyser,
      collectionAction: collection?.collectionId > 0 ? constants.data.lookup('entityAnalyserActions', constants.analyse.entityAnalyserActions.applyToExisting) :
        constants.data.lookup('entityAnalyserActions', constants.analyse.entityAnalyserActions.addToCollection),
      otherActions: constants.data.lookup('entityAnalyserActions', constants.analyse.entityAnalyserActions.createTagGroup)
    }
  }), [collection?.collectionId]);

  const saveActionMemo = useMemo(() => {
    return {
      label: mdDown ? 'Save' : 'Save actions',
      icon: Save,
      iconPosition: 'start',
      ButtonProps: {
        color: 'primary'
      }
    }
  }, [mdDown]);

  return <StyledEntitiesAnalyseWizard ref={ref} {...innerProps}
                                      steps={steps}
                                      dataKey="entitiesAnalyse"
                                      initialData={initialData}
                                      onSubmit={handleSubmit}
                                      saveAction={saveActionMemo} />
});

EntitiesAnalyseWizard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  collection: PropTypes.object,
  tableProvider: PropTypes.object,
  onSubmit: PropTypes.func
};

EntitiesAnalyseWizard.defaultProps = {};

export default EntitiesAnalyseWizard;
