import React, {useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import constants from 'helpers/constants';
import DialogHeader from 'components/molecules/Dialogs/DialogHeader/DialogHeader';
import StyledEntitiesAnalyseDialog from 'components/organisms/Dialogs/EntitiesAnalyseDialog/EntitiesAnalyseDialog.styles';
import utils from 'helpers/utils';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import EntitiesAnalyseChatDialogContent
  from 'components/organisms/Dialogs/EntitiesAnalyseChatDialogContent/EntitiesAnalyseChatDialogContent';
import DialogContent from 'components/atoms/Dialogs/DialogContent/DialogContent';
import DataList from 'components/organisms/Lists/DataList/DataList';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import AnalyserCard from 'components/organisms/Cards/AnalyserCard/AnalyserCard';
import EntitiesAnalyseMatchDialogContent
  from 'components/organisms/Dialogs/EntitiesAnalyseMatchDialogContent/EntitiesAnalyseMatchDialogContent';
import EntitiesAnalyseGraphDialogContent
  from 'components/organisms/Dialogs/EntitiesAnalyseGraphDialogContent/EntitiesAnalyseGraphDialogContent';
import EntitiesAnalyseTagDialogContent
  from 'components/organisms/Dialogs/EntitiesAnalyseTagDialogContent/EntitiesAnalyseTagDialogContent';

const EntitiesAnalyseDialog = React.forwardRef((props, ref) => {
  const {
    collection,
    tableProvider,
    analyserTypes,
    onSubmit,
    ...innerProps
  } = useComponentProps(props, 'EntitiesAnalyseDialog', {
    variable: ['type'],
    children: ['header', 'content', 'footer']
  });

  const innerRef = useRef(null);
  const contentRef = useRef(null);

  const authorize = useAuthorize();
  const dialogControl = useDialogControl();

  const resultsCount = Math.min(tableProvider.listSelection?.max, tableProvider.list?.meta?.resultsCount);
  const resultsCountOver = tableProvider.list?.meta?.resultsCount > tableProvider.listSelection?.max;

  const [internalState, setInternalState] = useState({
    dirty: false,
    type: null
  });

  useImperativeHandle(ref, () => innerRef.current);

  const handleClose = (e, reason) => {
    if (!internalState.dirty || ['saveButtonClick'].includes(reason)) {
      innerProps.onClose?.(e, reason);
    } else if (['escapeKeyDown', 'closeButtonClick', 'cancelButtonClick'].includes(reason)) {
      const handleConfirm = () => {
        contentRef.current?.cleanup?.();
        innerProps.onClose?.(e, reason);
      }

      dialogControl.show(<ConfirmDialog question="Are you sure you want to exit this analysis?"
                                        explanation="The dialog will close and your analysis will be lost"
                                        onConfirm={handleConfirm}/>, true);

    }
  }

  const handleDirty = (isDirty) => {
    setInternalState(utils.updater({dirty: isDirty}, true));
  }

  const renderCard = (analyserType, state) => {
    return <AnalyserCard analyser={analyserType}
                         variant="standard"
                         fullWidth={true}
                         selected={state.selected}
                         onClick={() => {
                           setInternalState(utils.updater({type: analyserType}, analyserType));
                         }}
                         isLoading={state.isLoading}
                         IconProps={{color: analyserType.color}}/>
  }

  const isItemEqual = (analyser, selected) => {
    return +analyser.value === +selected.value;
  }

  const typeData = useMemo(() => {
    return constants.data.entityAnalyserTypes
      .filter((ea) => ea.enabled)
      .filter((ea) => !analyserTypes || analyserTypes.find((a) => a.value === ea.value))
      .filter((ea) => !ea.collection || utils.isDefined(collection))
      .filter((ea) => authorize({attribute: `entity.analyser.${ea.value}.create`}));
  }, [analyserTypes, collection, authorize]);

  const renderTypeChoice = () => {
    return <DialogContent ref={contentRef}
                          className="EntitiesAnalyseDialog-content EntitiesAnalyseDialog-content-type">
      <DataList data={typeData}
                count={typeData.length}
                dataKey="value"
                renderItem={renderCard}
                isItemEqual={isItemEqual}
                isLoading={false}
                ListProps={{
                  virtualize: false,
                  track: true,
                  gap: 8
                }} />
    </DialogContent>
  };

  const renderContent = () => {
    switch (internalState.type?.value) {
      case constants.analyse.entityAnalyserTypes.match:
        return <EntitiesAnalyseMatchDialogContent ref={contentRef}
                                                  className="EntitiesAnalyseDialog-content"
                                                  collection={collection}
                                                  tableProvider={tableProvider}
                                                  dirty={internalState.dirty}
                                                  onDirty={handleDirty}
                                                  onClose={handleClose}
                                                  onSubmit={onSubmit}/>
      case constants.analyse.entityAnalyserTypes.tag:
        return <EntitiesAnalyseTagDialogContent ref={contentRef}
                                                className="EntitiesAnalyseDialog-content"
                                                collection={collection}
                                                tableProvider={tableProvider}
                                                dirty={internalState.dirty}
                                                onDirty={handleDirty}
                                                onClose={handleClose}
                                                onSubmit={onSubmit}/>
      case constants.analyse.entityAnalyserTypes.graph:
        return <EntitiesAnalyseGraphDialogContent ref={contentRef}
                                                  className="EntitiesAnalyseDialog-content"
                                                  collection={collection}
                                                  tableProvider={tableProvider}
                                                  dirty={internalState.dirty}
                                                  onDirty={handleDirty}
                                                  onClose={handleClose}
                                                  onSubmit={onSubmit}/>
      case constants.analyse.entityAnalyserTypes.chat:
        return <EntitiesAnalyseChatDialogContent ref={contentRef}
                                                 className="EntitiesAnalyseDialog-content"
                                                 collection={collection}
                                                 tableProvider={tableProvider}
                                                 dirty={internalState.dirty}
                                                 onDirty={handleDirty}
                                                 onClose={handleClose}
                                                 onSubmit={onSubmit}/>
      default: {
        return renderTypeChoice();
      }
    }
  }

  const renderTitle = () => {
    return internalState.type?.label ?? 'Company analyser';
  }

  innerProps.className = utils.flattenClassName(innerProps.className, {
    type: internalState.type?.value
  });

  return <StyledEntitiesAnalyseDialog ref={innerRef} {...innerProps} onClose={handleClose}>
    <DialogHeader className="EntitiesAnalyseDialog-header"
                  title={renderTitle()}
                  subtitle={resultsCountOver ?
                    `For the first ${utils.formatNumber(resultsCount)} compan${resultsCount === 1 ? 'y' : 'ies'}` :
                    `For ${utils.formatNumber(resultsCount)} compan${resultsCount === 1 ? 'y' : 'ies'}`
                  }/>
    {renderContent()}
  </StyledEntitiesAnalyseDialog>
});

EntitiesAnalyseDialog.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  collection: PropTypes.object,
  analyserTypes: PropTypes.array,
  tableProvider: PropTypes.object,
  onSubmit: PropTypes.func
};

EntitiesAnalyseDialog.defaultProps = {};

export default EntitiesAnalyseDialog;
