import {useCallback, useMemo} from 'react';
import {
  useCommentAdd,
  useCommentMarkDone,
  useCommentMarkUndone,
  useCommentUpdate
} from 'services/comment/comment.hooks';
import utils from 'helpers/utils';
import commentUtils from 'helpers/comment';
import {useAuthTeamId, useAuthUserId} from 'services/auth/auth.utils';
import constants from 'helpers/constants';
import {useClientCallbacks} from 'services/client/client.utils';

export function processComment (comment) {
  return utils.camelcaseEx(comment);
}

export function useTaskResponsibility (task, userId) {
  return useMemo(() => {
    if (task?.actionItem) {
      const tasks = task.isGroup ? task.comments : [task];

      const isResponsible = (task?.actionItem?.responsible ?? []).length === 0 || Boolean((task?.actionItem?.responsible ?? []).find((r) => +r.userId === +userId));

      const isDone = task?.actionItem?.isDone ||
        (isResponsible && (Boolean(!tasks.some((t) => {
          return !t?.actionItem?.isDone && ((t?.actionItem?.responsible ?? []).length === 0 ||
            (t.actionItem?.responsible ?? []).find((r) => +r.userId === +userId && !r.isDone))
        }))));

      const open = (!isResponsible || isDone) ? [] : tasks.filter((t) => {
        return !t?.actionItem?.isDone && ((t?.actionItem?.responsible ?? []).length === 0 ||
          (t.actionItem?.responsible ?? []).find((r) => +r.userId === +userId && !r.isDone));
      });

      return [isResponsible, isDone, open, tasks];
    } else {
      return [false, false, null, null];
    }
  }, [task, userId]);
}

export function useTaskLocation (task, timeline = false) {
  return useMemo(() => {
    if (task) {
      let entities = task.isGroup ? task.comments.map((c) => c.entity) : [task.entity];
      if (timeline) {
        entities = task.groupId > 0 ? task.entities : [task.entity];
      }

      if (+task.collectionId > 0 && task.collection) {
        return {
          ...constants.data.lookup('taskLocations', constants.task.locations.collection),
          label: task.collection.name
        };
      }
      if (!entities.some((e) => !(+e?.csi?.status > 0))) {
        return constants.data.lookup('taskLocations', constants.task.locations.dealflow);
      }
      return constants.data.lookup('taskLocations', constants.task.locations.database);
    } else {
      return constants.data.lookup('taskLocations', constants.task.locations.database);
    }
  }, [task, timeline]);
}

export function useTaskCompanyAction (task, timeline = false) {
  const userId = useAuthUserId();
  const taskLocation = useTaskLocation(task, timeline);
  const [isResponsible] = useTaskResponsibility(task, userId);

  return useMemo(() => {
    if (task) {
      const actionItem = timeline ? task.extraInfo?.actionItem : task.actionItem;

      let gotoProfile = true;
      let custom = `custom=task:${+task.groupId}_${task.commentId}_${isResponsible ? 'active' : 'closed'}`;
      if (actionItem?.type === constants.task.types.questionnaire) {
        custom = `custom=questionnaire:${+actionItem.contextId}_${isResponsible ? 'active' : 'closed'}`;
      }

      if (task.entity && (!task.isGroup || task.comments.length === 1)) {
        if (taskLocation.value === constants.task.locations.collection) {
          return {
            label: task.entity.name,
            navigation: {
              to: `/collections/${task.collection.collectionId}/entities/${task.entity.entityId}?custom=entity:${task.entity.entityId}&${custom}`,
              resetSearchParams: true
            }
          };
        } else {
          return {
            label: task.entity.name,
            navigation: {
              to: `/${taskLocation.value}/${task.entity.entityId}?custom=entity:${task.entity.entityId}&${custom}`,
              resetSearchParams: true
            }
          };
        }
      } else {
        const count = (task.on?.ids?.length ?? task.count) ?? 1;
        let entities = task.isGroup ? task.comments.map((c) => c.entity) : [task.entity];
        if (timeline) {
          entities = task.groupId > 0 ? task.entities : [task.entity];
        }
        const entity = (gotoProfile && entities.length > 0) ? (entities.length > 1 ? {entityId: 0} : entities[0]) : null;
        const entityCount = utils.formatNumber(count ?? 0);

        const label = `${entityCount} compan${(count ?? 0) === 1 ? 'y' : 'ies'}`;
        if (taskLocation.value === constants.task.locations.collection) {
          if (entity) {
            return {
              label: label,
              navigation: {
                to: `/collections/${task.collection.collectionId}/entities/${entity.entityId}?${custom}`,
                resetSearchParams: true
              }
            };
          } else {
            return {
              label: label,
              navigation: {
                to: `/collections/${task.collection.collectionId}/entities?${custom}`,
                resetSearchParams: true
              }
            };
          }
        } else if (taskLocation.value === constants.task.locations.dealflow) {
          if (entity) {
            return {
              label: label,
              navigation: {
                to: `/${taskLocation.value}/${entity.entityId}?${custom}`,
                resetSearchParams: true
              }
            };
          } else {
            return {
              label: label,
              navigation: {
                to: `/${taskLocation.value}/table?${custom}`,
                resetSearchParams: true
              }
            };
          }
        } else {
          if (entity) {
            return {
              label: label,
              navigation: {
                to: `/${taskLocation.value}/${entity.entityId}?${custom}`,
                resetSearchParams: true
              }
            };
          } else {
            return {
              label: label,
              navigation: {
                to: `/${taskLocation.value}?${custom}`,
                resetSearchParams: true
              }
            };
          }
        }
      }
    }
  }, [task, taskLocation, timeline, isResponsible]);
}

export function useTaskProgressStats (task) {
  return useMemo(() => {
    if (task) {
      const tasks = task.isGroup ? task.comments : [task];
      const responsible = (task?.actionItem?.responsible ?? []).length === 0 ? [constants.client.team.allMembers] : task?.actionItem?.responsible;

      return responsible.map((member) => {
        const doneCount = tasks.reduce((c, t) => {
          if (t.actionItem?.isDone || (t?.actionItem?.responsible ?? []).find((r) => +r.userId === +member.userId && Boolean(r.isDone))) {
            return c + 1;
          } else {
            return c;
          }
        }, 0);

        return {
          ...member,
          doneCount
        }
      });
    } else {
      return null;
    }
  }, [task]);
}

export function useCommentToggleTaskDone () {
  const commentDone = useCommentMarkDone();
  const commentUndone = useCommentMarkUndone();

  return useCallback((comment, done) => {
    done = utils.isDefined(done) ? done : !comment.isDone;

    return (!done ? commentUndone.mutation.mutateAsync : commentDone.mutation.mutateAsync)(
      {commentId: comment.commentId}
    );
  }, [commentDone.mutation, commentUndone.mutation])
}

export function useCommentCreate (mutationOptions = {}) {
  const teamId = useAuthTeamId();
  const commentAdd = useCommentAdd(mutationOptions);

  return useCallback((text, mentions = null, task = false, title = null, type = null, responsible = null, collaborationType = null, dueDate = null) => {
    const comment = commentUtils.createComment(text, 'csi', [teamId], null, mentions, task, title, type, responsible, collaborationType, dueDate);

    return commentAdd.mutation.mutateAsync({
      ...comment
    });
  }, [commentAdd.mutation, teamId]);
}

export function useCommentPatch (mutationOptions = {}) {
  const commentPatch = useCommentUpdate(mutationOptions);

  return useCallback((comment, changes) => {
    changes = commentUtils.calcCommentChanges(comment, changes);

    if (!utils.isEmpty(changes)) {
      return commentPatch.mutation.mutateAsync({
        commentId: comment.commentId,
        ...changes
      });
    } else {
      return Promise.resolve();
    }
  }, [commentPatch.mutation])
}

export function useTaskPatch (mutationOptions = {}) {
  const commentPatch = useCommentPatch(mutationOptions);

  return useCallback((task, changes) => {
    const changed = {};
    if (changes.hasOwnProperty('comment')) {
      changed.text = changes.comment;
    }
    return commentPatch(task, changed);
  }, [commentPatch]);
}

export function useTaskGroups (view) {
  return useMemo(() => {
    const groups = [];

    Object.keys(constants.task.groupDefinition).forEach((k) => {
      if (!utils.isFunction(constants.task.groupDefinition[k])) {
        if (!groups.find((g) => g.name === k)) {
          const groupDef = constants.groupDefinition.lookup('task', view, k, false, utils.mergeObjects);

          groups.push({
            ...groupDef,
            name: k,
            position: groupDef.position ??
              groups.reduce((p, g) => Math.max(p, g.position), 0),
            fields: utils.object2Array(groupDef.fields).map((f, idx) => {
              return {
                ...f,
                entity: 'task',
                position: f.position ?? idx,
                auth: {
                  read: utils.createAuth({attribute: `task.field.${f.name}.read`}),
                  update: utils.createAuth({attribute: `task.field.${f.name}.update`})
                },
                required: f.required ?? false
              };
            }) ?? []
          });
        }
      }
    });

    return groups;
  }, [view]);
}

export function useTaskSections (view) {
  return useMemo(() => {
    const sections = [];
    Object.keys(constants.task.sectionDefinition).forEach((k) => {
      if (!utils.isFunction(constants.task.sectionDefinition[k])) {
        const sectionDef = constants.sectionDefinition.lookup('task', view, k, utils.mergeObjects);
        const lastPos = sections.reduce((p, s) => Math.max(p, s.position ?? 0), 0);
        sections.push({
          ...sectionDef,
          name: k,
          position: (sectionDef.position ?? (lastPos + 1)),
          auth: {
            read: utils.createAuth({attribute: `task.section.${sectionDef.name}.read`}),
            update: utils.createAuth({attribute: `task.section.${sectionDef.name}.update`})
          },
          cards: utils.object2Array(sectionDef?.cards).map((cardDef) => ({
            ...cardDef,
            auth: {
              read: utils.createAuth({attribute: `task.section.${cardDef.name}.read`}),
              update: utils.createAuth({attribute: `task.section.${cardDef.name}.update`})
            }
          }))
        });
      }
    });

    return sections;
  }, [view]);
}

export function useTaskProfileCallbacks () {
  const clientCallbacks = useClientCallbacks();

  return useMemo(() => {
    return {
      ...clientCallbacks
    }
  }, [clientCallbacks]);
}
