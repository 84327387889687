import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useEffectItem} from 'helpers/hooks/utils';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import constants from 'helpers/constants';
import Search from '@mui/icons-material/Search';
import Close from '@mui/icons-material/Close';
import Icon from 'components/atoms/Icons/Icon/Icon';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import StyledSimpleQuery from 'components/organisms/Queries/SimpleQuery/SimpleQuery.styles';
import {default as searchUtils} from 'helpers/search';
import Button from 'components/atoms/Buttons/Button/Button';
import AutoAwesome from '@mui/icons-material/AutoAwesome';
import Box from 'components/atoms/Layout/Box/Box';
import utils from 'helpers/utils';
import Chip from 'components/atoms/Chips/Chip/Chip';
import ChipList from 'components/atoms/Chips/ChipList/ChipList';
import Add from '@mui/icons-material/Add';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import EntitySelectionDialog from 'components/organisms/Dialogs/EntitySelectionDialog/EntitySelectionDialog';

const SimpleQuery = React.forwardRef((props, ref) => {
  const {
    query,
    search,
    wizard,
    examples,
    filter,
    active,
    fieldData,
    onChange,
    ...innerProps
  } = useComponentProps(props, 'SimpleQuery', {
    static: ['wizard']
  });

  const searchFieldRef = useRef(null);
  const lookupKeyRef = useRef(`${SimpleQuery}_${Date.now()}`);
  const [wizardActive, setWizardActive] = useState(null);
  const [entities, setEntities] = useState([]);

  const dialogControl = useDialogControl();

  const wizardMode = useMemo(() => {
    if (active) {
      return utils.isDefined(wizardActive) ? wizardActive :
        !Boolean(search) && (Boolean(wizard) || examples?.length > 0);
    } else {
      return null;
    }
  }, [active, search, wizard, examples, wizardActive]);

  const onChangeEvent = useEffectEvent(onChange);
  const doChange = useCallback((wizardActive, search, wizard, examples) => {
    const newQuery = searchUtils.simpleQuery(query, search, wizard, examples, filter);
    onChangeEvent?.(newQuery, search, wizard, examples);
    setWizardActive(wizardActive);
  }, [query, filter, onChangeEvent]);

  const debouncedChange = useMemo(() => {
    return utils.debounce((wizardActive, search, wizard, examples) => {
      doChange(wizardActive, search, wizard, examples);
    }, constants.debounce.query);
  }, [doChange]);

  useEffect(() => {
    if (utils.isDefined(wizardMode)) {
      doChange(wizardMode, wizardMode ? '' : (search || wizard), wizardMode ? (wizard || search) : '', wizardMode ? examples : []);
    }
  }, [wizardMode, search, wizard, examples, doChange]);

  const examplesMemo = useEffectItem(examples);
  const entitiesEvent = useEffectEvent(fieldData?.callbacks?.entities);
  useEffect(() => {
    let active = true;

    if (examplesMemo?.length > 0) {
      entitiesEvent?.({
        key: lookupKeyRef.current,
        search: '',
        ids: examplesMemo,
        callback: (results) => {
          if (active) {
            setEntities(results.map((e) => e.entity));
          }
        }
      });
    } else {
      setEntities([]);
    }

    return () => {
      active = false;
    };
  }, [examplesMemo, entitiesEvent]);

  const handleChange = (field, value) => {
    doChange(wizardActive, wizardActive ? '' : value, wizardActive ? value : '', wizardActive ? examples : []);
  };

  const fields = useMemo(() => {
    const initialSearch = wizardActive ? wizard : search?.replace(/(\n)+/g, ' ');

    return [{
      name: wizardActive ? 'wizard' : 'search',
      inlineLabel: wizardActive ? 'Wizard text' : 'Search text',
      placeholder: wizardActive ? 'Type a description of a company' : 'Search for a company',
      type: wizardActive ? constants.formFieldTypes.textarea : constants.formFieldTypes.text,
      validation: constants.formFieldValidationTypes.text,
      initial: initialSearch,
      prefix: <Icon icon={Search}/>,
      postfix: <Box className="SimpleQuery-adornment">
        {(initialSearch?.length > 0) ? <ActionIconButton action={{
          icon: <Icon icon={Close}/>,
          onClick: (e) => {
            doChange(false, '', '', []);
            searchFieldRef.current?.focus();
            e.preventDefault();
          }
        }}/> : null}
        <Button size="smaller"
                plainBorder={!wizardActive}
                color={!wizardActive ? 'secondary' : 'primary'}
                variant={!wizardActive ? 'outlined' : 'contained'}
                startIcon={<Icon icon={AutoAwesome}/>}
                onClick={() => {
                  doChange(!wizardActive, wizardActive ? wizard : '', wizardActive ? '' : search, wizardActive ? [] : examples);
                }}>
          Wizard
        </Button>
      </Box>,
      debounce: wizardActive ? constants.debounce.multiline : constants.debounce.search,
      FormFieldProps: {
        ref: searchFieldRef,
        minRows: 1,
        hiddenLabel: true,
        variant: 'outlined'
      }
    }];
  }, [wizardActive, search, wizard, examples, doChange]);

  const addExampleAction = useMemo(() => ({
    label: entities.length === 0 ? 'Add example company' : 'Add example',
    icon: Add,
    onClick: () => {
      const handleSubmit = (selected) => {
        if (selected.length > 0) {
          const newEntities = entities.concat(selected);
          doChange(wizardActive, wizardActive ? '' : search, wizardActive ? wizard : '', wizardActive ? newEntities.map((e) => +e.entityId) : []);
        }
      };

      dialogControl.show(<EntitySelectionDialog title="Add example company"
                                                entities={entities}
                                                hideEntities={true}
                                                SaveButtonProps={{
                                                  color: 'success',
                                                  startIcon: <Icon icon={Add}/>,
                                                  children: 'Add company'
                                                }}
                                                onSubmit={handleSubmit}/>, true);
    }
  }), [entities, search, wizard, wizardActive, doChange, dialogControl]);

  const handleDeleteExample = (entity) => () => {
    const newEntities = entities.filter((e) => +e.entityId !== +entity.entityId);
    setEntities(newEntities);
    debouncedChange(wizardActive, wizardActive ? '' : search, wizardActive ? wizard : '', wizardActive ? newEntities.map((e) => +e.entityId) : []);
  }

  const renderOptions = () => {
    if (wizardActive) {
      return <Box className="SimpleQuery-options">
        <Box className="SimpleQuery-options-chips">
          <ChipList>
            {entities.map((entity, idx) => {
              return <Chip key={idx}
                           variant="filled"
                           size="small"
                           color="primary"
                           radius="round"
                           label={entity.name}
                           onDelete={handleDeleteExample(entity)}/>
            })}
            <ActionButton className="SimpleQuery-options-add"
                          action={addExampleAction}
                          variant="text"
                          size="smallest"
                          color="primary"
                          radius="round" />
          </ChipList>
        </Box>
      </Box>
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className, {
    wizard: wizard
  });

  return <StyledSimpleQuery ref={ref} {...innerProps}>
    <InlineForm className="SimpleQuery-form"
                onChange={handleChange}
                fields={fields}/>
    {renderOptions()}
  </StyledSimpleQuery>
});

SimpleQuery.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  search: PropTypes.string,
  wizard: PropTypes.string,
  examples: PropTypes.array,
  query: PropTypes.object,
  active: PropTypes.bool,
  fieldData: PropTypes.object,
  onChange: PropTypes.func
};

SimpleQuery.defaultProps = {};

export default SimpleQuery;
