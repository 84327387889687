import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react'
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import constants from 'helpers/constants';
import utils from 'helpers/utils';
import EntitiesTableProvider from 'components/organisms/Providers/TableProvider/EntitiesTableProvider';
import {useEffectEvent, useEffectItem, useProviderView} from 'helpers/hooks/utils';
import {useEntityList} from 'services/entity/entity.hooks';
import {useAuthClientId, useAuthTeamId} from 'services/auth/auth.utils';
import {
  getEntityCsi,
  useDealflowEntityColumns,
  useDealflowEntityFilterGroups, useDealflowEntityGraphs,
  useDealflowEntityPanels
} from 'services/entity/entity.utils';
import {useInvalidateQuery} from 'helpers/hooks/query';
import {useClientGet} from 'services/client/client.hooks';
import {useAuthClient} from 'components/organisms/Providers/AuthProvider/AuthProvider';

const DealflowEntitiesTableLoader = ({children, active}) => {
  const tableProvider = useTable();
  const listState = useEffectItem(tableProvider.appliedListState());

  const list = useEntityList({
    clientId: tableProvider.context?.data?.clientId,
    search: listState.search,
    filter: listState.filter,
    sort: listState.sort,
    page: listState.pagination.pageIndex,
    pageSize: listState.pagination.pageSize
  }, {
    ...constants.queryOptions.infinite,
    enablePreload: true,
    enabled: (active && listState.active) && tableProvider.context?.data?.clientId >= 0
  });

  const setListEvent = useEffectEvent(tableProvider.setList);
  useLayoutEffect(() => {
    setListEvent?.(list);
  }, [setListEvent, list]);

  return <React.Fragment>
    {children}
  </React.Fragment>
}

const DealflowEntitiesTableTestLoader = ({children, active}) => {
  const tableProvider = useTable();
  const listState = tableProvider.appliedListState('test');

  const list = useEntityList({
    clientId: tableProvider.context?.data?.clientId,
    search: listState.search,
    filter: listState.filter,
    sort: listState.sort,
    page: listState.pagination.pageIndex,
    pageSize: listState.pagination.pageSize
  }, {
    ...constants.queryOptions.runOnce,
    enabled: (active && listState.active) && tableProvider.context?.data?.clientId >= 0
  });

  const setTestListEvent = useEffectEvent(tableProvider.setTestList);
  useLayoutEffect(() => {
    setTestListEvent?.(list);
  }, [setTestListEvent, list]);

  return <React.Fragment>
    {children}
  </React.Fragment>
}

const DealflowEntitiesTableKanbanListLoader = ({children, options, active}) => {
  const tableProvider = useTable();
  const listState = tableProvider.appliedListState('kanban');

  const queryKeyRef = useRef(null);
  const enabled = active && tableProvider.context?.data?.clientId >= 0 &&
    !(tableProvider.list?.status?.isLoading || !tableProvider.list?.status?.isSuccess);

  // these kanban lists are temporary and can not refreshed
  // by the service because they are not connected to anything
  const teamId = useAuthTeamId();
  const invalidateQuery = useInvalidateQuery();
  const handleMonitor = (prev, next) => {
    if (queryKeyRef.current && utils.isDefined(tableProvider.kanbanLists[options.id]?.data)) {
      if (!(prev?.preload || prev?.dummy) && !(next?.preload || next?.dummy)) {
        const prevStatusId = getEntityCsi(prev?.data, teamId)?.status;
        const nextStatusId = getEntityCsi(next?.data, teamId)?.status;

        if ((+prevStatusId === +options?.id || +nextStatusId === +options?.id) && +prevStatusId !== +nextStatusId) {
          invalidateQuery(queryKeyRef.current.slice(0, 3), false, true);
        }
      }
    }
  };

  const filter = useMemo(() => {
    const statusFilter = (listState.filter ?? []).find((f) => f.id === 'status');
    const filteredOut = !statusFilter ? false : (
      utils.toArray(statusFilter.value).find((s) => s.toString() !== '-0') && (
        (
          utils.toArray(statusFilter.value).find((s) => !s.toString().startsWith('-')) &&
          !utils.toArray(statusFilter.value).find((s) => s.toString() === `${options.id}`)
        ) ||
        utils.toArray(statusFilter.value).find((s) => s.toString() === `-${options.id}`)
      )
    )

    if (filteredOut) {
      return [{id: 'status', value: `${options.id}`}, {id: 'status', value: `-${options.id}`}];
    } else {
      return utils.applyFilters(listState.filter, [{id: 'status', value: +options.id}], ['status']);
    }
  }, [options.id, listState.filter]);

  const sort = useMemo(() => {
    return utils.addSort(null, {id: 'dealflow_changed', desc: true});
  }, []);

  const list = useEntityList({
    clientId: tableProvider.context?.data?.clientId,
    search: listState.search,
    filter: filter,
    sort: sort,
    page: listState.pagination.pageIndex,
    pageSize: listState.pagination.pageSize
  }, {
    ...constants.queryOptions.infinite,
    lookupKey: `dealflowEntitiesKanban_${options?.id}`,
    enabled: enabled && tableProvider.context?.data?.clientId >= 0,
    monitorCallback: handleMonitor
  });

  const setKanbanListEvent = useEffectEvent(tableProvider.setKanbanList);
  useLayoutEffect(() => {
    queryKeyRef.current = list.query.queryKey;
    setKanbanListEvent?.(options?.id, list);
  }, [setKanbanListEvent, options?.id, list]);

  return <React.Fragment>
    {children}
  </React.Fragment>
}

const DealflowEntitiesTableKanbanLoader = ({children, active}) => {
  const [initialised, setInitialised] = useState(false);

  const tableProvider = useTable();
  const client = useAuthClient();
  const groups = client?.dealflowGroups;

  const lists = useMemo(() => {
    if (groups) {
      return groups?.reduce((a, group) => {
        return a.concat(group.statuses
          .filter((status) => +status.statusId > 0)
          .map((status) => ({
            id: +status.statusId
          })));
      }, [])
    } else {
      return null;
    }
  }, [groups]);

  // load the kanban lists
  const listState = useEffectItem(tableProvider.listState);
  const setSearchEvent = useEffectEvent(tableProvider?.kanbanListState?.setSearch);
  const setFilterEvent = useEffectEvent(tableProvider?.kanbanListState?.setFilter);
  const setCustomEvent = useEffectEvent(tableProvider?.kanbanListState?.setCustom);
  useEffect(() => {
    setSearchEvent?.(listState.search);
    setFilterEvent?.(listState.filter);
    setCustomEvent?.(listState.custom);
    setInitialised(true);
  }, [listState.search, listState.filter, listState.custom, setSearchEvent, setFilterEvent, setCustomEvent]);

  return <React.Fragment>
    {lists?.map((list) => {
      return <DealflowEntitiesTableKanbanListLoader key={list.id}
                                                    options={list}
                                                    active={initialised && active}/>
    })}
    {children}
  </React.Fragment>
}

const DealflowEntitiesTableProvider = (props) => {
  const clientId = useAuthClientId();
  const teamId = useAuthTeamId();

  const view = useProviderView('dealflow.entity', props.skipView);

  const client = useClientGet({clientId}, {enabled: clientId >= 0});

  const columns = useDealflowEntityColumns(view);
  const panels = useDealflowEntityPanels(view);
  const filterGroups = useDealflowEntityFilterGroups(view);
  const graphs = useDealflowEntityGraphs(view);

  const isLoading = props?.isLoading || client.status?.isLoading;
  const isDefinitionsLoading = props?.isDefinitionsLoading;

  const columnDefinitions = useMemo(() => {
    return columns?.length > 0 ? columns : null;
  }, [columns]);

  const filterGroupDefinitions = useMemo(() => {
    return filterGroups?.length > 0 ? filterGroups : null;
  }, [filterGroups]);

  const panelDefinitions = useMemo(() => {
    return panels?.length > 0 ? panels : null;
  }, [panels]);

  const graphDefinitions = useMemo(() => {
    return graphs?.length > 0 ? graphs : null;
  }, [graphs]);

  const options = useMemo(() => ({
    listState: {
      initial: {
        pageSize: 25
      },
      options: {
        type: constants.appState.type.local,
        name: `dealflowEntities_${teamId}`,
        searchParams: true
      }
    },
    testListState: {
      initial: {
        active: false,
        pageSize: 0
      },
      options: {
        name: `dealflowEntities_${teamId}_test`
      }
    },
    kanbanListState: {
      initial: {
        pageSize: 15
      },
      options: {
        name: `dealflowEntities_${teamId}_kanban`
      }
    },
    listSelection: {
      options: {
        max: constants.selection.max.dealflow
      }
    },
    columnState: {
      initial: {},
      options: {
        name: `dealflowEntities_${teamId}`,
        type: constants.appState.type.local
      }
    },
    kanbanState: {
      initial: {},
      options: {
        name: `dealflowEntities_${teamId}`,
        type: constants.appState.type.local
      }
    },
    graphState: {
      initial: {},
      options: {
        name: `dealflowEntities_${teamId}`,
        type: constants.appState.type.local
      }
    },
    columnDefinitions,
    panelDefinitions,
    filterGroupDefinitions,
    graphDefinitions
  }), [columnDefinitions, panelDefinitions, graphDefinitions, filterGroupDefinitions, teamId]);

  const fieldData = useMemo(() => {
    return {};
  }, []);

  const updaters = useMemo(() => {
    return {};
  }, []);

  const defaults = useMemo(() => {
    return utils.mergeObjects({
      list: {
        filter: [{id: 'status', value: '-0'}],
        sort: [{id: 'dealflow_added', desc: true}]
      }
    }, props.defaults);
  }, [props.defaults]);

  return <EntitiesTableProvider {...props}
                                view={view}
                                options={options}
                                fieldData={fieldData}
                                context={client}
                                updaters={updaters}
                                defaults={defaults}
                                LoaderComponent={DealflowEntitiesTableLoader}
                                TestLoaderComponent={DealflowEntitiesTableTestLoader}
                                KanbanLoaderComponent={DealflowEntitiesTableKanbanLoader}
                                isDefinitionsLoading={isDefinitionsLoading}
                                isLoading={isLoading}>
    {props.children}
  </EntitiesTableProvider>
};

DealflowEntitiesTableProvider.propTypes = {
}

export default DealflowEntitiesTableProvider;
