import React, {useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useListState} from 'helpers/hooks/utils';
import StyledEntitiesMatchWizardContent
  from 'components/organisms/WizardContent/EntitiesMatchWizardContent/EntitiesMatchWizardContent.styles';
import Box from 'components/atoms/Layout/Box/Box';
import {H6} from 'components/atoms/Text/Typography/Typography';
import Table from 'components/organisms/Tables/Table/Table';
import constants from 'helpers/constants';
import FieldTableCellEdit from 'components/organisms/TableCellEdits/FieldTableCellEdit/FieldTableCellEdit';
import TextTableCell from 'components/molecules/TableCells/TextTableCell/TextTableCell';
import Edit from '@mui/icons-material/Edit';
import utils from 'helpers/utils';
import {useSourceProcess} from 'services/source/source.hooks';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Search from '@mui/icons-material/Search';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import Clear from '@mui/icons-material/Clear';

const EntitiesMatchWizardContent = React.forwardRef((props, ref) => {
  const {
    wizard,
    step,
    onComplete,
    onError,
    onDirty,
    ...innerProps
  } = useComponentProps(props, 'EntitiesMatchWizardContent');

  const innerRef = useRef(null);

  const data = wizard.data?.[wizard.dataKey];
  const fileId = data.fileId;
  const config = data.params.config;
  const fields = data.fields;
  const replacement = data.replacement;
  const collectionId = data.params.collectionId;

  const snackbar = useSnackbar();
  const processFileSource = useSourceProcess();
  const setDataEvent = useEffectEvent(wizard.setData);

  const options = useMemo(() => ({
    listState: {
      options: {
        name: `entitiesMatch`,
      }
    }
  }), []);

  const listState = useListState(options.listState?.initial, options.listState?.options);

  const searchFieldRef = useRef(null);
  const setSearchEvent = useEffectEvent(listState.setSearch);
  const searchFields = useMemo(() => ([{
    name: 'search',
    label: 'Search',
    inlineLabel: 'search',
    placeholder: 'Search for a column name',
    type: constants.formFieldTypes.text,
    validation: constants.formFieldValidationTypes.text,
    initial: listState.search,
    debounce: constants.debounce.search,
    FormFieldProps: {
      ref: searchFieldRef,
      hiddenLabel: true,
      size: 'smallest',
      radius: 'round'
    },
    prefix: <Icon icon={Search} />,
    postfix: listState.search?.length > 0 ? <ActionIconButton action={{
      icon: <Icon icon={Clear}/>,
      onClick: (e) => {
        setSearchEvent?.('');
        searchFieldRef.current?.focus();
        e.preventDefault();
      }
    }} /> : null
  }]), [listState.search, setSearchEvent]);

  const onCompleteEvent = useEffectEvent(onComplete);
  const onErrorEvent = useEffectEvent(onError);
  const onDirtyEvent = useEffectEvent(onDirty);
  const matchColumnsWizardContent = useMemo(() => ({
    refs: {
      ref: innerRef,
    },
    submit: () => {
      const ignoreField = {label: '[ignore]', field: '_', name: '_'};
      const mapping = config.map((c) => ({
        ...c, field: fields.find((f) => f.name === c.field?.name && f.value !== '_') ?? ignoreField
      }));

      processFileSource.mutation.mutateAsync({
        fileId,
        collectionId,
        mapping: utils.underscoreEx(mapping, true),
        replacement: replacement
          .map((r) => {
            const corrected = r.correctedValue === '' ? null : (r.correctedValue ?? r.value);
            return {...r, correctedValue: corrected};
          })
      }).then((res) => {
        const errors = res.response?.data?.data?.errors ?? [];

        setDataEvent?.((current) => {
          return utils.updater({
            ...current,
            [wizard.dataKey]: {
              ...current[wizard.dataKey],
              errors,
              replacement: replacement?.length > 0 ? replacement : errors,
              example: res.response?.data?.data?.example,
              total: res.response?.data?.data?.total,

              params: {
                ...current[wizard.dataKey].params,
                config: mapping,
                file: res.response?.data?.data?.fileId
              }
            }
          })(current);
        });

        setTimeout(() => {
          onCompleteEvent?.(Boolean(step?.resetNextSteps));
        }, constants.debounce.minimal);
      }).catch((error) => {
        if (error?.response?.status < constants.http.status.serverError) {
          onErrorEvent?.('Matching failed.');
          snackbar.show('Matching failed, the company name is required', null, {color: 'error', autoHideDuration: constants.delay.error});
        } else {
          onErrorEvent?.('Matching failed.');
          snackbar.show('Matching progress failed', null, {color: 'error', autoHideDuration: constants.delay.error});
        }
      });
    }
  }), [
    fileId, fields, replacement, config, snackbar, collectionId, wizard.dataKey, step?.resetNextSteps,
    processFileSource.mutation, onCompleteEvent, setDataEvent, onErrorEvent
  ]);

  useImperativeHandle(ref, () => matchColumnsWizardContent);

  const columnsMemo = useMemo(() => {
    const columns = [];

    columns.push({
      id: 'value',
      header: 'Column in file',
      enableEditing: false,
      Cell: ({cell}) => {
        const column = cell.row.original.value;
        return <TextTableCell title={column} />
      }
    });

    columns.push({
      id: 'field',
      header: 'Column in database',
      enableEditing: true,
      Edit: ({column, table, cell}) => {
        const innerRef = useRef(null);
        const columnField = cell.column.columnDef.optimistic.get(cell, cell.row.original?.field);

        const fieldsMemo = useMemo(() => {
          const ignoreOption = {label: 'Ignore field', value: '_'};
          const fieldDef = constants.upload.fieldDefinition.lookup(utils.camelcase(columnField?.name));
          const ignore = !utils.isDefined(columnField?.label ?? columnField?.name ?? columnField?.value) ||
            (columnField?.name ?? columnField?.value) === ignoreOption.value;

          const initial = (columnField && !ignore) ? {
            label: fieldDef?.label ?? columnField.label,
            value: columnField.name ?? columnField.value,
          } : ignoreOption;

          const options = fields.map((f) => {
            const fieldDef = constants.upload.fieldDefinition.lookup(utils.camelcase(f.name));
            return {
              label: fieldDef?.label ?? f.label,
              value: f.name
            };
          });

          return [{
            name: column.id,
            label: column.columnDef.header,
            placeholder: 'Choose column',
            type: constants.formFieldTypes.autocomplete,
            initial,
            options,
            FormFieldProps: {
              minWidth: 280
            }
          }]
        }, [column.id, column.columnDef.header, columnField]);

        const handleChange = (field, value, onSuccess) => {
          const ignoreOption = {label: 'Ignore field', value: '_'};
          const option = fields.find((f) => f.name === value.value) ?? ignoreOption;
          cell.column.columnDef.optimistic.set(cell, option);

          const row = cell.row.original;
          const rowIndex = config.findIndex((r) => r.cell === row.cell);
          const newConfig = [...config.slice(0, rowIndex), {
            ...row,
            field: option
          }, ...config.slice(rowIndex + 1)];

          setDataEvent?.((current) => {
            return utils.updater({
              ...current,
              [wizard.dataKey]: {
                ...current[wizard.dataKey],
                params: {
                  ...current[wizard.dataKey].params,
                  config: newConfig
                },
              }
            })(current);
          });

          onSuccess?.();
          onDirtyEvent?.(true);
        }

        return <FieldTableCellEdit ref={innerRef}
                                   fields={fieldsMemo}
                                   table={table}
                                   cell={cell}
                                   onChange={handleChange}
                                   Anchor={<TextTableCell title={fieldsMemo[0].initial.label} />}/>
      },
      Cell: ({table, cell}) => {
        const columnField = cell.column.columnDef.optimistic.get(cell, cell.row.original?.field);
        const fieldDef = constants.upload.fieldDefinition.lookup(utils.camelcase(columnField?.name));
        const ignoreOption = {label: 'Ignore field', value: '_'};
        const ignore = !utils.isDefined(columnField?.label ?? columnField?.name ?? columnField?.value) ||
          (columnField?.name ?? columnField?.value) === ignoreOption.value;

        const action = wizard.state.submitting ? null : {
          label: 'Edit',
          tooltip: 'Edit',
          icon: Edit,
          onClick: (e) => {
            table.setEditingCell(cell);
            e.preventDefault();
          }
        };
        return <TextTableCell title={fieldDef?.label ?? (ignore ? ignoreOption.label : columnField?.label)}
                              action={action} />
      }
    });

    columns.push({
      id: 'example',
      header: 'Example',
      enableEditing: false,
      Cell: ({cell}) => {
        const example = cell.row.original.preview?.[0] ?? '';
        return <TextTableCell title={example} />
      }
    });

    return columns;
  }, [config, fields, wizard.dataKey, wizard.state.submitting, onDirtyEvent, setDataEvent]);

  const rows = useMemo(() => {
    return (config ?? []).filter((c) => {
      const columnField = c.field;
      const fieldDef = constants.upload.fieldDefinition.lookup(utils.camelcase(columnField?.name));

      return utils.isEmpty(listState.search) ||
        c.value?.toLowerCase()?.includes(listState.search.toLowerCase()) ||
        (fieldDef?.label ?? columnField?.label)?.toLowerCase()?.includes(listState.search.toLowerCase());
    });
  }, [config, listState.search]);

  const handleSearch = (field, value) => {
    setSearchEvent?.(value);
  }

  const renderSubtitle = () => {
    const step = wizard.steps[wizard.state.stepIndex];
    const subtitle = step.subSteps?.[wizard.state.subStepIndex || 0].subtitle ?? step.subtitle;

    if (subtitle) {
      return <Box className="EntitiesMatchWizardContent-subtitle">
        <H6>{subtitle}</H6>
        <InlineForm className="EntitiesValidateWizardContent-search"
                    onChange={handleSearch}
                    fields={searchFields} />
      </Box>
    }
  };

  return <StyledEntitiesMatchWizardContent ref={ref} {...innerProps}>
    {renderSubtitle()}
    <Table className="EntitiesMatchWizardContent-table"
           dataKey="cell"
           enableParentScroll={false}
           enableBottomToolbar={false}
           enableStickyHeader={true}
           enableSorting={false}
           enableEditing={!wizard.state.submitting}
           enableColumnDragging={false}
           enableColumnActions={false}
           enableColumnOrdering={false}
           enableColumnResizing={false}
           enablePinning={false}
           listState={listState}
           columns={columnsMemo}
           data={rows}
           rowCount={rows?.length}
           debounce={false}
           state={{
             isLoading: false,
             showProgressBars: false
           }} />
  </StyledEntitiesMatchWizardContent>
});

EntitiesMatchWizardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  wizard: PropTypes.object,
  step: PropTypes.object,
  onComplete: PropTypes.func,
  onDirty: PropTypes.func,
  onError: PropTypes.func,
};

EntitiesMatchWizardContent.defaultProps = {
};

export default EntitiesMatchWizardContent;
