import React, {useCallback, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useEffectItem, useFirstEffect} from 'helpers/hooks/utils';
import constants from 'helpers/constants';
import TextField from 'components/molecules/Fields/TextField/TextField';
import FileField from 'components/molecules/Fields/FileField/FileField';
import logger from 'helpers/logger';
import Markdown from 'components/atoms/Formatters/Markdown/Markdown';
import CheckboxField from 'components/molecules/Fields/CheckboxField/CheckboxField';
import AutocompleteField from 'components/molecules/Fields/AutocompleteField/AutocompleteField';
import utils from 'helpers/utils';
import {Span} from 'components/atoms/Text/Typography/Typography';
import InputAdornment from 'components/atoms/Inputs/InputAdornment/InputAdornment';
import {useForm} from 'components/organisms/Forms/Form/Form';
import * as icons from 'assets/icons';
import Icon from 'components/atoms/Icons/Icon/Icon';
import DateField from 'components/molecules/Fields/DateField/DateField';
import ListField from 'components/molecules/Fields/ListField/ListField';
import SliderField from 'components/molecules/Fields/SliderField/SliderField';
import CategoryField from 'components/molecules/Fields/CategoryField/CategoryField';
import SwitchField from 'components/molecules/Fields/SwitchField/SwitchField';
import TagsField from 'components/molecules/Fields/TagsField/TagsField';
import SuggestionField from 'components/molecules/Fields/SuggestionField/SuggestionField';
import ColorField from 'components/molecules/Fields/ColorField/ColorField';
import PopperField from 'components/molecules/Fields/PopperField/PopperField';
import CloudField from 'components/molecules/Fields/CloudField/CloudField';
import StyledFormField from 'components/organisms/Fields/FormField/FormField.styles';
import dom from 'helpers/dom';
import {withMemo} from 'helpers/wrapper';
import ComponentField from 'components/molecules/Fields/ComponentField/ComponentField';
import IconField from 'components/molecules/Fields/IconField/IconField';
import utilsBasic from 'helpers/utils.basic';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import Link from 'components/atoms/Links/Link/Link';
import UserField from 'components/molecules/Fields/UserField/UserField';
import PhoneField from 'components/molecules/Fields/PhoneField/PhoneField';
import EmailField from 'components/molecules/Fields/EmailField/EmailField';
import LinkField from 'components/molecules/Fields/LinkField/LinkField';

const FormField = withMemo(React.forwardRef((props, ref) => {
  const {
    index,
    field,
    openDirect,
    onChange,
    onChangeDirect,
    onBlur,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'FormField', {
    styled: ['minWidth', 'maxWidth'],
    static: ['readOnly', 'fullWidth', 'hidden'],
    variable: ['name']
  });

  const innerRef = useRef(null);
  const idRef = useRef(null);
  const pendingRef = useRef(false);
  const firstEffect = useFirstEffect();

  const form = useForm();
  const formik = form?.formik;

  const value = innerProps.value ?? '';
  const [internalValue, setInternalValue] = useState(value);

  const fieldMemo = useEffectItem(field);
  const contextMemo = useEffectItem(field?.context);
  const onBlurEvent = useEffectEvent(onBlur);
  const onFieldBlurEvent = useEffectEvent(field.onBlur);
  const onChangeEvent = useEffectEvent(onChange);
  const onChangeDirectEvent = useEffectEvent(onChangeDirect);

  const setValue = useCallback((value) => {
    const e = {
      target: {
        name: field.name,
        value: value
      }
    };
    setInternalValue(value);
    onChangeDirectEvent?.(e);
    onChangeEvent?.(e);
  }, [field?.name, onChangeDirectEvent, onChangeEvent]);

  const formField = useMemo(() => {
    return {
      ref: innerRef,
      clear: (value) => {
        setValue(value ?? '');
      },
      setValue: setValue,
      focus: () => {
        const ref = innerRef.current?.refs?.ref ?? innerRef.current?.ref ?? innerRef;
        return dom.focusElement(ref.current);
      },
      submit: (touch = true) => {
        return innerRef.current?.submit?.(touch);
      },
      validate: (touch = true) => {
        return innerRef.current?.validate?.(touch);
      },
      reset: (state) => {
        return innerRef.current?.reset?.(state);
      }
    }
  }, [setValue]);

  useImperativeHandle(ref, () => formField);

  const handleChange = useCallback((e) => {
    if (e?.target) {
      const canCompare = utils.comparable(e.target.value, true);
      if ((!canCompare && (e.target.value !== internalValue)) || (canCompare && !utils.compare(e.target.value, internalValue))) {
        const newValue = e.target.value;

        setInternalValue(newValue);

        // convert back
        e = {
          target: {
            name: field.name,
            value: newValue
          }
        };

        onChangeDirectEvent?.(e);
        onChangeEvent?.(e);
      }
    }
  }, [onChangeEvent, onChangeDirectEvent, field.name, internalValue]);

  const handleBlur = useCallback((e) => {
    const target = e?.target?.name ? e?.target : e?.relatedTarget;
    if (target) {
      e = {
        target: {
          name: field.name,
          value: internalValue
        }
      };

      if (pendingRef.current) {
        onChangeEvent?.(e);
      }
      onBlurEvent?.(e);
      onFieldBlurEvent?.(e);
    }
  }, [field.name, internalValue, onBlurEvent, onFieldBlurEvent, onChangeEvent]);

  useLayoutEffect(() => {
    setInternalValue(value);
  }, [value]);

  useLayoutEffect(() => {
    if (!firstEffect.current && contextMemo) {
      formField.ref.current?.reset?.();
    }
  }, [contextMemo, firstEffect, formField.ref]);

  if (!idRef.current) {
    idRef.current = `${field.name}_${Math.ceil(Math.random() * constants.numbers.randomInt)}`
  }

  innerProps.id = innerProps.id ?? idRef.current;
  innerProps.name = innerProps.name ?? field.name;
  innerProps.label = innerProps.label ?? field.label ?? '';
  innerProps.placeholder = innerProps.placeholder ?? field.placeholder ?? field.label ?? '';

  let description = field.description;
  if (field.description && !utilsBasic.isFunction(field.description) && !utilsBasic.isFunction(field.label) &&
      field.description.toLowerCase() === field.label?.toLowerCase()) {
    description = null;
  }

  innerProps.readOnly = innerProps.readOnly ?? field.readOnly;
  innerProps.disabled = innerProps.disabled ?? field.disabled;

  const errorText = innerProps.error ?? (formik?.touched?.[field.name] && formik?.errors?.[field.name]);
  const successText = innerProps.success;

  innerProps.error = !innerProps.disabled && Boolean(innerProps.error ?? (formik?.touched?.[field.name] && Boolean(formik?.errors?.[field.name])));
  innerProps.success = !innerProps.disabled && Boolean(innerProps.success);

  innerProps.helperText = (innerProps.error ? errorText : null) ??
    (innerProps.success ? successText : null) ?? innerProps.helperText ?? description ?? '';

  innerProps.helperText = useMemo(() => {
    let helperText = innerProps.helperText;
    if (!utils.isReactElement(helperText) && !utils.isFunction(helperText)) {
      if (utils.isArray(helperText)) {
        helperText = helperText.join('\n');
      } else if (utils.isObject(helperText)) {
        helperText = Object.values(helperText)
          .reduce((a, v) => {
            return a.concat(utils.toArray(v));
          }, []).join('\n');
      }
      helperText = helperText ?
        <Markdown>{helperText}</Markdown> : helperText;
    } else if (utils.isFunction(helperText)) {
      helperText = helperText(fieldMemo, internalValue, innerProps.disabled);
    }

    return helperText;
  }, [innerProps.helperText, innerProps.disabled, fieldMemo, internalValue]);

  innerProps.label = useMemo(() => {
    let label = innerProps.label;
    if (!utils.isReactElement(label) && !utils.isFunction(label)) {
      if (!innerProps.readOnly && (innerProps.required ?? fieldMemo.required)) {
        label = label + ' *';
      }

      label = label ? <Markdown>{label}</Markdown> : label;
    } else if (utils.isFunction(innerProps.label)) {
      label = label(fieldMemo, internalValue);
    }

    if (innerProps.labelPrefix ?? fieldMemo.labelPrefix) {
      if ((innerProps.labelPrefix ?? fieldMemo.labelPrefix) === 'index') {
        label = <React.Fragment>
          <Span className="FormField-labelPrefix">{index}.&nbsp;</Span>
          {label}
        </React.Fragment>
      } else {
        label = <React.Fragment>
          <Span className="FormField-labelPrefix">{innerProps.labelPrefix ?? fieldMemo.labelPrefix}</Span>
          {label}
        </React.Fragment>
      }
    }

    if (innerProps.labelPostfix ?? fieldMemo.labelPostfix) {
      if ((innerProps.labelPostfix ?? fieldMemo.labelPostfix) === 'index') {
        label = <React.Fragment>
          {label}
          <Span className="FormField-labelPostfix">&nbsp;({index})</Span>
        </React.Fragment>
      } else {
        label = <React.Fragment>
          {label}
          <Span className="FormField-labelPostfix">{innerProps.labelPostfix ?? fieldMemo.labelPostfix}</Span>
        </React.Fragment>
      }
    }

    return label;
  }, [innerProps.label, fieldMemo, internalValue, index,
    innerProps.readOnly, innerProps.required, innerProps.labelPrefix, innerProps.labelPostfix]);

  innerProps.InputProps = useMemo(() => {
    let InputProps = innerProps.InputProps;
    if (fieldMemo.prefix || fieldMemo.postfix) {
      const renderFix = (fix, props) => {
        return utils.isString(fix) ? (icons[fix] ? <Icon icon={fix} size="small" {...props}/> : fix) : (
          utils.isIconComponent(fix) ? <Icon icon={fix} size="small" {...props}/> : fix
        );
      }

      const renderFixAction = (fix) => {
        if (utils.isObject(fix) && !utils.isIconComponent(fix) && !utils.isReactElement(fix)) {
          if (fix.action) {
            return <ActionIconButton action={utils.isFunction(fix.action) ? fix.action(internalValue) : fix.action}
                                     {...fix.ActionIconButtonProps}/>
          } else if (fix.link) {
            let link = utils.isFunction(fix.link) ? fix.link(internalValue) : fix.link(internalValue);
            if (!utils.isEmpty(link)) {
              return <Link href={utils.isFunction(fix.link) ? fix.link(internalValue) : fix.link(internalValue)}
                           tabIndex={-1}
                           onClick={(e) => e.stopPropagation()}
                           target={fix.target ?? '_blank'}
                           {...fix.LinkProps}>
                {renderFix(fix.icon, fix.IconProps)}
              </Link>
            } else {
              return renderFix(fix.icon, fix.IconProps);
            }
          } else {
            return renderFix(fix.icon, fix.IconProps);
          }
        } else {
          return renderFix(fix, null);
        }
      }

      InputProps = {
        startAdornment: (fieldMemo.prefix ? <InputAdornment size={fieldMemo.FormFieldProps?.size ?? 'small'}
                                                            position="start">{renderFixAction(fieldMemo.prefix)}</InputAdornment> : null),
        endAdornment: (fieldMemo.postfix ? <InputAdornment size={fieldMemo.FormFieldProps?.size ?? 'small'}
                                                           position="end">{renderFixAction(fieldMemo.postfix)}</InputAdornment> : null),
        ...InputProps
      };
    }

    return InputProps;
  }, [fieldMemo, innerProps.InputProps, internalValue]);

  innerProps.inputProps = useMemo(() => {
    let inputProps = innerProps.inputProps;

    if ([constants.formFieldTypes.text, constants.formFieldTypes.textarea,
      constants.formFieldTypes.markdown].includes(fieldMemo.type)) {
      const min = utils.field2FieldMin(fieldMemo);
      const max = utils.field2FieldMax(fieldMemo);
      if (utilsBasic.isDefined(min) || utilsBasic.isDefined(max)) {
        inputProps = utilsBasic.mergeObjects({
          minLength: min,
          maxLength: max
        }, inputProps);
      }
    }

    return inputProps;
  }, [fieldMemo, innerProps.inputProps]);

  innerProps.disableFuture = useMemo(() => {
    if ([constants.formFieldTypes.date].includes(fieldMemo.type)) {
      return innerProps.disableFuture ?? utils.field2NotInTheFuture(fieldMemo);
    } else {
      return innerProps.disableFuture;
    }
  }, [fieldMemo, innerProps.disableFuture]);

  innerProps.disablePast = useMemo(() => {
    if ([constants.formFieldTypes.date].includes(fieldMemo.type)) {
      return innerProps.disablePast ?? utils.field2NotInThePast(fieldMemo);
    } else {
      return innerProps.disablePast;
    }
  }, [fieldMemo, innerProps.disablePast]);

  innerProps.className = utils.flattenClassName(innerProps.className, {
    readOnly: innerProps.readOnly,
    name: field.name
  });

  switch (field.type) {
    case constants.formFieldTypes.text:
    case constants.formFieldTypes.password:
      return <StyledFormField as={TextField}
                              ref={formField.ref}
                              {...innerProps}
                              value={internalValue}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              format={field.format}
                              type={field.type}/>
    case constants.formFieldTypes.textarea:
    case constants.formFieldTypes.markdown:
      return <StyledFormField as={TextField}
                              ref={formField.ref}
                              {...innerProps}
                              value={internalValue}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              multiline={true}
                              markdown={field.type === constants.formFieldTypes.markdown}
                              type="textarea"/>
    case constants.formFieldTypes.number:
      return <StyledFormField as={TextField}
                              ref={formField.ref}
                              {...innerProps}
                              value={internalValue}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              format={field.format}
                              type="text"/>
    case constants.formFieldTypes.phone:
      return <StyledFormField as={PhoneField}
                              ref={formField.ref}
                              {...innerProps}
                              value={internalValue}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              format={field.format}
                              type="text"/>
    case constants.formFieldTypes.email:
      return <StyledFormField as={EmailField}
                              ref={formField.ref}
                              {...innerProps}
                              value={internalValue}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              format={field.format}
                              type="text"/>
    case constants.formFieldTypes.link:
      return <StyledFormField as={LinkField}
                              ref={formField.ref}
                              {...innerProps}
                              value={internalValue}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              format={field.format}
                              type="text"/>
    case constants.formFieldTypes.date:
      return <StyledFormField as={DateField}
                              ref={formField.ref}
                              {...innerProps}
                              value={internalValue}
                              onChange={handleChange}
                              onBlur={handleBlur}/>
    case constants.formFieldTypes.autocomplete:
    case constants.formFieldTypes.monetary:
      return <StyledFormField as={AutocompleteField}
                              ref={formField.ref}
                              {...innerProps}
                              format={field.format}
                              value={internalValue}
                              openDirect={openDirect}
                              isLoading={isLoading}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              options={field.options}/>
    case constants.formFieldTypes.user:
      return <StyledFormField as={UserField}
                              ref={formField.ref}
                              {...innerProps}
                              value={internalValue}
                              isLoading={isLoading}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              format={field.format}
                              options={field.options}/>
    case constants.formFieldTypes.suggestion:
      return <StyledFormField as={SuggestionField}
                              ref={formField.ref}
                              {...innerProps}
                              format={field.format}
                              value={internalValue}
                              openDirect={openDirect}
                              isLoading={isLoading}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              options={field.options}/>
    case constants.formFieldTypes.cloud:
      return <StyledFormField as={CloudField}
                              ref={formField.ref}
                              {...innerProps}
                              format={field.format}
                              value={internalValue}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              options={field.options}/>
    case constants.formFieldTypes.list:
      return <StyledFormField as={ListField}
                              ref={formField.ref}
                              {...innerProps}
                              format={field.format}
                              value={internalValue}
                              isLoading={isLoading}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              options={field.options}/>
    case constants.formFieldTypes.category:
      return <StyledFormField as={CategoryField}
                              ref={formField.ref}
                              {...innerProps}
                              format={field.format}
                              value={internalValue}
                              isLoading={isLoading}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              options={field.options}/>
    case constants.formFieldTypes.slider:
      return <StyledFormField as={SliderField}
                              ref={formField.ref}
                              {...innerProps}
                              value={internalValue}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              options={field.options}/>
    case constants.formFieldTypes.checkbox:
      return <StyledFormField as={CheckboxField}
                              ref={formField.ref}
                              {...innerProps}
                              value={internalValue}
                              onChange={handleChange}
                              onBlur={handleBlur}/>
    case constants.formFieldTypes.switch:
      return <StyledFormField as={SwitchField}
                              ref={formField.ref}
                              {...innerProps}
                              value={internalValue}
                              onChange={handleChange}
                              onBlur={handleBlur}/>
    case constants.formFieldTypes.file:
      return <StyledFormField as={FileField}
                              ref={formField.ref}
                              {...innerProps}
                              value={internalValue}
                              onChange={handleChange}
                              onBlur={handleBlur}/>
    case constants.formFieldTypes.tags:
      return <StyledFormField as={TagsField}
                              ref={formField.ref}
                              {...innerProps}
                              format={field.format}
                              value={internalValue}
                              onChange={handleChange}
                              onBlur={handleBlur}/>
    case constants.formFieldTypes.color:
      return <StyledFormField as={ColorField}
                              ref={formField.ref}
                              {...innerProps}
                              value={internalValue}
                              onChange={handleChange}
                              onBlur={handleBlur}/>
    case constants.formFieldTypes.icon:
      return <StyledFormField as={IconField}
                              ref={formField.ref}
                              {...innerProps}
                              value={internalValue}
                              onChange={handleChange}
                              onBlur={handleBlur}/>
    case constants.formFieldTypes.popper:
      return <StyledFormField as={PopperField}
                              ref={formField.ref}
                              {...innerProps}
                              value={internalValue}
                              onChange={handleChange}
                              onBlur={handleBlur}/>
    case constants.formFieldTypes.component:
      return <StyledFormField as={ComponentField}
                              ref={formField.ref}
                              valueProp={field.valueProp}
                              Component={field.Component}
                              required={field.required}
                              {...innerProps}
                              value={internalValue}
                              onChange={handleChange}
                              onBlur={handleBlur}/>
    default:
      logger.warn('Field type not found for:', field.type, field);
  }
}));

FormField.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  index: PropTypes.number,
  field: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onChangeDirect: PropTypes.func,
  onBlur: PropTypes.func,
  isLoading: PropTypes.bool
};

FormField.defaultProps = {
};

export default FormField;
