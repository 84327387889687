import utils from 'helpers/utils';
import logger from 'helpers/logger';
import ServiceStore from 'stores/service.store';
import constants from 'helpers/constants';
import {processUser} from 'services/client/user/user.utils';

const path = utils.servicePath(import.meta.url);

export class PortalUserService extends ServiceStore {
  constructor (app, callbacks) {
    logger.trace(`Starting module at: services/${path}`);

    super(path, app, {
      refreshEnabled: false,
      process: (data, meta) => {
        // process
        if (meta?.context?.$store?.dataType !== constants.dataTypes.other) {
          if (utils.isArray(data)) {
            return data.map(processUser);
          } else {
            return processUser(data);
          }
        } else {
          return data;
        }
      }
    }, callbacks);
  }
}
