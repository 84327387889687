import React, {useCallback, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useBbox, useComponentProps} from 'helpers/hooks/utils';
import StyledContextDrawer from 'components/organisms/Drawers/ContextDrawer/ContextDrawer.styles';
import utils from 'helpers/utils';
import Wrapper, {useWrapper} from 'components/templates/Wrappers/Basic/Wrapper/Wrapper';
import {useDialog} from 'components/organisms/Dialogs/Dialog/Dialog';
import dom from 'helpers/dom';
import {useStyles} from 'components/organisms/Providers/ThemeProvider/ThemeProvider';

const ContextDrawer = React.forwardRef((props, ref) => {
  const {
    open,
    width,
    toggleWithMouse,
    onToggle,
    ...innerProps
  } = useComponentProps(props, 'ContextDrawer', {
    static: ['open', 'showing'], // modifiers that be filled by theme styles, see classnames
    variable: ['open', 'anchor'], // classname and style only filled when variant is given
    children: ['content'],
  });

  const dialog = useDialog();
  const isDialog = Boolean(dialog);
  const wrapper = useWrapper();

  const innerRef = useRef(null);

  const [internalState, setInternalState] = useState({open: true, showing: false});

  const toggleDrawer = (open) => {
    return function (e, reason) {
      const canToggle = innerProps.variant === 'temporary';

      if (canToggle) {
        onToggle?.(e, open, reason ?? (open ? 'mouseEnter' : 'mouseLeave'));
        setInternalState(utils.updater({open}, true));
      }
    }
  };

  const handleSlideEnd = () => {
    if (innerProps.variant === 'temporary') {
      setInternalState((current) => ({
        ...current,
        showing: !current.showing
      }));
    }
  }

  const contextDrawer = useMemo(() => ({
    refs: {
      ref: innerRef
    },
    state: {
      ...internalState,
      ...utils.cleanObject({open})
    }
  }), [internalState, open]);

  useImperativeHandle(ref, () => contextDrawer);

  const styles = useStyles();
  const bBox = useBbox(() => innerRef.current);
  const recalcHeight = useCallback(() => {
    const el = dom.getScrollElement(innerRef.current, false, true);
    const paper = innerRef.current?.querySelector?.('.MuiPaper-root');
    if (el && paper) {
      const top = paper.getBoundingClientRect().y;
      const bounds = utils.rem2Pixel(wrapper?.boundsCum?.top ?? '0rem', styles?.fontBase) +
        utils.rem2Pixel(wrapper?.boundsCum?.bottom ?? '0rem', styles?.fontBase);
      const max = bounds + Math.max(0, top - bounds);
      paper.style.height = `calc(100vh - ${max}px)`;
    }
  }, [wrapper?.boundsCum?.top, wrapper?.boundsCum?.bottom, styles?.fontBase]);

  useLayoutEffect(() => {
    if (innerProps.variant === 'persistent' && !isDialog) {
      recalcHeight();
    }
  }, [bBox, innerProps.variant, isDialog, recalcHeight]);

  innerProps.anchor = innerProps.anchor ?? 'right';
  innerProps.className = utils.flattenClassName(innerProps.className, {
    ...innerProps,
    ...contextDrawer.state
  });

  return <StyledContextDrawer ref={innerRef} {...innerProps}
                              open={contextDrawer.state.open}
                              onClose={toggleDrawer(false)}
                              onOpen={toggleDrawer(true)}
                              SlideProps={{
                                addEndListener: handleSlideEnd
                              }}
                              $width={width}
                              $dialog={Boolean(dialog)}
                              $wrapper={innerProps.variant === 'temporary' ? null : wrapper}>
    <Wrapper reset
             className="ContextDrawer-content"
             onMouseEnter={toggleWithMouse ? toggleDrawer(true) : null}
             onMouseLeave={toggleWithMouse ? toggleDrawer(false) : null}>
      {innerProps.children}
    </Wrapper>
  </StyledContextDrawer>
});

ContextDrawer.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  open: PropTypes.bool,
  width: PropTypes.string,
  onToggle: PropTypes.func,
  toggleWithMouse: PropTypes.bool
};

ContextDrawer.defaultProps = {
  toggleWithMouse: false,
  variant: 'permanent'
};

export default ContextDrawer;
