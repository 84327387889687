import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import BaseTableCell from 'components/molecules/TableCells/BaseTableCell/BaseTableCell';

const StyledTextTableCell = styled(BaseTableCell)`
  .TextTableCell {
    &-edit {
      display: none;
      margin-left: ${props => props.theme.layout('0.5sp')};
      color: ${props => props.theme.property('palette.text.disabled')};
    }
    
    &-icon {
      &-wrap {
        height: 100%;
        display: flex;
        align-items: center;
        margin-right: ${props => props.theme.layout('1sp')};
      }
    }
    
    &-text {
      display: flex;
      width: 100%;
      overflow: hidden;
      flex-direction: column;
      justify-content: center;
      
      .Typography, .Link {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-title {
        display: flex;
        align-items: center;
        width: 100%;
        gap: ${props => props.theme.layout('0.5sp')};
        
        &-text {
          display: flex;
          flex-grow: 1;
          width: 1px;
          max-width: fit-content;
        }
      }
      
      &-subtitle {
        display: flex;
        
        &-wrap {
          width: 100%;
          max-width: fit-content;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  &.TextTableCell-density-dense {
    .TextTableCell-text {
      &-subtitle {
        margin-top: ${props => props.theme.layout(-2)};
      }
    }
  }

  &.TextTableCell-density-denser {
    .TextTableCell-text {
      &-subtitle {
        margin-top: ${props => props.theme.layout(-4)};
      }
    }
  }
  
  &.TextTableCell-canUpdate {
    .TextTableCell-text:hover {
      cursor: pointer;  
    }
    
    &:hover {
      &:not(:has(.TextTableCell-text-title-text)) {
        .TextTableCell {
          &-edit {
            display: block;
          }
          &-text {
            padding-right: ${props => props.theme.layout('1sp')};
          }
        }
      }
    }

    &:has(.TextTableCell-text:hover) {
      .TextTableCell {
        &-edit {
          display: block;
        }
        &-text {
          padding-right: ${props => props.theme.layout('1sp')};
        }
      }
    }

    .TextTableCell-edit:hover {
      display: block;
    }
    .TextTableCell-text:has(~ .TextTableCell-edit:hover) {
      padding-right: ${props => props.theme.layout('1sp')};
    }
  }
  
  ${ComponentStyles}
`;

StyledTextTableCell.propTypes = {
  theme: PropTypes.object
};

StyledTextTableCell.defaultProps = {
}

export default StyledTextTableCell;
