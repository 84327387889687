import React, {useEffect, useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import StyledEntityTasksContextCard
  from 'components/organisms/Cards/EntityTasksContextCard/EntityTasksContextCard.styles';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import Add from '@mui/icons-material/Add';
import {useEntityCommentToggleTaskDone} from 'services/entity/comment/comment.utils';
import {useEntityCommentList} from 'services/entity/comment/comment.hooks';
import constants from 'helpers/constants';
import utils from 'helpers/utils';
import EntitiesTaskAddDialog from 'components/organisms/Dialogs/EntitiesTaskAddDialog/EntitiesTaskAddDialog';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';

const EntityTasksContextCard = React.forwardRef((props, ref) => {
  const {
    entity,
    collection,
    canUpdate,
    switched,
    ...innerProps
  } = useComponentProps(props, 'EntityTasksContextCard');

  const profileProvider = useProfile();

  const innerRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  const dialogControl = useDialogControl();

  const entityCreateTaskEvent = useEffectEvent(profileProvider.updaters?.createTask);

  const tasks = useEntityCommentList({
    entityId: entity?.originalEntityId ?? 0,
    page: 0,
    pageSize: 10,
    filter: switched ? [{id: 'open', value: true}, {id: 'responsible', value: true}] : [{id: 'open', value: true}],
    sort: [{id: 'dueDate', desc: false}, {id: 'createdAt', desc: true}],
    task: true
  }, {
    ...constants.queryOptions.infinite
  });

  const actionButtons = useMemo(() => ([
    {
      auth: utils.createAuth({ attribute: 'entity.task.create' }),
      onClick: () => {
        const handleClose = () => {
          dialogControl.hide();
        };

        const handleSubmit = (task) => {
          return entityCreateTaskEvent?.(entity, task);
        }

        dialogControl.show(<EntitiesTaskAddDialog count={1} onSubmit={handleSubmit}/>, true, handleClose);
      },
      label: 'Add task',
      icon: Add,
      ActionButtonProps: {
        color: 'primary',
        variant: 'text'
      }
    }
  ]), [dialogControl, entity, entityCreateTaskEvent]);

  const authorize = useAuthorize();
  const toggleDone = useEntityCommentToggleTaskDone();
  const taskListProps = useMemo(() => ({
    TaskCardProps: {
      onToggle: (task, done) => {
        return toggleDone?.(entity, task, done);
      },
      onCanUpdate: (task, markDone = false) => {
        return canUpdate && authorize({
          attribute: markDone ? 'entity.task.markDone' : 'entity.task.update',
          meta: { entity, task }
        });
      },
      size: 'smaller',
      variant: 'compact'
    }
  }), [toggleDone, entity, authorize, canUpdate]);

  useEffect(() => {
    const scroll = () => {
      return innerRef.current?.querySelector('.DataList-list')?.scrollTo({top: 0, left: 0});
    }

    utils.retry(scroll, 3);
  }, [entity?.entityId]);

  return <StyledEntityTasksContextCard ref={innerRef} {...innerProps}
                                       switched={switched}
                                       actionButtons={actionButtons}
                                       tasks={tasks}
                                       TaskListProps={taskListProps} />
});

EntityTasksContextCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  entity: PropTypes.object,
  collection: PropTypes.object,
  switched: PropTypes.bool,
  canUpdate: PropTypes.bool
};

EntityTasksContextCard.defaultProps = {};

export default EntityTasksContextCard;
