import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledSwitchTeamPage from 'components/pages/Auth/SwitchTeamPage/SwitchTeamPage.styles';
import SwitchTeamForm from 'components/organisms/Forms/SwitchTeamForm/SwitchTeamForm';
import {H4, H6, P} from 'components/atoms/Text/Typography/Typography';
import utils from 'helpers/utils';
import {useLocation, useParams} from 'react-router-dom';
import Box from 'components/atoms/Layout/Box/Box';
import {useLinkNavigate} from 'helpers/hooks/links';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import CircularProgress from 'components/atoms/Progress/CircularProgress/CircularProgress';
import {useAuthUser} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import constants from 'helpers/constants';

const SwitchTeamPage = (props) => {
  const innerProps = useComponentProps(props, 'SwitchTeamPage', {
    children: ['header', 'subtitle', 'leave', 'switchingText', 'form', 'footer']
  });

  const snackbar = useSnackbar();

  const [switching, setSwitching] = useState(false);

  const { teamId } = useParams();

  const user = useAuthUser();
  const location = useLocation();
  const navigate = useLinkNavigate();

  const doRedirect = () => {
    navigate({
      to: utils.validRedirect(location.state?.from),
      replace: true
    });
  }

  const handleSuccess = () => {
    doRedirect();
  };

  const handleError = (error) => {
    snackbar.show(error, null,
      {color: 'error', autoHideDuration: constants.delay.warning});
    doRedirect();
  };

  return <StyledSwitchTeamPage {...innerProps} $switching={switching}>
    <Box className="SwitchTeamPage-loading">
      <CircularProgress/>
      <H6>Preparing team data</H6>
    </Box>
    <Box className="SwitchTeamPage-content">
      <Box className="SwitchTeamPage-header">
        <H4 className="SwitchTeamPage-title"> Welcome back, {utils.personName(user.firstName)}! </H4>

        <P className="SwitchTeamPage-subtitle">Select a team</P>
      </Box>

      <SwitchTeamForm className="SwitchTeamPage-form"
                      teamId={utils.isDefined(teamId) ? +teamId : null}
                      autoFocus={true}
                      onSwitching={setSwitching}
                      onSuccess={handleSuccess}
                      onError={handleError}/>
    </Box>
  </StyledSwitchTeamPage>
};

SwitchTeamPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

SwitchTeamPage.defaultProps = {
};

export default SwitchTeamPage;
