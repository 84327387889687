import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Card from 'components/atoms/Cards/Card/Card';

const StyledFileCard = styled(Card)`
  width: 100%;
  display: flex;
  align-items: center;
  padding: ${props => props.theme.spacing(1.5)};
  
  .FileCard {
    &-info {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 1px;
      gap: ${props => props.theme.layout('0.25sp')};
      
      > .Link {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: fit-content;
      }
      
      .Link {
        font-size: ${props => props.theme.fontSize(14)};
      }
    }
    
    &-description {
      color: ${props => props.theme.property('palette.text.secondary')};
    }
  }
  
  ${ComponentStyles}
`;

StyledFileCard.propTypes = {
  theme: PropTypes.object
};

StyledFileCard.defaultProps = {
}

export default StyledFileCard;
