import React, {useCallback, useEffect, useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import FormHelperText from 'components/atoms/Helpers/FormHelperText/FormHelperText';
import {P} from 'components/atoms/Text/Typography/Typography';
import Box from 'components/atoms/Layout/Box/Box';
import InputContainer from 'components/atoms/Layout/InputContainer/InputContainer';
import dom from 'helpers/dom';
import FormLabel from 'components/atoms/Labels/FormLabel/FormLabel';
import ChipList from 'components/atoms/Chips/ChipList/ChipList';
import Chip from 'components/atoms/Chips/Chip/Chip';
import Icon from 'components/atoms/Icons/Icon/Icon';
import AutocompleteField from 'components/molecules/Fields/AutocompleteField/AutocompleteField';
import StyledCloudField from 'components/molecules/Fields/CloudField/CloudField.styles';
import {withMemo} from 'helpers/wrapper';
import ActionChip from 'components/molecules/Chips/ActionChip/ActionChip';

const CloudField = withMemo(React.forwardRef((props, ref) => {
  const {
    id,
    name,
    label,
    value,
    size,
    icon,
    format,
    placeholder,
    showAutocomplete,
    hiddenPlaceholder,
    hiddenSelectionHelper,
    hiddenIcons,
    helperText,
    autoFocus,
    options,
    sorted,
    emptyText,
    createOption,
    openDirect,
    openOnFocus,
    readOnlyAction,
    readOnlyOptionClick,
    onBlur,
    onChange,
    inputProps,
    InputProps,
    InputLabelProps,
    FormHelperTextProps,
    ChipListProps,
    ChipProps,
    IconProps,
    ListProps,
    ListItemProps,
    SearchFieldProps,
    ...innerProps
  } = useComponentProps(props, 'CloudField', {
    static: ['disabled', 'focused', 'error', 'multiple'],
    children: ['checkbox', 'label', 'helper']
  });

  const innerRef = useRef(null);
  const searchRef = useRef(null);
  const [focusActive, setFocusActive] = React.useState(false);

  useImperativeHandle(ref, () => innerRef.current);

  useEffect(() => {
    if (autoFocus) {
      const focus = () => {
        return dom.focusElement(innerRef.current?.querySelector?.('input'));
      }

      utils.retry(focus, 3);
    }
  }, [autoFocus]);

  const doChange = (value) => {
    onChange?.({
      target: {
        name: name,
        value: value ?? ''
      }
    });
  };

  const handleFocus = (e) => {
    innerProps.onFocus?.(e);
    setFocusActive(!innerProps.readOnly);
  };

  const handleBlur = (e) => {
    setFocusActive(false);

    if (focusActive) {
      if (innerRef.current && !dom.isPartOfParent(e.relatedTarget, innerRef.current.querySelector('.InputContainer'))) {
        onBlur?.({
          target: {
            name: name
          }
        });
      }
    }
  };

  const isOptionEqualToValue = useCallback((option, value) => {
    return utils.matchOptionToValue(option, value);
  }, []);

  const selection = useMemo(() => {
    const getOption = (v) => {
      if (utils.isObject(v)) {
        return v;
      } else if (v && utils.isString(v)) {
        return {label: v, value: null};
      } else if (utils.isDefined(v)) {
        return {label: '???', value: v};
      } else {
        return null;
      }
    }

    let selection = (typeof value === 'string' && value) ?
      value.split(',') : value;
    selection = selection ? utils.toArray(selection, true)
      .map((v) => getOption(v)).filter((_) => (_)) : [];

    if (sorted) {
      return selection.sort((a, b) => {
        return a.label.localeCompare(b.label) || (+a.value - +b.value);
      });
    } else {
      return selection;
    }
  }, [value, sorted]);

  const handleDeleteClick = (option) => () => {
    if (!innerProps.disabled) {
      if (option) {
        doChange(selection.filter((opt) => !isOptionEqualToValue(opt, option)));
      }
    }
  }

  const handleInputValueChange = (e) => {
    if (e.target.value) {
      doChange(selection.filter((opt) => !isOptionEqualToValue(opt, e.target.value)).concat(e.target.value));
    }
  }

  const handleInputKeyDown = (e) => {
    if (e.key === 'ArrowUp') {
      dom.focusElement(dom.getParent(e.target, null, 'CloudField-container').querySelector('.CloudField-list'));
      e.preventDefault();
    }
  }

  const handleFilterOption = (option) => {
    const found = utils.toArray(selection, true).find((opt) => isOptionEqualToValue(opt, option));

    return found ? false : null;
  }

  const handleOptions = ({search, callback}) => {
    return options({
      search,
      ids: utils.toArray(selection, true).map((opt) => `-${opt.value ?? opt}`),
      callback
    });
  }

  const renderReadOnly = () => {
    const chipSize = size === 'smaller' ? 'small' : size;
    const iconSize = size === 'medium' ? 'small' : 'smaller';
    if (selection) {
      return <ChipList className="CloudField-readOnly CloudField-readOnly-chips Input-readOnly"
                       variant="compact"
                       {...ChipListProps}>
        {selection
          .sort((a, b) => a.index - b.index)
          .map((v, idx) => {
            const optionIcon = icon ?? ChipProps?.icon ?? v.icon;
            const optionAction = utils.isFunction(readOnlyAction) ? readOnlyAction(v) :
              (readOnlyAction ?? v.action);

            const action = {
              label: utils.fieldValue2FormattedValue(format, v.label, true, false),
              icon: (optionIcon && !hiddenIcons) ?
                <Icon icon={optionIcon} size={iconSize} {...IconProps}/> : null,
              ChipProps: utils.filterObject(ChipProps, ['icon']),
              onClick: readOnlyOptionClick?.(v),
              ...optionAction
            }

            return <ActionChip key={idx} size={chipSize} action={action} showInactive={true}/>
          })}
      </ChipList>
    }
  }

  const renderList = () => {
    const chipSize = 'medium';
    const iconSize = 'small';

    if (selection?.length === 0) {
      return <Box className="CloudField-empty">
        <P>{emptyText ?? 'No tags selected'}</P>
      </Box>
    } else {
      return <ChipList className="CloudField-list"
                       variant="compact"
                       {...ChipListProps}>
        {selection.map((option, idx) => {
          const optionIcon = icon ?? ChipProps?.icon ?? option.icon;

          return <Chip key={idx}
                       size={chipSize}
                       icon={(optionIcon && !hiddenIcons) ?
                         <Icon icon={optionIcon} size={iconSize} {...IconProps}/> : null}
                       label={option.label}
                       color={option.color ?? 'default'}
                       disabled={innerProps.disabled}
                       onDelete={handleDeleteClick(option)}
                       {...utils.filterObject(ChipProps, ['icon'])} />
        }).filter((_) => (_))}
      </ChipList>
    }
  }

  innerProps.focused = innerProps.focused ?? focusActive;

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledCloudField ref={innerRef} {...innerProps}
                           onFocus={handleFocus}
                           onBlur={handleBlur}>
    <FormLabel size={size}
               {...utils.cleanObject({
                 htmlFor: (!(innerProps.readOnly || innerProps.disabled) ? id : null),
               })}
               className={utils.classNames('CloudField-label', InputLabelProps?.className)}
               {...InputLabelProps}>
      {label}
    </FormLabel>
    <InputContainer className="CloudField-container">
      {!innerProps.readOnly ? renderList() : null}
      {innerProps.readOnly ? renderReadOnly() : null}

      {(!innerProps.readOnly && showAutocomplete) ?
        <AutocompleteField ref={searchRef}
                           className="CloudField-search"
                           onChange={handleInputValueChange}
                           onKeyDown={handleInputKeyDown}
                           onFilterOption={handleFilterOption}
                           clearAfterChange={true}
                           disableCloseOnSelect={true}
                           options={utils.isFunction(options) ? handleOptions : options}
                           InputProps={InputProps}
                           inputProps={inputProps}
                           {...utils.cleanObject({
                             autoFocus,
                             format,
                             hiddenLabel: true,
                             hiddenHelperText: true,
                             placeholder: placeholder,
                             fullWidth: innerProps.fullWidth,
                             readOnly: innerProps.readOnly,
                             variant: innerProps.variant,
                             disabled: innerProps.disabled,
                             createOption: createOption,
                             openDirect: openDirect,
                             openOnFocus: openOnFocus,
                             size: size
                           })}
                           {...SearchFieldProps}/> : null}

      <FormHelperText component="div" {...FormHelperTextProps}
                      className={utils.classNames('CloudField-helper', FormHelperTextProps?.className)}>
        {helperText}
      </FormHelperText>
    </InputContainer>
  </StyledCloudField>
}));

CloudField.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.any,
  icon: PropTypes.any,
  variant: PropTypes.string,
  format: PropTypes.string,
  placeholder: PropTypes.any,
  hiddenPlaceholder: PropTypes.bool,
  hiddenSelectionHelper: PropTypes.bool,
  hiddenIcons: PropTypes.bool,
  value: PropTypes.any,
  size: PropTypes.string,
  helperText: PropTypes.any,
  autoFocus: PropTypes.bool,
  multiple: PropTypes.bool,
  openDirect: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  sorted: PropTypes.bool,
  emptyText: PropTypes.string,
  createOption: PropTypes.bool,
  readOnlyAction: PropTypes.object,
  readOnlyOptionClick: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  inputProps: PropTypes.object,
  InputLabelProps: PropTypes.object,
  FormHelperTextProps: PropTypes.object,
  ChipListProps: PropTypes.object,
  ChipProps: PropTypes.object,
  IconProps: PropTypes.object,
  ListProps: PropTypes.object,
  ListItemProps: PropTypes.object,
  SearchFieldProps: PropTypes.object
};

CloudField.defaultProps = {
  showAutocomplete: true,
  inputProps: {},
  size: 'small',
  hiddenIcons: true,
  openDirect: true
};

export default CloudField;
