import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import GraphCard from 'components/molecules/Cards/GraphCard/GraphCard';

const StyledCollectionEntitiesRelevancyDistributionGraphCard = styled(GraphCard)`
  display: flex;
  flex-direction: column;
  
  .CardContent {
    padding-top: 0;
    padding-bottom: 0;

    .CollectionEntitiesRelevancyDistributionGraphCard {
      &-content {
        height: 100%;
        width: 100%;
        
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      
      &-chart {
        flex-grow: 1;
        display: flex;
        align-items: center;
        aspect-ratio: 616/314;
        padding: ${props => props.theme.spacing(4)};
        
        ${props => props.theme.breakpoints.down('lg')} {
          padding: ${props => props.theme.spacing(1)};
        }
        ${props => props.theme.breakpoints.between('sm', 'md')} {
          padding: ${props => props.theme.spacing(4)};
        }
        .RelevancyDistributionPieChart {
          &-center {
            ${props => props.theme.breakpoints.down('lg')} {
              transform: scale(0.75);
            }
            ${props => props.theme.breakpoints.down('md')} {
              transform: scale(1);
            }
          }
        }
      }
      
      &-tables {
        display: flex;
        flex-direction: column;
        gap: ${props => props.theme.layout('3sp')};
      }
      
      &-consensus, &-disagreement {
        &-table {
          height: fit-content;
        }
        &-header {
          margin-bottom: ${props => props.theme.layout(12)};
          &-subtitle {
            display: flex;
            justify-content: space-between;
            align-items: center;
            
            .ScoreLinearProgress {
              width: ${props => props.theme.layout(134)};
            }
          }
        }
        tr {
          th { 
            font-weight: normal;
            font-size: ${props => props.theme.fontSize(12)};
          }

          th {
            padding: 0 ${props => props.theme.layout('1sp')};
          }
          td {
            padding: ${props => props.theme.layout('2sp')} ${props => props.theme.layout('1sp')};
          }
          th:first-child, td:first-child {
            padding-left: 0;
            padding-right: 0;
          }
          th:nth-child(3), td:nth-child(3) {
            // companies
            min-width: unset;
            width: unset;
            padding-left: ${props => props.theme.layout('1.5sp')};
            padding-right: ${props => props.theme.layout('1.5sp')};
          }
          th:last-child, td:last-child {
            padding-right: 0;
          }
        }
        tr:hover td {
          background-color: unset !important;
        }
        
        .RelevancyTableCell {
          .ActionButton {
            pointer-events: none;
            height: ${props => props.theme.layout(24)};
            min-height: unset;
            font-weight: normal;
          }
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledCollectionEntitiesRelevancyDistributionGraphCard.propTypes = {
  theme: PropTypes.object
};

StyledCollectionEntitiesRelevancyDistributionGraphCard.defaultProps = {}

export default StyledCollectionEntitiesRelevancyDistributionGraphCard;
