import React, {useMemo} from 'react'
import {useCollectionGet} from 'services/collection/collection.hooks';
import {useParams} from 'react-router-dom';
import {
  useCollectionEntityAdd, useCollectionEntityAssign,
  useCollectionEntityDelete, useCollectionEntityEnrich,
  useCollectionEntityGet, useCollectionEntityUnAssign
} from 'services/collection/entity/entity.hooks';
import {
  useCollectionEntityGroups,
  useCollectionEntityPatch, useCollectionEntityCallbacks,
  useCollectionEntitySections
} from 'services/collection/entity/entity.utils';
import constants from 'helpers/constants';
import EntityProfileProvider from 'components/organisms/Providers/ProfileProvider/EntityProfileProvider';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import utils from 'helpers/utils';
import {useEntityCsiPatch} from 'services/entity/csi/csi.utils';
import {useProviderView} from 'helpers/hooks/utils';
import {useCollectionEntityAnswerAdd} from 'services/collection/entity/answer/answer.hooks';
import {useEntityTaskCreate} from 'services/entity/comment/comment.utils';
import {useAuthClientId} from 'services/auth/auth.utils';
import {useCollectionCustomFieldCache} from 'services/collection/customField/customField.hooks';
import {useQuestionnaireQuestions} from 'services/collection/questionnaire/questionnaire.utils';

const CollectionEntityProfileProvider = (props) => {
  const params = useParams();
  const tableProvider = useTable();
  const { collectionId, entityId } = {...utils.cleanObject({
    collectionId: tableProvider?.context?.data?.collectionId,
    entityId: tableProvider?.selected()?.entityId
  }), ...params, ...props};

  const clientId = useAuthClientId();
  const view = useProviderView('collection.entity', props.skipView);

  const collection = useCollectionGet({collectionId}, {enabled: +collectionId > 0});
  const entity = useCollectionEntityGet({collectionId, entityId: entityId},
    {enabled: (+collectionId > 0 && +entityId > 0)});

  const customFields = useCollectionCustomFieldCache({clientId: clientId, collectionId: collection.data?.collectionId, addLinks: false},
    {enabled: clientId >= 0 && collection.data?.collectionId > 0});
  const customFieldsCache = (customFields.data?.data ?? customFields.data);
  const [questionnaireQuestions, isLoadingQuestions, isSuccessQuestions] = useQuestionnaireQuestions(collection?.data);

  const groups = useCollectionEntityGroups(view, collection?.data, customFieldsCache);
  const sections = useCollectionEntitySections(view, collection?.data, questionnaireQuestions);
  const entityProfileCallbacks = useCollectionEntityCallbacks(collection?.data);

  // isLoading anything, is loading something definition related
  const isLoading = props?.isLoading || customFields.status?.isLoading || collection.status?.isLoading || isLoadingQuestions || entity.status?.isLoading;
  const isDefinitionsLoading = props?.isDefinitionsLoading || !customFields.status?.isSuccess || !collection.status?.isSuccess || !isSuccessQuestions;

  const cardDefinitions = useMemo(() => {
    return groups?.length > 0 ? groups : null;
  }, [groups]);

  const sectionDefinitions = useMemo(() => {
    return sections?.length > 0 ? sections : null;
  }, [sections]);

  const options = useMemo(() => ({
    cardState: {
      options: {
        name: 'collectionEntity',
        type: constants.appState.type.local
      }
    },
    cardDefinitions,
    sectionDefinitions
  }), [cardDefinitions, sectionDefinitions]);

  const fieldData = useMemo(() => {
    return {
      customFields: customFieldsCache,
      tagGroups: collection.data?.tagGroups,
      questionnaireQuestions: questionnaireQuestions,
      callbacks: entityProfileCallbacks
    };
  }, [collection.data?.tagGroups, questionnaireQuestions, customFieldsCache, entityProfileCallbacks]);

  const update = useCollectionEntityPatch();
  const create = useCollectionEntityAdd();
  const deleteData = useCollectionEntityDelete();
  const enrich = useCollectionEntityEnrich();
  const assignItems = useCollectionEntityAssign();
  const unAssignItems = useCollectionEntityUnAssign();
  const acceptItems = useCollectionEntityAssign({deleteItem: true});
  const createTask = useEntityTaskCreate();
  const createAnswer = useCollectionEntityAnswerAdd();
  const updateCsi = useEntityCsiPatch();
  const updaters = useMemo(() => {
    return {
      createData: (data) => {
        return create.mutation.mutateAsync({collectionId: collection.data?.collectionId, ...data});
      },
      updateData: (entity, changes) => {
        return update(collection.data, entity, changes);
      },
      deleteData: (data) => {
        return deleteData.mutation.mutateAsync({collectionId: collection.data?.collectionId, ...data});
      },
      enrichData: (data) => {
        return enrich.mutation.mutateAsync({collectionId: collection.data?.collectionId, ...data});
      },
      assignItems: (data) => {
        return assignItems.mutation.mutateAsync({collectionId: collection.data?.collectionId, ...data});
      },
      unAssignItems: (data) => {
        return unAssignItems.mutation.mutateAsync({collectionId: collection.data?.collectionId, ...data});
      },
      acceptItems: (data) => {
        return acceptItems.mutation.mutateAsync({collectionId: collection.data?.collectionId, ...data});
      },
      createAnswer: (data) => {
        return createAnswer.mutation.mutateAsync({collectionId: collection.data?.collectionId, ...data});
      },
      createTask: (entity, data) => {
        return createTask([entity], collection.data?.collectionId, data);
      },
      updateCsi: (entity, changes) => {
        return updateCsi(entity, {collectionId: collection.data?.collectionId, ...changes});
      }
    }
  }, [update, create.mutation, deleteData.mutation, enrich.mutation, assignItems.mutation, createTask,
    unAssignItems.mutation, acceptItems.mutation, createAnswer.mutation, updateCsi, collection.data]);

  return <EntityProfileProvider {...props}
                                view={view}
                                context={collection}
                                data={entity}
                                options={options}
                                fieldData={fieldData}
                                updaters={updaters}
                                isDefinitionsLoading={isDefinitionsLoading}
                                isLoading={isLoading}>
    {props.children}
  </EntityProfileProvider>
};

CollectionEntityProfileProvider.propTypes = {
}

export default CollectionEntityProfileProvider;
