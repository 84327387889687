import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Accordion from 'components/atoms/Accordions/Accordion/Accordion';

const StyledKanbanPanelContent = styled(Accordion)`
  &::before {
    height: 0;
    background-color: unset;
  }
  
  .AccordionSummary {
    .MuiAccordionSummary-content, .AccordionSummary-badge {
      width: 100%;
      justify-content: space-between;
    }
    
    .Badge .MuiBadge-badge {
      color: ${props => props.theme.property('palette.text.primary')};
      background-color: ${props => props.theme.property('palette.primary.states.focusVisible')};
    }

    .KanbanPanelContent {
      &-title {
        flex-grow: 1;
        width: 1px;
        max-width: fit-content;
        
        > .Typography {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .MuiAccordionSummary-expandIconWrapper {
      transform: unset;
      &.Mui-expanded {
        .IconButton .Icon {
          transform: rotate(180deg);
        }
      }
    }
  }
  
  .AccordionDetails {
    margin: ${props => props.theme.spacing(0.5)} 0;
    padding: 0;
    
    border-radius: ${props => props.theme.radius(1)};
    
    .KanbanPanelContent {
      &-content {
        .DataList-list {
          max-height: min(70vh, ${props => props.theme.layout(520)});
          margin-bottom: -${props => props.theme.spacing(0.5)};
        }
      }
    }
  }
  
  .MuiCollapse-hidden {
    .AccordionDetails {
      height: 1px;
      overflow: hidden;
      display: flex;

      .DataList {
        &-list {
          .ListItem {
            display: none;
          }
        }
        &-header, &-footer {
          display: none;
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledKanbanPanelContent.propTypes = {
  theme: PropTypes.object
};

StyledKanbanPanelContent.defaultProps = {
}

export default StyledKanbanPanelContent;
