import utils from 'helpers/utils';
import {useServiceApiMutation, useServiceApiQuery} from 'stores/hooks/query';
import constants from 'helpers/constants';

const path = utils.servicePath(import.meta.url);

export function useSourceList ({search, filter, sort, page, pageSize, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list', {search, filter, sort, page, pageSize, ...rest}, queryOptions)
}

export function useSourceGet ({sourceId}, queryOptions = {}) {
  return useServiceApiQuery(path, 'get', {sourceId}, queryOptions);
}

export function useSourceAdd (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

export function useSourceUpdate (mutationOptions = {}) {
  return useServiceApiMutation(path, 'patch', {}, mutationOptions);
}

export function useSourceDelete (mutationOptions = {}) {
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

export function useSourceUpload (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    overridePath: 'sources/upload',
    overrideDataType: constants.dataTypes.other,
    ...mutationOptions
  });
}

export function useSourcePreProcess (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    overridePath: 'sources/preProcessFile',
    overrideDataType: constants.dataTypes.other,
    ...mutationOptions
  });
}

export function useSourceProcess (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    overridePath: 'sources/processFile',
    overrideDataType: constants.dataTypes.other,
    ...mutationOptions
  });
}
