import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Page from 'components/pages/Base/Page/Page';

const StyledCreditsSettingPage = styled(Page)`
  .CreditsSettingPage {
    &-header {
      .SettingsHeader {
        padding-bottom: ${props => props.theme.spacing(2)};
        
        &-info {
          margin-bottom: -${props => props.theme.spacing(1)};
        }
      }
      &-content {
        display: flex;
        flex-direction: column;
        gap: ${props => props.theme.layout('2sp')};
        padding: 0 ${props => props.theme.spacing(3)} ${props => props.theme.spacing(1.5)} ${props => props.theme.spacing(3)};
        
        .Card {
          aspect-ratio: 536/352;
          min-height: ${props => props.theme.layout(260)};
        }
      }
    }
    
    &-budgetPurpose {
      display: flex;
      align-items: center;
      justify-content: center;
      
      width: ${props => props.theme.layout(32)};
      max-width: ${props => props.theme.layout(32)};
      height: ${props => props.theme.layout(32)};
      max-height: ${props => props.theme.layout(32)};
      border-radius: ${props => props.theme.radius(1.5)};
      background-color: ${props => props.theme.property('palette.primary.states.selected')};
    }
  }
  
  ${ComponentStyles}
`;

StyledCreditsSettingPage.propTypes = {
  theme: PropTypes.object
};

StyledCreditsSettingPage.defaultProps = {}

export default StyledCreditsSettingPage;
