import utils from 'helpers/utils';

function commentMentions (text) {
  const split = utils.splitMentions(text, [{trigger: '@'}]);
  const mentions = [];
  split.forEach((s) => {
    if (s.mention && !mentions.find((m) => +m.user_id === +s.mention.id)) {
      mentions.push({
        'user_id': +s.id,
        'username': s.display.slice(1),
        'displayed_value': s.display
      })
    }
  });

  return mentions;
}

function createComment (text, type, ids, collectionId = null, mentions = null,
                        task = false, title = null, taskType = null, responsible = null,
                        collaborationType = null, dueDate = null) {
  const cleanMentions = (mentions) => {
    return utils.toArray(mentions).map((m) => ({
      user_id: m.user_id ?? m.value,
      username: m.username ?? m.display,
      displayed_value: m.displayed_value ?? `@${m.display}`
    }));
  };

  const titleMentions = commentMentions(title);
  const textMentions = commentMentions(text);

  const mentionedMembers = utils.uniqueArray(cleanMentions(textMentions.concat(titleMentions).concat(mentions ?? [])), 'user_id');
  const responsibleMembers = utils.uniqueArray(cleanMentions(responsible ?? textMentions), 'user_id');

  return {
    on: {
      type,
      id: ids.length === 1 ? ids[0] : null,
      ids: ids.length > 1 ? ids : null,
    },
    entity_ids: type === 'entity' ? ids : null,
    collection_id: collectionId,
    text,
    mentions: mentionedMembers,
    'is_action_item': task,
    'action_item': task ? {
      title,
      responsible: responsibleMembers,
      type: taskType,
      collaboration_type: collaborationType,
      'due_date': dueDate ? dueDate.toISOString() : null
    } : null
  }
}

function calcCommentChanges (comment, changes) {
  const titleMentions = commentMentions(changes.title);
  const textMentions = commentMentions(changes.text);

  const mentions = utils.uniqueArray(textMentions.concat(titleMentions), 'user_id');

  const changed = {};

  let actionItem = comment.actionItem, actionItemChanged = false;
  if (changes.text || changes.title) {
    changed.mentions = mentions;

    if (comment.isActionItem && changes.title) {
      actionItemChanged = true;
      actionItem = {
        ...actionItem,
        title: changes.title
      };
    }

    if (changes.text) {
      changed.text = changes.text;
    }
  }

  if (comment.isActionItem && changes.responsible) {
    actionItemChanged = true;
    actionItem = {
      ...actionItem,
      'responsible': changes.responsible
    };
  }

  if (comment.isActionItem && changes.dueDate) {
    actionItemChanged = true;
    actionItem = {
      ...actionItem,
      'due_date': changes.dueDate ? changes.dueDate.toISOString() : null
    };
  }

  if (actionItemChanged) {
    changed['action_item'] = actionItem;
  }

  return changed;
}

const comment = {
  createComment,
  commentMentions,
  calcCommentChanges
}

export default comment;
