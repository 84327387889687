import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledSourceStatsPieChart = styled(Box)`
  display: flex;
  flex-direction: column;
  
  gap: ${props => props.theme.layout('1sp')};
  height: 100%;
  
  .SourceStatsPieChart {
    &-chart {
      height: 100%;
      overflow: hidden;
      gap: ${props => props.theme.layout('2sp')};
    }
    
    &-chart-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      height: 100%;
      aspect-ratio: 1;
    }

    &-center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;

      .totalCount {
        line-height: ${props => props.theme.lineHeight('1.125f')};
      }

      .H6 {
        text-align: center;
      }
    }
    
    &-legend {
      flex-grow: 1;
      align-items: center;
      
      .Legend-list {
        gap: ${props => props.theme.layout('0.5sp')} !important;
      }
    }
  }

  // variants
  &.SourceStatsPieChart-half {
    .SourceStatsPieChart {
      &-center {
        height: 100%;
        justify-content: end;

        .Icon {
          font-size: ${props => props.theme.fontSize(24)};
        }

        .totalCount {
          margin-top: ${props => props.theme.layout(4)};
          margin-bottom: ${props => props.theme.layout(-3)};
          font-size: ${props => props.theme.fontSize(20)};
          line-height: ${props => props.theme.lineHeight('1f')};
          font-weight: 400;
        }

        .title {
          display: none;
        }
      }
    }
  }

  &.SourceStatsPieChart-orientation-horizontal {
    width: 100%;
    flex-direction: row;
    gap: ${props => props.theme.layout('1sp')};
    .SourceStatsPieChart {
      &-chart {
        display: flex;
        align-items: center;
        width: 100%;
      }

      &-legend {
        overflow: hidden;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledSourceStatsPieChart.propTypes = {
  theme: PropTypes.object
};

StyledSourceStatsPieChart.defaultProps = {}

export default StyledSourceStatsPieChart;
