import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useUpdatedRef} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledFieldTableCellEdit from 'components/organisms/TableCellEdits/FieldTableCellEdit/FieldTableCellEdit.styles';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import constants from 'helpers/constants';
import FieldTableCell from 'components/molecules/TableCells/FieldTableCell/FieldTableCell';
import {withMemo} from 'helpers/wrapper';

const FieldTableCellEdit = withMemo(React.forwardRef((props, ref) => {
  const {
    fields,
    fieldData,
    onChange,
    onDirty,
    ...innerProps
  } = useComponentProps(props, 'FieldTableCellEdit');

  const closeRef = useRef(false);
  const [busy, setBusy] = useState(false);

  const snackbar = useSnackbar();

  const onDirtyEvent = useEffectEvent(onDirty);

  useEffect(() => {
    onDirtyEvent?.(busy);
  }, [busy, onDirtyEvent]);

  const handleSubmit = () => {
    if (!busy) {
      innerProps.table?.setEditingCell(null);
    } else {
      closeRef.current = true;
    }
  }

  const handleChange = (field, value, onSuccess, onError) => {
    const handleError = (err) => {
      innerProps.table.setEditingCell((current) => {
        if (current?.id !== innerProps.cell?.id) {
          snackbar.show(err ?? 'Saving failed', null,
            {color: 'error', autoHideDuration: constants.delay.error});
        }

        return current;
      });
      setBusy(true);
      onError?.(err);
    }

    const handleSuccess = (msg) => {
      onSuccess?.(msg);
      setBusy(false);
    }

    onChange?.(field, value, handleSuccess, handleError);
  };

  const tableRef = useUpdatedRef(innerProps.table);
  useLayoutEffect(() => {
    if (!busy && closeRef.current) {
      tableRef.current?.setEditingCell(null);
      closeRef.current = false;
    }
  }, [tableRef, busy]);

  const fieldsMemo = useMemo(() => {
    return fields.map((field) => {
      const formField = utils.fields2FormFields([field], fieldData)[0];
      return {
        ...field,
        debounce: constants.formFieldTypes.textTypes.multiline.includes(formField.type) ? constants.debounce.multiline : (
          constants.formFieldTypes.textTypes.singleLine.includes(formField.type) ? constants.debounce.input : false
        ),
        FormFieldProps: utils.mergeObjects(formField.FormFieldProps, {
          size: 'smaller',
          variant: !(fields.length > 1) ? 'inlineLabel' : 'staticLabel',
          hiddenLabel: !(fields.length > 1),
          fullWidth: true,
        }, true)
      }
    });
  }, [fields, fieldData]);

  const Anchor = innerProps.Anchor ?? <FieldTableCell fields={fieldsMemo}
                                                      fieldData={fieldData}
                                                      active={true}/>;

  const minWidth = fieldsMemo.reduce((m, f) => {
    return utils.isDefined(f.FormFieldProps?.minWidth) ? Math.max(m ?? 0, f.FormFieldProps?.minWidth) : m
  }, null);

  const maxWidth = fieldsMemo.reduce((m, f) => {
    return utils.isDefined(f.FormFieldProps?.maxWidth) ? Math.max(m ?? 0, f.FormFieldProps?.maxWidth) : m
  }, null);

  return <StyledFieldTableCellEdit ref={ref} {...innerProps}
                                   $minWidth={minWidth}
                                   $maxWidth={maxWidth}
                                   autoClose={!busy}
                                   autoFocus={false}
                                   TableCellPopperProps={{
                                     density: 'densest'
                                   }}
                                   Anchor={Anchor}>
    <InlineForm onSubmit={handleSubmit}
                onChange={handleChange}
                autoTouch={true}
                fields={fieldsMemo}
                fieldData={fieldData}
                onBusy={setBusy}/>
  </StyledFieldTableCellEdit>
}));

FieldTableCellEdit.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  icon: PropTypes.any,
  logo: PropTypes.any,
  fields: PropTypes.array,
  fieldData: PropTypes.object,
  onChange: PropTypes.func
};

FieldTableCellEdit.defaultProps = {
  children: 'FieldTableCellEdit text'
};

export default FieldTableCellEdit;
