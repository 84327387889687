import React from 'react';
import ReactDOM from 'react-dom/client';
import AppRoutes from 'app/App.routes';
import reportWebVitals from 'reportWebVitals';
import logger from 'helpers/logger';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<AppRoutes />);
//root.render(<React.StrictMode><AppRoutes /></React.StrictMode>); // doubles component creation in dev

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(logger.debug);
