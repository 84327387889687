import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';
import {rgba} from 'polished';

const StyledEntityFinanceHistoryProfileCardContent = styled(Box)`
  width: 100%;
  
  .EntityFinanceHistoryProfileCardContent {
    &-years {
      display: flex;
      align-items: center;
      border-top: 1px solid ${props => props.theme.property('palette.divider')};
      box-shadow: inset 0 -1px 0 rgba(207, 218, 226, 0.6000000238418579);
      padding: ${props => props.theme.spacing(10/8)} 0 0 0;
      margin-bottom: ${props => props.theme.spacing(10/8)};

      > .Typography:first-child {
        min-width: ${props => props.theme.layout(140 - 24 - 8)};
        max-width: ${props => props.theme.layout(140 - 24 - 8)};
        margin-right: ${props => props.theme.layout('1sp')};
      }

      .Tabs {
        .MuiTab-root {
          min-width: unset;
          padding: ${props => props.theme.layout('1sp')} ${props => props.theme.layout('2sp')};
        }
      }
    }
    
    &-selector {
      flex-grow: 1;
      display: flex;
      align-items: center;
      gap: ${props => props.theme.layout('1sp')};
      
      > *:nth-child(2) {
        flex-grow: 1;
        text-align: left;
      }
    }

    &-info {
      width: 100%;
      display: flex;
      align-items: flex-start;
      gap: ${props => props.theme.layout('0.5sp')};
      border-top: 1px solid ${props => props.theme.property('palette.divider')};
      padding-top: ${props => props.theme.spacing(1)};
      margin-top: ${props => props.theme.spacing(1.25)};
      
      .Icon {
        margin-top: ${props => props.theme.layout(3.25)};
      }
      .Typography {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-top: ${props => props.theme.layout(2)};
      }
    }
    
    &-empty {
      width: 100%;
      text-align: center;
      color: ${props => rgba(props.theme.property('palette.text.primary'), props.theme.property('palette.action.disabledOpacity'))};
      font-style: italic;

      P {
        white-space: pre-line;
        margin: ${props => props.theme.spacing(1)} 0;
      }
    }
  }
  
  &.EntityFinanceHistoryProfileCardContent-isEditing {
    width: calc(100% + ${props => props.theme.spacing(6)});
    margin-right: ${props => props.theme.spacing(-3)};
    margin-left: ${props => props.theme.spacing(-3)};
    
    .EntityFinanceHistoryProfileCardContent {
      &-years {
        border-top: unset;
        min-height: ${props => props.theme.layout(42)};
        box-shadow: inset 0 -1px 0 ${props => props.theme.property('palette.divider')};
        margin-bottom: ${props => props.theme.spacing(2)};
        padding: 0 ${props => props.theme.spacing(3)};

        > .Typography:first-child {
          min-width: unset;
          margin-right: ${props => props.theme.layout('2sp')};
        }

        > .Typography:nth-child(3) {
          margin-left: ${props => props.theme.layout('2sp')};
        }
      }
      
      &-tab {
        display: flex;
        flex-direction: column;
        padding: 0 ${props => props.theme.spacing(3)};
        gap: ${props => props.theme.layout('2sp')};

        .Form {
          .Form-fields {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: ${props => props.theme.layout('2sp')};
          }
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledEntityFinanceHistoryProfileCardContent.propTypes = {
  theme: PropTypes.object
};

StyledEntityFinanceHistoryProfileCardContent.defaultProps = {}

export default StyledEntityFinanceHistoryProfileCardContent;
