import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Card from 'components/atoms/Cards/Card/Card';

const StyledExampleCard = styled(Card)`
  position: relative;
  padding: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(1.5)};
  
  .ExampleCard{
    &-title {
      > .Typography {
        font-weight: 500;
        color: ${props => props.theme.property('palette.text.secondary')};
      }
    }
    
    &-text {
      color: ${props => props.theme.property('palette.text.secondary')};
    }

    &-tooltip {
      position: absolute;
    }
  }
  ${ComponentStyles}
`;

StyledExampleCard.propTypes = {
  theme: PropTypes.object
};

StyledExampleCard.defaultProps = {}

export default StyledExampleCard;
