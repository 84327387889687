import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledSourcesList from 'components/organisms/Lists/SourcesList/SourcesList.styles';
import SourceCard from 'components/organisms/Cards/SourceCard/SourceCard';
import constants from 'helpers/constants';

const SourcesList = React.forwardRef((props, ref) => {
  const {
    sources,
    onClick,
    isLoading,
    SourceCardProps,
    ListProps,
    ...innerProps
  } = useComponentProps(props, 'SourcesList');

  const renderCard = (source, state) => {
    const sourceDef = constants.data.lookup('sourceTypes', source?.type);
    const handleClick = (e) => {
      if (!e.defaultPrevented) {
        onClick?.(source);
      }
    }

    return <SourceCard source={source}
                       icon={sourceDef?.icon}
                       img={sourceDef?.img}
                       fullWidth={true}
                       selected={state.selected}
                       onClick={handleClick}
                       isLoading={state.isLoading}
                       IconProps={{color: sourceDef?.color}}
                       {...SourceCardProps} />
  };

  const isItemEqual = (source, selected) => {
    return +source.sourceId === +selected.sourceId;
  }

  innerProps.dataKey = innerProps.dataKey ?? 'sourceId';
  innerProps.isItemEqual = innerProps.isItemEqual ?? isItemEqual;
  innerProps.renderItem = innerProps.renderItem ?? renderCard;

  return <StyledSourcesList ref={ref} {...innerProps}
                            data={sources?.data}
                            count={sources.meta?.resultsCount}
                            loaderCount={3}
                            isLoading={isLoading || sources.status.isLoading}
                            showProgressBar={sources.status.isLoadingNext}
                            onFetchMore={sources.query?.fetchNextPage}
                            ListProps={{
                              track: true,
                              virtualize: true,
                              estimatedSize: 106,
                              gap: 8,
                              ...ListProps
                            }}/>
});

SourcesList.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  source: PropTypes.object,
  sources: PropTypes.object,
  Card: PropTypes.any,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  SourceCardProps: PropTypes.object,
  ListProps: PropTypes.object
};

SourcesList.defaultProps = {
};

export default SourcesList;
