import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';
import {rgba} from 'polished';

const StyledEntityFilesProfileCardContent = styled(Box)`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.layout('1.25sp')};
  max-height: ${props => props.theme.layout(224)};
  
  &:has(.EntityFilesProfileCardContent-empty) {
    gap: 0;
  }
  
  &:has(.EntityFilesProfileCardContent-action) {
    .EntityFilesProfileCardContent {
      &-files {
        height: calc(100% - ${props => props.theme.layout(36)} - ${props => props.theme.layout('1.25sp')});
      }
    }
  }
  
  .EntityFilesProfileCardContent {
    &-search {
      position: absolute;
      right: -${props => props.theme.layout('0.5sp')};
      width: ${props => props.theme.layout(204)};
      top: -${props => props.theme.layout('6sp')};

      background-color: ${props => props.theme.property('palette.background.paper')};
    }
    
    &-action {
      &-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
    
    &-empty {
      height: 100%;
      min-height: ${props => props.theme.layout('6sp')};
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: ${props => rgba(props.theme.property('palette.text.primary'), props.theme.property('palette.action.disabledOpacity'))};
      font-style: italic;

      P {
        white-space: pre-line;
        margin: ${props => props.theme.spacing(1)} 0;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledEntityFilesProfileCardContent.propTypes = {
  theme: PropTypes.object
};

StyledEntityFilesProfileCardContent.defaultProps = {}

export default StyledEntityFilesProfileCardContent;
