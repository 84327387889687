import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledQuestionnaireContextCard
  from 'components/organisms/Cards/QuestionnaireContextCard/QuestionnaireContextCard.styles';
import utils from 'helpers/utils';
import QuestionnaireCard from 'components/organisms/Cards/QuestionnaireCard/QuestionnaireCard';
import {useImperativeHandle, useRef} from 'react';
import dom from 'helpers/dom';
import constants from 'helpers/constants';
import Close from '@mui/icons-material/Close';
import Edit from '@mui/icons-material/Edit';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';

const QuestionnaireContextCard = React.forwardRef((props, ref) => {
  const {
    entity,
    collection,
    questionnaireQuestion,
    fieldData,
    canUpdate,
    onDirty,
    onChange,
    isLoading,
    QuestionnaireCardProps,
    ...innerProps
  } = useComponentProps(props, 'QuestionnaireContextCard');

  const innerRef = useRef(null);
  const [editing, setEditing] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useImperativeHandle(ref, () => innerRef.current);

  const action = useMemo(() => {
    const open = questionnaireQuestion?.questionnaires?.find((qn) => {
      return entity?.openQuestions?.find((q) => +q.questionId === +questionnaireQuestion.questionId && +q.questionnaireId === +qn.questionnaireId);
    })

    if (canUpdate && !submitting && !open) {
      return {
        tooltip: editing ? 'Close answer' : 'Edit answer',
        icon: editing ? Close : Edit,
        onClick: () => {
          setEditing((current) => !current);
        }
      };
    }
  }, [canUpdate, editing, submitting,  questionnaireQuestion?.questionId,
    questionnaireQuestion?.questionnaires, entity?.openQuestions]);

  const handleFocus = () => {
    setTimeout(() => {
      if (innerRef.current) {
        dom.scrollIntoView(innerRef.current);
        return true;
      }
    }, constants.debounce.shortest);
  }

  const handleChange = (answer) => {
    setSubmitting(true);

    return utils.asPromise(onChange)(answer)
      .then(() => {
        setEditing(false);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  const renderContext = () => {
    if (action) {
      return <ActionIconButton className="QuestionnaireContextCard-action"
                               tabIndex={-1}
                               action={action} density="dense"
                               size="smaller" variant="outlined"
                               IconProps={{size: 'tiny'}} />
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledQuestionnaireContextCard ref={innerRef} {...innerProps}
                                         title={questionnaireQuestion?.question?.question}
                                         context={renderContext()}
                                         onFocus={handleFocus}
                                         isLoading={isLoading}>
    <QuestionnaireCard entity={entity}
                       collection={collection}
                       fieldData={fieldData}
                       questionnaireQuestion={questionnaireQuestion}
                       canUpdate={canUpdate}
                       onChange={handleChange}
                       onDirty={onDirty}
                       isEditing={editing}
                       isLoading={isLoading} />
  </StyledQuestionnaireContextCard>
});

QuestionnaireContextCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  entity: PropTypes.object,
  questionnaireQuestion: PropTypes.object,
  canUpdate: PropTypes.bool,
  fieldData: PropTypes.object,
  onDirty: PropTypes.func,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool
};

QuestionnaireContextCard.defaultProps = {
  variant: 'question'
};

export default QuestionnaireContextCard;
