import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import Close from '@mui/icons-material/Close';
import StyledUsersActionbar from 'components/organisms/Snackbars/UsersActionbar/UsersActionbar.styles';
import {useClientUserDelete} from 'services/client/user/user.hooks';
import Delete from '@mui/icons-material/Delete';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import utils from 'helpers/utils';
import constants from 'helpers/constants';
import {useAuthClientId} from 'services/auth/auth.utils';
import useMediaQuery from '@mui/material/useMediaQuery';

const UsersActionbar = React.forwardRef((props, ref) => {
  const {
    listSelection,
    data,
    ...innerProps
  } = useComponentProps(props, 'UsersActionbar');

  const clientId = useAuthClientId();

  const snackbar = useSnackbar();
  const dialogControl = useDialogControl();

  const users = listSelection.dataSelection;

  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const deleteUser = useClientUserDelete();
  const clearSelectionEvent = useEffectEvent(listSelection.clearSelection);
  const actionItems = useMemo(() => {
    const actions = [];

    if (!mdDown) {
      actions.push({
        label: 'Remove Selection',
        icon: Close,
        ButtonProps: {
          variant: 'outlined',
          color: 'white'
        },
        onClick: () => {
          clearSelectionEvent?.();
        }
      });
    }

    if (listSelection.count <= listSelection.max) {
      actions.push({
        label: 'Remove users',
        auth: utils.createAuth({attribute: 'settings.users.delete', meta: {user: users}}),
        icon: Delete,
        ButtonProps: {
          color: 'error'
        },
        onClick: (e) => {
          const handleConfirm = () => {
            const userIds = users?.map((f) => +f.userId);

            return Promise.all(userIds.map((userId) => {
              return deleteUser.mutation.mutateAsync({
                clientId,
                userId,
              });
            })).catch(() => {
              snackbar.show(`Removing user${users.length === 1 ? '' : 's'} failed`, null,
                {color: 'error', autoHideDuration: constants.delay.error});
            });
          }

          const question = `Are you sure you want to remove ${users.length} user${users.length === 1 ? '' : 's'}?`;
          const explanation = `This will remove the users${users.length === 1 ? '' : 's'} including all user related data`;

          dialogControl.show(<ConfirmDialog question={question}
                                            explanation={explanation}
                                            challenge={`Remove ${users.length === 1 ? users[0].label : users.length}`}
                                            challengeLabel="User count"
                                            onConfirm={handleConfirm}
                                            ConfirmButtonProps={{
                                              children: `Remove user${users.length === 1 ? '' : 's'}`,
                                              color: 'error'
                                            }}/>, true);
          e.preventDefault();
        }
      });
    }

    return actions;
  }, [
    users, dialogControl, snackbar, listSelection.count, listSelection.max,
    deleteUser.mutation, clearSelectionEvent, clientId, mdDown
  ]);

  const renderTitle = () => {
    const count = utils.formatNumber(listSelection.count ?? 0);
    const title = `${count} user${listSelection.count === 1 ? '' : 's'} selected`;
    if (actionItems?.length > 1) {
      return title;
    } else {
      const max = utils.formatNumber(listSelection.max ?? 0);
      return `${title} (max ${max})`
    }
  }

  return <StyledUsersActionbar ref={ref} {...innerProps}
                               title={renderTitle()}
                               actions={actionItems} />
});

UsersActionbar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  listSelection: PropTypes.object,
  data: PropTypes.array
};

UsersActionbar.defaultProps = {};

export default UsersActionbar;
