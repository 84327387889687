import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Card from 'components/atoms/Cards/Card/Card';

const StyledPersonCard = styled(Card)`
  display: flex;
  flex-direction: column;
  
  .PersonCard {
    &-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: ${props => props.theme.layout('2sp')};
      
      padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(2)} ${props => props.theme.spacing(1.5)} ${props => props.theme.spacing(2)};
      
      &:not(:has(.PersonCard-role)) {
        align-items: unset;
        .PersonCard-content {
          padding-top: ${props => props.theme.layout('0.5sp')};
        }
      }
    }
    
    &-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 100%;
      gap: 0;
    }

    &-name {
      display: flex;
      align-items: flex-start;
      gap: ${props => props.theme.layout('0.75sp')};
      
      > *:last-child {
        flex-grow: 1;
        max-width: unset;
        display: inline-block;
        overflow: hidden;
      }
    }
    
    &-role {
      > .Typography {
        overflow: hidden;

        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    
    &-img {
      padding-top: ${props => props.theme.layout('0.5sp')};
    }

    &-text {
      width: 100%;
      overflow: hidden;

      &-wrapper {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: ${props => props.theme.layout('1.5sp')};
        padding: 0 ${props => props.theme.spacing(2)};
        
        .readMore {
          ${props => props.theme.font2Css('caption')};
          position: absolute;
          bottom: ${props => props.theme.layout(14)};
        }
      }
    }
    
    &-contact {
      display: flex;
      flex-direction: row;
      row-gap: ${props => props.theme.layout('0.75sp')};
      column-gap: ${props => props.theme.layout('1sp')};
      padding: 0 ${props => props.theme.spacing(2)};
      margin-bottom: ${props => props.theme.layout('0.5sp')};
      
      > .Typography {
        flex-grow: 1;
        max-width: fit-content;
      }
      
      .PersonCard-enrich {
        white-space: nowrap;
        
        &:has(.CircularProgress) {
          .MuiButton-startIcon {
            width: ${props => props.theme.layout(12)} !important;
            height: ${props => props.theme.layout(12)} !important;
          }
        }
        
        .CircularProgress {
          margin: ${props => props.theme.layout(0.75)} ${props => props.theme.layout(1)} ${props => props.theme.layout(1)} ${props => props.theme.layout(1)} !important;

          > * {
            width: ${props => props.theme.layout(9)} !important;
            height: ${props => props.theme.layout(9)} !important;
            color: ${props => props.theme.property('palette.action.disabled')};
          }
        }
      }
    }
    
    &-footer {
      display: flex;
      align-items: center;
      padding: 0 ${props => props.theme.spacing(2)} ${props => props.theme.spacing(2)} ${props => props.theme.spacing(2)};
      gap: ${props => props.theme.layout('1sp')};
      overflow: hidden;
    }
    
    &-linkedin, &-phone, &-email {
      line-height: ${props => props.theme.lineHeight('1f')};
      align-items: center;
      color: ${props => props.theme.property('palette.text.disabled')};
      
      margin-top: ${props => props.theme.layout(0)};
      svg {
        margin-top: ${props => props.theme.layout(0)};
      }

      &.Typography {
        display: flex;
        gap: ${props => props.theme.layout('0.5sp')};

        overflow: hidden;
        > * {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &:not(:has(.PersonCard-text)) {
    .PersonCard-contact {
      flex-wrap: wrap;
    }
  }
  
  &:has(.readMore) {
    .PersonCard-footer {
      min-height: ${props => props.theme.layout('3.75sp')};
      padding-right: ${props => props.theme.layout('11.5sp')};
    }
  }

  &.PersonCard-clampText {
    .PersonCard-text {
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  
  ${ComponentStyles}
`;

StyledPersonCard.propTypes = {
  theme: PropTypes.object
};

StyledPersonCard.defaultProps = {}

export default StyledPersonCard;
