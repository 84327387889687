import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import DropdownTableCellEdit from 'components/organisms/TableCellEdits/DropdownTableCellEdit/DropdownTableCellEdit';
import utils from 'helpers/utils';
import FormStyles from 'styles/mixins/Form.styles';

const StyledFieldTableCellEdit = styled(DropdownTableCellEdit)`
  display: flex;
  width: 100%;
  height: 100%;
  
  > .Icon, > .Logo {
    margin-right: ${props => props.theme.layout('1sp')};
  }

  &.FieldTableCellEdit {
    max-width: ${props => props.theme.layout(320)};
    max-height: min(${props => props.theme.layout(300)}, 48vh);

    &:has(.SuggestionField) {
      max-width: unset;
    }
    &:has(.TextField-multiline) {
      max-width: unset;
    }

    &:has(.CloudField) {
      max-width: unset;
      overflow: hidden;
    }
    
    ${props => utils.isDefined(props.$minWidth) ? css`
      max-width: unset;
      min-width: ${props.theme.layout(props.$minWidth)} !important;
    ` : css``}

    ${props => utils.isDefined(props.$maxWidth) ? css`
      max-width: ${props.theme.layout(props.$maxWidth)} !important;
    ` : css``}
  }
  
  .Form {
    height: 100%;
    padding: ${props => props.theme.spacing(2)};
    &:has(.ListField) {
      padding: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(2)};
    }
    
    .Form-fields {
      height: 100%;
      
      .FormField {
        min-width: ${props => props.theme.layout(240)};
        
        &.ListField {
          &:not(.ListField-showSearch) {
            min-width: unset;
          }
        }

        &.SuggestionField {
          min-width: ${props => props.theme.layout(420)};
          ${props => utils.isDefined(props.$maxWidth) ? css`
            min-width: calc(${props.theme.layout(props.$maxWidth)} - ${props.theme.layout('4sp')}) !important;
          ` : css``}
        }

        &.TextField-multiline {
          min-width: ${props => props.theme.layout(320)};
          ${props => utils.isDefined(props.$maxWidth) ? css`
            min-width: calc(${props.theme.layout(props.$maxWidth)} - ${props.theme.layout('4sp')}) !important;
          ` : css``}
          .MuiInputBase-root {
            max-height: 100%;
            .MuiInputBase-input {
              max-height: 100%;
              overflow: auto !important;
            }
          }
        }

        &.CloudField {
          max-width: ${props => props.theme.layout(380)};
          min-width: ${props => props.theme.layout(380)};
        }
      }
    }
  }
  
  .MuiFormControl-root {
    max-height: 100%;
    position: relative;
    &.CheckboxField, &.SwitchField {
      min-height: unset;
      
      .FormControlLabel {
        margin-left: ${props => props.theme.layout(-8)};
      }  
      .Checkbox {
        padding: ${props => props.theme.layout(6)};
        svg {
          font-size: ${props => props.theme.fontSize(20)};
        }
      }
      .MuiFormHelperText-root:has(*) {
        bottom: ${props => props.theme.layout(-12)};
      }
    }
    
    .InputContainer {
      max-height: 100%;
    }
    
    .MuiFormHelperText-root:has(*) {
      display: flex;
      width: 100%;
      bottom: ${props => props.theme.layout(-14)};
      height: ${props => props.theme.layout(16)};

      > * {
        width: 1px;
        flex-grow: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        p {
          display: contents;
        }
      }
    }

    .MuiFormHelperText-root:has(.FeedbackHelper) {
      height: unset;
    }
  }
  
  ${FormStyles}
  ${ComponentStyles}
`;

StyledFieldTableCellEdit.propTypes = {
  theme: PropTypes.object
};

StyledFieldTableCellEdit.defaultProps = {
}

export default StyledFieldTableCellEdit;
