import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Autocomplete from 'components/atoms/Inputs/Autocomplete/Autocomplete';

const StyledAutocompleteField = styled(Autocomplete)`
  &.FormControl-fullHeight {
    height: 100%;

    .AutocompleteField-container {
      height: 100%;

      .AutocompleteField-list {
        height: 100%;
      }
    }
  }
  
  .MuiOutlinedInput-root {
    .MuiInputBase-input {
      padding-left: ${props => props.theme.layout(8.5)} !important;
    }
  }
  .TextField {
    .MuiInputBase-root {
      &:not(.Mui-focused) {
        .MuiInputBase-input {
          min-width: 0 !important;
        }
        ul + .MuiInputBase-input {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
      
      li:has(.Chip) {
        max-width: calc(100% - ${props => props.theme.layout(2)});
      }
      
      &.Mui-focused {
        .Span:has(.Chip), .Chip {
          max-width: calc(100% - ${props => props.theme.layout(2)}) !important;
        }
      }
    }
    
    &.FormControl-fullWidth {
      &.FormControl-inlineLabel {
        .TextField-container {
          flex-grow: 1;
          width: 1px;
        }
      }
    }
    
    .Typography-span {
      max-width: 100%;
    }
    
    .MuiInputBase-root {
      padding-left: ${props => props.theme.layout(6)} !important;
      &:not(:has(.MuiAutocomplete-endAdornment)) {
        padding-right: ${props => props.theme.layout(6)} !important;
      }
      .MuiInputBase-input {
        padding-left: ${props => props.theme.layout(8)} !important;
      }
      
      &:has(.MuiInputAdornment-positionEnd) {
        padding-right: ${props => props.theme.layout(32)} !important;
        &:has(.MuiAutocomplete-endAdornment) {
          padding-right: ${props => props.theme.layout(64)} !important;
        }
        &:has(.MuiAutocomplete-endAdornment.CircularProgress) {
          padding-right: ${props => props.theme.layout(64)} !important;
        }
      }
      &:not(:has(.MuiInputAdornment-positionEnd)) {
        &:has(.MuiAutocomplete-endAdornment) {
          padding-right: ${props => props.theme.layout(32)} !important;
        }
        &:has(.MuiAutocomplete-endAdornment.CircularProgress) {
          padding-right: ${props => props.theme.layout(32)} !important;
        }
      }
    }
    
    .MuiAutocomplete-endAdornment.CircularProgress {
      position: absolute;
      top: ${props => props.theme.layout(5)} !important;
      right: ${props => props.theme.layout(4.5)} !important;
      
      > * {
        margin-right: ${props => props.theme.layout(1.5)};
        color: ${props => props.theme.property('palette.text.secondary')};
        
        width: ${props => props.theme.layout(20)} !important;
        height: ${props => props.theme.layout(20)} !important;
      }
    }
    
    .MuiInputAdornment-positionStart + .MuiInputBase-input {
      margin-left: ${props => props.theme.layout(-8)};
    }
    .MuiInputAdornment-root, .MuiAutocomplete-endAdornment {
      .MuiButtonBase-root {
        font-size: ${props => props.theme.fontSize(16)};
        padding: 0;
        margin-top: ${props => props.theme.layout(-1)};
        margin-right: ${props => props.theme.layout(-0.5)};
      }
      .MuiAutocomplete-popupIndicator {
        margin-top: ${props => props.theme.layout(2)};
      }
    }
    .MuiInputAdornment-positionStart {
      margin-left: ${props => props.theme.layout(5)};
      .MuiButtonBase-root {
        margin-left: ${props => props.theme.layout(-2)};
      }
    }
    .MuiInputAdornment-positionEnd {
      position: absolute;
      top: 50%;
      right: ${props => props.theme.layout(40)};
      
      margin-right: ${props => props.theme.layout(-3)};
      .MuiButtonBase-root {
        margin-right: ${props => props.theme.layout(-2)};
      }
    }

    .ChipList {
      gap: 0;
      .Chip {
        margin: ${props => props.theme.layout(2)} ${props => props.theme.layout(3)};
      }
    }

    .Chip, .MuiAutocomplete-tag {
      margin: ${props => props.theme.layout(2)} ${props => props.theme.layout(2)} !important;
      max-width: 100%;
    }
  }
  
  &.AutocompleteField-multiple {
    .TextField {
      .MuiInputBase-root {
        &.Mui-focused {
          .MuiInputBase-input {
            min-width: ${props => props.theme.layout(140)} !important;
          }
          span + .MuiInputBase-input, ul + .MuiInputBase-input {
            padding-left: ${props => props.theme.layout(4)} !important;
          }
        }
      }
    }
    
    &.Mui-focused {
      .TextField {
        .MuiInputBase-root {
          height: unset !important;
          padding-top: ${props => props.theme.layout(6)} !important;
          padding-bottom: ${props => props.theme.layout(6)} !important;
        }
      }
    }
    &:not(.Mui-focused) {
      .TextField {
        .MuiInputBase-root {
          flex-wrap: nowrap;
        }
      }
    }
  }
  
  &.AutocompleteField-size-smaller {
    .TextField {
      .MuiInputBase-root {
        padding-left: ${props => props.theme.layout(3)} !important;
        &:not(:has(.MuiAutocomplete-endAdornment)) {
          padding-right: ${props => props.theme.layout(3)} !important;
        }
        .MuiInputBase-input {
          padding-left: ${props => props.theme.layout(8)} !important;
          padding-top: ${props => props.theme.layout(2)} !important;
          padding-bottom: ${props => props.theme.layout(2)} !important;
        }

        .MuiAutocomplete-input {
          padding: ${props => props.theme.layout(2.5)} ${props => props.theme.layout(4)} ${props => props.theme.layout(2.5)} ${props => props.theme.layout(8)} !important;
        }
      }

      .MuiInputAdornment-positionStart {
        margin-left: ${props => props.theme.layout(6)};

        .MuiButtonBase-root {
          padding: ${props => props.theme.layout(2)} !important;
          margin-left: ${props => props.theme.layout(-2)};
          margin-top: ${props => props.theme.layout(0)};
        }
      }

      .MuiAutocomplete-endAdornment {
        right: ${props => props.theme.layout(7)};
      }

      .MuiInputAdornment-positionEnd {
        right: ${props => props.theme.layout(12)};
        margin-right: ${props => props.theme.layout(22)};

        .MuiButtonBase-root {
          padding: ${props => props.theme.layout(2)} !important;
          margin-top: ${props => props.theme.layout(2)};
        }
      }

      .Chip, .MuiAutocomplete-tag {
        margin: ${props => props.theme.layout(1)} ${props => props.theme.layout(2)} !important;
      }

      .MuiAutocomplete-endAdornment.CircularProgress {
        top: ${props => props.theme.layout(4)} !important;
        right: ${props => props.theme.layout(3.5)} !important;
        > * {
          width: ${props => props.theme.layout(20)} !important;
          height: ${props => props.theme.layout(20)} !important;
        }
      }
    }

    &.AutocompleteField-multiple {
      .TextField {
        .MuiInputBase-root {
          padding-left: ${props => props.theme.layout(4)} !important;
          &:not(:has(.MuiAutocomplete-endAdornment)) {
            padding-right: ${props => props.theme.layout(4)} !important;
          }
          padding-top: ${props => props.theme.layout(5)} !important;
          padding-bottom: ${props => props.theme.layout(5)} !important;
        }
      }

      &.Mui-focused {
        .TextField {
          .MuiInputBase-root {
            padding-top: ${props => props.theme.layout(4)} !important;
            padding-bottom: ${props => props.theme.layout(4)} !important;
          }
        }
      }
    }
  }

  &.AutocompleteField-size-smallest {
    .TextField {
      .MuiInputBase-root {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        padding-left: ${props => props.theme.layout(2)} !important;
        &:not(:has(.MuiAutocomplete-endAdornment)) {
          padding-right: ${props => props.theme.layout(2)} !important;
        }
        .MuiInputBase-input {
          padding-left: ${props => props.theme.layout(8)} !important;
          padding-top: ${props => props.theme.layout(2)} !important;
          padding-bottom: ${props => props.theme.layout(2)} !important;
        }

        .MuiAutocomplete-input {
          padding: ${props => props.theme.layout(2.5)} ${props => props.theme.layout(4)} ${props => props.theme.layout(2.5)} ${props => props.theme.layout(8)} !important;
        }
      }

      .MuiInputAdornment-positionStart {
        margin-left: ${props => props.theme.layout(6)};

        .MuiButtonBase-root {
          padding: ${props => props.theme.layout(2)} !important;
          margin-left: ${props => props.theme.layout(-2)};
          margin-top: ${props => props.theme.layout(0)};
        }
      }

      .MuiAutocomplete-endAdornment {
        right: ${props => props.theme.layout(7)};

        svg {
          font-size: ${props => props.theme.fontSize(22)};
        }
      }

      .MuiInputAdornment-positionEnd {
        top: calc(50% + ${props => props.theme.layout(1)});
        right: ${props => props.theme.layout(12)};
        margin-right: ${props => props.theme.layout(20)};

        .MuiButtonBase-root {
          padding: ${props => props.theme.layout(2)} !important;
          margin-top: ${props => props.theme.layout(2)};
        }
      }

      .Chip, .MuiAutocomplete-tag {
        margin: ${props => props.theme.layout(1)} ${props => props.theme.layout(2)} !important;
      }

      .MuiAutocomplete-endAdornment.CircularProgress {
        top: ${props => props.theme.layout(3.5)} !important;
        right: ${props => props.theme.layout(3.5)} !important;

        > * {
          width: ${props => props.theme.layout(18)} !important;
          height: ${props => props.theme.layout(18)} !important;
        }
      }
    }

    &.AutocompleteField-multiple {
      .TextField {
        .MuiInputBase-root {
          padding-top: ${props => props.theme.layout(3)} !important;
          padding-bottom: ${props => props.theme.layout(3)} !important;
        }
      }
      
      &.Mui-focused {
        .TextField {
          .MuiInputBase-root {
            padding-top: ${props => props.theme.layout(2)} !important;
            padding-bottom: ${props => props.theme.layout(2)} !important;
          }
        }
      }
    }
  }

  // see also listField
  .AutocompleteField-readOnly {
    .AutocompleteField-readOnly-chips {
      &.ChipList {
        .Chip {
          margin: 0;
        }
      }
    }

    &:not(.AutocompleteField-readOnly-chips) {
      display: flex;
      gap: ${props => props.theme.layout('0.5sp')};
      align-items: center;
      width: 100%;
      
      &:has(.placeholder) {
        color: ${props => props.theme.property('palette.text.disabled')};
      }

      > .Icon {
        align-self: flex-start;
      }
      > *:not(.Icon, .InputAdornment) {
        flex-grow: 1;
        width: 1px;
        max-width: fit-content;
      }

      .MuiInputAdornment-root {
        margin: 0;
        height: unset;
        min-height: unset;
        max-height: unset;
        color: unset;
        font: inherit;

        .Icon {
          color: ${props => props.theme.property('palette.action.active')};
        }
        
        .Link {
          text-decoration: none !important;
          &:has(.Icon) {
            line-height: 0;
          }
        }
        
        > * {
          font: inherit;
          color: unset;
        }
      }
    }
  }

  &.AutocompleteField-multiple {
    .AutocompleteField-readOnly {
      &:not(.AutocompleteField-readOnly-chips) {
        display: flex;
        gap: ${props => props.theme.layout('1sp')};
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        > .Box {
          display: flex;
          gap: ${props => props.theme.layout('1sp')};
          align-items: center;
          width: 100%;
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledAutocompleteField.propTypes = {
  theme: PropTypes.object
};

StyledAutocompleteField.defaultProps = {
}

export default StyledAutocompleteField;
