import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import BrowserBar from 'components/organisms/Bars/BrowserBar/BrowserBar';

const StyledEntityBrowserBar = styled(BrowserBar)`
  .EntityBrowserBar {
    &-preload {
      display: none;
    }
    
    &-center {
      width: 100%;
      display: flex;
      justify-content: center;
      
      .Form, &-loading {
        width: fit-content;
      }
      
      .Form-fields, &-loading-fields {
        display: grid;
        width: fit-content;
        grid-template-columns: repeat(${props => props.$centerColumns}, 1fr);
        grid-template-rows: repeat(${props => props.$centerColumns}, 1fr);
        grid-auto-flow: column;
        gap: ${props => props.theme.layout('0.25sp')} ${props => props.theme.layout('8sp')};
      }

      &-loading-fields {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-auto-flow: column;
        gap: ${props => props.theme.layout('0.25sp')} ${props => props.theme.layout('8sp')};
      }

      .FormControl-inlineLabel {
        .InputLabel {
          min-width: ${props => props.theme.layout(100)};
          max-width: ${props => props.theme.layout(100)};
          ${props => props.theme.font2Css('body2')};
          
          * {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .Input-readOnly {
          ${props => props.theme.font2Css('body2')};
          font-weight: 700;
          
          > * {
            width: fit-content;
          }
        }
      }
    }
  }
  
  &.EntityBrowserBar-showScore {
    .PageBar-center {
      margin-right: 0 !important;
      flex: 1 0 calc(min(${props => props.theme.layout(640)}, 36%)) !important;

      .EntityBrowserBar {
        &-center {
          display: flex;
          flex-wrap: nowrap;
          justify-content: flex-end;
          gap: ${props => props.theme.layout('2sp')};

          .InlineForm, &-loading {
            flex-grow: 1;
            justify-content: center;
            margin-left: ${props => props.theme.layout('2sp')};
            display: flex;
            overflow: hidden;
            
            .Form-fields {
              column-gap: ${props => props.theme.layout('4sp')};
            }
          }
          
          .EntityBrowserBar-scoreBar {
            align-self: center;
            height: ${props => props.theme.layout(23)};
            width: ${props => props.theme.layout(200)};
            margin-right: ${props => props.theme.layout('2sp')};
          }
        }
      }
    }
  }

  &.EntityBrowserBar-showSideBar {
    &.EntityBrowserBar-showScore {
      .EntityBrowserBar {
        &-center {
          .EntityBrowserBar-scoreBar {
            width: calc(min(${props => props.theme.layout(200)}, 20%));
          }
        }
      }
    }
  }
  
  &.EntityBrowserBar-density-sparse {
    &.EntityBrowserBar-showScore {
      .PageBar-center {
        flex: 1 0 calc(min(${props => props.theme.layout(640)}, 32%)) !important;

        .EntityBrowserBar {
          &-center {
            gap: ${props => props.theme.layout('4sp')};

            .InlineForm {
              margin-left: ${props => props.theme.layout('3sp')};
            }
            .EntityBrowserBar-scoreBar {
              margin-right: ${props => props.theme.layout('3sp')};
              width: calc(min(${props => props.theme.layout(200)}, 36%));
            }
          }
        }
      }
    }
  }

  ${ComponentStyles}
`;

StyledEntityBrowserBar.propTypes = {
  theme: PropTypes.object
};

StyledEntityBrowserBar.defaultProps = {
};

export default StyledEntityBrowserBar;
