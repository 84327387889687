import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Card from 'components/atoms/Cards/Card/Card';

const StyledQuestionnaireCard = styled(Card)`
  width: 100%;
  
  .QuestionnaireCard {
    &-question {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: ${props => props.theme.layout('1sp')};
      
      padding: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(2)} ${props => props.theme.spacing(2)} ${props => props.theme.spacing(2)};
      background-color: ${props => props.theme.property('palette.primary.states.hover')};
      border-radius: ${props => props.theme.radius(1)};

      .FormControl {
        .MuiInputBase-root {
          background-color: ${props => props.theme.property('palette.background.paper')};
        }
      }
      
      .FormField-name-comment {
        .InputLabel {
          transform: scale(0.875) !important;
          width: calc(${1 / 0.875 * 100}%);
          margin-bottom: ${props => props.theme.spacing(1)};
        }
      }
      
      &.error {
        border: 1px solid ${props => props.theme.property('palette.error.main')};
      }
    }
    
    &-stats {
      display: flex;
      flex-direction: column;
      gap: ${props => props.theme.layout('2sp')};

      padding: ${props => props.theme.spacing(1.5)} ${props => props.theme.spacing(2)} ${props => props.theme.spacing(2)} ${props => props.theme.spacing(2)};
      background-color: ${props => props.theme.property('palette.primary.states.hover')};
      border-radius: ${props => props.theme.radius(1)};
    }
    
    &-stat {
      display: flex;
      flex-direction: column;
      gap: ${props => props.theme.layout(2)};
      
      &-title {
        display: flex;
        justify-content: space-between;
        gap: ${props => props.theme.layout('2sp')};
        align-items: flex-end;
        
        .Typography:last-child {
          white-space: nowrap;
        }
      }
      
      .LinearProgress {
        height: ${props => props.theme.layout('1sp')};
        background-color: ${props => props.theme.property('palette.divider')};
      }
      
      &-result {
        display: flex;
        justify-content: space-between;
        gap: ${props => props.theme.layout('2sp')};
        padding-top: ${props => props.theme.spacing(1)};
        border-top: 1px solid ${props => props.theme.property('palette.divider')};
        
        > .Typography {
          &:first-child {
            line-height: ${props => props.theme.lineHeight('1f')};
            align-items: center;
            color: ${props => props.theme.property('palette.text.disabled')};
            
            margin-top: ${props => props.theme.layout(0)};
            svg {
              margin-top: ${props => props.theme.layout(-1)};
            }
    
            &.Typography {
              display: flex;
              gap: ${props => props.theme.layout('0.5sp')};
    
              > * {
                white-space: nowrap;
              }
            }
          }
          &:last-child {
            white-space: nowrap;
          }
        }
      }
    }
    
    &-budget {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      padding-bottom: ${props => props.theme.spacing(0.25)};
      flex-grow: 1;
      
      > .Typography {
        line-height: ${props => props.theme.lineHeight('1f')};
        align-items: center;
        color: ${props => props.theme.property('palette.text.disabled')};

        margin-top: ${props => props.theme.layout(0)};

        svg {
          margin-top: ${props => props.theme.layout(-1)};
        }

        &.Typography {
          display: flex;
          gap: ${props => props.theme.layout('0.5sp')};

          > * {
            white-space: nowrap;
          }
        }
      }
    }
    
    &-buttons {
      display: flex;
      gap: ${props => props.theme.layout('1sp')};
      > .Button {
        min-width: unset;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledQuestionnaireCard.propTypes = {
  theme: PropTypes.object
};

StyledQuestionnaireCard.defaultProps = {}

export default StyledQuestionnaireCard;
