import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useEffectItem} from 'helpers/hooks/utils';
import Close from '@mui/icons-material/Close';
import FolderSharp from '@mui/icons-material/FolderSharp';
import ListIcon from '@mui/icons-material/List';
import EntitiesTaskAddDialog from 'components/organisms/Dialogs/EntitiesTaskAddDialog/EntitiesTaskAddDialog';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import StyledEntitiesActionbar
  from 'components/organisms/Snackbars/EntitiesActionbar/EntitiesActionbar.styles';
import CollectionSelectionDialog
  from 'components/organisms/Dialogs/CollectionSelectionDialog/CollectionSelectionDialog';
import Download from '@mui/icons-material/Download';
import BackupTable from '@mui/icons-material/BackupTable';
import FolderZip from '@mui/icons-material/FolderZip';
import utils from 'helpers/utils';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import constants from 'helpers/constants';
import useMediaQuery from '@mui/material/useMediaQuery';

const EntitiesActionbar = React.forwardRef((props, ref) => {
  const {
    tableProvider,
    listSelection,
    data,
    showDownload,
    ...innerProps
  } = useComponentProps(props, 'EntitiesActionbar');

  const snackbar = useSnackbar();
  const dialogControl = useDialogControl();

  const listState = useEffectItem(tableProvider.appliedListState());

  const client = tableProvider.context?.data;
  const entities = listSelection.dataSelection;
  const clearSelectionEvent = useEffectEvent(listSelection.clearSelection);
  const entityAssignEvent = useEffectEvent(tableProvider.updaters?.assignItems);
  const entityDownloadEvent = useEffectEvent(tableProvider.updaters?.downloadItems);
  const entitySelectedEvent = useEffectEvent(tableProvider.updaters?.selectedItems);
  const entityCreateTaskEvent = useEffectEvent(tableProvider.updaters?.createTask);

  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const actions = useMemo(() => {
    const actions = [];

    if (!mdDown) {
      actions.push({
        label: 'Remove Selection',
        icon: Close,
        ButtonProps: {
          variant: 'outlined',
          color: 'white'
        },
        onClick: () => {
          clearSelectionEvent?.();
        }
      });
    }

    if (listSelection.count <= listSelection.max) {
      actions.push({
        label: 'Add to collection',
        auth: utils.createAuth({attribute: 'collection.update'}),
        icon: FolderSharp,
        onClick: (e) => {
          let selected;

          const handleClose = (e, reason) => {
            if (reason === 'saveButtonClick') {
              clearSelectionEvent?.();
            }
            dialogControl.hide();
          };

          const handleSubmit = (collections) => {
            if (collections.length > 0) {
              if (!utils.isDefined(selected)) {
                return Promise.reject();
              }

              let promise;
              const hasEps = selected.some((entity) => !entity.originalEntityId);
              if (collections.length > 0 && hasEps) {
                promise = entityAssignEvent?.({
                  collectionId: collections[0].collectionId,
                  entityIds: selected?.map((e) => +e.entityId)
                })
                  .then(() => Promise.all(collections.slice(1).map((collection) => {
                    return entityAssignEvent?.({
                      collectionId: collection.collectionId,
                      entityIds: selected?.map((e) => +e.entityId)
                    });
                  })));
              } else {
                promise = Promise.all(collections.map((collection) => {
                  return entityAssignEvent?.({
                    collectionId: collection.collectionId,
                    entityIds: selected?.map((e) => +e.entityId)
                  });
                }));
              }

              const message = `Adding the compan${selected.length === 1 ? 'y' : 'ies'} to the collection${collections.length === 1 ? '' : 's'}`;
              return utils.observePromise(promise, () => {
                return snackbar.show(`${message}...`, null, {
                  color: 'info',
                  autoHideDuration: null
                });
              }, () => {
                return snackbar.show(`The compan${selected.length === 1 ? 'y' : 'ies'} ${selected.length === 1 ? 'was' : 'were'} added to the collection${collections.length === 1 ? '' : 's'}`, null,
                  {color: 'success', autoHideDuration: constants.delay.success});
              }, () => {
                return snackbar.show(`${message} failed`, null,
                  {color: 'error', autoHideDuration: constants.delay.error});
              }, (id) => {
                snackbar.hide(id);
              });
            }
          };

          const showDialog = (collections) => {
            dialogControl.show(<CollectionSelectionDialog placement="top"
                                                          title="Add to collection"
                                                          subtitle={`For ${utils.formatNumber(listSelection.count)} compan${listSelection.count === 1 ? 'y' : 'ies'}`}
                                                          collections={collections}
                                                          hideCollections={true}
                                                          onSubmit={handleSubmit}/>, true, handleClose);
          }

          if (listSelection.count === entities.length) {
            const collections = entities.reduce((a, e) => {
              e.collections.forEach((ce) => {
                if (!a.find((c) => +c.collectionId === +ce.collectionId)) {
                  if (!entities.some((e1) => !e1.collections.find((c) => +c.collectionId === +ce.collectionId))) {
                    a.push(ce);
                  }
                }
              })
              return a;
            }, []);

            selected = entities;
            showDialog(collections);
          } else {
            showDialog();
            entitySelectedEvent?.(listState, 0, listSelection.count, null, true, (entities) => {
              selected = entities;
            }, () => {
              dialogControl.hide();
              snackbar.show('Retrieving selection data failed', null,
                {color: 'error', autoHideDuration: constants.delay.error});
            });
          }

          e.preventDefault();
        }
      })

      actions.push({
        label: 'Add task',
        auth: utils.createAuth({attribute: 'entity.task.create'}),
        icon: ListIcon,
        onClick: (e) => {
          let selected;

          const handleClose = (e, reason) => {
            if (reason === 'saveButtonClick') {
              clearSelectionEvent?.();
            }
            dialogControl.hide();
          };

          const handleSubmit = (task) => {
            if (!utils.isDefined(selected)) {
              return Promise.reject();
            }

            const message = `Adding the task for the compan${selected.length === 1 ? 'y' : 'ies'}`;
            return utils.observePromise(entityCreateTaskEvent?.(selected, task), () => {
              return snackbar.show(`${message}...`, null, {
                color: 'info',
                autoHideDuration: null
              });
            }, () => {
              return snackbar.show(`The task was added to the compan${selected.length === 1 ? 'y' : 'ies'}`, null,
                {color: 'success', autoHideDuration: constants.delay.success});
            }, () => {
              return snackbar.show(`${message} failed`, null,
                {color: 'error', autoHideDuration: constants.delay.error});
            }, (id) => {
              snackbar.hide(id);
            });
          }

          const showDialog = () => {
            dialogControl.show(<EntitiesTaskAddDialog placement="top"
                                                      count={listSelection.count}
                                                      onSubmit={handleSubmit}/>, true, handleClose);
          }

          showDialog();
          if (listSelection.count !== entities.length) {
            entitySelectedEvent?.(listState, 0, listSelection.count, null, true, (entities) => {
              selected = entities;
            }, () => {
              dialogControl.hide();
              snackbar.show('Retrieving selection data failed', null,
                {color: 'error', autoHideDuration: constants.delay.error});
            });
          } else {
            selected = entities;
          }

          e.preventDefault();
        }
      });
    }

    if (showDownload) {
      actions.push({
        label: 'Download',
        auth: utils.createAuth({attribute: 'entity.download'}),
        icon: Download,
        ActionButtonProps: {
          DropDownPopperProps: {
            ContextPopperProps: {
              density: 'densest',
              transparent: true,
              strategy: 'fixed',
              stretch: false,
              offset: [0, 8]
            }
          }
        },
        children: [
          {
            label: 'Download .xlsx',
            auth: utils.createAuth({attribute: 'entity.download.xlsx'}),
            icon: BackupTable,
            onClick: () => {
              const filename = `${client.name}.xlsx`;
              const downloadingSnackbar = snackbar.show('Downloading company data...', null, {
                color: 'info',
                autoHideDuration: null
              });
              clearSelectionEvent?.();

              entityDownloadEvent?.({
                filename: filename,
                entityIds: listSelection.count === entities.length ? entities.map((e) => e.entityId) : null,
                search: listState.search,
                filters: utils.filter2Object(listState.filter)
              })
                .then((res) => {
                  utils.bytesDownload(res.response.data, filename, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                })
                .catch((error) => {
                  if (error?.response?.status === constants.http.status.conflict) {
                    snackbar.show('The company export limit has been reached', null,
                      {color: 'warning', autoHideDuration: constants.delay.warning});
                  } else {
                    snackbar.show(`Downloading compan${listSelection.count === 1 ? 'y' : 'ies'} failed`, null,
                      {color: 'error', autoHideDuration: constants.delay.error});
                  }
                })
                .finally(() => {
                  snackbar.hide(downloadingSnackbar);
                });
            }
          },
          {
            label: 'Download .zip',
            auth: utils.createAuth({attribute: 'entity.download.zip'}),
            icon: FolderZip,
            onClick: (e) => {
              const filename = `${client.name}.zip`;
              entityDownloadEvent?.({
                zip: true,
                filename: filename,
                entityIds: listSelection.count === entities.length ? entities.map((e) => e.entityId) : null,
                search: listState.search,
                filters: utils.filter2Object(listState.filter)
              })
                .then(() => {
                  snackbar.show('Zip download is scheduled', null,
                    {color: 'success', autoHideDuration: constants.delay.success});
                  clearSelectionEvent?.();
                })
                .catch((error) => {
                  if (error?.response?.status === constants.http.status.conflict) {
                    snackbar.show('The company export limit has been reached', null,
                      {color: 'warning', autoHideDuration: constants.delay.warning});
                  } else {
                    snackbar.show(`Download scheduling failed`, null,
                      {color: 'error', autoHideDuration: constants.delay.error});
                  }
                });

              e.preventDefault();
            }
          }
        ]
      });
    }
    return actions;
  }, [showDownload, listState, listSelection.count, listSelection.max, client, entities, clearSelectionEvent, mdDown,
    snackbar, dialogControl, entityDownloadEvent, entityAssignEvent, entitySelectedEvent, entityCreateTaskEvent]);

  const renderTitle = () => {
    const count = utils.formatNumber(listSelection.count ?? 0);
    const title = `${count} compan${listSelection.count === 1 ? 'y' : 'ies'} selected`;
    if (actions?.length > 1) {
      return title;
    } else {
      const max = utils.formatNumber(listSelection.max ?? 0);
      return `${title} (max ${max})`
    }
  }

  return <StyledEntitiesActionbar ref={ref} {...innerProps}
                                  title={renderTitle()}
                                  actions={actions} />
});

EntitiesActionbar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  tableProvider: PropTypes.any,
  listSelection: PropTypes.object,
  data: PropTypes.array,
  showDownload: PropTypes.bool
};

EntitiesActionbar.defaultProps = {};

export default EntitiesActionbar;
