import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Dialog from 'components/organisms/Dialogs/Dialog/Dialog';
import FormStyles from 'styles/mixins/Form.styles';

const StyledPersonDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: clamp(0px, ${props => props.theme.layout(504)}, 86vw);
  }
  
  .DialogContent {
    padding: ${props => props.theme.spacing(3)};
    
    .Form {
      .Form-fields {
        .FileField {
          align-items: center;
          
          > .InputContainer {
            min-width: ${props => props.theme.layout('20sp')};
            max-width: ${props => props.theme.layout('20sp')};
          }

          &-dropzone-inner {
            > .Avatar:not(.Logo) {
              margin-top: -${props => props.theme.layout('3sp')};
            }
            .Typography {
              font-size: 50%;
              &.title {
                font-size: 60%;
              }
            }
          }
          
          &-delete {
            padding: ${props => props.theme.spacing(0.5)};
            right: -${props => props.theme.layout('1sp')};
          }

          .MuiFormHelperText-root:has(*) {
            position: absolute;
            display: flex;
            width: 100%;
            bottom: ${props => props.theme.layout(-22)};
            height: ${props => props.theme.layout(24)};

            > * {
              width: 1px;
              flex-grow: 1;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;

              p {
                display: contents;
              }
            }
          }
        }
      }
    }
  }

  &.PersonDialog-isEditing {
    .DialogContent {
      .Form {
        .Form-fields {
          .FileField {
            align-items: flex-start;
          }
        }
      }
    }
  }
  ${props => props.theme.breakpoints.down('xs')} {
    .DialogContent {
      .Form {
        .Form-fields {
          .FileField {
            &-dropzone {
              width: calc(100% - 2 * ${props => props.theme.layout('2.5sp')});
              margin: 0 ${props => props.theme.layout('2.5sp')};
            }
          }
        }
      }
    }
  }
  
  ${FormStyles}
  ${ComponentStyles}
`;

StyledPersonDialog.propTypes = {
  theme: PropTypes.object
};

StyledPersonDialog.defaultProps = {}

export default StyledPersonDialog;
