import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledAutocompleteFieldPaper from 'components/molecules/Fields/AutocompleteField/AutocompleteFieldPaper.styles';

const AutocompleteFieldPaper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'AutocompleteFieldPaper');

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledAutocompleteFieldPaper ref={ref} {...innerProps} />
});

AutocompleteFieldPaper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

AutocompleteFieldPaper.defaultProps = {
  elevation: 8,
  size: 'medium'
};

export default AutocompleteFieldPaper;
