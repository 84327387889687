import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';
import {rgba} from 'polished';

const StyledCollectionsThemes = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  
  .CollectionsThemes {
    &-content {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      overflow: hidden;
    }
    
    &-empty {
      text-align: center;
      color: ${props => rgba(props.theme.property('palette.text.primary'), props.theme.property('palette.action.disabledOpacity'))};
      font-style: italic;

      P {
        white-space: pre-line;
        margin: ${props => props.theme.spacing(2)} 0;
      }
    }
    
    &-toolbar {
      gap: ${props => props.theme.layout('1sp')};
      border-bottom: 1px solid ${props => props.theme.property('palette.divider')};
      &-title {
        flex-grow: 1;
      }
    }
    
    &-search {
      padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(3)};
      background-color: ${props => props.theme.property('palette.primary.states.hover')};
      border-bottom: 1px solid ${props => props.theme.property('palette.divider')};
      
      .TextField {
        background-color: ${props => props.theme.property('palette.background.default')};
      }
    }

    &-themes {
      flex-grow: 1;
      height: 1px;
      width: 100%;
    }
  }
  
  ${ComponentStyles}
`;

StyledCollectionsThemes.propTypes = {
  theme: PropTypes.object
};

StyledCollectionsThemes.defaultProps = {}

export default StyledCollectionsThemes;
