import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectItem} from 'helpers/hooks/utils';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import constants from 'helpers/constants';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import {useCollectionStatRecentChanges} from 'services/collection/stat/stat.utils';
import StyledCollectionEntitiesRecentChangesGraphCard
  from 'components/organisms/Cards/CollectionEntitiesRecentChangesGraphCard/CollectionEntitiesRecentChangesGraphCard.styles';
import RecentChangesBarChart from 'components/organisms/Charts/RecentChangesBarChart/RecentChangesBarChart';
import utils from 'helpers/utils';
import {useClientCustomFieldCache} from 'services/client/customField/customField.hooks';
import {useAuthClientId} from 'services/auth/auth.utils';
import Box from 'components/atoms/Layout/Box/Box';
import {P} from 'components/atoms/Text/Typography/Typography';
import {useLinkNavigate} from 'helpers/hooks/links';
import {useCollectionCustomFieldCache} from 'services/collection/customField/customField.hooks';

const CollectionEntitiesRecentChangesGraphCard = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionEntitiesRecentChangesGraphCard');

  const tableProvider = useTable();
  const collection = tableProvider.context?.data;
  const graphState = tableProvider.graphState;

  const clientId = useAuthClientId();

  const [recentChanges, setRecentChanges] = useState(null);

  const clientCustomFields = useClientCustomFieldCache({clientId}, {enabled: clientId >= 0});
  const clientCustomFieldsCache = (clientCustomFields.data?.data ?? clientCustomFields.data);
  const collectionCustomFields = useCollectionCustomFieldCache({clientId, collectionId: collection?.collectionId, addLinks: false},
    {enabled: clientId >= 0 && collection?.collectionId > 0});
  const collectionCustomFieldsCache = (collectionCustomFields.data?.data ?? collectionCustomFields.data);

  const customFields = (clientCustomFieldsCache ?? [])
    .concat(collectionCustomFieldsCache ?? []);

  const navigate = useLinkNavigate();

  const listState = useEffectItem(tableProvider.appliedListState());
  const year = graphState.graphSettings['recentChanges']?.filter?.year;
  const months = graphState.graphSettings['recentChanges']?.filter?.months ?? 12;

  const [recentChangesBreakdown, isLoading] = useCollectionStatRecentChanges(
    collection?.collectionId,
    year,
    months,
    listState.search,
    listState.filter
  );

  useEffect(() => {
    if (recentChangesBreakdown) {
      setRecentChanges(recentChangesBreakdown);
    }
  }, [recentChangesBreakdown]);

  const fields = useMemo(() => {
    const initialOption = constants.data.periodMonths.find((opt) => opt.value === months);

    return [{
      name: 'months',
      type: constants.formFieldTypes.autocomplete,
      validation: constants.formFieldValidationTypes.text,
      placeholder: 'Select a period',
      initial: initialOption,
      FormFieldProps: {
        autoFocus: false,
        hiddenLabel: true,
        clearable: false,
        size: 'small'
      },
      options: constants.data.periodMonths
    }];
  }, [months]);

  const visibleFields = useMemo(() => {
    const option = constants.data.recentChanges[0];

    let visibleFields = [];
    if (customFields && option) {
      option.fields.forEach((f) => {
        const cf = customFields.find((cf) => utils.camelcase(cf.name) === f);
        if (cf) {
          visibleFields.push({
            label: cf.label,
            value: f
          })
        } else if (f === 'fte') {
          visibleFields.push({
            label: 'FTE',
            value: f
          })
        } else {
          const fh = Object.keys(constants.entity.financeHistory).find((k) => k === f);
          if (fh) {
            visibleFields.push({
              label: fh.label,
              value: f
            })
          } else {
            visibleFields.push({
              label: utils.upperFirst(f),
              value: f
            })
          }
        }
      });
    }
    return visibleFields;
  }, [customFields]);

  const handleFilterChange = (field, value) => {
    graphState.setGraphSettings((current) => ({
      ...current,
      'recentChanges': {
        ...current?.['recentChanges'],
        filter: {
          ...current?.['recentChanges']?.filter,
          months: value?.value ?? value
        }
      }
    }));
  };

  const handleVisibilityChange = (visibility) => {
    graphState.setGraphSettings((current) => ({
      ...current,
      'recentChanges': {
        ...current?.['recentChanges'],
        visibility
      }
    }));
  };

  const handleClick = (e, release, data) => {
    const user = data.tooltipPayload?.[0]?.dataKey;
    const field = data.field;
    if (user && field && months) {
      navigate({
        event: e,
        to: `/collections/${collection.collectionId}/entities?custom=recentChanges:${months}_${user}_${field}`,
        keepSearchParams: true
      });
    }
  };

  return <StyledCollectionEntitiesRecentChangesGraphCard ref={ref} {...innerProps}
                                                         title="Recent changes"
                                                         context={(fields ? <InlineForm onChange={handleFilterChange}
                                                                                        fields={fields} /> : null)}>
    {(recentChanges?.recentChanges?.length > 0 || isLoading) ?
      <RecentChangesBarChart changes={recentChanges?.recentChanges}
                             fields={visibleFields}
                             showLegend={true}
                             isLoading={isLoading}
                             onClick={handleClick}
                             visibility={graphState.graphSettings['recentChanges']?.visibility}
                             onVisibilityChange={handleVisibilityChange} /> : null}

    {(!(recentChanges?.recentChanges?.length > 0) && !isLoading) ?
      <Box className="GraphCard-empty">
        <P>No recent changes found</P>
      </Box> : null}
  </StyledCollectionEntitiesRecentChangesGraphCard>
});

CollectionEntitiesRecentChangesGraphCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionEntitiesRecentChangesGraphCard.defaultProps = {};

export default CollectionEntitiesRecentChangesGraphCard;
