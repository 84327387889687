import utils from 'helpers/utils';
import ServiceStore from 'stores/service.store';
import logger from 'helpers/logger';
import constants from 'helpers/constants';
import {processComment} from 'services/comment/comment.utils';

const path = utils.servicePath(import.meta.url);

export class CommentService extends ServiceStore {
  constructor (app, callbacks) {
    logger.trace(`Starting module at: services/${path}`);

    super(path, app, {
      overrideListPath: 'comments?group=true',
      overrideEntityPath: 'comments/:commentId?group=true',
      watch: ['user', 'collection', 'entity'],
      match: (cache, entity, id) => {
        const comments = cache.data?.isGroup ? cache.data.comments : [cache.data];
        return comments.some((comment) => {
          return (entity === 'comment' && +comment.commentId === +id) ||
            (entity === 'user' && +comment.userId === +id) ||
            (entity === 'collection' && +comment.collectionId === +id) ||
            (entity === 'entity' && (
              +comment.relationId === +id ||
              +comment.entity?.stackId === +id ||
              +comment.entity?.redirectStackId === +id
            ));
        });
      },
      process: (data, meta) => {
        // process
        if (meta?.context?.$store?.dataType !== constants.dataTypes.other) {
          if (utils.isArray(data)) {
            return data.map(processComment);
          } else {
            return processComment(data);
          }
        } else {
          return data;
        }
      }
    }, callbacks);
  }
}
