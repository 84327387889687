import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import StyledCollectionsThemesWrapper
  from 'components/templates/Wrappers/Collections/CollectionsThemesWrapper/CollectionsThemesWrapper.styles';
import CollectionsThemes from 'components/organisms/TableColumns/CollectionsThemes/CollectionsThemes';

const CollectionsThemesWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionsThemesWrapper');

  const tableProvider = useTable();

  return <StyledCollectionsThemesWrapper ref={ref} {...innerProps}
                                         variant="foldout"
                                         context={<CollectionsThemes />}
                                         open={Boolean(tableProvider.state?.showIndex)}
                                         size="medium"
                                         ContextDrawerProps={{
                                           onToggle: (e, open) => {
                                             if (open) {
                                               tableProvider.openIndex();
                                             } else {
                                               tableProvider.closeIndex();
                                             }
                                           }
                                         }}>
    {innerProps.children}
  </StyledCollectionsThemesWrapper>
});

CollectionsThemesWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionsThemesWrapper.defaultProps = {
  children: 'CollectionsThemesWrapper text',
};

export default CollectionsThemesWrapper;
