import React, {useLayoutEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import EntityTaskProfileWrapper
  from 'components/templates/Wrappers/Tasks/EntityTaskProfileWrapper/EntityTaskProfileWrapper';
import DatabaseEntityProfileProvider
  from 'components/organisms/Providers/ProfileProvider/DatabaseEntityProfileProvider';
import DatabaseEntitySectionPanel
  from 'components/organisms/SectionPanels/DatabaseEntitySectionPanel/DatabaseEntitySectionPanel';
import DatabaseEntityProfileFoldout
  from 'components/organisms/Foldouts/DatabaseEntityProfileFoldout/DatabaseEntityProfileFoldout';
import StyledDatabaseEntityProfileWrapper
  from 'components/templates/Wrappers/Database/Entities/DatabaseEntityProfileWrapper/DatabaseEntityProfileWrapper.styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const DatabaseEntityProfileWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DatabaseEntityProfileWrapper');

  const foldoutRef = useRef(null);
  const tableProvider = useTable();

  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [size, setSize] = useState('larger');
  const [newProfile, setNewProfile] = useState(false);

  const handleProfileClose = () => {
    tableProvider.closeProfile();
  }

  const handleClose = (e, reason) => {
    foldoutRef.current?.close?.(e, reason);
  }

  const handleToggle = (e, open, reason) => {
    if (!open) {
      foldoutRef.current?.close?.(e, reason);
    }
  }

  const handleNext = (entity, isNew) => {
    tableProvider.openProfile(entity?.entityId, !isNew ? {activeSection: 'options'} : null);
  }

  const handleChange = (section) => {
    setSize(section.size ?? 'larger');
  }

  const handleCardClose = (card) => {
    if (card?.name === tableProvider.state?.showProfile?.settings?.activeCard) {
      tableProvider.closeProfile();
    }
  }

  const renderContext = () => {
    const entityId = tableProvider.state?.showProfile?.id;
    const settings = tableProvider.state?.showProfile?.settings;
    const state = (entityId && settings) ? {settings} : null;

    return <DatabaseEntityProfileProvider entityId={entityId ?? -1} state={state}>
      <EntityTaskProfileWrapper>
        <DatabaseEntityProfileFoldout ref={foldoutRef}
                                      onClose={handleProfileClose}
                                      newProfile={newProfile}>
          <DatabaseEntitySectionPanel showProfile={true}
                                      newProfile={newProfile}
                                      onClose={handleClose}
                                      onCloseCard={handleCardClose}
                                      onNext={handleNext}
                                      onChange={handleChange}/>
        </DatabaseEntityProfileFoldout>
      </EntityTaskProfileWrapper>
    </DatabaseEntityProfileProvider>
  }

  useLayoutEffect(() => {
    if (Boolean(tableProvider.state?.showProfile?.id)) {
      setNewProfile(tableProvider.state?.showProfile?.id === -1 ? Date.now() : false);
    }
  }, [tableProvider.state?.showProfile?.id]);

  const keepMounted = Boolean(tableProvider.state?.showProfile?.id) && tableProvider.state?.showProfile?.settings?.hideProfile;

  return <StyledDatabaseEntityProfileWrapper ref={ref} {...innerProps}
                                             size={smDown ? 'largest' : size}
                                             variant="foldout"
                                             context={renderContext()}
                                             open={Boolean(tableProvider.state?.showProfile?.id) && !tableProvider.state?.showProfile?.settings?.hideProfile}
                                             onToggle={handleToggle}
                                             ContextDrawerProps={keepMounted ? {
                                               ModalProps: {
                                                 keepMounted: true
                                               }
                                             } : null}>
    {innerProps.children}
  </StyledDatabaseEntityProfileWrapper>
});

DatabaseEntityProfileWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DatabaseEntityProfileWrapper.defaultProps = {
  children: 'DatabaseEntityProfileWrapper text',
};

export default DatabaseEntityProfileWrapper;
