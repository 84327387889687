import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledLogo = styled(Box)`
  font-size: ${props => props.theme.fontSize(16)};
  width: ${props => props.theme.layout(40)};
  min-width: ${props => props.theme.layout(40)};
  height: ${props => props.theme.layout(40)};
  min-height: ${props => props.theme.layout(40)};

  border-radius: 50%;
  
  .Avatar, .Badge {
    width: 100%;
    height: 100%;
  }

  &.Logo {
    .Badge {
      .MuiBadge-badge {
        top: ${props => props.theme.layout('0.6sp')};
        right: ${props => props.theme.layout('0.6sp')};
      }
    }
  }
  
  .fallback {
    font-size: ${props => props.theme.fontSize(18)} !important;
  }

  &.Logo-size-small {
    width: ${props => props.theme.layout(32)};
    min-width: ${props => props.theme.layout(32)};
    height: ${props => props.theme.layout(32)};
    min-height: ${props => props.theme.layout(32)};

    .fallback {
      font-size: ${props => props.theme.fontSize(16)} !important;
    }
  }
  
  &.Logo-size-large {
    width: ${props => props.theme.layout(48)};
    min-width: ${props => props.theme.layout(48)};
    height: ${props => props.theme.layout(48)};
    min-height: ${props => props.theme.layout(48)};
    
    .fallback {
      font-size: ${props => props.theme.fontSize(24)} !important;
    }
  }

  &.Logo-size-huge {
    width: ${props => props.theme.layout(80)};
    min-width: ${props => props.theme.layout(80)};
    height: ${props => props.theme.layout(80)};
    min-height: ${props => props.theme.layout(80)};

    .fallback {
      font-size: ${props => props.theme.fontSize(40)} !important;
    }
  }
  
  &.Logo-outlined {
    border: 1px solid ${props => props.theme.property('palette.divider')};
  }

  &.Logo-clickable {
    cursor: pointer;
  }

  &.Logo-density-sparse {
    padding: ${props => props.theme.spacing(3/8)};
  }
  &.Logo-density-dense {
    padding: ${props => props.theme.spacing(1/8)};
  }
  &.Logo-density-densest {
    padding: 0;
  }

  ${ComponentStyles}
`;

StyledLogo.propTypes = {
  theme: PropTypes.object
};

StyledLogo.defaultProps = {
}

export default StyledLogo;
