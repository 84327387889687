import {useServiceApiMutation, useServiceApiQuery} from 'stores/hooks/query';
import utils from 'helpers/utils';

const path = utils.servicePath(import.meta.url);

export function useCollectionLabelList ({collectionId, search, filter, sort, page, pageSize, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list',
    {collectionId, search, filter, sort, page, pageSize, ...rest},
    {overridePath: 'collections/labels', ...queryOptions});
}

export function useCollectionLabelCreate (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    overridePath: 'collections/labels',
    ...mutationOptions
  });
}

export function useCollectionLabelAdd (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    overridePath: 'collections/labels/:labelId/add',
    ...mutationOptions
  });
}

export function useCollectionLabelDelete (mutationOptions = {}) {
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    overridePath: 'collections/labels/:labelId/remove',
    ...mutationOptions
  });
}

