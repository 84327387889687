import React, {useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledResolveCollectionCategoriesWizardContent
  from 'components/organisms/WizardContent/ResolveCollectionCategoriesWizardContent/ResolveCollectionCategoriesWizardContent.styles';
import Box from 'components/atoms/Layout/Box/Box';
import {H6} from 'components/atoms/Text/Typography/Typography';
import TagGroupCard from 'components/organisms/Cards/TagGroupCard/TagGroupCard';
import constants from 'helpers/constants';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';
import {useAuthClient} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';

const ResolveCollectionCategoriesWizardContent = React.forwardRef((props, ref) => {
  const {
    collection,
    wizard,
    step,
    onComplete,
    onError,
    onDirty,
    ...innerProps
  } = useComponentProps(props, 'ResolveCollectionCategoriesWizardContent');

  const innerRef = useRef(null);

  const data = wizard.data?.[wizard.dataKey];
  const setDataEvent = useEffectEvent(wizard.setData);

  const client = useAuthClient();

  const dialogControl = useDialogControl();

  const onCompleteEvent = useEffectEvent(onComplete);
  const onDirtyEvent = useEffectEvent(onDirty);
  const resolveCollectionCategoriesWizardContent = useMemo(() => ({
    refs: {
      ref: innerRef,
    },
    submit: () => {
      const doSubmit = (autoTagsService) => {
        if (autoTagsService) {
          const service = constants.data.lookup('services', constants.services.types.autotags);

          setDataEvent?.((current) => ({
            ...current,
            [wizard.dataKey]: {
              ...current[wizard.dataKey],
              autotagPeriod: service?.period
            }
          }));
        }

        onCompleteEvent?.(Boolean(step?.resetNextSteps));
      }

      const createAutoTags = data?.outputTagGroups.some((tg) => tg.autoTagType !== constants.collection.autoTagTypes.noAutoAssign);

      const hasAutoTags = Boolean(client?.props?.autotagPeriod !== constants.services.periods.never) ||
        Boolean((collection?.autotagPeriod ?? constants.services.periods.never) !== constants.services.periods.never);

      if (!hasAutoTags && createAutoTags) {
        const handleConfirm = () => {
          doSubmit(true);
        }

        const handleClose = (e, reason) => {
          if (reason === 'cancelButtonClick') {
            doSubmit(false);
          } else if (reason !== 'confirmButtonClick') {
            onDirtyEvent?.(true, false);
          }
          dialogControl.hide();
        }

        dialogControl.show(<ConfirmDialog question="You have selected to auto-assign tags. Do you wish to activate the auto tags service?"
                                          onConfirm={handleConfirm}
                                          CancelButtonProps={{
                                            children: 'No'
                                          }}
                                          ConfirmButtonProps={{
                                            children: 'Yes'
                                          }}/>, true, handleClose);
      } else {
        doSubmit(false);
      }
    }
  }), [step?.resetNextSteps, data?.outputTagGroups, collection?.autotagPeriod, client?.props?.autotagPeriod,
    wizard.dataKey, dialogControl, setDataEvent, onCompleteEvent, onDirtyEvent]);

  useImperativeHandle(ref, () => resolveCollectionCategoriesWizardContent);

  const tagGroups = useMemo(() => {
    return data.outputTagGroups
      .filter((tg) => tg.tags?.length > 0)
      .map((tg) => ({
        ...tg,
        tags: tg.tags.map((t) => ({
          ...t,
          value: t.name,
          description: t.reason
        }))
      }));
  }, [data.outputTagGroups]);

  const renderSubtitle = () => {
    let subTitle = `All set! ${tagGroups?.length ?? 0} categor${tagGroups?.length === 1 ? 'y is' : 'ies are'} ready to be added to the collection.`;

    return <Box className="ResolveCollectionCategoriesWizardContent-subtitle">
      <H6>{subTitle}</H6>
    </Box>
  };

  const renderCategories = () => {
    if (tagGroups.length > 0) {
      return <Box className="ResolveCollectionCategoriesWizardContent-tagGroups">
        {tagGroups.map((tg, idx) => {
          return <TagGroupCard key={idx}
                               tagGroup={tg}
                               variant="compact"
                               hasAutoTags={false}
                               isLoading={false} />
        })}
      </Box>
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledResolveCollectionCategoriesWizardContent ref={innerRef} {...innerProps}>
    {renderSubtitle()}
    <Box className="ResolveCollectionCategoriesWizardContent-content">
      {renderCategories()}
    </Box>
  </StyledResolveCollectionCategoriesWizardContent>
});

ResolveCollectionCategoriesWizardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  wizard: PropTypes.object,
  step: PropTypes.object,
  collection: PropTypes.object,
  onComplete: PropTypes.func,
  onDirty: PropTypes.func,
  onError: PropTypes.func
};

ResolveCollectionCategoriesWizardContent.defaultProps = {};

export default ResolveCollectionCategoriesWizardContent;
