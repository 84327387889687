import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useEffectItem} from 'helpers/hooks/utils';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import constants from 'helpers/constants';
import StyledCollectionsThemes from 'components/organisms/TableColumns/CollectionsThemes/CollectionsThemes.styles';
import Toolbar from 'components/atoms/Toolbars/Toolbar/Toolbar';
import Box from 'components/atoms/Layout/Box/Box';
import {H6, P} from 'components/atoms/Text/Typography/Typography';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import Close from '@mui/icons-material/Close';
import utils from 'helpers/utils';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Search from '@mui/icons-material/Search';
import Clear from '@mui/icons-material/Clear';
import ThemesList from 'components/organisms/Lists/ThemesList/ThemesList';
import {useClientStatisticLabelsBreakdown} from 'services/client/statistic/statistic.utils';
import {useLinkNavigate} from 'helpers/hooks/links';
import dom from 'helpers/dom';

const CollectionsThemes = React.forwardRef((props, ref) => {
  const {
    ...innerProps
  } = useComponentProps(props, 'CollectionsThemes');

  const tableProvider = useTable();
  const listState = useEffectItem(tableProvider.appliedListState());

  const [themes, setThemes] = useState(null);
  const [internalState, setInternalState] = useState({
    searchValue: ''
  });

  const navigate = useLinkNavigate();
  const [labelsBreakdown, , isLoading] = useClientStatisticLabelsBreakdown(
    listState.search,
    listState.filter
  );

  useEffect(() => {
    if (labelsBreakdown) {
      setThemes(labelsBreakdown.filter((theme) => {
        return !(internalState.searchValue?.length > 0) ||
          (theme.label.toLowerCase().includes(internalState.searchValue.toLowerCase()));
      }));
    }
  }, [labelsBreakdown, internalState.searchValue]);

  const handleSearchKeyDown = useCallback((e) => {
    if (e.key === 'ArrowDown') {
      dom.focusElement(dom.getParent(e.target, null, 'CollectionsThemes').querySelector('.List'));
      e.preventDefault();
    }
  }, []);

  const searchFieldRef = useRef(null);
  const searchField = useMemo(() => ([{
    name: 'search',
    label: 'Search',
    inlineLabel: 'search',
    type: constants.formFieldTypes.text,
    validation: constants.formFieldValidationTypes.text,
    initial: internalState.searchValue,
    debounce: constants.debounce.search,
    FormFieldProps: {
      ref: searchFieldRef,
      hiddenLabel: true,
      size: 'smaller',
      onKeyDown: handleSearchKeyDown,
    },
    prefix: <Icon icon={Search} />,
    postfix: internalState.searchValue?.length > 0 ? <ActionIconButton action={{
      icon: <Icon icon={Clear}/>,
      onClick: (e) => {
        setInternalState(utils.updater({
          searchValue: ''
        }, true));
        searchFieldRef.current?.focus();
        e.preventDefault();
      }
    }} /> : null
  }]), [internalState.searchValue, handleSearchKeyDown]);

  const onCloseEvent = useEffectEvent(tableProvider.closeIndex);
  const closeAction = useMemo(() => ({
    label: 'Close',
    tooltip: 'Close',
    icon: Close,
    onClick: () => {
      onCloseEvent?.();
    }
  }), [onCloseEvent]);

  const handleClick = (e, theme) => {
    navigate({
      event: e,
      to: `/collections?custom=theme:${theme.labelId}`,
      keepSearchParams: true
    });
    tableProvider.closeIndex();
  }

  const handleSearch = (field, value) => {
    setInternalState(utils.updater({
      searchValue: value
    }, true));
  }

  return <StyledCollectionsThemes ref={ref} {...innerProps}>
    <Toolbar className="CollectionsThemes-toolbar">
      <Box className="CollectionsThemes-toolbar-title">
        <H6>Themes</H6>
      </Box>
      <ActionIconButton size="smaller"
                        density="sparse"
                        variant="outlined"
                        className="CollectionsThemes-toolbar-close"
                        IconProps={{
                          size: 'smaller'
                        }}
                        action={closeAction} />
    </Toolbar>
    <Box className="CollectionsThemes-content">
      <InlineForm className="CollectionsThemes-search"
                  onChange={handleSearch}
                  fields={searchField} />

      <Box className="CollectionsThemes-themes">
        {(themes?.length > 0 || isLoading) ?
          <ThemesList themes={themes}
                      isLoading={isLoading}
                      onClick={handleClick}
                      ListProps={{
                        catchFocus: false
                      }}/> : null}
        {(themes?.length === 0 && !isLoading) ?
          <Box className="CollectionsThemes-empty">
            <P>No theme(s) found</P>
          </Box> : null}
      </Box>
    </Box>
  </StyledCollectionsThemes>
});

CollectionsThemes.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  columnGroups: PropTypes.array,
  columnPresets: PropTypes.array
};

CollectionsThemes.defaultProps = {};

export default CollectionsThemes;
