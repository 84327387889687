import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import DataList from 'components/organisms/Lists/DataList/DataList';

const StyledPersonsList = styled(DataList)`
  &.PersonsList {
    .ListItem {
      &:focus {
        padding-left: 20px;
      }
    }
  }

  ${ComponentStyles}
`;

StyledPersonsList.propTypes = {
  theme: PropTypes.object
};

StyledPersonsList.defaultProps = {}

export default StyledPersonsList;
