import React, {useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import Typography from 'components/atoms/Text/Typography/Typography';
import StyledExampleCard from 'components/organisms/Cards/ExampleCard/ExampleCard.styles';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';

const ExampleCard = React.forwardRef((props, ref) => {
  const {
    title,
    text,
    copyText,
    isLoading,
    TooltipProps,
    ...innerProps
  } = useComponentProps(props, 'ExampleCard');

  const innerRef = useRef(null);
  const [copied, setCopied] = useState(false);

  useImperativeHandle(ref, () => innerRef.current);

  const renderTooltip = () => {
    if (copyText) {
      if (copied) {
        return 'Copied to clipboard';
      } else {
        return !isLoading ? 'Click to copy' : null;
      }
    }
  }

  const handleTooltipClose = (e) => {
    TooltipProps?.onClose?.(e);
    if (!e.defaultPrevented && copyText) {
      setCopied(false);
    }
  }

  const handleClick = (e) => {
    innerProps.onClick?.(e);
    if (!e.defaultPrevented) {
      if (copyText) {
        navigator.clipboard.writeText(text.toString().trim())
          .then(() => {
            setCopied(true);
          })
          .catch(() => {
            setCopied(false);
          });
      }
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <Tooltip title={renderTooltip()}
                  placement="bottom"
                  followCursor={true}
                  {...(copyText ? {
                    enterDelay: 0
                  } : null)}
                  onClose={handleTooltipClose}
                  {...TooltipProps}>
    <StyledExampleCard ref={innerRef} {...innerProps} onClick={copyText ? handleClick : innerProps.onClick}>
      {title ? <Box className="ExampleCard-title">{utils.isReactElement(title) ? title : <Typography variant="subtitle2">{title}</Typography>}</Box> : null}
      {text ? <Box className="ExampleCard-text">{utils.isReactElement(text) ? text : <Typography variant="body2">{text}</Typography>}</Box> : null}
    </StyledExampleCard>
  </Tooltip>
});

ExampleCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.any,
  text: PropTypes.any,
  copyText: PropTypes.bool,
  TooltipProps: PropTypes.object,
  isLoading: PropTypes.bool
};

ExampleCard.defaultProps = {
};

export default ExampleCard;
