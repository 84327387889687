import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import TextField from 'components/molecules/Fields/TextField/TextField';

const StyledEmailField = styled(TextField)`
  &:not(.EmailField-showLinkIcon) {
    .TextField-readOnly {
      .Link {
        line-break: anywhere;
      }
    }
  }
  
  &.EmailField-showLinkIcon {
    .TextField-readOnly {
      > *:not(.Icon, .InputAdornment) {
        flex-grow: 1;
        width: 1px;
        max-width: fit-content;
      }

      .InputAdornment {
        .Link > .Icon {
          color: ${props => props.theme.property('palette.action.active')};
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledEmailField.propTypes = {
  theme: PropTypes.object
};

StyledEmailField.defaultProps = {
}

export default StyledEmailField;
