import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import Box from 'components/atoms/Layout/Box/Box';
import StyledAnalysisScoreTableCell
  from 'components/molecules/TableCells/AnalysisScoreTableCell/AnalysisScoreTableCell.styles';
import {useCollectionEntityScoreBreakDown} from 'services/collection/entity/entity.utils';
import AnalysisScoreBarChart from 'components/organisms/Charts/AnalysisScoreBarChart/AnalysisScoreBarChart';
import {withMemo} from 'helpers/wrapper';
import constants from 'helpers/constants';
import utils from 'helpers/utils';

const AnalysisScoreTableCell = withMemo(React.forwardRef((props, ref) => {
  const {
    entity,
    collection,
    onClick,
    onHover,
    debounce,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'AnalysisScoreTableCell');

  const [analysisBreakdown, total] = useCollectionEntityScoreBreakDown(collection, entity);

  const onHoverEvent = useEffectEvent(onHover);
  const debouncedHover = useMemo(() => {
    return utils.debounce((e, data) => {
      onHoverEvent?.(e, data);
    }, constants.debounce.hover);
  }, [onHoverEvent]);

  const handleHover = (e, data) => {
    if (debounce) {
      debouncedHover(e, data);
    } else {
      onHover?.(e, data);
    }
  }

  return <StyledAnalysisScoreTableCell ref={ref} {...innerProps}>
    <Box className="AnalysisScoreTableCell-bar">
      <AnalysisScoreBarChart total={total}
                             breakdown={analysisBreakdown}
                             onClick={onClick}
                             onHover={handleHover}
                             animate={false}
                             isLoading={isLoading}/>
    </Box>
  </StyledAnalysisScoreTableCell>
}));

AnalysisScoreTableCell.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  entity: PropTypes.object,
  onClick: PropTypes.func,
  onHover: PropTypes.func,
  debounce: PropTypes.bool,
  isLoading: PropTypes.bool
};

AnalysisScoreTableCell.defaultProps = {
  debounce: true
};

export default AnalysisScoreTableCell;


