import React, {Children, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useBbox, useComponentProps} from 'helpers/hooks/utils';
import StyledChipList from 'components/atoms/Chips/ChipList/ChipList.styles';
import utils from 'helpers/utils';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';
import Chip from 'components/atoms/Chips/Chip/Chip';
import dom from 'helpers/dom';
import ActionChip from 'components/molecules/Chips/ActionChip/ActionChip';

const ChipList = React.forwardRef((props, ref) => {
  const {
    variant,
    children,
    nowrap,
    maxRows,
    drawFromStart,
    ...innerProps
  } = useComponentProps(props, 'ChipList', {
    static: ['nowrap']
  });

  const innerRef = useRef(null);
  const [display, setDisplay] = useState({});

  useImperativeHandle(ref, () => innerRef.current);

  const bBox = useBbox(() => innerRef.current, ['left', 'right', 'bottom', 'height', 'width']);
  const compact = variant === 'compact';

  useEffect(() => {
    const count = Children.toArray(children).length;
    setDisplay((current) => {
      const text = Children.toArray(children).map(utils.reactElementToText);
      if (!utils.compare(current.text, text)) {
        return {text, count, visible: count, calculated: drawFromStart, confirmed: false};
      } else {
        return current;
      }
    })
  }, [children, drawFromStart]);

  const chipSize = Children.toArray(children)?.[0]?.props?.size ??
    Children.toArray(children)?.[0]?.props?.action?.ChipProps?.size;
  useEffect(() => {
    if (compact) {
      if (innerRef.current) {
        // hide overflow
        if (utils.isDefined(display.count) && innerRef.current && bBox) {
          const size = chipSize;
          const elements = Array.from(innerRef.current.children);

          let overflowChip = innerRef.current.querySelector('.ChipList-overflow-wrapper');
          if (overflowChip) {
            overflowChip.style.display = 'none';
          }

          elements.forEach((el) => {
            const computedStyle = dom.getComputedStyle(el);
            if (computedStyle.visibility === 'hidden') {
              el.style.display = 'unset';
            }
            el.style.maxWidth = 'unset';
          });

          const liveBox = dom.getBbox(innerRef.current);
          const computedStyle = dom.getComputedStyle(innerRef.current);
          const rowGap = utils.toInt(computedStyle?.rowGap ?? 0);
          const columnGap = utils.toInt(computedStyle?.columnGap ?? 0);

          let visible = 0, overflow = 0, rowTop = 0, row = 0, fitted = false,
            maxWidth = null, lastRight = +liveBox.left.toFixed(1);
          elements.forEach((el, idx) => {
            const chipEl = el.querySelector('.Chip');

            const box = dom.getBbox(chipEl);
            const computedStyle = dom.getComputedStyle(chipEl);
            const marginRight = utils.toInt(computedStyle?.marginRight ?? 0) + columnGap;
            const marginBottom = utils.toInt(computedStyle?.marginBottom ?? 0) + rowGap;

            if (idx === 0) {
              rowTop = +box.top.toFixed(1);
            }
            if (+box.top.toFixed(1) > rowTop) {
              row += 1;
              rowTop = +box.top.toFixed(1);
            }

            if (!fitted) {
              let spare = 0;
              if ((elements.length - (idx + 1)) > 0) {
                spare = size === 'large' ? 48 : (size === 'medium' ? 44 : 36);
              } else if ((elements.length - (idx + 1)) >= 10) {
                spare = size === 'large' ? 54 : (size === 'medium' ? 50 : 42);
              } else if ((elements.length - (idx + 1)) >= 100) {
                spare = size === 'large' ? 60 : (size === 'medium' ? 56 : 48);
              }

              const fitHeight = utils.isDefined(maxRows) ? row < maxRows :
                +box.bottom.toFixed(1) <= +liveBox.bottom.toFixed(1);
              const lastRow = utils.isDefined(maxRows) ? (row === maxRows - 1) :
                (fitHeight && !((+box.bottom.toFixed(1) + +box.height.toFixed(1) + marginBottom) <= +liveBox.bottom.toFixed(1)));
              const fitWidth = +box.right.toFixed(1) <= (+liveBox.right.toFixed(1) - (lastRow ? (spare + marginRight) : 0));

              if (el.className === 'ChipList-overflow-wrapper') {
                overflow = 1;
              } else {
                if (fitHeight && (fitWidth || !lastRow)) {
                  visible = idx + 1 - overflow;

                  if (lastRow) {
                    lastRight = +box.right.toFixed(1) + marginRight;
                  }
                } else {
                  maxWidth = Math.floor((+liveBox.right.toFixed(1) - spare) - lastRight - marginRight);

                  if (maxWidth >= 60) {
                    visible = idx + 1 - overflow;
                  } else {
                    maxWidth = 0;
                  }

                  fitted = lastRow || !fitHeight;
                }
              }
            }
          });

          elements.forEach((el) => {
            const computedStyle = dom.getComputedStyle(el);
            if (computedStyle.visibility === 'hidden') {
              el.style.display = 'none';
            }
            el.style.maxWidth = null;
          });

          setDisplay((current) => {
            const pingPong = current.lastWidth === liveBox.width.toFixed(1) &&
              current.lastHeight === liveBox.height.toFixed(1);

            if (!pingPong || (current.pingPong ?? 0) < 16) {
              if (current.visible !== visible || current.maxWidth !== maxWidth || !current.calculated) {
                return {
                  ...current,
                  visible,
                  maxWidth,
                  boxWidth: liveBox.width.toFixed(1),
                  lastWidth: current.boxWidth,
                  boxHeight: liveBox.height.toFixed(1),
                  lastHeight: current.boxHeight,
                  pingPong: pingPong ? (current.pingPong ?? 0) + 1 : 0,
                  calculated: true
                };
              }
            }

            return current;
          });

          if (overflowChip) {
            overflowChip.style.display = null;
          }
        }
      }
    }
  }, [compact, bBox, chipSize, display, maxRows]);

  const density = innerProps.density ?? (chipSize === 'medium' ? 'sparse' : 'dense');
  innerProps.className = utils.flattenClassName(innerProps.className, {
    density: density
  });

  const renderedChips = useMemo(() => {
    const renderChips = (offset, count, show = false, tooltip = false) => {
      let chips = utils.isDefined(count) ? Children.toArray(children).slice(offset, count) :
        Children.toArray(children).slice(offset);

      const renderTooltipChip = (chip) => {
        if (utils.isDefined(chip.props.action)) {
          return utils.cloneElement(chip, {
            action: {
              ...chip.props.action,
              color: 'contrast',
              tooltip: null,
              ChipProps: {
                ...chip.props.action?.ChipProps,
                color: 'contrast'
              }
            },
            variant: 'outlined',
            showTooltip: false
          });
        } else {
          return utils.cloneElement(chip, {color: 'contrast', showTooltip: false, variant: 'outlined'});
        }
      }

      return chips.map((chip, idx) => {
        if (!tooltip) {
          return <li className="ChipList-item" style={{
            visibility: (!show ? 'hidden' : null),
            display: (!show ? 'none' : null)
          }} key={offset + idx}>{chip}</li>
        } else {
          return <React.Fragment key={offset + idx}>
            {renderTooltipChip(chip)}
          </React.Fragment>;
        }
      });
    };

    const renderOverflow = () => {
      const child = Children.toArray(children)?.[0];
      if (child) {
        const renderChip = () => {
          if (utils.isDefined(child.props.action)) {
            return <ActionChip className="ChipList-overflow"
                               action={{
                                 ...child.props.action,
                                 label: `+${(display.count - display.visible)}`,
                                 tooltip: null,
                                 navigation: null,
                                 auth: null,
                                 onClick: null,
                                 icon: null
                               }}
                               variant={child.props.variant}
                               color={child.props.color}
                               size={child.props.size}
                               showTooltip={false}
                               tabIndex={-1} />
          } else {
            return <Chip className="ChipList-overflow"
                         disabled={Boolean(child.props.disabled)}
                         variant={child.props.variant}
                         color={child.props.color}
                         size={child.props.size}
                         showTooltip={false}
                         tabIndex={-1}
                         onClick={null}
                         label={`+${(display.count - display.visible)}`} />
          }
        }

        return <Tooltip slotProps={{tooltip: {style: {maxWidth: 'fit-content'}}}}
                        title={!child.props.disabled ? <ChipList variant="tooltip">
                          {renderChips(display.visible, null, true, true)}
                        </ChipList> : null} enterDelay={0}>
          <li className="ChipList-overflow-wrapper">
            {renderChip()}
          </li>
        </Tooltip>
      }
    }

    return <React.Fragment>
      {renderChips(0, display.visible, !compact || display.calculated)}
      {display.visible < display.count ? renderOverflow() : null}
      {display.visible < display.count ? renderChips(display.visible, null, false) : null}
    </React.Fragment>
  }, [children, compact, display.visible, display.count, display.calculated])

  return <StyledChipList ref={innerRef} {...innerProps} $maxWidth={display.maxWidth}>
    {renderedChips}
  </StyledChipList>
});

ChipList.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  nowrap: PropTypes.bool,
  maxRows: PropTypes.number,
  drawFromStart: PropTypes.bool,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['standard', 'compact', 'tooltip']), PropTypes.string]),
};

ChipList.defaultProps = {
  children: 'ChipList chips',
  variant: 'standard',
  drawFromStart: false
};

export default ChipList;
