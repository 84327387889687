import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import CardContent from 'components/atoms/Cards/CardContent/CardContent';
import {H6} from 'components/atoms/Text/Typography/Typography';
import Box from 'components/atoms/Layout/Box/Box';
import ActionCheckbox from 'components/molecules/Inputs/ActionCheckbox/ActionCheckbox';
import ActionChip from 'components/molecules/Chips/ActionChip/ActionChip';
import utils from 'helpers/utils';
import Icon from 'components/atoms/Icons/Icon/Icon';
import StyledThemeCard from 'components/molecules/Cards/ThemeCard/ThemeCard.styles';
import Star from '@mui/icons-material/Star';
import {Bookmark} from '@mui/icons-material';

const ThemeCard = React.forwardRef((props, ref) => {
  const {
    themeData,
    onClick,
    onChipClick,
    onFavoriteClick,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'ThemeCard');

  const onFavoriteClickEvent = useEffectEvent(onFavoriteClick);
  const favoriteAction = useMemo(() => {
    const favorite = themeData?.favorites?.length === +themeData?.collectionsCount;
    return {
      label: 'Favorite',
        tooltip: 'Favorite',
      auth: !onFavoriteClickEvent ? utils.createAuth({attribute: 'system.null'}) : null,
      icon: <Icon icon={Star} color="divider" />,
      checkedIcon: <Icon icon={Star} color="amber.500" />,
      active: favorite,
      onClick: onFavoriteClickEvent
    }
  }, [themeData, onFavoriteClickEvent]);

  const onChipClickEvent = useEffectEvent(onChipClick);
  const chipAction = useMemo(() => ({
    label: `${utils.formatNumber(themeData?.collectionsCount ?? 0)} collection${(themeData?.collectionsCount ?? 0) === 1 ? '' : 's'}`,
    onClick: onChipClickEvent
  }), [themeData, onChipClickEvent]);

  const handleClick = (e) => {
    onClick?.(e, themeData);
  }

  return <StyledThemeCard {...innerProps} ref={ref}
                          onClick={!isLoading ? handleClick : null}>
    <CardContent className="ThemeCard-content">
      <Icon icon={Bookmark} color="primary"/>
      <Box className="ThemeCard-content-text">
        <H6 className="ThemeCard-content-text-label"
            min={4} max={16}
            isLoading={isLoading}
            showTooltip={true}>{themeData?.label}</H6>
        <ActionChip variant="transparent"
                    color="primary"
                    size="small"
                    showInactive
                    isLoading={isLoading}
                    action={chipAction} />
      </Box>
    </CardContent>
    {favoriteAction ?
      <ActionCheckbox className="ThemeCard-favorite"
                      isLoading={isLoading}
                      action={favoriteAction}
                      showInactive={false} /> : null }
  </StyledThemeCard>
});

ThemeCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  themeData: PropTypes.object,
  onClick: PropTypes.func,
  onChipClick: PropTypes.func,
  onFavoriteClick: PropTypes.func,
  isLoading: PropTypes.bool
};

ThemeCard.defaultProps = {
};

export default ThemeCard;
