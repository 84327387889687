import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import DropdownTableCellEdit from 'components/organisms/TableCellEdits/DropdownTableCellEdit/DropdownTableCellEdit';

const StyledRelevancyTableCellEdit = styled(DropdownTableCellEdit)`
  .Button-size-medium {
    min-width: ${props => props.theme.layout(112)};
    max-width: ${props => props.theme.layout(112)};
  }
  .Button-size-small {
    min-width: ${props => props.theme.layout(67)};
  }
  ${ComponentStyles}
`;

StyledRelevancyTableCellEdit.propTypes = {
  theme: PropTypes.object
};

StyledRelevancyTableCellEdit.defaultProps = {}

export default StyledRelevancyTableCellEdit;
