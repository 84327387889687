import React, {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import StyledCollectionSourcesSectionPanel
  from 'components/organisms/SectionPanels/CollectionSourcesSectionPanel/CollectionSourcesSectionPanel.styles';
import SourceUploadSectionPanelContent
  from 'components/organisms/SectionPanels/SourceUploadSectionPanelContent/SourceUploadSectionPanelContent';
import SourceCreateSectionPanelContent
  from 'components/organisms/SectionPanels/SourceCreateSectionPanelContent/SourceCreateSectionPanelContent';
import SourceDatabaseSectionPanelContent
  from 'components/organisms/SectionPanels/SourceDatabaseSectionPanelContent/SourceDatabaseSectionPanelContent';
import {useSourceList} from 'services/source/source.hooks';
import constants from 'helpers/constants';
import Badge from 'components/atoms/Badges/Badge/Badge';
import utils from 'helpers/utils';
import SourceWizardSectionPanelContent
  from 'components/organisms/SectionPanels/SourceWizardSectionPanelContent/SourceWizardSectionPanelContent';

const CollectionSourcesSectionPanel = React.forwardRef((props, ref) => {
  const {
    collection,
    sectionDefinitions,
    isNewCollection,
    onError,
    onDialog,
    onSubmit,
    ...innerProps
  } = useComponentProps(props, 'CollectionSourcesSectionPanel');

  const authorize = useAuthorize();
  const [internalState, setInternalState] = useState({});

  const sourceIds = (collection?.projectSources ?? [])
    .filter((s) => ![constants.sources.types.suggestions, constants.sources.types.database].includes(s.type))
    .map((s) => s.sourceId);
  const databaseSources = useSourceList({
    page: 0,
    pageSize: 25,
    search: internalState.search,
    filter: [
      {id: 'includeProcessing', value: true}
    ].concat(sourceIds.length > 0 ? [
      {id: 'sourceId', value: sourceIds.map((sourceId) => `-${sourceId}`)}
    ] : []).concat(!(internalState.search?.length > 0) ? [{
      id: 'suggestFor', value: {
        collectionId: collection.collectionId,
        keywords: collection.projectTopics?.length > 0 ? collection.projectTopics : ''
      }
    }] : [])
  }, {
    ...constants.queryOptions.infinite
  });

  const handleSearchExisting = useCallback((search) => {
    setInternalState(utils.updater({search}, true));
  }, []);

  const onErrorEvent = useEffectEvent(onError);
  const onDialogEvent = useEffectEvent(onDialog);
  const onSubmitEvent = useEffectEvent(onSubmit);
  const sections = useMemo(() => {
    if (sectionDefinitions) {
      const sections = [];

      sectionDefinitions
        .forEach((sectionDef) => {
          const handleCanUpdate = (collection) => {
            return authorize(sectionDef.auth?.update ? {...sectionDef.auth?.update, meta: {...sectionDef.auth?.update?.meta, collection}} : {});
          }

          if (sectionDef.name === 'fileUpload') {
            sections.push({
              ...sectionDef,
              Content: ({onDirty}) => <SourceUploadSectionPanelContent collection={collection}
                                                                       isNewCollection={isNewCollection}
                                                                       onDialog={onDialogEvent}
                                                                       onSubmit={(upload) => onSubmitEvent?.('upload', upload)}
                                                                       onCanUpdate={handleCanUpdate}
                                                                       onDirty={onDirty} />
            });
          }

          if (sectionDef.name === 'createSource') {
            const addSuggestions = !collection?.projectSources?.find((s) => s.type === constants.sources.types.suggestions);
            sections.push({
              ...sectionDef,
              Content: () => <SourceCreateSectionPanelContent collection={collection}
                                                              onCanUpdate={handleCanUpdate}
                                                              onSubmit={(source) => onSubmitEvent?.('source', source)}
                                                              onDialog={onDialogEvent}
                                                              addSuggestions={addSuggestions}/>
            });
          }

          if (sectionDef.name === 'databaseSource') {
            sections.push({
              ...sectionDef,
              Content: () => <SourceDatabaseSectionPanelContent databaseSources={databaseSources}
                                                                search={internalState.search}
                                                                onSearch={handleSearchExisting}
                                                                onCanUpdate={handleCanUpdate}
                                                                onSubmit={(source) => onSubmitEvent?.('source', source)}/>,
              TabProps: {
                badge: <Badge isLoading={databaseSources?.status?.isLoading}
                              badgeContent={databaseSources.meta?.resultsCount || '0'}
                              color={databaseSources.meta?.resultsCount > 0 ? 'primary' : 'light'}/>
              }
            });
          }

          if (sectionDef.name === 'wizardSource') {
            sections.push({
              ...sectionDef,
              Content: () => <SourceWizardSectionPanelContent collection={collection}
                                                              onCanUpdate={handleCanUpdate}
                                                              onSubmit={(source) => onSubmitEvent?.('source', source)}
                                                              onError={onErrorEvent} />
            });
          }
        });

      return sections;
    } else {
      return [];
    }
  }, [
    sectionDefinitions, databaseSources, isNewCollection, internalState.search,
    collection, authorize, onSubmitEvent, onErrorEvent, onDialogEvent, handleSearchExisting
  ]);

  return <StyledCollectionSourcesSectionPanel ref={ref} {...innerProps}
                                              data={null}
                                              dataKey='sourceId'
                                              sections={sections} />
});

CollectionSourcesSectionPanel.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  collection: PropTypes.object,
  sectionDefinitions: PropTypes.array,
  isNewCollection: PropTypes.bool,
  onError: PropTypes.func,
  onDialog: PropTypes.func,
  onSubmit: PropTypes.func
};

CollectionSourcesSectionPanel.defaultProps = {};

export default CollectionSourcesSectionPanel;
