import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import DropdownTableCellEdit from 'components/organisms/TableCellEdits/DropdownTableCellEdit/DropdownTableCellEdit';

const StyledQuestionnaireTableCellEdit = styled(DropdownTableCellEdit)`
  &.QuestionnaireTableCellEdit {
    width: ${props => props.theme.layout(288)};
    
    display: flex;
    flex-direction: column;
    row-gap: ${props => props.theme.layout('1.25sp')};
    
    .QuestionnaireTableCellEdit {
      &-header {
        padding: 0;    
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledQuestionnaireTableCellEdit.propTypes = {
  theme: PropTypes.object
};

StyledQuestionnaireTableCellEdit.defaultProps = {}

export default StyledQuestionnaireTableCellEdit;
