import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Wizard from 'components/organisms/Wizards/Wizard/Wizard';

const StyledEntitiesAnalyseGraphWizard = styled(Wizard)`
  .Wizard {
    &-content {
      padding: 0 !important;
    }
    &-step {
      &-content {
        padding: ${props => props.theme.spacing(3)};
        
        &-description {
          > * {
            height: 100%;

            .Form-fields {
              height: 100%;
              min-height: fit-content;
              flex-wrap: nowrap;
              flex-direction: column;
              
              .FormField {
                &.FormField-name-examples {
                  flex-grow: 1;
                  overflow: hidden;
                  height: 1px;
                  min-height: ${props => props.theme.layout(160)};
                  
                  .FormLabel {
                    font-size: ${props => props.theme.fontSize(16)};
                    padding-bottom: ${props => props.theme.spacing(0.75)}; 
                  }
                  .InputContainer {
                    overflow: auto;
                  }
                }
              }
            }
          }
        }
      }
    }
    .ToolbarWrapper-toolbar {
      padding-right: 0;
    }
  }
  
  &.EntitiesAnalyseGraphWizard {
    .ToolbarWrapper-toolbar {
      padding-right: 0 !important;
    }
  }
  
  ${ComponentStyles}
`;

StyledEntitiesAnalyseGraphWizard.propTypes = {
  theme: PropTypes.object
};

StyledEntitiesAnalyseGraphWizard.defaultProps = {}

export default StyledEntitiesAnalyseGraphWizard;
