import utils from 'helpers/utils';
import {useServiceStatus} from 'stores/hooks/service';
import {useServiceApiMutation} from 'stores/hooks/query';

const path = utils.servicePath(import.meta.url);

export function usePortalUserStatus () {
  return useServiceStatus(path);
}

export function usePortalUserUpdate (mutationOptions = {}) {
  return useServiceApiMutation(path, 'patch', {}, {
    ...mutationOptions
  });
}
