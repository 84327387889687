import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import UserTableCell from 'components/molecules/TableCells/UserTableCell/UserTableCell';
import StyledUserTableCellEdit
  from 'components/organisms/TableCellEdits/UserTableCellEdit/UserTableCellEdit.styles';
import UserList from 'components/molecules/Lists/UserList/UserList';
import {withMemo} from 'helpers/wrapper';
import constants from 'helpers/constants';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';

const UserTableCellEdit = withMemo(React.forwardRef((props, ref) => {
  const {
    cell,
    table,
    user,
    proxies,
    onChange,
    ...innerProps
  } = useComponentProps(props, 'UserTableCellEdit');

  const snackbar = useSnackbar();

  const handleClick = (e, user) => {
    cell.column.columnDef.optimistic.set(cell, user);
    table.setEditingCell(null);
    utils.asPromise(onChange)(cell.row.original, user)
      .catch(() => {
        cell.column.columnDef.optimistic.reset(cell);
        table.setEditingCell(cell);
        snackbar.show('Saving failed', null,
          {color: 'error', autoHideDuration: constants.delay.error});
      });

    e.preventDefault();
  }

  return <StyledUserTableCellEdit ref={ref} {...innerProps}
                                  cell={cell}
                                  table={table}
                                  TableCellPopperProps={{
                                    size: 'medium',
                                    density: 'densest'
                                  }}
                                  Anchor={<UserTableCell user={user}
                                                         active={true} />}>
    <UserList user={user}
              proxies={proxies}
              track={true}
              onClick={handleClick}/>
  </StyledUserTableCellEdit>
}));

UserTableCellEdit.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  table: PropTypes.object,
  cell: PropTypes.object,
  user: PropTypes.object,
  proxies: PropTypes.bool,
  onChange: PropTypes.func
};

UserTableCellEdit.defaultProps = {
  proxies: true
};

export default UserTableCellEdit;
