import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import HeaderBar from 'components/organisms/Bars/HeaderBar/HeaderBar';

const StyledTableBar = styled(HeaderBar)`
  .TableBar {
    &-search {
      &-adornment {
        display: flex;
        align-items: center;
        gap: ${props => props.theme.layout('1sp')};

        &:has(.TableBar-search-adornment-wizard) {
          margin-right: -${props => props.theme.layout(3)};
        }
        
        &-wizard {
          margin-top: -${props => props.theme.layout(0.5)};
          
          padding: ${props => props.theme.layout(6)} !important;
          border-radius: ${props => props.theme.radius(1.5)} !important;
          
          &.IconButton-contained {
            margin-top: ${props => props.theme.layout(0.5)} !important;
            padding: ${props => props.theme.layout(7)} !important;
            
            .Icon {
              color: ${props => props.theme.property('palette.primary.contrastText')} !important;
            }
          }
        }
      }
    }
  }
  
  .PageBar {
    &-title {
      flex-grow: 1;
      white-space: nowrap;
    }
    
    &-rightFields {
      width: unset;
      
      .MuiFormControl-root {
        .MuiInputBase-root {
          &:not(${props => props.theme.state2Selector('MuiInputBase-root', null, ['active', 'hover', 'focus', 'focusVisible', 'selected'], '')}) {
            .MuiOutlinedInput-notchedOutline {
              border-color: ${props => props.theme.property('palette.divider')};
            }
          }
        }
      }

      .FormControl {
        &.FormField-name-search {
          min-width: ${props => props.theme.layout(315)};
          
          &:has(.TableBar-search-adornment-wizard.ActionIconButton-contained) {
            .MuiInputBase-root {
              cursor: pointer !important;

              .MuiInputBase-input {
                pointer-events: none;
                user-select: none;
                caret-color: transparent;
              }
            }
          }
        }
      }
    }
  }

  ${props => props.theme.breakpoints.down('md')} {
    .PageBar {
      &-rightFields {
        .FormControl {
          &.FormField-name-search {
            min-width: ${props => props.theme.layout(200)};
            max-width: ${props => props.theme.layout(200)};
          }
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledTableBar.propTypes = {
  theme: PropTypes.object
};

StyledTableBar.defaultProps = {
};

export default StyledTableBar;
