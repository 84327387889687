import React, {useCallback, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import StyledTable from 'components/organisms/Tables/Table/Table.styles';
import {useComponentProps, useEffectEvent, useEffectItem, useOverflowShadow, useUpdatedRef} from 'helpers/hooks/utils';
import MaterialReactTable from 'material-react-table';
import utils from 'helpers/utils';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';
import IconButton from 'components/atoms/Buttons/IconButton/IconButton';
import Icon from 'components/atoms/Icons/Icon/Icon';
import dom from 'helpers/dom';
import constants from 'helpers/constants';
import {useWrapper} from 'components/templates/Wrappers/Basic/Wrapper/Wrapper';
import Refresh from '@mui/icons-material/Refresh';
import {useActionbar} from 'components/organisms/Providers/ActionbarProvider/ActionbarProvider';
import Box from 'components/atoms/Layout/Box/Box';
import TableHeader from 'components/organisms/Tables/Table/TableHeader';
import TableText from 'components/organisms/Tables/Table/TableText';
import TableCell from 'components/organisms/Tables/Table/TableCell';
import TableHead from 'components/organisms/Tables/Table/TableHead';
import TableEdit from 'components/organisms/Tables/Table/TableEdit';
import {useStyles} from 'components/organisms/Providers/ThemeProvider/ThemeProvider';

const getIsFirstLeftPinnedColumn = (column) => {
  return (
    column.getIsPinned() === 'left' &&
    column.getPinnedIndex() === 0
  );
};

const getIsLastLeftPinnedColumn = (table, column) => {
  return (
    column.getIsPinned() === 'left' &&
    table.getLeftLeafHeaders().length - 1 === column.getPinnedIndex()
  );
};

const getIsFirstRightPinnedColumn = (column) => {
  return column.getIsPinned() === 'right' && column.getPinnedIndex() === 0;
};

const getIsLastRightPinnedColumn = (table, column) => {
  return column.getIsPinned() === 'right' && column.getPinnedIndex() === table.getRightLeafHeaders().length - 1;
};

const getCellIsEditable = (column, row) => {
  return utils.isFunction(column?.columnDef?.enableEditing) ? column?.columnDef?.enableEditing(row) :
    column?.columnDef?.enableEditing !== false;
}

const getColumnClassNames = (table, column, row, header) => {
  const prefix = header ? 'TableHeader' : 'TableCell';
  const { draggingColumn, hoveredColumn } = table.getState();

  const isEditable = !header && getCellIsEditable(column, row);

  const classNames = [prefix]
    .concat((column.id && column.id.toString().startsWith('mrt-')) ? [`${prefix}-${column.id}`] :
      [`${prefix}-${column.id}`])
    .concat((!header && row.getIsSelected()) ? [`${prefix}-selected`] : [])
    .concat(isEditable ? [`${prefix}-editable`] : [])
    .concat(column.getIsPinned() ? [`${prefix}-pinned`, `${prefix}-pinned-${column.getIsPinned()}`] : [])
    .concat(draggingColumn?.id === column.id ? [`${prefix}-dragging`] : [])
    .concat(hoveredColumn?.id === column.id ? [`${prefix}-hovering`] : [])
    .concat((getIsLastLeftPinnedColumn(table, column) || getIsFirstRightPinnedColumn(column)) ?
      [`${prefix}-pinned-last`] : [])
    .concat((getIsFirstLeftPinnedColumn(column) || getIsLastRightPinnedColumn(table, column)) ?
      [`${prefix}-pinned-first`] : []);

  return classNames.join(' ');
}

const getColumnLeft = (boundsLeft, table, column) => {
  return column.getIsPinned() === 'left'
    ? `calc(${boundsLeft ?? '0px'} + ${column.getStart('left')}px)`
    : null;
}

const getColumnRight = (boundsRight, table, column) => {
  const getEnd = () => {
    const cols = table.getRightLeafHeaders()
      .map((c) => c.column)
      .filter((c) => c.getIsPinned() === 'right');
    cols.sort((c1, c2) => c2.getPinnedIndex() - c1.getPinnedIndex());
    return cols.filter((c) => c.getPinnedIndex() > column.getPinnedIndex())
      .reduce((s, c) => s + c.getSize(), 0);
  }

  return column.getIsPinned() === 'right'
    ? `calc(${boundsRight ?? '0px'} + ${getEnd()}px)`
    : null;
}

const getColumnScrollMargins = (bounds, sticky, table, column, header) => {
  const margin = {top: 0, right: 0, bottom: 0, left: 0};

  if (!column.getIsPinned()) {
    margin.left = `calc(${bounds?.left ?? '0px'} + ${table.getLeftLeafHeaders()
      .map((c) => c.column)
      .filter((c) => c.getIsPinned() === 'left')
      .reduce((s, c) => s + c.getSize(), 0)}px)`;
    margin.right = `calc(${bounds?.right ?? '0px'} + ${table.getRightLeafHeaders()
      .map((c) => c.column)
      .filter((c) => c.getIsPinned() === 'right')
      .reduce((s, c) => s + c.getSize(), 0)}px)`;
  }
  if (!sticky || header) {
    margin.top = bounds?.top ?? 0;
    margin.bottom = bounds?.bottom ?? 0;
  } else {
    const header = table?.refs?.tableContainerRef?.current?.querySelector?.('th');
    margin.top = `calc(${bounds?.top ?? '0px'} + ${header?.clientHeight ?? 0}px)`;
    margin.bottom = bounds?.bottom ?? 0;
  }

  return `${margin.top} ${margin.right} ${margin.bottom} ${margin.left}`;
}

function getColumnId(column) {
  return column.id ?? column.accessorKey?.toString?.() ?? column.header;
}

const Table = React.forwardRef((props, ref) => {
  const {
    color,
    state,
    dataKey,
    loaders,
    debounce,
    listState,
    columnState,
    listSelection,
    rowOverScan,
    columnOverScan,
    ActionbarComponent,
    onFetchMore,
    onCanRowClick,
    onRowClick,
    enableParentScroll,
    enableScrollReset,
    ...innerProps
  } = useComponentProps(props, 'Table');

  const minColumnSize = 120;

  const wrapper = useWrapper();

  // STATE VARIABLES INIT
  const innerRef = useRef(null);
  const tableRef = useRef(null);
  const rowVirtualizerRef = useRef(null);
  const columnVirtualizerRef = useRef(null);
  const overflowRef = useRef(null);
  const scrollInitialised = useRef(false);
  const optimisticStoreRef = useRef({});
  const optimisticRef = useRef({
    get: (cell, original) => {
      if (optimisticStoreRef.current[cell.id]) {
        if (utils.compare(optimisticStoreRef.current[cell.id]?.original, original) ||
            (!utils.isDefined(optimisticStoreRef.current[cell.id]?.original) && !utils.isDefined(original))) {
          return optimisticStoreRef.current[cell.id].value;
        }
      }
      // not found then reset
      optimisticStoreRef.current[cell.id] = {
        original: original,
        value: original
      }
      return original;
    },
    set: (cell, value) => {
      optimisticStoreRef.current[cell.id] = optimisticStoreRef.current[cell.id] ?? {};
      optimisticStoreRef.current[cell.id].value = value;
    },
    reset: (cell) => {
      optimisticStoreRef.current[cell.id] = optimisticStoreRef.current[cell.id] ?? {};
      optimisticStoreRef.current[cell.id].value = optimisticStoreRef.current[cell.id].original;
    }
  });

  const [internalState, setInternalState] = useState({
    isFullScreen: false,
    scrollElement: null,
    firstLoad: true
  });

  const sticky = Boolean(innerProps.enableStickyHeader || innerProps.enableStickyFooter);

  const isLoading = !utils.isDefined(innerProps.data) || state?.isLoading;
  const isLoadingFirst = internalState.firstLoad && isLoading;

  const hasListState = Boolean(listState);
  const hasColumnState = Boolean(columnState);
  const hasListSelection = !isLoadingFirst && innerProps.columns?.length > 0 && Boolean(listSelection);

  const [columnSizingInternal, setColumnSizingInternal] = useState(columnState?.columnSizing);
  const columnSizingInfoInternal = useRef({});

  const getColumnOrderCopy = useCallback(() => {
    const columnIds = (innerProps?.columns || []).map((c) => getColumnId(c));
    const missing = columnIds.filter((cId) => !columnState?.columnOrder?.find((oId) => oId === cId));

    let res = columnState?.columnOrder;
    if (missing.length > 0) {
      res = [...res, ...missing];
    }

    if (hasListSelection) {
      res = ['mrt-row-select'].concat(res.filter((c) => c !== 'mrt-row-select'));
    } else {
      res = res.filter((c) => c !== 'mrt-row-select');
    }

    return res;
  }, [columnState?.columnOrder, hasListSelection, innerProps?.columns]);

  const getColumnPinning = useCallback(() => {
    let columnPinningSorted = {
      left: [...(columnState?.columnPinning?.left ?? [])],
      right: [...(columnState?.columnPinning?.right ?? [])]
    };
    const order = getColumnOrderCopy() ?? [];
    columnPinningSorted.left = columnPinningSorted.left
      .filter((c) => (columnState?.columnVisibility?.[c] ?? true) === true)
      .sort((a, b) => {
        return order.findIndex((o) => o === a) - order.findIndex((o) => o === b);
      });
    columnPinningSorted.right = columnPinningSorted.right
      .filter((c) => (columnState?.columnVisibility?.[c] ?? true) === true)
      .sort((a, b) => {
        return order.findIndex((o) => o === a) - order.findIndex((o) => o === b);
      });

    if (hasListSelection) {
      const left = columnPinningSorted.left.filter((p) => p !== 'mrt-row-select');
      return {...columnPinningSorted, left: ['mrt-row-select', ...left]};
    } else {
      return columnPinningSorted;
    }
  }, [columnState?.columnPinning?.left, columnState?.columnPinning?.right,
    getColumnOrderCopy, columnState?.columnVisibility, hasListSelection]);

  // COMPONENT OBJECT: to hold state and refs, possibly callbacks (imperative)
  const stateMemo = useEffectItem(state);
  const table = useMemo(() => ({
    refs: {
      ref: innerRef,
      tableRef: tableRef,
      overflowRef: overflowRef
    },
    state: {
      ...(hasColumnState ? {
        columnOrder: getColumnOrderCopy(),
        columnVisibility: columnState?.columnVisibility,
        columnPinning: getColumnPinning(),
        columnSizing: columnSizingInternal
      } : {}),
      ...(hasListState ? {
        columnFilters: listState?.filter,
        globalFilter: listState?.search,
        sorting: listState?.sort,
        pagination: listState?.pagination
      } : {}),
      ...(hasListSelection ? {
        rowSelection: listSelection?.rowSelection
      } : {}),
      parentScroll: Boolean(enableParentScroll && !internalState.isFullScreen),
      ...internalState,
      ...stateMemo,
      isLoading: false,
      loaders: loaders,
      debounce: debounce,
      customIsLoading: isLoading
    }
  }), [columnSizingInternal, columnState?.columnVisibility, enableParentScroll, getColumnOrderCopy, getColumnPinning,
    hasListSelection, hasListState, hasColumnState, internalState, listSelection?.rowSelection,
    listState?.filter, listState?.pagination, listState?.search, listState?.sort, stateMemo, isLoading, loaders, debounce]);

  useImperativeHandle(ref, () => table);

  const rowScrollOffset = useRef({x: 0, y: 0});
  const rowScrollElement = table.state.scrollElement;

  const columnScrollOffset = useRef({x: 0, y: 0});
  const columnScrollElement = table.state.scrollElement;

  // ALL EFFECTS

  // don't store every change, debounce
  const columnSizingEvent = useEffectEvent(columnState?.setColumnSizing);
  useEffect(() => {
    return utils.observeTimeout(() => {
      columnSizingEvent?.(table.state.columnSizing);
    }, constants.debounce.shortest);
  }, [table.state.columnSizing, columnSizingEvent]);

  useLayoutEffect(() => {
    if (hasColumnState) {
      setColumnSizingInternal(utils.updater(columnState?.columnSizing));
    }
  }, [hasColumnState, columnState?.columnSizing]);

  useLayoutEffect(() => {
    if (utils.isDefined(listState?.search)) {
      // table.refs.tableRef.current?.setGlobalFilter(() => listState?.search);
    }
  }, [listState?.search]);

  useLayoutEffect(() => {
    // table,ref.current.setShowFilters(false);
  }, [listState?.navigate]);

  // infinite scroll
  useOverflowShadow(rowScrollElement, null, overflowRef.current, innerProps.data?.length >= innerProps.rowCount);

  const infiniteScroll = Boolean(onFetchMore);
  const fetchingMoreRef = useRef({});
  const fetchMoreOnBottomReached = useCallback(() => {
    const el = rowScrollElement;

    if (el) {
      const horizontal = false;
      const scrollSize = horizontal ?
        (el === window ? document.body.scrollWidth : el.scrollWidth) :
        (el === window ? document.body.scrollHeight : el.scrollHeight);

      const scrollPos = horizontal ?
        (el === window ? window.scrollX : el.scrollLeft) :
        (el === window ? window.scrollY : el.scrollTop);
      const clientSize = horizontal ?
        (el === window ? window.innerWidth : el.clientWidth) :
        (el === window ? window.innerHeight : el.clientHeight);

      const endOfPage = ((scrollSize - scrollPos - clientSize) < (scrollPos === 0 ? 1 : (clientSize * 0.1)));

      // reset scroll state
      if ((innerProps.rowCount !== fetchingMoreRef.current.count) ||
          (scrollSize < fetchingMoreRef.current.scrollSize)) {
        fetchingMoreRef.current = {
          count: innerProps.rowCount,
          scrollSize: -1
        };
      }

      if (infiniteScroll) {
        if (!isLoading && innerProps.data?.length > 0) {
          if (fetchingMoreRef.current.scrollSize !== scrollSize && innerProps.data?.length < innerProps.rowCount) {
            if (endOfPage) {
              fetchingMoreRef.current.scrollSize = scrollSize;
              onFetchMore();

              return true;
            }
          }
        }
      }
    }
  }, [infiniteScroll, onFetchMore, rowScrollElement, isLoading, innerProps.data?.length, innerProps.rowCount]);

  // parent scroll params change
  useLayoutEffect(() => {
    if (table.refs.tableRef.current?.refs?.tableContainerRef?.current) {
      const element = () => {
        const scrollElement = dom.getScrollElement(table.refs.tableRef.current.refs.tableContainerRef.current, false, true);
        setInternalState((current) => {
          if (current.scrollElement !== scrollElement) {
            return {...current, scrollElement};
          } else {
            return current;
          }
        });

        return Boolean(scrollElement);
      }

      utils.retry(element);
    }
  }, [table.state.parentScroll, table.refs.tableRef]);

  //a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  useEffect(() => {
    if (rowScrollElement) {
      utils.retry(fetchMoreOnBottomReached, 3);
    }
  }, [fetchMoreOnBottomReached, rowScrollElement]);

  useEffect(() => {
    if (rowScrollElement) {
      return utils.observeScroll(rowScrollElement, () => {
        fetchMoreOnBottomReached();
      });
    }
  }, [rowScrollElement, fetchMoreOnBottomReached]);

  useEffect(() => {
    if (rowScrollElement) {
      return utils.observeResize(rowScrollElement, () => {
        fetchMoreOnBottomReached();
      });
    }
  }, [rowScrollElement, fetchMoreOnBottomReached]);

  const setScrollEvent = useEffectEvent(listState?.setScroll);
  const debouncedScroll = useMemo(() => {
    return utils.debounce((offset) => {
      if (scrollInitialised.current) {
        if (hasListState && enableScrollReset) {
          const index = {};
          try {
            index.column = columnVirtualizerRef.current?.getVirtualItemForOffset?.(offset.x)?.index;
            index.row = rowVirtualizerRef.current?.getVirtualItemForOffset?.(offset.y)?.index;
          } catch {
            /* SQUASH */
          }

          setScrollEvent?.({offset, index});
        }
      }
    }, constants.debounce.scroll);
  }, [hasListState, enableScrollReset, setScrollEvent]);

  // scroll memory
  useLayoutEffect(() => {
    if (rowScrollElement && rowScrollOffset.current.y > 0) {
      rowScrollElement.scrollTo?.({
        top: rowScrollOffset.current.y
      });
    }

    return utils.observeScroll(rowScrollElement, (offset) => {
      rowScrollOffset.current = offset;
      debouncedScroll(offset);
    });
  }, [rowScrollElement, debouncedScroll]);

  useLayoutEffect(() => {
    if (columnScrollElement && columnScrollOffset.current.x > 0) {
      columnScrollElement.scrollTo?.({
        left: columnScrollOffset.current.x
      });
    }

    return utils.observeScroll(columnScrollElement, (offset) => {
      columnScrollOffset.current = offset;
      debouncedScroll(offset);
    });
  }, [columnScrollElement, debouncedScroll]);

  // callbacks
  const parentWrapper = (table.state.parentScroll && sticky) ? wrapper : null;
  const parentWrapperRef = useUpdatedRef(parentWrapper);

  const handleColumnSizingInfoChange = useCallback((updater) => {
    columnSizingInfoInternal.current = updater(columnSizingInfoInternal.current);

    if (columnSizingInfoInternal.current.isResizingColumn) {
      if (table.refs.tableRef.current) {
        const cols = table.refs.tableRef.current.getLeftLeafHeaders()
          .map((c) => c.column)
          .filter((c) => c.getIsPinned() === 'left');

        const resizeCol = cols.find((c) => c.getIsResizing());
        if (resizeCol) {
          cols.sort((c1, c2) => c2.getPinnedIndex() - c1.getPinnedIndex());

          const resizeColPinIdx = resizeCol.getPinnedIndex();
          const other = cols.filter((c) => c.getPinnedIndex() > resizeColPinIdx);
          if (other.length > 0 && table.refs.tableRef.current.refs.tableContainerRef.current) {
            const rows = Array.from(table.refs.tableRef.current.refs.tableContainerRef.current
              .querySelectorAll('.MuiTableRow-root'));

            rows.forEach((r) => {
              const cells = Array.from(r.querySelectorAll('.TableHeader-pinned-left, .TableCell-pinned-left'));

              if (cells[resizeColPinIdx]) {
                cells[resizeColPinIdx].style.width = `${columnSizingInfoInternal.current.startSize + columnSizingInfoInternal.current.deltaOffset}px`;
              }
              other.forEach((c, idx) => {
                if (cells[resizeColPinIdx + idx]) {
                  const bbox = dom.getBbox(cells[resizeColPinIdx + idx]);
                  if (cells[resizeColPinIdx + (idx + 1)]) {
                    const width = Math.min(
                      Math.max(
                        resizeCol.columnDef.minSize,
                        (columnSizingInfoInternal.current.startSize + columnSizingInfoInternal.current.deltaOffset)
                      ),
                      resizeCol.columnDef.maxSize
                    )
                    cells[resizeColPinIdx + (idx + 1)].style.left =
                      `${bbox.left + width}px`;
                  }
                }
              })
            })
          }
        }
      }
    }
  }, [table.refs.tableRef]);

  const handleIsFullScreenChange = useCallback((isFullScreen) => {
    setInternalState(utils.updater({isFullScreen}, true));
  }, []);

  innerProps.onColumnSizingInfoChange = innerProps.onColumnSizingInfoChange ?? handleColumnSizingInfoChange;
  innerProps.onIsFullScreenChange = innerProps.onIsFullScreenChange ?? handleIsFullScreenChange;

  if (hasListState) {
    innerProps.onGlobalFilterChange = innerProps.onGlobalFilterChange ?? listState.setSearch;
    innerProps.manualFiltering = innerProps.manualFiltering ?? Boolean(listState.setFilter);
    innerProps.onColumnFiltersChange = innerProps.onColumnFiltersChange ?? listState.setFilter;
    innerProps.manualSorting = innerProps.manualSorting ?? Boolean(listState.setSort);
    innerProps.onSortingChange = innerProps.onSortingChange ?? listState.setSort;
    innerProps.manualPagination = innerProps.manualPagination ?? Boolean(listState.setPagination);
    innerProps.onPaginationChange = innerProps.onPaginationChange ?? listState.setPagination;
  }

  if (hasColumnState) {
    innerProps.onColumnOrderChange = innerProps.onColumnOrderChange ?? columnState.setColumnOrder;
    innerProps.onColumnVisibilityChange = innerProps.onColumnVisibilityChange ?? columnState.setColumnVisibility;
    innerProps.onColumnPinningChange = innerProps.onColumnPinningChange ?? columnState.setColumnPinning;
    innerProps.onColumnSizingChange = innerProps.onColumnSizingChange ?? setColumnSizingInternal;
  }

  const muiSelectCheckboxPropsMemo = useEffectItem(innerProps.muiSelectCheckboxProps);
  const muiSelectAllCheckboxPropsMemo = useEffectItem(innerProps.muiSelectAllCheckboxProps);
  const selectAllEvent = useEffectEvent(listSelection?.selectAll);
  const selectAllChecked = Boolean(listSelection?.allSelected);
  const selectAllIndeterminate = Boolean(!selectAllChecked && listSelection?.count > 0);
  innerProps.muiSelectAllCheckboxProps = useMemo(() => {
    if (hasListSelection) {
      return {
        ...(hasListSelection ? {
          indeterminate: selectAllIndeterminate,
          checked: selectAllChecked,
          onChange: () => {
            return selectAllEvent?.()
          }
        } : {}),
        ...muiSelectCheckboxPropsMemo,
        ...muiSelectAllCheckboxPropsMemo
      };
    }
  }, [hasListSelection, selectAllEvent, selectAllChecked, selectAllIndeterminate,
    muiSelectCheckboxPropsMemo, muiSelectAllCheckboxPropsMemo]);

  if (hasListSelection) {
    innerProps.enableRowSelection = true;
    innerProps.onRowSelectionChange = listSelection.setRowSelection;
  }

  innerProps.columnResizeMode = innerProps.columnResizeMode ?? 'onChange';
  innerProps.enablePagination = innerProps.enablePagination ?? !infiniteScroll;

  innerProps.editingMode = innerProps.editingMode ?? 'cell';

  innerProps.positionToolbarAlertBanner = innerProps.positionToolbarAlertBanner ?? 'none';
  const onRefetchEvent = useEffectEvent(innerProps.onRefetch);
  const handleRenderTopToolbarCustomActions = useCallback(() => {
    if (onRefetchEvent) {
      return <Tooltip arrow title="Refresh Data">
        <IconButton onClick={() => onRefetchEvent?.()}>
          <Icon icon={Refresh}/>
        </IconButton>
      </Tooltip>
    }
  }, [onRefetchEvent]);
  innerProps.renderTopToolbarCustomActions = innerProps.renderTopToolbarCustomActions ?? handleRenderTopToolbarCustomActions;

  const handleRenderColumnActionsMenuItems = useCallback(({ internalColumnMenuItems }) => {
    return internalColumnMenuItems.map((item, idx) => {
      return utils.cloneElement(item, {
        dense: true,
        style: {
          paddingLeft: innerProps.theme.layout('1sp'),
          paddingRight: innerProps.theme.layout('1.5sp'),
          marginTop: idx === 0 ? innerProps.theme.layout('-0.5sp') : 'unset',
          marginBottom: (idx === internalColumnMenuItems.length - 1) ? innerProps.theme.layout('-0.5sp') : 'unset'
        }
      })
    });
  }, [innerProps.theme]);
  innerProps.renderColumnActionsMenuItems = innerProps.renderColumnActionsMenuItems ?? handleRenderColumnActionsMenuItems;
  const tableHeadCellColumnActionsButtonProps = useMemo(() => ({
    title: 'Actions',
    className: 'ColumnActionMenu'
  }), []);
  innerProps.muiTableHeadCellColumnActionsButtonProps = innerProps.muiTableHeadCellColumnActionsButtonProps ?? tableHeadCellColumnActionsButtonProps;

  innerProps.sortDescFirst = innerProps.sortDescFirst ?? false;
  innerProps.enableRowVirtualization = innerProps.enableRowVirtualization ?? true;
  innerProps.enableColumnVirtualization = innerProps.enableColumnVirtualization ?? true;

  const styles = useStyles();
  const defaultVirtualizerProps = useMemo(() => ({
    observeElementRect: (v, cb) => {
      return utils.observeResize(v.scrollElement, cb);
    },
    observeElementOffset: (v, cb) => {
      return utils.observeScroll(v.scrollElement, ({x, y}) => {
        let extraOffset = v.options.horizontal ? (table.state.parentScroll ?
            utils.rem2Pixel(parentWrapperRef.current?.boundsCum?.left ?? 0, styles?.fontBase) : 0) :
          (table.state.parentScroll ? utils.rem2Pixel(parentWrapperRef.current?.boundsCum?.top ?? 0, styles?.fontBase) : 0);

        v.options.horizontal ? cb(x - extraOffset) : cb(y - extraOffset);
        v.initialised = true;
      }, {
        timeout: constants.debounce.minimal, speed: 1, // > 1px per ms, then debounce else apply
        direction: v.options.horizontal ? 'horizontal' : 'vertical'
      });
    },
    scrollToFn: (offset, {adjustments = 0, behavior}, v) => {
      if (v.initialised) { // prevent scroll reset
        const extraOffset = v.options.horizontal ? (table.state.parentScroll ?
            utils.rem2Pixel(parentWrapperRef.current?.boundsCum?.left ?? 0, styles?.fontBase) : 0) :
          (table.state.parentScroll ? utils.rem2Pixel(parentWrapperRef.current?.boundsCum?.top ?? 0, styles?.fontBase) : 0);

        const toOffset = offset + adjustments + extraOffset;
        if (toOffset >= 0) {
          v.scrollElement?.scrollTo?.({
            [v.options.horizontal ? 'left' : 'top']: toOffset,
            behavior,
          });
        }
      }
    }
  }), [table.state.parentScroll, parentWrapperRef, styles?.fontBase]);

  const rowVirtualizerProps = useMemo(() => ({
    ...defaultVirtualizerProps,
    overscan: rowOverScan,
    horizontal: false,
    getScrollElement: () => {
      return rowScrollElement;
    }
  }), [rowOverScan, rowScrollElement, defaultVirtualizerProps]);

  if (innerProps.enableRowVirtualization) {
    innerProps.rowVirtualizerProps = innerProps.rowVirtualizerProps ?? rowVirtualizerProps;
  }

  const columnVirtualizerProps = useMemo(() => ({
    ...defaultVirtualizerProps,
    overscan: columnOverScan,
    horizontal: true,
    getScrollElement: () => {
      return columnScrollElement;
    }
  }), [columnOverScan, columnScrollElement, defaultVirtualizerProps]);

  if (innerProps.enableColumnVirtualization) {
    innerProps.columnVirtualizerProps = innerProps.columnVirtualizerProps ?? columnVirtualizerProps;
  }

  // scroll top
  useEffect(() => {
    if (scrollInitialised.current) {
      utils.retry(() => {
        if (rowScrollElement) {
          rowScrollElement.scrollTo?.({
            top: 0
          });
        }
      }, 3);
    }
  }, [table.state.pagination, table.state.sorting, table.state.globalFilter, table.state.columnFilters, rowScrollElement]);

  // scroll reset
  useEffect(() => {
    if (rowScrollElement && columnScrollElement) {
      if (!scrollInitialised.current) {
        if (hasListState && enableScrollReset) {
          if (listState.active) {
            if (utils.isDefined(listState.scroll)) {
              utils.retry(() => {
                let yOffset = listState.scroll.offset.y;
                if (innerProps.enableRowVirtualization) {
                  try {
                    const [vOffset] = rowVirtualizerRef.current.getOffsetForIndex(listState.scroll.index.row, 'start');
                    if (Math.abs(vOffset - yOffset) > 36) {
                      yOffset = vOffset + 1;
                    }
                  } catch (e) {
                    /* SQUASH */
                  }
                }
                rowScrollElement.scrollTo?.({
                  top: yOffset
                });

                let xOffset = listState.scroll.offset.x;
                if (innerProps.enableColumnVirtualization) {
                  try {
                    const [vOffset] = columnVirtualizerRef.current.getOffsetForIndex(listState.scroll.index.column, 'start');
                    //if (Math.abs(vOffset - xOffset) > 180) {
                    xOffset = vOffset;
                    //}
                  } catch (e) {
                    /* SQUASH */
                  }
                }
                columnScrollElement.scrollTo?.({
                  left: xOffset
                });
              }, 3)
                .then(() => scrollInitialised.current = true);
            } else {
              scrollInitialised.current = true;
            }
          }
        } else {
          scrollInitialised.current = true;
        }
      }
    }
  }, [hasListState, enableScrollReset, listState?.active, listState?.scroll, innerProps.enableRowVirtualization,
    innerProps.enableColumnVirtualization, rowScrollElement, columnScrollElement]);

  const onRowClickEvent = useEffectEvent(onRowClick);
  const onCanRowClickEvent = useEffectEvent(onCanRowClick);
  const muiTableBodyRowPropsMemo = useEffectItem(innerProps.muiTableBodyRowProps);
  innerProps.muiTableBodyRowProps = useCallback((args) => {
    const { row, table } = args;
    const canClick = onRowClickEvent && (!onCanRowClickEvent || onCanRowClickEvent?.(row.original));
    return utils.mergeObjects({
      className: canClick ? 'clickable' : '',
      onClick: (e) => {
        if (!e.defaultPrevented && !table.getState().editingCell) {
          if (canClick) {
            onRowClickEvent?.(e, row.original);
          }
        }
      }
    }, utils.isFunction(muiTableBodyRowPropsMemo) ? muiTableBodyRowPropsMemo(args) :
      muiTableBodyRowPropsMemo);
  }, [muiTableBodyRowPropsMemo, onCanRowClickEvent, onRowClickEvent]);

  const muiTableContainerPropsMemo = useEffectItem(innerProps.muiTableContainerProps);
  innerProps.muiTableContainerProps = useMemo(() => {
    return utils.mergeObjects({
      onScroll: !table.state.parentScroll ? fetchMoreOnBottomReached : null
    }, muiTableContainerPropsMemo)
  }, [table.state.parentScroll, muiTableContainerPropsMemo, fetchMoreOnBottomReached]);

  // fix page index bug
  useLayoutEffect(() => {
    const pagination = {...listState?.pagination};
    if (pagination) {
      if (!innerProps?.rowCount) {
        pagination.pageIndex = 0;
      } else {
        if (table.refs.tableRef.current && utils.isDefined(innerProps?.rowCount) &&
          pagination?.pageIndex > 0 && pagination?.pageSize > 0) {
          if ((innerProps?.rowCount / pagination?.pageSize) <= pagination?.pageIndex) {
            pagination.pageIndex = 0;
            if (listState) {
              listState.resetPageIndex();
            } else {
              setTimeout(() => table.refs.tableRef.current.setPageIndex(0), 100);
            }
          }
        }
      }
    }
  }, [listState, table.refs.tableRef, innerProps?.rowCount]);

  const muiTablePaperPropsMemo = useEffectItem(innerProps.muiTablePaperProps);
  innerProps.muiTablePaperProps = useMemo(() => utils.mergeObjects({
    elevation: 0
  }, muiTablePaperPropsMemo), [muiTablePaperPropsMemo]);

  innerProps.muiSelectCheckboxProps = useMemo(() => utils.mergeObjects({
    size: 'small'
  }, muiSelectCheckboxPropsMemo), [muiSelectCheckboxPropsMemo]);

  const iconsMemo = useEffectItem(innerProps.icons);
  innerProps.icons = useMemo(() => utils.mergeObjects({ /* icon overrides */ }, iconsMemo), [iconsMemo]);

  // SET COLUMN DEFAULT OVERRIDES
  const muiTableHeadCellPropsDefault = useEffectEvent((args, prev = null) => {
    const { table, column, row } = args;

    return utils.mergeObjects({
      className: getColumnClassNames(table, column, row, true),
      onFocus: (e) => {
        setTimeout(() => {
          let el = e?.target;
          while (el && !Array.from(el.classList).find((c) => c === 'TableHeader')) {
            el = el.parentElement;
          }
          dom.scrollIntoView(el);
        }, 100)
      },
      style: {
        left: getColumnLeft(parentWrapper?.boundsCum?.left, table, column),
        right: getColumnRight(parentWrapper?.boundsCum?.right, table, column),
        scrollMargin: getColumnScrollMargins(parentWrapper?.scrollBoundsCum, sticky, table, column, true),
        maxWidth: utils.isDefined(column.columnDef.maxSize) ? innerProps.theme.layout(column.columnDef.maxSize) : null
      },
    }, utils.isFunction(prev) ? prev(args) : prev);
  });

  const muiTableBodyCellPropsDefault = useEffectEvent((args, prev = null) => {
    const {table, column, row} = args;

    let clickTimeout;

    return utils.mergeObjects({
      className: getColumnClassNames(table, column, row, false),
      onClick: (e) => {
        if (!e.defaultPrevented && !e.nativeEvent?.refire) {
          clearTimeout(clickTimeout);
          if (!table.getState().editingCell) {
            const isEditable = getCellIsEditable(column, row);
            if (innerProps.enableEditing && isEditable) {
              clickTimeout = setTimeout(() => {
                utils.refireEvent(e);
              }, constants.debounce.dblClick);
              e.preventDefault();
            }
          }
        }
      },
      onDoubleClick: () => {
        clearTimeout(clickTimeout);
      },
      onFocus: (e) => {
        setTimeout(() => {
          let el = e?.target;
          while (el && !Array.from(el.classList).find((c) => c === 'TableCell')) {
            el = el.parentElement;
          }
          dom.scrollIntoView(el);
        }, 100)
      },
      style: {
        left: getColumnLeft(parentWrapper?.boundsCum?.left, table, column),
        right: getColumnRight(parentWrapper?.boundsCum?.right, table, column),
        scrollMargin: getColumnScrollMargins(parentWrapper?.scrollBoundsCum, sticky, table, column, false),
        maxWidth: utils.isDefined(column.columnDef.maxSize) ? innerProps.theme.layout(column.columnDef.maxSize) : null
      },
    }, utils.isFunction(prev) ? prev(args) : prev);
  });

  innerProps.displayColumnDefOptions = useMemo(() => {
    const columns = utils.mergeObjects({
      'mrt-row-select': {
        minSize: 40,
        size: 40,
        enableClickToCopy: false,
        enableColumnActions: false,
        enableColumnDragging: false,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enablePinning: false,
        enableEditing: false,
        enableGlobalFilter: false,
        enableGrouping: false,
        enableHiding: false,
        enableResizing: false,
        enableSorting: false
      },
    }, innerProps.displayColumnDefOptions);

    Object.keys(columns).forEach((k) => {
      const column = columns[k];
      column.muiTableHeadCellProps = (args) => muiTableHeadCellPropsDefault(args);
      column.muiTableBodyCellProps = (args) => muiTableBodyCellPropsDefault(args);
    });

    return columns;
  }, [innerProps.displayColumnDefOptions, muiTableHeadCellPropsDefault, muiTableBodyCellPropsDefault]);

  const columns = useMemo(() => {
    return (innerProps.columns || []).map((column) => {
      column = {...column};
      column.optimistic = optimisticRef.current;
      column.minSize = column?.minSize ?? minColumnSize;

      if (!column.Header) {
        column.Header = TableHeader;
      }
      if (!column.Cell) {
        column.Cell = TableText;
      }

      if (!utils.isDefined(column.DefaultHeader)) {
        column.DefaultHeader = column.Header;
      }

      if (!utils.isDefined(column.DefaultCell)) {
        column.DefaultCell = column.Cell;
      }

      if (!utils.isDefined(column.DefaultEdit) && column.Edit) {
        column.DefaultEdit = column.Edit;
      }

      column.Header = TableHead;
      column.Cell = TableCell;
      column.Edit = TableEdit;

      if (!utils.isDefined(column.muiDefaultProps)) {
        column.muiDefaultProps = {
          muiTableHeadCellProps: column.muiTableHeadCellProps,
          muiTableBodyCellProps: column.muiTableBodyCellProps
        }
      }

      column.muiTableHeadCellProps = (args) => muiTableHeadCellPropsDefault(args, column.muiDefaultProps.muiTableHeadCellProps);
      column.muiTableBodyCellProps = (args) => muiTableBodyCellPropsDefault(args, column.muiDefaultProps.muiTableBodyCellProps);

      return column;
    });
  }, [innerProps.columns, muiTableHeadCellPropsDefault, muiTableBodyCellPropsDefault])

  const actionbar = useActionbar();
  useEffect(() => {
    if (hasListSelection) {
      if (listSelection.count > 0) {
        actionbar.show(ActionbarComponent, listSelection, innerProps.data,
          (e, reason) => {
            if (['escapeKeyDown', 'closeButtonClick', 'cancelButtonClick'].includes(reason)) {
              listSelection.clearSelection();
              actionbar.hide();
            } else if (listSelection.count === 0) {
              actionbar.hide();
            }
          })
      } else {
        actionbar.hide();
      }

      return () => actionbar.hide();
    }
  }, [ActionbarComponent, hasListSelection, listState, listSelection, actionbar, innerProps.data]);

  useEffect(() => {
    if (utils.isDefined(innerProps.data)) {
      setInternalState(utils.updater({firstLoad: false}, true));
    }
  }, [innerProps.data]);

  const data = useMemo(() => {
    return isLoading ?
      Array(table.state?.pagination?.pageSize).fill({
        ...columns.reduce((o, c) => ({...o, [getColumnId(c)]: null}), {})
      }) : (innerProps.data ?? []);
  }, [columns, innerProps.data, isLoading, table.state?.pagination?.pageSize]);

  const localization = useMemo(() => ({
    noRecordsToDisplay: (isLoading || table.state.showProgressBars) ? 'Loading results...' :
      (innerProps.emptyText ?? 'No results to display')
  }), [isLoading, innerProps.emptyText, table.state.showProgressBars]);

  const initialStateMemo = useEffectItem(innerProps.initialState);
  const initialState = useMemo(() => ({
    showColumnFilters: false,
    ...initialStateMemo
  }), [initialStateMemo]);

  const getRowId = useCallback((row, index) => {
    return dataKey ? row[dataKey] : index;
  }, [dataKey]);

  innerProps.getRowId = innerProps.getRowId ?? getRowId;

  const classNameFlattened = utils.flattenClassName(innerProps.className);

  return <StyledTable ref={innerRef}
                      className={classNameFlattened}
                      $wrapper={parentWrapper}
                      $parentScroll={table.state.parentScroll}
                      $minimalFooter={!innerProps.enableBottomToolbar}
                      $showProgressBars={table.state.showProgressBars}
                      $sticky={sticky}
                      $color={color}>
    <MaterialReactTable {...innerProps}
                        data={data}
                        tableInstanceRef={tableRef}
                        columns={columns}
                        localization={localization}
                        initialState={initialState}
                        rowVirtualizerInstanceRef={rowVirtualizerRef}
                        columnVirtualizerInstanceRef={columnVirtualizerRef}
                        enableBottomToolbar={true}
                        state={table.state} />
    <Box ref={overflowRef} className="Table-footer" />
  </StyledTable>
});

Table.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  state: PropTypes.object,
  dataKey: PropTypes.string,
  onRefetch: PropTypes.func,
  listState: PropTypes.object,
  columnState: PropTypes.object,
  kanbanState: PropTypes.object,
  listSelection: PropTypes.object,
  onFetchMore: PropTypes.func,
  canRowClick: PropTypes.func,
  onRowClick: PropTypes.func,
  enableParentScroll: PropTypes.bool,
  enableScrollReset: PropTypes.bool,
  color: PropTypes.string,
  loaders: PropTypes.bool,
  emptyText: PropTypes.string,
  debounce: PropTypes.bool,
  ActionbarComponent: PropTypes.any
}

Table.defaultProps = {
  color: 'primary',
  loaders: true,
  debounce: true,
  rowOverScan: 3,
  columnOverScan: 2,
  enableColumnActions: true,
  enableColumnFilters: false,
  enableGrouping: false,
  enableHiding: false,
  enableTopToolbar: false,
  enableBottomToolbar: false,
  enableSorting: false,
  enableEditing: false,
  ActionbarComponent: null
};

export default Table;
