import React, {useEffect, useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useEffectItem} from 'helpers/hooks/utils';
import {default as searchUtils} from 'helpers/search';
import StyledAdvancedQuery from 'components/organisms/Queries/AdvancedQuery/AdvancedQuery.styles';
import AdvancedQueryPart from 'components/organisms/Queries/AdvancedQueryPart/AdvancedQueryPart';
import utils from 'helpers/utils';
import constants from 'helpers/constants';

const AdvancedQuery = React.forwardRef((props, ref) => {
  const {
    query,
    search,
    wizard,
    examples,
    filter,
    filterGroups,
    fieldData,
    required,
    readOnly,
    disabled,
    debounce,
    error,
    success,
    size,
    onChange,
    onError,
    ...innerProps
  } = useComponentProps(props, 'AdvancedQuery');

  const innerRef = useRef(null);
  const partRef = useRef(null);

  const advancedQuery = useMemo(() => ({
    refs: {
      ref: innerRef,
      partRef
    },
    submit: (touch = true) => {
      return partRef.current?.submit?.(touch);
    },
    validate: (touch = true) => {
      return partRef.current?.validate?.(touch);
    },
    reset: (state) => {
      return partRef.current?.reset?.(state);
    }
  }), []);

  useImperativeHandle(ref, () => advancedQuery);

  const filterGroupsMemo = useMemo(() => {
    const processFilter = (filter) => {
      // does the filter have a list of options or user-input allowed
      const staticOptions = !utils.isEmpty(filter.options);
      const defaultOperators = staticOptions ? constants.query.filterOperatorGroups.static :
        constants.query.filterOperatorGroups.dynamic;

      return {
        ...filter,
        staticOptions,
        operators: (filter.operators ?? defaultOperators)
      }
    }

    const entityFilterGroups = [];
    if (filterGroups) {
      filterGroups
        .forEach((filterGroupDef) => {
          entityFilterGroups.push({
            ...filterGroupDef,
            name: filterGroupDef.name,
            title: filterGroupDef.title,
            filters: filterGroupDef.filters?.reduce((a, filter) => {
              return a.concat([processFilter(filter)]);
            }, [])
          });
        });
    }

    return entityFilterGroups;
  }, [filterGroups]);

  const examplesMemo = useEffectItem(examples);
  const onChangeEvent = useEffectEvent(onChange);
  useEffect(() => {
    if (!query) {
      const newQuery = searchUtils.advancedQuery(query, search, wizard, examplesMemo, filter);
      onChangeEvent?.(newQuery, search, wizard, examplesMemo);
    }
  }, [query, search, wizard, examplesMemo, filter, onChangeEvent]);

  const debouncedChange = useMemo(() => {
    return utils.debounce((query) => {
      onChangeEvent?.(query, search, wizard, examplesMemo);
    }, debounce === true ? constants.debounce.input : (!debounce ? 0 : debounce));
  }, [onChangeEvent, search, wizard, examplesMemo, debounce]);

  const handleChange = (change, isNewPart) => {
    const cleanNewParts = (part, depth = 0) => {
      if (part.parts) {
        part.parts = part.parts.filter((p) => !p.isNew);
        part.parts.forEach((p) => {
          cleanNewParts(p, depth + 1);
        });

        if (depth > 0 && part.parts.length === 1) {
          Object.assign(part, {
            ...part,
            ...part.parts[0],
            id: part.id,
            queryType: part.queryType,
            parts: part.parts[0].parts
          });
        }
      }

      if (utils.isEmpty(part.parts)) {
        delete part.parts;
      }
    }

    if (!isNewPart) {
      const newQuery = utils.clone(query, true);
      cleanNewParts(change);
      if (change.parts?.length > 0) {
        newQuery.parts = change.parts;
      } else if (utils.isDefined(change.queryType)) {
        newQuery.parts = [change];
      } else {
        newQuery.parts = [];
      }

      if (debounce) {
        debouncedChange(newQuery, search, wizard, examplesMemo);
      } else {
        onChangeEvent?.(newQuery, search, wizard, examplesMemo);
      }
    }
  }

  return <StyledAdvancedQuery ref={innerRef} {...innerProps}>
    {query ? <AdvancedQueryPart ref={partRef}
                                part={query}
                                depth={0}
                                first={true}
                                size={size}
                                error={error}
                                canUpdate={Boolean(!disabled && !readOnly)}
                                filterGroups={filterGroupsMemo}
                                fieldData={fieldData}
                                required={required}
                                onError={onError}
                                onChange={handleChange}/> : null}
  </StyledAdvancedQuery>
});

AdvancedQuery.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  search: PropTypes.string,
  wizard: PropTypes.string,
  examples: PropTypes.array,
  query: PropTypes.object,
  filterGroups: PropTypes.array,
  fieldData: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  size: PropTypes.string,
  debounce: PropTypes.any,
  onChange: PropTypes.func,
  onError: PropTypes.func
};

AdvancedQuery.defaultProps = {
  size: 'small'
};

export default AdvancedQuery;
