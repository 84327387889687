import {useAuthClientId} from 'services/auth/auth.utils';
import utils from 'helpers/utils';
import {useCallback, useMemo} from 'react';
import {useClientCustomFieldCache, useClientCustomFieldUpdate} from 'services/client/customField/customField.hooks';

export function useClientCustomFieldGroups () {
  const clientId = useAuthClientId();
  const clientCustomFields = useClientCustomFieldCache({clientId}, {enabled: utils.isDefined(clientId)});
  const clientCustomFieldsCache = (clientCustomFields.data?.data ?? clientCustomFields.data);

  return useMemo(() => {
    const groups = clientCustomFieldsCache?.reduce((acc, f) => {
      if (f.groupName) {
        acc.push({
          label: utils.upperFirst(f.groupName),
          value: utils.upperFirst(f.groupName)
        });
      }
      return acc;
    }, []);

    return utils.uniqueArray(groups ?? [], null, true, (a, b) => {
      return a.value.toLowerCase() === b.value.toLowerCase();
    })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [clientCustomFieldsCache]);
}

export function useClientCustomFieldPatch () {
  const clientId = useAuthClientId();
  const updateField = useClientCustomFieldUpdate();

  return useCallback((field, changes) => {
    changes = (Object.keys(changes).length > 1) ? utils.changedFormFields(field, changes) : changes;

    if (!utils.isEmpty(changes)) {
      return updateField.mutation.mutateAsync({
        clientId,
        fieldId: field.fieldId,
        ...changes
      });
    } else {
      return Promise.resolve();
    }
  }, [clientId, updateField.mutation]);
}
