import React, {useImperativeHandle, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import Typography from 'components/atoms/Text/Typography/Typography';
import StyledSuggestionCard from 'components/molecules/Cards/SuggestionCard/SuggestionCard.styles';
import Button from 'components/atoms/Buttons/Button/Button';
import Link from 'components/atoms/Links/Link/Link';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Chip from 'components/atoms/Chips/Chip/Chip';

const SuggestionCard = React.forwardRef((props, ref) => {
  const {
    title,
    subtitle,
    link,
    icon,
    number,
    chip,
    button,
    ButtonProps,
    ...innerProps
  } = useComponentProps(props, 'SuggestionCard', {
    static: ['selected'],
    variable: ['iconDensity'],
    children: ['title', 'subtitle', 'link', 'button']
  });

  const innerRef = useRef(null);
  useImperativeHandle(ref, () => innerRef.current);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledSuggestionCard ref={innerRef} {...innerProps} elevation={0}>
    <Box className="SuggestionCard-body">
      {number ?
        <Box className="SuggestionCard-number">
          {utils.isReactElement(number) ? number : <Typography variant="subtitle2">{number}</Typography>}
          {chip ? <Box className="SuggestionCard-chip">{utils.isReactElement(chip) ? chip : <Chip label={chip} showTooltip={false} size="small"/>}</Box> : null}
        </Box> : null}
      {title ? <Box className="SuggestionCard-title">{utils.isReactElement(title) ? title :
        <Typography showTooltip={true} variant="subtitle2">{title}</Typography>}</Box> : null}
      {subtitle ?
        <Box className="SuggestionCard-subtitle">{utils.isReactElement(subtitle) ? subtitle :
          <Typography variant="body2">{subtitle}</Typography>}</Box> : null}
      {link ?
        <Box className="SuggestionCard-link">{utils.isReactElement(link) ? link :
          <Link showTooltip={true}
                href={utils.cleanExternalLink(link?.value ?? link)}
                onClick={(e) => e.stopPropagation()}
                target="_blank">{link?.label ?? link}</Link>}
        </Box> : null}
    </Box>
    {button ? <Box className="SuggestionCard-button">{utils.isReactElement(button) ? button :
      <Button label={button}
              variant="text"
              size="small"
              startIcon={icon ? <Icon icon={icon}/> : null}
              onClick={() => null}
              {...ButtonProps}/>}</Box> : null}
  </StyledSuggestionCard>
});

SuggestionCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.any,
  subtitle: PropTypes.any,
  consolidatedParent: PropTypes.any,
  link: PropTypes.any,
  icon: PropTypes.any,
  number: PropTypes.any,
  chip: PropTypes.any,
  button: PropTypes.any,
  ButtonProps: PropTypes.object
};

SuggestionCard.defaultProps = {
};

export default SuggestionCard;
