import React, {useCallback, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useOverflowShadow} from 'helpers/hooks/utils';
import StyledDataList from 'components/organisms/Lists/DataList/DataList.styles';
import utils from 'helpers/utils';
import constants from 'helpers/constants';
import LinearProgress from 'components/atoms/Progress/LinearProgress/LinearProgress';
import List from 'components/atoms/Lists/List/List';
import Box from 'components/atoms/Layout/Box/Box';
import {P} from 'components/atoms/Text/Typography/Typography';
import ListItem from 'components/atoms/Lists/ListItem/ListItem';
import {withMemo} from 'helpers/wrapper';
import dom from 'helpers/dom';

const DataListItem = withMemo((props) => {
  const {
    item,
    render,
    selected,
    debounce,
    isLoading
  } = props;

  return render?.(
    item, {
      debounce,
      isLoading,
      selected
    }
  );
});

const DataList = React.forwardRef((props, ref) => {
  const {
    count,
    data,
    selected,
    debounce,
    emptyText,
    dataKey,
    isItemEqual,
    renderItem,
    isLoading,
    loaderCount,
    showProgressBar,
    orientation,
    ListProps,
    ListItemProps,
    onSelectionChange,
    onHighlightChange,
    onFetchMore,
    selectionEnabled,
    ...innerProps
  } = useComponentProps(props, 'DataList', {
    children: ['list'],
    styled: ['overflowHeader', 'overflowFooter'],
    variable: ['orientation']
  });

  const innerRef = useRef(null);
  const listRef = useRef(null);
  const overflowHeaderRef = useRef(null);
  const overflowFooterRef = useRef(null);
  const selectedIndexRef = useRef(null);
  const fetchingMoreRef = useRef({});
  const [internalState, setInternalState] = useState({
    selectedIndex: null,
    debouncedSelectedIndex: null,
    scrollElement: null
  });

  const dataList = useMemo(() => ({
    refs: {
      ref: innerRef,
      listRef
    },
    state: {
      ...internalState,
      ...utils.cleanObject({})
    }
  }), [internalState])

  useImperativeHandle(ref, () => dataList);

  const items = useMemo(() => {
    return (isLoading && loaderCount > 0) ? new Array(loaderCount).fill({ loader: true }) : data;
  }, [data, isLoading, loaderCount]);

  const infiniteScroll = Boolean(onFetchMore);
  const hasSelection = selectionEnabled || Boolean(onSelectionChange);

  const debouncedSelection = useMemo(() => {
    return utils.debounce((e, idx) => {
      setInternalState(utils.updater({debouncedSelectedIndex: idx, selectedIndex: idx}, true));
    }, debounce === true ? constants.debounce.selection : (!debounce ? 0 : debounce));
  }, [debounce]);

  const doSelection = useCallback((e, idx, direct = false) => {
    if (direct) {
      selectedIndexRef.current = idx;
      setInternalState(utils.updater({debouncedSelectedIndex: idx, selectedIndex: idx}, true));
    } else {
      debouncedSelection(e, idx);
      if (debounce) {
        selectedIndexRef.current = null;
        setInternalState(utils.updater({selectedIndex: idx}, true));
      }
    }
  }, [debouncedSelection, debounce]);

  const selectedIndexOld = useMemo(() => {
    if (hasSelection) {
      if (items?.length > 0) {
        if (internalState.selectedIndex > (items?.length - 1)) {
          selectedIndexRef.current = items?.length - 1;
        } else if (!(internalState.selectedIndex >= 0)) {
          selectedIndexRef.current = 0;
        }
        return selectedIndexRef.current;
      }
    }
  }, [hasSelection, items, internalState.selectedIndex]);

  useLayoutEffect(() => {
    if (hasSelection) {
      if ((selectedIndexOld ?? -1) >= 0) {
        doSelection(null, selectedIndexOld, true);
      }
    }
  }, [hasSelection, selectedIndexOld, doSelection]);

  const isItemEqualEvent = useEffectEvent(isItemEqual);
  const selectedIndexNew = useMemo(() => {
    if (hasSelection) {
      const selectedIdx = selected ? items?.findIndex((d) => {
        return isItemEqualEvent ? isItemEqualEvent?.(d, selected) : (dataKey ?
          d[dataKey] === selected[dataKey] : d === selected);
      }) : null;

      if ((selectedIdx ?? -1) >= 0) {
        selectedIndexRef.current = selectedIdx;
      }

      return selectedIndexRef.current;
    }
  }, [hasSelection, items, selected, dataKey, isItemEqualEvent]);

  useLayoutEffect(() => {
    if (hasSelection) {
      if ((selectedIndexNew ?? -1) >= 0) {
        doSelection(null, selectedIndexNew, true);
      }
    }
  }, [hasSelection, selectedIndexNew, doSelection]);

  useLayoutEffect(() => {
    if (hasSelection) {
      if (!((selectedIndexNew ?? -1) >= 0)) {
        if (items?.length > 0) {
          if (utils.isDefined(internalState.selectedIndex)) {
            doSelection(null, internalState.selectedIndex, true);
          }
        }
      }
    }
  }, [hasSelection, selectedIndexNew, doSelection, items?.length, internalState.selectedIndex]);

  const handleSelectionChange = (e, idx, reason) => {
    onSelectionChange?.(e, idx, reason);
    if (!e.defaultPrevented) {
      doSelection(e, idx, reason === 'mouse');
    }
  }

  useOverflowShadow(internalState.scrollElement, overflowHeaderRef.current, overflowFooterRef.current, items?.length >= count, orientation);

  const fetchMoreOnBottomReached = useCallback(() => {
    const el = internalState.scrollElement;

    if (el) {
      const horizontal = orientation === 'horizontal';
      const scrollSize = horizontal ?
        (el === window ? document.body.scrollWidth : el.scrollWidth) :
        (el === window ? document.body.scrollHeight : el.scrollHeight);

      const scrollPos = horizontal ?
        (el === window ? window.scrollX : el.scrollLeft) :
        (el === window ? window.scrollY : el.scrollTop);
      const clientSize = horizontal ?
        (el === window ? window.innerWidth : el.clientWidth) :
        (el === window ? window.innerHeight : el.clientHeight);

      const endOfPage = ((scrollSize - scrollPos - clientSize) < (scrollPos === 0 ? 1 : (clientSize * 0.1)));

      // reset scroll state
      if ((count !== fetchingMoreRef.current.count) ||
          (scrollSize < fetchingMoreRef.current.scrollSize)) {
        fetchingMoreRef.current = {
          count: count,
          scrollSize: -1
        };
      }

      if (infiniteScroll) {
        if (!isLoading && items?.length > 0) {
          if (fetchingMoreRef.current.scrollSize !== scrollSize && items?.length < count) {
            if (endOfPage) {
              fetchingMoreRef.current.scrollSize = scrollSize;
              onFetchMore();

              return true;
            }
          }
        }
      }
    }
  }, [infiniteScroll, orientation, count, internalState.scrollElement, items?.length, isLoading, onFetchMore]);

  useEffect(() => {
    if (internalState.scrollElement) {
      utils.retry(fetchMoreOnBottomReached, 3);
    }
  }, [internalState.scrollElement, fetchMoreOnBottomReached]);

  useEffect(() => {
    if (internalState.scrollElement) {
      return utils.observeScroll(internalState.scrollElement, () => {
        fetchMoreOnBottomReached();
      });
    }
  }, [internalState.scrollElement, fetchMoreOnBottomReached]);

  useEffect(() => {
    if (internalState.scrollElement) {
      return utils.observeResize(internalState.scrollElement, () => {
        fetchMoreOnBottomReached();
      });
    }
  }, [internalState.scrollElement, fetchMoreOnBottomReached]);

  const debouncedScrollElement = useMemo(() => {
    return utils.debounce((el) => {
      if (el) {
        const element = () => {
          const scrollElement = dom.getScrollElement(el, orientation === 'horizontal', orientation === 'vertical');
          setInternalState((current) => {
            if (current.scrollElement !== scrollElement) {
              return {...current, scrollElement};
            } else {
              return current;
            }
          });

          return Boolean(scrollElement);
        }

        utils.retry(element);
      }
    }, constants.debounce.minimal, null, true);
  }, [orientation]);

  const handleRef = (el) => {
    if (el) {
      debouncedScrollElement(el);
    }
  }

  const empty = count === 0 && !isLoading;

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledDataList ref={innerRef} {...innerProps}>
    <Box ref={overflowHeaderRef} className="DataList-header" />
    <Box ref={handleRef} className="DataList-list">
      {(empty && emptyText) ? <Box className="DataList-empty">
        {utils.isReactElement(emptyText) ? emptyText :
          <P>{emptyText}</P>}
      </Box> : null}
      <List ref={listRef}
            isLoading={isLoading}
            loop={items?.length === count}
            selectedIndex={hasSelection ? (selectedIndexRef.current ?? internalState.selectedIndex) : null}
            onSelectionChange={hasSelection ? handleSelectionChange : null}
            onHighlightChange={onHighlightChange}
            orientation={orientation}
            {...ListProps}>
        {items?.map((item, idx) => {
          return <ListItem key={item[dataKey] ?? idx}
                           data-key={item[dataKey] ?? idx}
                           data-droppable={item.droppable !== false}
                           density="densest"
                           {...ListItemProps}>
            <DataListItem item={item}
                          render={renderItem}
                          selected={hasSelection ? (
                            idx === (selectedIndexRef.current ?? internalState.debouncedSelectedIndex)
                          ) : false}
                          isLoading={isLoading}/>
          </ListItem>
        })}
      </List>
    </Box>
    <Box ref={overflowFooterRef} className="DataList-footer">
      <LinearProgress className={`DataList-progressBar ${!showProgressBar ? 'hide' : ''}`}/>
    </Box>
  </StyledDataList>
});

DataList.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  count: PropTypes.number,
  data: PropTypes.array,
  selected: PropTypes.any,
  debounce: PropTypes.any,
  emptyText: PropTypes.any,
  dataKey: PropTypes.string,
  isItemEqual: PropTypes.func,
  renderItem: PropTypes.func,
  showProgressBar: PropTypes.bool,
  isLoading: PropTypes.bool,
  loaderCount: PropTypes.number,
  ListProps: PropTypes.object,
  ListItemProps: PropTypes.object,
  onSelectionChange: PropTypes.func,
  onHighlightChange: PropTypes.func,
  onFetchMore: PropTypes.func,
  selectionEnabled: PropTypes.bool,
  overflowHeader: PropTypes.bool,
  overflowFooter: PropTypes.bool
};

DataList.defaultProps = {
  debounce: false,
  orientation: 'vertical',
  loaderCount: 16,
  overflowFooter: true
};

export default DataList;
