import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Card from 'components/atoms/Cards/Card/Card';

const StyledTagGroupCard = styled(Card)`
  position: relative;
  padding: 0;
  border: unset;
  display: flex;
  box-shadow: unset;
  background-color: unset;
  flex-direction: column;
  min-height: ${props => props.theme.layout(276)};
  max-height: ${props => props.theme.layout(276)};

  .TagGroupCard {
    &-content {
      padding: ${props => props.theme.spacing(2)};
      gap: ${props => props.theme.layout('0.25sp')};
      flex-direction: column;
    }
    
    &-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: ${props => props.theme.layout('1sp')};
    }

    &-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: fit-content;
    }
    
    &-details {
      display: flex;
      flex-direction: column;
      gap: ${props => props.theme.layout('0.5sp')};
    }
    
    &-detail {
      display: flex;
      flex-wrap: nowrap;
      gap: ${props => props.theme.layout('0.5sp')};

      &-label {
        color: ${props => props.theme.property('palette.text.secondary')};
        min-width: ${props => props.theme.layout(88)};
        max-width: ${props => props.theme.layout(88)};
      }
      &-value, &-value > * {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: fit-content;
      }
    }
    
    &-tags {
      overflow: hidden;
      padding: ${props => props.theme.spacing(2)};
      border-top: 1px solid ${props => props.theme.property('palette.divider')};
      flex-grow: 1;
      
      .ChipList {
        height: 100%;
        align-items: flex-start;
        align-content: flex-start;
        column-gap: ${props => props.theme.layout('0.5sp')};
        row-gap: ${props => props.theme.layout('0.5sp')};
        
        .ChipList-overflow-wrapper {
          margin-top: ${props => props.theme.layout(-2)};
          display: block;
        }
      }
    }
  }
  
  &.TagGroupCard-compact {
    min-height: ${props => props.theme.layout(140)};
    max-height: ${props => props.theme.layout(140)};

    .TagGroupCard {
      &-info {
        flex-direction: row;
        
        > *:first-child {
          flex-grow: 1;
          max-width: unset;
        }
        
        > *:last-child {
          margin-right: -${props => props.theme.layout('1sp')};
        }
      }
      &-content {
        padding: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(2)};
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledTagGroupCard.propTypes = {
  theme: PropTypes.object
};

StyledTagGroupCard.defaultProps = {}

export default StyledTagGroupCard;
