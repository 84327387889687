import React, {useCallback, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useFirstEffect} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import Paper from 'components/atoms/Papers/Paper/Paper';
import StyledContextPopper from 'components/molecules/Poppers/ContextPopper/ContextPopper.styles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Wrapper from 'components/templates/Wrappers/Basic/Wrapper/Wrapper';
import {useLocation} from 'react-router-dom';
import Fade from '@mui/material/Fade';
import dom from 'helpers/dom';

export const PopperContext = React.createContext(null)

export function usePopper () {
  return React.useContext(PopperContext);
}

const ContextPopper = React.forwardRef((props, ref) => {
  const {
    open,
    autoClose,
    anchorEl,
    onOpen,
    onClose,
    onOpened,
    onClosed,
    onKeyDown,
    offset,
    padding,
    stretch,
    density,
    elevation,
    transparent,
    strategy,
    outlined,
    reverseShadow,
    closeOnNavigate,
    PaperProps,
    TransitionComponent,
    ...innerProps
  } = useComponentProps(props, 'ContextPopper', {
    styled: ['elevation', 'width', 'minWidth', 'maxWidth'],
    static: ['outlined', 'stretch', 'transparent', 'reverseShadow', 'fixed'],
    children: ['paper']
  });

  const innerRef = useRef();
  const paperRef = useRef();

  const [internalState, setInternalState] = useState({open: false, closed: false, clickAway: true});

  const location = useLocation();
  const firstEffect = useFirstEffect();

  const onOpenEvent = useEffectEvent(onOpen);
  const onCloseEvent = useEffectEvent(onClose);
  const doChange = useCallback((e, open, reason) => {
    setInternalState(utils.updater({open}, true));
    open ? onOpenEvent?.(e, reason) : onCloseEvent?.(e, reason);
  }, [onOpenEvent, onCloseEvent]);

  const contextPopper = useMemo(() => ({
    refs: {
      ref: innerRef,
      paperRef
    },
    state: {
      ...internalState,
      ...utils.cleanObject({open, autoClose})
    },
    open: (e, reason) => {
      doChange(e ?? utils.createEvent('click'), true, reason ?? 'openButtonClick');
    },
    close: (e, reason) => {
      doChange(e ?? utils.createEvent('click'), false, reason ?? 'closeButtonClick');
    },
    clickAway: (value) => {
      setInternalState(utils.updater({clickAway: value}, true));
    }
  }), [internalState, doChange, open, autoClose]);

  useImperativeHandle(ref, () => contextPopper);

  const modifiers = useMemo(() => {
    let modifiers = innerProps.modifiers ?? [];
    modifiers = modifiers.concat(
      !modifiers.find((m) => m.name === 'flip') ? [
        {
          name: 'flip',
          enabled: true,
          options: {
            altBoundary: false,
            fallbackPlacements: innerProps.placement === 'right-start' ? ['left-start', 'right-end', 'right', 'left', 'left-end'] :
              (innerProps.placement === 'bottom-start' ? ['top-start', 'bottom-end', 'top-end'] :
                (innerProps.placement === 'bottom-end' ? ['top-end', 'bottom-start', 'top-start'] :
                  (innerProps.placement === 'top-start' ? ['bottom-start', 'top-end', 'bottom-end'] :
                    (innerProps.placement === 'top-end' ? ['bottom-end', 'top-start', 'bottom-start'] : ['top'])
                  )
                )
              ),
            rootBoundary: 'viewport',
            padding: padding,
          },
        }
      ] : []);

    if (offset) {
      modifiers = modifiers.concat(
        !modifiers.find((m) => m.name === 'offset') ? [
          {
            name: 'offset',
            options: {
              offset: offset
            },
          }
        ] : []);
    }

    return modifiers;
  }, [offset, padding, innerProps.placement, innerProps.modifiers])

  const popperOptions = useMemo(() => {
    let popperOptions = innerProps.popperOptions ?? {};
    if (strategy) {
      popperOptions = utils.mergeObjects({strategy}, popperOptions);
    }

    return popperOptions;
  }, [strategy, innerProps.popperOptions]);

  const handleClose = (e) => {
    if (contextPopper.state.clickAway) {
      if (internalState.opened && !dom.isPartOfParent(e.target, paperRef.current)) {
        doChange(e, false, 'clickaway');
      }
    }
  };

  const handleOpened = () => {
    onOpened?.();
    setInternalState(utils.updater({opened: true}, true));
  }

  const handleClosed = () => {
    onClosed?.();
    setInternalState(utils.updater({closed: true}, true));
  }

  useLayoutEffect(() => {
    setInternalState((current) => {
      return utils.updater({
        ...current,
        open: open,
        opened: open ? current.opened : false,
        closed: open ? false : current.closed
      })(current);
    });
  }, [open]);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(contextPopper.state.open);
  useEffect(() => {
    if (prevOpen.current === true && contextPopper.state.open === false) {
      try {
        dom.focusElement(anchorEl);
      } catch {
        /* SQUASH */
      }
    }

    prevOpen.current = contextPopper.state.open;
  }, [contextPopper.state.open, anchorEl]);

  useEffect(() => {
    if (!firstEffect.current && closeOnNavigate) {
      doChange(utils.createEvent('click'), false);
    }
  }, [firstEffect, doChange, location, closeOnNavigate]);

  innerProps.style = {
    minWidth: stretch ? anchorEl?.getBoundingClientRect?.()?.width : null,
    maxWidth: stretch ? anchorEl?.getBoundingClientRect?.()?.width : null,
    ...innerProps.style
  };

  innerProps.className = utils.flattenClassName(innerProps.className);

  if (anchorEl) {
    return <StyledContextPopper ref={innerRef} {...innerProps}
                                $offset={offset}
                                transition={true}
                                anchorEl={anchorEl}
                                modifiers={modifiers}
                                popperOptions={popperOptions}
                                onKeyDown={onKeyDown}
                                open={contextPopper.state.open}>
      {({TransitionProps}) => {
        return <ClickAwayListener onClickAway={handleClose}>
          <TransitionComponent {...TransitionProps} {...innerProps.TransitionProps}
                               onEntered={handleOpened}
                               onExited={handleClosed}>
            <Paper ref={paperRef}
                   className="ContextPopper-paper"
                   onKeyDown={onKeyDown}
                   {...PaperProps}>
              <Wrapper reset={true}>
                <PopperContext.Provider value={contextPopper}>
                  {(contextPopper.state.open || !contextPopper.state.closed) ? innerProps.children : null}
                </PopperContext.Provider>
              </Wrapper>
            </Paper>
          </TransitionComponent>
        </ClickAwayListener>
      }}
    </StyledContextPopper>
  }
});

ContextPopper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  open: PropTypes.bool,
  clickAway: PropTypes.bool,
  offset: PropTypes.array,
  stretch: PropTypes.bool,
  elevation: PropTypes.number,
  outlined: PropTypes.bool,
  transparent: PropTypes.bool,
  closeOnNavigate: PropTypes.bool,
  padding: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object
  ]),
  transitionType: PropTypes.oneOf(['fade', 'grow']),
  anchorEl: PropTypes.any,
  disablePortal: PropTypes.bool,
  reverseShadow: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onOpened: PropTypes.func,
  onClosed: PropTypes.func,
  onKeyDown: PropTypes.func,
  PaperProps: PropTypes.object,
  TransitionComponent: PropTypes.elementType,
  TransitionProps: PropTypes.object,
};

ContextPopper.defaultProps = {
  children: 'Popper text',
  TransitionComponent: Fade,
  disablePortal: false,
  placement: 'bottom-start',
  padding: 20,
  elevation: 8,
  outlined: true,
  reverseShadow: true
};

export default ContextPopper;
