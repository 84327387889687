import React, {useCallback, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledSwitchTeamDialog from 'components/organisms/Dialogs/SwitchTeamDialog/SwitchTeamDialog.styles';
import {
  useAuth,
  useAuthClientId,
  useAuthIsProxy
} from 'services/auth/auth.utils';
import Add from '@mui/icons-material/Add';
import SwitchTeamForm from 'components/organisms/Forms/SwitchTeamForm/SwitchTeamForm';
import {H6, Span} from 'components/atoms/Text/Typography/Typography';
import utils from 'helpers/utils';
import {useAuthPassLogout} from 'services/auth/auth.hooks';
import {useAuthControl, useAuthTeams, useAuthUser} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import constants from 'helpers/constants';
import CircularProgress from 'components/atoms/Progress/CircularProgress/CircularProgress';
import Box from 'components/atoms/Layout/Box/Box';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import TeamAddDialog from 'components/organisms/Dialogs/TeamAddDialog/TeamAddDialog';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import {useClientTeamCreate} from 'services/client/team/team.utils';
import {useClientTeamMemberAdd} from 'services/client/team/member/member.hooks';
import {useLinkNavigate} from 'helpers/hooks/links';
import {useLocation} from 'react-router-dom';

const SwitchTeamDialog = React.forwardRef((props, ref) => {
  const {
    onClose,
    ...innerProps
  } = useComponentProps(props, 'SwitchTeamDialog');

  const authControl = useAuthControl();

  const [internalState, setInternalState] = useState({
    switching: false,
    error: null
  });

  const innerRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  const auth = useAuth();
  const user = useAuthUser();
  const teams = useAuthTeams();
  const clientId = useAuthClientId();
  const isProxy = useAuthIsProxy();
  const passLogout = useAuthPassLogout();

  const dialogControl = useDialogControl();
  const snackbar = useSnackbar();
  const location = useLocation();
  const navigate = useLinkNavigate();

  const teamAdd = useClientTeamCreate();
  const addTeamMember = useClientTeamMemberAdd();

  const doRedirect = useCallback(() => {
    navigate({
      to: utils.validRedirect(location),
      replace: true
    });
  }, [location, navigate]);

  const buttonActions = useMemo(() => {
    const buttons = [];

    if (isProxy) {
      buttons.push({
        onClick: () => {
          doRedirect();
          authControl.setSuspended(true);
          setInternalState(utils.updater({
            switching: true,
            subject: 'client'
          }, true));
          passLogout.mutation.mutate({authId: auth?.authId});
        },
        label: 'Leave',
        ActionButtonProps: {
          variant: 'text'
        },
        ButtonProps: internalState.switching ? {
          disabled: internalState.switching
        } : null
      })
    }

    buttons.push({
      auth: utils.createAuth({attribute: 'team.create'}),
      onClick: (e) => {
        const handleSubmit = (team) => {
          return teamAdd(clientId, team)
            .then((res) => {
              const newTeam = utils.camelcase(res.response.data.data);
              if (team.isPrivate) {
                return addTeamMember.mutation.mutateAsync({
                  clientId,
                  teamId: newTeam.clientId,
                  userId: user?.userId
                }).then(() => {
                  dialogControl.hide();
                  setInternalState(utils.updater({newTeamId: newTeam.clientId}, true));
                });
              } else {
                dialogControl.hide();
                setInternalState(utils.updater({newTeamId: newTeam.clientId}, true));
              }
            });
        };

        dialogControl.show(<TeamAddDialog onSubmit={handleSubmit}/>, true);

        e.preventDefault();
      },
      color: 'success',
      label: 'Add team',
      icon: Add,
      iconPosition: 'start',
      ButtonProps: internalState.switching ? {
        disabled: internalState.switching
      } : null
    });

    return buttons;
  }, [authControl, dialogControl, clientId, user?.userId, isProxy, teamAdd, doRedirect,
    addTeamMember.mutation, internalState.switching, passLogout.mutation, auth?.authId]);

  const handleSuccess = () => {
    authControl.setSuspended(false);
    innerRef.current?.close?.(null, 'switched');
  };

  const handleSwitching = (switching) => {
    doRedirect();
    setInternalState(utils.updater({
      error: null,
      switching,
      subject: 'team'
    }, true));
    authControl.setSuspended(switching);
  };

  const handleError = (error) => {
    setInternalState(utils.updater({
      switching: false,
      error
    }, true));
    authControl.setSuspended(false);
  };

  useEffect(() => {
    if (passLogout.status.error) {
      setInternalState(utils.updater({
        switching: false,
        error: 'Leave failed'
      }, true));
      authControl.setSuspended(false);

      if (passLogout.status.error.response?.status === constants.http.status.forbidden) {
        snackbar.show('Your session has expired', null,
          {color: 'warning', autoHideDuration: constants.delay.warning});

        navigate({to: '/auth/logout', resetState: true});
        innerRef.current?.close?.(null, 'switched');
      }
    }
  }, [authControl, snackbar, navigate, auth?.authId, passLogout.status.error]);

  useLayoutEffect(() => {
    const switched = +user?.clientId === 0;
    if (switched) {
      authControl.setSuspended(false);
      innerRef.current?.close?.(null, 'switched');
    }
  }, [authControl, user?.clientId]);

  useEffect(() => {
    const team = teams?.find((team) => +team.clientId === +internalState.newTeamId);
    if (team) {
      setInternalState(utils.updater({switchTeamId: team.clientId}, true));
    }
  }, [teams, internalState.newTeamId]);

  const handleClose = (e, reason) => {
    if (!internalState.switching || reason === 'switched') {
      onClose?.(e, reason);
    }
  }

  return <StyledSwitchTeamDialog ref={innerRef} {...innerProps}
                                 $switching={internalState.switching}
                                 title={'Select a team'}
                                 onClose={handleClose}
                                 info={internalState.error ? <Span color="error">{internalState.error}</Span> : null}
                                 form={<Box className="SwitchTeamDialog-form-wrapper">
                                   <Box className="SwitchTeamDialog-loading">
                                     <CircularProgress />
                                     <H6>Preparing {internalState.subject} data</H6>
                                   </Box>
                                   <SwitchTeamForm className="SwitchTeamDialog-form"
                                                   teamId={internalState.switchTeamId}
                                                   autoFocus={true}
                                                   onSwitching={handleSwitching}
                                                   onSuccess={handleSuccess}
                                                   onError={handleError} />
                                 </Box>}
                                 buttonActions={buttonActions} />
});

SwitchTeamDialog.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  onClose: PropTypes.func
};

SwitchTeamDialog.defaultProps = {
};

export default SwitchTeamDialog;
