import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import BarChart from 'components/organisms/Charts/BarChart/BarChart';
import Legend from 'components/molecules/Charts/Legend/Legend';
import Box from 'components/atoms/Layout/Box/Box';
import StyledCompletenessBarChart from 'components/organisms/Charts/CompletenessBarChart/CompletenessBarChart.styles';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import BreakdownTooltip from 'components/molecules/Tooltips/BreakdownTooltip/BreakdownTooltip';

const CompletenessBarChart = React.forwardRef((props, ref) => {
  const {
    completeness,
    fields,
    showLegend,
    isLoading,
    visibility,
    onClick,
    onVisibilityChange,
    BarChartProps,
    ...innerProps
  } = useComponentProps(props, 'CompletenessBarChart', {
    children: ['chart', 'legend']
  });

  const [internalState, setInternalState] = useState({});

  const completenessMemo = useMemo(() => {
    if (fields && completeness) {
      return fields.map((field, idx) => {
        const total = completeness.total ?? 0;
        const value = +(completeness.completeness[field.value] ?? 0);

        const completePercentage = total > 0 ? Math.round((value / total) * 100) : 0;
        return {
          id: field.value,
          name: field.value,
          label: field.label,
          position: idx,
          breakdown: [
            {
              id: 'complete',
              label: 'Complete',
              position: 1,
              count: completePercentage,
              countLabel: `${value} companies`,
              icon: Check,
              iconColor: 'success',
              color: innerProps.theme.property('palette.success.main')
            },
            {
              id: 'incomplete',
              label: 'Incomplete',
              position: 2,
              count: 100 - completePercentage,
              countLabel: `${total - value} companies`,
              icon: Close,
              iconColor: 'error',
              color: innerProps.theme.property('palette.error.main')
            }
          ]
        }
      });
    } else {
      return [];
    }
  }, [fields, completeness, innerProps.theme]);

  const [legend, rows] = useMemo(() => {
    let legend = [];
    if (completenessMemo) {
      legend = completenessMemo?.[0]?.breakdown
        .map((bd) => {
          return {
            id: bd.id,
            position: bd.position,
            label: bd.label,
            color: bd.color,
            active: visibility?.[bd.id] ?? true,
            meta: bd
          }
        }) ?? [];
    }

    const rows = Math.ceil(legend.length / 4);

    return [legend, rows];
  }, [completenessMemo, visibility]);

  const completenessData = useMemo(() => {
    if (completenessMemo) {
      return {
        dataKey: 'name',
        layout: 'vertical',
        bars: completenessMemo?.[0]?.breakdown
          .map((bd) => {
            return {
              id: bd.id,
              name: bd.id,
              position: bd.position,
              dataKey: bd.id,
              label: bd.label,
              color: bd.color
            }
          })
          .filter((bd) => {
            return Boolean(legend.find((l) => l.active && l.id === bd.id))
          }),
        data: completenessMemo
          .reduce((a, c) => {
            let item = a.find((i) => i.id === c.id);
            if (!item) {
              item = {
                id: c.id,
                name: c.label,
                position: c.position
              };
              a.push(item);
            }

            c.breakdown.forEach((bd) => {
              if (Boolean(legend.find((l) => l.active && l.id === bd.id))) {
                item[bd.id] = bd.count;
                item[`${bd.id}-meta`] = bd
              }
            });

            return a;
          }, [])
          .sort((a, b) => a.position - b.position),
        TooltipComponent: BreakdownTooltip
      };
    }
  }, [completenessMemo, legend]);

  const handleLegendEnter = (e, item) => {
    if (item.active) {
      setInternalState(utils.updater({hoveredId: item.id}, true));
    }
  }

  const handleLegendLeave = () => {
    setInternalState(utils.updater({hoveredId: null}, true));
  }

  const handleLegendClick = (e, item) => {
    const newVisibility = {
      ...visibility,
      [item.id]: !(visibility?.[item.id] ?? true)
    }

    if (!legend.find((l) => newVisibility[l.id] ?? true)) {
      onVisibilityChange?.({});
    } else {
      onVisibilityChange?.(newVisibility);
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledCompletenessBarChart ref={ref} {...innerProps}>
    <Box className="CompletenessBarChart-chart">
      <BarChart {...completenessData}
                showGrid
                showTooltip
                showCursor
                isLoading={isLoading}
                radius={4}
                barSize={40}
                layout="horizontal"
                hoveredId={internalState.hoveredId}
                onClick={onClick}
                TooltipProps={{
                  variant: 'breakdown',
                  placement: 'right',
                  gap: 12,
                  isPercentage: true
                }}
                margin={{
                  top: 0, right: 0, bottom: 4, left: -12
                }}
                YAxisProps={{
                  domain: [0, 100],
                  tickFormatter: (v) => {
                    return `${v} %`;
                  }
                }}
                {...BarChartProps}/>
    </Box>
    {showLegend ? <Legend className="CompletenessBarChart-legend"
                          onClick={onVisibilityChange ? handleLegendClick : null}
                          onMouseEnter={handleLegendEnter}
                          onMouseLeave={handleLegendLeave}
                          legend={legend}
                          isLoading={isLoading}
                          rows={rows}/> : null}
  </StyledCompletenessBarChart>
});

CompletenessBarChart.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  completeness: PropTypes.object,
  fields: PropTypes.array,
  showLegend: PropTypes.bool,
  isLoading: PropTypes.bool,
  visibility: PropTypes.object,
  onClick: PropTypes.func,
  onVisibilityChange: PropTypes.func,
  BarChartProps: PropTypes.object
};

CompletenessBarChart.defaultProps = {
};

export default CompletenessBarChart;
