import {
  useInfiniteQuery,
  useMutation as useMutationBase,
  useQuery as useQueryBase,
  useQueryClient
} from '@tanstack/react-query';
import {useServiceData, useServiceDataById} from 'stores/hooks/store';
import {useApi} from 'helpers/hooks/api';
import {useServiceStore} from 'components/organisms/Providers/ServiceProvider/ServiceProvider';
import utils from 'helpers/utils';
import constants from 'helpers/constants';
import {useEffectEventStable, useEffectItem, useUpdatedRef} from 'helpers/hooks/utils';
import {useMemo} from 'react';

function useQuery (path, keys, fn, options = {}) {
  const {
    initialDataId,
    dataContext,
    forceNewData,
    overrideApiToken,
    infiniteQuery,
    ...otherOptions
  } = options;

  const http = useApi(overrideApiToken);
  const store = useServiceStore(path);
  const queryClient = useQueryClient();

  const keysMemo = useEffectItem(keys);
  const uniqueId = useMemo(() => {
    return utils.sha1({keys: keysMemo, now: Date.now()});
  }, [keysMemo]);

  const context = store.apiPathContext(dataContext.apiPath, dataContext.dataType,
    {...dataContext.apiParams, ...dataContext.apiParams?.$httpParams}, dataContext.extra);

  const wrapped = ({signal, ...params}) => {
    const enabled = options.enabled !== false;
    if (enabled) {
      return utils.asPromise(fn)(http(signal), params)
        .then((response) => {
          return {
            context,
            response
          }
        });
    } else {
      return Promise.resolve({
        context,
        response: {
          meta: utils.responseMeta(null),
          data: null
        }
      });
    }
  };

  // initialDataId = loaded from memory
  const initialData = useServiceDataById(path, initialDataId, context);
  const initialDataOptions = (initialData && !forceNewData) ? {
    initialData: {
      context,
      response: {
        data: initialData?.data,
        meta: {time: initialData?.meta?.dataAt, initialData: true}
      }
    },
    initialDataUpdatedAt: (!initialData?.meta?.dataAt || initialData.meta?.invalid) ? 1 : initialData.meta.dataAt.getTime()
  } : null;

  const storeCallbacks = {}; // will be filled in later by useServiceData
  const queryOptions = {
    ...otherOptions,
    onSuccess: (data) => {
      storeCallbacks?.onSuccess?.(data);
      otherOptions?.onSuccess?.(data);
    },
    onError: (error) => {
      const handledStore = storeCallbacks?.onError?.(error);
      const handledOther = otherOptions?.onError?.(error);

      if (!handledStore && !handledOther) {
        queryClient.defaultQueryOptions().onError?.(error);
      }
    },
    onSettled: (data, error) => {
      storeCallbacks?.onSettled?.(data, error);
      otherOptions?.onSettled?.(data, error);
    }
  }

  const useQFn = infiniteQuery ? useInfiniteQuery : useQueryBase;
  const queryKey = [path, 'query'].concat(keys)
    .concat(infiniteQuery ? ['infinite'] : [])
    .concat(forceNewData ? [uniqueId] : []);
  const query = useQFn(queryKey, wrapped, {
    ...initialDataOptions,
    ...queryOptions
  });

  const stored = useServiceData(path, queryKey.slice(1), query, {
    ...otherOptions,
    storeCallbacks
  });

  const queryRef = useUpdatedRef(query);
  const queryKeyMemo = useEffectItem(queryKey);
  const removeEvent = useEffectEventStable(query.remove);
  const refetchEvent = useEffectEventStable(query.refetch);
  const fetchNextPageEvent = useEffectEventStable(query.fetchNextPage);
  const queryMemo = useMemo(() => {
    return {
      queryKey: queryKeyMemo,
      remove: removeEvent,
      refetch: refetchEvent,
      fetchNextPage: () => {
        if (queryRef.current.hasNextPage) {
          fetchNextPageEvent?.();
        }
      }
    }
  }, [queryKeyMemo, removeEvent, refetchEvent, queryRef, fetchNextPageEvent]);

  return useMemo(() => {
    return {query: queryMemo, ...stored};
  }, [queryMemo, stored]);
}

function useMutation (path, keys, fn, options = {}) {
  const {
    dataContext,
    overrideApiToken,
    ...otherOptions
  } = options;

  const http = useApi(overrideApiToken);
  const store = useServiceStore(path);
  const queryClient = useQueryClient();

  const wrapped = (variables = {}) => {
    const {
      $body,
      $httpParams,
      ...data
    } = variables;

    const context = store.apiPathContext(dataContext.apiPath, dataContext.dataType,
      {...dataContext.apiParams, ...data, ...$body, ...$httpParams}, dataContext.extra);

    const enabled = options.enabled !== false;
    if (enabled) {
      return utils.asPromise(fn)(http(), $body ?? data, $httpParams)
        .then((response) => {
          return {
            context,
            response
          }
        });
    } else {
      return Promise.resolve({
        context,
        response: {
          meta: utils.responseMeta(null),
          data: null
        }
      });
    }
  };

  const storeCallbacks = {}; // will be filled in later by useServiceData
  const mutationOptions = {
    ...otherOptions,
    onMutate: (variables) => {
      storeCallbacks?.onMutate?.(variables);
      otherOptions?.onMutate?.(variables);
    },
    onSuccess: (data, variables, context) => {
      storeCallbacks?.onSuccess?.(data, variables, context);
      otherOptions?.onSuccess?.(data, variables, context);
    },
    onError: (error, variables, context) => {
      const handledStore = storeCallbacks?.onError?.(error, variables, context);
      const handledOther = otherOptions?.onError?.(error, variables, context);

      if (!handledStore && !handledOther) {
        queryClient.defaultMutationOptions().onError?.(error, variables, context);
      }
    },
    onSettled: (data, error, variables, context) => {
      storeCallbacks?.onSettled?.(data, error, variables, context);
      otherOptions?.onSettled?.(data, error, variables, context);
    }
  }

  const mutation = useMutationBase(wrapped, mutationOptions);

  const stored = useServiceData(path, ['mutation'].concat(keys), mutation, {
    ...otherOptions,
    mutation: true,
    storeCallbacks,
  });

  const mutateEvent = useEffectEventStable(mutation.mutate);
  const mutateAsyncEvent = useEffectEventStable(mutation.mutateAsync);
  const mutationMemo = useMemo(() => {
    return {
      mutate: mutateEvent,
      mutateAsync: mutateAsyncEvent
    }
  }, [mutateEvent, mutateAsyncEvent]);

  return useMemo(() => {
    return {mutation: mutationMemo, ...stored};
  }, [mutationMemo, stored]);
}

export function useServiceApiQuery (path, fn, apiParams = {}, queryOptions = {}) {
  const store = useServiceStore(path);

  const key = store.key;
  const initialDataId = apiParams?.[key];

  const {
    lookupKey,
    overrideKeys,
    overridePath,
    overrideDataType,
    ...otherOptions
  } = queryOptions;

  const name = fn;

  fn = store.api.query[fn];
  let {
    dataType = constants.dataTypes.entity,
    ...actionOptions
  } = fn(apiParams, {...otherOptions, calculateOptions: true}).options || {};
  dataType = overrideDataType ?? dataType;

  const apiPath = store.apiPath(dataType, overridePath);
  const context = store.apiPathContext(apiPath, dataType, apiParams, {name});
  apiParams = {...apiParams, path: utils.resolvePath(apiPath, apiParams, true)};

  fn = fn(utils.filterObject(apiParams, context.keys), otherOptions).action;

  const keys = overrideKeys ? overrideKeys : (lookupKey ? [lookupKey] : [])
    .concat([name, apiParams])
    .concat(overridePath ? [overridePath] : []);

  return useQuery(path, keys, fn, {
    initialDataId,
    dataContext: {
      apiPath,
      apiParams,
      dataType,
      extra: { name }
    },
    ...actionOptions,
    ...otherOptions
  });
}

export function useServiceApiMutation (path, fn, apiParams = {}, mutationOptions = {}) {
  const store = useServiceStore(path);

  const {
    lookupKey,
    overrideKeys,
    overridePath,
    overrideDataType,
    ...otherOptions
  } = mutationOptions;

  const name = fn;

  fn = store.api.mutation[fn];
  let {
    dataType = constants.dataTypes.entity,
    ...actionOptions
  } = fn(apiParams, {...otherOptions, calculateOptions: true}).options || {};
  dataType = overrideDataType ?? dataType;

  const apiPath = store.apiPath(dataType, overridePath);
  apiParams = {...apiParams, path: utils.resolvePath(apiPath, apiParams, true)};

  fn = fn(apiParams, otherOptions).action;

  const keys = overrideKeys ? overrideKeys : (lookupKey ? [lookupKey] : [])
    .concat([name, apiParams])
    .concat(overridePath ? [overridePath] : []);

  return useMutation(path, keys, fn, {
    dataContext: {
      apiPath,
      apiParams,
      dataType,
      extra: { name }
    },
    ...actionOptions,
    ...otherOptions
  });
}
