import utils from 'helpers/utils';
import logger from 'helpers/logger';
import ServiceStore from 'stores/service.store';
import constants from 'helpers/constants';
import {processTimelineItem} from 'services/client/timeline/timeline.utils';

const path = utils.servicePath(import.meta.url);

export class EntityTimelineService extends ServiceStore {
  constructor (app, callbacks) {
    logger.trace(`Starting module at: services/${path}`);

    super(path, app, {
      list: 'timeline',
      refreshEnabled: false,
      priority: ['comment', 'task'],
      watch: ['user', 'comment', 'collection', 'entity', 'task', 'export', 'upload'],
      match: (cache, entity, id) => {
        // match multiple things for invalidation
        // refetchContext reacts to entityId since it's the parent
        return (entity === 'user' && +cache.data?.userId === +id) ||
          (entity === 'comment' && +cache.data?.commentId === +id) ||
          (entity === 'collection' && +cache.data?.collectionId === +id) ||
          (entity === 'entity' && (
            +cache.data?.relationId === +id ||
            +cache.data?.entity?.stackId === +id ||
            +cache.data?.entity?.redirectStackId === +id
          )) ||
          (['export', 'task', 'upload'].includes(entity) && +cache.data?.relationId === +id);
      },
      process: (data, meta) => {
        if (meta?.context?.$store?.dataType !== constants.dataTypes.other) {
          if (utils.isArray(data)) {
            return data.map(processTimelineItem);
          } else {
            return processTimelineItem(data);
          }
        } else {
          return data;
        }
      }
    }, callbacks);
  }
}
