import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import StyledEntitiesQueryBar from 'components/organisms/Bars/EntitiesQueryBar/EntitiesQueryBar.styles';

const EntitiesQueryBar = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'EntitiesQueryBar');

  const tableProvider = useTable();
  const fieldData = tableProvider.fieldData;
  const filterGroupDefinitions = tableProvider.filterGroupDefinitions;

  const handleChange = (query, search, wizard, examples) => {
    tableProvider.listState.setSearch(search);
    tableProvider.listState.setFilter((current) => {
      if (wizard && examples?.length > 0) {
        return utils.applyFilters(current, [{id: 'wizard', value: wizard}, {id: 'examples', value: examples}], ['wizard', 'examples']);
      } else if (wizard) {
        return utils.applyFilters(current, [{id: 'wizard', value: wizard}, {id: 'examples', value: null}], ['wizard', 'examples']);
      } else if (examples) {
        return utils.applyFilters(current, [{id: 'wizard', value: null}, {id: 'examples', value: examples}], ['wizard', 'examples']);
      }
    });
    tableProvider.listState.setQuery(query);
  }

  const [wizard, examples, filter] = useMemo(() => {
    const wizardFilter = (tableProvider.listState.filter ?? []).find((f) => f.id === 'wizard');
    const wizard = wizardFilter ? (utils.toArray(wizardFilter.value, true)?.[0] ?? '') : '';
    const examplesFilter = (tableProvider.listState.filter ?? []).find((f) => f.id === 'examples');
    const examples = examplesFilter ? (utils.toArray(examplesFilter.value, true) ?? []) : [];
    const filter = (tableProvider.listState.filter ?? []).filter((f) => f.id !== 'wizard' && f.id !== 'examples');

    return [wizard, examples, filter];
  }, [tableProvider.listState.filter]);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledEntitiesQueryBar ref={ref} {...innerProps}
                                 search={tableProvider.listState.search}
                                 filter={filter}
                                 wizard={wizard}
                                 examples={examples}
                                 active={tableProvider.listState.active}
                                 query={tableProvider.listState.query}
                                 filterGroups={filterGroupDefinitions}
                                 fieldData={fieldData}
                                 onChange={handleChange} />
});

EntitiesQueryBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

EntitiesQueryBar.defaultProps = {
};

export default EntitiesQueryBar;
