import React, {useEffect, useImperativeHandle, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import constants from 'helpers/constants';
import utils from 'helpers/utils';
import {useCollectionTimelineList} from 'services/collection/timeline/timeline.hooks';
import {useCommentMarkAllRead} from 'services/comment/comment.hooks';
import StyledCollectionNotificationList
  from 'components/organisms/Lists/CollectionNotificationList/CollectionNotificationList.styles';
import TimelineList from 'components/organisms/Lists/TimelineList/TimelineList';
import {useTimelineMarkAllRead} from 'services/client/timeline/timeline.hooks';
import {useAuthClientId, useAuthUserId} from 'services/auth/auth.utils';
import {usePopper} from 'components/molecules/Poppers/ContextPopper/ContextPopper';

const CollectionNotificationList = React.forwardRef((props, ref) => {
  const {
    collection,
    ...innerProps
  } = useComponentProps(props, 'CollectionNotificationList');

  const innerRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  const popper = usePopper();

  const clientId = useAuthClientId();
  const userId = useAuthUserId();

  const collectionCommentAllRead = useCommentMarkAllRead();
  const collectionTimelineAllRead = useTimelineMarkAllRead();

  const timeline = useCollectionTimelineList({
    collectionId: collection?.collectionId,
    page: 0,
    pageSize: 15,
    filter: [
      {id: 'notification', value: false},
      {id: 'actionlist', value: true},
      {id: 'excludedTypes', value: ['additions', 'status', 'csi', 'deal_leader']},
    ]
  }, {
    ...constants.queryOptions.infinite,
    enabled: collection?.collectionId > 0
  });
  const isLoading = timeline.status?.isLoading || !utils.isDefined(timeline.data);

  useEffect(() => {
    const scroll = () => {
      return innerRef.current?.refs?.ref?.current?.querySelector('.DataList-list')?.scrollTo({top: 0, left: 0});
    }

    utils.retry(scroll, 3);
  }, [collection?.collectionId]);

  useEffect(() => {
    const unreadComments = timeline.data?.filter?.((ti) => ti.recordType === 'comment' && ti.isRead === false)
    if (unreadComments?.length > 0) {
      collectionCommentAllRead.mutation.mutate({
        collectionId: collection?.collectionId,
        group: true,
        commentIds: unreadComments.map((d) => d.commentId)
      });
    }
    const unreadTasks = timeline.data?.filter?.((ti) => ti.relationType === 'task' && +ti.userId === +userId && ti.isRead === false)
    if (unreadTasks?.length > 0) {
      collectionTimelineAllRead.mutation.mutate({
        clientId: clientId,
        collectionId: collection?.collectionId,
        taskIds: unreadTasks.map((d) => d.relationId)
      });
    }
  }, [clientId, userId, collectionCommentAllRead.mutation, collectionTimelineAllRead.mutation, timeline.data, collection?.collectionId]);

  const isItemEqual = (update, selected) => {
    return +update.timelineId === +selected.timelineId;
  }

  const handleEdit = (e, item, editing) => {
    if (item?.timelineType === 'task') {
      popper?.close?.();
    }  else {
      popper?.clickAway(!editing);
      e?.stopPropagation();
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledCollectionNotificationList ref={innerRef} {...innerProps}>
    <TimelineList count={timeline?.meta?.resultsCount}
                  timeline={timeline.data}
                  loaderCount={4}
                  dataKey="timelineId"
                  emptyText="No notifications found"
                  isItemEqual={isItemEqual}
                  onFetchMore={timeline.query?.fetchNextPage}
                  onEdit={handleEdit}
                  isLoading={isLoading}
                  showProgressBar={timeline.status?.isLoadingNext}/>
  </StyledCollectionNotificationList>
});

CollectionNotificationList.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  collection: PropTypes.object
};

CollectionNotificationList.defaultProps = {};

export default CollectionNotificationList;
