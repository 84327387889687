import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectItem} from 'helpers/hooks/utils';
import StyledFilesList from 'components/organisms/Lists/FilesList/FilesList.styles';
import FileCard from 'components/molecules/Cards/FileCard/FileCard';
import Component from 'components/organisms/Utils/Component/Component';

const FilesList = React.forwardRef((props, ref) => {
  const {
    files,
    Card,
    onClick,
    isLoading,
    FileCardProps,
    ...innerProps
  } = useComponentProps(props, 'FilesList');

  const FileCardDefault = ({file, ...props}) => {
    return <FileCard file={file}
                     fullWidth={true}
                     {...props}/>
  };

  const CardMemo = useEffectItem(Card ?? FileCardDefault);
  const renderCard = (file, state) => {
    return <Component Original={CardMemo}
                      file={file}
                      selected={state.selected}
                      onClick={onClick}
                      isLoading={state.isLoading}
                      {...FileCardProps} />
  };

  const isItemEqual = (file, selected) => {
    return +file.uploadId === +selected.uploadId;
  }

  return <StyledFilesList ref={ref} {...innerProps}
                          data={files}
                          count={files?.length}
                          dataKey="uploadId"
                          isItemEqual={isItemEqual}
                          renderItem={renderCard}
                          isLoading={isLoading}
                          showProgressBar={false}
                          onFetchMore={null}
                          ListProps={{
                            track: true,
                            virtualize: true,
                            estimatedSize: 68,
                            gap: 8,
                          }}/>
});

FilesList.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  files: PropTypes.array,
  Card: PropTypes.any,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  FileCardProps: PropTypes.object
};

FilesList.defaultProps = {
  loaderCount: 8
};

export default FilesList;
