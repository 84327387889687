import React, {useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useEffectItem} from 'helpers/hooks/utils';
import Table from 'components/organisms/Tables/Table/Table';
import TextTableCell from 'components/molecules/TableCells/TextTableCell/TextTableCell';
import utils from 'helpers/utils';
import Logo from 'components/atoms/Logos/Logo/Logo';
import HomeWorkOutlined from '@mui/icons-material/HomeWorkOutlined';
import FieldTableCell from 'components/molecules/TableCells/FieldTableCell/FieldTableCell';
import constants from 'helpers/constants';
import StyledEntitiesAnalyseMatchWizardContent
  from 'components/organisms/WizardContent/EntitiesAnalyseMatchWizardContent/EntitiesAnalyseMatchWizardContent.styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useAnalyseQuestion} from 'services/analyse/analyse.hooks';
import {processEntityInfo} from 'services/entity/entity.utils';
import Box from 'components/atoms/Layout/Box/Box';
import Typography, {H6} from 'components/atoms/Text/Typography/Typography';
import ScoreLinearProgress from 'components/organisms/Progress/ScoreLinearProgress/ScoreLinearProgress';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import SplitBar from 'components/organisms/Bars/SplitBar/SplitBar';
import {Downloading} from '@mui/icons-material';
import Legend from 'components/molecules/Charts/Legend/Legend';

const FieldCell = React.forwardRef(({table, row, column, cell, columnDef, fieldData}, ref) => {
  const entity = cell.row.original;
  const field = utils.initializeFormFields([columnDef], entity)[0];
  field.initial = cell.column.columnDef.optimistic.get(cell, field.initial);

  field.info = processEntityInfo(entity, field, field.initial?.value ?? field.initial);

  field.FormFieldProps = field.FormFieldProps ?? {};
  if (field.tagGroup) {
    field.FormFieldProps.ChipProps = {
      ...field.FormFieldProps.ChipProps,
      size: 'medium'
    };
  }

  return <FieldTableCell ref={ref} fields={[field]} fieldData={fieldData}/>
});

const EntitiesAnalyseMatchWizardContent = React.forwardRef((props, ref) => {
  const {
    wizard,
    step,
    collection,
    tableProvider,
    onComplete,
    onError,
    onDirty,
    ...innerProps
  } = useComponentProps(props, 'EntitiesAnalyseMatchWizardContent');

  const innerRef = useRef(null);
  const loadingRef = useRef(null);

  const state = wizard.getStepState();
  const data = wizard.data?.[wizard.dataKey];
  const setDataEvent = useEffectEvent(wizard.setData);
  const setStateEvent = useEffectEvent(wizard.setStepState);

  const questionData = data?.[data?.questionHash];
  const resultsCount = Math.min(tableProvider.listSelection.max, tableProvider.list?.meta?.resultsCount);
  
  const done = Math.min((questionData?.pointer ?? 0) - (questionData?.batch * constants.analyse.batchSizes.match), constants.analyse.batchSizes.match);
  const left = Math.min(resultsCount - (questionData?.batch * constants.analyse.batchSizes.match), constants.analyse.batchSizes.match);
  const batchProgress = (done / left) * 100;
  const totalDone = (data?.entityIds ?? [])
    .slice(0, ((questionData?.batch + 1) * constants.analyse.batchSizes.match))
    .filter((entityId) => utils.isDefined(questionData?.evidences?.[entityId])).length;
  //const totalProgress = (totalDone / resultsCount) * 100;
  const calculating = batchProgress < 100;

  const entitiesLoading = Object.keys(data?.entities || {})
    .filter((entityId) => data?.entities?.[entityId]?.questionHash === data?.questionHash).length === 0;

  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const snackbar = useSnackbar();
  const listState = useEffectItem(tableProvider.appliedListState());
  const entitySelectedEvent = useEffectEvent(tableProvider.updaters?.selectedItems);

  const analyseQuestion = useAnalyseQuestion();

  useEffect(() => {
    if (!utils.isDefined(data?.entityIds)) {
      entitySelectedEvent?.(listState, 0, resultsCount, null, true,
        (entities) => {
          setDataEvent?.((current) => ({
            ...current,
            [wizard.dataKey]: {
              ...current[wizard.dataKey],
              entityIds: (current?.[wizard.dataKey]?.entityIds || [])
                .concat(entities.map((e) => e.entityId))
            }
          }));
        }, () => {
          snackbar.show('Retrieving company data failed', null,
            {color: 'error', autoHideDuration: constants.delay.error});
        });
    }
  }, [snackbar, entitySelectedEvent, listState, data?.entityIds, resultsCount, wizard.dataKey, setDataEvent]);

  useEffect(() => {
    if (utils.isDefined(questionData?.batch) &&
        (questionData?.batch * constants.analyse.batchSizes.match) < data?.entityIds?.length &&
        (questionData?.batch * constants.analyse.batchSizes.match) >= (data?.loadCount ?? 0) &&
        (questionData?.batch * constants.analyse.batchSizes.match) < resultsCount) {
      const entityIds = data?.entityIds.slice(questionData?.batch * constants.analyse.batchSizes.match, (questionData?.batch + 1) * constants.analyse.batchSizes.match);

      entitySelectedEvent?.(listState, 0, constants.analyse.batchSizes.match, entityIds, false,
        (entities) => {
          setDataEvent?.((current) => ({
            ...current,
            [wizard.dataKey]: {
              ...current[wizard.dataKey],
              loadCount: (current?.[wizard.dataKey]?.loadCount ?? 0) + entityIds.length,
              entities: {
                ...current?.[wizard.dataKey]?.entities,
                ...entities.reduce((o, entity) => {
                  return {...o, [entity.entityId]: entity};
                }, {})
              }
            }
          }));
        }, () => {
          snackbar.show('Retrieving company data failed', null,
            {color: 'error', autoHideDuration: constants.delay.error});
        });
    }
  }, [questionData?.batch, data?.loadCount, data?.entityIds, snackbar, entitySelectedEvent, listState,
    resultsCount, wizard.dataKey, setDataEvent]);

  const onCompleteEvent = useEffectEvent(onComplete);
  const matchColumnsWizardContent = useMemo(() => ({
    refs: {
      ref: innerRef,
    },
    submit: () => {
      onCompleteEvent?.(Boolean(step?.resetNextSteps));
    }
  }), [step?.resetNextSteps, onCompleteEvent]);

  useImperativeHandle(ref, () => matchColumnsWizardContent);

  const fieldData = useMemo(() => {
    let description = `How well does the profile match this text: "${data?.description}"?`;
    description += ' (perfect: very good match, good: good match, fair: reasonable match, poor: poor match, terrible: very bad match, unclear: do not know)'

    return {
      tagGroups: [{
        groupId: -1,
        name: 'match',
        multiselect: false,
        color: innerProps.theme.property('palette.primary.main'),
        hasPoints: false,
        autoTagType: data?.matchType,
        collectionId: collection?.collectionId,
        description: description,
        tags: [
          {
            tagId: -1,
            value: 'perfect'
          },
          {
            tagId: -2,
            value: 'good'
          },
          {
            tagId: -3,
            value: 'fair'
          },
          {
            tagId: -4,
            value: 'poor'
          },
          {
            tagId: -5,
            value: 'terrible'
          },
          {
            tagId: -6,
            value: 'unclear'
          }
        ]
      }]
    }
  }, [innerProps.theme, data?.description, data?.matchType, collection?.collectionId]);

  const matchColumnDef = useMemo(() => ({
    id: `dropdown_${-1}`, // sortingKey
    name: `tagGroup${-1}`,
    entity: 'entity',
    tagGroupId: -1,
    tagGroup: fieldData.tagGroups.find((tg) => +tg.groupId === -1),
    FormFieldProps: {
      ChipListProps: {
        variant: 'compact'
      }
    }
  }), [fieldData.tagGroups]);

  const question = useMemo(() => {
    const tagGroup = fieldData?.tagGroups?.find((tg) => +tg.groupId === +matchColumnDef.tagGroupId);

    return {
      tagGroup: tagGroup.groupId,
      explanation: tagGroup.description,
      analyserType: constants.collection.analyserTypes.collectionTagAnalyser,
      analyserParams: {
        tagGroup: tagGroup.groupId,
        multiselect: tagGroup.multiselect,
        gptExplanations: true
      },
      subQuestions: tagGroup.tags.map((t) => ({
        analyserType: tagGroup.autoTagType,
        id: t.tagId,
        questionId: t.tagId,
        tagValue: t.value
      }))
    }
  }, [fieldData, matchColumnDef]);

  useLayoutEffect(() => {
    setDataEvent?.((current) => {
      if (!utils.compare(current?.[wizard.dataKey]?.question, question)) {
        const questionHash = utils.sha1(question);

        return {
          ...current,
          [wizard.dataKey]: {
            ...current[wizard.dataKey],
            fieldData: fieldData,
            question: question,
            questionHash: questionHash,
            [questionHash]: {
              ...current[wizard.dataKey]?.[questionHash],
              batch: current[wizard.dataKey]?.[questionHash]?.batch ?? 0,
              pointer: current[wizard.dataKey]?.[questionHash]?.pointer ?? 0
            }
          }
        }
      } else {
        return current;
      }
    })
  }, [question, fieldData, wizard.dataKey, setDataEvent]);

  const columnsMemo = useMemo(() => {
    const columns = [];

    columns.push({
      accessorKey: 'name',
      id: 'name',
      header: 'Company name',
      enableEditing: false,
      Cell: ({cell}) => {
        const entity = cell.row.original;

        return <TextTableCell title={entity.name || entity.entityId}
                              logo={<Logo logo={entity.logoUrl}
                                          altLogo={entity.altLogoUrl}
                                          fallbackIcon={HomeWorkOutlined}
                                          density="sparse"
                                          outlined={true}/>}/>
      },
    });

    if (!mdDown) {
      columns.push({
        accessorKey: 'oneliner',
        id: 'oneliner',
        header: 'Oneliner',
        enableSorting: false,
        enableEditing: false,
        Cell: (props) => <FieldCell {...props}
                                    columnDef={{
                                      name: 'oneliner',
                                      entity: 'entity',
                                      FormFieldProps: {
                                        showTooltip: true
                                      }
                                    }}
                                    fieldData={fieldData}/>
      });
    }

    columns.push({
      accessorKey: 'match',
      id: 'match',
      header: 'Match',
      enableSorting: false,
      enableEditing: false,
      Cell: (props) => {
        const entity = props.cell.row.original;

        if (utils.isDefined(entity.evidenceTime)) {
          return <FieldCell {...props}
                            columnDef={matchColumnDef}
                            fieldData={fieldData}/>
        } else if (entity.isLoading) {
          return <ScoreLinearProgress size="smallish"
                                      color="primary"
                                      showEmpty={true}
                                      score={0}
                                      LinearProgressProps={{
                                        variant: 'indeterminate'
                                      }}/>
        } else {
          return <ScoreLinearProgress size="smallish" isLoading={true} />
        }
      }
    })

    return columns;
  }, [fieldData, matchColumnDef, mdDown]);

  useEffect(() => {
    if (data?.question && utils.compare(data?.question, question)) {
      if (questionData?.pointer < data?.entityIds?.length && questionData?.pointer < data?.loadCount &&
          questionData?.pointer < ((questionData?.batch + 1) * constants.analyse.batchSizes.match)) {
        const batchSize = Math.min(10, data?.loadCount - questionData?.pointer);
        const entityIds = data?.entityIds.slice(questionData?.pointer, questionData?.pointer + batchSize)
          .filter((entityId) => !questionData?.evidences?.[entityId]);

        if (entityIds.length > 0) {
          if (loadingRef.current !== entityIds.join('_')) {
            loadingRef.current = entityIds.join('_');
            setStateEvent?.({loading: entityIds.reduce((o, entityId) => {
              o[entityId] = true;
              return o;
            }, {})});
            analyseQuestion.mutation.mutateAsync({
              entityIds: entityIds,
              question: data?.question,
              level: 2
            })
              .then(({response}) => {
                const result = utils.camelcase(response.data?.data);
                const credits = result.credits;
                const evidences = result.evidence;

                loadingRef.current = null;
                setStateEvent?.({loading: null});
                setDataEvent?.((current) => ({
                  ...current,
                  [wizard.dataKey]: {
                    ...current[wizard.dataKey],
                    [current[wizard.dataKey]?.questionHash]: {
                      ...current[wizard.dataKey]?.[current[wizard.dataKey]?.questionHash],
                      credits: (current[wizard.dataKey]?.[current[wizard.dataKey]?.questionHash]?.credits ?? 0) + credits,
                      pointer: (current[wizard.dataKey]?.[current[wizard.dataKey]?.questionHash]?.pointer ?? 0) + batchSize,
                      evidences: {
                        ...current[wizard.dataKey]?.[current[wizard.dataKey]?.questionHash]?.evidences,
                        ...entityIds.reduce((o, entityId) => {
                          o[entityId] = {
                            time: new Date(),
                            evidences: evidences
                              .filter((evidence) => +evidence.entityId === +entityId)
                              .map((evidence) => ({
                                ...evidence,
                                tagGroupId: current[wizard.dataKey]?.question?.tagGroup
                              }))
                          };

                          return o;
                        }, {})
                      }
                    }
                  }
                }));
              })
              .catch((error) => {
                loadingRef.current = null;
                setStateEvent?.({loading: null});
                if (error?.response?.status === constants.http.status.conflict) {
                  snackbar.show('0 credits left in your wallet', null,
                    {color: 'error', autoHideDuration: constants.delay.warning});
                } else {
                  snackbar.show('Failed to analyse the company', null,
                    {color: 'error', autoHideDuration: constants.delay.warning});
                }
              });
          }
        } else {
          setDataEvent?.((current) => ({
            ...current,
            [wizard.dataKey]: {
              ...current[wizard.dataKey],
              [current[wizard.dataKey]?.questionHash]: {
                ...current[wizard.dataKey]?.[current[wizard.dataKey]?.questionHash],
                pointer: (current[wizard.dataKey]?.[current[wizard.dataKey]?.questionHash]?.pointer ?? 0) + batchSize
              }
            }
          }));
        }
      }
    }
  }, [snackbar, question, questionData?.batch, questionData?.pointer, questionData?.evidences,
    data?.question, data?.loadCount, data?.entityIds, collection?.collectionId,
    analyseQuestion.mutation, wizard.dataKey, setDataEvent, setStateEvent]);

  useEffect(() => {
    if (questionData?.evidences || data?.question || data?.loadCount) {
      setDataEvent?.((current) => {
        if (current[wizard.dataKey]?.question) {
          const entities = Object.keys(current[wizard.dataKey]?.entities ?? {}).map((entityId) => {
            const entity = current[wizard.dataKey]?.entities[entityId];
            const tagGroup = current[wizard.dataKey]?.fieldData?.tagGroups?.find((tg) => +tg.groupId === +current[wizard.dataKey]?.question.analyserParams.tagGroup);

            const evidences = current[wizard.dataKey]?.[current[wizard.dataKey]?.questionHash]?.evidences?.[entity.entityId];

            const evidenceTime = evidences?.time;
            const tagged = evidences?.evidences?.filter((e) => +e.computedEvidence.score > 0) ?? [];

            return {
              ...entity,
              evidenceTime: evidenceTime,
              questionHash: current[wizard.dataKey]?.questionHash,
              tagGroups: (tagged?.length > 0) ? [{
                groupId: current[wizard.dataKey]?.question.analyserParams.tagGroup,
                tags: tagged.map((te) => {
                  return tagGroup.tags.find((t) => +t.tagId === +te.questionId);
                })
              }] : null,
              clientQuestions: (tagged?.length > 0) ? [{
                ...current[wizard.dataKey]?.question,
                subQuestions: tagged.map((te) => {
                  return {
                    ...current[wizard.dataKey]?.question.subQuestions.find((t) => +t.questionId === +te.questionId),
                    currentEvidence: {
                      2: te.computedEvidence
                    }
                  };
                })
              }] : null
            };
          }).reduce((o, e) => {
            return {...o, [e.entityId]: e};
          }, {});

          return {
            ...current,
            [wizard.dataKey]: {
              ...current[wizard.dataKey],
              entities
            }
          }
        } else {
          return current;
        }
      })
    }
  }, [questionData?.evidences, data?.loadCount, data?.question, wizard.dataKey, setDataEvent]);

  const rows = useMemo(() => {
    return (data?.entityIds ?? [])
      .slice(0, ((questionData?.batch + 1) * constants.analyse.batchSizes.match))
      .filter((entityId) => data?.entities?.[entityId]?.questionHash === data?.questionHash)
      .map((entityId) => ({
        ...data?.entities?.[entityId],
        isLoading: state?.loading?.[entityId]
      }))
      .filter((entity) => {
        const tagId = entity.tagGroups?.[0]?.tags?.[0]?.tagId;
        return !Object.keys(state?.legend?.visibility ?? {}).find((k) => state?.legend?.visibility[k]) ||
          (state?.legend?.visibility?.[tagId] ?? false);
      });
  }, [data?.questionHash, questionData?.batch, data?.entityIds, data?.entities, state?.loading, state?.legend?.visibility]);

  const renderSubtitle = () => {
    const subtitle = `${calculating ? 'Calculating' : 'Calculated'} match for companies ${utils.formatNumber((questionData?.batch * constants.analyse.batchSizes.match) + 1)} to 
      ${utils.formatNumber(Math.min((questionData?.batch + 1) * constants.analyse.batchSizes.match, resultsCount))} 
      (${Math.round(batchProgress)} %)`;

    return <Box className="EntitiesAnalyseMatchWizardContent-subtitle">
      <H6>{subtitle}</H6>
      <ScoreLinearProgress size="smallish"
                           showEmpty={true}
                           color="primary"
                           score={batchProgress} />
    </Box>
  };

  const barActions = useMemo(() => {
    return [{
      label: 'Load more',
      tooltip: null,
      icon: Downloading,
      onClick: () => {
        setDataEvent?.((current) => {
          return utils.updater({
            ...current,
            [wizard.dataKey]: {
              ...current[wizard.dataKey],
              [current[wizard.dataKey]?.questionHash] : {
                ...current[wizard.dataKey]?.[current[wizard.dataKey]?.questionHash],
                batch: (current?.[wizard.dataKey]?.[current[wizard.dataKey]?.questionHash]?.batch ?? 0) + 1
              }
            }
          })(current);
        });
      },
      ButtonProps: {
        disabled: (
          calculating ||
          ((questionData?.batch + 1) * constants.analyse.batchSizes.match) >= resultsCount
        )
      }
    }]
  }, [questionData?.batch, resultsCount, calculating, wizard.dataKey, setDataEvent]);

  const legend = useMemo(() => {
    const tagGroup = data?.fieldData?.tagGroups?.find((tg) => +tg.groupId === +data?.question.analyserParams.tagGroup);
    const legend = (tagGroup?.tags ?? []).map((t, idx) => ({
      id: t.tagId,
      label: t.value,
      active: state?.legend?.visibility?.[t.tagId] ?? false,
      color: t.color ?? tagGroup.color,
      count: 0,
      position: idx
    }));

    (data?.entityIds ?? [])
      .slice(0, ((questionData?.batch + 1) * constants.analyse.batchSizes.match))
      .filter((entityId) => data?.entities?.[entityId]?.questionHash === data?.questionHash)
      .map((entityId) => data?.entities?.[entityId])
      .forEach((entity) => {
        const tagId = entity.tagGroups?.[0]?.tags?.[0]?.tagId;

        legend.forEach((item) => {
          if (+item.id === +tagId) {
            item.count += 1;
          }
        });
      });

    legend.forEach((item) => {
      item.badge = `${utils.formatNumber(item.count)}`;
    });

    return legend;
  }, [questionData?.batch, data?.entityIds, data?.entities, data?.fieldData, data?.question, data?.questionHash, state?.legend]);

  const renderBarContext = () => {
    const handleLegendClick = (e, item) => {
      setStateEvent?.((current) => {
        let newVisibility = {
          ...current?.legend?.visibility,
          [item.id]: !(current?.legend?.visibility?.[item.id] ?? false)
        }

        return {
          ...current,
          legend: {
            visibility: newVisibility
          }
        }
      });
    }

    return <React.Fragment>
      <Legend onClick={handleLegendClick}
              legend={legend}
              colorLabel={true}
              rows={2}
              BadgeProps={{
                size: 'smaller',
                max: 999
              }}/>
      <Box className="EntitiesAnalyseMatchWizardContent-info">
        <Typography className="EntitiesAnalyseMatchWizardContent-total" variant="caption">
          {utils.formatNumber(totalDone)} / {utils.formatNumber(resultsCount)}
        </Typography>
        <Typography className="EntitiesAnalyseMatchWizardContent-credits" variant="caption">
          {utils.formatNumber(Math.round(questionData?.credits ?? 0))} credits
        </Typography>
      </Box>
    </React.Fragment>
  }

  const renderBar = () => {
    if (data?.question) {
      return <SplitBar className="EntitiesAnalyseMatchWizardContent-controls"
                       reverse={true}
                       actions={barActions}
                       context={renderBarContext()}/>
    }
  }

  return <StyledEntitiesAnalyseMatchWizardContent ref={ref} {...innerProps}>
    {renderSubtitle()}
    <Table className="EntitiesAnalyseMatchWizardContent-table"
           dataKey="entityId"
           enableTableHead={true}
           enableParentScroll={false}
           enableBottomToolbar={false}
           enableStickyHeader={true}
           enableSorting={false}
           enableEditing={false}
           enableColumnDragging={false}
           enableColumnActions={false}
           enableColumnOrdering={false}
           enableColumnResizing={false}
           enablePinning={false}
           enableColumnVirtualization={false}
           columns={columnsMemo}
           data={rows}
           rowCount={rows?.length}
           state={{
             isLoading: entitiesLoading,
             columnOrder: ['name', 'oneliner', 'match'],
             pagination: {
               pageIndex: 0,
               pageSize: rows?.length || 8
             }
           }} />
    {renderBar()}
  </StyledEntitiesAnalyseMatchWizardContent>
});

EntitiesAnalyseMatchWizardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  wizard: PropTypes.object,
  step: PropTypes.object,
  collection: PropTypes.object,
  tableProvider: PropTypes.object,
  onComplete: PropTypes.func,
  onDirty: PropTypes.func,
  onError: PropTypes.func,
};

EntitiesAnalyseMatchWizardContent.defaultProps = {
};

export default EntitiesAnalyseMatchWizardContent;
